<template>
  <div id="panel" class="panel" style="min-height: 696px;">
    <form id="searchSection" onsubmit="return false;" style="margin-bottom:0">
      <div class="status-num-wrap">
        <p class="titleName">各状态指南数目统计</p>
        <ul class="status-num-list">
          <li v-for="(item,index) in numArry" :key="index">
            <p class="p1">{{item.state}}</p>
            <p class="p2">{{getNum(item.code)}}条</p>
          </li>
        </ul>
      </div>
      <div class="status-num-wrap">
        <p class="titleName">已提交指南各领域占比</p>
        <div class="m-t-md" v-if="submitGuieArr.length > 0">
          <div class="table-wrap">
            <table>
              <tbody>
                <tr class="l_header">
                  <th class="itemStatus">领域名称</th>
                  <th class="itemStatus">指南数</th>
                  <th class="itemStatus">覆盖专业类数</th>
                  <th class="itemStatus">覆盖专业数</th>
                  <th class="itemStatus">覆盖课程数</th>
                </tr>
                <tr class="item" v-for="(item,index) in submitGuieArr" :key="index">
                  <td>{{item.title}}</td>
                  <td>{{item.count_guide}}</td>
                  <td>{{item.count_categorie02}}</td>
                  <td>{{item.count_categorie03}}</td>
                  <td>{{item.count_course}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="chart-wrap" ref='myChart1'></div>
        </div>
        <div class="noitems" v-else>没有数据</div>
      </div>
      <div class="status-num-wrap">
        <p class="titleName">已发布指南各领域占比</p>
        <div class="m-t-md" v-if="publishGuieArr.length > 0">
          <div class="table-wrap">
            <table>
              <tbody>
                <tr class="l_header">
                  <th class="itemStatus">领域名称</th>
                  <th class="itemStatus">指南数</th>
                  <th class="itemStatus">覆盖专业类数</th>
                  <th class="itemStatus">覆盖专业数</th>
                  <th class="itemStatus">覆盖课程数</th>
                </tr>
                <tr class="item" v-for="(item,index) in publishGuieArr" :key="index">
                  <td>{{item.title}}</td>
                  <td>{{item.count_guide}}</td>
                  <td>{{item.count_categorie02}}</td>
                  <td>{{item.count_categorie03}}</td>
                  <td>{{item.count_course}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="chart-wrap" ref='myChart2'></div>
        </div>
        <div class="noitems" v-else>没有数据</div>
      </div>

      <div class="status-num-wrap">
        <p class="titleName">已发布指南各专业类占比</p>
        <div class="m-t-md">
          <ul class="tabList">
            <li v-for="(item,index) in publishGuieArr" :key="index"
            :class="onClick == index?'onActive':''" @click="onClick = index,getStatisticData(item.code)">{{item.title}}</li>
          </ul>
        </div>
        <div class="m-t-md" v-if="majorGuieArr.length > 0">
          <div class="table-wrap" style="width:100%">
            <table>
              <tbody>
                <tr class="l_header">
                  <th class="itemStatus">专业类名称</th>
                  <th class="itemStatus">所属领域</th>
                  <th class="itemStatus">指南数</th>
                  <th class="itemStatus">覆盖专业数</th>
                  <th class="itemStatus">覆盖课程数</th>
                </tr>
                <tr class="item" v-for="(item,index) in majorGuieArr" :key="index">
                  <td>{{item.title}}</td>
                  <td>{{item.count_guide}}</td>
                  <td>{{item.count_guide}}</td>
                  <td>{{item.count_categorie03}}</td>
                  <td>{{item.count_course}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="noitems" v-else>没有数据</div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "AdminStatistics",
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      onClick:0,
      EchartSubmitTotal:0,
      EchartSubmitValue:[],

      EchartPublishTotal:0,
      EchartPublishValue:[],

      numArry:[
        {state:'建设中',code:0},
        {state:'待审核',code:1},
        {state:'待评审',code:3},
        {state:'已发布',code:5},
        {state:'已关闭',code:2},
        {state:'评审未通过',code:4},
        {state:'总计',code:10},
      ],
      tabArry:[
        '光电工程信息技术'
      ],
      toggleBtn:[],
      submitGuieArr:[],  //已提交指南各领域占比
      publishGuieArr:[],  //已发布指南各领域占比
      majorGuieArr:[],  //已发布指南各专业类占比
    }
  },
  // 事件处理器
  methods: {
    myChartInit1 () {
      var myChart = this.$echarts.init(this.$refs.myChart1)
      var option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        series: [
          {
            name: '已提交指南',
            type: 'pie',
            selectedMode: 'single',
            radius: [0, '45%'],
            center: ["50.5%", "50%"],
            hoverAnimation:false,
            label: {
              position: 'inner',
              fontSize: 12,
              formatter: ' {b|{b}：}{c} ',
              rich: {
                b: {
                  color: '#FFFFFF',
                  fontSize: 12,
                  lineHeight: 33
                },
              }
            },
            labelLine: {
              show: false
            },
            data: [
              {value: this.EchartSubmitTotal, name: '指南总数'},
            ]
          },
          {
            name: '已提交指南',
            type: 'pie',
            radius: ['50%', '65%'],
            center: ["50.5%", "50%"],
            labelLine: {
              length: 15,
            },
            label: {
              formatter: ' {b|{b}：}{c} ',
              rich: {
                b: {
                  color: '#4C5058',
                  fontSize: 12,
                  lineHeight: 33
                },
              }
            },
            data: this.EchartSubmitValue
          }
        ]
    };
    myChart.setOption(option)
    },
    myChartInit2 () {
      var myChart = this.$echarts.init(this.$refs.myChart2)
      var option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        series: [
          {
            name: '已发布指南',
            type: 'pie',
            selectedMode: 'single',
            radius: [0, '45%'],
            center: ["50.5%", "50%"],
            hoverAnimation:false,
            label: {
              position: 'inner',
              fontSize: 12,
              formatter: ' {b|{b}：}{c} ',
              rich: {
                b: {
                  color: '#FFFFFF',
                  fontSize: 12,
                  lineHeight: 33
                },
              }
            },
            labelLine: {
              show: false
            },
            data: [
              {value: this.EchartPublishTotal, name: '指南总数'},
            ]
          },
          {
            name: '已发布指南',
            type: 'pie',
            radius: ['50%', '65%'],
            center: ["50.5%", "50%"],
            labelLine: {
              length: 15,
            },
            label: {
              formatter: ' {b|{b}：}{c} ',
              rich: {
                b: {
                  color: '#4C5058',
                  fontSize: 12,
                  lineHeight: 33
                },
              }
            },
            data: this.EchartPublishValue
          }
        ]
      };
      myChart.setOption(option)
    },
    async getStatisticData(code){
      await this.$http.get('/statistics_sub/', {
        params: {
          status:5,
          code:code
        }
      }).then((data) => {
        this.majorGuieArr = data.data
      }).catch(() => {
        this.$parent.$parent.showMsg('获取指南信息失败！')
      })
    },
    // 获取列表数据
    async getGuideData(type) {
      await this.$http.get('/statistics/', {
        params: {
          status:type
        }
      }).then((data) => {
        if(data.data.length > 0){
          if(type==1){
            this.$nextTick(()=>{
              setTimeout(()=>{
                this.myChartInit1()
            
              },600)
            })
            
            this.submitGuieArr=data.data
            this.submitGuieArr.forEach(element => {
              this.EchartSubmitTotal = element.count_guide + this.EchartSubmitTotal
              this.EchartSubmitValue.push(
                {
                  value:element.count_guide,
                  name:element.title
                }
              )
            });
          } else if(type==5){
            this.$nextTick(()=>{
              setTimeout(()=>{
                this.myChartInit2()
              },600)
            })
            this.publishGuieArr=data.data
            this.publishGuieArr.forEach(element => {
              this.EchartPublishTotal = element.count_guide + this.EchartPublishTotal
              this.EchartPublishValue.push(
                {
                  value:element.count_guide,
                  name:element.title
                }
              )
            });
            this.getStatisticData(this.publishGuieArr[0].code)
          }
        }
      }).catch(() => {
        this.$parent.$parent.showMsg('获取指南信息失败！')
      })
    },
    getNum(code){
      var num = 0
      this.toggleBtn.forEach(item => {
        if(code==10){
          num = item.status_sum + num
        } else if(code == item.status){
          num = item.status_sum
        }
      })
      return num
    },
    async getGuideStatus() {
      await this.$http.get('/guide_status/').then((data) => {
        this.toggleBtn = data.data
      }).catch(() => {
        this.$parent.$parent.showMsg('获取指南信息失败！')
      })
    },
  },
  // 生命周期-实例创建完成后调用
  created () { 
    this.getGuideStatus()
    this.getGuideData(1)  //已提交指南各领域占比
    this.getGuideData(5)  //已发布指南各领域占比
  },
  // 生命周期-实例挂载后调用
  mounted () {
    // this.$nextTick(()=>{
    //   setTimeout(()=>{
    //     this.myChartInit1()
        
    //   },600)
    // })
    
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style  lang="less" scoped>
.status-num-wrap{
  background: #F2F2F2;
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
  .titleName {
    font-size: 18px;
    text-align: center;
  }
  .status-num-list{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    li{
      padding: 10px 5px;
      width: 101px;
      background: #33CC99;
      color: #ffffff;
    }
  }
  .m-t-md{
    overflow: hidden;
    .tabList{
      overflow: hidden;
      li{
        cursor: pointer;
        float: left;
        height: 36px;
        line-height: 36px;
        padding: 0 15px;
        background: #797979;
        color: #fff;
        margin-right: 5px;
      }
      .onActive{
        background: #4976e8;
      }
    }
    .table-wrap{
      float: left;
      width: 460px;
      max-height: 260px;
      overflow-y: auto;
      table {
        border-collapse: collapse;
        text-align: center;
        background: #fff;
        width: 100%;
        border-left: 1px solid #D7D7D7;
        border-top: 1px solid #D7D7D7;
        th {
          padding: 10px 5px;
          background: #D0E9F5;
          border-right: 1px solid #D7D7D7;
          border-bottom: 1px solid #D7D7D7;
          color: #333;
          font-weight: normal;
          line-height: 20px;
        }
        td {
          line-height: 20px;
          padding: 10px 5px;
          border-right: 1px solid #D7D7D7;
          border-bottom: 1px solid #D7D7D7;
          color: #959595;
        }
      }
    }
    .chart-wrap{
      float: right;
      width: 370px;
      height: 260px;
    }
  }
}
.status-num-wrap:last-child{
  margin-bottom: 0;
  padding-bottom: 50px;
}
</style>
