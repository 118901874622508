import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import store from './store'
import VueCookies from 'vue-cookies'
import './assets/css/global.css'
import echarts from 'echarts'

import vueSwiper from 'vue-awesome-swiper'  //引入vue-awesome-swiper
import 'swiper/dist/css/swiper.css'  //引入样式

import layer from 'vue-layer'
import 'vue-layer/lib/vue-layer.css';

Vue.prototype.$layer = layer(Vue);

Vue.use(VueCookies)
Vue.use(vueSwiper)  //使用插件

Vue.prototype.$echarts = echarts

axios.defaults.baseURL = store.state.rootUrl + 'api'

axios.interceptors.request.use(config => {

  if (store.state.last_use_time) {
    let cur_time = parseInt(Date.parse(new Date()) / 1000)
    if (store.state.last_use_time + 1800 < cur_time) {
      window.localStorage.clear()
      window.location.href = '/'
    } else {
      store.commit('setLastUseTime', parseInt(Date.parse(new Date()) / 1000))
    }
  }

  if (config.url !== '/api-token-auth/') {
    const token = store.state.token
    config.headers.common.Authorization = 'Token ' + token
  }
  return config
})
axios.interceptors.response.use(function (response) {
  return response.data
})

// 设置浏览器标题
Vue.directive('title', {
  inserted: function (el) {
    document.title = el.dataset.title
  }
})

Vue.prototype.$http = axios

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
