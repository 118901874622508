<template>
  <div id="panel" class="panel" style="min-height: 696px;">
    <form id="searchSection" onsubmit="return false;">
      <select name="position" class="s-select separate" v-model="queryInfo.position">
        <option value="">选择展示位置</option>
        <option value="0">首页轮播</option>
        <option value="1">广告位</option>
      </select>
      <select name="status" class="s-select separate" v-model="queryInfo.status">
        <option value="">选择发布状态</option>
        <option value="0">未发布</option>
        <option value="1">已发布</option>
      </select>
      <input type="text" name="title" class="s-input separate" placeholder="标题" v-model="queryInfo.title">
      <input type="button" class="s-btn" value="搜索" @click="search">
      <div class="add-btn" @click="showAddWindow">新建</div>
    </form>
    <div id="list">
      <table v-if="this.tableData.length > 0">
        <tbody>
        <tr class="l_header">
          <th class="itemStatus">位置</th>
          <th class="itemStatus">标题</th>
          <th class="itemStatus">发布状态</th>
          <th class="itemStatus">轮播图片</th>
          <th class="action" width="180">操作</th>
        </tr>
        <tr class="item" v-for="item in tableData" :key="item.id">
          <td class="itemId">{{ item.position == 0 ? '首页轮播' : '广告位' }}</td>
          <td class="itemStatus">{{ item.title }}</td>
          <td :class="'itemStatus itemStatus' + item.status">{{ item.status == 1 ? '已发布' : '未发布' }}</td>
          <td class="itemStatus"><img :src="formTableImageSrc(item.imgPath)" height="50" style="margin:10px 0"></td>
          <td class="action">
            <a v-if="item.status==1" class="nopublish" href="javascript:;" @click="unpublish(item.id)">取消发布</a>
            <a v-if="item.status==0" class="publish" href="javascript:;" @click="publish(item.id)">发布</a>
            <a class="edit" href="javascript:;" @click="querySingle(item.id)">编辑</a>
            <a class="delete" href="javascript:;" @click="askDel(item.id)">删除</a>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="noitems" v-if="tableData.length == 0">没有数据</div>
    </div>

    <pagination :cur="pageInfo.page" :all="pageInfo.all" :totalData="pageInfo.totalData"
                :callback="pageCallback"></pagination>

    <div class="form_overlay" id="form_overlay" :style="classShowAddWindow">
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">添加/编辑轮播图</div>
        <div class="form_content">
          <form id="itemForm">
            <div class="item">
              <span class="label"><i class="require">*</i>展示位置:</span>
              <input type="hidden" v-model="formInfo.id">
              <select name="position" v-model="formInfo.position">
                <option value="">选择展示位置</option>
                <option value="0">首页轮播</option>
                <option value="1">首页广告位</option>
              </select>
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>标题:</span>
              <input type="text" name="title" v-model="formInfo.title">
            </div>
            <div class="item">
              <span class="label">链接地址:</span>
              <input type="text" name="link" v-model="formInfo.link">
            </div>
            <div class="item">
              <span class="label">图片大小:</span>
              <div id="size" name="size">1920px * 350px</div>
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>轮播图片:</span>
              <div class="item-box">
                <input name="imgPath" type="text" id="imgurl" disabled="disabled" style="width: 225px;"
                       v-model="formInfo.imgPath">
                <span class="button" id="uploadImg" @click="showImageWindow">添加图片</span>
              </div>
            </div>
            <div class="item">
              <div id="preview"></div>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a href="javascript:;" class="button close" id="form_close" @click="hideAddWindow">取消</a>
          <a href="javascript:;" class="button" id="form_submit" @click="saveInfo">提交</a>
        </div>
      </div>
    </div>

    <div id="dialog_overlay" class="" :style="classShowImageWindow">
      <div id="dialog_bg"></div>
      <div id="dialog_panel" style="margin-left: -137px; margin-top: -84px;" class="">
        <div id="dialog_panel_bg"></div>
        <div id="dialog_title">选择图片</div>
        <div id="dialog_content">
          <div class="imgType" style="margin-bottom:10px;" id="imgType">允许上传的图片格式：jpg</div>
          <div id="thumbPreview" v-show="showImgPreview"><img ref="headImg"></div>
          <div>
            <input id="imageFile" type="file" name="imageFile" accept="image/*"
                   ref="imageFile" @change="imageFileChange">
          </div>
        </div>
        <div id="dialog_control">
          <a href="javascript:void(0)" @click="hideImageWindow" id="dialog_close" class="button"
             style="display: inline-block;">取消</a>
          <a href="javascript:void(0)" @click="confirmImage" id="dialog_ok" class="button"
             style="display: inline-block;">确认</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../../components/Pagination";

export default {
  name: "AdminFlash",
  components: {
    Pagination
  },
  data() {
    return {
      queryInfo: {
        page: 1,
        size: 10,
        position: '',
        status: '',
        title: '',
      },
      formInfo: {
        id: 0,
        position: '',
        title: '',
        link: '',
        imgPath: ''
      },
      classShowAddWindow: {},
      classShowImageWindow: {},
      showImgPreview: false,
      uploaded_img: '',
      tableData: [],
      tableDataCount: 0,
    }
  },
  computed: {
    pageInfo() {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    }
  },
  methods: {
    showAddWindow() {
      this.classShowAddWindow = {
        visibility: 'visible'
      }
    },
    hideAddWindow() {
      this.classShowAddWindow = {}
      this.formInfo = {
        id: 0,
        position: '',
        title: '',
        link: '',
        imgPath: ''
      }
    },
    showImageWindow() {
      this.classShowImageWindow = {
        visibility: 'visible'
      }
    },
    hideImageWindow() {
      this.classShowImageWindow = {}
    },
    async imageFileChange() {
      const uploadUrl = '/info_files/' + this.$refs.imageFile.files[0].name
      await this.$http.put(uploadUrl, this.$refs.imageFile.files[0]).then((data) => {
        console.log(data)
        this.uploaded_img = data
        this.showImgPreview = true
        this.$refs.headImg.src = this.$store.state.wwwFileRootUrl + data
      }).catch(() => {
        this.$parent.$parent.showMsg('上传失败！')
      })
    },
    confirmImage() {
      this.formInfo.imgPath = this.uploaded_img
      this.hideImageWindow()
    },
    async saveInfo() {
      if(!this.formInfo.position.toString()){
        this.$parent.$parent.showMsg("请选择展示位置!")
        return false
      }
      if(!this.formInfo.title){
        this.$parent.$parent.showMsg("请输入标题!")
        return false
      }
      if(!this.formInfo.imgPath){
        this.$parent.$parent.showMsg("请选择轮播图片!")
        return false
      }

      if(this.formInfo.id){
        let url = '/flash/' + this.formInfo.id + '/'
        await this.$http.put(url, this.formInfo).then((data) => {
          if (data.id) {
            this.$parent.$parent.showMsg('修改成功！')
            this.hideAddWindow()
            this.search()
          } else {
            this.$parent.$parent.showMsg('修改失败！')
          }
        }).catch((error) => {
          console.log(error)
          this.$parent.$parent.showMsg('修改失败！')
        })
      }else{
        await this.$http.post('/flash/', this.formInfo).then((data) => {
          if (data.id) {
            this.$parent.$parent.showMsg('添加成功！')
            this.hideAddWindow()
            this.search()
          } else {
            this.$parent.$parent.showMsg('添加失败！')
          }
        }).catch((error) => {
          console.log(error)
          this.$parent.$parent.showMsg('添加失败！')
        })
      }

    },
    async search() {
      await this.$http.get('/flash/', {
        params: this.queryInfo
      }).then((data) => {
        console.log(data)
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$parent.$parent.showMsg('获取轮播信息失败！')
      })
    },
    async changePublishState(id, status) {
      const msg = status == 0? "取消": ""
      const url = '/flash/' + id + '/'
      await this.$http.put(url, {
        status: status
      }).then((data) => {
        console.log(data)
        this.$parent.$parent.showMsg(msg + '发布成功！');
        this.search()
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg(msg + '发布失败！');
      })
    },
    unpublish(id) {
      console.log(id)
      this.$parent.$parent.showConfirm('确定要取消发布吗？', () => {
        this.changePublishState(id, 0)
      });
    },
    publish(id) {
      console.log(id)
      this.$parent.$parent.showConfirm('确定要发布吗？', () => {
        this.changePublishState(id, 1)
      });
    },
    async delRow(id) {
      const url = '/flash/' + id + '/'
      await this.$http.put(url, {
        isdel: 1
      }).then((data) => {
        console.log(data)
        this.$parent.$parent.showMsg('删除成功！');
        this.search()
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg('删除失败！');
      })
    },
    askDel(id) {
      this.$parent.$parent.showConfirm('确定要删除吗？', () => {
        this.delRow(id)
      });
    },
    formTableImageSrc(src) {
      if (src != '') {
        return this.$store.state.wwwFileRootUrl + src
      } else {
        return ''
      }
    },
    async pageCallback(data) {
      this.queryInfo.page = data
      await this.search()
    },
    async querySingle(id) {
      const url = '/flash/' + id + '/'
      await this.$http.get(url).then((data) => {
        console.log(data)
        this.formInfo = {
          id: id,
          position: data.position,
          title: data.title,
          link: data.link,
          imgPath: data.imgPath
        }
        this.showAddWindow()
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg('数据获取失败！');
      })
    }
  },
  mounted() {
    this.search()
  }
}
</script>

<style scoped>
/* .form_panel {
  top: 80px !important;
} */

.form_content .item .item-box .button {
  left: 0;
  top: 0;
}
</style>
