<template>
  <div
    id="panel"
    class="panel"
    style="min-height: 696px;"
  >
    <form
      id="searchSection"
      onsubmit="return false;"
    >
      <input
        type="text"
        name="title"
        placeholder="会议资料标题"
        class="s-input separate"
        v-model="queryInfo.fileName"
      >
      <input
        type="date"
        name="date"
        placeholder="日期"
        class="date_box"
        v-model="queryInfo.created"
      >
      <input
        type="button"
        class="s-btn"
        value="搜索"
        @click="queryInfo.page=1,search()"
      >
      <div
        class="add-btn"
        style="margin-left:0;"
        @click="showFileWindow"
      >导入</div>
      <div class="search-line clearfix">
      </div>
    </form>
    <div id="list">
      <table v-if="this.tableData.length > 0">
        <tbody>
          <tr class="l_header">
            <th width="45px"><a
                href="javascript:;"
                class="a-link"
                @click="ckAll"
              >全选</a></th>
            <th class="itemTitle">会议资料标题</th>
            <th class="itemTitle">上传时间</th>
            <th
              class="action"
              width="130"
            >操作</th>
          </tr>
          <tr
            class="item"
            v-for="item in tableData"
            :key="item.id"
          >
            <td><input
                type="checkbox"
                name="chose"
                :value="item.id"
                v-model="ckUser"
                @change="ckChange"
              ></td>
            <td class="itemId">
              <a
                class="fileNamePath"
                style="max-width:500px"
                title="点击进行预览"
                href="javascript:void(0)"
                @click="showPreviewWindow($store.state.wwwFileRootUrl + item.filePath)"
              >{{item.fileName}}</a>
            </td>
            <td class="itemTitle">{{ item.created?item.created.substr(0, 10):'' }}</td>
            <td
              class="action"
              style="font-size:12px"
            >
              <a
                class="delete"
                href="javascript:;"
                @click="isDel(item.id)"
              >删除</a>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="noitems"
        v-if="this.tableData.length == 0"
      >没有数据</div>
    </div>

    <ul
      v-if="this.tableData.length > 0"
      class="batchBtns"
      id="batchBtns"
    >
      <li><input
          type="button"
          class="disable-btn"
          id="batchDisableBtn"
          value="批量删除"
          @click="batchDisable"
        ></li>
    </ul>
    <pagination
      :cur="pageInfo.page"
      :all="pageInfo.all"
      :totalData="pageInfo.totalData"
      :callback="pageCallback"
    ></pagination>

    <!-- 导入 -->
    <div
      id="file-upload-overlay"
      :style="bigFileWindowShow"
    >
      <div class="file-upload-bg"></div>
      <div class="file-upload">
        <div class="file-upload-type">允许上传的文件扩展名：pdf、doc、docx、ppt、pptx、mp4</div>
        <div
          class="file-upload-close"
          @click="hideFileWindow"
        >×</div>
        <span
          class="file_uploadBtn"
          id="file_uploadBtn"
          style="display: block;"
        >
          选择文件<input
            type="file"
            accept=".pdf,.doc,.docx,.ppt,.pptx,.mp4"
            id="file_fileInput"
            ref="docFile"
            style="display: block;width: 160px;"
            @change="fileChange"
          >
        </span>
        <div>
          <a
            class="fileNamePath"
            title="点击进行预览"
            :href="$store.state.wwwFileRootUrl + formInfo.filePath"
            target="_blank"
          >{{formInfo.fileName}}</a>
        </div>
        <div
          class="fileBox"
          id="file_uploadBox"
          style="display: block;"
        >
          <span
            class="pauseBtn"
            id="file_pauseBtn"
            style="display: none;"
          >暂停</span>
          <span
            class="goBtn"
            id="file_goBtn"
            style="display:none;"
          >继续</span>
          <div
            class="filename"
            id="file_filename"
            style="display: none;"
          ></div>
          <div
            class="pbar"
            v-if="progressValue!=0"
            :style="showProgress ? 'display: block' : 'display: none'"
          >
            <div
              class="progress"
              id="file_progress"
              :style="'width: ' + Math.ceil(progressValue * 4.2) + 'px;'"
            ></div>
            <div id="file_percent">{{ progressValue }}%</div>
          </div>
        </div>
        <!-- <div>
          <input type="button" class="onSave" value="导入" @click="onSaveFile">
        </div> -->
        <div class="clear"></div>
      </div>
    </div>

    <!-- 预览窗体-->
    <div class="form_overlay" :style="classShowPreviewWindow">
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">预览</div>
        <div class="form_content">
          <video v-if="previewType == 'mp4'" :src="previewPath" autoplay controls></video>
          <div v-if="previewType == 'pdf'">
            <pdf v-for="item in numPages" :key="item" :src="previewPath" :page="item"/>
          </div>
<!--          <pdf ref="morePDF" :src="previewPath"></pdf>-->
          <iframe v-if="previewType == 'micro'" :src="previewPath"></iframe>
        </div>
        <div class="form_control">
          <a href="javascript:;" class="button close" id="form_close" @click="hidePreviewWindow">取消</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import Pagination from "../../components/Pagination"
export default {
  // 可用组件的哈希表
  components: {
    Pagination,
    pdf
  },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      queryInfo:{
        page: 1,
        size: 10,
        meetingInfo_id:'',
        fileName: '',
        created: '',
      },
      formInfo: {
        id: 0,
        meetingInfo_id: '',
        fileName: '',
        filePath: '',
        viewCount: 374,
        meetingInfo: '',
      },
      imgId: 1,
      tableDataCount: 0,
      tableData:[],
      ckUser: [],
      bigFileWindowShow: {
        display: 'none'
      },
      showProgress: false,
      progressValue: 0,
      classShowPreviewWindow: {},
      previewType: '',
      previewPath: '',
      numPages: 0
    }
  },
  // 事件处理器
  methods: {
    showPreviewWindow(v) {
      this.classShowPreviewWindow = {
        visibility: 'visible'
      }

      const expName = v.split('.')[v.split('.').length - 1].toString().toLowerCase()
      if (expName === 'mp4') {
        this.previewPath = v
        this.previewType = 'mp4'
      } else if (expName === 'pdf') {
        // this.previewPath = v
        this.previewType = 'pdf'

        this.previewPath = pdf.createLoadingTask({ url: v });//解决中文乱码问题
        this.previewPath.promise.then((pdf) => {
          this.numPages = pdf.numPages;
        })

      } else if (expName === 'doc' || expName === 'docx' || expName === 'ppt' || expName === 'pptx') {
        this.previewPath = this.generateMicroPath(v)
        this.previewType = 'micro'
      }
      console.log(document.domain)
      console.log(v)
    },
    hidePreviewWindow() {
      this.classShowPreviewWindow = {}
      this.previewPath = ''
    },
    generateMicroPath(v) {
      if (v) {
        return 'https://view.officeapps.live.com/op/view.aspx?src=http://' + document.domain + v
      }
      return ''
    },
    // 分页
    async pageCallback(data) {
      this.queryInfo.page = data
      await this.search()
    },
    // 搜索
    async search() {
      await this.$http.get('/meeting_file/', {
        params: this.queryInfo
      }).then((data) => {
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$parent.$parent.showMsg('获取会议信息失败！')
      })
    },
    showFileWindow() {
      this.bigFileWindowShow = {
        display: 'block'
      }
    },
    hideFileWindow() {
      this.bigFileWindowShow = {
        display: 'none'
      }
    },
    // 全选
    ckAll() {
      if (this.ckUser.length == this.tableData.length) {
        this.ckUser = []
      } else {
        for (let i = 0; i < this.tableData.length; i++) {
          this.ckUser.push(this.tableData[i].id)
        }
      }
    },
    ckChange() {
      console.log(this.ckUser)
    },
    guid() {
      let s = [];
      let hexDigits = "0123456789abcdef";
      for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = "-";

      let uuid = s.join("");
      return uuid;
    },
    // 上传文件
    async fileChange(e) {
      console.log(e)
      let uuid_str = this.guid()

      this.showProgress = true
      const fileRealName = this.$refs.docFile.files[0].name

      let uuid_filename = uuid_str + '.' + fileRealName.split('.')[fileRealName.split('.').length - 1]

      let bytesPerPiece = 1024 * 1024;
      let blob = this.$refs.docFile.files[0];
      let start = 0;
      let end;
      let filesize = blob.size;

      while(start < filesize) {
        if (this.bigFileWindowShow.display !== 'block') {
          this.showProgress = false
          this.progressValue = 0

          this.formInfo.fileName = ''
          this.formInfo.filePath = ''
          e.target.value = ''
          break
        }

        end = start + bytesPerPiece;
        if(end > filesize) {
          end = filesize;
        }

        let chunk = blob.slice(start,end);//切割文件
        let formData = new FormData();
        formData.append("file", chunk, uuid_filename);

        const uploadUrl = '/splice_files/'
        await this.$http.post(uploadUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          // onUploadProgress: (e) => {
          //   console.dir(e)
          // }
        }).then((data) => {
          this.progressValue = Math.ceil(end / filesize * 100)

          if (end >= filesize) {
            this.showProgress = false
            this.progressValue = 0

            this.formInfo.fileName = fileRealName
            this.formInfo.filePath = data

            this.onSaveFile()

            this.$parent.$parent.showMsg('上传成功！')
          }
        }).catch((e) => {
          console.log(e)
        })

        start = end;
      }
      return false;

      // this.showProgress = true
      // const fileRealName = this.$refs.docFile.files[0].name
      // const uploadUrl = '/info_files/' + fileRealName
      // await this.$http.put(uploadUrl, this.$refs.docFile.files[0], {
      //   headers: {
      //     'Content-Type': 'multipart/form-data'
      //   },
      //   onUploadProgress: (e) => {
      //     console.dir(e)
      //     this.progressValue = Math.ceil(e.loaded / e.total * 100)
      //   }
      // }).then((data) => {
      //   window.console.log(data)
      //
      //   this.showProgress = false
      //   this.progressValue = 0
      //
      //   this.formInfo.fileName = fileRealName
      //   this.formInfo.filePath = data
      //
      //   this.onSaveFile()
      //
      //   this.imgId++
      //   this.$parent.$parent.showMsg('上传成功！')
      // }).catch(() => {
      //   this.showProgress = false
      //   this.progressValue = 0
      //   this.$parent.$parent.showMsg('上传失败！')
      // })
    },
    // 导入文件
    async onSaveFile(){
      this.formInfo.meetingInfo_id = this.$route.query.meet_id
      await this.$http.post('/meeting_file/', this.formInfo).then((data) => {
        if (data.id) {
          this.$parent.$parent.showMsg('添加成功！')
          this.formInfo = {}
          this.hideFileWindow()
          this.search()
        } else {
          this.$parent.$parent.showMsg('添加失败！')
        }
      }).catch(() => {
        this.$parent.$parent.showMsg('添加失败！')
      })
    },
    // 删除确认框
    isDel(id) {
      this.$parent.$parent.showConfirm('确定要删除吗？', () => {
        this.delRow(id)
      });
    },
    async delRow(id) {
      const url = '/meeting_file/' + id + '/'
      await this.$http.delete(url, {
      }).then(() => {
        this.$parent.$parent.showMsg('删除成功！');
        this.search()
      }).catch(() => {
        this.$parent.$parent.showMsg('删除失败！');
      })
    },
    // 批量删除
    batchDisable() {
      if(this.ckUser.length==0){
        this.$parent.$parent.showMsg('请选择要删除的文件');
        return
      }
      this.$parent.$parent.showConfirm("确认将所选文件批量删除？", () => {
        for (let i = 0; i < this.ckUser.length; i++) {
          this.delRow(this.ckUser[i])
        }
        this.ckUser = []
      });
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.formInfo.meetingInfo_id = this.$route.query.meet_id
    this.queryInfo.meetingInfo_id = this.$route.query.meet_id
  },
  // 生命周期-实例挂载后调用
  mounted () {
    this.search()
   },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {
    pageInfo() {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    },
  },
  // 自定义的侦听器
  watch: {}
}
</script>

<style scoped>
.date_box {
  border: 1px solid #e6e6e6;
  background-color: #f5f7fc;
  width: 150px;
  height: 32px;
  line-height: 32px;
  margin-right: 8px;
  padding-left: 8px;
}
.fileNamePath {
  cursor: pointer;
  position: relative;
  text-decoration: underline;
  display: inline-block;
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  top: 10px;
  color: #5684f8;
}
.onSave {
  position: relative;
  top: 20px;
  width: 50px;
  height: 30px;
}
.form_content {
  max-height: 420px;
  overflow-y: auto;
}
.form_content video{
  width: 600px;
}
.form_content iframe {
  width: 650px;
  height: 400px;
  border: 0px;
}
/*.form_panel, .other_panel {*/
/*  margin-top: -30%;*/
/*}*/
</style>
