<template>
  <div class="load">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>

</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {}
  },
  // 事件处理器
  methods: {},
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.load{
  width: 100px;
  height: 100px;
  position: relative;
  margin: 0 auto;
  top: 180px;
}
.load span{
    display: inline-block;
    opacity: 0.1;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background: #333333;
    position: absolute;
    -webkit-animation: load 1.04s ease infinite;
}
@-webkit-keyframes load{
    0%{
        opacity: 1;
    }
    100%{
        opacity: 0.1;
    }
}
.load span:nth-child(1){
    left: 5px;
    top: 50%;
    margin-top: -8px;
    -webkit-animation-delay: 0.13s;
}
.load span:nth-child(2){
    left: 15px;
    top: 15px;
    -webkit-animation-delay: 0.26s;
}
.load span:nth-child(3){
    left: 50%;
    top: 5px;
    margin-left: -8px;
    -webkit-animation-delay: 0.39s;
}
.load span:nth-child(4){
    top: 15px;
    right: 15px;
    -webkit-animation-delay: 0.52s;
}
.load span:nth-child(5){
    right: 5px;
    top: 50%;
    margin-top: -8px;
    -webkit-animation-delay: 0.65s;
}
.load span:nth-child(6){
    right: 15px;
    bottom: 15px;
    -webkit-animation-delay: 0.78s
}
.load span:nth-child(7){
    bottom: 5px;
    left: 50%;
    margin-left: -8px;
    -webkit-animation-delay: 0.91s;
}
.load span:nth-child(8){
    bottom: 15px;
    left: 15px;
    -webkit-animation-delay: 1.04s;
}
</style>
