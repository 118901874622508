<template>
  <div id="center">
    <div class="subBanner2"></div>
    <div id="subCenter">
      <div id="content" class="content organization-wrap">
        <div class="subLeft">
          <dl>
            <dt>虚仿竞赛
              <p>COMPETITION</p>
            </dt>
            <dd @click="updateMenuSelected" :class="this.menuSelected == '/www/competition/notice' ? 'cur' : ''">
              <router-link to="/www/competition/notice">赛事通知</router-link>
            </dd>
          </dl>
        </div>
        <router-view></router-view>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "OrganizationLayout",
  data() {
    return {
      menuSelected: ''
    }
  },
  methods: {
    updateMenuSelected(){
      const url = window.location.href
      const arrUrl = url.split('//')
      const start = arrUrl[1].indexOf('/')
      let relUrl = arrUrl[1].substring(start)

      if (relUrl.indexOf('?') !== -1) {
        relUrl = relUrl.split('?')[0]
      }
      relUrl = relUrl.replace('#', '')
      this.menuSelected = relUrl
    },
  },
  mounted() {
    this.updateMenuSelected()
  }
}
</script>

<style scoped>
#center {
  width: auto !important;
}
</style>
