<template>
  <div
    id="center"
    style="min-height: 493px;"
  >
    <div
      class="register"
      id="state_login"
      style="min-height: 493px;"
    >
      <div class="register_title clearfix">
        <div class="pull-left">
          <span class="r-title">用户注册</span>
          <span class="r-tip">我已经注册，立即
            <router-link to="/login">登录</router-link>
          </span>
        </div>
        <div class="pull-right">
          <span
            class="s1"
            :class="step == 1 || step == 2 ? 'on' : ''"
          >添加手机号</span>
          <span
            class="s2"
            :class="step == 3 ? 'on' : ''"
          >填写账号信息</span>
          <span
            class="s3"
            :class="step == 4 ? 'on' : ''"
          >设置密码</span>
        </div>
      </div>
      <form
        class="register_form"
        id="itemForm"
      >
        <div class="reg-tab hide">
          <span class="tab tab1 on">手机号注册</span>
          <span class="tab tab2">邮箱注册</span>
        </div>
        <div
            id="step2"
            class="step_info"
            :style="step == 1 ? 'display: block;' : 'display: none;'"
        >
          <div class="register_item phone-reg">
            <input
                class="ipt"
                v-model="formInfo.mobile"
                type="text"
                name="phone"
                placeholder="请输入手机号"
            >
          </div>
          <div class="register_item email-reg hide">
            <input
                class="ipt"
                type="text"
                name="regEmail"
                placeholder="请输入邮箱地址"
            >
          </div>
          <div class="register_item">
            <input
                class="ipt"
                v-model="formInfo.code"
                type="text"
                name="code"
                placeholder="输入验证码"
            >
            <span
                class="code"
                id="testcode"
                @click="sendMsg"
                :style="isMsgSend ? 'opacity: 1; background: rgb(153, 153, 153);' : ''"
            >
              {{ this.isMsgSend ? '' + this.seconds + '秒' : '获取验证码' }}
            </span>
          </div>
          <div class="notice-agree">
            <label for="agreement">
              <input
                  type="checkbox"
                  id="agreement"
                  v-model="isAgree"
              >
              <p>我已阅读并同意</p>
            </label>
            <span @click="step=2">虚拟仿真实验教学创新联盟网站用户协议</span>
          </div>
          <div class="register_item">
            <div
                class="next-btn btn1"
                @click="verifyCode"
            >下一步</div>
          </div>
        </div>
        <div
          id="step1"
          :style="step == 2 ? 'display: block;' : 'display: none;'"
        >
          <div class="notice_info">
            <h2>虚拟仿真实验教学创新联盟网站用户协议</h2>
            <p>
              导言
            </p>
            <p>
              欢迎您使用虚拟仿真实验教学创新联盟网站相关服务！为了能正常使用本网站的相关服务，您应当阅读并遵守本《虚拟仿真实验教学创新联盟网站用户协议》（以下简称“本协议”）。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款，以及开通或使用某项服务的单独协议，并勾选“同意”选项。限制、免责条款可能以加粗形式提示您注意。
            </p>
            <p>
              本协议是您（以下简称“用户”）与虚拟仿真实验教学创新联盟网站（以下简称“网站”）之间在使用联盟网站服务之前、注册联盟网站账号时签署的协议。
            </p>
            <p>
              除非您已阅读并接受本协议所有条款，否则您无权使用本网站的相关服务。
            </p>
            <p>
              如果您未满18周岁，请在法定监护人的陪同下阅读本协议及其他上述协议，并特别注意未成年人使用条款。
            </p>
            <p class="notice_info_title">
              1.重要须知
            </p>
            <p>
              1.1用户完成全部注册程序的行为表示用户已经阅读本协议的全部条款，理解并同意本协议的条款所约定的权利及义务，并受本协议条款的约束。如用户对本协议的条款存有疑义,可暂停注册程序并向网站书面提出；否则，用户完成注册程序之后，应当遵守本协议的条款使用相关服务。
            </p>
            <p>
              1.2用户一经注册本网站账号，除非子频道要求单独开通权限，用户有权利用该账号使用网站各个频道的单项服务，当用户使用各单项服务时，用户的使用行为视为其对该单项服务的服务条款以及网站在该单项服务中发出的各类公告所声明的权利及义务的接受，即同意受此服务条款及公告权利义务的约束。用户应当对以其用户账号进行的所有活动和事件负法律责任。
            </p>
            <p>
              1.3联盟网站用户服务协议以及各个频道单项服务条款和公告可由本网站随时更新，可能会不断发布的关于本协议的相关补充协议、业务规则等内容。上述内容一经正式发布，即为本协议不可分割的组成部分，上述的更新或发布内容我们将不再另行通知。您在使用相关服务时,应关注并遵守其所适用的相关条款。
            </p>
            <p class="notice_info_title">
              2.关于账号
            </p>
            <p>
              2.1用户账号，仅限于在联盟网站上注册用户本人使用，禁止赠与、借用、租用、转让或售卖。如果本网站发现使用者并非账号初始注册人，有权在未经通知的情况下回收该账号而无须向该账号使用人承担法律责任，由此带来的包括并不限于用户通信中断、用户资料和数据清空等损失由用户自行承担。
            </p>
            <p>
              2.2用户应当妥善保管自己的账号、密码，用户就其账号及密码项下之一切活动负全部责任，包括用户数据的修改、所有的损失由用户自行承担。用户须重视账号密码保护。用户如发现他人未经许可使用其账号时应当立即通知本平台。
            </p>
            <p>
              2.3用户在账号丢失或遗忘密码后，可遵照联盟网站的申诉途径及时申诉请求找回账号，用户应不断提供能增加账号安全性的个人密码保护资料。用户可以凭初始注册资料向本网站申请找回账号，联盟网站的密码找回机制仅负责识别初始注册资料与系统记录资料的正确性，而无法识别申诉人是否系真正账号使用人。对用户因被他人冒名申诉而致的任何损失，本网站不承担任何责任，用户知晓账号及密码保管责任在于用户，本网站并不承诺账号丢失或遗忘密码后用户一定能通过申诉找回账号。
            </p>
            <p>
              2.4用户保证注册账号时填写的身份信息是真实的，任何非法、不真实、不准确的用户信息所产生的责任由用户承担。用户应不断更新注册资料，符合及时、详尽、真实、准确的要求。所有原始输入的资料将引用用户的账号注册资料。因用户注册信息不真实而引起的问题，以及对问题发生所带来的后果，本网站不负任何责任。
            </p>
            <p>
              2.5如用户违反法律法规、联盟网站各服务协议等规定，本网站有权根据相关规则进行违规判定，并采取相应限制或处罚措施，包括但不限于：限制或冻结用户账号的使用，限制或停止某项单独服务。
            </p>
            <p class="notice_info_title">
              3.用户不得从事的行为
            </p>
            <p>
              3.1利用联盟网站发表、传送、传播、存储危害国家安全、国家统一、社会稳定的内容，或侮辱诽谤、色情、暴力、引起他人不安及任何违反国家法律法规政策的内容或者设置含有上述内容的网名、角色名。
            </p>
            <p>
              3.2利用联盟网站发表、传送、传播、存储侵害他人知识产权、商业机密权、肖像权、隐私权等合法权利的内容。
            </p>
            <p>
              3.3进行任何危害计算机网络安全的行为，包括但不限于：使用未经许可的数据或进入未经许可的服务器/账户；未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；未经许可，企图探查、扫描、测试本“软件”系统或网络的弱点或实施其他破坏网络安全的行为；企图干涉、破坏本“软件”系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；伪造TCP/IP数据包名称或部分名称。
            </p>
            <p>
              3.4进行任何破坏联盟网站服务公平性或者其他影响应用正常秩序的行为，如主动或被动刷分、合伙作弊、使用外挂或者其他的作弊软件、利用BUG（“漏洞”或者“缺陷”）来获得不正当的非法利益，或者利用互联网或其他方式将外挂、作弊软件、BUG公之于众。
            </p>
            <p>
              3.5进行任何诸如发布广告、销售商品的商业行为，或者进行任何非法的侵害本网站利益的行为，如贩卖虚拟货币、外挂等。
            </p>
            <p>
              3.6进行其他任何违法以及侵犯其他个人、公司、社会团体、组织的合法权益的行为。
            </p>
            <p class="notice_info_title">
              4.联盟网站平台声明
            </p>
            <p>
              4.1用户须明白，在使用联盟网站服务可能存在有来自任何他人的包括威胁性的、诽谤性的、令人反感的或非法的内容或行为或对他人权利的侵犯（包括知识产权）的匿名或冒名的信息的风险，用户须自行承担以上风险，本网站对服务不作担保，不论是明确的或隐含的，包括所有有关信息真实性、适当性、适于某一特定用途、所有权和非侵权性的默示担保和条件，对因此导致任何因用户不正当或非法使用服务产生的直接、间接、偶然、特殊及后续的损害，不承担任何责任。
            </p>
            <p>
              4.2使用联盟网站服务必须遵守国家有关法律和政策等，维护国家利益，保护国家安全，并遵守本条款，对于用户违法或违反本协议的使用（包括但不限于言论发表、传送等）而引起的一切责任，由用户负全部责任。
            </p>
            <p>
              4.3联盟网站将运用各种安全技术和程序建立完善的管理制度来保护用户的个人信息，以使用户个人信息免受未经授权的访问、使用或披露。未经用户的同意，本网站不会对任何公司、组织和个人披露您的个人信息，但法律法规另有规定的除外。
            </p>
            <p>
              4.4用户理解联盟网站的服务同大多数互联网产品一样，易受到各种安全问题的困扰，包括但不限于：
            </p>
            <p>
              （1）在使用服务时透露详细个人资料，被不法分子利用，造成现实生活中的骚扰；
            </p>
            <p>
              （2）用户信息泄露或遭哄骗、破译密码；
            </p>
            <p>
              （3）下载安装的其他软件中含有“特洛伊木马”等病毒，威胁到个人计算机上信息和数据的安全，继而威胁对本服务的使用。对于发生上述情况的，用户应当自行承担责任。
            </p>
            <p>
              4.5用户明白，本网站为了服务整体运营的需要，有权在公告通知后修改或中断、中止或终止服务而无须通知用户，且无须向第三方负责或承担任何赔偿责任。
            </p>
            <p>
              4.6用户理解，互联网技术的不稳定性可能导致政府政策管制、病毒入侵、黑客攻击、服务器系统崩溃或者其他现今技术无法解决的风险发生，可能导致联盟网站服务中断或账号数据损失，此类非人为因素引起的损失由用户承担责任。
            </p>
            <p class="notice_info_title">
              5.知识产权
            </p>
            <p>
              5.1联盟网站的服务包括联盟网站、网页应用、软件以及内含的文字、图片、视频、音频等元素，联盟网站标志、标识以及专利权，本网站对此享有上述知识产权。
            </p>
            <p>
              5.2用户不得对联盟网站服务涉及的相关网页、应用、软件等产品进行反向工程、反向汇编、反向编译等。
            </p>
            <p>
              5.3用户只能在本协议以及相应的授权许可协议所授权的范围内使用联盟网站知识产权，未经授权超范围使用构成对联盟网站的侵权。
            </p>
            <p>
              5.4用户在使用联盟网站服务时发表、上传的文字、图片、视频、音频等用户原创的信息，此部分信息的知识产权归用户，但用户的发表、上传行为是对本网站的授权，用户确认其发表、上传的信息非独占性、永久性的授权，本平台有权对用户发表、上传的信息或作品在联盟网站上使用，亦有权在保障用户权益的将该权利授予第三方使用。
            </p>
            <p class="notice_info_title">
              6.个人隐私及保护条款
            </p>
            <p>
              6.1本网站在提供服务时可能会收集用户信息，网站会明确告知用户，这些信息包括但不限于用户姓名、性别、年龄、出生日期、身份证号、家庭住址、教育程度、公司情况、所属行业、兴趣爱好、所处地理位置、个人说明、手机软硬件信息、手机识别码等。
            </p>
            <p>
              6.2您了解并同意本网站在用户登录和使用联盟网站相关服务功能时收集用户的以上信息。当您在购买本平台列出的商品或服务时，您的信息将被提供商品服务的商家收集，您应自行承担此类信息披露的责任。
            </p>
            <p>
              6.3您了解并同意本网站收集用户的以上信息用于系统备份，识别用户身份信息，用户使用情况分析，形成教研大数据报告，协助用户处理突发情况，帮助用户解决使用中遇到的问题等用途。本网站承诺不以非法方式披露您的个人信息。
            </p>
            <p>
              6.4请用户注意勿在使用联盟网站服务中透露自己的各类财产账户、银行卡、信用卡、第三方支付账户及对应密码等重要资料，否则由此带来的任何损失由用户自行承担。
            </p>
            <p>
              6.5用户的账号、密码属于保密信息，本网站应当采取积极的措施保护用户账号、密码的安全。
            </p>
            <p>
              6.6用户的注册信息作为本平台的商业秘密进行保护。但用户同时明白，互联网的开放性以及技术更新非常快，由非联盟网站可控制的因素导致用户信息泄露的，本网站不承担责任。
            </p>
            <p>
              6.7用户在使用联盟网站服务时不可将自认为隐私的信息发表、上传至联盟网站，也不可将该等信息通过本网站的服务传播给其他人，因用户的行为引起的隐私泄露，由用户承担责任。
            </p>
            <p>
              6.8就下列相关事宜的发生，联盟网站不承担任何法律责任：
            </p>
            <p>
              （1）本网站根据法律规定或相关政府、司法机关的要求提供您的个人信息；
            </p>
            <p>
              （2）由于用户将用户密码告知他人或与他人共享注册账户，由此导致的任何个人信息的泄露，或其他非因本网站原因导致的个人信息的泄露；
            </p>
            <p>
              （3）任何由于黑客攻击、计算机病毒侵入造成的信息泄露；
            </p>
            <p>
              （4）因不可抗力导致的信息泄露；
            </p>
            <p>
              （5）更多联盟网站的隐私权保护政策，请用户查看网站信息。
            </p>
            <p>
              6.9您了解并同意联盟网站可依照法律或司法、行政机关的强制性命令对第三方披露您的个人信息且无须事先向您发出通知。
            </p>
            <p class="notice_info_title">
              7.其他条款
            </p>
            <p>
              7.1本协议的签署地点为北京市西城区，若用户与联盟网站发生争议，双方同意将争议提交北京市西城区人民法院诉讼解决。
            </p>
            <p>
              7.2本协议所有条款的标题仅为阅读方便，本身并无实际含义，不能作为本协议含义解释的依据。
            </p>
            <p>
              7.3本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
            </p>

          </div>
          <div class="register_item">
            <div
              class="next-btn btn1 return-btn"
              @click="step=1"
            >返回</div>
          </div>
        </div>
        <div
          id="step3"
          class="step_info"
          :style="step == 3 ? 'display: block;' : 'display: none;'"
        >
          <div class="register_item ">
            <input
              class="ipt"
              v-model="formInfo.username"
              type="text"
              name="name"
              placeholder="请输入用户名"
            >
            <span class="required">*</span>
          </div>
          <div class="register_item phone-reg">
            <input
              class="ipt"
              v-model="formInfo.email"
              type="text"
              name="email"
              placeholder="请输入邮箱"
            >
          </div>
          <div class="register_item ">
            <select
              class="ipt"
              name="role"
              v-model="formInfo.role"
            >
              <option value="">选择身份</option>
              <option value="3">教师</option>
              <option value="4">社会人士</option>
            </select>
            <span class="required">*</span>
          </div>
          <div class="ts-unit">
            <div
              class="register_item "
              v-show="formInfo.role != ''"
            >
              <select
                class="ipt"
                v-model="formInfo.province"
                name="province"
                id="province"
              >
                <option value="">选择省份</option>
                <option value="0">北京市</option>
                <option value="1">天津市</option>
                <option value="2">河北省</option>
                <option value="3">山西省</option>
                <option value="4">内蒙古自治区</option>
                <option value="5">辽宁省</option>
                <option value="6">吉林省</option>
                <option value="7">黑龙江省</option>
                <option value="8">上海市</option>
                <option value="9">江苏省</option>
                <option value="10">浙江省</option>
                <option value="11">安徽省</option>
                <option value="12">福建省</option>
                <option value="13">江西省</option>
                <option value="14">山东省</option>
                <option value="15">河南省</option>
                <option value="16">湖北省</option>
                <option value="17">湖南省</option>
                <option value="18">广东省</option>
                <option value="19">广西壮族自治区</option>
                <option value="20">海南省</option>
                <option value="21">重庆市</option>
                <option value="22">四川省</option>
                <option value="23">贵州省</option>
                <option value="24">云南省</option>
                <option value="25">西藏自治区</option>
                <option value="26">陕西省</option>
                <option value="27">甘肃省</option>
                <option value="28">青海省</option>
                <option value="29">宁夏回族自治区</option>
                <option value="30">新疆维吾尔自治区</option>
                <option value="31">新疆生产建设兵团</option>
                <option value="32">香港特别行政区</option>
                <option value="33">澳门特别行政区</option>
                <option value="34">台湾省</option>
              </select>

            </div>
            <div
              class="register_item "
              v-show="formInfo.role == '3'"
            >
              <select
                class="ipt"
                v-model="formInfo.school"
                name="school"
                id="school"
              >
                <option value="">选择学校</option>
                <option
                  v-for="item in dictSchool"
                  :key="item.id"
                  :value="item.id"
                >{{ item.title }}</option>
              </select>
            </div>
          </div>
          <div class="so-unit">
            <div
              class="register_item "
              v-show="formInfo.role == '4'"
            >
              <select
                class="ipt"
                v-model="formInfo.industry"
                name="industry"
                id="industry"
              >
                <option value="">选择行业</option>
                <option value="1">农、林、木、渔业</option>
                <option value="2">采矿业</option>
                <option value="3">制造业</option>
                <option value="4">电力、热力、燃气及水生产和供应</option>
                <option value="5">建筑业</option>
                <option value="6">批发和零售业</option>
                <option value="7">交通运输、仓储和邮政业</option>
                <option value="8">住宿和餐饮业</option>
                <option value="9">信息传输、软件和信息技术服务业</option>
                <option value="10">金融业</option>
                <option value="11">房地产业</option>
                <option value="12">租赁和商务服务业</option>
                <option value="13">科学研究和技术服务业</option>
                <option value="14">水利、环境和公共设施管理业</option>
                <option value="15">居民服务、修理和其他服务业</option>
                <option value="16">教育</option>
                <option value="17">卫生和社会工作</option>
                <option value="18">文化、体育和娱乐业</option>
                <option value="19">公共管理、社会保障和社会组织</option>
                <option value="20">国际组织</option>
              </select>
            </div>
          </div>
          <div class="register_item">
            <div
              class="pre-btn btn2"
              @click="step = 1"
            >上一步</div>
          </div>
          <div class="register_item">
            <div
              class="next-btn btn3"
              @click="verifyNameAndRole"
            >下一步</div>
          </div>
        </div>
        <div
          id="step4"
          class="step_info"
          :style="step == 4 ? 'display: block;' : 'display: none;'"
        >
          <div class="register_item">
            <input
              class="ipt"
              v-model="formInfo.password1"
              type="password"
              name="password"
              placeholder="请输入密码（8-64位）"
            >
            <span class="required">*</span>
          </div>
          <div class="register_item">
            <input
              class="ipt"
              v-model="formInfo.password2"
              type="password"
              name="password2"
              placeholder="再次输入密码"
            >
            <span class="required">*</span>
          </div>
          <div class="register_item">
            <div
              class="pre-btn btn4"
              @click="step = 3"
            >上一步</div>
          </div>
          <div class="register_item">
            <div
              class="next-btn btn5"
              id="submitBtn"
              @click="saveInfo"
            >提交注册</div>
          </div>
        </div>
      </form>
    </div>
    <div
      id="dialog_overlay"
      :style="classShowWindow"
    >
      <div id="dialog_bg"></div>
      <div id="dialog_panel">
        <div id="dialog_panel_bg"></div>
        <div id="dialog_title">提示</div>
        <div id="dialog_content">注册成功！</div>

        <div id="dialog_control">
          <a
            href="javascript:void(0)"
            class="button"
            @click="backHome"
          >返回首页</a>
          <a
            href="javascript:void(0)"
            class="button button1"
            @click="backLogin"
          >去登录</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Register",
  data() {
    return {
      step: 1,
      isMsgSend: false,
      interval: null,
      seconds: 0,
      formInfo: {
        id: 0,
        user_id: 0,
        mobile: '',
        code: '',
        username: '',
        name:'用户',
        email: '',
        role: '',
        province: '',
        school: '',
        industry: '',
        password1: '',
        password2: ''
      },
      dictSchool: [],
      isAgree:false,
      classShowWindow:{}
    }
  },
  computed: {
    verifyForm() {
      return {
        phone: this.formInfo.mobile,
        code: this.formInfo.code,
        type: 1 // 1 注册 2 忘记密码 3 绑定手机
      }
    },
    authUserForm() {
      return {
        username: this.formInfo.username,
        password1: this.formInfo.password1,
        password2: this.formInfo.password2
      }
    },
    userProfileForm() {
      return {
        user_id: this.formInfo.user_id,
        username: this.formInfo.username,
        mobile: this.formInfo.mobile,
        name: this.formInfo.name,
        email: this.formInfo.email,
        role: this.formInfo.role,
        province: this.formInfo.province,
        school: this.formInfo.role == '3' ? this.formInfo.school : '',
        industry: this.formInfo.role == '4' ? this.formInfo.industry : '',
      }
    }
  },
  methods: {
    async getSchool() {
      await this.$http.get('/allowany/school/', {
        params: {
          page: 1,
          size: 1000000,
          status: 1,
          isdel: 0
        }
      }).then(async (data) => {
        this.dictSchool = data.results
      }).catch((error) => {
        console.log(error)
        this.$parent.showMsg('获取学校失败！')
      })
    },
    async sendMsg() {
      if (this.isMsgSend == true){
        return;
      }
      await this.$http.get('/verify_code/', {
        params: this.verifyForm
      }).then(() => {

        this.isMsgSend = true
        this.seconds = 10
        this.interval = setInterval(() => {
          if(this.seconds == 0){
            clearInterval(this.interval)
            this.isMsgSend = false
          }
          this.seconds -= 1
        }, 1000)

      }).catch(() => {
        this.$parent.showMsg('发送验证码失败！')
      })

    },
    async verifyCode() {

      if(!(/^1[3456789]\d{9}$/.test(this.verifyForm.phone))){
        this.$layer.msg('请正确输入手机号码！')
        return false;
      }

      if(!this.verifyForm.code){
        this.$layer.msg('请输入验证码！')
        return false;
      }

      if(!this.isAgree){
        this.$layer.msg('请先同意协议')
        return
      }

      let isBind = false

      await this.$http.get('/allowany/userprofile/', {
        params: {
          eq_mobile: this.verifyForm.phone
        }
      }).then((data) => {
        if(data.count > 0){
          isBind = true
        }
      })

      if(isBind){
        this.$parent.showMsg('手机号码已绑定过其他账户，不能重复绑定！')
        return false;
      }

      await this.$http.post('/verify_code/', this.verifyForm).then((data) => {
        if (data.code == '200'){
          this.step = 3
        } else {
          this.$parent.showMsg(data.msg)
        }
      }).catch(() => {
        this.$parent.showMsg("验证码错误")
      })
    },
    async verifyNameAndRole() {
      if(!this.formInfo.username) {
        this.$parent.showMsg("请输入用户名")
        return;
      }
      if(!this.formInfo.role) {
        this.$parent.showMsg("请选择身份")
        return;
      }

      this.step++
    },
    async saveInfo() {
      // 保存前 数据检查
      if(!this.formInfo.password1){
        this.$parent.showMsg("请输入密码!")
        return false
      }
      if(!this.formInfo.password2){
        this.$parent.showMsg("请输入确认密码!")
        return false
      }
      if(this.formInfo.password1 != this.formInfo.password2){
        this.$parent.showMsg("两次输入密码不一致!")
        return false
      }

      await this.$http.post('/allowany/user/', this.authUserForm).then((data) => {
        if (data.id) {
          this.formInfo.user_id = data.id
          this.saveProfile()
        } else {
          this.$parent.showMsg("创建用户失败")
        }
      }).catch((error) => {
        this.$parent.showMsg(error.response.data)
      })
    },
    async saveProfile() {
      await this.$http.post('/allowany/userprofile/', this.userProfileForm).then((data) => {
        if (data.id) {
          this.classShowWindow={
            visibility: 'visible'
          }
        } else {
          this.$parent.showMsg('注册失败！')
          this.delInfo(this.formInfo.user_id)
        }
      }).catch((error) => {
        console.log(error)
        this.delInfo(this.formInfo.user_id)
        this.$parent.showMsg(error)
      })
    },
    nextStep(){
      if(!this.isAgree){
        this.$layer.msg('请先同意协议')
        return
      }
      this.step++
    },
    backHome(){
      this.$router.push('/')
      this.classShowWindow={
        visibility: 'hidden'
      }
    },
    backLogin(){
      this.$router.push('/login')
      this.classShowWindow={
        visibility: 'hidden'
      }
    }
  },
  mounted() {
    this.getSchool()
  }
}
</script>

<style scoped>
@import "../../assets/css/signup.css";

#step2,
#step3,
#step4,
.ts-unit,
.so-unit {
  width: 400px;
  display: block;
}
.step_info {
  width: 400px;
  margin: auto;
}

.ipt {
  width: 400px;
}

.notice_info {
  height: 500px;
  overflow-y: auto;
}
.notice_info h2 {
  text-align: center;
  margin-bottom: 10px;
}
.notice_info p {
  margin: 5px 0;
}
.notice_info .notice_info_title {
  font-weight: bold;
  color: #333;
  margin: 10px 0;
}
.notice-agree {
  text-align: center;
  margin: 10px 0;
  display: flex;
  justify-content: center;
}
.notice-agree label {
  display: flex;
  justify-content: center;
  align-items: center;
}
.notice-agree label p {
  margin-left: 5px;
}
.notice-agree span {
  color: #216acd;
  margin-left: 10px;
}
#dialog_control .button1 {
  color: #216acd;
}
.return-btn {
  width: 400px;
  margin: auto;
}
</style>
