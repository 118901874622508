<template>
  <div id="panel" class="panel" style="min-height: 696px;">
    <form id="searchSection" onsubmit="return false;">
      <input type="text" name="title" placeholder="会议主题" class="s-input separate" v-model="queryInfo.title">
      <input type="date" name="date" placeholder="日期" class="date_box" v-model="queryInfo.meetingTime">
      <input type="button" class="s-btn" value="搜索" @click="queryInfo.page=1,search()">
      <div class="add-btn" style="margin-left:0;" @click="showAddWindow">新建</div>
      <div class="search-line clearfix">
      </div>
    </form>
    <div id="list">
      <table v-if="this.tableData.length > 0">
        <tbody>
        <tr class="l_header">
          <th class="itemStatus">会议主题</th>
          <th class="itemStatus">会议时间</th>
          <th class="itemStatus">会议地点</th>
          <th class="action" width="180">操作</th>
        </tr>
        <tr class="item" v-for="item in tableData" :key="item.id">
          <td class="itemStatus">{{ item.title }}</td>
          <td class="itemStatus">{{ dateFormat('YYYY-mm-dd',item.meetingTime) }}</td>
          <td class="itemStatus">{{ item.address }}</td>
          <td class="action">
            <a class="edit" href="javascript:;"  @click="edit(item.id)">编辑</a>
            <router-link class="upload" :to="'/admin_uploadmeet?meet_id=' + item.id">上传会议资料</router-link><br>
            <router-link class="import" :to="'/admin_attendance?meet_id=' + item.id">导入参会人员</router-link>
            <a class="delete" href="javascript:;" @click="isDel(item.id)">删除</a>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="noitems" v-if="tableData.length == 0">没有数据</div>
    </div>

    <pagination :cur="pageInfo.page" :all="pageInfo.all" :totalData="pageInfo.totalData"
                :callback="pageCallback"></pagination>
                
    <!-- 新建窗口 -->
    <div class="form_overlay" id="form_overlay" :style="classShowAddWindow">
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">新建会议</div>
        <div class="form_content">
          <form id="itemForm">
            <div class="item">
              <span class="label"><i class="require">*</i>会议主题:</span>
              <input type="text" v-model="formInfo.title" name="title">
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>会议时间:</span>
              <input type="date" v-model="formInfo.meetingTime" name="date" placeholder="日期" 
                     style="width: 300px;height: 30px;border: 1px solid #ddd;">
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>会议地点:</span>
              <input type="text" v-model="formInfo.address" name="title">
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>封面图片:</span>
              <div class="item-box"><span class="button"  id="uploadImg" 
                style="top:0;left:0" @click="showImageWindow">{{formInfo.picPath?'更改图片':'添加图片'}}</span>
                <img v-if="formInfo.picPath" class="previewImg" :src="$store.state.wwwFileRootUrl + formInfo.picPath" alt=""><br>
                <span class="tips" :class="formInfo.picPath?'tips_Hover':'tips'">建议图片尺寸为180px*120px，图片格式支持jpg、png格式</span>
              </div>
            </div>
            <div class="item">
              <div id="preview"></div>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a href="javascript:;" class="button close" id="form_close" @click="hideAddWindow">取消</a>
          <a href="javascript:;" class="button" id="form_submit" @click="saveInfo">提交</a>
        </div>
      </div>
    </div>
    <!-- 上传图片窗口 -->
    <div id="dialog_overlay" class="" :style="classShowImageWindow">
      <div id="dialog_bg"></div>
      <div id="dialog_panel" style="margin-left: -137px; margin-top: -84px;" class="">
        <div id="dialog_panel_bg"></div>
        <div id="dialog_title">选择图片</div>
        <div id="dialog_content">
          <div class="imgType" style="margin-bottom:10px;" id="imgType">允许上传的图片格式：jpg</div>
          <div id="thumbPreview" v-show="showImgPreview"><img ref="headImg"></div>
          <div>
            <input id="imageFile" type="file" name="imageFile" accept="image/*"
                   ref="imageFile" @change="imageFileChange">
          </div>
        </div>
        <div id="dialog_control">
          <a href="javascript:void(0)" @click="hideImageWindow" id="dialog_close" class="button"
             style="display: inline-block;">取消</a>
          <a href="javascript:void(0)" @click="confirmImage" id="dialog_ok" class="button"
             style="display: inline-block;">确认</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../../components/Pagination";
export default {
  // 可用组件的哈希表
  components: {Pagination},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      queryInfo: {
        title: '',
        meetingTime: '',
        page: 1,
        size: 10,
      },
      formInfo: {
        id: 0,
        title: '',
        meetingTime: '',
        address: '',
        picPath: ''
      },
      tableData:[],
      tableDataCount:0,
      classShowAddWindow: {},
      classShowImageWindow: {},
      showImgPreview: false,
      uploaded_img: '',
    }
  },
  // 事件处理器
  methods: {
    // 分页
    async pageCallback(data) {
      this.queryInfo.page = data
      await this.search()
    },
    // 打开新建弹窗
    showAddWindow() {
      this.classShowAddWindow = {
        visibility: 'visible'
      }
    },
    // 关闭新建弹窗
    hideAddWindow(){
      this.classShowAddWindow = {}
      this.formInfo = {}
    },
    // 打开上传图片弹窗
    showImageWindow() {
      this.classShowImageWindow = {
        visibility: 'visible'
      }
    },
    // 关闭上传图片弹窗
    hideImageWindow() {
      this.classShowImageWindow = {}
    },
    confirmImage() {
      this.formInfo.picPath = this.uploaded_img
      this.hideImageWindow()
    },
    // 日期格式转换
    dateFormat(fmt, date) {
      let ret="";
      date=new Date(date);
      const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString() // 秒
      }
      for (let k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0')
          )
        }
      }
      return fmt
    },
    // 上传图片
    async imageFileChange() {
      const uploadUrl = '/info_files/' + this.$refs.imageFile.files[0].name
      await this.$http.put(uploadUrl, this.$refs.imageFile.files[0]).then((data) => {
        this.uploaded_img = data
        this.showImgPreview = true
        this.$refs.headImg.src = this.$store.state.wwwFileRootUrl + data
      }).catch(() => {
        this.$parent.$parent.showMsg('上传失败！')
      })
    },
    // 搜索
    async search() {
      await this.$http.get('/meeting/', {
        params: this.queryInfo
      }).then((data) => {
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$parent.$parent.showMsg('获取会议信息失败！')
      })
    },
    // 新建或编辑保存
    async saveInfo() {
      if(!this.formInfo.title){
        this.$parent.$parent.showMsg("请输入会议主题!")
        return false
      }
      if(!this.formInfo.meetingTime){
        this.$parent.$parent.showMsg("请选择会议时间!")
        return false
      }
      if(!this.formInfo.address){
        this.$parent.$parent.showMsg("请输入会议地点!")
        return false
      }
      if(!this.formInfo.picPath){
        this.$parent.$parent.showMsg("请上传封面图片!")
        return false
      }

      if(this.formInfo.id){
        let url = '/meeting/' + this.formInfo.id + '/'
        await this.$http.put(url, this.formInfo).then((data) => {
          if (data.id) {
            this.$parent.$parent.showMsg('修改成功！')
            this.formInfo = {}
            this.hideAddWindow()
            this.search()
          } else {
            this.$parent.$parent.showMsg('修改失败！')
          }
        }).catch(() => {
          this.$parent.$parent.showMsg('修改失败！')
        })
      }else{
        await this.$http.post('/meeting/', this.formInfo).then((data) => {
          if (data.id) {
            this.$parent.$parent.showMsg('添加成功！')
            this.formInfo = {}
            this.hideAddWindow()
            this.search()
            
          } else {
            this.$parent.$parent.showMsg('添加失败！')
          }
        }).catch(() => {
          this.$parent.$parent.showMsg('添加失败！')
        })
      }
    },
    // 编辑时查详情
    async edit(id) {
      const url = '/meeting/' + id + '/'
      await this.$http.get(url).then((data) => {
        this.formInfo = {
          id: id,
          title: data.title,
          meetingTime: this.getFormat(data.meetingTime),
          address: data.address,
          picPath: data.picPath
        }
        this.showAddWindow()
      }).catch(() => {
        this.$parent.$parent.showMsg('数据获取失败！');
      })
    },
    getFormat(time){
      var format = "";
      var nTime = new Date(time);
      format += nTime.getFullYear()+"-";
      format += (nTime.getMonth()+1)<10?"0"+(nTime.getMonth()+1):(nTime.getMonth()+1);
      format += "-";
      format += nTime.getDate()<10?"0"+(nTime.getDate()):(nTime.getDate());
      // format += "T";
      // format += nTime.getHours()<10?"0"+(nTime.getHours()):(nTime.getHours());
      // format += ":";
      // format += nTime.getMinutes()<10?"0"+(nTime.getMinutes()):(nTime.getMinutes());
      // format += ":00";
      return format;
    },
    // 删除确认框
    isDel(id) {
      this.$parent.$parent.showConfirm('确定要删除吗？', () => {
        this.delRow(id)
      });
    },
    async delRow(id) {
      const url = '/meeting/' + id + '/'
      await this.$http.put(url, {
        isdel: 1
      }).then(() => {
        this.$parent.$parent.showMsg('删除成功！');
        this.search()
      }).catch(() => {
        this.$parent.$parent.showMsg('删除失败！');
      })
    },
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () {
    this.search()
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {
    pageInfo() {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    }
  },
  // 自定义的侦听器
  watch: {}
}
</script>

<style scoped>
.date_box {
  border: 1px solid #e6e6e6;
  background-color: #f5f7fc;
  width: 150px;
  height: 32px;
  line-height: 32px;
  margin-right: 8px;
  padding-left: 8px;
}
.tips{
  position: relative;
  left: 110px;
  font-size: 12px;
  color: #aaaaaa;
}
.tips_Hover{
  top: 30px;
  height: 50px;
}
.previewImg{
  position: relative;
  left: -105px;
  top: 40px;
  width: 180px;
  height: 120px;
}
</style>
