<template>
  <div class="subRight">
    <div class="subRightTit">
      <span>联盟简介</span>
      <a href="/">首页</a>&gt;&gt;
      <a href="/www/introduction/intro">联盟介绍</a>&gt;&gt;联盟简介
    </div>
    <div class="con">
      <p>
        虚拟仿真实验教学创新联盟（以下简称"联盟"）是由高等院校、相关企事业单位和专家组织自愿组成的，在教育部高教司指导下开展虚拟仿真实验教学研究、咨询、指导、评估和服务的非盈利组织，2019年1月27日在清华大学正式成立。联盟是开放型组织，实行机构会员制，第一届理事长单位为清华大学。</p>
      <p>
        联盟的宗旨是推进现代信息技术与实验教学项目深度融合，拓展实验教学内容广度和深度、延伸实验教学时间和空间、提升实验教学质量和水平；推动形成专业布局合理、教学效果优良、开放共享有效的高等教育信息化实验教学项目新体系；促进实现学校教学、行业应用与技术创新的融合发展。</p>
      <p>
        欢迎广大高校和相关领域企业加入联盟，共同推动虚拟仿真实验教学项目的共享应用，参与制定虚拟仿真实验教学项目建设指导框架，实施实验教学队伍教师能力提升计划，建立实验体系认证标准，推进虚拟仿真实验教学产教融合。</p>
      <p>申请加入联盟的单位需要
        <router-link to="/www/apply" class="aBlueLine">线上填写申请表</router-link>，经联盟秘书处办公室初审通过后，下载打印并加盖单位公章，与相关资质资料请发送至邮箱vse2019@126.com。
      </p>
      <p>联盟秘书处办公室收到申请表后30日内提请秘书处讨论并作出决定。成员应遵守联盟章程，积极参与联盟及相关工作委员会的活动。企业会员需要缴纳会员费用2万元/年。</p>
      <p>&nbsp;</p>
      <p>联盟秘书处办公室联系人:王成涛</p>
      <p>电话：13681363100</p>
      <p>邮箱：wangchengtao@china-didac.com</p>
      <p>地址：北京市西城区大木仓胡同北一巷1号中国教学仪器设备有限公司202室</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Intro"
}
</script>

<style scoped>

</style>
