<template>
  <div id="center" class="clearfix">
    <div id="left-menu" class="pull-left"></div>
    <div id="content1" class="pull-right">
      <div class="main-wrap border-box">
        <div class="main-left border-box border bg-white pull-left heightFull">

          <div class="title-wrap">
            <p class="title">指南列表</p>
          </div>

          <form id="searchSection" onsubmit="return false;">
            <div class="choice m-t-md" style="width:100%">
              <input type="text" name="f_direction" class="s-input separate half" placeholder="实验项目方向"
                     v-model="queryInfo.direction">
              <input type="text" name="f_courseTitle" class="s-input separate half" placeholder="课程名称"
                     v-model="queryInfo.courseTitle">
              <input type="button" class="s-btn" value="搜索" @click="search">
            </div>
          </form>
          <div class="content-wrap border-box min-padding">
            <div class="scroll-box border-box">
              <ul id="listBox">
                <li :class="index == selectIndex ? 'on' : ''" v-for="(item, index) in tableData" :key="item.id"
                    @click="changeSelected(index)">
                  <div class="desc-wrap">
                    <p class="p1 text-overflow">{{ item.direction }}</p>
                    <p class="p2 text-overflow">
                      <span class="m-r-md">{{ num_format(item.categorie02, item.id) }}</span>
                      <span>{{ item.courseTitle }}</span>
                    </p>
                  </div>
                  <span class="status-label bg-orange">{{ status_format(item.status) }}</span>
                </li>
              </ul>

              <pagination-simple style="margin-top: 0px"
                                 :cur="pageInfo.page"
                                 :all="pageInfo.all"
                                 :totalData="pageInfo.totalData"
                                 :callback="pageCallback">
              </pagination-simple>

            </div>
          </div>
        </div>
        <div class="main-right border-box pull-right heightFull">
          <button class="btn-toggle" id="toggleBtn" @click="toList"><i class="iconfont m-r-xs"></i>切换列表模式</button>
          <div class="top-box m-t-md border bg-white"
               v-if="$route.query.status!=2 && $route.query.status!=4 && $route.query.status!=5">
            <div class="title-wrap">
              <p class="title">操作</p>
            </div>
            <div class="btn-operates">
              <button class="btn-edit" id="editBtn"
                      v-if="[0, 1, 3, 5].indexOf(this.queryInfo.status) >= 0 && this.formInfo.id && !this.editMode"
                      @click="toEdit">
                <i class="iconfont m-r-xs"></i>编辑
              </button>
              <button class="btn-del" id="deleteBtn" @click="askDel"
                      v-if="this.queryInfo.status == 0 && this.formInfo.id && !this.editMode">删除
              </button>
              <button class="btn-del" id="closeBtn" @click="askClose"
                      v-if="(this.queryInfo.status == 1 || this.queryInfo.status == 5) && this.formInfo.id && !this.editMode && isShow()">
                关闭
              </button>
              <button class="btn-del" id="openBtn" @click="askOpen"
                      v-if="this.queryInfo.status == 2 && this.formInfo.id && !this.editMode"><i
                class="iconfont m-r-xs"></i>开启
              </button>

              <button class="btn-edit" v-if="this.editMode" @click="saveEdit"><i class="iconfont m-r-xs"></i>保存
              </button>
              <button class="btn-cancel" v-if="this.editMode" @click="cancelEdit">取消</button>

            </div>
          </div>
          <div class="bottom-box-wrap" style="height: 350px">
            <div class="bottom-box border bg-white border-box">
              <div class="title-wrap">
                <p class="title">意见</p>
              </div>
              <div class="textarea-wrap">
                <textarea placeholder="" v-if="this.queryInfo.status == 3" v-model="formInfo.feedback"
                          style="height: 80px"></textarea>
                <textarea placeholder="" v-if="this.queryInfo.status != 3" v-model="formInfo.feedback"
                          disabled></textarea>
              </div>
              <div v-if="isShow()">
                <button class="btn-del" v-if="this.queryInfo.status == 3 && this.formInfo.id" style="width: 100px;"
                        @click="askDiss">设为不通过
                </button>
              </div>
              <div class="btn-group m-t-md">
                <button class="btn-prev pull-left" id="prevBtn" @click="prevRow">上一条</button>
                <button class="btn-next pull-right" id="nextBtn" @click="nextRow">下一条</button>
              </div>
            </div>
          </div>
        </div>
        <div class="main-middle-wrap heightFull">
          <div class="main-middle border-box border bg-white heightFull">
            <div class="title-wrap">
              <p class="title">指南详情</p>
            </div>
            <div class="content-wrap border-box">
              <div class="scroll-box border-box" id="detailContentBox">
                <div class="group-wrap">
                  <div class="item-group">
                    <span class="text1">编号：</span>
                    <span class="text2">{{ num_format(formInfo.categorie02, formInfo.id) }}</span>
                  </div>
                  <div class="item-group">
                    <span class="text1">创建时间：</span>
                    <span class="text2">{{ formInfo.created ? formInfo.created.substr(0, 10) : '' }}</span>
                  </div>
                  <div class="item-group">
                    <span class="text1">创建人：</span>
                    <span class="text2">{{ formInfo.owner }}</span>
                  </div>
                </div>
                <div class="group-wrap">
                  <div class="item-group">
                    <span class="text1">课程名称：</span>
                    <span class="text2">{{ formInfo.courseTitle }}</span>
                  </div>
                </div>
                <div class="group-wrap">
                  <div class="item-group">
                    <span class="text1">领域：</span>
                    <span class="text2">{{ formInfo.categorie01 in category_dict ? category_dict[formInfo.categorie01] : '无' }}</span>
                  </div>
                  <div class="item-group">
                    <span class="text1">专业类：</span>
                    <span class="text2">{{ formInfo.categorie02 in category_dict ? category_dict[formInfo.categorie02] : '无' }}</span>
                  </div>
                  <div class="item-group">
                    <span class="text1">适用专业：</span>
                    <span class="text2">{{ formInfo.categorie03 in category_dict ? category_dict[formInfo.categorie03] : '无' }}</span>
                  </div>
                </div>
                <div class="group-wrap">
                  <div class="item-group">
                    <span class="text1">课程类型：</span>
                    <span class="text2">{{ formInfo.courseType }}</span>
                  </div>
                  <div class="item-group">
                    <span class="text1">实验类型：</span>
                    <span class="text2">{{ formInfo.experimentType }}</span>
                  </div>
                  <div class="item-group">
                    <span class="text1">建议学时：</span>
                    <span class="text2">{{ formInfo.hours }}</span>
                  </div>
                </div>
                <div class="item-group m-b-md"><span class="text1">实验项目方向：</span><span
                  class="text2">{{ formInfo.direction }}</span></div>
                <div class="item-group m-b-md">
                  <span class="text1">必要性：</span>
                  <span class="text2"> {{ formInfo.necessity }}</span>
                </div>
                <div class="item-group m-b-md">
                  <span class="text1">内容简介：</span>
                  <span class="text2">{{ formInfo.brief }}</span>
                </div>
                <div class="item-group m-b-md">
                  <span class="text1">建议实验目的：</span>
                  <span class="text2">{{ formInfo.suggest }}</span>
                </div>
                <div class="item-group"><span class="text1">备注：</span><span class="text2">{{ formInfo.remarks }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaginationSimple from "../../components/PaginationSimple";

export default {
  name: "AdminGuidDetail",
  components: {
    PaginationSimple
  },
  data() {
    return {
      queryInfo: {
        page: 1,
        size: 5,
        ownerId: null,
        direction: '',
        courseTitle: '',
        status: null,
        categorie01: null,
        categorie02: null
      },
      formInfo: {
        id: 0,
        courseTitle: '',
        categorie01: '',
        categorie02: '',
        categorie03: '',
        courseType: '',
        experimentType: '',
        hours: '',
        direction: '',
        necessity: '',
        brief: '',
        suggest: '',
        remarks: '',
        feedback: '',
        status: 0,
        created: '',
        lastUpdated: '',
        isdel: 0,
        ownerId: 0,
        owner: '',
      },
      tableData: [],
      tableDataCount: 0,
      selectIndex: 0,
      category_dict: {},
      editMode: false
    }
  },
  methods: {
    toList() {
      this.$router.go(-1)
    },
    getUrlKey(name) {
      let url = window.location.href;
      let dz_url = url.split('#')[0];
      if (dz_url.split('?').length <= 1) {
        return ''
      }
      let cs_arr = dz_url.split('?')[1].split('&');
      let cs = {};
      for (let i = 0; i < cs_arr.length; i++) {
        cs[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
      }
      return cs[name]
    },
    async get_category() {
      await this.$http.get('/categories/', {
        params: {
          page: 1,
          size: 1000,
        }
      }).then(async (data) => {
        let temp_dict = {}
        for (let i = 0; i < data.results.length; i++) {
          temp_dict[data.results[i]['code']] = data.results[i]['title']
        }
        this.category_dict = temp_dict
      })
    },
    async search() {
      let role = parseInt(this.$store.state.userInfo.role)
      if (parseInt(this.getUrlKey('type')) == 1){
        // 个人中心 根据创建人查询
        this.queryInfo.ownerId = this.$store.state.userInfo.user_id
      } else if (role == 0 || role == 1 || role == 5) {
        console.log('query all')
      } else if (role == 6 || role == 7) {
        this.queryInfo.categorie01 = null
        this.queryInfo.categorie02 = null

        const url = '/userprofile/' + this.$store.state.userInfo.profile_id + '/'
        await this.$http.get(url).then(async (data) => {
          console.log(data)

          if (role == 6){
            if (data.categorie01) {
              this.queryInfo.categorie01 = data.categorie01
            } else {
              // 领域管理员 未设置领域时 不查询数据
              this.queryInfo.categorie01 = -1
            }
          }

          if (role == 7){
            if (data.categorie02) {
              this.queryInfo.categorie02 = data.categorie02
            } else {
              // 专业类管理员 未设置专业类时 不查询数据
              this.queryInfo.categorie02 = -1
            }
          }

        })
      } else if (role == 3) {
        this.queryInfo.ownerId = this.$store.state.userInfo.user_id
      } else {
        return false;
      }

      await this.$http.get('/guide/', {
        params: this.queryInfo
      }).then((data) => {
        this.tableData = data.results
        this.tableDataCount = data.count

        if (data.results.length > 0) {
          this.formInfo = data.results[0]
        } else {
          this.formInfo = {
            id: 0,
            courseTitle: '',
            categorie01: '',
            categorie02: '',
            categorie03: '',
            courseType: '',
            experimentType: '',
            hours: '',
            direction: '',
            necessity: '',
            brief: '',
            suggest: '',
            remarks: '',
            feedback: '',
            status: 0,
            created: '',
            lastUpdated: '',
            isdel: 0,
            ownerId: 0,
            owner: '',
          }
        }

      }).catch((data) => {
        // this.$parent.showMsg('获取信息失败！')
        console.log(data)
      })
    },

    async pageCallback(data) {
      this.queryInfo.page = data
      await this.search()
      this.selectIndex = 0
    },
    num_format(category, id) {
      let res = ''
      res += category
      for (let i = 0; i < 5 - id.toString().length; i++) {
        res += '0'
      }
      res += id.toString()
      return res
    },
    changeSelected(index) {
      this.selectIndex = index
      this.formInfo = this.tableData[this.selectIndex]
    },
    async prevRow() {
      if (this.selectIndex > 0) {
        this.selectIndex -= 1
        this.formInfo = this.tableData[this.selectIndex]
      } else if (this.selectIndex == 0 && this.queryInfo.page > 1) {
        this.queryInfo.page -= 1
        await this.search()
        this.selectIndex = 4
      }
    },
    async nextRow() {
      if (this.selectIndex + 1 == this.tableData.length && this.queryInfo.page == Math.ceil(this.tableDataCount / this.queryInfo.size)) {
        // 最后一页 最后一个
        return
      }
      if (this.selectIndex < this.queryInfo.size - 1) {
        this.selectIndex += 1
        this.formInfo = this.tableData[this.selectIndex]
      } else if (this.selectIndex == 4 && this.queryInfo.page < Math.ceil(this.tableDataCount / this.queryInfo.size)) {
        this.queryInfo.page += 1
        await this.search()
        this.selectIndex = 0
      }
    },
    status_format(status) {
      return this.$store.state.guid_status_dict[status]
    },
    toEdit() {
      let oUrl = ''
      if (this.$route.query.type == 1) {
        oUrl = '/admin_info_edit?id='
      } else if (this.$route.query.type == 2) {
        oUrl = '/admin_guid_edit?id='
      }
      window.location.href = oUrl + this.formInfo.id + '&state=2' + '&num=' + this.num_format(this.formInfo.categorie02, this.formInfo.id)
    },
    async delRow(id) {
      const url = '/guide/' + id + '/'
      await this.$http.put(url, {
        isdel: 1
      }).then(() => {
        this.$parent.showMsg('删除成功！');
        this.search()
        this.selectIndex = 0
      }).catch(() => {
        this.$parent.showMsg('删除失败！');
      })
    },
    askDel() {
      this.$parent.showConfirm('确定要删除吗？', () => {
        this.delRow(this.formInfo.id)
      });
    },
    async changeStatus(id, status) {
      const url = '/guide/' + id + '/'
      await this.$http.put(url, {
        status: status
      }).then(() => {
        this.$parent.showMsg('操作成功！');
        this.search()
        this.selectIndex = 0
      }).catch((error) => {
        console.log(error)
        this.$parent.showMsg('操作失败！');
      })
    },
    askClose() {
      this.$parent.showConfirm('确定要关闭吗？', () => {
        this.changeStatus(this.formInfo.id, 2)
      });
    },
    askOpen() {
      this.$parent.showConfirm('确定要开启吗？', () => {
        this.changeStatus(this.formInfo.id, 1)
      });
    },
    async doDiss(id) {
      const url = '/guide/' + id + '/'
      await this.$http.put(url, {
        status: 4,
        feedback: this.formInfo.feedback
      }).then(() => {
        this.$parent.showMsg('操作成功！');
        this.search()
        this.selectIndex = 0
      }).catch((error) => {
        console.log(error)
        this.$parent.showMsg('操作失败！');
      })
    },
    askDiss() {
      this.$parent.showConfirm('确定要设为不通过吗？', () => {
        this.doDiss(this.formInfo.id, 1)
      });
    },
    saveEdit() {
      this.editMode = false
    },
    cancelEdit() {
      this.editMode = false
    },
    isShow(){
      if(['0' ,'1', '5'].indexOf(this.localUser.role) >= 0){
        return 1
      }else{
        return 0
      }
    }
  },
  computed: {
    pageInfo() {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    },
    localUser() {
      return this.$store.state.userInfo
    }
  },
  created() {
  },
  mounted() {
    let status = this.getUrlKey('status')
    if (status) {
      this.queryInfo.status = parseInt(status)
    }
    setTimeout(() => {
      this.get_category()
      this.search()
    }, 100);
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {
    this.formInfo = {
      id: 0,
      courseTitle: '',
      categorie01: '',
      categorie02: '',
      categorie03: '',
      courseType: '',
      experimentType: '',
      hours: '',
      direction: '',
      necessity: '',
      brief: '',
      suggest: '',
      remarks: '',
      feedback: '',
      status: 0,
      created: '',
      lastUpdated: '',
      isdel: 0,
      ownerId: 0,
      owner: '',
    }
  },

  // 自定义的侦听器
  watch: {}
}
</script>

<style scoped>
body {
  background: #F3F3F3;
}

#content1 {
  float: none;
}

.main-wrap {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 40px;
  height: 650px;
}

.heightFull {
  height: 100%;
}

.bg-white {
  background: #fff;
}

.border {
  border: 1px solid #EBEBEB;
}

.title-wrap {
  padding-bottom: 20px;
  border-bottom: 1px solid #F3F3F3;
  position: relative;
  z-index: 2;
  text-align: left;
}

.title-wrap .title {
  font-size: 18px;
  padding-left: 10px;
  border-left: 3px solid #4C74E8;
  line-height: 20px;
}

#searchSection {
  position: relative;
  z-index: 2;
}

#searchSection .choice {
  overflow: hidden;
}

#searchSection input.half {
  width: 136px;
  box-sizing: border-box;
}

#searchSection .s-select {
  width: 137px;
}

#searchSection .s-input, #searchSection .s-select, #searchSection .s-checkbox {
  float: left;
}

#searchSection .s-btn {
  box-sizing: border-box;
  height: 32px;
  line-height: 32px;
}

/*#searchSection input.half:nth-child(2) {*/
/*    margin-right:0;*/
/*}*/

.main-left {
  width: 410px;
  padding: 30px;
  position: relative;
}

.main-left .content-wrap {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding: 180px 30px 0 30px;
}

.main-left .content-wrap.min-padding {
  padding-top: 140px;
}

.main-left .content-wrap .scroll-box {
  height: 100%;
  overflow-x: auto;
}

.main-left ul li {
  background: #F5F7FC;
  border: 1px solid #EEF1F1;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
  padding: 10px 0;
  cursor: pointer;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.main-left ul li.on {
  border-color: #dee7ff;
  background-color: #dee7ff;
}

.main-left ul li .desc-wrap {
  padding-right: 80px;
  font-size: 14px;
  color: #666;
  padding-left: 10px;
}

.main-left ul li .desc-wrap .p1 {
  color: #333;
  font-size: 16px;
}

.main-left ul li .status-label {
  position: absolute;
  right: 10px;
  top: 25px;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  background: #E45800;
  color: #fff;
  border-radius: 15px;
}

.main-right {
  width: 230px;
  position: relative;
  text-align: center;
}

.main-right .btn-toggle {
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #1398BC;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  z-index: 22;
}

.main-right .top-box {
  padding: 30px;
}

.main-right .top-box {
  position: relative;
  z-index: 22;
}

.main-right .btn-edit {
  width: 140px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  background: #1F46A8;
  border: none;
  color: #fff;
  font-size: 16px;
  margin-top: 20px;
  border-radius: 4px;
  cursor: pointer;
}

.main-right .textarea-wrap {
  background: #F5F7FC;
  border-radius: 10px;
  padding: 10px;
  margin-top: 20px;
}

.main-right .textarea-wrap textarea {
  width: 100%;
  height: 140px;
  background: none;
  border: none;
  max-width: 100%;
  max-height: 180px;
  font-family: inherit;
}

.main-right .btn-group button {
  height: 38px;
  line-height: 38px;
  width: 74px;
  border: none;
  color: #fff;
  background: #1F46A8;
  border-radius: 4px;
  cursor: pointer;
}

.main-right .btn-set {
  background: #D7413D;
  color: #fff;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.main-right .bottom-box-wrap {
  position: absolute;
  height: 100%;
  width: 100%;
  margin-top: 20px;
  /* top: 0; */
  left: 0;
  /* padding-top: 250px; */
  box-sizing: border-box;
  z-index: 1;
}

.main-right .bottom-box-wrap .bottom-box {
  padding: 30px;
  height: 100%;
  overflow-y: auto;
}

.main-middle-wrap {
  padding: 0 250px 0 430px;
  width: 100%;
  box-sizing: border-box;
}

.main-middle {
  padding: 30px;
  position: relative;
}

.main-middle .content-wrap {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding: 70px 30px 0 30px;
}

.main-middle .content-wrap .scroll-box {
  height: 100%;
  overflow-x: auto;
  padding: 10px 0;
}

.group-wrap {
  overflow: hidden;
  margin-bottom: 10px;
}

.group-wrap .item-group {
  float: left;
  width: 216px;
  font-size: 14px;
  color: #000;
}

.item-group .text1 {
  color: #666;
}

.cN-pagination {
  padding: 0;
}

.please {
  font-size: 14px;
  color: #828282;
  text-align: center;
  margin-top: 20px;
}

.btn-operates {
  width: 100%;
}

.btn-operates:after {
  content: '';
  display: table;
  clear: both;
  height: 0;
}

.btn-operates button {
  display: inline-block;
}

.btn-operates .btn-edit {
  width: 78px;
}

.btn-del {
  width: 78px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  background: #E45800;
  border: none;
  color: #fff;
  font-size: 16px;
  margin-left: 7px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

.btn-cancel {
  width: 78px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  background: #8f8f8f;
  border: none;
  color: #fff;
  font-size: 16px;
  margin-left: 7px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

</style>
