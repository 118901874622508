<template>
  <div id="panel" class="panel" style="min-height: 696px;">
    <form id="searchSection" onsubmit="return false;">
      <div class="choice" style="width:100%">
        <select name="status" class="s-select separate" v-model="queryInfo.status">
          <option value="">选择发布状态</option>
          <option value="0">未发布</option>
          <option value="1">已发布</option>
        </select>
        <select name="province" class="s-select separate" v-model="queryInfo.province">
          <option value="">选择省份</option>
          <option value="0">北京市</option>
          <option value="1">天津市</option>
          <option value="2">河北省</option>
          <option value="3">山西省</option>
          <option value="4">内蒙古自治区</option>
          <option value="5">辽宁省</option>
          <option value="6">吉林省</option>
          <option value="7">黑龙江省</option>
          <option value="8">上海市</option>
          <option value="9">江苏省</option>
          <option value="10">浙江省</option>
          <option value="11">安徽省</option>
          <option value="12">福建省</option>
          <option value="13">江西省</option>
          <option value="14">山东省</option>
          <option value="15">河南省</option>
          <option value="16">湖北省</option>
          <option value="17">湖南省</option>
          <option value="18">广东省</option>
          <option value="19">广西壮族自治区</option>
          <option value="20">海南省</option>
          <option value="21">重庆市</option>
          <option value="22">四川省</option>
          <option value="23">贵州省</option>
          <option value="24">云南省</option>
          <option value="25">西藏自治区</option>
          <option value="26">陕西省</option>
          <option value="27">甘肃省</option>
          <option value="28">青海省</option>
          <option value="29">宁夏回族自治区</option>
          <option value="30">新疆维吾尔自治区</option>
          <option value="31">新疆生产建设兵团</option>
          <option value="32">香港特别行政区</option>
          <option value="33">澳门特别行政区</option>
          <option value="34">台湾省</option>
        </select>
        <select name="level" class="s-select separate" v-model="queryInfo.level">
          <option value="">选择教育层次</option>
          <option value="0">本科</option>
          <option value="1">专科</option>
        </select>
        <input type="text" name="title" class="s-input separate" placeholder="学校名称" v-model="queryInfo.title">
        <input type="text" name="code" class="s-input separate" placeholder="学校代码" v-model="queryInfo.code">
        <input type="button" class="s-btn" value="搜索" @click="search">
      </div>
      <hr>
      <div class="add-btn" @click="showAddWindow">新建</div>
      <div class="import-btn" id="batchUpload" @click="showUploadWindow">导入</div>
    </form>
    <div id="list">
      <table v-if="this.tableData.length > 0">
        <tbody>
        <tr class="l_header">
          <th class="itemStatus">学校名称</th>
          <th class="itemStatus">学校代码</th>
          <th class="itemStatus">省市</th>
          <th class="itemStatus">教育层次</th>
          <th class="itemStatus">状态</th>
          <th class="action" width="180">操作</th>
        </tr>
        <tr class="item" v-for="item in tableData" :key="item.id">
          <td class="itemStatus">{{ item.title }}</td>
          <td class="itemStatus">{{ item.code }}</td>
          <td class="itemStatus">{{ provinceText(item.province) }}</td>
          <td class="itemStatus">{{ levelText(item.level) }}</td>
          <td :class="'itemStatus itemStatus' + item.status">{{ statusText(item.status) }}</td>
          <td class="action">
            <a v-if="item.status==1" class="nopublish" href="javascript:;" @click="unpublish(item.id)">取消发布</a>
            <a v-if="item.status==0" class="publish" href="javascript:;" @click="publish(item.id)">发布</a>
            <a class="edit" href="javascript:;" @click="querySingle(item.id)">编辑</a>
            <a class="delete" href="javascript:;" @click="askDel(item.id)">删除</a>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="noitems" v-if="tableData.length == 0">没有数据</div>
    </div>

    <pagination :cur="pageInfo.page" :all="pageInfo.all" :totalData="pageInfo.totalData"
                :callback="pageCallback"></pagination>

<!--    <ul id="pagination" class="cN-pagination">-->
<!--      <li class="selected">1</li>-->
<!--      <span class="page_total">共<b>1</b>条</span>-->
<!--    </ul>-->

    <div class="form_overlay" id="form_overlay" :style="classShowAddWindow">
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">添加/编辑学校</div>
        <div class="form_content">
          <form id="itemForm">
            <div class="item">
              <span class="label"><i class="require">*</i>学校名称:</span>
              <input type="hidden" v-model="formInfo.id">
              <input type="text" name="title" v-model="formInfo.title">
            </div>
            <div class="item">
              <span class="label"><i class="require"></i>学校英文名称:</span>
              <input type="text" name="en_title" v-model="formInfo.en_title">
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>学校代码:</span>
              <input type="text" name="code" v-model="formInfo.code">
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>省份:</span>
              <select name="province" v-model="formInfo.province">
                <option value="">选择省份</option>
                <option value="0">北京市</option>
                <option value="1">天津市</option>
                <option value="2">河北省</option>
                <option value="3">山西省</option>
                <option value="4">内蒙古自治区</option>
                <option value="5">辽宁省</option>
                <option value="6">吉林省</option>
                <option value="7">黑龙江省</option>
                <option value="8">上海市</option>
                <option value="9">江苏省</option>
                <option value="10">浙江省</option>
                <option value="11">安徽省</option>
                <option value="12">福建省</option>
                <option value="13">江西省</option>
                <option value="14">山东省</option>
                <option value="15">河南省</option>
                <option value="16">湖北省</option>
                <option value="17">湖南省</option>
                <option value="18">广东省</option>
                <option value="19">广西壮族自治区</option>
                <option value="20">海南省</option>
                <option value="21">重庆市</option>
                <option value="22">四川省</option>
                <option value="23">贵州省</option>
                <option value="24">云南省</option>
                <option value="25">西藏自治区</option>
                <option value="26">陕西省</option>
                <option value="27">甘肃省</option>
                <option value="28">青海省</option>
                <option value="29">宁夏回族自治区</option>
                <option value="30">新疆维吾尔自治区</option>
                <option value="31">新疆生产建设兵团</option>
                <option value="32">香港特别行政区</option>
                <option value="33">澳门特别行政区</option>
                <option value="34">台湾省</option>
              </select>
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>教育层次:</span>
              <select name="level" v-model="formInfo.level">
                <option value="">选择教育层次</option>
                <option value="0">本科</option>
                <option value="1">专科</option>
              </select>
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>学校属性:</span>
              <select name="attr" v-model="formInfo.attr">
                <option value="">选择学校属性</option>
                <option value="0">部属院校</option>
                <option value="1">军队院校</option>
                <option value="2">普通院校</option>
              </select>
            </div>
            <div class="item">
              <span class="label">备注说明:</span>
              <textarea class="simple" name="remark" v-model="formInfo.remark"></textarea>
            </div>
            <div class="item">
              <span class="label">联系人:</span>
              <input type="text" name="contacts" v-model="formInfo.contacts">
            </div>
            <div class="item">
              <span class="label">电话:</span>
              <input type="text" name="phone" v-model="formInfo.phone">
            </div>
            <div class="item">
              <span class="label">学校介绍:</span>
              <textarea class="simple" name="brief" v-model="formInfo.brief"></textarea>
            </div>
            <div class="item">
              <span class="label">学校网址:</span>
              <input type="text" name="url" v-model="formInfo.url">
            </div>
            <div class="item">
              <span class="label">图片大小:</span>
              <div>200px * 200px</div>
            </div>
            <div class="item">
              <span class="label">校徽图片:</span>
              <div class="item-box">
                <input name="logoPath" type="text" id="imgurl" disabled="disabled" v-model="formInfo.logoPath">
                <span class="button" id="uploadImg" @click="showImageWindow">添加图片</span>
              </div>
            </div>
            <div class="item">
              <div id="preview" v-show="formPreviewShow">
                <img :src="formPreviewSrc" height="50" style="margin-left: 10px">
              </div>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a href="javascript:;" class="button close" id="form_close" @click="hideAddWindow">取消</a>
          <a href="javascript:;" class="button" id="form_submit" @click="saveInfo">提交</a>
        </div>
      </div>
    </div>

    <div class="form_overlay" :style="classShowImageWindow">
      <div id="dialog_bg"></div>
      <div id="dialog_panel" style="margin-left: -137px; margin-top: -84px;" class="">
        <div id="dialog_panel_bg"></div>
        <div id="dialog_title">选择图片</div>
        <div id="dialog_content">
          <div class="imgType" style="margin-bottom:10px;" id="imgType">允许上传的图片格式：jpg</div>
          <div id="thumbPreview" v-show="showImgPreview"><img ref="headImg"></div>
          <div>
            <input id="imageFile" type="file" name="imageFile" accept="image/*"
                   ref="imageFile" @change="imageFileChange">
          </div>
        </div>
        <div id="dialog_control">
          <a href="javascript:void(0)" @click="hideImageWindow" id="dialog_close" class="button"
             style="display: inline-block;">取消</a>
          <a href="javascript:void(0)" @click="confirmImage" id="dialog_ok" class="button"
             style="display: inline-block;">确认</a>
        </div>
      </div>
    </div>

    <div class="form_overlay" :style="classShowUploadWindow">
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">批量上传</div>
        <div class="form_content">
          <form id="uploadForm">
            <div class="item">
              <span class="label" style="width: auto"><i class="require"></i>模版创建规则:</span>
              <div class="item-box divLabel gray">
                <div class="normal-width">
                  <p>1. 文件格式为“逗号分隔”的CSV文件；</p>
                  <p>2. 文件编码必须为ANSI编码；</p>
                  <p>3. 上传文档转换完毕后，请使用记事本打开，查看是否含有空数据，如果含有空数据，则需删除；</p>
                  <p>4. 上传文档中内容不能含有西文逗号；</p>
                  <p>5. CSV文件生成，可使用excel另存为“逗号分隔的CSV文件”；</p>
                  <p>6. 文件编码，可使用记事本打开CSV，另存为ANSI编码。</p>
                </div>
              </div>
              <div class="clear"></div>
              <div class="attachment">
                <a href="/static/upload_template/batch_upload_school_template.csv" download="batch_upload_school_template.csv" target="_blank">模版下载</a>
              </div>
            </div>
            <div class="item">
              <span class="label" style="width: auto"><i class="require">*</i>CSV文件:</span>
              <div class="item-box" style="margin-left: 130px;">
                <input name="file" v-model="uploadedCsv" disabled type="text" id="fileUrl" style="width: 225px;">
                <span class="button" style="position: relative;top: 0px; left: 0px;">
                  选择文件
                  <input @change="xlsChange" type="file" ref="xlsFile" id="fileUpload" class="upload-derict">
                </span>
              </div>
              <div class="clear"></div>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a href="javascript:;" class="button close" id="form_upload_close" @click="hideUploadWindow">取消</a>
          <a href="javascript:;" class="button" id="form_upload_submit" @click="submitCsv">提交</a>
        </div>
      </div>
    </div>

    <div id="dialog_overlay" :style="classShowProcessWindow">
      <div id="dialog_bg"></div>
      <div id="dialog_panel" ref="refErrorList" style="margin-left: -136px; margin-top: -54px;max-width: 300px;">
        <div id="dialog_panel_bg"></div>
        <div id="dialog_title">提示</div>
        <div id="dialog_content">
          ({{ curCsvIndex }} / {{ uploadedCscData.length }})
          <span style="color: #219d2b">
            {{ ' ' + csvFinishText }}
          </span>
        </div>
        <div id="dialog_content2">
          <ul>
            <li v-for="(item, index) in errorList" v-bind:key="index">
              <span :class="item.code == 1 ? 'success' : 'fail'">{{ item.text }}</span>
            </li>
          </ul>

        </div>
        <div id="dialog_control">
          <a href="javascript:void(0)" id="dialog_ok" class="button col2" @click="classShowProcessWindow={}"
             :style="isFinishUpsert ? 'display: inline-block' : 'display:none'">确认</a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Pagination from "../../components/Pagination";

export default {
  name: "AdminSchool",
  components: {
    Pagination
  },
  data() {
    return {
      queryInfo: {
        page: 1,
        size: 10,
        status: '',
        province: '',
        level: '',
        title: '',
        code: '',
      },
      formInfo: {
        id: 0,
        title: '',
        en_title: '',
        code: '',
        province: '',
        level: '',
        attr: '',
        remark: '',
        contacts: '',
        phone: '',
        brief: '',
        url: '',
        logoPath: ''
      },
      classShowAddWindow: {},
      classShowImageWindow: {},
      showImgPreview: false,
      uploaded_img: '',
      uploaded_xls: '',
      uploaded_rows: 0,
      inserted_rows: 0,
      tableData: [],
      tableDataCount: 0,
      confirmWindow: {
        show: false,
        text: ''
      },
      classShowUploadWindow: {},
      uploadedCsv: '',
      uploadedCscData: [],
      classShowProcessWindow: {},
      errorList: [],
      isFinishUpsert: false,
      curCsvIndex: 0,
      csvFinishText: ''
    }
  },
  methods: {
    provinceText(v) {
      return this.$store.state.dict_province[v]
    },
    levelText(v) {
      return this.$store.state.school_level_dict[v]
    },
    statusText(v) {
      return this.$store.state.pub_state_dict[v]
    },
    async imageFileChange() {
      const uploadUrl = '/files/' + this.$refs.imageFile.files[0].name
      await this.$http.put(uploadUrl, this.$refs.imageFile.files[0]).then((data) => {

        this.uploaded_img = data
        this.showImgPreview = true
        this.$refs.headImg.src = this.$store.state.uploadFileRootUrl + data
      }).catch(() => {
        this.$parent.$parent.showMsg('上传失败！')
      })
    },
    async search() {
      await this.$http.get('/school/', {
        params: this.queryInfo
      }).then((data) => {
        console.log(data)
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$parent.$parent.showMsg('获取学校信息失败！')
      })
    },
    showAddWindow() {
      this.classShowAddWindow = {
        visibility: 'visible'
      }
    },
    hideAddWindow() {
      this.classShowAddWindow = {}
      this.formInfo = {
        id: 0,
        title: '',
        en_title: '',
        code: '',
        province: '',
        level: '',
        attr: '',
        remark: '',
        contacts: '',
        phone: '',
        brief: '',
        url: '',
        logoPath: ''
      }
    },
    showImageWindow() {
      this.classShowImageWindow = {
        visibility: 'visible'
      }
    },
    hideImageWindow() {
      this.classShowImageWindow = {}
      this.uploaded_img = ''
      this.showImgPreview = false
    },
    confirmImage() {
      this.formInfo.logoPath = this.uploaded_img
      this.hideImageWindow()
    },
    async saveInfo() {
      if(!this.formInfo.title){
        this.$parent.$parent.showMsg("请输入学校名称!")
        return false
      }
      if(!this.formInfo.code){
        this.$parent.$parent.showMsg("请输入学校代码!")
        return false
      }
      if(!this.formInfo.province){
        this.$parent.$parent.showMsg("请选择省份!")
        return false
      }
      if(!this.formInfo.level){
        this.$parent.$parent.showMsg("请选择教育层次!")
        return false
      }
      if(!this.formInfo.attr){
        this.$parent.$parent.showMsg("请选择学校属性!")
        return false
      }

      if(this.formInfo.phone && (this.formInfo.phone.length < 7 || this.formInfo.phone.length > 12 )){
        this.$parent.$parent.showMsg("电话错误")
        return false;
      }

      if(this.formInfo.id){
        let url = '/school/' + this.formInfo.id + '/'
        await this.$http.put(url, this.formInfo).then((data) => {
          if (data.id) {
            this.$parent.$parent.showMsg('修改成功！')
            this.hideAddWindow()
            this.search()
          } else {
            this.$parent.$parent.showMsg('修改失败！')
          }
        }).catch((error) => {
          console.log(error)
          this.$parent.$parent.showMsg('修改失败！')
        })
      }else{
        await this.$http.post('/school/', this.formInfo).then((data) => {
          if (data.id) {
            this.$parent.$parent.showMsg('添加成功！')
            this.hideAddWindow()
            this.search()
          } else {
            this.$parent.$parent.showMsg('添加失败！')
          }
        }).catch((error) => {
          console.log(error)
          this.$parent.$parent.showMsg('添加失败！')
        })
      }
    },
    async changePublishState(id, status) {
      const msg = status == 0? "取消": ""
      const url = '/school/' + id + '/'
      await this.$http.put(url, {
        status: status
      }).then((data) => {
        console.log(data)
        this.$parent.$parent.showMsg(msg + '发布成功！');
        this.search()
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg(msg + '发布失败！');
      })
    },
    unpublish(id) {
      console.log(id)
      this.$parent.$parent.showConfirm('确定要取消发布吗？', () => {
        this.changePublishState(id, 0)
      });
    },
    publish(id) {
      console.log(id)
      this.$parent.$parent.showConfirm('确定要发布吗？', () => {
        this.changePublishState(id, 1)
      });
    },
    async delSchool(id) {
      const url = '/school/' + id + '/'
      await this.$http.put(url, {
        isdel: 1
      }).then((data) => {
        console.log(data)
        this.$parent.$parent.showMsg('删除成功！');
        this.search()
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg('删除失败！');
      })
    },
    askDel(id) {
      this.$parent.$parent.showConfirm('确定要删除吗？', () => {
        this.delSchool(id)
      });
    },
    async pageCallback(data) {
      this.queryInfo.page = data
      await this.search()
    },
    async querySingle(id) {
      const url = '/school/' + id + '/'
      await this.$http.get(url).then((data) => {
        console.log(data)
        this.formInfo = {
          id: id,
          title: data.title,
          en_title: data.en_title,
          code: data.code,
          province: data.province,
          level: data.level,
          attr: data.attr,
          remark: data.remark,
          contacts: data.contacts,
          phone: data.phone,
          brief: data.brief,
          url: data.url,
          logoPath: data.logoPath
        }
        this.showAddWindow()
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg('数据获取失败！');
      })
    },
    showUploadWindow() {
      this.classShowUploadWindow = {
        visibility: 'visible'
      }
    },
    hideUploadWindow() {
      this.classShowUploadWindow = {}
    },
    async xlsChange(){
      const uploadUrl = '/files/' + this.$refs.xlsFile.files[0].name
      await this.$http.put(uploadUrl, this.$refs.xlsFile.files[0]).then((data) => {
        console.log(data)
        this.uploadedCsv = data
      }).catch(() => {
        this.$parent.$parent.showMsg('上传失败！')
      })
    },
    async submitCsv() {
      const uploadUrl = '/get_csv/'
      await this.$http.get(uploadUrl, {
        params: {
          'file_name': this.uploadedCsv
        }
      }).then((data) => {
        console.log(data)

        if(data.length <= 1){
          this.$parent.$parent.showMsg('表格中无数据！')
          return;
        }

        data.splice(0, 1)

        this.uploadedCscData = data

        this.hideUploadWindow()

        this.classShowProcessWindow = {
          visibility: 'visible'
        }
        this.isFinishUpsert = false
        this.curCsvIndex = 0
        this.csvFinishText = ''
        this.errorList = []
        this.insertOne()

      }).catch(() => {
        this.$parent.$parent.showMsg('读取失败！')
      })
    },
    async insertOne(){

      console.log(this.uploadedCscData)

      if(this.curCsvIndex >= this.uploadedCscData.length) {
        this.csvFinishText = '上传完成'
        this.isFinishUpsert = true
        this.search()
        return;
      }

      let row = this.uploadedCscData[this.curCsvIndex]
      let province = ''
      let level = ''
      let attr = ''

      // region 数据检查开始

      if (row[0].trim().length == 0) {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '请输入学校名称！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      if (row[1].trim().length == 0) {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '请输入学校代码！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      if (row[2].trim().length == 0) {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '请输入学校省份！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      if (row[3].trim().length == 0) {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '请输入层次！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      if (row[4].trim().length == 0) {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '请输入学校属性！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      let dict_province = this.$store.state.dict_province
      let provinceValid = false
      for (let key in dict_province) {
        if (dict_province[key] == row[2].trim()) {
          provinceValid = true
          province = key
          break
        }
      }
      if (!provinceValid) {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '省份输入错误！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      if (row[3] != '本科' && row[3] != '专科') {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '层次输入错误！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      if (row[3] == '本科') {
        level = '1'
      } else if (row[3] == '专科') {
        level = '2'
      }

      if (row[4] != '部属院校' && row[4] != '军队院校'&& row[4] != '普通院校') {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '学校属性输入错误！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      if (row[4] == '部属院校') {
        attr = '1'
      } else if (row[4] == '军队院校') {
        attr = '2'
      } else if (row[4] == '普通院校') {
        attr = '2'
      }

      let titleValid = false
      await this.$http.get('/school/',{
        params: {
          eq_title: row[0],
        }
      }).then(async (data) => {
        console.log(data)
        if (data.count == 0) {
          titleValid = true
        }
      })
      if (!titleValid) {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '学校名称已存在！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      let codeValid = false
      await this.$http.get('/school/',{
        params: {
          eq_title: row[0],
        }
      }).then(async (data) => {
        console.log(data)
        if (data.count == 0) {
          codeValid = true
        }
      })
      if (!codeValid) {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '学校编码已存在！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      // endregion 数据检查结束

      let addForm = {
        title: row[0],
        code: row[1],
        province: province,
        level: level,
        attr: attr,
        en_title: row[5],
        remark: row[6],
        status: 1
      }

      console.log(addForm)

      await this.$http.post('/school/',addForm).then(async (data) => {
        console.log(data)
        this.errorList.push({ code : 1, text: '[' + addForm.title + ']' +"导入成功！" })
        this.curCsvIndex++
        this.insertOne()

      }).catch((error) => {
        console.dir(error)
        this.errorList.push({ code : 0, text: '[' + addForm.title + ']' + error.response.data[0] })
        this.curCsvIndex++
        this.insertOne()
      })
    }
  },
  computed: {
    formPreviewShow() {
      if (this.formInfo.logoPath != '') {
        return true
      } else {
        return false
      }
    },
    formPreviewSrc() {
      if (this.formInfo.logoPath != '') {
        return this.$store.state.uploadFileRootUrl + this.formInfo.logoPath
      } else {
        return ''
      }
    },
    pageInfo() {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    }
  },
  mounted() {
    this.search()
  }
}
</script>

<style scoped>
@import "../../assets/css/dialog.css";
/*@import "../../assets/css/common.css";*/

@import "../../assets/css/pagination.css";
/*@import "../../assets/css/form.css";*/
/*@import "../../assets/css/panel.css";*/
/*@import "../../assets/css/union.css";*/

/*@import "../../assets/css/leftmenu.css";*/
/*@import "../../assets/css/header.css";*/
/*@import "../../assets/css/footer.css";*/

/*@import "../../assets/css/chunkUpload.css";*/

/* .form_panel {
  top: 5px !important;
} */
#dialog_content2 {
  max-height: 100px;
  overflow-y: auto;
  border-top: 1px solid #ddd;
}

#dialog_content2 .success {
  color: #aaa;
}

#dialog_content2 .fail {
  color: #e33;
}
</style>
