<template>
  <div id="panel" class="panel" style="min-height: 696px;">
    <form id="searchSection" onsubmit="return false;">
      <input type="text" name="title" placeholder="用户名" class="s-input separate" v-model="queryInfo.username">
      <input type="button" class="s-btn" value="搜索" @click="queryInfo.page=1,search()">
      <div class="add-btn" style="margin-left:0;" @click="showUploadWindow">导入</div>
      <div class="search-line clearfix">
      </div>
    </form>
    <div id="list">
      <table v-if="this.tableData.length > 0">
        <tbody>
        <tr class="l_header">
          <th width="45px"><a href="javascript:;" class="a-link" @click="ckAll">全选</a></th>
          <th class="itemTitle">用户名或手机号</th>
          <th class="itemTitle">姓名</th>
          <th class="action" width="130">操作</th>
        </tr>
        <tr class="item" v-for="item in tableData" :key="item.id">
          <td><input type="checkbox" name="chose" :value="item.id" v-model="ckUser" @change="ckChange"></td>
          <td class="itemId">{{ item.userProfile?item.userProfile.username:'' }}</td>
          <td class="itemTitle">{{ item.userProfile?item.userProfile.name:'' }}</td>
          <td class="action" style="font-size:12px">
            <a class="delete" href="javascript:;"  @click="isDel(item.id)">删除</a>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="noitems" v-if="this.tableData.length == 0">没有人员</div>
    </div>
    <ul v-if="this.tableData.length > 0" class="batchBtns" id="batchBtns">
      <li><input type="button" class="disable-btn" id="batchDisableBtn" value="批量删除" @click="batchDisable"></li>
    </ul>
    <pagination :cur="pageInfo.page" :all="pageInfo.all" :totalData="pageInfo.totalData"
                :callback="pageCallback"></pagination>

    <!-- 弹窗 -->
    <div class="form_overlay" :style="classShowUploadWindow">
      <div class="form_overlay_bg"></div>
      <div class="form_panel" >
        <div class="form_title">批量上传</div>
        <div class="form_content">
          <form id="uploadForm">
            <div class="item">
              <span class="label" style="width: auto"><i class="require"></i>模版创建规则:</span>
              <div class="item-box divLabel gray">
                <div class="normal-width">
                  <p>1. 文件格式为“逗号分隔”的CSV文件；</p>
                  <p>2. 文件编码必须为ANSI编码；</p>
                  <p>3. 上传文档转换完毕后，请使用记事本打开，查看是否含有空数据，如果含有空数据，则需删除；</p>
                  <p>4. 上传文档中内容不能含有西文逗号；</p>
                  <p>5. CSV文件生成，可使用excel另存为“逗号分隔的CSV文件”；</p>
                  <p>6. 文件编码，可使用记事本打开CSV，另存为ANSI编码。</p>
                </div>
              </div>
              <div class="clear"></div>
              <div class="attachment">
                <a href="/static/upload_template/batch_upload_meetinguser_template.csv" download="batch_upload_meetinguser_template.csv" target="_blank">模版下载</a>
              </div>
            </div>
            <div class="item">
              <span class="label" style="width: auto"><i class="require">*</i>CSV文件:</span>
              <div class="item-box" style="margin-left: 130px;">
                <input name="file" v-model="uploadedCsv" disabled type="text" id="fileUrl" style="width: 225px;">
                <span class="button" style="position: relative;top: 0px; left: 0px;">
                  选择文件
                  <input @change="xlsChange" type="file" ref="xlsFile" id="fileUpload" class="upload-derict">
                </span>
              </div>
              <div class="clear"></div>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a href="javascript:;" style="width: 30px" class="button close" id="form_upload_close"
             @click="hideUploadWindow">取消</a>
          <a href="javascript:;" style="width: 30px" class="button" id="form_upload_submit"  @click="submitCsv">提交</a>
        </div>
      </div>
    </div>
    <div id="dialog_overlay" :style="classShowProcessWindow">
      <div id="dialog_bg"></div>
      <div id="dialog_panel" ref="refErrorList" style="margin-left: -136px; margin-top: -54px;max-width: 300px;">
        <div id="dialog_panel_bg"></div>
        <div id="dialog_title">提示</div>
        <div id="dialog_content">
          ({{ curCsvIndex }} / {{ uploadedCscData.length }})
          <span style="color: #219d2b">
            {{ ' ' + csvFinishText }}
          </span>
        </div>
        <div id="dialog_content2">
          <ul>
            <li v-for="(item, index) in errorList" v-bind:key="index">
              <span :class="item.code == 1 ? 'success' : 'fail'">{{ item.text }}</span>
            </li>
          </ul>

        </div>
        <div id="dialog_control">
          <a href="javascript:void(0)" id="dialog_ok" class="button col2" @click="classShowProcessWindow={}"
             :style="isFinishUpsert ? 'display: inline-block' : 'display:none'">确认</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../../components/Pagination"
export default {
  // 可用组件的哈希表
  components: {Pagination},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      userqueryInfo:{
        page: 1,
        size: 100000,
      },
      userData:[],
      queryInfo:{
        page: 1,
        size: 10,
        username: '',
        meetingInfo_id: '',
        userProfile_id: '',
      },
      tableDataCount: 0,
      tableData:[],
      ckUser: [],
      classShowUploadWindow:{},

      uploadedCsv: '',
      uploadedCscData: [],
      classShowProcessWindow: {},
      isFinishUpsert: false,
      curCsvIndex: 0,
      csvFinishText: '',
      errorList: [],
    }
  },
  // 事件处理器
  methods: {
    // 分页
    async pageCallback(data) {
      this.queryInfo.page = data
      await this.search()
    },
    // 搜索
    async search() {
      await this.$http.get('/meeting_user/', {
        params: this.queryInfo
      }).then((data) => {
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$parent.$parent.showMsg('获取会议信息失败！')
      })
    },
    // 导入
    showUploadWindow() {
      this.classShowUploadWindow = {
        visibility: 'visible'
      }
      this.getUserinfo()
    },
    async getUserinfo() {
      await this.$http.get('/userprofile/', {
        params: this.userqueryInfo
      }).then((data) => {
        this.userData = data.results
      }).catch(() => {
        this.$parent.$parent.showMsg('获取用户信息失败！')
      })
    },
    hideUploadWindow() {
      this.classShowUploadWindow = {}
    },
    // 全选
    ckAll() {
      if (this.ckUser.length == this.tableData.length) {
        this.ckUser = []
      } else {
        for (let i = 0; i < this.tableData.length; i++) {
          this.ckUser.push(this.tableData[i].id)
        }
      }
    },
    ckChange() {
      console.log(this.ckUser)
    },

    async xlsChange() {
      const uploadUrl = '/files/' + this.$refs.xlsFile.files[0].name
      await this.$http.put(uploadUrl, this.$refs.xlsFile.files[0]).then((data) => {
        this.uploadedCsv = data
      }).catch(() => {
        this.$parent.$parent.showMsg('上传失败！')
      })
    },
    async submitCsv() {
      const uploadUrl = '/get_csv/'
      await this.$http.get(uploadUrl, {
        params: {
          'file_name': this.uploadedCsv
        }
      }).then((data) => {
        console.log(data)

        if(data.length <= 1){
          this.$parent.$parent.showMsg('表格中无数据！')
          return;
        }

        data.splice(0, 1)

        this.uploadedCscData = data

        this.hideUploadWindow()

        this.classShowProcessWindow = {
          visibility: 'visible'
        }
        this.isFinishUpsert = false
        this.curCsvIndex = 0
        this.csvFinishText = ''
        this.errorList = []
        this.insertOne()

      }).catch(() => {
        this.$parent.$parent.showMsg('读取失败！')
      })
    },
    async insertOne(){

      console.log(this.uploadedCscData)

      if(this.curCsvIndex >= this.uploadedCscData.length) {
        this.csvFinishText = '上传完成'
        this.isFinishUpsert = true
        this.search()
        return;
      }

      let row = this.uploadedCscData[this.curCsvIndex]
      let userProfile_id = ''

      // region 数据检查开始

      if (row[0].trim().length == 0) {
        this.errorList.push({code: 0, text: '请输入用户名或手机号！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      if (row[1].trim().length == 0) {
        this.errorList.push({code: 0, text: '请输入姓名！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      let dict_userData = this.userData
      let userValid = false
      dict_userData.forEach(element => {
        if(element.username==row[0].trim() || element.mobile==row[0].trim()){
          userProfile_id = element.id
          userValid = true
        }
      });
      if (!userValid) {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '该用户或手机号无注册，已自动注册，默认密码:123456'})
        let authForm = {
          username: row[0],
          password1: '123456',
          password2: '123456'
        }
        await this.$http.post('/user/', authForm).then(async (data) => {
          userProfile_id = data.id
        this.curCsvUserId = data.id
          let profileForm = {
            user_id: data.id,
            username: row[0],
            name: row[1],
            role: 4,
          }
          console.log(profileForm)
          await this.$http.post('/userprofile/', profileForm).then((profileData) => {
            console.log(profileData)

            this.curCsvIndex++
            this.insertOne()

          }).catch((error) => {
            console.log(error)
            this.delInfo(this.curCsvUserId)
            this.curCsvIndex++
            this.insertOne()
          })

        }).catch((error) => {
          console.dir(error)
          this.curCsvIndex++
          this.insertOne()
        })
      }

      let userIdValid = false
      await this.$http.get('/meeting_user/',{
        params: {
          meetingInfo_id: this.queryInfo.meetingInfo_id,
          userProfile_id: userProfile_id
        }
      }).then(async (data) => {
        console.log(data)
        if (data.count == 0) {
          userIdValid = true
        }
      })
      if (!userIdValid) {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '该人员已存在！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }



      // endregion 数据检查结束

      let addForm = {
        userName:row[0],
        meetingInfo_id: this.queryInfo.meetingInfo_id,
        userProfile_id: userProfile_id,
      }

      console.log(addForm)

      await this.$http.post('/meeting_user/',addForm).then(async (data) => {
        console.log(data)
        this.errorList.push({ code : 1, text: '[' + addForm.userName + ']' +"导入成功！" })
        this.curCsvIndex++
        this.insertOne()

      }).catch((error) => {
        console.dir(error)
        this.errorList.push({ code : 0, text: '[' + addForm.userName + ']' + error.response.data[0] })
        this.curCsvIndex++
        this.insertOne()
      })
    },
    // 删除确认框
    isDel(id) {
      this.$parent.$parent.showConfirm('确定要删除吗？', () => {
        this.delRow(id)
      });
    },
    async delRow(id) {
      const url = '/meeting_user/' + id + '/'
      await this.$http.delete(url, {
        meetingInfo_id: 1,
        userProfile_id: 1,
      }).then(() => {
        this.$parent.$parent.showMsg('删除成功！');
        this.search()
      }).catch(() => {
        this.$parent.$parent.showMsg('删除失败！');
      })
    },
    // 批量删除
    batchDisable() {
      if(this.ckUser.length==0){
        this.$parent.$parent.showMsg('请选择要删除的人员');
        return
      }
      this.$parent.$parent.showConfirm("确认将所选人员批量删除？", () => {
        for (let i = 0; i < this.ckUser.length; i++) {
          this.delRow(this.ckUser[i])
        }
        this.ckUser = []
      });
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.queryInfo.meetingInfo_id = this.$route.query.meet_id
  },
  // 生命周期-实例挂载后调用
  mounted () {
    this.search()
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {
    pageInfo() {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    },
  },
  // 自定义的侦听器
  watch: {}
}
</script>

<style scoped>
.date_box {
  border: 1px solid #e6e6e6;
  background-color: #f5f7fc;
  width: 150px;
  height: 32px;
  line-height: 32px;
  margin-right: 8px;
  padding-left: 8px;
}

#dialog_content2 {
  max-height: 100px;
  overflow-y: auto;
  border-top: 1px solid #ddd;
}

#dialog_content2 .success {
  color: #aaa;
}

#dialog_content2 .fail {
  color: #e33;
}
</style>
