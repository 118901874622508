<template>
  <div>
    <form id="searchSection" onsubmit="return false;" style="margin-top: 30px;">
      <div class="choice" style="width:100%">
        <div class="add-btn" @click="showInfoWindow">新建</div>
      </div>
    </form>
    <div id="list">
      <table v-if="this.tableData.length > 0">
        <tbody>
        <tr class="l_header">
          <th class="itemStatus">序号</th>
          <th class="itemStatus">技术工作委员会内容简介</th>
          <th class="action" width="180">操作</th>
        </tr>
        <tr class="item" v-for="(item, index) in tableData" :key="item.id">
          <td class="itemStatus">{{ (queryInfo.page - 1) * queryInfo.size + index + 1 }}</td>
<!--          <td class="itemStatus">{{ item.brief.length > 50 ? item.brief.substr(0,50) + '...' : item.brief }}</td>-->
          <td class="itemStatus">{{ formatHtml(item.brief) }}</td>
          <td class="action">
            <a class="edit" href="javascript:;" @click="querySingle(item.id)">编辑</a>
            <a class="delete" href="javascript:;" @click="askDel(item.id)">删除</a>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="noitems" v-if="tableData.length == 0">没有数据</div>

      <pagination :cur="pageInfo.page" :all="pageInfo.all" :totalData="pageInfo.totalData"
                  :callback="pageCallback"></pagination>
    </div>
    <div class="form_overlay" id="form_overlay" :style="classShowInfoWindow">
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">技术工作委员会添加</div>
        <div class="form_content">
          <form id="itemForm">
            <div class="item">
              <span class="label">内容:</span>
              <div></div>
            </div>
            <vue-ueditor-wrap v-model="formInfo.brief" :config="editorConfig"  editor-id="editor-demo-01">
            </vue-ueditor-wrap>
          </form>
        </div>
        <div class="form_control">
          <a href="javascript:;" class="button close" id="form_close" @click="hideInfoWindow">取消</a>
          <a href="javascript:;" class="button" id="form_submit" @click="saveInfo">提交</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueUeditorWrap from 'vue-ueditor-wrap'
import Pagination from "../../../components/Pagination";

export default {
  name: "AdminOrgBrief",
  components: {
    VueUeditorWrap,
    Pagination
  },
  data() {
    return {
      queryInfo: {
        page: 1,
        size: 10
      },
      formInfo: {
        id: 0,
        brief: '',
      },
      tableData: [],
      tableDataCount: 0,
      showWindow: false,
      editorConfig: {
        UEDITOR_HOME_URL: '/UE/',
        serverUrl: this.$store.state.uEditorServerUrl,
        autoHeight: true,
        initialFrameWidth: '100%',
        initialFrameHeight: 300
      },
    }
  },
  computed: {
    classShowInfoWindow() {
      if (this.showWindow) {
        return {
          visibility: 'visible'
        }
      } else {
        return {}
      }
    },
    pageInfo() {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    }
  },
  methods: {
    async search() {
      await this.$http.get('/committee_brief/', {
        params: this.queryInfo
      }).then((data) => {
        console.log(data)
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$layer.msg('获取信息失败！')
      })
    },
    showInfoWindow() {
      this.showWindow = true
    },
    hideInfoWindow() {
      this.showWindow = false
      this.formInfo = {
        id: 0,
        brief: ''
      }
    },
    async saveInfo() {
      // if(!this.formInfo.brief){
      //   this.$parent.$parent.$parent.showMsg("请输入内容!")
      //   return false
      // }

      if(this.formInfo.id){
        await this.$http.put('/committee_brief/' + this.formInfo.id + '/', this.formInfo).then((data) => {
          if (data.id) {
            this.$layer.msg("修改成功！")
            this.hideInfoWindow()
            this.search()
          } else {
            this.$layer.msg('修改失败！')
          }
        }).catch((error) => {
          console.log(error)
          this.$layer.msg('修改失败！')
        })
      }else{
        await this.$http.post('/committee_brief/', this.formInfo).then((data) => {
          if (data.id) {
            this.$layer.msg("添加成功！")
            this.hideInfoWindow()
            this.search()
          } else {
            this.$layer.msg('添加失败！')
          }
        }).catch((error) => {
          console.log(error)
          this.$layer.msg('添加失败！')
        })
      }
    },
    async delInfo(id) {
      const url = '/committee_brief/' + id + '/'
      await this.$http.delete(url, ).then((data) => {
        console.log(data)
        this.$layer.msg('删除成功！');
        this.search()
      }).catch((error) => {
        console.log(error)
        this.$layer.msg('删除失败！');
      })
    },
    askDel(id) {
      this.$parent.$parent.$parent.showConfirm('确定要删除吗？', () => {
        this.delInfo(id)
      });
    },
    async pageCallback(data) {
      this.queryInfo.page = data
      await this.search()
    },
    async querySingle(id) {
      const url = '/committee_brief/' + id + '/'
      await this.$http.get(url).then((data) => {
        console.log(data)
        this.formInfo = {
          id: id,
          brief: data.brief
        }
        this.showInfoWindow()
      }).catch((error) => {
        console.log(error)
        this.$layer.msg('数据获取失败！');
      })
    },
    formatHtml(richText) {
      let content = richText.replace(/<.+?>/g, '');
      content = content.replace(/&nbsp;/ig, '');
      content = content.replace(/\s/ig, '');
      return content.length > 30 ? content.substr(0, 30) + '...' : content
    }
  },
  mounted() {
    this.search()
  }
}
</script>

<style scoped>
.form_content .label{
  width: 80px;
  padding-right: 10px;
}
</style>
