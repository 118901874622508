<template>
  <div>
    <div class="breadcrumb-nav">
      <a href="/">首页</a>&gt;&gt;
      <a href="/www/organization/chairman">组织机构</a>&gt;&gt;专业工作委员会
    </div>
    <div class="subRight">
      <div class="subRightTit">
        <span
          v-for="(v, k) in dict_org_major_type"
          :key="k"
          :class="'' + category == '' + k ? 'active' : ''"
          @click="category = k; changeCategory()"
        >{{ v }}</span>
      </div>

      <div class="con">
        <table
          cellspacing="0"
          cellpadding="0"
          class="orgTbl m-hide"
          v-if="showTable"
        >
          <tbody>
            <template v-for="(data, index) in categoryData">
              <tr :key="'head_' + index">
                <th
                  colspan="5"
                  width="759"
                >{{ index }}</th>
              </tr>
              <tr :key="'title_' + index">
                <td class="thead" style="width: 80px;">职位</td>
                <td class="thead" style="width: 60px;">姓名</td>
                <td class="thead">职称/职务</td>
                <td class="thead">所在部门</td>
                <td class="thead">工作单位</td>
              </tr>
              <tr
                v-for="item in data"
                :key="'row_' + item.id"
              >
                <td>{{ item.position }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.duty }}</td>
                <td>{{ item.dep_name }}</td>
                <td>{{ item.org_name }}</td>
              </tr>
            </template>
          </tbody>
        </table>
        <div style="width: 100%;text-align: center">
          <button class="btn-default" @click="changeShow">{{ showAll == false ? '显示全部' : '显示默认' }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrgMajor",
  data (){
    return {
      category: 1,
      data: [],
      categoryData: [],
      showTable: false,
      showAll: false,
      queryInfo: {
        page: 1,
        size: 10000
      }
    }
  },
  computed: {
    dict_org_major_type () {
      return this.$store.state.dict_org_major_type
    }
  },
  methods: {
    changeCategory() {
      let temp = this.data.filter(m => m.type == this.category)
      if (temp.length > 0) {
        this.showTable = true
      } else {
        this.showTable = false
      }
      let majors = {}
      for (let i = 0; i < temp.length; i++) {
        if (!(temp[i].major_name.toString() in majors)) {
          majors[temp[i].major_name] = []
        }
      }
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].is_show || this.showAll) {
          majors[temp[i].major_name].push(temp[i])
        }
      }
      this.categoryData = majors
    },
    async getData(){
      await this.$http.get('/allowany/org_major/', {
        params: this.queryInfo
      }).then((data) => {
        this.data = data.results
        this.changeCategory()
      })
    },
    changeShow() {
      this.showAll = !this.showAll
      this.changeCategory()
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped>
/*td {*/
/*  width: 60px;*/
/*}*/
/*td:nth-child(4) {*/
/*  width: 200px;*/
/*}*/
/*td:last-child {*/
/*  width: 200px;*/
/*}*/

.subRightTit {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
}

.subRightTit span {
  position: relative;
  color: #777777;
  border-bottom: none;
  cursor: pointer;
  margin-right: 10px;
  border-bottom: transparent 2px solid;
}

.subRightTit span.active {
  color: #333;
  border-bottom: #444 2px solid;
}

.btn-default {
  border:1px solid #018484;
  background:#FFF;
  color:#333333;
  cursor: pointer;
  margin-left: 15px;
  line-height: 32px;
  height: 32px;
  display: inline-block;
  padding: 0 15px;
  border-radius:3px;
  border-radius:2px;
  align-self: center;
}
</style>
