<template>
  <div>
    <Load v-if="loading" />
    <!-- banner上传 -->
    <div
      v-if="!loading"
      id="center"
    >
      <div class="uploadBanner">
        <img
          :src="formInfo.images[0].src"
          alt=""
        >
        <div
          class="upload_bottom"
          v-if="isPreview"
        >
          <p>图片建议尺寸 1920px*450px，图片格式仅支持jpg、png</p>
          <button class="upload_btn">
            上传图片
            <input
              id="imageFile"
              type="file"
              name="imageFile"
              accept="image/*"
              class="imageupload"
              ref="imageFile"
              @change="imageFileChange('0',$event)"
            >
          </button>
        </div>
        <!-- 菜单 -->
        <TemplateList
          v-if="isMenu"
          :isPreview="isPreview"
          :category=2
          @Setpreview="preview"
          @Setsave="save"
        />
      </div>
      <div class="content_area">
        <!-- 自我介绍 -->
        <div class="self_introduction">
          <div class="self_left">
            <div class="wrap">

              <h4
                class="Headline"
                :contenteditable="isPreview"
                @blur="missBlur('introduceTitle',$event)"
                v-html="formInfo.introduceTitle"
              ></h4>
              <div class="decorate"></div> <!-- 装饰 -->
            </div>
            <!-- 简介 -->
            <div class="brief">
              <span
                :contenteditable="isPreview"
                @blur="missBlur('companyTitle',$event)"
                v-html="formInfo.companyTitle"
              ></span>
              <p
                class="textareaClick"
                :contenteditable="isPreview"
                @blur="missBlur('brief',$event)"
                v-html="formInfo.brief"
              ></p>
            </div>
          </div>
          <div class="self_right">
            <div class="companyBanner">
              <img
                :src="formInfo.images[1].src"
                alt=""
              >
              <div
                class="upload_bottom"
                v-if="isPreview"
              >
                <p>图片建议尺寸 555px*730px，图片格式仅支持jpg、png</p>
                <button class="upload_btn">上传图片
                  <input
                    id="imageFile"
                    type="file"
                    name="imageFile"
                    accept="image/*"
                    class="imageupload"
                    ref="imageFile"
                    @change="imageFileChange('1',$event)"
                  >
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- 产品介绍 -->
        <div class="inner_box">
          <h4
            class="Headline"
            :contenteditable="isPreview"
            @blur="missBlur('productTitle',$event)"
            v-html="formInfo.productTitle"
          ></h4>
          <ul class="rowlist">
            <li>
              <div class="productIcon">
                <img
                  :src="formInfo.images[2].src"
                  alt=""
                >
                <div
                  class="upload_bottom"
                  v-if="isPreview"
                >
                  <p>图片建议尺寸 300px*200px<br />图片格式仅支持jpg、png</p>
                  <button class="upload_btn">上传图片
                    <input
                      id="imageFile"
                      type="file"
                      name="imageFile"
                      accept="image/*"
                      class="imageupload"
                      ref="imageFile"
                      @change="imageFileChange('2',$event)"
                    >
                  </button>
                </div>
              </div>
              <div class="grid">
                <div class="line"></div>
                <span
                  class="textareaClick"
                  :contenteditable="isPreview"
                  @blur="missBlur('productGridTitle1',$event)"
                  v-html="formInfo.productGridTitle1"
                ></span>

                <p
                  class="textareaClick"
                  :contenteditable="isPreview"
                  @blur="missBlur('productTextarea1',$event)"
                  v-html="formInfo.productTextarea1"
                ></p>
              </div>
            </li>
            <li>
              <div class="grid middle">
                <div class="line"></div>
                <span
                  class="textareaClick"
                  :contenteditable="isPreview"
                  @blur="missBlur('productGridTitle2',$event)"
                  v-html="formInfo.productGridTitle2"
                ></span>
                <p
                  class="textareaClick"
                  :contenteditable="isPreview"
                  @blur="missBlur('productTextarea2',$event)"
                  v-html="formInfo.productTextarea2"
                ></p>
              </div>
              <div class="productIcon">
                <img
                  :src="formInfo.images[3].src"
                  alt=""
                >
                <div
                  class="upload_bottom"
                  v-if="isPreview"
                >
                  <p>图片建议尺寸 300px*200px<br />图片格式仅支持jpg、png</p>
                  <button class="upload_btn">上传图片
                    <input
                      id="imageFile"
                      type="file"
                      name="imageFile"
                      accept="image/*"
                      class="imageupload"
                      ref="imageFile"
                      @change="imageFileChange('3',$event)"
                    >
                  </button>
                </div>
              </div>
            </li>
            <li>
              <div class="productIcon">
                <img
                  :src="formInfo.images[4].src"
                  alt=""
                >
                <div
                  class="upload_bottom"
                  v-if="isPreview"
                >
                  <p>图片建议尺寸 300px*200px<br />图片格式仅支持jpg、png</p>
                  <button class="upload_btn">上传图片
                    <input
                      id="imageFile"
                      type="file"
                      name="imageFile"
                      accept="image/*"
                      class="imageupload"
                      ref="imageFile"
                      @change="imageFileChange('4',$event)"
                    >
                  </button>
                </div>
              </div>
              <div class="grid">
                <div class="line"></div>
                <span
                  class="textareaClick"
                  :contenteditable="isPreview"
                  @blur="missBlur('productGridTitle3',$event)"
                  v-html="formInfo.productGridTitle3"
                ></span>

                <p
                  class="textareaClick"
                  :contenteditable="isPreview"
                  @blur="missBlur('productTextarea3',$event)"
                  v-html="formInfo.productTextarea3"
                ></p>
              </div>
            </li>
          </ul>
        </div>
        <!-- 产品优势 -->
        <div
          class="inner_box"
          style="margin-top:0px"
        >
          <!-- <h4 class="Headline" :contenteditable="isPreview" @blur="missBlur('advantageTitle',$event)" v-html="formInfo.advantageTitle"></h4> -->
          <ul class="rowlist">
            <li style="background:#fff">
              <div class="productIcon">
                <img
                  :src="formInfo.images[5].src"
                  alt=""
                >
                <div
                  class="upload_bottom"
                  v-if="isPreview"
                >
                  <p>图片建议尺寸 300px*200px<br />图片格式仅支持jpg、png</p>
                  <button class="upload_btn">上传图片
                    <input
                      id="imageFile"
                      type="file"
                      name="imageFile"
                      accept="image/*"
                      class="imageupload"
                      ref="imageFile"
                      @change="imageFileChange('5',$event)"
                    >
                  </button>
                </div>
              </div>
              <div class="grid">

                <span
                  class="textareaClick"
                  :contenteditable="isPreview"
                  @blur="missBlur('advantageTitle1',$event)"
                  v-html="formInfo.advantageTitle1"
                  style="margin-bottom:5px"
                ></span>
                <div
                  class="line"
                  style="height:1px;margin-bottom:20px"
                ></div>

                <p
                  class="textareaClick"
                  :contenteditable="isPreview"
                  @blur="missBlur('advantageTextarea1',$event)"
                  v-html="formInfo.advantageTextarea1"
                ></p>
              </div>
            </li>
            <li style="background:#fff">
              <div class="productIcon">
                <img
                  :src="formInfo.images[6].src"
                  alt=""
                >
                <div
                  class="upload_bottom"
                  v-if="isPreview"
                >
                  <p>图片建议尺寸 300px*200px<br />图片格式仅支持jpg、png</p>
                  <button class="upload_btn">上传图片
                    <input
                      id="imageFile"
                      type="file"
                      name="imageFile"
                      accept="image/*"
                      class="imageupload"
                      ref="imageFile"
                      @change="imageFileChange('6',$event)"
                    >
                  </button>
                </div>
              </div>
              <div class="grid">

                <span
                  class="textareaClick"
                  :contenteditable="isPreview"
                  @blur="missBlur('advantageTitle2',$event)"
                  v-html="formInfo.advantageTitle2"
                  style="margin-bottom:5px"
                ></span>
                <div
                  class="line"
                  style="height:1px;margin-bottom:20px"
                ></div>

                <p
                  class="textareaClick"
                  :contenteditable="isPreview"
                  @blur="missBlur('advantageTextarea2',$event)"
                  v-html="formInfo.advantageTextarea2"
                ></p>
              </div>
            </li>
            <li style="background:#fff">
              <div class="productIcon">
                <img
                  :src="formInfo.images[7].src"
                  alt=""
                >
                <div
                  class="upload_bottom"
                  v-if="isPreview"
                >
                  <p>图片建议尺寸 300px*200px<br />图片格式仅支持jpg、png</p>
                  <button class="upload_btn">上传图片
                    <input
                      id="imageFile"
                      type="file"
                      name="imageFile"
                      accept="image/*"
                      class="imageupload"
                      ref="imageFile"
                      @change="imageFileChange('7',$event)"
                    >
                  </button>
                </div>
              </div>
              <div class="grid">

                <span
                  class="textareaClick"
                  :contenteditable="isPreview"
                  @blur="missBlur('advantageTitle3',$event)"
                  v-html="formInfo.advantageTitle3"
                  style="margin-bottom:5px"
                ></span>
                <div
                  class="line"
                  style="height:1px;margin-bottom:20px"
                ></div>

                <p
                  class="textareaClick"
                  :contenteditable="isPreview"
                  @blur="missBlur('advantageTextarea3',$event)"
                  v-html="formInfo.advantageTextarea3"
                ></p>
              </div>
            </li>
          </ul>
        </div>
        <!-- 经典案例 -->

        <div class="inner_box">
          <h4
            class="Headline"
            :contenteditable="isPreview"
            @blur="missBlur('caseTitle',$event)"
            v-html="formInfo.caseTitle"
          ></h4>
          <ul class="caseList">
            <li>
              <span
                class="textareaClick"
                :contenteditable="isPreview"
                @blur="missBlur('caseTitle1',$event)"
                v-html="formInfo.caseTitle1"
              ></span>

              <p
                class="textareaClick edit"
                :contenteditable="isPreview"
                @blur="missBlur('caseTextarea1',$event)"
                v-html="formInfo.caseTextarea1"
              ></p>
            </li>
            <li>
              <img
                :src="formInfo.images[8].src"
                alt=""
              >
              <div
                class="upload_bottom"
                v-if="isPreview"
              >
                <p>图片建议尺寸 300px*300px<br />图片格式仅支持jpg、png</p>
                <button class="upload_btn">上传图片
                  <input
                    id="imageFile"
                    type="file"
                    name="imageFile"
                    accept="image/*"
                    class="imageupload"
                    ref="imageFile"
                    @change="imageFileChange('8',$event)"
                  >
                </button>
              </div>
            </li>
            <li>
              <span
                class="textareaClick"
                :contenteditable="isPreview"
                @blur="missBlur('caseTitle2',$event)"
                v-html="formInfo.caseTitle2"
              ></span>

              <p
                class="textareaClick edit"
                :contenteditable="isPreview"
                @blur="missBlur('caseTextarea2',$event)"
                v-html="formInfo.caseTextarea2"
              ></p>
            </li>
            <li>
              <img
                :src="formInfo.images[9].src"
                alt=""
              >
              <div
                class="upload_bottom"
                v-if="isPreview"
              >
                <p>图片建议尺寸 300px*300px<br />图片格式仅支持jpg、png</p>
                <button class="upload_btn">上传图片
                  <input
                    id="imageFile"
                    type="file"
                    name="imageFile"
                    accept="image/*"
                    class="imageupload"
                    ref="imageFile"
                    @change="imageFileChange('9',$event)"
                  >
                </button>
              </div>
            </li>
            <li>
              <img
                :src="formInfo.images[10].src"
                alt=""
              >
              <div
                class="upload_bottom"
                v-if="isPreview"
              >
                <p>图片建议尺寸 300px*300px<br />图片格式仅支持jpg、png</p>
                <button class="upload_btn">上传图片
                  <input
                    id="imageFile"
                    type="file"
                    name="imageFile"
                    accept="image/*"
                    class="imageupload"
                    ref="imageFile"
                    @change="imageFileChange('10',$event)"
                  >
                </button>
              </div>
            </li>
            <li>
              <span
                class="textareaClick"
                :contenteditable="isPreview"
                @blur="missBlur('caseTitle3',$event)"
                v-html="formInfo.caseTitle3"
              ></span>

              <p
                class="textareaClick edit"
                :contenteditable="isPreview"
                @blur="missBlur('caseTextarea3',$event)"
                v-html="formInfo.caseTextarea3"
              ></p>
            </li>
            <li>
              <img
                :src="formInfo.images[11].src"
                alt=""
              >
              <div
                class="upload_bottom"
                v-if="isPreview"
              >
                <p>图片建议尺寸 300px*300px<br />图片格式仅支持jpg、png</p>
                <button class="upload_btn">上传图片
                  <input
                    id="imageFile"
                    type="file"
                    name="imageFile"
                    accept="image/*"
                    class="imageupload"
                    ref="imageFile"
                    @change="imageFileChange('11',$event)"
                  >
                </button>
              </div>
            </li>
            <li>
              <span
                class="textareaClick"
                :contenteditable="isPreview"
                @blur="missBlur('caseTitle4',$event)"
                v-html="formInfo.caseTitle4"
              ></span>

              <p
                class="textareaClick edit"
                :contenteditable="isPreview"
                @blur="missBlur('caseTextarea4',$event)"
                v-html="formInfo.caseTextarea4"
              ></p>
            </li>
          </ul>
        </div>
        <!-- 联系我们 -->
        <div class="inner_box">
          <h4
            class="Headline"
            :contenteditable="isPreview"
            @blur="missBlur('contactTitle',$event)"
            v-html="formInfo.contactTitle"
          ></h4>

          <div class="contact_us">
            <div class="contact_left">
              <img
                :src="formInfo.images[12].src"
                alt=""
              >
              <div
                class="upload_bottom"
                v-if="isPreview"
              >
                <p>图片建议尺寸 650px*420px 图片格式仅支持jpg、png</p>
                <button class="upload_btn">上传图片
                  <input
                    id="imageFile"
                    type="file"
                    name="imageFile"
                    accept="image/*"
                    class="imageupload"
                    ref="imageFile"
                    @change="imageFileChange('12',$event)"
                  >
                </button>
              </div>
            </div>
            <div class="contact_right">
              <div class="code">
                <img
                  :src="formInfo.images[13].src"
                  alt=""
                >
                <div
                  class="upload_bottom"
                  v-if="isPreview"
                >
                  <p>图片建议尺寸 250px*250px<br />图片格式仅支持jpg、png</p>
                  <button class="upload_btn">上传图片
                    <input
                      id="imageFile"
                      type="file"
                      name="imageFile"
                      accept="image/*"
                      class="imageupload"
                      ref="imageFile"
                      @change="imageFileChange('13',$event)"
                    >
                  </button>
                </div>
              </div>
              <p
                class="textareaClick ellipsis"
                :contenteditable="isPreview"
                @blur="missBlur('contactTextarea',$event)"
                v-html="formInfo.contactTextarea"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TemplateList from "./TemplateList"
import Load from "./Loading"
export default {
  // 可用组件的哈希表
  components: {TemplateList,Load},
  // 接收传值
  props: {
    isPreview: {
      type: Boolean,
    },
    isMenu: {
      type: Boolean,
    }
  },
  // 数据对象
  data () {
    return {
      loading:true,
      formInfo:{
        headImg:'',

        introduceTitle:'我们是谁',    //介绍的标题
        companyTitle:'影视摄影服务商',     //公司名称
        brief:'<div>目标客户：公司企业、品牌商、政府学校<br></div><div><br></div><div>使&nbsp; &nbsp; &nbsp; &nbsp;命：点亮企业的一根火柴</div><div><br></div><div>价 值&nbsp; 观：创意 责任 梦想</div><div><br></div><div>文化核心：影像创造价值 品牌源自文化</div><div><br></div><div>企业使命：用影像感动世界</div><div><br></div><div>服务理念：只有不断超越客户预期，才能超越自己</div><div><br></div><div>实战观点：把营销变成艺术，用艺术拉进距离</div>',
        companyImg:'',

        productTitle:'产品介绍',
        productGridTitle1:'产品宣传片',
        productTextarea1:'产品TVC广告片、产品创意短视频、产品推广宣传视频、演员情景式视频',

        productGridTitle2:'产品广告片',
        productTextarea2:'产品展示视频、产品功能介绍视频、产品工艺视频、产品监测视频',

        productGridTitle3:'宣传片',
        productTextarea3:'产品TVC广告片、产品创意短视频、产品推广宣传视频、演员情景式视频',
        
        advantageTitle:'产品优势',
        advantageTitle1:'政企宣传片',
        advantageTextarea1:'十年企业宣传片制作经验，专业团队策划拍摄。已制作上千部企业宣传片，已为众多政企单位、机械自动化、电子电器、家具行业制作宣传片',

        advantageTitle2:'品牌宣传片',
        advantageTextarea2:'十年企业宣传片制作经验，专业团队策划拍摄。已制作上千部企业宣传片，已为众多政企单位、机械自动化、电子电器、家具行业制作宣传片',

        advantageTitle3:'动画制作',
        advantageTextarea3:'十年企业宣传片制作经验，专业团队策划拍摄。已制作上千部企业宣传片，已为众多政企单位、机械自动化、电子电器、家具行业制作宣传片',

        caseTitle:'经典案例',
        caseTitle1:'产品摄影',
        caseTextarea1:'<div>灯饰家具摄影</div><div>电子产品拍摄</div><div>机械设备拍摄</div><div>电商产品拍摄</div>',

        caseTitle2:'店铺设计',
        caseTextarea2:'<div>天猫店铺设计</div><div>淘宝店铺设计</div><div>京东店铺设计</div><div>阿里店铺设计</div>',

        caseTitle3:'企业形象照',
        caseTextarea3:'<div>外观建筑拍摄</div><div>机械设备拍摄</div><div>团队形象拍摄</div><div>厂房车间拍摄</div>',

        caseTitle4:'画册设计',
        caseTextarea4:'<div>形象宣传画册</div><div>文化宣传画册</div><div>产品目录画册</div><div>宣传海报设计</div>',

        contactTitle:'联系我们',
        contactTextarea:'<div>地&nbsp; &nbsp; &nbsp; &nbsp;址：北京市海淀区北三环中路44号院文教产业园D座</div><div>客户热线：010-59508493 010-59508535</div><div>销售专线：400-888-3467</div><div>邮&nbsp; &nbsp; &nbsp; &nbsp;箱：support@rainier.net.cn&nbsp; &nbsp; &nbsp;&nbsp;</div><div>Q&nbsp; &nbsp; &nbsp; &nbsp; Q：1052004576</div>',
        images:[
          {src:require('../assets/images/two/1.png')},
          {src:require('../assets/images/two/2.png')},
          {src:require('../assets/images/two/3.png')},
          {src:require('../assets/images/two/4.png')},
          {src:require('../assets/images/two/5.png')},
          {src:require('../assets/images/two/6.png')},
          {src:require('../assets/images/two/7.png')},
          {src:require('../assets/images/two/8.png')},
          {src:require('../assets/images/two/9.png')},
          {src:require('../assets/images/two/10.png')},
          {src:require('../assets/images/two/11.png')},
          {src:require('../assets/images/two/12.png')},
          {src:require('../assets/images/two/contact1.png')},
          {src:require('../assets/images/two/contact2.png')},
        ]
      },
      queryInfo:{
        type:2,
        ownerId:''
      },
      companyId:''
    }
  },
  // 事件处理器
  methods: {
    // 上传图片
    async imageFileChange(value,e) {
      const uploadUrl = '/files/' + e.target.files[0].name
      await this.$http.put(uploadUrl, e.target.files[0]).then((data) => {
        this.formInfo.images[value].src = this.$store.state.uploadFileRootUrl+data
      }).catch(() => {
        alert('上传失败')
      })
    },
    missBlur(e,v){
      this.formInfo[e]=v.target.innerHTML
    },
    preview(){
      this.isPreview=!this.isPreview
    },
    // 获取当前时间
    getCurrentTime() {
      var _this = this
      const yy = new Date().getFullYear()
      const mm = new Date().getMonth() + 1
      const dd = new Date().getDate()
      const hh = new Date().getHours()
      const mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
      const ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
      _this.gettime = yy + '-' + mm + '-' + dd + 'T' + hh + ':' + mf + ':' + ss
      return _this.gettime
    },
    async save(){
      const url='/application/'+this.companyId+'/'
      await this.$http.put(url, {
        super_view_category:2,
        super_view_status:1,
        super_view_submit_time:this.getCurrentTime(),
        super_view_data:JSON.stringify(this.formInfo)
      }).then(() => {
      }).catch(() => {
      })
    },
    async search() {
      await this.$http.get('/allowany/application/', {
        params: this.queryInfo
      }).then((data) => {
        this.loading = false
        if(data.results.length!=0){
          this.companyId=data.results[0].id
          if(data.results[0].super_view_data!=='' && data.results[0].super_view_category==2){
            this.formInfo=JSON.parse(data.results[0].super_view_data)
          } 
        }else{
          this.$parent.showMsg('您不是高级会员身份')
          this.$router.push('/')
        }
      }).catch(() => {
        this.$parent.$parent.showMsg('获取通知信息失败！')
      })
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    if(this.$route.path.substr(0, 4) == '/www'){
      if(this.$route.query.ownerId){
        this.queryInfo.ownerId = this.$route.query.ownerId
      } else{
        this.$router.push('/')
      }
    }else{
      this.queryInfo.ownerId = this.$store.state.userInfo.user_id
    }
    this.search()
    
   },
  // 生命周期-实例挂载后调用
  mounted () { 
    
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
#center {
  background: #ffffff;
  width: auto !important;
}
.upload_btn {
  width: 110px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  margin-top: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  background: #335dcc;
  border: 1px solid #335dcc;
  border-radius: 10px;
  position: relative;
  .imageupload {
    position: absolute;
    left: 0;
    opacity: 0;
    height: 40px;
    width: 110px;
  }
}
.uploadBanner {
  position: relative;
  height: 450px;
  img {
    width: 100%;
    height: 100%;
  }
  .upload_bottom {
    position: absolute;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 140px;
    bottom: 0px;
    p {
      margin-top: 45px;
      color: #ffffff;
    }
  }
}
.content_area {
  width: 1200px;
  margin: 0 auto;
  margin-top: 80px;
  // 大标题
  .Headline {
    padding: 0 10px;
    font-size: 36px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    position: absolute;
    z-index: 2;
  }
  .editInput {
    width: 240px;
    height: 45px;
    position: absolute;
    z-index: 99;
    font-size: 36px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
  }
  .textareaClick {
    padding: 0 10px;
  }
  .upload_bottom {
    position: absolute;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    width: 555px;
    height: 100px;
    bottom: 7px;
    p {
      margin-top: 18px;
      color: #ffffff;
    }
    .upload_btn {
      margin-top: 10px;
      width: 80px;
      height: 32px;
    }
  }
  .editTextarea {
    width: 310px;
    font-size: 14px;
    color: #333333;
    line-height: 30px;
  }
  .self_introduction {
    display: flex;
    .self_left {
      width: 576px;
      .wrap {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 67px;
        width: 320px;
        height: 143px;
        .decorate {
          position: absolute;
          z-index: 1;
          width: 225px;
          height: 127px;
          top: 0;
          right: 0;
          background: #fafafa;
          border: 8px solid #f2f2f2;
        }
      }
      .brief {
        margin-top: 100px;
        color: #333333;
        font-size: 14px;
        span {
          padding: 0 10px;
          display: inline-block;
          margin-bottom: 35px;
        }
        > div {
          line-height: 35px;
        }
      }
    }
    .self_right {
      flex: 1;
      position: relative;
      .companyBanner {
        margin-left: 69px;
        width: 555px;
        img {
          width: 100%;
          height: 730px;
        }
        .upload_bottom {
          width: 555px;
          height: 100px;
          bottom: 7px;
          p {
            margin-top: 18px;
            color: #ffffff;
          }
          .upload_btn {
            font-size: 12px;
            margin-top: 10px;
            line-height: 32px;
            width: 80px;
            height: 32px;
            .imageupload {
              width: 80px;
              height: 32px;
            }
          }
        }
      }
      .companyBanner::before {
        content: "";
        width: 69px;
        height: 627px;
        background: #f2f2f2;
        position: absolute;
        left: 0;
        top: 28px;
        display: inline-block;
      }
    }
  }
  .inner_box {
    margin-top: 90px;
    text-align: center;
    .Headline {
      position: relative;
      display: inline-block;
      height: 65px;
    }
    .editInput {
      position: relative;
      top: 12px;
    }
    .rowlist {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      li {
        width: 300px;
        background-color: #f5f5f5;
        .productIcon {
          bottom: 0;
          height: 200px;
          position: relative;
          img {
            width: 100%;
            height: 200px;
            bottom: 0;
          }
          .upload_bottom {
            width: 300px;
            bottom: 0;
            p {
              margin-top: 8px;
            }
            .upload_btn {
              line-height: 32px;
              font-size: 12px;
              margin-top: 3px;
              .imageupload {
                width: 82px;
                height: 32px;
              }
            }
          }
        }
        .grid {
          margin-top: 5px;
          padding: 0 28px 30px;
          min-height: 110px;
          .line {
            width: 63px;
            height: 4px;
            background: #4e76de;
            margin: 0 auto;
          }
          span {
            margin-top: 10px;
            display: inline-block;
            margin-bottom: 15px;
            font-size: 20px;
            color: #333333;
          }
          .edit {
            margin-top: 14px;
            margin-bottom: 19px;
            font-size: 20px;
            color: #333333;
            width: 150px;
          }
          .textarea {
            text-align: justify;
            height: 50px;
            overflow: hidden;
          }
          .ellipsis {
            height: 70px;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            text-overflow: ellipsis;
          }
          .editTextarea {
            width: 220px;
            font-size: 14px;
            line-height: 22px;
          }
        }
        .middle {
          position: relative;
          height: 125px;
          .line {
            position: absolute;
            left: 120px;
            bottom: 4px;
          }
        }
      }
    }
    .caseList {
      overflow: hidden;
      margin-top: 50px;
      li {
        float: left;
        text-align: center;
        width: 300px;
        height: 300px;
        background: #333333;
        position: relative;
        color: #ffffff;
        span {
          margin-top: 66px;
          font-size: 20px;
          display: inline-block;
          color: #ffffff;
        }
        .edit {
          margin: 20px auto 0;
          width: 160px;
        }
        img {
          width: 100%;
          height: 100%;
        }
        .upload_bottom {
          bottom: 0;
          width: 300px;
          p {
            line-height: 23px;
            margin-top: 8px;
          }
          .upload_btn {
            margin-top: 8px !important;
            font-size: 12px;
            margin-top: 3px;
            line-height: 29px;
            .imageupload {
              width: 82px;
              height: 32px;
            }
          }
        }
      }
    }
    .contact_us {
      margin-top: 50px;
      overflow: hidden;
      .contact_left {
        position: relative;
        float: left;
        width: 650px;
        height: 420px;
        img {
          width: 100%;
          height: 100%;
        }
        .upload_bottom {
          width: 650px;
          bottom: 0;
          .upload_btn {
            line-height: 30px;
            .imageupload {
              width: 82px;
              height: 32px;
            }
          }
        }
      }
      .contact_right {
        width: 500px;
        float: right;
        text-align: left;
        .code {
          margin-left: 10px;
          width: 250px;
          height: 250px;
          position: relative;
          img {
            width: 100%;
            height: 100%;
          }
          .upload_bottom {
            width: 250px;
            bottom: 0;
            p {
              margin-top: 12px;
              line-height: 20px;
            }
            .upload_btn {
              line-height: 30px;
              font-size: 12px;
              .imageupload {
                width: 82px;
                height: 32px;
              }
            }
          }
        }
        .ellipsis {
          margin-top: 35px;
          display: inline-block;
          font-size: 16px;
          color: #333333;
          line-height: 28px;
        }
        .editTextarea {
          margin-top: 36px;
          color: #333333;
          line-height: 28px;
        }
      }
    }
  }
}
</style>
