<template>
  <div>
    <div class="outer_layer">
      <div class="content">
        <div
          class="module_name"
          v-if="innovateList.length"
        >
          <h4>创新合作伙伴</h4>
        </div>
        <div
          class="vipList clearfix rowlist"
          v-if="innovateList.length"
        >
          <ul
            id="list"
            class="clearfix"
          >
            <template v-for="item in innovateList">
              <li
                class="type2"
                :ref="'vipLi' + item.id"
                :key="item.id"
                @mouseenter="showOrg(item,1)"
                @mouseleave="hideOrg(item)"
              >
                <div
                  class="list-item"
                  @click="goTemplate(item.super_view_status,item.super_view_category,item.ownerId)"
                >
                  <img
                    v-if="item.logo"
                    :src="formatImage(item.logo)"
                  >
                  <img
                    v-if="!item.logo"
                    src="../../assets/images/vip_default_logo.png"
                  >
                  <span :data-id="item.id">{{ item.title }}</span>
                </div>
                <div
                  class="form_overlay"
                  id="form_overlay"
                  :style="item.show_status==4?formatCompanyStyle(item):''"
                >
                  <div class="mask-bg"></div>
                  <div
                    class="form_panel"
                    style="min-height: 444px;"
                  >
                    <div class="form_title">
                      <span>企业介绍</span>
                      <a
                        href="javascript:void(0);"
                        v-if="item.super_view_category"
                      >
                        <img
                          src="../../assets/images/arrow.png"
                          alt=""
                          style="width:20px;height:20px;margin:0;margin-right:5px"
                        >
                        <span @click="goTemplate(item.super_view_status,item.super_view_category,item.ownerId)">官网首页</span>
                      </a>
                    </div>
                    <div
                      class="form_content clearfix"
                      id="vipInfo"
                    >
                      <div
                        class="leftInfo"
                        style="margin-left: 20px;"
                      >
                        <img
                          :src="formatImage(item.logo)"
                          style="margin-top: 40px;height: 150px;width: 150px;"
                        >
                        <p class="vipTitle">{{ item.title }}</p>
                        <p class="vipInfo"><span class="iconfont"></span>{{ item.show_address }}</p>
                        <p class="vipInfo"><span class="iconfont"></span>{{ item.show_mainPrincipal }}</p>
                        <p class="vipInfo"><span class="iconfont"></span>{{ item.show_mobile1 }}</p>
                        <p class="vipInfo"><span class="iconfont"></span>{{ item.show_email1 }}</p>
                      </div>
                      <div
                        class="rightInfo"
                        style="top: auto;left: 370px;width: 370px;min-width: 370px;"
                      >
                        <p class="infoTitle">企业简介</p>
                        <p
                          class="brief"
                          style="width: 370px;"
                          v-html="item.show_brief"
                        >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </template>
          </ul>
          <a
            href="javascript:"
            class="getMore"
            id="getMore"
            style="display: block !important;"
            v-if="showMoreInnovate"
            @click="searchMoreInnovate"
          >查看更多</a>
        </div>
        <div
          class="module_name"
          v-if="directorList.length"
        >
          <h4>理事单位</h4>
        </div>
        <div
          class="vipList clearfix rowlist"
          v-if="directorList.length"
        >
          <ul
            id="list"
            class="clearfix"
          >
            <template v-for="item in directorList">
              <li
                class="type2"
                :ref="'vipLi' + item.id"
                :key="item.id"
                @mouseenter="showOrg(item,1)"
                @mouseleave="hideOrg(item)"
              >
                <div
                  class="list-item"
                  @click="goTemplate(item.super_view_status,item.super_view_category,item.ownerId)"
                >
                  <img
                    v-if="item.logo"
                    :src="formatImage(item.logo)"
                  >
                  <img
                    v-if="!item.logo"
                    src="../../assets/images/vip_default_logo.png"
                  >
                  <span :data-id="item.id">{{ item.title }}</span>
                </div>
                <div
                  class="form_overlay"
                  id="form_overlay"
                  :style="item.show_status==4?formatCompanyStyle(item):''"
                >
                  <div class="mask-bg"></div>
                  <div
                    class="form_panel"
                    style="min-height: 444px;"
                  >
                    <div class="form_title">
                      <span>企业介绍</span>
                      <a
                        href="javascript:void(0);"
                        v-if="item.super_view_category"
                      >
                        <img
                          src="../../assets/images/arrow.png"
                          alt=""
                          style="width:20px;height:20px;margin:0;margin-right:5px"
                        >
                        <span @click="goTemplate(item.super_view_status,item.super_view_category,item.ownerId)">官网首页</span>
                      </a>
                    </div>
                    <div
                      class="form_content clearfix"
                      id="vipInfo"
                    >
                      <div
                        class="leftInfo"
                        style="margin-left: 20px;"
                      >
                        <img
                          :src="formatImage(item.logo)"
                          style="margin-top: 40px;height: 150px;width: 150px;"
                        >
                        <p class="vipTitle">{{ item.title }}</p>
                        <p class="vipInfo"><span class="iconfont"></span>{{ item.show_address }}</p>
                        <p class="vipInfo"><span class="iconfont"></span>{{ item.show_mainPrincipal }}</p>
                        <p class="vipInfo"><span class="iconfont"></span>{{ item.show_mobile1 }}</p>
                        <p class="vipInfo"><span class="iconfont"></span>{{ item.show_email1 }}</p>
                      </div>
                      <div
                        class="rightInfo"
                        style="top: auto;left: 370px;width: 370px;min-width: 370px;"
                      >
                        <p class="infoTitle">企业简介</p>
                        <p
                          class="brief"
                          style="width: 370px;"
                          v-html="item.show_brief"
                        >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </template>
          </ul>
          <a
            href="javascript:"
            class="getMore"
            id="getMore"
            style="display: block !important;"
            v-if="showMoreDirector"
            @click="searchMoreDirector"
          >查看更多</a>
        </div>
        <div class="module_name">
          <h4>会员单位</h4>
        </div>
        <div class="vipList clearfix rowlist">
          <ul
            id="list"
            class="clearfix"
          >
            <template v-for="item in superList">
              <li
                class="type2"
                :ref="'vipLi' + item.id"
                :key="item.id"
                @mouseenter="showOrg(item,2)"
                @mouseleave="hideOrg(item)"
              >
                <div
                  class="list-item"
                  @click="goTemplate(item.super_view_status,item.super_view_category,item.ownerId)"
                >
                  <img
                    v-if="item.logo"
                    :src="formatImage(item.logo)"
                  >
                  <img
                    v-if="!item.logo"
                    src="../../assets/images/vip_default_logo.png"
                  >
                  <span :data-id="item.id">{{ item.title }}</span>
                </div>
                <div
                  class="form_overlay"
                  id="form_overlay"
                  :style="item.show_status==4?formatCompanyStyle(item):''"
                >
                  <div class="mask-bg"></div>
                  <div
                    class="form_panel"
                    style="min-height: 444px;"
                  >
                    <div class="form_title">
                      <span>企业介绍</span>
                      <a
                        href="javascript:void(0);"
                        v-if="item.super_view_category"
                      >
                        <img
                          src="../../assets/images/arrow.png"
                          alt=""
                          style="width:20px;height:20px;margin:0;margin-right:5px"
                        >
                        <span @click="goTemplate(item.super_view_status,item.super_view_category,item.ownerId)">官网首页</span>
                      </a>
                    </div>
                    <div
                      class="form_content clearfix"
                      id="vipInfo"
                    >
                      <div
                        class="leftInfo"
                        style="margin-left: 20px;"
                      >
                        <img
                          :src="formatImage(item.logo)"
                          style="margin-top: 40px;height: 150px;width: 150px;"
                        >
                        <p class="vipTitle">{{ item.title }}</p>
                        <p class="vipInfo"><span class="iconfont"></span>{{ item.show_address }}</p>
                        <p class="vipInfo"><span class="iconfont"></span>{{ item.show_mainPrincipal }}</p>
                        <p class="vipInfo"><span class="iconfont"></span>{{ item.show_mobile1 }}</p>
                        <p class="vipInfo"><span class="iconfont"></span>{{ item.show_email1 }}</p>
                      </div>
                      <div
                        class="rightInfo"
                        style="top: auto;left: 370px;width: 370px;min-width: 370px;"
                      >
                        <p class="infoTitle">企业简介</p>
                        <p
                          class="brief"
                          style="width: 370px;"
                          v-html="item.show_brief"
                        >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </template>
          </ul>
          <a
            href="javascript:"
            class="getMore"
            id="getMore"
            style="display: block !important;"
            v-if="showMore"
            @click="searchMore"
          >查看更多</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      superList: [],
      superListCount:0,
      innovateList:[],
      innovateListCount:0,
      directorList:[],
      directorListCount:0,
      queryInfo: {
        page: 1,
        size: 18,
        type: 2,
        status: '5',
        isdel: 0,
        isPub: 1,
        ordering:'-super_category3_sort_num',
        super_category3:'1',
        
      },
      innovateQueyInfo:{
        page: 1,
        size: 18,
        super_status: 3,
        isdel: 0,
        super_category1:'1',
        ordering:'-super_category1_sort_num'
      },
      directorQueyInfo:{
        page: 1,
        size: 18,
        super_status: 3,
        isdel: 0,
        super_category2:'1',
        ordering:'-super_category2_sort_num'
      }
    }
  },
  // 事件处理器
  methods: {
    formatImage(fileName) {
      return this.$store.state.uploadFileRootUrl + fileName
    },
    formatCompanyStyle(item) {
      return item.curCompanyShow ? 'visibility:visible' : ''
    },
    // 判断弹窗是否显示
    showOrg(item,code) {
      if(code===1){
        item.curCompanyShow = true
      }else{
        item.curCompanyShow = true
      }
    },
    hideOrg(item) {
      item.curCompanyShow = false
    },
    // 获取所有高级会员信息
    async getInnovateInfo() {
      console.log(this.innovateQueyInfo)
      await this.$http.get('/allowany/application/', {
        params: this.innovateQueyInfo
      }).then((data) => {
        for (let i = 0; i< data.results.length; i++) {
          data.results[i].curCompanyShow = false
        }
         this.innovateList=this.innovateList.concat(data.results)
          this.innovateListCount = data.count
      })
    },
    async getDirectorInfo() {
      console.log(this.directorQueyInfo)
      await this.$http.get('/allowany/application/', {
        params:this.directorQueyInfo
      }).then((data) => {
        for (let i = 0; i< data.results.length; i++) {
          data.results[i].curCompanyShow = false
        }
        this.directorList=this.directorList.concat(data.results)
        this.directorListCount = data.count
      })
    },
    // 获取所有普通企业会员
    async getVipInfo(){
      await this.$http.get('/allowany/application/', {
        params: this.queryInfo
      }).then((data) => {
        for (let i = 0; i < data.results.length; i++) {
          data.results[i].showTip = false
          data.results[i].curCompanyShow = false
          data.results[i].left = 0
          data.results[i].top = 0
        }
        this.superList = this.superList.concat(data.results)
        this.superListCount = data.count
      }).catch(() => {
        this.$layer.msg('获取会员信息失败！')
      })
    },
    async searchMore() {
      this.queryInfo.page += 1
      this.getVipInfo()
    },
    async searchMoreInnovate() {
      this.innovateQueyInfo.page += 1
      this.getInnovateInfo()
    },
    async searchMoreDirector() {
      this.directorQueyInfo.page += 1
      this.getDirectorInfo()
    },
    goTemplate(super_view_status,super_view_category, ownerId) {
      console.log(super_view_category, ownerId)
      if (super_view_status==='2') {
        var oUrl = ''
        oUrl = super_view_category == 1 ? '/www/template_one' : super_view_category == 2 ? '/www/template_two' : '/www/template_three'
        window.open(oUrl+'?ownerId=' + ownerId, '_blank')
      }
      // else{
      //   this.$layer.msg('未选择模板')
      // }
    }
  },
  created() {
  },
  async mounted() {
    console.log(this.innovateQueyInfo)
    console.log(this.directorQueyInfo)
    await this.getInnovateInfo()
    await this.getDirectorInfo()
    await this.getVipInfo()
  },
  computed: {
    showMore() {
      return this.superList.length < this.superListCount
    },
    showMoreInnovate() {
      return this.innovateList.length < this.innovateListCount
    },
    showMoreDirector() {
      return this.directorList.length < this.directorListCount
    }
  }
}
</script>

<style lang="less" scoped>
.banner {
  margin-bottom: -6px;
  position: relative;

  .bgImg {
    width: 100%;
  }

  .btnImg {
    cursor: pointer;
    position: absolute;
    margin: 0 auto;
    bottom: 60px;
    left: 0;
    right: 0;
    -webkit-animation-name: scaleDraw;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 2s;
  }

  @keyframes scaleDraw {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(1.1);
    }
    50% {
      transform: scale(1);
    }
    75% {
      transform: scale(1.1);
    }
  }
}

.outer_layer {
  padding-top: 50px;
  background-color: #ffffff;

  .content {
    width: 1200px;
    margin: 0 auto;
    text-align: center;

    .module_name {
      height: 45px;

      h4 {
        display: inline-block;
        position: relative;
        color: #1f45a9;
        font-weight: bold;
        font-size: 20px;
        line-height: 20px;

        &::before {
          content: "";
          position: absolute;
          display: inline-block;
          width: 100%;
          height: 2px;
          top: 40px;
          bottom: 0;
          background-color: #1f45a9;
        }
      }

      &::before {
        content: "";
        position: relative;
        display: block;
        top: 42px;
        height: 2px;
        background-color: #ededed;
      }
    }

    .rowlist {
      margin-top: 30px;
      overflow: hidden;

      .subset {
        cursor: pointer;
        background: #ffffff;
        float: left;
        margin-bottom: 50px;
        margin-right: 50px;
        width: 198px;
        height: 146px;
        border: 1px solid #ededed;

        img {
          margin-top: 20px;
          width: 60px;
          height: 55px;
          transition: all 0.6s;
        }

        p {
          font-family: Microsoft YaHei;
          color: #333333;
          margin-top: 10px;
          font-size: 14px;
        }

        &:nth-child(5n) {
          margin-right: 0;
        }

        &:hover {
          img {
            transform: scale(1.2);
          }

          p {
            color: #1f45a9;
          }
        }
      }
    }

    .join_now {
      height: 350px;
      margin-top: 50px;
      display: flex;
      justify-content: space-between;

      .subset {
        width: 201px;
        height: 221px;
        border: 1px solid #ededed;
        border-radius: 40px;

        img {
          margin-top: 23px;
          -webkit-transition: 0.8s;
          -webkit-transition: -webkit-transform 0.8s ease-out;
          transition: transform 0.8s ease-out;
          -moz-transition: -moz-transform 0.8s ease-out;

          &:hover {
            -webkit-transform: rotateZ(360deg);
            -moz-transform: rotateZ(360deg);
            -o-transform: rotateZ(360deg);
            -ms-transform: rotateZ(360deg);
            transform: rotateZ(360deg);
          }
        }

        p {
          margin-top: 15px;
          font-size: 14px;
        }
      }

      .arrow {
        height: 221px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}

.otherColor {
  background-color: #f8f8f8;
}

#subjectForm_overlay .form_panel {
  width: 522px;
  height: 402px;
  // background:#fff;
}

.form-title {
  text-align: center;
  font-size: 14px;
  color: #494949;
}

.itemForm {
  .item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 76px;
    box-sizing: border-box;

    label {
      display: inline-block;
      width: 70px;
      font-size: 12px;
      color: #494949;
    }

    input {
      border-color: #ededed;
    }

    .upload-btn {
      width: 79px;
      height: 32px;
      position: relative;

      input {
        width: 79px;
        height: 32px;
      }

      button {
        width: 79px;
        height: 32px;
        background: #1f45a9;
        border: 1px solid #1f45a9;
        color: #fff;
      }
    }
  }
}

.form_control {
  a {
    border-radius: 0;
    background: #1f45a9;
  }

  .close {
    border: 1px solid #1f45a9;
    color: #1f45a9;
    background: #fff;
  }
}

.form_panel {
  padding: 20px;
  min-width: 700px;
  -webkit-box-shadow: #bbb 0px 0px 15px;
  -moz-box-shadow: #bbb 0px 0px 15px;
  box-shadow: #bbb 0px 0px 15px;
  z-index: 10;
}

.form_title {
  font-weight: normal;
  border-left: 0;
  border-bottom: 1px solid #eee;
  padding: 0 0 10px 0;
  // text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    color: #1f45a9;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.form_content p.infoTitle {
  text-align: left;
}

.form_content p.brief {
  font-size: 15px;
  line-height: 2.2;
  text-align: justify;
}

.vipList ul li img {
  width: 120px;
  height: 120px;
  object-fit: scale-down;
}

.mask-bg {
  width: 950px;
  height: 500px;
  background: rgba(255, 255, 255, 0);
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.vipList {
  margin-top: 0;
}

.content {
  padding-bottom: 100px;
}

.outer_layer .content .rowlist {
  margin-top: 0;
}

.form_content p.vipInfo {
  text-align: left;
}
.list-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
}
</style>
