<template>
  <div class="subRight">
    <div class="subRightTit">
      <span>联盟公告</span>
      <a href="/">首页</a>&gt;&gt;
      <a href="/www/introduction/intro">联盟介绍</a>&gt;&gt;联盟公告
    </div>
    <div class="con">
      <ul id="elList">
        <li v-for="item in tableData" :key="item.id">
          <router-link :target="formatFileLink(item.attPath) ? '_blank' : ''" :to="formatFileLink(item.attPath)" >
            {{ item.title }}
            <span class="time">{{ item.date }}</span>
          </router-link>
        </li>
      </ul>

      <pagination :cur="pageInfo.page" :all="pageInfo.all" :totalData="pageInfo.totalData"
                  :callback="pageCallback"></pagination>

    </div>
  </div>
</template>

<script>
import Pagination from "../../../components/Pagination"

export default {
  name: "Notice",
  components: {
    Pagination
  },
  data() {
    return {
      queryInfo: {
        page: 1,
        size: 10,
        status: '1',
        type: '3',
      },
      tableData: [],
      tableDataCount: 0,
    }
  },
  computed: {
    pageInfo() {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    }
  },
  methods: {
    async search() {
      await this.$http.get('/allowany/info/', {
        params: this.queryInfo
      }).then((data) => {
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$parent.$parent.showMsg('获取通知信息失败！')
      })
    },
    async pageCallback(data) {
      this.queryInfo.page = data
      await this.search()
    },
    formatFileLink(attPath) {
      if(attPath) {
        let jsonPath = JSON.parse(attPath)
        if(jsonPath.length > 0){
          return this.$store.state.wwwFileRootUrl + jsonPath[0].path
        }
      }
      return ''
    }
  },
  mounted() {
    this.search()
  }
}
</script>

<style scoped>
#pagination {
  float: none;
}
</style>
