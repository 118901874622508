<template>
  <div
    id="panel"
    class="panel"
    style="min-height: 696px;"
  >
    <form
      id="searchSection"
      onsubmit="return false;"
    >
      <input
        type="text"
        name="title"
        placeholder="标题"
        class="s-input separate"
        v-model="queryInfo.title"
      >
      <input
        type="date"
        name="date"
        placeholder="日期"
        class="date_box"
        v-model="queryInfo.date"
      >
      <select
        name="status"
        class="s-select separate"
        v-model="queryInfo.status"
      >
        <option value="">选择发布状态</option>
        <option value="0">未发布</option>
        <option value="1">已发布</option>
      </select>
      <select
        name="source"
        class="s-select separate"
        v-model="queryInfo.source"
      >
        <option value="">选择来源</option>
        <option value="0">原创</option>
        <option value="1">转载</option>
      </select>
      <select
        name="type"
        class="s-select separate"
        v-model="queryInfo.type"
      >
        <option value="">选择类型</option>
        <option value="1">通知公告</option>
        <!--        <option value="2">活动报道</option>-->
        <!--        <option value="3">联盟公告</option>-->
        <option value="5">会议研讨</option>
        <option value="6">专业委员会</option>
        <option value="7">双百计划</option>
        <option value="8">课题研究</option>
        <option value="9">建设指南</option>
        <option value="10">建设规范</option>
        <option value="4">虚仿竞赛</option>
      </select>
      <input
        type="button"
        class="s-btn"
        value="搜索"
        @click="search"
      >
      <div
        class="add-btn"
        style="margin-left:0;"
        @click="showAddWindow"
      >新建</div>

      <div class="search-line clearfix">

      </div>
    </form>
    <div id="list">
      <table v-if="this.tableData.length > 0">
        <tbody>
          <tr class="l_header">
            <th
              class="itemStatus"
              width="350"
            >标题</th>
            <th class="itemStatus">日期</th>
            <th class="itemStatus">来源</th>
            <th class="itenStatus">类型</th>
            <th class="itemStatus">状态</th>
            <th class="itemStatus">发布时间</th>
            <th
              class="action"
              width="155"
            >操作</th>
          </tr>
          <tr
            class="item"
            v-for="item in tableData"
            :key="item.id"
          >
            <td
              class="itemStatus"
              :title="item.title"
            >
              <a
                class="a-link"
                :href="'/www/activity?id=' + item.id"
                target="_blank"
              >{{item.title}}</a>
            </td>
            <td class="itemStatus">{{item.date}}</td>
            <td class="itemStatus">{{item.source == 0 ? '原创' : '转载'}}</td>
            <td class="itemStatus">{{formTableTypeName(item.type)}}</td>
            <td :class="'itemStatus itemStatus' + item.status">{{ item.status == 1 ? '已发布' : '未发布' }}</td>
            <td class="itemStatus">{{item.publishDate}}</td>
            <td class="action">
              <a
                v-if="item.status==1"
                class="nopublish"
                href="javascript:;"
                @click="unpublish(item.id)"
              >取消发布</a>
              <a
                v-if="item.status==0"
                class="publish"
                href="javascript:;"
                @click="publish(item.id)"
              >发布</a>
              <a
                class="edit"
                href="javascript:;"
                @click="querySingle(item.id)"
              >编辑</a>
              <a
                class="delete"
                href="javascript:;"
                @click="askDel(item.id)"
              >删除</a>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="noitems"
        v-if="tableData.length == 0"
      >没有数据</div>
    </div>

    <pagination
      :cur="pageInfo.page"
      :all="pageInfo.all"
      :totalData="pageInfo.totalData"
      :callback="pageCallback"
    ></pagination>

    <div
      class="form_overlay"
      id="form_overlay"
      :style="classShowAddWindow"
    >
      <!-- <vue-ueditor-wrap
        v-model="formInfo.contents"
        :config="editorConfig"
        editor-id="editor-demo-01"
      ></vue-ueditor-wrap> -->

      <div class="form_panel">
        <div class="form_title">添加/编辑资讯</div>
        <div class="form_content">
          <form id="itemForm">
            <div class="item">
              <span class="label"><i class="require">*</i>标题:</span>
              <input
                type="hidden"
                v-model="formInfo.id"
              >
              <input
                type="text"
                name="title"
                v-model="formInfo.title"
              >
            </div>
            <div class="item">
              <span class="label">副标题:</span>
              <input
                type="text"
                name="subTitle"
                v-model="formInfo.subTitle"
              >
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>来源:</span>
              <select
                name="source"
                id="source"
                v-model="formInfo.source"
              >
                <option value="">选择来源</option>
                <option value="0">原创</option>
                <option value="1">转载</option>
              </select>
            </div>
            <div
              class="item"
              id="explain"
              style="display:none"
            >
              <span class="label">转载说明:</span>
              <input
                type="text"
                name="reprintInfo"
              >
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>类型:</span>
              <select
                name="type"
                id="type"
                v-model="formInfo.type"
              >
                <option value="">选择类型</option>
                <option value="1">通知公告</option>
                <option value="5">会议研讨</option>
                <option value="6">专业委员会</option>
                <option value="7">双百计划</option>
                <option value="8">课题研究</option>
                <option value="9">建设指南</option>
                <option value="10">建设规范</option>
                <option value="4">虚仿竞赛</option>
              </select>
            </div>

            <div class="item">
              <span class="label"><i class="require">*</i>日期:</span>
              <input
                type="date"
                name="date"
                placeholder="日期"
                v-model="formInfo.date"
                style="width: 300px;height: 30px;border: 1px solid #ddd;"
              >
            </div>
            <div class="item">
              <span class="label">摘要:</span>
              <textarea
                class="simple"
                name="brief"
                v-model="formInfo.brief"
              ></textarea>
            </div>

            <div class="item">
              <span class="label">上传附件:</span>
              <div class="item-box clearfix">
                <div class="filebox-list">
                  <p
                    v-for="item in this.formInfo.fileList"
                    :key="item.id"
                  >
                    <a :href="item.path">{{ item.name }}</a>
                    <span
                      class="del"
                      @click="delFile(item.id)"
                      title="删除"
                    >×</span>
                  </p>
                </div>
                <span
                  class="button"
                  id="attPath"
                  @click="showFileWindow"
                >上传</span>
              </div>
            </div>
            <div class="item">
              <span class="label">资讯内容:</span>
              <div></div>
            </div>
            <div class="clearfix"></div>
            <vue-ueditor-wrap
              v-model="formInfo.contents"
              :config="editorConfig"
              editor-id="editor-demo-01"
            ></vue-ueditor-wrap>
            <div class="clearfix"></div>
          </form>
        </div>
        <div class="form_control">
          <a
            href="javascript:;"
            class="button close"
            id="form_close"
            @click="hideAddWindow"
          >取消</a>
          <a
            href="javascript:;"
            class="button"
            id="form_submit"
            @click="saveInfo"
          >提交</a>
        </div>
      </div>
      <div class="form_overlay_bg"></div>
    </div>

    <div
      id="file-upload-overlay"
      :style="bigFileWindowShow"
    >
      <div class="file-upload-bg"></div>
      <div class="file-upload">
        <div class="file-upload-type">
          允许上传的文件扩展名：pdf,doc,docx,xsl,xlsx,ppt,pptx <br>
          文件大小限制：{{ maxFileSize }}M
        </div>
        <div
          class="file-upload-close"
          @click="hideFileWindow"
        >×</div>
        <span
          class="file_uploadBtn"
          id="file_uploadBtn"
          style="display: block;"
        >
          选择文件<input
            type="file"
            id="file_fileInput"
            ref="docFile"
            style="display: block;"
            @change="fileChange"
          >
        </span>
        <div
          class="fileBox"
          id="file_uploadBox"
          style="display: block;"
        >
          <span
            class="pauseBtn"
            id="file_pauseBtn"
            style="display: none;"
          >暂停</span>
          <span
            class="goBtn"
            id="file_goBtn"
            style="display:none;"
          >继续</span>
          <div
            class="filename"
            id="file_filename"
            style="display: none;"
          ></div>
          <div
            class="pbar"
            :style="showProgress ? 'display: block' : 'display: none'"
          >
            <div
              class="progress"
              id="file_progress"
              :style="'width: ' + Math.ceil(progressValue * 4.2) + 'px;'"
            ></div>
            <div id="file_percent">{{ progressValue }}%</div>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </div>

  </div>
</template>

<script>
import VueUeditorWrap from 'vue-ueditor-wrap'
import Pagination from "../../components/Pagination";


export default {
  name: "AdminInfo",
  components: {
    VueUeditorWrap,
    Pagination
  },
  data() {
    return {
      queryInfo: {
        page: 1,
        size: 10,
        title: '',
        date: '',
        status: '',
        source: '',
        type: '',
      },
      imgId: 1,
      formInfo: {
        id: 0,
        title: '',
        subTitle: '',
        source: '',
        type: '',
        date: '',
        brief: '',
        fileList: [],
        attPath: '',
        contents: ''
      },
      classShowAddWindow: {},
      classShowImageWindow: {},
      editorConfig: {
        // 访问 UEditor 静态资源的根路径，可参考 https://hc199421.gitee.io/vue-ueditor-wrap/#/faq
        UEDITOR_HOME_URL: '/UE/',
        // 服务端接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
        // serverUrl: '//ueditor.szcloudplus.com/cos',
        serverUrl: this.$store.state.uEditorServerUrl,
        autoHeight: true,
        // imagePathFormat: '/files/{yyyy}{mm}{dd}-{time}{rand:6}',
        // imageUrlPrefix: 'http://localhost:8000'
        initialFrameWidth: '100%',

        initialFrameHeight: 300
      },
      tableData: [],
      tableDataCount: 0,
      bigFileWindowShow: {
        display: 'none'
      },
      showProgress: false,
      progressValue: 0,
      maxFileSize: 200
    }
  },
  methods: {
    showAddWindow() {
      this.classShowAddWindow = {
        visibility: 'visible'
      }
    },
    hideAddWindow() {
      this.classShowAddWindow = {}
      this.formInfo = {
        id: 0,
        title: '',
        subTitle: '',
        source: '',
        type: '',
        date: '',
        brief: '',
        fileList: [],
        attPath: '',
        contents: ''
      }
    },
    showFileWindow() {
      this.bigFileWindowShow = {
        display: 'block'
      }
    },
    hideFileWindow() {
      this.bigFileWindowShow = {
        display: 'none'
      }
    },
    delFile(fileId) {
      for (let i = 0; i < this.formInfo.fileList.length; i++) {
        if (this.formInfo.fileList[i].id == fileId) {
          // delete this.formInfo.fileList[i]
          this.formInfo.fileList.splice(i, 1)
          break
        }
      }
    },
    async saveInfo() {
      if(!this.formInfo.title){
        this.$parent.$parent.showMsg("请输入标题!")
        return false
      }
      if(!this.formInfo.source){
        this.$parent.$parent.showMsg("请选择来源!")
        return false
      }
      if(!this.formInfo.type){
        this.$parent.$parent.showMsg("请选择类型!")
        return false
      }
      if(!this.formInfo.date){
        this.$parent.$parent.showMsg("请选择日期!")
        return false
      }

      this.formInfo.attPath = JSON.stringify(this.formInfo.fileList)
      if(this.formInfo.id){
        let url = '/info/' + this.formInfo.id + '/'
        await this.$http.put(url, this.formInfo).then((data) => {
          if (data.id) {
            this.$parent.$parent.showMsg('修改成功！')
            this.hideAddWindow()
            this.search()
          } else {
            this.$parent.$parent.showMsg('修改失败！')
          }
        }).catch((error) => {
          console.log(error)
          this.$parent.$parent.showMsg('修改失败！')
        })
      }else{
        await this.$http.post('/info/', this.formInfo).then((data) => {
          if (data.id) {
            this.$parent.$parent.showMsg('添加成功！')
            this.hideAddWindow()
            this.search()
          } else {
            this.$parent.$parent.showMsg('添加失败！')
          }
        }).catch((error) => {
          console.log(error)
          this.$parent.$parent.showMsg('添加失败！')
        })
      }

    },
    async search() {
      await this.$http.get('/info/', {
        params: this.queryInfo
      }).then((data) => {
        console.log(data)
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$parent.$parent.showMsg('获取资讯信息失败！')
      })
    },
    async changePublishState(id, status) {
      const msg = status == 0? "取消": ""
      const url = '/info/' + id + '/'
      await this.$http.put(url, {
        status: status
      }).then((data) => {
        console.log(data)
        this.$parent.$parent.showMsg(msg + '发布成功！');
        this.search()
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg(msg + '发布失败！');
      })
    },
    unpublish(id) {
      console.log(id)
      this.$parent.$parent.showConfirm('确定要取消发布吗？', () => {
        this.changePublishState(id, 0)
      });
    },
    publish(id) {
      console.log(id)
      this.$parent.$parent.showConfirm('确定要发布吗？', () => {
        this.changePublishState(id, 1)
      });
    },
    async delRow(id) {
      const url = '/info/' + id + '/'
      await this.$http.put(url, {
        isdel: 1
      }).then((data) => {
        console.log(data)
        this.$parent.$parent.showMsg('删除成功！');
        this.search()
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg('删除失败！');
      })
    },
    askDel(id) {
      this.$parent.$parent.showConfirm('确定要删除吗？', () => {
        this.delRow(id)
      });
    },
    formTableTypeName(type) {
      if (type == '1') {
        return '通知公告'
      } else if (type == '2') {
        return '活动报道'
      } else if (type == '3') {
        return '联盟公告'
      } else if (type == '4') {
        return '虚仿竞赛'
      } else if (type == '5') {
        return '会议研讨'
      } else if (type == '6') {
        return '专业委员会'
      } else if (type == '7') {
        return '双百计划'
      } else if (type == '8') {
        return '课题研究'
      } else if (type == '9') {
        return '建设指南'
      } else if (type == '10') {
        return '建设规范'
      } else {
        return '-'
      }
    },
    async pageCallback(data) {
      this.queryInfo.page = data
      await this.search()
    },
    async querySingle(id) {
      const url = '/info/' + id + '/'
      await this.$http.get(url).then((data) => {
        console.log(data)
        console.log(data.attPath ? JSON.parse(data.attPath) : [])
        this.formInfo = {
          id: id,
          title: data.title,
          subTitle: data.subTitle,
          source: data.source,
          type: data.type,
          date: data.date,
          brief: data.brief,
          fileList: data.attPath ? JSON.parse(data.attPath): [],
          attPath: data.attPath,
          contents: data.contents,
        }
        this.showAddWindow()
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg('数据获取失败！');
      })
    },
    async fileChange() {
      this.showProgress = true

      const fileRealName = this.$refs.docFile.files[0].name
      console.dir(this.$refs.docFile.files[0].size)

      if (this.$refs.docFile.files == 0) {
        this.showProgress = false
        this.progressValue = 0
        return false
      }

      let maxSize = this.maxFileSize * 1024 * 1024
      if (this.$refs.docFile.files[0].size > maxSize) {
        this.$parent.$parent.showMsg('文件大小超出限制！')

        this.showProgress = false
        this.progressValue = 0
        return false
      }

      const uploadUrl = '/info_files/' + fileRealName
      await this.$http.put(uploadUrl, this.$refs.docFile.files[0], {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (e) => {
          console.dir(e)
          this.progressValue = Math.ceil(e.loaded / e.total * 100)
        }
      }).then((data) => {
        window.console.log(data)

        this.showProgress = false
        this.progressValue = 0
        this.hideFileWindow()

        this.formInfo.fileList.push({
          id: this.imgId,
          name: fileRealName,
          path: data
        })
        this.formInfo.attPath = JSON.stringify(this.formInfo.fileList)

        this.imgId++

      }).catch(() => {
        this.showProgress = false
        this.progressValue = 0
        this.hideFileWindow()
        this.$parent.$parent.showMsg('上传失败！')
      })
    },

  },
  mounted() {
    this.search()
  },
  computed: {
    pageInfo() {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    }
  },
}
</script>

<style scoped>
.date_box {
  border: 1px solid #e6e6e6;
  background-color: #f5f7fc;
  width: 150px;
  height: 32px;
  line-height: 32px;
  margin-right: 8px;
  padding-left: 8px;
}
</style>
