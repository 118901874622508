<template>
  <div id="subCenter">
    <div class="subBanner1"></div>
    <div class="search-header">
      <span>搜索结果</span>
      <div>
        
        <i class="iconfont icon-index-copy"></i><span style="cursor: pointer;" @click="toHome">首页</span>&gt;<span>搜索结果</span>
      </div>
    </div>
    <div class="search-main">
      <div class="con" v-if="tableData.length>0">
        <ul id="elList">
          <li class="tips">
            <span>标题</span>
            <span>发布时间</span>
          </li>
          <li
            v-for="item in tableData"
            :key="item.id"
          >
            <router-link
              target="_blank"
              :to="'/www/activity?id=' + item.id"
            ><span>&gt;{{ item.title }}</span><span class="time">{{ item.date }}</span></router-link>
          </li>
        </ul>

        <pagination
          :cur="pageInfo.page"
          :all="pageInfo.all"
          :totalData="pageInfo.totalData"
          :callback="pageCallback"
        ></pagination>
      </div>
      <div class="vipList clearfix"  v-else-if="vipData.length>0">
          <ul id="list" class="clearfix">
            <template v-for="item in vipData" >
            <li :ref="'vipLi' + item.id" :key="item.id" v-if="item.type!==3"
                 @mouseenter="showOrg(item)" @mouseleave="hideOrg(item)">
              <img v-if="item.logo" :src="formatImage(item.logo)">
              <img v-if="!item.logo" src="../../assets/images/vip_default_logo.png">
              <span :data-id="item.id">{{ item.title }}</span>
              <div  class="subList" :style="formatStyle(item)" style="opacity: 1;" >
                <div class="title">本校二级学院会员</div>
                <div class="list_main">
                  <div class="list_img">
                    <div class="school_img">
                      <img v-if="item.logo" :src="formatImage(item.logo)">
                      <img v-if="!item.logo" src="../../assets/images/vip_default_logo.png">
                    </div>
                    <span>{{ item.title }}</span>
                  </div>
                  <ul class="list_info">
                    <li v-for="sub in getSub(item.id)" :key="sub.id">
                      {{ sub.title }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="form_overlay" id="form_overlay" :style="item.show_status==4?formatCompanyStyle(item):''" >
                <div class="mask-bg"></div>
                <div class="form_panel" style="min-height: 444px;">
                  <div class="form_title">企业介绍
                  </div>
                  <div class="form_content clearfix" id="vipInfo">
                    <div class="leftInfo" style="margin-left: 20px;">
                      <img :src="formatImage(item.logo)"
                          style="margin-top: 40px;height: 150px;width: 150px;">
                      <p class="vipTitle">{{ item.title }}</p>
                      <p class="vipInfo"><span class="iconfont"></span>{{item.show_address}}</p>
                      <p class="vipInfo"><span class="iconfont"></span>{{item.show_mainPrincipal}}</p>
                      <p class="vipInfo"><span class="iconfont"></span>{{item.show_mobile1}}</p>
                      <p class="vipInfo"><span class="iconfont"></span>{{item.show_email1}}</p></div>
                    <div class="rightInfo" style="top: auto;left: 370px;width: 370px;min-width: 370px;">
                      <p class="infoTitle">企业简介</p>
                      <p class="brief" style="width: 370px;" v-html="item.show_brief">
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            </template>
          </ul>
      </div>
      <div class="no-res" v-else>
          <i class="iconfont icon-meh"></i>
          <span>没有匹配的数据</span>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../../components/Pagination"
export default {
  name: "Apply",
  components: {
    Pagination
  },
  data() {
    return {
      tableData:[],
      subData:[],
      vipData:[],
      queryInfo: {
        page: 1,
        size: 10,
        status: '1',
        title: '',
      },
      vipInfo:{
        page: 1,
        size: 10,
        like_title: '',
        status: 5,
        isPub:1,
        type:'',
        isdel: 0,
      },
      curCompany: {
        title: '',
        logo: '',
        show_mainPrincipal: '',
        show_mobile1: '',
        show_email1: '',
        show_brief: '',
        show_address: ''
      },
      tableDataCount: 0,
      curCompanyShow: {
        visibility: 'hidden'
      },
      curSchoolShow: {
        visibility: 'hidden'
      },
    }
  },
  created(){
    this.queryInfo.title=this.$route.params.searchText
    this.vipInfo.like_title=this.$route.params.searchText
    if(this.queryInfo.title){
      this.search()
      this.getVipRes()
    }
    
  },
  watch: {
      // 利用watch方法检测路由变化：
      '$route': function (to) {
        this.queryInfo.title=to.params.searchText
        this.vipInfo.like_title=to.params.searchText
        if(this.queryInfo.title){
          this.search()
          this.getVipRes()
        }
    }
  },
  computed: {
    pageInfo() {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    }
  },
  methods: {
    toHome(){
      this.$router.push('/www/index')
    },
    async search() {
      await this.$http.get('/allowany/info/', {
        params: this.queryInfo
      }).then((data) => {
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$parent.$parent.showMsg('获取通知信息失败！')
      })
    },
    async getVipRes(){
      await this.$http.get('/allowany/application/', {
        params: this.vipInfo
      }).then((data) => {
        for (let i = 0; i < data.results.length; i++) {
          data.results[i].showTip = false
          data.results[i].curCompanyShow = false
          data.results[i].left = 0
          data.results[i].top = 0
        }
        this.vipData = data.results
      })
      await this.$http.get('/allowany/application/', {
          params: {
            type: 3,
            status: '5',
            isdel: 0,
            isPub: 1,
            page: 1,
            size: 1000000
          }
        }).then((data) => {
          
          this.subData = data.results
        })
    },
    async pageCallback(data) {
      this.queryInfo.page = data
      await this.search()
    },
    getSub(schoolId) {
      let res = []
      for (let i = 0; i < this.subData.length; i++) {
        if (this.subData[i].schoolId == schoolId) {
          res.push(this.subData[i])
        }
      }
      return res
    },
    formatImage(fileName) {
      return this.$store.state.uploadFileRootUrl + fileName
    },
    formatStyle(item) {
      return item.showTip ?  'display:block;' : ''
    },
    formatCompanyStyle(item) {
      return item.curCompanyShow ?  'visibility:visible' : ''
    },
    showOrg(item) {
      if (item.type == 1) {
        item.showTip = true
      }else if(item.type ==2){
        item.curCompanyShow = true
      }
    },
    hideOrg(item) {
      if (item.type == 1) {
        item.showTip = false
      }else if(item.type ==2){
        item.curCompanyShow = false
      }
    },
    showCompany(item) {
      if (item.type == 1) {
        return
      }
      this.curCompany = {
        title: item.title,
        logo: item.logo,
        mainPrincipal: item.mainPrincipal,
        mobile1: item.mobile1,
        email1: item.email1,
        brief: item.brief,
        address: item.address,
      }
      this.curCompanyShow = {
        visibility: 'visible'
      }
    }
  },
  mounted() {
    
  }
}
</script>

<style scoped>
@import "../../assets/css/vip.css";
@font-face {
  font-family: "iconfont"; /* Project id 2618897 */
  src: url('//at.alicdn.com/t/font_2618897_s18ag2055a.woff2?t=1625451544247') format('woff2'),
       url('//at.alicdn.com/t/font_2618897_s18ag2055a.woff?t=1625451544247') format('woff'),
       url('//at.alicdn.com/t/font_2618897_s18ag2055a.ttf?t=1625451544247') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-meh:before {
  content: "\ebee";
}

.icon-sousuo:before {
  content: "\e603";
}

.icon-index-copy:before {
  content: "\e622";
}

.icon-index-copy{
  font-size:14px;
  color:#999
}
#subCenter {
  padding-top: 0;
  padding-bottom: 90px;
}
.search-header {
  width: 1200px;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color:#333;
  font-size: 20px;
  
}
.search-header>div{
  font-size:16px;
}
.search-header>div span{
  margin:0 5px;
}
.search-main {
  width: 1200px;
  margin: 0 auto 30px;
  padding:10px;
  min-height: 450px;
  background: #fff;
  box-sizing: border-box;
  position:relative;
  overflow: hidden;
}

.no-res{
  display: flex;
  align-items: center;
  color:#333;
  position:absolute;
  left:50%;
  top:50%;
  transform: translate(-50%,-50%);
}
.icon-meh{
  color:#50f282;
  font-size:30px;
  margin-right:5px;
}
.no-res>span{
  font-weight: bold;
  font-size:18px;
}
#elList li{
  border-bottom:1px dashed #eee;
}
#elList li a{
  margin-bottom: 0;
}
#elList li a span{
  display: inline-block;
  width:50%;
  padding-left:20px;
  box-sizing: border-box;
}
.tips{
  background:#eee;
}
.tips span{
  display: inline-block;
  width:50%;
  padding-left:20px;
  box-sizing: border-box;
}
.form_content p.vipInfo{
  text-align: left;
}

.vipList ul li img{
  width: 120px;
  height: 120px;
  object-fit: scale-down;
}

.form_panel{
  padding: 20px;
  min-width: 700px;
  -webkit-box-shadow: #bbb 0px 0px 15px; 
   -moz-box-shadow: #bbb 0px 0px 15px; 
   box-shadow: #bbb 0px 0px 15px; 
  /* margin-left: -350px; */
  /* top: 5%; */
}

.form_title{
  font-weight: normal;
  border-left: 0;
  border-bottom:1px solid #eee;
  padding:0 0 10px 0;
  text-align: left;
}
.form_content p.infoTitle{
  text-align: left;
  
}
.form_content p.brief{
  font-size:15px;
  line-height:2.2;
  text-align: justify;
}
.vipList {
  padding: 0;
  margin-top: 0;
}
.mask-bg{
  width:950px;
  height:500px;
  background:rgba(255,255,255,0);
  position:fixed;
  left:50%;
  top:50%;
  transform: translate(-50%,-50%);
  z-index: 1;
}
</style>
