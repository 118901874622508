<template>
  <div
    id="panel"
    class="panel"
    style="min-height: 696px;"
  >
    <div class="orgTab">
      <span
        :class="changeIndex == 1 ? 'active' : ''"
        @click="changeTab(1),searchInnovate()"
      >
        <a>创新合作伙伴展示</a>
      </span>
      <span
        :class="changeIndex == 2 ? 'active' : ''"
        @click="changeTab(2),searchDirector()"
      >
        <a>理事会员展示</a>
      </span>
      <span
        :class="changeIndex==3? 'active' : ''"
        @click="changeTab(3),searchZoneInfo()"
      >
        <a>企业会员展示</a>
      </span>
    </div>
    <template v-if="changeIndex == 1">
      <form
        id="searchSection"
        onsubmit="return false;"
      >
        <div class="search-line clearfix">
          <input
            v-model="queryInnovate.super_title"
            type="text"
            name="title"
            class="s-input separate"
            placeholder="单位名称"
          >
          <input
            type="submit"
            class="s-btn"
            value="搜索"
            @click="searchInnovate"
          >
        </div>

        <div class="search-line clearfix">

        </div>

      </form>
      <div id="list">
        <table v-if="this.innovateData.length > 0">
          <tbody>
            <tr class="l_header">
              <th class="itemTitle">单位名称</th>
              <th class="itemTitle">联系人</th>
              <th class="itemTitle">电话</th>
              <th class="itemTitle">邮箱</th>
              <th class="itemTitle">操作</th>

            </tr>
            <tr
              class="item"
              v-for="(item,index) in innovateData"
              :key="item.id"
            >
              <td class="itemTitle">
                <a
                  class="a-link"
                  :href="'/www/appview?id=' + item.id"
                  target="_blank"
                >
                  {{ item.super_title }}
                </a>
              </td>
              <td class="itemTitle">{{ item.super_connect }}</td>
              <td class="itemTitle">{{ item.super_phone }}</td>
              <td class="itemTitle">{{ item.super_email }}</td>
              <td class="itemTitle itemaction">
                <span>展示排序</span>
                <div>
                  <i
                    v-if="index!=0"
                    class="iconfont"
                    @click="ascOrder(innovateData,index,1)"
                  >&#xe76d;</i>
                  <i
                    v-if="index!=innovateData.length-1"
                    class="iconfont"
                    @click="descOrder(innovateData,index,1)"
                  >&#xe771;</i>
                </div>
              </td>

            </tr>
          </tbody>
        </table>
        <div
          class="noitems"
          v-if="innovateData.length == 0"
        >没有数据
        </div>
      </div>

    </template>
    <template v-if="changeIndex == 2">
      <form
        id="searchSection"
        onsubmit="return false;"
      >
        <div class="search-line clearfix">
          <input
            v-model="queryDirector.super_title"
            type="text"
            name="title"
            class="s-input separate"
            placeholder="单位名称"
          >
          <input
            type="submit"
            class="s-btn"
            value="搜索"
            @click="searchDirector"
          >
        </div>
        <div class="search-line clearfix">
        </div>
      </form>
      <div id="list">
        <table v-if="directorData.length > 0">
          <tbody>
            <tr class="l_header">
              <th class="itemTitle">单位名称</th>
              <th class="itemTitle">联系人</th>
              <th class="itemTitle">电话</th>
              <th class="itemTitle">邮箱</th>
              <th class="itemTitle">操作</th>
            </tr>
            <tr
              class="item"
              v-for="(item,index) in directorData"
              :key="item.id"
            >
              <td class="itemTitle">
                <a
                  class="a-link"
                  :href="'/www/appview?id=' + item.id"
                  target="_blank"
                >
                  {{ item.super_title }}
                </a>
              </td>
              <td class="itemTitle">{{ item.super_connect }}</td>
              <td class="itemTitle">{{ item.super_phone }}</td>
              <td class="itemTitle">{{ item.super_email }}</td>
              <td class="itemTitle itemaction">
                <span>展示排序</span>
                <div>
                  <i
                    v-if="index!=0"
                    class="iconfont"
                    @click="ascOrder(directorData,index,2)"
                  >&#xe76d;</i>
                  <i
                    v-if="index!=directorData.length-1"
                    class="iconfont"
                    @click="descOrder(directorData,index,2)"
                  >&#xe771;</i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="noitems"
          v-if="directorData.length == 0"
        >没有数据
        </div>
      </div>
    </template>
    <template v-if="changeIndex == 3">
      <form
        id="searchSection"
        onsubmit="return false;"
      >
        <div class="search-line clearfix">
          <input
            v-model="zoneQueryInfo.like_title"
            type="text"
            name="title"
            class="s-input separate"
            placeholder="单位名称"
          >
          <input
            type="submit"
            class="s-btn"
            value="搜索"
            @click="searchZoneInfo"
          >
        </div>

        <div class="search-line clearfix">

        </div>

      </form>
      <div id="list">
        <table v-if="this.zoneTableData.length > 0">
          <tbody>
            <tr class="l_header">
              <th class="itemTitle">单位名称</th>
              <th class="itemTitle">联系人</th>
              <th class="itemTitle">电话</th>
              <th class="itemTitle">邮箱</th>
              <th class="itemCategory">显示到企业专区</th>
              <th class="itemCategory">操作</th>

            </tr>
            <tr
              class="item"
              v-for="(item,index) in zoneTableData"
              :key="item.id"
            >
              <td class="itemTitle">
                {{ item.type == 3 ? item.schoolTitle : item.title }}
              </td>
              <td class="itemTitle">{{ item.contacts }}</td>
              <td class="itemTitle">{{ item.phone2 }}</td>
              <td class="itemTitle">{{ item.email1 }}</td>
              <td class="itemTitle">
                <span
                  :class="item.super_category3 == 1 ? 'ck_select' : 'ck_unselect'"
                  @click="changeSuperCategory3(item)"
                ></span>
              </td>
              <td class="itemTitle itemaction">
                <span>展示排序</span>
                <div>
                  <i
                    v-if="index!=0"
                    class="iconfont"
                    @click="ascOrder(zoneTableData,index,3)"
                  >&#xe76d;</i>
                  <i
                    v-if="index!=zoneTableData.length-1"
                    class="iconfont"
                    @click="descOrder(zoneTableData,index,3)"
                  >&#xe771;</i>
                </div>
              </td>

            </tr>
          </tbody>
        </table>
        <div
          class="noitems"
          v-if="zoneTableData.length == 0"
        >没有数据
        </div>
      </div>

    </template>
    <div
      v-show="isShow"
      class="load"
    >
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "AdminSuperApply",
 
  data() {
    return {
      isShow:false,
      changeIndex:1,
      queryInnovate: {
        page: 1,
        size: 100000,
        super_title: '',
        super_status: '1,2,3',
        isdel: 0,
        super_category1:1,
        ordering:'-super_category1_sort_num'
      },
      queryDirector: {
        page: 1,
        size: 100000,
        super_title: '',
        super_status: '1,2,3',
        isdel: 0,
        super_category2:1,
        ordering:'-super_category2_sort_num'
      },
      zoneQueryInfo: {
        page: 1,
        size: 100000,
        like_title: '',
        status: '5',
        isPub: '1',
        type: 2,
        isdel: 0,
        ordering:'-super_category3_sort_num'
      },
      directorData: [],
      directorDataCount: 0,
      innovateData: [],
      innovateDataCount: 0,
      zoneTableData: [],
      zoneTableDataCount: 0,
    }
  },
 
  methods: {
    changeTab(value){
      this.changeIndex=value
    },
    async searchInnovate() {
      await this.$http.get('/application/', {
        params: this.queryInnovate
      }).then((data) => {
        console.log(data)
        this.innovateData = data.results
        this.innovateDataCount = data.count
        
      }).catch(() => {
        this.$parent.$parent.showMsg('获取通知信息失败！')
      })
    },
    async searchDirector() {
      await this.$http.get('/application/', {
        params: this.queryDirector
      }).then((data) => {
        console.log(data)
        this.directorData = data.results
        this.directorDataCount = data.count
      }).catch(() => {
        this.$parent.$parent.showMsg('获取通知信息失败！')
      })
    },
    async searchZoneInfo() {
      await this.$http.get('/application/', {
        params: this.zoneQueryInfo
      }).then((data) => {
        console.log(data)
        this.zoneTableData = data.results
        this.zoneTableDataCount = data.count
      }).catch(() => {
        this.$parent.$parent.showMsg('获取通知信息失败！')
      })
    },
    async changeSuperCategory3(item) {
      let msg = item.super_category3 == 1 ? '确认取消显示到企业专区？' : '确认要显示到企业专区？'
      this.$parent.$parent.showConfirm(msg, async () => {
        let v = item.super_category3 == 1 ? 0 : 1
        const url = '/application/' + item.id + '/'
        await this.$http.put(url, {
          super_category3: v
        }).then((data) => {
          console.log(data)
          this.$layer.msg('操作成功！');
          this.searchZoneInfo()
        }).catch((error) => {
          console.log(error)
          this.$layer.msg('操作失败！');
        })
      });
    },
    async changeOrder(id,obj){
      const url = '/application/' + id + '/'
       await this.$http.put(url, obj).then((data) => {
          console.log(data)
         
        }).catch((error) => {
          console.log(error)
          this.$layer.msg('操作失败！');
        })
    },
    // 升序
    async ascOrder(arr,index,code){
      let currentNum,preNum,currentId,preId
      let preIndex=index-1
      let obj={}
      let obj1={}
      this.isShow=true
      if(code===1){
        currentNum=arr[index].super_category1_sort_num
        preNum=arr[preIndex].super_category1_sort_num
        currentId=arr[index].id
        preId=arr[preIndex].id
        console.log(index,currentNum)
        console.log(preIndex,preNum)
        if(currentNum<preNum){
          obj.super_category1_sort_num=preNum
          await this.changeOrder(currentId,obj)
          obj1.super_category1_sort_num=currentNum
          await this.changeOrder(preId,obj1)
          await this.searchInnovate()
          this.isShow=false
        }
      }else if(code===2){
        currentNum=arr[index].super_category2_sort_num
        preNum=arr[preIndex].super_category2_sort_num
        currentId=arr[index].id
        preId=arr[preIndex].id
        if(currentNum<preNum){
          obj.super_category2_sort_num=preNum
          await this.changeOrder(currentId,obj)
          obj1.super_category2_sort_num=currentNum
          await this.changeOrder(preId,obj1)
          await this.searchDirector()
          this.isShow=false
        }
      }else{
        currentNum=arr[index].super_category3_sort_num
        preNum=arr[preIndex].super_category3_sort_num
        currentId=arr[index].id
        preId=arr[preIndex].id
        if(currentNum<preNum){
          obj.super_category3_sort_num=preNum
          await this.changeOrder(currentId,obj)
          obj1.super_category3_sort_num=currentNum
          await this.changeOrder(preId,obj1)
          await this.searchZoneInfo()
          this.isShow=false
        }
      } 
    },
    // 降序
   async descOrder(arr,index,code){
      let currentNum,nextNum,currentId,nextId
      let nextIndex=index+1
      let obj={}
      let obj1={}
      this.isShow=true
      if(code===1){
        currentNum=arr[index].super_category1_sort_num
        nextNum=arr[nextIndex].super_category1_sort_num
        currentId=arr[index].id
        nextId=arr[nextIndex].id
        if(currentNum>nextNum){
          obj.super_category1_sort_num=nextNum
          await this.changeOrder(currentId,obj)
          obj1.super_category1_sort_num=currentNum
          await this.changeOrder(nextId,obj1)
          await this.searchInnovate()
          this.isShow=false
        }
      }else if(code===2){
        currentNum=arr[index].super_category2_sort_num
        nextNum=arr[nextIndex].super_category2_sort_num
        currentId=arr[index].id
        nextId=arr[nextIndex].id
        if(currentNum>nextNum){
          obj.super_category2_sort_num=nextNum
          await this.changeOrder(currentId,obj)
          obj1.super_category2_sort_num=currentNum
          await this.changeOrder(nextId,obj1)
          await this.searchDirector()
          this.isShow=false
        }
      }else{
        currentNum=arr[index].super_category3_sort_num
        nextNum=arr[nextIndex].super_category3_sort_num
        currentId=arr[index].id
        nextId=arr[nextIndex].id
        if(currentNum>nextNum){
          obj.super_category3_sort_num=nextNum
          await this.changeOrder(currentId,obj)
          obj1.super_category3_sort_num=currentNum
          await this.changeOrder(nextId,obj1)
          await this.searchZoneInfo()
          this.isShow=false
        }
      } 
    }
  },
  mounted() {
    this.searchInnovate()
  },
}
</script>

<style scoped>
.orgTab {
  height: 52px;
  border-bottom: 1px solid #dddddd;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.orgTab span {
  display: inline-block;
  line-height: 50px;
  font-size: 14px;
  color: #333333;
  margin-right: 15px;
}

.orgTab span > a {
  display: inline-block;
  line-height: 50px;
  box-sizing: border-box;
}

.orgTab span.active {
  border-bottom: 2px solid #5473e8;
  font-weight: bold;
}
.ck_select {
  width: 16px;
  height: 16px;
  display: inline-block;
  background-image: url(../../assets/images/ck_select.png);
}

.ck_unselect {
  width: 16px;
  height: 16px;
  display: inline-block;
  background-image: url(../../assets/images/ck_unselect.png);
}

.action a {
  display: inline-block;
  padding: 0 !important;
  padding-right: 17px !important;
  word-wrap: break-word;
  text-align: left;
}

.btncolor {
  border: 1px solid #4976e8 !important;
  background-color: #4976e8 !important;
}

.form_panel {
  margin: 0;
  transform: translate(-50%, -50%);
}

.form_content table {
  border-collapse: collapse;
  text-align: center;
  background: #fff;
  width: 100%;
  margin: 0 auto;
  border-left: 1px solid #f0f0f3;
  border-top: 1px solid #f0f0f3;
}

.form_content tr.l_header {
  border: 1px solid #f0f0f3;
  background: #f5f7fc;
  border: none;
  color: #666;
}

.form_content table th {
  height: 44px;
  background: #f5f7fc;
  line-height: 44px;
  border-right: 1px solid #f0f0f3;
  border-bottom: 1px solid #f0f0f3;
  font-weight: lighter;
}

.form_content td {
  padding: 2px 5px;
  border-right: 1px solid #f0f0f3;
  border-bottom: 1px solid #f0f0f3;
  color: #959595;
}

#expert-search {
  margin-bottom: 10px;
}

#expert-search .s-input,
#expert-search .s-select,
#expert-search .s-checkbox {
  border: 1px solid #e6e6e6;
  background: #f5f7fc;
  height: 32px;
  line-height: 34px;
  padding-left: 10px;
  text-indent: 0;
  width: auto;
  border-radius: 2px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

#expert-search .s-btn1 {
  border: 1px solid #4976e8;
  background: #4976e8;
  height: 34px;
  line-height: 34px;
  text-align: center;
  color: #fff;
  padding: 0px 12px;
  cursor: pointer;
  border-radius: 2px;
  vertical-align: middle;
  display: inline-block;
}
.itemaction {
  display: flex;
  align-items: center;
  justify-content: center;
}
.itemaction div {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.itemaction div i {
  cursor: pointer;
}
.load {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  z-index: 2;
  font-size: 20px;
}
.spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #fff;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
