<template>
  <div style="height: 100%">
    <!--    <div>Login</div>-->
    <!--    <div>-->
    <!--      <input type="text" v-model="loginForm.username">-->
    <!--      <br>-->
    <!--      <input type="password" v-model="loginForm.password">-->
    <!--      <br>-->
    <!--      <button @click="login">登陆</button>-->
    <!--    </div>-->
    <div id="layout">
      <div id="north"></div>
      <div id="menu"></div>
      <div
        id="center"
        style="min-height: 420px;"
      >

        <div class="nav">
          <div class="logo">
            <router-link to="/">
              <img src="../assets/images/logo2.png">
            </router-link>
          </div>
        </div>
        <div id="login_banner"></div>
        <div
          class="login"
          id="state_login"
        >
          <div class="login_title">虚拟仿真实验教学创新联盟平台-登录</div>
          <form
            class="login_form"
            id="loginForm"
          >
            <div class="login_item">
              <input
                class="ipt"
                id="user"
                type="text"
                autocomplete="off"
                name="phone"
                placeholder="手机号"
                required=""
                autofocus=""
                v-model="loginForm.username"
                @keydown="enterEvent"
              >
            </div>
            <div class="login_item">
              <input
                class="ipt"
                id="password"
                type="password"
                autocomplete="new-password"
                name="password"
                placeholder="密码"
                required=""
                v-model="loginForm.password"
                @keydown="enterEvent"
              >
            </div>
            <div
              class="login_item item-verify"
              v-if="verify.show"
            >
              <input
                class="ipt verify"
                type="text"
                autocomplete="off"
                name="verify"
                placeholder="验证码"
                required=""
                v-model="loginForm.code"
                @keydown="enterEvent"
              >
              <span
                class="verify-code"
                @click="getVerifyCode"
              >
                <img
                  :src="verify.path"
                  onload="verify.show = true"
                >
              </span>
            </div>
          </form>
          <div
            class="login_btn"
            id="loginBut"
            @click="login"
          >立即登录</div>
          <div class="login_remenber_box clearfix">
            <div class="login_resigter">
              <router-link to="/www/register">注册</router-link>
            </div>
            <div class="login_remenber">
              <router-link to="/www/forgotpwd">忘记密码</router-link>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div
      id="dialog_overlay"
      style="visibility: hidden;"
    >
      <div id="dialog_bg"></div>
      <div
        id="dialog_panel"
        style="margin-left: -136px; margin-top: -54px;"
      >
        <div id="dialog_panel_bg"></div>
        <div id="dialog_title">提示</div>
        <div id="dialog_content"></div>
        <div id="dialog_control">
          <a
            href="javascript:void(0)"
            id="dialog_close"
            class="button"
            style="display:none;"
          >取消</a>
          <a
            href="javascript:void(0)"
            id="dialog_ok"
            class="button"
          >确认</a>
        </div>
      </div>
    </div>
    <div
      id="page-loading"
      style="display: none;"
    ></div>
    <a
      href="javascript:;"
      id="toTop"
      style="display: none;"
    >
      <span id="toTopHover"></span><i class="move-to-up iconfont"></i>
    </a>
  </div>
</template>

<script>

export default {
  name: 'Login',
  data() {
    return {
      // 登录表单
      loginForm: {
        username: '',
        password: '',
        code: '',
        rel_username: ''
      },
      verify: {
        path: '',
        code: '',
        show: false
      },
      clientId: ''
    }
  },
  methods: {
    async login() {

      if(!this.loginForm.username){
        this.$layer.msg('请输入用户名！')
        return
      }
      if(!this.loginForm.password){
        this.$layer.msg('请输入密码！')
        return
      }
      if(this.verify.show && !this.loginForm.code){
        this.$layer.msg('请输入验证码！')
        return
      }
      // if(this.verify.show && this.loginForm.code.toLowerCase() != this.verify.code.toLowerCase()){
      //   this.$layer.msg('验证码错误！')
      //   return
      // }

      let checkLoginCount = false

      await this.$http.get('/login_fail_log/', {
        params: {
          username: this.loginForm.username
        }
      }).then((data) => {
        if(data.count > 5){
          checkLoginCount = true
        }
      })

      if(checkLoginCount){
        this.$layer.msg('五分钟内登陆失败次数超过5次，请稍候尝试！')
        return false;
      }

      this.loginForm.rel_username = this.loginForm.username

      let mobileReg = /^1[0-9]{10}$/;
      if(mobileReg.test(this.loginForm.username)){
        await this.$http.get('/allowany/userprofile/', {
          params: {
            eq_mobile: this.loginForm.username
          }
        }).then((data) => {
          console.log(data)
          if(data.count > 0){
            this.loginForm.rel_username = data.results[0]['username']
          }
        })
      }

      await this.$http.get('/pwd_checked/', {
        params: {
          username: this.loginForm.rel_username
        }
      }).then(async (data) => {
        let login_checked = data
        if(login_checked.pwdChecked){
          // 登录方法
          await this.doLogin()
        }else{
          await this.checkOnlineUser()
        }
      }).catch((error) => {
        console.log(error)
        // if(error[0]){
        //   this.$layer.msg(error[0])
        // }
        this.$layer.msg('账号或密码输入错误！')
      })

    },
    async doLogin() {

      await this.$http.post('/api-token-auth/', {
        username: this.loginForm.rel_username,
        password: this.loginForm.password,
        code: this.loginForm.code,
        clientId: this.clientId
      }).then(async (data) => {
        if (!data.token) {
          await this.getVerifyCode()
          await this.insertLoginLog()
          return this.$layer.msg('账号或密码输入错误！')
        }
        window.localStorage.clear()
        // 登录后添加token到vuex中
        // this.$cookies.set('token', data.token)
        this.$store.commit('setToken', data.token)
        this.$store.commit('setLastUseTime', parseInt(Date.parse(new Date()) / 1000))

        await this.getUserInfo()
      }).catch(async (error) => {
        console.log(error)
        await this.getVerifyCode()
        await this.insertLoginLog()
        this.$layer.msg('账号或密码输入错误！')
      })

    },
    async checkOnlineUser() {

      await this.$http.post('/pwd_checked/', {
        username: this.loginForm.rel_username,
        password: this.loginForm.password
      }).then(async (data) => {
        console.log('check online')
        console.log(data)
        if(data["code"] == 0){
          // 验证成功
          await this.doLogin()
        }else if(data["code"] == 2){
          // 无用户
          await this.doLogin()
        } else{
          await this.insertLoginLog()
          this.$layer.msg(data["msg"])
        }
      }).catch((error) => {
        console.log(error)
      })

    },

    async getUserInfo() {
      await this.$http.post('/login_user/', {}).then(async (data) => {
        console.log(data)
        this.$store.commit('setUserInfo', data)

        if('' + data.pwdChecked == '1') {
          console.log(123)
        }

        if(data.isFirstLog == '1' && !data.mobile){
          this.$router.push('/www/bindphone')
        }else{
          this.$router.push('/')
        }
      }).catch((error) => {
        console.log(error)
        this.$layer.msg('登陆失败！')
      })
    },
    async getVerifyCode(){
      await this.$http.get('/login_code/?clientId=' + this.clientId).then(async (data) => {
        this.verify.path = this.$store.state.rootUrl + data.path
        this.verify.code = data.code
        this.verify.show = true
      }).catch((error) => {
        console.log(error)
      })
    },

    enterEvent(e){
      if(e.keyCode == 13){
        this.login()
      }
    },

    async insertLoginLog(){
      await this.$http.post('/login_fail_log/', {
        username: this.loginForm.username
      }).then((data) => {
        console.log(data)
      })
    },

    generateUUID () {
      var d = new Date().getTime()
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (d + Math.random()*16)%16 | 0
        d = Math.floor(d/16)
        return (c=='x' ? r : (r&0x3|0x8)).toString(36)
      })
      this.clientId = uuid
    },
  },
  async mounted() {
    await this.generateUUID()
    await this.getVerifyCode()
  }
}
</script>

<style scoped>
html {
  height: 100%;
}

#layout {
  height: 100%;
  background: url("../assets/images/bigbg.jpeg") no-repeat;
  background-size: cover;
  visibility: visible !important;
}

.nav {
  width: 1200px;
  margin: 0 auto;
}

.logo {
  padding: 10px 0;
}

.login {
  margin: 45px auto;
  padding: 30px;
  width: 450px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background: #fff;
}

.single span {
  margin-right: 20px;
}
/*.register {*/

/*    margin: auto;*/

/*    padding:30px;*/

/*    width:390px;*/

/*    -webkit-border-radius: 5px;*/

/*    -moz-border-radius: 5px;*/

/*    border-radius: 5px;*/

/*    background: #fff;*/

/*}*/
.login_title {
  color: #000;
  font-size: 19px;
  text-align: center;
}

.login_item {
  margin: 30px 0;
  position: relative;
  border: 1px solid #c9c9c9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  box-sizing: border-box;
}

.login_item:hover {
  border: 1px solid #50abf2;
}

.register_item .code {
  background: #b1b0b0;
  width: 85px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  color: #fff;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}

.item1:before {
  position: absolute;
  content: "";
  top: 12px;
  left: 10px;
  width: 22px;
  height: 22px;
  background: url(/images/spriteb.png) no-repeat -79px -275px;
}

.item1:hover:before {
  background: url(/images/spriteb.png) no-repeat -79px -359px;
}

.item2:before {
  position: absolute;
  content: "";
  top: 12px;
  left: 10px;
  width: 17px;
  height: 22px;
  background: url(/images/spriteb.png) no-repeat -79px -317px;
}

.item2:hover:before {
  background: url(/images/spriteb.png) no-repeat -79px -401px;
}

#login_banner {
  width: 100%;
  height: 100%;
  /*background:url("../images/login.png") no-repeat center center;*/
}

.ipt {
  width: 450px;
  padding: 0 20px;
  height: 47px;
  line-height: 47px;
  border: none;
  background: none;
}

.verify {
  width: 180px;
}

.verify-code {
  height: 47px;
  width: 130px;
  vertical-align: middle;
  float: right;
  /*background: #c1c1c1;*/
  text-align: center;
  line-height: 47px;
  cursor: pointer;
}

.verify-code img {
  vertical-align: middle;
  height: 47px;
  width: 120px;
  display: block;
  float: right;
}

.login_btn {
  width: 100%;
  margin: 20px auto;
  height: 45px;
  line-height: 45px;
  text-align: center;
  color: #fff;
  background: #216acd;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  cursor: pointer;
}

.login_btn:hover {
  background: #447fd0;
}

.login_remenber_box {
  margin: 0 0 20px 0;
}

.login_remenber {
  float: right;
}

.login_remenber a {
  color: #373945;
  text-decoration: underline;
}

.login_resigter {
  float: left;
}

.login_resigter a {
  color: #373945;
  text-decoration: underline;
}
.item-verify {
  position: relative;
  overflow: hidden;
}
.item-verify input {
  width: 100%;
}
.item-verify span {
  position: absolute;
  right: 0;
  top: 0;
}
.ipt {
  outline: none;
}

@media screen and (max-width: 640px) {
  #center {
    padding: 0 10px;
  }
  .nav {
    width: 100%;
  }

  .logo {
    text-align: center;
    padding-top: 40px;
  }

  .logo img {
    width: 280px;
  }

  .login,
  .ipt {
    width: 100%;
    box-sizing: border-box;
  }
  .login {
    margin: 20px auto 0;
  }
  .login_title {
    font-size: 16px;
  }
}
</style>
