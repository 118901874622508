<template>
  <div id="content1" class="pull-right">

    <div class="center_content" style="min-height: 458px;">
      <div class="person clearfix">
        <ul id="elList">
          <li>
            <div class="info">姓名：<span>{{ this.$store.state.userInfo.name }}</span>
              <a href="javascript:;" class="change-email" @click="showUpdateNameWindow">修改</a>
            </div>
          </li>
          <li>
            <div class="info">身份：<span>{{
                this.$store.state.userInfo.role in this.$store.state.dict_role ? this.$store.state.dict_role[this.$store.state.userInfo.role] : '-'
              }}</span></div>
          </li>
          <li>
            <div class="info">性别：<span>{{
                this.$store.state.userInfo.gender in this.$store.state.dict_gender ? this.$store.state.dict_gender[this.$store.state.userInfo.gender] : '-'
              }}</span>
              <a href="javascript:;" class="change-email" @click="showUpdateGenderWindow">修改</a>
            </div>
          </li>
          <li v-if="this.$store.state.userInfo.role == 3">
            <div class="info">省份：<span>{{ provinceFormater(this.$store.state.userInfo.province) }}</span></div>
          </li>
          <li v-if="this.$store.state.userInfo.role == 3">
            <div class="info">学校：<span>{{ schoolFormater(this.$store.state.userInfo.school) }}</span></div>
          </li>
          <li v-if="showPhone">
            <div class="info">手机号：
              <span>{{ this.$store.state.userInfo.mobile ? this.$store.state.userInfo.mobile : '-' }}</span>
              <a href="/www/bindphone" class="change-phone">
                {{ this.$store.state.userInfo.mobile ? '修改' : '绑定' }}
              </a>
            </div>
          </li>
        </ul>
        <div class="head">
          <div class="userhead">
            <img v-if="this.$store.state.userInfo.avatar == ''" src="../../assets/images/user.png">
            <img v-if="this.$store.state.userInfo.avatar != ''"
                 :src="this.$store.state.uploadFileRootUrl + this.$store.state.userInfo.avatar">
          </div>
          <div class="upload" id="uploadimg" @click="showUpdateImageWindow">修改头像</div>
        </div>
      </div>
    </div>

    <div class="form_overlay" id="form_overlay" :style="classShowUpdateGender">
      <div class="form_overlay_bg"></div>
      <div class="form_panel" >
        <div class="form_title">修改性别</div>
        <div class="form_content">
          <form id="itemForm">
            <div class="item hide">
              <span class="label"><i class="require">*</i>用户名</span>
              <input type="text" class="form-control" disabled="disabled" name="username">
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>性别</span>
              <select name="gender" v-model="gender">
                <option value="">选择性别</option>
                <!--                <option v-for="key in this.$store.state.dict_gender" v-bind:key="key" :value="key">-->
                <!--                  {{ this.$store.state.dict_gender[key] }}-->
                <!--                </option>-->
                <option value="M">男</option>
                <option value="F">女</option>
              </select>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a href="javascript:;" class="button close" id="form_close" @click="hideUpdateGenderWindow">取消</a>
          <a href="javascript:;" class="button" id="form_submit" @click="confirmSaveGender">保存</a>
        </div>
      </div>
    </div>

    <div class="form_overlay" id="name_overlay" :style="classShowUpdateName">
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">修改姓名</div>
        <div class="form_content">
          <form id="nameForm">
            <div class="item">
              <span class="label"><i class="require">*</i>姓名</span>
              <input type="text" class="form-control" name="name" v-model="name">
            </div>
          </form>
        </div>
        <div class="form_control">
          <a href="javascript:;" class="button close" id="name_close" @click="hideUpdateNameWindow">取消</a>
          <a href="javascript:;" class="button" id="name_submit" @click="confirmSaveName">保存</a>
        </div>
      </div>
    </div>

    <div id="dialog_overlay" :style="classShowUpdateImage" class="">
      <div id="dialog_bg"></div>
      <div id="dialog_panel" class="" style="margin-left: -311px; margin-top: -192px;">
        <div id="dialog_panel_bg"></div>
        <div id="dialog_title">选择图片</div>
        <div id="dialog_content">
          <div class="imgType" style="margin-bottom:10px;" id="imgType">允许上传的图片格式：jpg</div>
          <div class="imageSelector clearfix">
            <div id="thumbPreview"><img src="../../assets/images/user.png" ref="headImg"></div>
            <div class="image-file-content">
              <span class="image-file-btn" style="display:inline-block">
                <span class="image-file-txt" style="display:none;">选择图片</span>
                <input id="imageFile" type="file" name="imageFile" accept="image/*"
                       ref="imageFile" @change="imageFileChange">
              </span>
              <div class="image-file-tip" style="padding: 10px 0px; font-size: 13px; color: rgb(153, 153, 153);">
                图片格式：JPG/PNG
              </div>
              <div class="image-file-error" style="display:none;padding:10px 0;font-size:13px;color:#ff0000;"></div>
            </div>
          </div>
        </div>
        <div id="dialog_control">
          <a href="javascript:void(0)" @click="hideUpdateImageWindow" id="dialog_close" class="button"
             style="display: inline-block;">取消</a>
          <a href="javascript:void(0)" @click="confirmHeadImage" id="dialog_ok" class="button"
             style="display: inline-block;">确认</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Profile",
  data() {
    return {
      classShowUpdateName: {},
      classShowUpdateImage: {},
      classShowUpdateGender: {},
      uploaded_img: '',
      name: '',
      gender: '',
      dictAllSchool: {},
    }
  },
  computed: {
    showPhone(){
      return this.$store.state.userInfo.isFirstLog
    }
  },
  methods: {
    showUpdateNameWindow() {
      this.classShowUpdateName = {
        visibility: 'visible'
      }
    },
    hideUpdateNameWindow() {
      this.classShowUpdateName = {}
    },
    showUpdateImageWindow() {
      this.classShowUpdateImage = {
        visibility: 'visible'
      }
    },
    hideUpdateImageWindow() {
      this.classShowUpdateImage = {}
    },
    showUpdateGenderWindow() {
      this.classShowUpdateGender = {
        visibility: 'visible'
      }
    },
    hideUpdateGenderWindow() {
      this.classShowUpdateGender = {}
    },
    async imageFileChange() {
      const uploadUrl = '/files/' + this.$refs.imageFile.files[0].name
      await this.$http.put(uploadUrl, this.$refs.imageFile.files[0]).then((data) => {
        this.uploaded_img = data
        this.$refs.headImg.src = this.$store.state.uploadFileRootUrl + data
      }).catch(() => {
        alert('上传失败')
      })
    },
    async confirmHeadImage() {
      const url = '/userprofile/' + this.$store.state.userInfo.profile_id + '/'
      await this.$http.put(url, {
        avatar: this.uploaded_img
      }).then((data) => {
        console.log(data)
        let userInfo = JSON.parse(JSON.stringify(this.$store.state.userInfo))
        userInfo.avatar = this.uploaded_img
        this.$store.commit('setUserInfo', userInfo)
        // this.$store.state.userInfo.avatar = this.uploaded_img
        this.hideUpdateImageWindow()
      }).catch((error) => {
        console.log(error)
        alert('修改失败！')
      })
    },
    async confirmSaveName() {
      if(this.name.trim() == ''){
        this.$layer.msg('请输入姓名')
        return false;
      }

      if(this.name.length < 2){
        this.$layer.msg('姓名长度必须大于2')
        return false;
      }

      if(this.name.length > 20){
        this.$layer.msg('姓名过长')
        return false;
      }

      const url = '/userprofile/' + this.$store.state.userInfo.profile_id + '/'
      await this.$http.put(url, {
        name: this.name
      }).then((data) => {
        console.log(data)
        let userInfo = JSON.parse(JSON.stringify(this.$store.state.userInfo))
        userInfo.name = this.name
        this.$store.commit('setUserInfo', userInfo)
        // this.$store.state.userInfo.name = this.name
        this.hideUpdateNameWindow()
      }).catch((error) => {
        console.log(error)
        alert('修改失败！')
      })
    },
    async confirmSaveGender() {
      if(this.gender == ''){
        this.$layer.msg('请选择性别')
        return false;
      }

      const url = '/userprofile/' + this.$store.state.userInfo.profile_id + '/'
      await this.$http.put(url, {
        gender: this.gender
      }).then((data) => {
        console.log(data)
        let userInfo = JSON.parse(JSON.stringify(this.$store.state.userInfo))
        userInfo.gender = this.gender
        this.$store.commit('setUserInfo', userInfo)
        // this.$store.state.userInfo.gender = this.gender
        this.hideUpdateGenderWindow()
      }).catch((error) => {
        console.log(error)
        alert('修改失败！')
      })
    },

    async getAllSchool() {
      await this.$http.get('/school/', {
        params: {
          page: 1,
          size: 1000000
        }
      }).then(async (data) => {
        console.log(data)
        let dict_result = {}
        for (let i = 0; i < data.results.length; i++) {
          dict_result[data.results[i].id.toString()] = data.results[i].title
        }
        this.dictAllSchool = dict_result
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg('获取专业类信息失败！')
      })
    },
    provinceFormater(province) {
      if(province in this.$store.state.dict_province){
        return this.$store.state.dict_province[province]
      }
      return '-'
    },
    schoolFormater(school) {
      if(school in this.dictAllSchool){
        return this.dictAllSchool[school]
      }
      return '-'
    },
  },
  mounted() {
    if(this.$store.state.userInfo.role == 3){
      this.getAllSchool()
    }
  }
}
</script>

<style scoped>
@import "../../assets/css/dialog.css";
@import "../../assets/css/common.css";
@import "../../assets/css/header.css";
@import "../../assets/css/footer.css";
@import "../../assets/css/pagination.css";
@import "../../assets/css/common(1).css";
@import "../../assets/css/form.css";
@import "../../assets/css/my-profile.css";

#elList li a {
  display: inline;
}

#elList li {
  border-bottom: none;
  margin: 6px 0px 6px 0px;
}

.center_content {
  width: 900px !important;
  margin: 0px !important;
}
</style>
