<template>
  <div>
    <div class="breadcrumb-nav">
      <a href="/">首页</a>&gt;&gt;
      <a href="/www/organization/chairman">组织机构</a>&gt;&gt;高职领域委员会
    </div>
    <div class="subRight">
      <div class="subRightTit">
        <span>高职领域委员会</span>
      </div>
      <div class="con">
        <table
            cellspacing="0"
            cellpadding="0"
            class="orgTbl m-hide"
            v-for="(gao, index) in data" :key="index"
        >
          <tbody>
          <tr>
            <th
                colspan="5"
                width="759"
            >{{ gao.gao_name }}</th>
          </tr>
          <tr>
            <td class="thead">职位</td>
            <td class="thead">姓名</td>
            <td class="thead">职称/职务</td>
            <td class="thead">所在部门</td>
            <td class="thead">工作单位</td>
          </tr>
          <tr
              v-for="item in gao.data"
              :key="item.id"
          >
            <td>{{ item.position }}</td>
            <td>{{ item.username }}</td>
            <td>{{ item.duty }}</td>
            <td>{{ item.department }}</td>
            <td>{{ item.org_name }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrgGao",
  data (){
    return {
      data: []
    }
  },
  methods: {
    async getData(){
      await this.$http.get('/allowany/org_gao/', {
        params: {
          page: 1,
          size: 10000,
          is_show: 1,
        }
      }).then((data) => {
        console.log(data)
        // this.data = data.results
        for (let i = 0; i < data.results.length; i++) {
          // 查找
          let hasGaoName = false;
          for (let j = 0; j < this.data.length; j++) {
            if (data.results[i].gao_name === this.data[j].gao_name) {
              hasGaoName = true;
              this.data[j].data.push(data.results[i])
            }
          }
          if (!hasGaoName) {
            this.data.push({
              gao_name: data.results[i].gao_name,
              data: [data.results[i]]
            })
          }
          console.log(this.data)
        }
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped>
td {
  padding: 10px 20px;
}
</style>
