<template>
  <div id="panel" class="panel" style="min-height: 696px;">
    <form id="searchSection" class="search-form" onsubmit="return false;">
      <div class="content-title">
        <p>{{state?'查看':'编辑'}}指南</p>
        <div class="btns">
          <button type="button" v-if="$route.query.state==0" class="save-submit-btn" id="SaveSubmitBtn" @click="saveInfo(1)">保存并提交</button>
          <button type="button" v-if="!state" class="save-btn" id="SaveBtn" @click="saveInfo(2)">保存</button>
          <button type="button" class="back-btn" id="BackBtn" @click="$router.go(-1)">返回</button>
        </div>
      </div>
      <div class="content-form">
        <form id="editForm" class="edit-form">
          <div class="form-row" id="numBox" v-if="$route.query.state!=0">
            <div class="form-col">
              <div class="form-group">
                <label for="num">编号</label>
                <input type="text" readonly v-model="formInfo.num" class="form-input plaintext" id="num"/>
              </div>
            </div>
            <div class="form-col">
              <div class="form-group">
                <label for="num">创建时间</label>
                <input type="text" readonly v-model="formInfo.created" class="form-input plaintext" id="num"/>
              </div>
            </div>
          </div>
          <div class="form-row" id="numBox">
            <div class="form-col">
              <div class="form-group">
                <label class="required" for="courseTitle">课程名称</label>
                <input type="text" v-model="formInfo.courseTitle" :disabled="state" class="form-input" id="courseTitle" placeholder="请输入课程名称"/>
              </div>
            </div>
          </div>
          <div class="form-row" id="numBox">
            <div class="form-col">
              <div class="form-group">
                <label class="required" for="courseTitle">领域</label>
                <select name="status" class="form-input" :disabled="state" placeholder="请选择领域" v-model="formInfo.categorie01" @change="get_category(2)">
                  <option value=0>请选择领域</option>
                  <option :value="item.code" v-for="item in category01_dict" :key="item.code">{{ item.title }}</option>
                </select>
              </div>
            </div>
            <div class="form-col">
              <div class="form-group">
                <label class="required" for="courseTitle">专业类</label>
                <select name="status" class="form-input" :disabled="state" placeholder="请选择专业类" @change="get_category(3)" v-model="formInfo.categorie02">
                  <option value=0>请选择专业类</option>
                  <option :value="item.code" v-for="item in category02_dict" :key="item.code">{{ item.title }}</option>
                </select>
              </div>
            </div>
            <div class="form-col">
              <div class="form-group">
                <label class="required" for="courseTitle">适用专业</label>
                <select name="status" class="form-input" :disabled="state" placeholder="请选择适用专业" v-model="formInfo.categorie03">
                  <option value=0>请选择适用专业</option>
                  <option :value="item.code" v-for="item in category03_dict" :key="item.code">{{ item.title }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-row" id="numBox">
            <div class="form-col">
              <div class="form-group">
                <label class="required" for="courseTitle">课程类型</label>
                <select name="status" class="form-input" :disabled="state" placeholder="请选择课程类型" v-model="formInfo.courseType">
                  <option value="">请选择课程类型</option>
                  <option value="1">专业基础</option>
                  <option value="2">公共基础</option>
                  <option value="3">专业</option>
                </select>
              </div>
            </div>
            <div class="form-col">
              <div class="form-group">
                <label class="required" for="courseTitle">实验类型</label>
                <select name="status" class="form-input" :disabled="state" placeholder="请选择实验类型" v-model="formInfo.experimentType">
                  <option value="">请选择实验类型</option>
                  <option value="1">综合</option>
                  <option value="2">验证</option>
                </select>
              </div>
            </div>
            <div class="form-col">
              <div class="form-group">
                <label class="required" for="courseTitle">建议学时</label>
                <select name="status" class="form-input" :disabled="state" placeholder="请选择建议学时" v-model="formInfo.hours">
                  <option value="">请选择建议学时</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-row" id="numBox">
            <div class="form-col">
              <div class="form-group">
                <label class="required" for="courseTitle">实验项目方向</label>
                <textarea type="text" v-model="formInfo.direction" rows="3" class="form-textarea" id="direction" :disabled="state" placeholder="请输入实验项目方向"></textarea>
              </div>
            </div>
          </div>
          <div class="form-row" id="numBox">
            <div class="form-col">
              <div class="form-group">
                <label class="required" for="courseTitle">必要性</label>
                <textarea type="text" v-model="formInfo.necessity" rows="3" class="form-textarea" id="direction" :disabled="state" placeholder="请输入必要性"></textarea>
              </div>
            </div>
          </div>
          <div class="form-row" id="numBox">
            <div class="form-col">
              <div class="form-group">
                <label class="required" for="courseTitle">内容简介</label>
                <textarea type="text" v-model="formInfo.brief" rows="3" class="form-textarea" id="direction" :disabled="state" placeholder="请输入内容简介"></textarea>
              </div>
            </div>
          </div>
          <div class="form-row" id="numBox">
            <div class="form-col">
              <div class="form-group">
                <label for="courseTitle">建议实验项目</label>
                <textarea type="text" v-model="formInfo.suggest" rows="3" class="form-textarea" id="direction" :disabled="state" placeholder="请输入建议实验项目"></textarea>
              </div>
            </div>
          </div>
          <div class="form-row" id="numBox">
            <div class="form-col">
              <div class="form-group">
                <label for="courseTitle">所属单位</label>
                <input type="text" v-model="formInfo.org_name" :disabled="state" class="form-input" id="courseTitle" placeholder="请输入所属单位"/>
              </div>
            </div>
          </div>
          <div class="form-row" id="numBox">
            <div class="form-col">
              <div class="form-group">
                <label for="courseTitle">备注</label>
                <textarea type="text" v-model="formInfo.remarks" rows="3" class="form-textarea" id="direction" :disabled="state" placeholder="请输入备注"></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      state:false,
      formInfo:{
        id:0,
        created:'',
        courseTitle:'',
        categorie01:0,
        categorie02:0,
        categorie03:0,
        courseType:'',
        experimentType:'',
        hours:'',
        direction:'',
        necessity:'',
        brief:'',
        suggest:'',
        remarks:'',
        org_name:'',
        owner:'',
        ownerId:''
      },
      category01_dict: [],
      category02_dict: [],
      category03_dict: [],
    }
  },
  // 事件处理器
  methods: {
    // 获取数据详情
    async querySingle(id) {
      const url = '/guide/' + id + '/'
      await this.$http.get(url).then((data) => {
        this.formInfo = {
          id: id,
          created: data.created.substr(0, 10),
          courseTitle: data.courseTitle,
          categorie01: data.categorie01,
          categorie02: data.categorie02,
          categorie03: data.categorie03,
          courseType: data.courseType,
          experimentType: data.experimentType,
          hours: data.hours,
          direction: data.direction,
          necessity: data.necessity,
          brief: data.brief,
          suggest: data.suggest,
          remarks: data.remarks,
          org_name: data.org_name,
        }
        this.get_category(2,true)
        this.get_category(3,true)
        this.formInfo.num = this.$route.query.num
      }).catch(() => {
        this.$parent.$parent.showMsg('数据获取失败！');
      })
    },
    // 查子级类
    async get_category(level,type){
      let params = {}
      if(level == 1){
        params = {
          grade: 1,
          status: 1,
          isdel: 0
        }
      }else if(level == 2){
        params = {
          grade: 2,
          status: 1,
          isdel: 0,
          parent: this.formInfo.categorie01
        }
      }else if(level == 3){
        params = {
          grade: 3,
          status: 1,
          isdel: 0,
          parent2: this.formInfo.categorie02
        }
      }

      await this.$http.get('/categories/', {
        params: params
      }).then(async (data) => {
        if(level == 1){
          this.category01_dict = data.results
        }else if(level == 2){
          this.category02_dict = data.results
          if(type){
            console.log()
          } else{
            this.category03_dict=[]
            this.formInfo.categorie02 = 0
            this.formInfo.categorie03 = 0
          }
        }else if(level == 3){
          this.category03_dict = data.results
          if(type){
            console.log()
          } else{
            this.formInfo.categorie03 = 0
          }
        }
      })
    },
    // 保存
    async saveInfo(type) {
      if(!this.formInfo.courseTitle){
        this.$parent.$parent.showMsg("请输入课程名称!")
        return false
      } else if(this.formInfo.categorie01==0){
        this.$parent.$parent.showMsg("请选择领域!")
        return false
      } else if(this.formInfo.categorie02==0){
        this.$parent.$parent.showMsg("请选择专业类!")
        return false
      } else if(this.formInfo.categorie03==0){
        this.$parent.$parent.showMsg("请选择适用专业!")
        return false
      } else if(!this.formInfo.courseType){
        this.$parent.$parent.showMsg("请选择课程类型!")
        return false
      } else if(!this.formInfo.experimentType){
        this.$parent.$parent.showMsg("请选择实验类型!")
        return false
      } else if(!this.formInfo.hours){
        this.$parent.$parent.showMsg("请选择建议学时!")
        return false
      } else if(!this.formInfo.direction){
        this.$parent.$parent.showMsg("请输入实验项目方向!")
        return false
      } else if(!this.formInfo.necessity){
        this.$parent.$parent.showMsg("请输入必要性!")
        return false
      } else if(!this.formInfo.brief){
        this.$parent.$parent.showMsg("请输入内容简介!")
        return false
      }
      if(this.formInfo.id){
        let url = '/guide/' + this.formInfo.id + '/'
        await this.$http.put(url, this.formInfo).then((data) => {
          if (data.id) {
            this.$parent.$parent.showMsg('修改成功！')
            this.$router.go(-1)
          } else {
            this.$parent.$parent.showMsg('修改失败！')
          }
        }).catch((error) => {
          console.log(error)
          this.$parent.$parent.showMsg('修改失败！')
        })
      }else{
        if(type == 1){
          this.formInfo.status = 1
        } else if(type == 2){
          this.formInfo.status = 0
        }
        this.formInfo.ownerId = this.localUser.user_id
        this.formInfo.owner = this.localUser.name
        await this.$http.post('/guide/', this.formInfo).then((data) => {
          if (data.id) {
            this.$parent.$parent.showMsg('添加成功！')
            this.$router.go(-1)
          } else {
            this.$parent.$parent.showMsg('添加失败！')
          }
        }).catch((error) => {
          console.log(error)
          this.$parent.$parent.showMsg('添加失败！')
        })
      }
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.state = this.$route.query.state==1?true:false
    this.get_category(1,true)
    if(this.$route.query.state!=0){
      this.querySingle(this.$route.query.id)
    }
   },
  // 生命周期-实例挂载后调用
  mounted () { 
    
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {
    localUser(){
      return this.$store.state.userInfo
    }
  },
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.content-title {
  width: 100%;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 20px;
  overflow: hidden;
  p{
    float: left;
    font-size: 18px;
    font-weight: normal;
    padding-left: 10px;
    position: relative;
  }
  p:before {
    content: '';
    position: absolute;
    width: 3px;
    height: 17px;
    background-color: #4878e7;
    left: 0;
    top: 50%;
    margin-top: -8px;
  }
  .btns{
    float: right;
    margin: 0;
    padding: 0;
    button {
      display: inline-block;
      border: 1px solid transparent;
      color: #fff;
      background-color: #727272;
      padding: 6px 10px;
      border-radius: 5px;
      cursor: pointer;
    }
    .save-submit-btn {
      background-color: #f67a00;
      border-color: #e87000;
      margin-right: 10px;
    }
    .save-btn {
      background-color: #009900;
      border-color: #008d01;
      margin-right: 10px;
    }
    .back-btn {
      border-color: #625c5c;
    }
  }
}
.content-form {
  width: 100%;
  padding-top: 20px;
  box-sizing: border-box;
  .form-row {
    width: 100%;
    display: table;
    .form-col {
      display: table-cell;
      padding: 0 20px;
      .form-group {
        margin-bottom: 15px;
        min-width: 200px;
        label {
          display: inline-block;
          margin-bottom: 12px;
          color: #989898;
        }
        .form-input.plaintext, .form-select.plaintext, .form-textarea.plaintext {
          display: block;
          width: 100%;
          background-color: transparent;
          border: solid transparent;
          border-width: 1px 0;
          outline: none;
          padding: 0;
          color: #757575;
          pointer-events: none;
        }
        .form-input {
          display: block;
          width: 100%;
          height: 32px;
          padding: 0 10px;
          font-size: 14px;
          font-weight: 400;
          line-height: 32px;
          background-color: #f5f7fc;
          border: 1px solid #e6e6e6;
          border-radius: 2px;
          box-sizing: border-box;
        }
        .form-textarea {
          height: auto;
          display: block;
          width: 100%;
          padding: 0 10px;
          font-size: 14px;
          font-weight: 400;
          line-height: 32px;
          background-color: #f5f7fc;
          border: 1px solid #e6e6e6;
          border-radius: 2px;
          box-sizing: border-box;
          font-family: inherit;
        }
        .required:before {
          content: '*';
          color: red;
          display: inline;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
