<template>
  <div id="content1" class="pull-right">
    <div class="center_content" style="min-height: 458px;">
      <div class="notice">
        <ul id="noticeList">
          <li v-for="item in tableData" :key="item.id">
            <a href="javascript:;" @click="showDetailWindow(item.id)">{{ item.title }}</a>
            <span class="time">{{ item.created ? item.created.substr(0, 10) : '' }}</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="dialog_box" :style="classShowDetailWindow">
      <p class="dialog_top">会员通知<span class="close btn_close" @click="hideDetailWindow">×</span></p>
      <div class="dialog_content"><h2 class="dialog_title">{{ detailFormInfo.title }}</h2>
        <ul class="content_list">
          <li><strong class="content_title">开始时间：</strong>
            {{ detailFormInfo.startTime ? detailFormInfo.startTime.substr(0, 10) : '' }}
          </li>
          <li><strong class="content_title">结束时间：</strong>
            {{ detailFormInfo.endTime ? detailFormInfo.endTime.substr(0, 10) : '' }}
          </li>
          <li><strong class="content_title">通知内容：</strong>
            <p v-html="detailFormInfo.content"></p>
          </li>
        </ul>
        <button class="close dialog_btn btn_confirm" @click="hideDetailWindow">确认</button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "UserNotice",
  data() {
    return {
      queryInfo: {
        page: 1,
        size: 10,
        status: '1',
        type: '1',
        startTime: '',
        endTime: ''
      },
      tableData: [],
      tableDataCount: 0,
      detailFormInfo: {
        title: '',
        startTime: '',
        endTime: '',
        content: ''
      },
      classShowDetailWindow: {display: 'none'},
    }
  },
  methods: {
    async search() {
      let now = this.curTime()
      this.queryInfo.startTime = now
      this.queryInfo.endTime = now
      await this.$http.get('/notice/', {
        params: this.queryInfo
      }).then((data) => {
        console.log(data)
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$parent.$parent.showMsg('获取会员通知失败！')
      })
    },
    async showDetailWindow(id) {
      const url = '/notice/' + id + '/'
      await this.$http.get(url).then((data) => {
        console.log(data)
        this.detailFormInfo.title = data.title
        this.detailFormInfo.startTime = data.startTime
        this.detailFormInfo.endTime = data.endTime
        this.detailFormInfo.content = data.content
        this.classShowDetailWindow = {display: 'block'}
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg('删除失败！');
      })
    },
    hideDetailWindow() {
      this.classShowDetailWindow = {display: 'none'}
      this.detailFormInfo = {
        title: '',
        startTime: '',
        endTime: '',
        content: ''
      }
    },
    formatDataTime(dt, fmt) {
      let o = {
        "M+": dt.getMonth() + 1, //月份
        "d+": dt.getDate(), //日
        "H+": dt.getHours(), //小时
        "m+": dt.getMinutes(), //分
        "s+": dt.getSeconds(), //秒
        "q+": Math.floor((dt.getMonth() + 3) / 3), //季度
        "S": dt.getMilliseconds() //毫秒
      };
      if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (dt.getFullYear() + "").substr(4 - RegExp.$1.length));
      for (let k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
      return fmt;
    },
    curTime(){
      let nowDate = new Date();
      return this.formatDataTime(nowDate, 'yyyy-MM-dd HH:mm:ss')
    }
  },
  mounted() {
    this.search()
  }
}
</script>

<style scoped>
@import "../../assets/css/dialog.css";
@import "../../assets/css/common.css";
@import "../../assets/css/header.css";
@import "../../assets/css/footer.css";
@import "../../assets/css/pagination.css";
@import "../../assets/css/common(1).css";
@import "../../assets/css/notice-view.css";

.center_content {
  width: 900px !important;
  margin: 0px !important;
}

.dialog_btn {
  width: 70px;
  height: 36px;
  font-size: 14px;
}

</style>
