import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

// 例 'http://192.168.0.115'
let rootUrl = ''
export default new Vuex.Store({
  state: {
    uEditorServerUrl:rootUrl + '/ueditor/upload/',
    uploadFileRootUrl:rootUrl + '/static/upload_file/',
    wwwFileRootUrl:rootUrl + '/static/www_file/',
    rootUrl: rootUrl + '/',
    userInfo: {
      'user_id': 0,
      'user_name': '',
      'profile_id': 0,
      'name': '',
      'avatar': '',
      'role': '',
      'gender': '',
      'isFirstLog': false,
      'province': '',
      'school': '',
      'mobile': '',
      'pwdChecked': 0,
      'super_status': 0
    },
    token: '',
    dict_gender: {
      'M': '男',
      'F': '女'
    },
    dict_role: {
      '1': '联盟管理员',
      '2': '联盟运营管理员',
      '3': '教师',
      '4': '社会人士',
      '5': '指南负责人',
      '6': '领域负责人',
      '7': '专业类负责人',
      '8': '专家'
    },
    dict_province: {
      '0': '北京市',
      '1': '天津市',
      '2': '河北省',
      '3': '山西省',
      '4': '内蒙古自治区',
      '5': '辽宁省',
      '6': '吉林省',
      '7': '黑龙江省',
      '8': '上海市',
      '9': '江苏省',
      '10': '浙江省',
      '11': '安徽省',
      '12': '福建省',
      '13': '江西省',
      '14': '山东省',
      '15': '河南省',
      '16': '湖北省',
      '17': '湖南省',
      '18': '广东省',
      '19': '广西壮族自治区',
      '20': '海南省',
      '21': '重庆市',
      '22': '四川省',
      '23': '贵州省',
      '24': '云南省',
      '25': '西藏自治区',
      '26': '陕西省',
      '27': '甘肃省',
      '28': '青海省',
      '29': '宁夏回族自治区',
      '30': '新疆维吾尔自治区',
      '31': '新疆生产建设兵团',
      '32': '香港特别行政区',
      '33': '澳门特别行政区',
      '34': '台湾省'
    },
    dict_industry: {
      '1': '农、林、木、渔业',
      '2': '采矿业',
      '3': '制造业',
      '4': '电力、热力、燃气及水生产和供应',
      '5': '建筑业',
      '6': '批发和零售业',
      '7': '交通运输、仓储和邮政业',
      '8': '住宿和餐饮业',
      '9': '信息传输、软件和信息技术服务业',
      '10': '金融业',
      '11': '房地产业',
      '12': '租赁和商务服务业',
      '13': '科学研究和技术服务业',
      '14': '水利、环境和公共设施管理业',
      '15': '居民服务、修理和其他服务业',
      '16': '教育',
      '17': '卫生和社会工作',
      '18': '文化、体育和娱乐业',
      '19': '公共管理、社会保障和社会组织',
      '20': '国际组织'
    },
    school_level_dict: {
      '0': '本科',
      '1': '专科'
    },
    pub_state_dict: {
      '0': '未发布',
      '1': '已发布'
    },
    guid_status_dict: {
      '0': '建设中',
      '1': '待审核',
      '2': '已关闭',
      '3': '待评审',
      '4': '评审未通过',
      '5': '已发布',
    },
    super_status_dict: {
      '1': '待审核',
      '2': '驳回',
      '3': '已通过'
    },
    dict_org_major_type: {
      '1': '基础领域',
      '2': '土建领域',
      '3': '信息领域',
      '4': '机械领域',
      '5': '化环领域',
      '6': '生物领域',
      '7': '文科领域',
      '8': '医学领域'
    },
    last_use_time: 0,
  },
  mutations: {
    setUserInfo (state, userInfo) {
      this.state.userInfo = userInfo
    },
    setToken (state, token) {
      this.state.token = token
    },
    setLastUseTime (state, timestamp) {
      this.state.last_use_time = timestamp
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState({ storage: window.localStorage })]
})
