<template>
  <div id="panel" class="panel" style="min-height: 696px;">
    <ul class="change-tab">
      <li :class="changeIndex==1?'changed':''" @click="changeTab(1)">企业会员申请管理</li>
      <li :class="changeIndex==2?'changed':''" @click="changeTab(2)">企业会员简介管理</li>
    </ul>
    <div class="item1" v-if="changeIndex==1">
      <ul class="list-type">
        <li>
          <i class="iconfont icon-huiyuanzongshu"></i>
          <div>
            <span>1</span>
            <span>企业会员总数</span>
          </div>
        </li>
        <li>
          <i class="iconfont icon-shenhezhong"></i>
          <div>
            <span>1</span>
            <span>待审核数</span>
          </div>
        </li>
        <li>
          <i class="iconfont icon-shenhe-yitongguo"></i>
          <div>
            <span>1</span>
            <span>已通过数</span>
          </div>
        </li>
        <li>
          <i class="iconfont icon-yijiaona"></i>
          <div>
            <span>1</span>
            <span>已缴纳费用</span>
          </div>
        </li>
        <li>
          <i class="iconfont icon-yibiangengcopy"></i>
          <div>
            <span>1</span>
            <span>未缴纳费用</span>
          </div>
        </li>
      </ul>
      <form id="searchSection" onsubmit="return false;">
        <div class="search-line clearfix">
          <select name="type" class="s-select separate">
            <option value="">费用缴纳情况</option>
            <option value="1">已缴纳</option>
            <option value="0">未缴纳</option>
          </select>
          <select name="status" class="s-select separate">
            <option value="">选择状态</option>
            <option value="0">审核中</option>
            <option value="1">已通过</option>
            <option value="2">驳回</option>
          </select>
          <input type="text" name="title" class="s-input separate" placeholder="单位名称">

          <input type="submit" class="s-btn" value="搜索" @click="search">

          <div class="import-btn btncolor" id="batchUpload">导出</div>
        </div>

        <div class="search-line clearfix">

        </div>
      </form>
      <div id="list">
        <table>
          <tbody>
          <tr class="l_header">
            <th class="itemTitle">单位名称</th>
            <th class="itemTitle">主要联系人</th>
            <th class="itemTitle">电话</th>
            <th class="itemTitle">申请时间</th>
            <th class="itemTitle">状态</th>
            <th class="itemTitle">费用缴纳情况</th>
            <th class="action" width="140">操作</th>
          </tr>
          <tr class="item">
            <td class="itemTitle">北京原力辰超导技术有限公司</td>
            <td class="itemTitle">张伟</td>
            <td class="itemTitle">18345467892</td>
            <td class="itemTitle">2021-04-27</td>
            <td class="itemTitle">审核中</td>
            <td class="itemTitle">已缴纳</td>
            <td class="action">
              <a class="nopublish" href="javascript:;">通过</a>
              <a class="edit" href="javascript:;">驳回</a>
              <a class="delete" href="javascript:;">发送短信</a>
              <a class="other1" href="javascript:;">查看</a>
              <a class="other1" href="javascript:;">费用缴纳</a>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="noitems" v-if="tableData.length == 0">没有数据</div>
      </div>
      <pagination :cur="pageInfo.page" :all="pageInfo.all" :totalData="pageInfo.totalData"
                :callback="pageCallback"></pagination>
    </div>
    <div class="item2" v-else>
      <form id="searchSection" onsubmit="return false;">
        <div class="search-line clearfix">
          <select name="status" class="s-select separate">
            <option value="">选择状态</option>
            <option value="0">待审核</option>
            <option value="1">待发布</option>
            <option value="2">已发布</option>
            <option value="3">驳回</option>
          </select>
          <input type="text" name="title" class="s-input separate" placeholder="企业名称">
          <input type="text" name="name" class="s-input separate" placeholder="姓名">
          <input type="text" name="mobbile" class="s-input separate" placeholder="手机号">

          <input type="submit" class="s-btn" value="搜索" @click="search">

        </div>

        <div class="search-line clearfix">

        </div>
      </form>
      <div id="list">
        <table>
          <tbody>
          <tr class="l_header">
            <th class="itemTitle">企业名称</th>
            <th class="itemTitle">联系人姓名</th>
            <th class="itemTitle">手机号</th>
            <th class="itemTitle">邮箱</th>
            <th class="itemTitle">提交时间</th>
            <th class="itemTitle">状态</th>
            <th class="action" width="140">操作</th>
          </tr>
          <tr class="item">
            <td class="itemTitle">北京百朋索奇科技有限公司</td>
            <td class="itemTitle">张伟</td>
            <td class="itemTitle">18345467892</td>
            <td class="itemTitle">zhangwei@163.com</td>
            <td class="itemTitle">2021-04-27</td>
            <td class="itemTitle">待审核</td>
            <td class="action">
              <a class="see" href="javascript:;">查看</a>
              <a class="nopublish" href="javascript:;">通过</a>
              <a class="edit" href="javascript:;">驳回</a>
              <a class="delete" href="javascript:;">发布</a>
              <a class="delete" href="javascript:;">取消发布</a>
              <a class="delete" href="javascript:;">删除</a>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="noitems" v-if="tableData.length == 0">没有数据</div>
      </div>
      <pagination :cur="pageInfo.page" :all="pageInfo.all" :totalData="pageInfo.totalData"
                :callback="pageCallback"></pagination>
    </div>
  </div>
</template>

<script>
import Pagination from "../../components/Pagination"

export default {
  name: 'AdminMember',
  components: {
    Pagination
  },
  data() {
    return {
      changeIndex:1,
      queryInfo: {
        page: 1,
        size: 10,
      },
      tableData: [],
      tableDataCount: 0,
    }
  },
  computed: {
    pageInfo() {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    }
  },
  methods: {
    changeTab(value){
      this.changeIndex=value
    },
    async search() {
      await this.$http.get('/application/', {
        params: this.queryInfo
      }).then((data) => {
        console.log(data)
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$parent.$parent.showMsg('获取通知信息失败！')
      })
    },
 
    async pageCallback(data) {
      this.queryInfo.page = data
      await this.search()
    },
  },
  mounted() {
    this.search()
  },
}
</script>

<style lang="less" scoped>
@font-face {
  font-family: "iconfont"; /* Project id 2618897 */
  src: url('//at.alicdn.com/t/font_2618897_s18ag2055a.woff2?t=1625451544247') format('woff2'),
       url('//at.alicdn.com/t/font_2618897_s18ag2055a.woff?t=1625451544247') format('woff'),
       url('//at.alicdn.com/t/font_2618897_s18ag2055a.ttf?t=1625451544247') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-yibiangengcopy:before {
  content: "\e607";
}

.icon-huiyuanzongshu:before {
  content: "\e610";
}

.icon-yijiaona:before {
  content: "\e6fa";
}

.icon-shenhezhong:before {
  content: "\e65c";
}

.icon-shenhe-yitongguo:before {
  content: "\e625";
}
#dialog_content2 {
  max-height: 100px;
  overflow-y: auto;
  border-top: 1px solid #ddd;
}

#dialog_content2 .success {
  color: #aaa;
}

#dialog_content2 .fail {
  color: #e33;
}
.btncolor{
  border: 1px solid #4976e8 !important;
  background-color: #4976e8 !important;
}
.list-type{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom:20px;
  li{
    border:1px solid #ccc;
    border-radius: 5px;
    width:150px; 
    height:80px;
    display: flex;
    justify-content: center;
    align-items: center;
    i{
      font-size:30px;
      margin-right:10px;
    }
    div{
      span{
        display: block;
        text-align: center;
        &:first-child{
          font-weight: bold;
        }
      }
    }
  }
}
.change-tab{
  border-bottom: 1px solid #ccc;
  margin-bottom:20px;
  li{
    display: inline-block;
    font-size:18px;
    padding-bottom:10px;
    cursor: pointer;
    &:first-child{
      margin-right:20px;
    }
  }
  .changed{
    border-bottom: 2px solid #4976e8;
    font-weight: bold;
  }
}
</style>
