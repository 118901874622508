<template>
  <div>
    <Load v-if="loading" />
    <!-- 顶部banner -->
    <div
      v-if="!loading"
      id="center"
    >
      <div class="headBanner">
        <img
          class="bannerImgAll"
          :src="formInfo.images[0].src"
          alt=""
        >
        <div
          class="uploadImgAll"
          v-if="isPreview"
        >
          <p class="promptText">图片建议尺寸 1920px*450px，图片格式仅支持jpg、png</p>
          <span class="btnAll">上传图片
            <input
              id="imageFile"
              type="file"
              name="imageFile"
              accept="image/*"
              class="image_upload_file"
              ref="imageFile"
              @change="imageFileChange('0',$event)"
            >
          </span>
        </div>
        <!-- 菜单 -->
        <TemplateList
          v-if="isMenu"
          :isPreview="isPreview"
          :category=3
          @Setpreview="preview"
          @Setsave="save"
        />
      </div>
      <div class="middle_area">
        <!-- 产品介绍 -->
        <div class="row_about">
          <h4
            class="titleAll"
            :contenteditable="isPreview"
            @blur="missBlur('BigTitleOne',$event)"
            v-html="formInfo.BigTitleOne"
          ></h4>
          <span
            class="eg_titleAll"
            :contenteditable="isPreview"
            @blur="missBlur('BigEgTitleOne',$event)"
            v-html="formInfo.BigEgTitleOne"
          ></span>
          <ul class="row_list">
            <li>
              <div class="listImg">
                <img
                  class="bannerImgAll"
                  :src="formInfo.images[1].src"
                  alt=""
                >
                <div
                  class="uploadImgAll"
                  v-if="isPreview"
                >
                  <p class="promptText">图片建议尺寸 390px*260px，图片格式仅支持jpg、png</p>
                  <span class="btnAll">上传图片
                    <input
                      id="imageFile"
                      type="file"
                      name="imageFile"
                      accept="image/*"
                      class="image_upload_file"
                      ref="imageFile"
                      @change="imageFileChange('1',$event)"
                    >
                  </span>
                </div>
              </div>
              <div class="listText">
                <span
                  class="small_title"
                  :contenteditable="isPreview"
                  @blur="missBlur('productTitle1',$event)"
                  v-html="formInfo.productTitle1"
                ></span>
                <p
                  class="textarea"
                  :contenteditable="isPreview"
                  @blur="missBlur('productTextarea1',$event)"
                  v-html="formInfo.productTextarea1"
                ></p>
              </div>
            </li>
            <li>
              <div class="listImg">
                <img
                  class="bannerImgAll"
                  :src="formInfo.images[2].src"
                  alt=""
                >
                <div
                  class="uploadImgAll"
                  v-if="isPreview"
                >
                  <p class="promptText">图片建议尺寸 390px*260px，图片格式仅支持jpg、png</p>
                  <span class="btnAll">上传图片
                    <input
                      id="imageFile"
                      type="file"
                      name="imageFile"
                      accept="image/*"
                      class="image_upload_file"
                      ref="imageFile"
                      @change="imageFileChange('2',$event)"
                    >
                  </span>
                </div>
              </div>
              <div class="listText">
                <span
                  class="small_title"
                  :contenteditable="isPreview"
                  @blur="missBlur('productTitle2',$event)"
                  v-html="formInfo.productTitle2"
                ></span>
                <p
                  class="textarea"
                  :contenteditable="isPreview"
                  @blur="missBlur('productTextarea2',$event)"
                  v-html="formInfo.productTextarea2"
                ></p>
              </div>
            </li>
            <li>
              <div class="listImg">
                <img
                  class="bannerImgAll"
                  :src="formInfo.images[3].src"
                  alt=""
                >
                <div
                  class="uploadImgAll"
                  v-if="isPreview"
                >
                  <p class="promptText">图片建议尺寸 390px*260px，图片格式仅支持jpg、png</p>
                  <span class="btnAll">上传图片
                    <input
                      id="imageFile"
                      type="file"
                      name="imageFile"
                      accept="image/*"
                      class="image_upload_file"
                      ref="imageFile"
                      @change="imageFileChange('3',$event)"
                    >
                  </span>
                </div>
              </div>
              <div class="listText">
                <span
                  class="small_title"
                  :contenteditable="isPreview"
                  @blur="missBlur('productTitle3',$event)"
                  v-html="formInfo.productTitle3"
                ></span>
                <p
                  class="textarea"
                  :contenteditable="isPreview"
                  @blur="missBlur('productTextarea3',$event)"
                  v-html="formInfo.productTextarea3"
                ></p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="headBanner aboutUs">
        <!-- 关于我们 -->
        <div
          class="uploadImgAll"
          v-if="isPreview"
        >
          <p class="promptText">图片建议尺寸 1920px*470px，图片格式仅支持jpg、png</p>
          <span class="btnAll">上传图片
            <input
              id="imageFile"
              type="file"
              name="imageFile"
              accept="image/*"
              class="image_upload_file"
              ref="imageFile"
              @change="imageFileChange('4',$event)"
            >
          </span>
        </div>
        <img
          class="bannerImgAll"
          :src="formInfo.images[4].src"
          alt=""
        >

        <div class="selfInformation">
          <h4
            class="titleAll"
            :contenteditable="isPreview"
            @blur="missBlur('BigTitleTwo',$event)"
            v-html="formInfo.BigTitleTwo"
          ></h4>
          <span
            class="eg_titleAll"
            :contenteditable="isPreview"
            @blur="missBlur('BigEgTitleTwo',$event)"
            v-html="formInfo.BigEgTitleTwo"
          ></span>
          <p
            class="textarea"
            :contenteditable="isPreview"
            @blur="missBlur('aboutUsTextarea',$event)"
            v-html="formInfo.aboutUsTextarea"
          ></p>
        </div>
      </div>
      <div class="middle_area">
        <!-- 产品优势 -->
        <div class="row_advantage">
          <h4
            class="titleAll"
            :contenteditable="isPreview"
            @blur="missBlur('BigTitleThree',$event)"
            v-html="formInfo.BigTitleThree"
          ></h4>
          <span
            class="eg_titleAll"
            :contenteditable="isPreview"
            @blur="missBlur('BigEgTitleThree',$event)"
            v-html="formInfo.BigEgTitleThree"
          ></span>
          <div class="row_list">
            <div class="row_left">
              <img
                class="bannerImgAll"
                :src="formInfo.images[5].src"
                alt=""
              >
              <div
                class="uploadImgAll"
                v-if="isPreview"
              >
                <p class="promptText">图片建议尺寸 580px*450px，图片格式仅支持jpg、png</p>
                <span class="btnAll">上传图片
                  <input
                    id="imageFile"
                    type="file"
                    name="imageFile"
                    accept="image/*"
                    class="image_upload_file"
                    ref="imageFile"
                    @change="imageFileChange('5',$event)"
                  >
                </span>
              </div>
            </div>
            <div class="row_right">
              <div class="item">
                <div class="num">1</div>
                <div class="itemRight">
                  <span
                    :contenteditable="isPreview"
                    @blur="missBlur('advantageTitle1',$event)"
                    v-html="formInfo.advantageTitle1"
                  ></span>
                  <p
                    :contenteditable="isPreview"
                    @blur="missBlur('advantageText1',$event)"
                    v-html="formInfo.advantageText1"
                  ></p>
                </div>
              </div>
              <div class="item">
                <div class="num">2</div>
                <div class="itemRight">
                  <span
                    :contenteditable="isPreview"
                    @blur="missBlur('advantageTitle2',$event)"
                    v-html="formInfo.advantageTitle2"
                  ></span>
                  <p
                    :contenteditable="isPreview"
                    @blur="missBlur('advantageText2',$event)"
                    v-html="formInfo.advantageText2"
                  ></p>
                </div>
              </div>
              <div class="item">
                <div class="num">3</div>
                <div class="itemRight">
                  <span
                    :contenteditable="isPreview"
                    @blur="missBlur('advantageTitle3',$event)"
                    v-html="formInfo.advantageTitle3"
                  ></span>
                  <p
                    :contenteditable="isPreview"
                    @blur="missBlur('advantageText3',$event)"
                    v-html="formInfo.advantageText3"
                  ></p>
                </div>
              </div>
              <div class="item">
                <div class="num">4</div>
                <div class="itemRight">
                  <span
                    :contenteditable="isPreview"
                    @blur="missBlur('advantageTitle4',$event)"
                    v-html="formInfo.advantageTitle4"
                  ></span>
                  <p
                    :contenteditable="isPreview"
                    @blur="missBlur('advantageText4',$event)"
                    v-html="formInfo.advantageText4"
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 成功案例 -->
        <div class="row_about">
          <h4
            class="titleAll"
            :contenteditable="isPreview"
            @blur="missBlur('BigTitleFour',$event)"
            v-html="formInfo.BigTitleFour"
          ></h4>
          <span
            class="eg_titleAll"
            :contenteditable="isPreview"
            @blur="missBlur('BigEgTitleFour',$event)"
            v-html="formInfo.BigEgTitleFour"
          ></span>
          <ul class="row_list case_list">
            <li>
              <div class="listImg">
                <img
                  class="bannerImgAll"
                  :src="formInfo.images[6].src"
                  alt=""
                >
                <div
                  class="uploadImgAll"
                  v-if="isPreview"
                >
                  <p class="promptText">图片建议尺寸 360px*190px<br />图片格式仅支持jpg、png</p>
                  <span class="btnAll">上传图片
                    <input
                      id="imageFile"
                      type="file"
                      name="imageFile"
                      accept="image/*"
                      class="image_upload_file"
                      ref="imageFile"
                      @change="imageFileChange('6',$event)"
                    >
                  </span>
                </div>
              </div>
              <div class="listText2">
                <span
                  class="small_title"
                  :contenteditable="isPreview"
                  @blur="missBlur('successTitle1',$event)"
                  v-html="formInfo.successTitle1"
                ></span>
                <p
                  class="textarea"
                  :contenteditable="isPreview"
                  @blur="missBlur('successTextarea1',$event)"
                  v-html="formInfo.successTextarea1"
                ></p>
              </div>
              <div class="line"></div>
            </li>
            <li>
              <div class="listImg">
                <img
                  class="bannerImgAll"
                  :src="formInfo.images[7].src"
                  alt=""
                >
                <div
                  class="uploadImgAll"
                  v-if="isPreview"
                >
                  <p class="promptText">图片建议尺寸 360px*190px<br />图片格式仅支持jpg、png</p>
                  <span class="btnAll">上传图片
                    <input
                      id="imageFile"
                      type="file"
                      name="imageFile"
                      accept="image/*"
                      class="image_upload_file"
                      ref="imageFile"
                      @change="imageFileChange('7',$event)"
                    >
                  </span>
                </div>
              </div>
              <div class="listText2">
                <span
                  class="small_title"
                  :contenteditable="isPreview"
                  @blur="missBlur('successTitle2',$event)"
                  v-html="formInfo.successTitle2"
                ></span>
                <p
                  class="textarea"
                  :contenteditable="isPreview"
                  @blur="missBlur('successTextarea2',$event)"
                  v-html="formInfo.successTextarea2"
                ></p>
              </div>
              <div class="line"></div>
            </li>
            <li>
              <div class="listImg">
                <img
                  class="bannerImgAll"
                  :src="formInfo.images[8].src"
                  alt=""
                >
                <div
                  class="uploadImgAll"
                  v-if="isPreview"
                >
                  <p class="promptText">图片建议尺寸 360px*190px<br />图片格式仅支持jpg、png</p>
                  <span class="btnAll">上传图片
                    <input
                      id="imageFile"
                      type="file"
                      name="imageFile"
                      accept="image/*"
                      class="image_upload_file"
                      ref="imageFile"
                      @change="imageFileChange('8',$event)"
                    >
                  </span>
                </div>
              </div>
              <div class="listText2">
                <span
                  class="small_title"
                  :contenteditable="isPreview"
                  @blur="missBlur('successTitle3',$event)"
                  v-html="formInfo.successTitle3"
                ></span>
                <p
                  class="textarea"
                  :contenteditable="isPreview"
                  @blur="missBlur('successTextarea3',$event)"
                  v-html="formInfo.successTextarea3"
                ></p>
              </div>
              <div class="line"></div>
            </li>
          </ul>
        </div>
      </div>
      <div class="contact_us">
        <h4
          class="titleAll"
          :contenteditable="isPreview"
          @blur="missBlur('BigTitleFive',$event)"
          v-html="formInfo.BigTitleFive"
        ></h4>
        <span
          class="eg_titleAll"
          :contenteditable="isPreview"
          @blur="missBlur('BigEgTitleFive',$event)"
          v-html="formInfo.BigEgTitleFive"
        ></span>
      </div>
      <div class="middle_area">
        <div class="row_contactus">
          <div class="contact_left">
            <img
              :src="formInfo.images[9].src"
              alt=""
            >
            <div
              class="uploadImgAll"
              v-if="isPreview"
            >
              <p class="promptText">图片建议尺寸 640px*400px，图片格式仅支持jpg、png</p>
              <span class="btnAll">上传图片
                <input
                  id="imageFile"
                  type="file"
                  name="imageFile"
                  accept="image/*"
                  class="image_upload_file"
                  ref="imageFile"
                  @change="imageFileChange('9',$event)"
                >
              </span>
            </div>
          </div>
          <div class="contact_right">
            <div class="code">
              <img
                :src="formInfo.images[10].src"
                alt=""
              >
              <div
                class="uploadImgAll"
                v-if="isPreview"
              >
                <p class="promptText">图片建议尺寸 250px*250px<br />图片格式仅支持jpg、png</p>
                <span class="btnAll">上传图片
                  <input
                    id="imageFile"
                    type="file"
                    name="imageFile"
                    accept="image/*"
                    class="image_upload_file"
                    ref="imageFile"
                    @change="imageFileChange('10',$event)"
                  >
                </span>
              </div>
            </div>
            <p
              class="textarea"
              :contenteditable="isPreview"
              @blur="missBlur('contactTextarea',$event)"
              v-html="formInfo.contactTextarea"
            ></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TemplateList from "./TemplateList"
import Load from "./Loading"
export default {
  // 可用组件的哈希表
  components: {TemplateList,Load},
  // 接收传值
   props: {
    isPreview: {
      type: Boolean,
    },
    isMenu: {
      type: Boolean,
    }
  },
  // 数据对象
  data () {
    return {
      loading:true,
      formInfo:{
        BigTitleOne:'产品介绍',
        BigEgTitleOne:'PRODUCT DESCRIPTION',
        productTitle1:'产品方案设计',
        productTextarea1:'产品推广宣传视频、演员情景式视频',

        productTitle2:'产品方案设计',
        productTextarea2:'产品推广宣传视频、演员情景式视频',

        productTitle3:'产品方案设计',
        productTextarea3:'产品推广宣传视频、演员情景式视频',

        BigTitleTwo:'关于我们',
        BigEgTitleTwo:'ABOUT US',
        aboutUsTextarea:'<div><span style="white-space:pre"> </span>公司自成立以来，始终坚持以人才为本、诚信立业的经营原则，荟萃业界精英，将国外先进的信息技术、管理方法及企业经验与国内企业的具体实际相结合，为企业供给全方位的解决方案，帮忙企业提高管理水平和生产本事，使企业在激烈的市场竞争中始终坚持竞争力，实现企业快速、稳定地发展。</div>',

        BigTitleThree:'产品优势',
        BigEgTitleThree:'PRODUCT ADVANTAGES',
        advantageTitle1:'标题标题标题',
        advantageText1:'公司自成立以来，始终坚持以人才为本、诚信立业的经营原则，荟萃业界精英，将国外先进的信息技术。',

        advantageTitle2:'标题标题标题',
        advantageText2:'公司自成立以来，始终坚持以人才为本、诚信立业的经营原则，荟萃业界精英，将国外先进的信息技术。',

        advantageTitle3:'标题标题标题',
        advantageText3:'公司自成立以来，始终坚持以人才为本、诚信立业的经营原则，荟萃业界精英，将国外先进的信息技术。',

        advantageTitle4:'标题标题标题',
        advantageText4:'公司自成立以来，始终坚持以人才为本、诚信立业的经营原则，荟萃业界精英，将国外先进的信息技术。',

        BigTitleFour:'成功案例',
        BigEgTitleFour:'SUCCESS CASE',
        successTitle1:'区域内短途运输',
        successTextarea1:'公司自成立以来，始终坚持以人才为本、诚信立业的经营原则，荟萃业界精英，将国外先进的信息技术。',

        successTitle2:'区域内短途运输',
        successTextarea2:'公司自成立以来，始终坚持以人才为本、诚信立业的经营原则，荟萃业界精英，将国外先进的信息技术。',

        successTitle3:'区域内短途运输',
        successTextarea3:'公司自成立以来，始终坚持以人才为本、诚信立业的经营原则，荟萃业界精英，将国外先进的信息技术。',

        BigTitleFive:'联系我们',
        BigEgTitleFive:'CONTACT US',
        contactTextarea:'<div>地&nbsp; &nbsp; &nbsp; &nbsp;址：北京市海淀区北三环中路44号院文教产业园D座</div><div>客户热线：010-59508493 010-59508535</div><div>销售专线：400-888-3467</div><div>邮&nbsp; &nbsp; &nbsp; &nbsp;箱：support@rainier.net.cn&nbsp; &nbsp; &nbsp;&nbsp;</div><div>Q&nbsp; &nbsp; &nbsp; &nbsp; Q：1052004576</div>',
        
        images:[
          {src:require('../assets/images/three/1.png')},  //0
          {src:require('../assets/images/three/2.png')},  //1
          {src:require('../assets/images/three/3.png')}, // 2
          {src:require('../assets/images/three/4.png')},  //3
          {src:require('../assets/images/three/5.png')}, //4
          {src:require('../assets/images/three/6.png')},  //5
          {src:require('../assets/images/three/7.png')},  //6
          {src:require('../assets/images/three/8.png')}, //7
          {src:require('../assets/images/three/9.png')},  //8
          {src:require('../assets/images/two/contact1.png')},  //9
          {src:require('../assets/images/two/contact2.png')},  //10
        ]
      },
      queryInfo:{
        type:2,
        ownerId:''
      },
      companyId:''
    }
  },
  // 事件处理器
  methods: {
    // 上传图片
    async imageFileChange(value,e) {
      console.log(e.target.files[0].name)
      const uploadUrl = '/files/' + e.target.files[0].name
      await this.$http.put(uploadUrl, e.target.files[0]).then((data) => {
        this.formInfo.images[value].src = this.$store.state.uploadFileRootUrl+data
      }).catch(() => {
        alert('上传失败')
      })
    },
    // 获取编辑值
    missBlur(e,v){
      this.formInfo[e]=v.target.innerHTML
    },
    preview(){
      this.isPreview=!this.isPreview
    },
    // 获取当前时间
    getCurrentTime() {
      var _this = this
      const yy = new Date().getFullYear()
      const mm = new Date().getMonth() + 1
      const dd = new Date().getDate()
      const hh = new Date().getHours()
      const mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
      const ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
      _this.gettime = yy + '-' + mm + '-' + dd + 'T' + hh + ':' + mf + ':' + ss
      return _this.gettime
    },
    async save(){
      const url='/application/'+this.companyId+'/'
      await this.$http.put(url, {
        super_view_category:3,
        super_view_status:1,
        super_view_submit_time:this.getCurrentTime(),
        super_view_data:JSON.stringify(this.formInfo)
      }).then(() => {
      }).catch(() => {
      })
    },
    async search() {
      await this.$http.get('/allowany/application/', {
        params: this.queryInfo
      }).then((data) => {
        this.loading = false
        if(data.results.length!=0){
          this.companyId=data.results[0].id
          if(data.results[0].super_view_data!=='' && data.results[0].super_view_category==3){
            this.formInfo=JSON.parse(data.results[0].super_view_data)
          }
        }else{
          this.$parent.showMsg('您不是高级会员身份')
          this.$router.push('/')
        }
      }).catch(() => {
        this.$parent.showMsg('获取通知信息失败！')
      })
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    if(this.$route.path.substr(0, 4) == '/www'){
      if(this.$route.query.ownerId){
        this.isPreview = false
        this.isMenu = false
        this.queryInfo.ownerId = this.$route.query.ownerId
      } else{
        this.$router.push('/')
      }
    }else{
      this.queryInfo.ownerId = this.$store.state.userInfo.user_id
    }
    this.search()
  },
  // 生命周期-实例挂载后调用
  mounted () { 
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
#center {
  background: #ffffff;
  width: auto !important;
  margin-top: 0 !important;
}
.bannerImgAll {
  width: 100%;
  height: 100%;
}
// 上传图片样式
.uploadImgAll {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  width: 100%;
  height: 155px;
  text-align: center;
  font-size: 14px;
  .promptText {
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;
    margin-top: 50px;
  }
  .btnAll {
    width: 110px;
    height: 40px;
    line-height: 40px;
    margin-top: 20px;
    color: #ffffff;
    display: inline-block;
    background: #335dcc;
    border-radius: 10px;
    position: relative;
    .image_upload_file {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      width: 110px;
      height: 40px;
    }
  }
}
// 模块标题
.titleAll {
  display: inline-block;
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #1e252f;
  line-height: 45px;
  padding: 0 10px;
}
// 英文标题
.eg_titleAll {
  display: block;
  max-width: 400px;
  margin: 0 auto;
  line-height: 25px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #1e252f;
}
// 首banner
.headBanner {
  width: 100%;
  height: 450px;
  position: relative;
}
.middle_area {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  .row_about {
    margin: 90px 0;
    .row_list {
      display: flex;
      justify-content: space-between;
      margin-top: 25px;
      li {
        width: 390px;
        .listImg {
          width: 390px;
          height: 260px;
          position: relative;
          .uploadImgAll {
            height: 100px;
            .promptText {
              margin-top: 17px;
            }
            .btnAll {
              margin-top: 15px;
              width: 88px;
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              .image_upload_file {
                height: 32px;
                width: 88px;
              }
            }
          }
        }
        .listText {
          border: 1px solid #8ed1fe;
          padding: 25px 0;
          .small_title {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #1e252f;
            line-height: 30px;
            padding: 0 10px;
          }
          .textarea {
            margin: 10px auto 0;
            width: 300px;
            padding: 0 10px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
        }
      }
    }
    .case_list {
      min-height: 350px;
      li {
        position: relative;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
        padding: 15px;
        width: 348px;
        .listImg {
          width: 348px;
          height: 190px;
          .uploadImgAll {
            .promptText {
              margin-top: 10px;
            }
            .btnAll {
              margin-top: 5px;
            }
          }
        }
        .listText2 {
          text-align: left;
          margin-top: 10px;
          .small_title {
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #1e252f;
            line-height: 22px;
          }
        }
        .line {
          position: absolute;
          margin: 0 auto;
          bottom: 10px;
          left: 0;
          right: 0;
          width: 107px;
          height: 8px;
          background: #8ed1fe;
        }
      }
    }
  }
  .row_advantage {
    .row_list {
      margin-top: 25px;
      overflow: hidden;
      .row_left {
        position: relative;
        float: left;
        width: 580px;
        height: 450px;
        .uploadImgAll {
          height: 100px;
          .promptText {
            margin-top: 17px;
          }
          .btnAll {
            margin-top: 15px;
            width: 88px;
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            .image_upload_file {
              height: 32px;
              width: 88px;
            }
          }
        }
      }
      .row_right {
        float: right;
        width: 594px;
        .item {
          overflow: hidden;
          text-align: left;
          height: 90px;
          background: #f7f7f7;
          box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.08);
          margin-bottom: 30px;
          &:last-child {
            margin-bottom: 0;
          }
          .num {
            float: left;
            text-align: center;
            line-height: 90px;
            font-size: 48px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #1e252f;
            width: 90px;
            height: 90px;
            background: #ededed;
          }
          .itemRight {
            float: left;
            max-width: 488px;
            padding: 8px 0;
            font-family: Microsoft YaHei;
            color: #1e252f;
            line-height: 22px;
            span {
              padding: 0 8px;
              font-size: 18px;
              font-weight: bold;
            }
            p {
              padding: 0 8px;
              margin-top: 5px;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
  .row_contactus {
    top: -40px;
    position: relative;
    overflow: hidden;
    .uploadImgAll {
      height: 100px;
      .btnAll {
        width: 88px;
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        margin-top: 15px;
        .image_upload_file {
          width: 88px;
          height: 32px;
        }
      }
      .promptText {
        margin-top: 15px;
      }
    }
    .contact_left {
      position: relative;
      float: left;
      width: 650px;
      height: 420px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .contact_right {
      width: 500px;
      float: right;
      text-align: left;
      .code {
        margin-left: 10px;
        width: 250px;
        height: 250px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .btnAll {
          margin-top: 5px;
        }
      }
      .textarea {
        margin-top: 25px;
        line-height: 30px;
        padding: 0 10px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      .ellipsis {
        margin-top: 35px;
        display: inline-block;
        font-size: 16px;
        color: #333333;
        line-height: 28px;
        padding: 10px;
      }
    }
  }
}
// 关于我们样式
.aboutUs {
  height: 700px;
  .bannerImgAll {
    height: 470px;
  }
  .uploadImgAll {
    top: 0;
  }
  .selfInformation {
    position: relative;
    bottom: 260px;
    margin: 0 auto;
    padding: 40px;
    width: 734px;
    min-height: 300px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
    text-align: center;
    .textarea {
      padding: 0 10px;
      margin-top: 10px;
      text-align: left;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1e252f;
      line-height: 25px;
    }
  }
}
// 联系我们样式
.contact_us {
  height: 196px;
  background-color: #8ed1fe;
  text-align: center;
  .titleAll {
    margin-top: 55px;
    color: #ffffff;
  }
  .eg_titleAll {
    color: #ffffff;
  }
}
</style>
