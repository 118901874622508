<template>
  <div
    id="content1"
    class="pull-right"
  >
    <div
      class="center_content"
      style="min-height: 458px;"
    >
      <p class="title">我的会议</p>
      <form
        id="searchSection"
        onsubmit="return false;"
      >
        <input
          type="text"
          name="title"
          placeholder="会议名称"
          class="s-input separate"
          v-model="queryInfo.title"
        >
        <input
          type="date"
          name="date"
          placeholder="日期"
          class="date_box"
          v-model="queryInfo.meetingTime"
        >
        <input
          type="button"
          class="s-btn"
          value="搜索"
          @click="queryInfo.page=1,search()"
        >
        <div class="search-line clearfix">
        </div>
      </form>
      <div
        class="rowList"
        v-for="(item,index) in tableData"
        :key="item.id"
      >
        <div class="meetName">
          <p class="meetTitle">{{item.userProfile?item.meetingInfo.title:''}}</p>
        </div>
        <div class="cover">
          <img
            :src="$store.state.wwwFileRootUrl + item.meetingInfo.picPath"
            alt=""
          >
          <div class="cover_bottom">
            <span class="meetsource">{{item.userProfile?item.meetingInfo.address:''}}</span>
            <span class="meetsource">{{item.meetingInfo?item.meetingInfo.meetingTime.substr(0, 10):''}}</span>
          </div>
        </div>
        <div
          class="filelist"
          v-if="fileArry[index]?fileArry[index].length>0:''"
        >
          <div
            class="file_row"
            v-for="items in fileArry[index]"
            :key="items.id"
          >
            <img
              :src="formatType(items.filePath)"
              alt=""
              style="width:16px;height:16px;"
            >
            <a
              href="javascript:void(0)"
              v-if="items.filePath.substr(items.filePath.lastIndexOf('.') + 1)=='mp4'"
              @click="showVideoWindow(items)"
            >
              {{items.fileName}}
            </a>
            <a
              v-else
              title="点击查看详情"
              :href="$store.state.wwwFileRootUrl + items.filePath"
              target="_blank"
            >{{items.fileName}}</a>
          </div>
        </div>
        <div
          class="NoYet"
          v-else
          style="color:#DCDCDC"
        >
          暂无资料
        </div>
      </div>
      <div
        class="noitems"
        style="margin-top:100px"
        v-if="tableData.length == 0"
      >暂无会议</div>
      <div class="paging">
        <pagination
          :cur="pageInfo.page"
          :all="pageInfo.all"
          :totalData="pageInfo.totalData"
          :callback="pageCallback"
        ></pagination>
      </div>
    </div>
    <div
      class="form_overlay"
      id="form_overlay"
      :style="classShowVideoWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel editor">
        <div class="form_title">{{videoInfo.title}}</div>
        <div
          class="close"
          @click="classShowVideoWindow={}"
        >x</div>
        <div class="form_content">
          <video
            controls
            :src="videoInfo.path"
            style="width:100%;height:auto"
          ></video>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pdf from '../../assets/images/pdf.png'
import Pagination from "../../components/Pagination"
export default {
  // 可用组件的哈希表
  components: {Pagination},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      pdf,
      imgType:{
        pdf:require('../../assets/images/pdf.png'),
        word:require('../../assets/images/word.png'),
        mp4:require('../../assets/images/mp4.png'),
        ppt:require('../../assets/images/ppt.png'),
      },
      tableData:[],
      fileArry:[],
      queryInfo:{
        userProfile_id: '',
        title:'',
        meetingTime:'',
        page: 1,
        size: 2,
      },
      tableDataCount:0,
      fileQueryInfo:{
        meetingInfo_id: '',
        page: 1,
        size: 100,
      },
      meet_id_Arry:[],
      classShowVideoWindow:{},
      videoInfo:{
        title:'',
        path:''
      }
    }
  },
  // 事件处理器
  methods: {
    showVideoWindow(item) {
      this.classShowVideoWindow = {
        visibility: 'visible'
      }
      this.videoInfo={
        title:item.fileName,
        path:this.$store.state.wwwFileRootUrl+item.filePath
      }
    },

    formatType(path){
      var index = path.lastIndexOf('.')
      var fileTyle = path.substr(index + 1)
      if(fileTyle==='pdf'){
        return this.imgType.pdf
      }else if(fileTyle==='doc' || fileTyle==='docx'){
        return this.imgType.word
      }else if(fileTyle==='mp4'){
        return this.imgType.mp4
      }else if(fileTyle==='ppt' || fileTyle==='pptx'){
        return this.imgType.ppt
      }
    },
    // 分页
    async pageCallback(data) {
      this.queryInfo.page = data
      await this.search()
    },
    // 搜索
    async search() {
      this.queryInfo.userProfile_id = this.localUser.profile_id
      await this.$http.get('/meeting_user/', {
        params: this.queryInfo
      }).then((data) => {
        this.tableData = data.results
        this.tableDataCount = data.count
        this.fileArry=[],
        this.meet_id_Arry = []
        this.tableData.forEach((element,index) => {
          this.meet_id_Arry.push(element.meetingInfo.id)
          if((index + 1) == this.tableData.length ){
            this.getMeetfile()
          }
        });
      }).catch(() => {
        this.$parent.$parent.showMsg('获取会议信息失败！')
      })
    },
    async getMeetfile(){
      for(let i=0;i<this.meet_id_Arry.length;){
        this.fileQueryInfo.meetingInfo_id = this.meet_id_Arry[i]
        await this.$http.get('/meeting_file/', {
          params: this.fileQueryInfo
        }).then((data) => {
          i++
          this.fileArry.push(data.results)
        }).catch(() => {
          this.$parent.$parent.showMsg('获取会议资料失败！')
        })
      }
    }
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () {
    this.search()
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {
    localUser(){
      return this.$store.state.userInfo
    },
    pageInfo() {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    },
  },
  // 自定义的侦听器
  watch: {}
}
</script>

<style scoped>
.date_box {
  border: 1px solid #e6e6e6;
  background-color: #f5f7fc;
  width: 150px;
  height: 32px;
  line-height: 32px;
  margin-right: 8px;
  padding-left: 8px;
}
#searchSection {
  margin-top: 15px;
  margin-bottom: 16px;
}
.center_content {
  width: 900px !important;
  margin: 0px !important;
}

.dialog_btn {
  width: 70px;
  height: 36px;
  font-size: 14px;
}
.title {
  font-size: 18px;
  color: #333333;
  font-weight: 500;
}
.title:before {
  content: "";
  display: inline-block;
  position: relative;
  top: 3px;
  width: 4px;
  height: 18px;
  background: #02a7f0;
  margin-right: 7px;
}
.rowList {
  /* padding: 10px; */
  margin: 10px 0;
  border: 1px solid #eee;
  overflow: hidden;
}
.rowList .meetName {
  height: 19px;
  padding: 10px;
  background-color: #f5f7fc;
}
.rowList .meetName p {
  /* font-size: 13px; */
  font-weight: bold;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 19px;
}
.rowList .cover {
  position: relative;
  float: left;
  width: 357px;
  margin-left: 10px;
  margin-top: 10px;
}
.rowList .cover img {
  width: 100%;
  height: 210px;
}
.rowList .cover .cover_bottom {
  position: absolute;
  display: flex;
  padding: 0 10px;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.5);
  bottom: 6px;
  width: 337px;
  height: 25px;
}
.rowList .cover .meetsource {
  font-size: 12px;
  display: inline-block;
  color: #ffffff;
  line-height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rowList .filelist {
  float: left;
  margin-top: 10px;
  width: 500px;
  max-height: 210px;
  overflow-y: auto;

  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
}
.NoYet {
  height: 181px;
  margin-left: 222px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rowList .filelist .file_row {
  width: 500px;
  height: 35px;
  /* margin-right: 30px; */
}
.rowList .filelist .file_row:nth-child(2n) {
  margin-right: 0;
}
.file_row img {
  margin-right: 10px;
  position: relative;
  top: -4px;
}
.file_row a {
  display: inline-block;
  max-width: 460px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.file_row a:hover {
  text-decoration: underline;
  color: #02a7f0;
}

.paging {
  overflow: hidden;
}
#pagination {
  margin-top: 20px;
}
.form_panel {
  transform: translate(-60%, -70%);
  margin: 0;
  position: relative;
}
.form_panel .close {
  position: absolute;
  right: 30px;
  top: 20px;
  cursor: pointer;
  color: red;
}
</style>
