<template>
  <div style="width:100%">
    <Load v-if="loading" />
    <div v-if="!loading">
      <div class="banner">
        <img
          :src="images[0].src"
          alt=""
        >
        <div
          class="mask"
          v-show="isPreview"
        >
          <span>图片建议尺寸 1920px*450px，图片格式仅支持jpg、png</span>
          <div class="bts">
            <button>上传图片</button>
            <input
              id="imageFile"
              type="file"
              name="imageFile"
              accept="image/*"
              ref="imageFile"
              @change="imageFileChange('0',$event)"
            >
          </div>
        </div>
        <!-- 菜单 -->
        <TemplateList
          v-if="isMenu"
          :isPreview="isPreview"
          :category=1
          @Setpreview="preview"
          @Setsave="save"
        />
      </div>
      <div class="advan">
        <div class="advantage">
          <h2
            class="regular"
            :contenteditable="isPreview"
            v-html="tempOne.advantageH2"
            @blur="missBlur('advantageH2',$event)"
          ></h2>
          <span
            class="regular"
            :contenteditable="isPreview"
            v-html="tempOne.advantageSpan"
            @blur="missBlur('advantageSpan',$event)"
          ></span>
        </div>
        <ul class="advantage-list">
          <li>
            <span
              :contenteditable="isPreview"
              v-html="tempOne.list1Title"
              @blur="missBlur('list1Title',$event)"
            ></span>
            <p
              :contenteditable="isPreview"
              v-html="tempOne.list1Info"
              @blur="missBlur('list1Info',$event)"
            ></p>
          </li>
          <li>
            <span
              :contenteditable="isPreview"
              v-html="tempOne.list2Title"
              @blur="missBlur('list2Title',$event)"
            ></span>
            <p
              :contenteditable="isPreview"
              v-html="tempOne.list2Info"
              @blur="missBlur('list2Info',$event)"
            ></p>
          </li>
          <li>
            <span
              :contenteditable="isPreview"
              v-html="tempOne.list3Title"
              @blur="missBlur('list3Title',$event)"
            ></span>
            <p
              :contenteditable="isPreview"
              v-html="tempOne.list3Info"
              @blur="missBlur('list3Info',$event)"
            ></p>
          </li>
          <li>
            <span
              :contenteditable="isPreview"
              v-html="tempOne.list4Title"
              @blur="missBlur('list4Title',$event)"
            ></span>
            <p
              :contenteditable="isPreview"
              v-html="tempOne.list5Info"
              @blur="missBlur('list5Info',$event)"
            ></p>
          </li>
        </ul>
      </div>
      <div class="service-sys">
        <div class="advantage">
          <h2
            class="regular"
            :contenteditable="isPreview"
            v-html="tempOne.serviceH2"
            @blur="missBlur('serviceH2',$event)"
          ></h2>
          <span
            class="regular"
            :contenteditable="isPreview"
            v-html="tempOne.serviceSpan"
            @blur="missBlur('serviceSpan',$event)"
          ></span>
        </div>
        <ul class="service">
          <li>
            <div class="images">
              <img
                :src="images[1].src"
                alt=""
              >
              <div
                class="mask"
                v-show="isPreview"
              >
                <span>图片建议尺寸 350px*150px，
                  图片格式仅支持jpg、png</span>
                <div class="bts">
                  <button>上传图片</button>
                  <input
                    id="imageFile"
                    type="file"
                    name="imageFile"
                    accept="image/*"
                    ref="imageFile"
                    @change="imageFileChange('1',$event)"
                  >
                </div>
              </div>
            </div>
            <div class=introduce>
              <span
                :contenteditable="isPreview"
                v-html="tempOne.serviceTitle1"
                @blur="missBlur('serviceTitle1',$event)"
              ></span>
              <p
                :contenteditable="isPreview"
                v-html="tempOne.serviceInfo1"
                @blur="missBlur('serviceInfo1',$event)"
              ></p>
            </div>
          </li>
          <li>
            <div class="images">
              <img
                :src="images[2].src"
                alt=""
              >
              <div
                class="mask"
                v-show="isPreview"
              >
                <span>图片建议尺寸 350px*150px，
                  图片格式仅支持jpg、png</span>
                <div class="bts">
                  <button>上传图片</button>
                  <input
                    id="imageFile"
                    type="file"
                    name="imageFile"
                    accept="image/*"
                    ref="imageFile"
                    @change="imageFileChange('2',$event)"
                  >
                </div>
              </div>
            </div>
            <div class=introduce>
              <span
                :contenteditable="isPreview"
                v-html="tempOne.serviceTitle2"
                @blur="missBlur('serviceTitle2',$event)"
              ></span>
              <p
                :contenteditable="isPreview"
                v-html="tempOne.serviceInfo2"
                @blur="missBlur('serviceInfo2',$event)"
              ></p>
            </div>
          </li>
          <li>
            <div class="images">
              <img
                :src="images[3].src"
                alt=""
              >
              <div
                class="mask"
                v-show="isPreview"
              >
                <span>图片建议尺寸 350px*150px，
                  图片格式仅支持jpg、png</span>
                <div class="bts">
                  <button>上传图片</button>
                  <input
                    id="imageFile"
                    type="file"
                    name="imageFile"
                    accept="image/*"
                    ref="imageFile"
                    @change="imageFileChange('3',$event)"
                  >
                </div>
              </div>
            </div>
            <div class=introduce>
              <span
                :contenteditable="isPreview"
                v-html="tempOne.serviceTitle3"
                @blur="missBlur('serviceTitle3',$event)"
              ></span>
              <p
                :contenteditable="isPreview"
                v-html="tempOne.serviceInfo3"
                @blur="missBlur('serviceInfo3',$event)"
              ></p>
            </div>
          </li>
          <li>
            <div class="images">
              <img
                :src="images[4].src"
                alt=""
              >
              <div
                class="mask"
                v-show="isPreview"
              >
                <span>图片建议尺寸 350px*150px，
                  图片格式仅支持jpg、png</span>
                <div class="bts">
                  <button>上传图片</button>
                  <input
                    id="imageFile"
                    type="file"
                    name="imageFile"
                    accept="image/*"
                    ref="imageFile"
                    @change="imageFileChange('4',$event)"
                  >
                </div>
              </div>
            </div>
            <div class=introduce>
              <span
                :contenteditable="isPreview"
                v-html="tempOne.serviceTitle4"
                @blur="missBlur('serviceTitle4',$event)"
              ></span>
              <p
                :contenteditable="isPreview"
                v-html="tempOne.serviceInfo4"
                @blur="missBlur('serviceInfo4',$event)"
              ></p>
            </div>
          </li>
          <li>
            <div class="images">
              <img
                :src="images[5].src"
                alt=""
              >
              <div
                class="mask"
                v-show="isPreview"
              >
                <span>图片建议尺寸 350px*150px，
                  图片格式仅支持jpg、png</span>
                <div class="bts">
                  <button>上传图片</button>
                  <input
                    id="imageFile"
                    type="file"
                    name="imageFile"
                    accept="image/*"
                    ref="imageFile"
                    @change="imageFileChange('5',$event)"
                  >
                </div>
              </div>
            </div>
            <div class=introduce>
              <span
                :contenteditable="isPreview"
                v-html="tempOne.serviceTitle5"
                @blur="missBlur('serviceTitle5',$event)"
              ></span>
              <p
                :contenteditable="isPreview"
                v-html="tempOne.serviceInfo5"
                @blur="missBlur('serviceInfo5',$event)"
              ></p>
            </div>
          </li>
          <li>
            <div class="images">
              <img
                :src="images[6].src"
                alt=""
              >
              <div
                class="mask"
                v-show="isPreview"
              >
                <span>图片建议尺寸 350px*150px，
                  图片格式仅支持jpg、png</span>
                <div class="bts">
                  <button>上传图片</button>
                  <input
                    id="imageFile"
                    type="file"
                    name="imageFile"
                    accept="image/*"
                    ref="imageFile"
                    @change="imageFileChange('6',$event)"
                  >
                </div>
              </div>
            </div>
            <div class=introduce>
              <span
                :contenteditable="isPreview"
                v-html="tempOne.serviceTitle6"
                @blur="missBlur('serviceTitle6',$event)"
              ></span>
              <p
                :contenteditable="isPreview"
                v-html="tempOne.serviceInfo6"
                @blur="missBlur('serviceInfo6',$event)"
              ></p>
            </div>
          </li>
        </ul>
      </div>
      <div class="we-info">
        <div>
          <div class="info">
            <span
              :contenteditable="isPreview"
              v-html="tempOne.weInfoTitle"
              @blur="missBlur('weInfoTitle',$event)"
            ></span>
            <p
              :contenteditable="isPreview"
              v-html="tempOne.weInfoIntroduce"
              @blur="missBlur('weInfoIntroduce',$event)"
            ></p>
          </div>
          <ul class="we-list">
            <li
              :contenteditable="isPreview"
              v-html="tempOne.weInfolist1"
              @blur="missBlur('weInfolist1',$event)"
            ></li>
            <li
              :contenteditable="isPreview"
              v-html="tempOne.weInfolist2"
              @blur="missBlur('weInfolist2',$event)"
            ></li>
            <li
              :contenteditable="isPreview"
              v-html="tempOne.weInfolist3"
              @blur="missBlur('weInfolist3',$event)"
            ></li>
            <li
              :contenteditable="isPreview"
              v-html="tempOne.weInfolist4"
              @blur="missBlur('weInfolist4',$event)"
            ></li>
            <li
              :contenteditable="isPreview"
              v-html="tempOne.weInfolist5"
              @blur="missBlur('weInfolist5',$event)"
            ></li>
          </ul>
        </div>
      </div>
      <div class="sucess">
        <div class="advantage">
          <h2
            class="regular"
            :contenteditable="isPreview"
            v-html="tempOne.sucessH2"
            @blur="missBlur('sucessH2',$event)"
          ></h2>
          <span
            class="regular"
            :contenteditable="isPreview"
            v-html="tempOne.sucessSpan"
            @blur="missBlur('sucessSpan',$event)"
          ></span>
        </div>
        <div class="sucess-list">
          <li>
            <img
              :src="images[7].src"
              alt=""
            >
            <div
              class="mask"
              v-show="isPreview"
            >
              <span>图片建议尺寸 290px*390px，
                图片格式仅支持jpg、png</span>
              <div class="bts">
                <button>上传图片</button>
                <input
                  id="imageFile"
                  type="file"
                  name="imageFile"
                  accept="image/*"
                  ref="imageFile"
                  @change="imageFileChange('7',$event)"
                >
              </div>
            </div>
          </li>
          <li>
            <img
              :src="images[8].src"
              alt=""
            >
            <div
              class="mask"
              v-show="isPreview"
            >
              <span>图片建议尺寸 290px*390px，
                图片格式仅支持jpg、png</span>
              <div class="bts">
                <button>上传图片</button>
                <input
                  id="imageFile"
                  type="file"
                  name="imageFile"
                  accept="image/*"
                  ref="imageFile"
                  @change="imageFileChange('8',$event)"
                >
              </div>
            </div>
          </li>
          <li>
            <img
              :src="images[9].src"
              alt=""
            >
            <div
              class="mask"
              v-show="isPreview"
            >
              <span>图片建议尺寸 580px*390px，图片格式仅支持jpg、png</span>
              <div class="bts">
                <button>上传图片</button>
                <input
                  id="imageFile"
                  type="file"
                  name="imageFile"
                  accept="image/*"
                  ref="imageFile"
                  @change="imageFileChange('9',$event)"
                >
              </div>
            </div>
          </li>
          <li>
            <img
              :src="images[10].src"
              alt=""
            >
            <div
              class="mask"
              v-show="isPreview"
            >
              <span>图片建议尺寸 590px*380px，图片格式仅支持jpg、png</span>
              <div class="bts">
                <button>上传图片</button>
                <input
                  id="imageFile"
                  type="file"
                  name="imageFile"
                  accept="image/*"
                  ref="imageFile"
                  @change="imageFileChange('10',$event)"
                >
              </div>
            </div>
          </li>
          <li>
            <img
              :src="images[11].src"
              alt=""
            >
            <div
              class="mask"
              v-show="isPreview"
            >
              <span>图片建议尺寸 270px*380px，
                图片格式仅支持jpg、png</span>
              <div class="bts">
                <button>上传图片</button>
                <input
                  id="imageFile"
                  type="file"
                  name="imageFile"
                  accept="image/*"
                  ref="imageFile"
                  @change="imageFileChange('11',$event)"
                >
              </div>
            </div>
          </li>
          <li>
            <img
              :src="images[12].src"
              alt=""
            >
            <div
              class="mask"
              v-show="isPreview"
            >
              <span>图片建议尺寸 270px*380px，
                图片格式仅支持jpg、png</span>
              <div class="bts">
                <button>上传图片</button>
                <input
                  id="imageFile"
                  type="file"
                  name="imageFile"
                  accept="image/*"
                  ref="imageFile"
                  @change="imageFileChange('12',$event)"
                >
              </div>
            </div>
          </li>
        </div>
      </div>
      <div class="contact-our">
        <div class="advantage">
          <h2
            class="regular"
            :contenteditable="isPreview"
            v-html="tempOne.contactTitle"
            @blur="missBlur('contactTitle',$event)"
          ></h2>
        </div>
        <ul class="contact-list">
          <li>
            <img
              :src="images[13].src"
              alt=""
            >
            <div
              class="mask"
              v-show="isPreview"
            >
              <span>图片建议尺寸 650px*420px，图片格式仅支持jpg、png</span>
              <div class="bts">
                <button>上传图片</button>
                <input
                  id="imageFile"
                  type="file"
                  name="imageFile"
                  accept="image/*"
                  ref="imageFile"
                  @change="imageFileChange('13',$event)"
                >
              </div>
            </div>
          </li>
          <li>
            <div>
              <img
                :src="images[14].src"
                alt=""
              >
              <div
                class="mask"
                v-show="isPreview"
              >
                <span>图片建议尺寸 250px*250px，
                  图片格式仅支持jpg、png</span>
                <div class="bts">
                  <button>上传图片</button>
                  <input
                    id="imageFile"
                    type="file"
                    name="imageFile"
                    accept="image/*"
                    ref="imageFile"
                    @change="imageFileChange('14',$event)"
                  >
                </div>
              </div>
            </div>
            <div
              :contenteditable="isPreview"
              v-html="tempOne.contact"
              @blur="missBlur('contact',$event)"
            >
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import TemplateList from "./TemplateList"
import Load from "./Loading"
export default {
  name: "TemplateOne",
  components: {TemplateList,Load},
  props: {
    isPreview: {
      type: Boolean,
    },
    isMenu: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading:true,
      tempOne:{
        advantageH2:'优势介绍',
        advantageSpan:'优质的“互联网+影像”视频营销系统服务商',
        list1Title:'知名导演团队',
        list1Info:'集合北上广知名导演创作团队，对影片价值的更加追求，画面内容的精准把控，帮助企业提升品牌与商业价值',
        list2Title:'XXX+合作伙伴',
        list2Info:'与XXX多家上市集团、政府机构、大中小企业达成战略合作关系，随时掌握媒体的协作方向，监理企业与媒体的沟通管道',
        list3Title:'XXX+家主流媒体',
        list3Info:'与国内XXX多家主流媒体建立深度合作，搭建强势营销传播平台锁定有效的沟通对象，将讯息准确快速的传播给群众',
        list4Title:'较好的增值服务',
        list5Info:'率先提倡“互联网+视频”传播体系，帮助企业建立影像档案库，用影响为企业品牌传播保驾护航',
        serviceH2:'服务体系',
        serviceSpan:'凝聚XX年案例经验，我们了解大中小型企业的模式与需求',
        serviceTitle1:'宣传片',
        serviceInfo1:'企业宣传片拍摄、城市宣传片制作产品宣传片拍摄、个人宣传片制作',
        serviceTitle2:'广告片',
        serviceInfo2:'电视广告片、企业广告片产品广告片、创意广告片',
        serviceTitle3:'微电影',
        serviceInfo3:'企业微电影、城市微电影个人微电影、公益微电影',
        serviceTitle4:'影视制作',
        serviceInfo4:'品牌策划、视觉设计、创意执行三维动画、电视栏目、出版发行',
        serviceTitle5:'营销推广',
        serviceInfo5:'视频营销、事件营销、话题炒作、社会化营销、新媒体营销推广',
        serviceTitle6:'活动公关',
        serviceInfo6:'新闻发布会、新品发布会、年会晚会、招商会、订货会、书画展览、模特经纪',
        weInfoTitle:'我们是谁',
        weInfoIntroduce:`凭着XX文化传媒的媒体优势以及出色的技术和服务，XX文化传媒已经成为省内外的综合服务提供商。公司与多家省内外知名企业建立起了广泛良好的合作关系。<br>
    较好的服务质量，完美的客户体验以及高水准的专业服务团
    队，为企业建立优秀的公司形象、打造XXXX品牌和知名度，
    构建企业的核心竞争力优势。公司与多家省内外知名企业建
    立起了广泛良好的合作关系。XX文化传媒优秀的服务产品，
    较好的服务质量。`,
        weInfolist1:'精准',
        weInfolist2:'全面',
        weInfolist3:'收益',
        weInfolist4:'丰富',
        weInfolist5:'高效',
        contactTitle:'联系我们',
        contact:'<div>地&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;址：北京市海淀区北三环中路44号院文教产业园D座</div><div>客户热线：010-59508493 010-59508535</div><div>销售专线：400-888-3467</div><div>邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱：support@rainier.net.cn</div><div>Q&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Q：1052004576</div>',
        sucessH2:'成功案例',
        sucessSpan:'凝聚XX年案例经验，奠定XXX多家中小公司及政府机构合作关系',
      },
        images:[
          {
            src:require('../assets/images/one/one_1.png')
          },
          {
            src:require('../assets/images/one/one_2.png')
          },
          {
            src:require('../assets/images/one/one_3.png')
          },
          {
            src:require('../assets/images/one/one_4.png')
          },
          {
            src:require('../assets/images/one/one_5.png')
          },
          {
            src:require('../assets/images/one/one_6.png')
          },
          {
            src:require('../assets/images/one/one_7.png')
          },
          {
            src:require('../assets/images/one/one_8.png')
          },
          {
            src:require('../assets/images/one/one_9.png')
          },
          {
            src:require('../assets/images/one/one_10.png')
          },
          {
            src:require('../assets/images/one/one_11.png')
          },
          {
            src:require('../assets/images/one/one_12.png')
          },
          {
            src:require('../assets/images/one/one_13.png')
          },
          {
            src:require('../assets/images/one/one_14.jpg')
          },
          {
            src:require('../assets/images/one/one_15.jpg')
          },
        ],
     companyId:'',
     queryInfo:{
        type:2,
        ownerId:''
      },
    }
  },
  computed: {
   
  },
  watch:{

  },
  methods: {
    showUsewindow(){
      this.classUseWindow={
        visibility: 'visible'
      }
    },
    preview(){
      this.isPreview=!this.isPreview
    },
    async imageFileChange(value,e) {
      const uploadUrl = '/files/' + e.target.files[0].name
      await this.$http.put(uploadUrl, e.target.files[0]).then((data) => {
        this.images[value].src = this.$store.state.uploadFileRootUrl+data
      }).catch(() => {
        alert('上传失败')
      })
    },
    missBlur(value,e){
      this.tempOne[value]=e.target.innerHTML
    },
    // 获取当前时间
    getCurrentTime() {
      var _this = this
      const yy = new Date().getFullYear()
      const mm = new Date().getMonth() + 1
      const dd = new Date().getDate()
      const hh = new Date().getHours()
      const mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
      const ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
      _this.gettime = yy + '-' + mm + '-' + dd + 'T' + hh + ':' + mf + ':' + ss
      return _this.gettime
    },
    async save(){
      const super_view_data={
        tempOne:this.tempOne,
        images:this.images,
      }
      const url='/application/'+this.companyId+'/'
      await this.$http.put(url, {
        super_view_category:1,
        super_view_status:1,
        super_view_submit_time:this.getCurrentTime(),
        super_view_data:JSON.stringify(super_view_data)
      }).then(() => {
       
      }).catch(() => {
      })
    },
    async search() {
      await this.$http.get('/allowany/application/', {
        params: this.queryInfo
      }).then((data) => {
        this.loading = false
        if(data.results.length!=0){
          this.companyId=data.results[0].id
          if(data.results[0].super_view_data!=='' && data.results[0].super_view_category==1){
            this.images=JSON.parse(data.results[0].super_view_data).images
            this.tempOne=JSON.parse(data.results[0].super_view_data).tempOne
          }
        }else{
          this.$parent.showMsg('您不是高级会员身份')
          this.$router.push('/')
        }
      }).catch(() => {
        // this.$parent.$parent.showMsg('获取通知信息失败！')
      })
    },
  },
  
  // 生命周期-实例创建完成后调用
  created () { 
    if(this.$route.path.substr(0, 4) == '/www'){
      if(this.$route.query.ownerId){
        // this.isPreview = false
        // this.isMenu = false
        this.queryInfo.ownerId = this.$route.query.ownerId
      } else{
        this.$router.push('/')
      }
    }else{
      this.queryInfo.ownerId = this.$store.state.userInfo.user_id
    }
    this.search()
  },
  mounted() {
  }
}
</script>

<style lang="less" scoped>
@font-face {
  font-family: "Yuanti";
  src: url("../assets/fonts/华文圆体 REGULAR.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.regular {
  font-family: "Yuanti";
}
.banner {
  width: 1910px;
  height: 450px;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
  }
}
.mask {
  width: 100%;
  height: 155px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  bottom: 0;
  color: #fff;
  font-size: 14px;
  text-align: center;
  button {
    background: #335dcc;
    width: 110px;
    height: 40px;
    border: 1px solid #335dcc;
    color: #fff;
    border-radius: 10px;
    font-size: 14px;
    cursor: pointer;
  }
}
.advan {
  background: #fff;
  padding: 52px 0 102px;
}
.advantage {
  width: 605px;
  margin: 0 auto;
  border-bottom: 1px solid #dedede;
  position: relative;
  text-align: center;
  h2 {
    font-size: 30px;
    line-height: normal;
    font-weight: normal;
  }
  span {
    display: block;
    text-align: center;
    font-size: 14px;
    color: #333333;
    margin: 30px 0;
  }
  &::before {
    content: "";
    display: block;
    width: 226px;
    height: 10px;
    background: #3aced1;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.advantage-list {
  width: 1200px;
  margin: 52px auto 0;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  li {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // flex-direction: column;
    text-align: center;
    width: 258px;
    // height: 275px;
    border: 1px solid #ededed;
    padding: 0 30px;
    padding-bottom: 50px;
    box-sizing: border-box;
    overflow: hidden;
    span {
      display: inline-block;
      margin-top: 60px;
      font-size: 20px;
      color: #333;
    }
    p {
      font-size: 14px;
      color: #333;
      text-align: justify;
      margin-top: 47px;
    }
  }
}
.service-sys {
  padding: 52px 0 0px;
}
.service {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 52px;
  li {
    width: 350px;
    height: 273px;
    background: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-bottom: 60px;
    .images {
      width: 100%;
      height: 150px;
      padding: 0;
      position: relative;
      margin-bottom: 15px;
      img {
        width: 100%;
        height: 100%;
      }
      .mask {
        height: 100px;
        span {
          display: block;
          width: 200px;
          font-size: 14px;
          text-align: center;
        }
        .bts {
          width: 88px;
          height: 33px;
          margin: 0;
        }
        button {
          width: 88px;
          height: 33px;
        }
      }
    }
    .introduce {
      text-align: center;
      span {
        font-size: 20px;
        font-weight: bold;
      }
      p {
        width: 240px;
        font-size: 14px;
        color: #333;
      }
    }
  }
}
.we-info {
  background: #fff;
  height: 545px;
  > div {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .info {
      width: 445px;
      height: 340px;
      margin-top: 120px;
      span {
        font-size: 30px;
        font-weight: bold;
      }
      p {
        font-size: 16px;
        text-align: justify;
        margin-top: 52px;
      }
    }
    .we-list {
      height: 545px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 623px;
      li {
        width: 118px;
        height: 118px;
        border-radius: 50%;
        text-align: center;
        line-height: 118px;
        background: #dfdfdf;
        font-size: 24px;
        position: relative;
        margin-top: 191px;
        &::after {
          content: "";
          display: block;
          width: 1px;
          height: 235px;
          background: #dfdfdf;
          position: absolute;
          left: 50%;
          bottom: -235px;
          transform: translateX(-50%);
        }
        &:nth-child(2) {
          width: 70px;
          height: 70px;
          font-size: 16px;
          line-height: 70px;
          margin-top: 293px;
          &::after {
            height: 182px;
            bottom: -182px;
          }
        }
        &:nth-child(3) {
          margin-top: 121px;
          background: #7e7b81;
          color: #fff;
          &::after {
            height: 307px;
            bottom: -307px;
            background: #7e7b81;
          }
        }
        &:nth-child(4) {
          margin-top: 248px;
          &::after {
            height: 177px;
            bottom: -177px;
          }
        }
        &:nth-child(5) {
          width: 75px;
          height: 75px;
          line-height: 75px;
          color: #fff;
          font-size: 16px;
          background: #7e7b81;
          margin-top: 209px;
          &::after {
            height: 259px;
            bottom: -259px;
            background: #7e7b81;
          }
        }
      }
    }
  }
}
.sucess {
  background: #f5f5f5;
  padding: 52px 0 0;
}
.sucess-list {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 52px;
  li {
    width: 290px;
    height: 390px;
    background: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-bottom: 60px;
    position: relative;
    &:nth-child(3) {
      width: 580px;
    }
    &:nth-child(4) {
      width: 590px;
    }
    &:nth-child(5) {
      width: 270px;
    }
    &:nth-child(6) {
      width: 270px;
    }
    img {
      width: 100%;
      height: 100%;
    }
    .mask {
      height: 100px;
      span {
        display: block;
        width: 200px;
        font-size: 14px;
        text-align: center;
      }
      .bts {
        width: 88px;
        height: 33px;
        margin: 0;
      }
      button {
        width: 88px;
        height: 33px;
      }
    }
  }
}
.contact-our {
  padding: 52px 0 150px;
  background: #fff;
  .advantage {
    h2 {
      margin-bottom: 37px;
    }
  }
}
.contact-list {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 52px;
  li {
    width: 650px;
    height: 420px;
    background: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    margin-right: 50px;
    &:nth-child(2) {
      width: unset;
      > div {
        &:first-child {
          position: relative;
          width: 250px;
          height: 250px;
          margin-bottom: 27px;
        }
        &:last-child {
          font-size: 16px;
          color: #333;
          line-height: 1.8;
        }
      }
    }
    img {
      width: 100%;
      height: 100%;
    }
    .mask {
      height: 100px;
      span {
        display: block;
        width: 200px;
        font-size: 14px;
        text-align: center;
      }
      .bts {
        width: 88px;
        height: 33px;
        margin: 0;
      }
      button {
        width: 88px;
        height: 33px;
      }
    }
  }
}
.bts {
  position: relative;
  width: 110px;
  height: 40px;
  margin-top: 30px;
  cursor: pointer;

  input {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }
}
.form_content {
  p {
    padding-left: 30px;
  }
}
</style>
