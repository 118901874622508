<template>
  <div>
    <div class="breadcrumb-nav">
      <a href="/">首页</a>&gt;&gt;<a href="/activity">工作动态</a>&gt;&gt;建设规范
    </div>
    <div class="subRight">
      <div
        class="subRightTit"
        id="crumb"
      ><span>建设规范</span>
      </div>
      <div class="con">
        <ul id="elList">
          <li
            v-for="item in tableData"
            :key="item.id"
          >
            <router-link
              target="_blank"
              :to="'/www/activity?id=' + item.id"
            ><span class="info-title">{{ item.title }}</span><span class="time">{{ item.date }}</span></router-link>
          </li>
        </ul>

        <pagination
          :cur="pageInfo.page"
          :all="pageInfo.all"
          :totalData="pageInfo.totalData"
          :callback="pageCallback"
        ></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../../../components/Pagination"

export default {
  name: "ActivityRule",
  components: {
    Pagination
  },
  data() {
    return {
      queryInfo: {
        page: 1,
        size: 6,
        status: '1',
        type: '10',
      },
      tableData: [],
      tableDataCount: 0,
    }
  },
  computed: {
    pageInfo() {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    }
  },
  methods: {
    async search() {
      await this.$http.get('/allowany/info/', {
        params: this.queryInfo
      }).then((data) => {
        console.log(data)
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$parent.$parent.showMsg('获取通知信息失败！')
      })
    },
    async pageCallback(data) {
      this.queryInfo.page = data
      await this.search()
    },
  },
  mounted() {
    this.search()
  }
}
</script>

<style scoped>
#pagination {
  float: none;
}
</style>
