<template>
  <div id="center">
    <div id="subCenter">
      <div
        id="content"
        class="content "
      >
        <div class="vipReg clearfix">
          <div class="step">
            <span class="st1"><i>1</i>用户注册</span>
            <span class="st2"><i>2</i>提交会员申请信息<br>并下载完整申请表</span>
            <span class="st3"><i>3</i>请将盖章后的申请表发送至<br>邮箱vse2019@126.com</span>
            <span class="st4"><i>4</i>秘书处会议审批</span>

          </div>
        </div>
        <div class="subMenu">
<!--          <a-->
<!--            href="javascript:;"-->
<!--            data-type="1"-->
<!--            :class="showCategory == 1? 'cur' : ''"-->
<!--            @click="changeCategory(1)"-->
<!--          >高校会员申请</a>-->
          <a
            href="javascript:;"
            data-type="3"
            :class="showCategory == 3? 'cur' : ''"
            @click="changeCategory(3)"
          >高校二级学院会员申请</a>
          <a
            href="javascript:;"
            data-type="2"
            :class="showCategory == 2? 'cur' : ''"
            @click="changeCategory(2)"
          >企业会员申请</a>
        </div>
        <div class="vipRegCon clearfix">
          <div>
            <form
              class="applyTable"
              id="applyForm"
            >
              <div :class="showCategory == 0? 'type1 ' : 'type1 hide'">
                <h4 style="color: #888">暂无数据</h4>
              </div>
              <div :class="showCategory == 1? 'type1 ' : 'type1 hide'">
                <h2>虚拟仿真实验教学创新联盟高校会员申请表</h2>
                <table
                  border="0"
                  cellspacing="0"
                  cellpadding="0"
                >
                  <tbody>
                    <tr>
                      <td class="td1">单&nbsp;位&nbsp;名&nbsp;称</td>
                      <td colspan="5">
                        <input
                          v-model.trim="formInfo1.title"
                          :disabled="isDetail"
                          type="text"
                          name="title"
                          id="title1"
                          autocomplete="off"
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="td1">通&nbsp;讯&nbsp;地&nbsp;址</td>
                      <td colspan="3">
                        <input
                          type="text"
                          v-model="formInfo1.address"
                          :disabled="isDetail"
                          name="address"
                          id="address1"
                          autocomplete="off"
                        >
                      </td>
                      <td class="td2">邮&nbsp;&nbsp;编</td>
                      <td>
                        <input
                          type="text"
                          v-model="formInfo1.postcode"
                          :disabled="isDetail"
                          name="postcode"
                          id="postcode1"
                          autocomplete="off"
                        >
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="td1"
                        rowspan="2"
                      >
                        <p>学&nbsp;校&nbsp;主&nbsp;管</p>
                        <p>领&nbsp;导</p>
                      </td>
                      <td rowspan="2">
                        <input
                          v-model="formInfo1.mainPrincipal"
                          :disabled="isDetail"
                          type="text"
                          name="mainPrincipal"
                          id="mainPrincipal1"
                          autocomplete="off"
                        >
                      </td>
                      <td class="td2">职&nbsp;务&nbsp;职&nbsp;称</td>
                      <td>
                        <input
                          v-model="formInfo1.mpjobTitle"
                          :disabled="isDetail"
                          type="text"
                          name="mpjobTitle"
                          id="mpjobTitle1"
                          autocomplete="off"
                        >
                      </td>
                      <td class="td2">办&nbsp;公&nbsp;电&nbsp;话</td>
                      <td>
                        <input
                          v-model="formInfo1.phone1"
                          :disabled="isDetail"
                          type="text"
                          name="phone1"
                          id="phone11"
                          autocomplete="off"
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="td2">手&nbsp;&nbsp;机</td>
                      <td>
                        <input
                          type="text"
                          v-model="formInfo1.mobile1"
                          :disabled="isDetail"
                          name="mobile1"
                          id="mobile11"
                          autocomplete="off"
                        >
                      </td>
                      <td class="td2">邮&nbsp;&nbsp;箱</td>
                      <td>
                        <input
                          type="text"
                          v-model="formInfo1.email1"
                          :disabled="isDetail"
                          name="email1"
                          id="email11"
                          autocomplete="off"
                        >
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="td1"
                        rowspan="2"
                      >责&nbsp;任&nbsp;部&nbsp;门</td>
                      <td rowspan="2">
                        <input
                          v-model="formInfo1.respDepart"
                          :disabled="isDetail"
                          type="text"
                          name="respDepart"
                          id="respDepart1"
                          autocomplete="off"
                        >
                      </td>
                      <td class="td2">负&nbsp;责&nbsp;人</td>
                      <td>
                        <input
                          v-model="formInfo1.principal"
                          :disabled="isDetail"
                          type="text"
                          name="principal"
                          id="principal1"
                          autocomplete="off"
                        >
                      </td>
                      <td class="td2">职&nbsp;务&nbsp;职&nbsp;称</td>
                      <td>
                        <input
                          v-model="formInfo1.pjobTitle"
                          :disabled="isDetail"
                          type="text"
                          name="pjobTitle"
                          id="pjobTitle1"
                          autocomplete="off"
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="td2">手&nbsp;&nbsp;机</td>
                      <td>
                        <input
                          v-model="formInfo1.mobile2"
                          :disabled="isDetail"
                          type="text"
                          name="mobile2"
                          id="mobile21"
                          autocomplete="off"
                        >
                      </td>
                      <td class="td2">邮&nbsp;&nbsp;箱</td>
                      <td>
                        <input
                          v-model="formInfo1.email3"
                          :disabled="isDetail"
                          type="text"
                          name="email3"
                          id="email31"
                          autocomplete="off"
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="td1">主&nbsp;要&nbsp;联&nbsp;系&nbsp;人</td>
                      <td>
                        <input
                          v-model="formInfo1.contacts"
                          :disabled="isDetail"
                          type="text"
                          name="contacts"
                          id="contacts1"
                          autocomplete="off"
                        >
                      </td>
                      <td class="td2">手&nbsp;&nbsp;机</td>
                      <td>
                        <input
                          v-model="formInfo1.phone2"
                          :disabled="isDetail"
                          type="text"
                          name="phone2"
                          id="phone21"
                          autocomplete="off"
                        >
                      </td>
                      <td class="td2">邮&nbsp;&nbsp;箱</td>
                      <td>
                        <input
                          v-model="formInfo1.email2"
                          :disabled="isDetail"
                          type="text"
                          name="email2"
                          id="email21"
                          autocomplete="off"
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div id="subBtn">
                  <a
                    href="javascript:;"
                    class="subject"
                    @click="subjectFormShow = {visibility: 'visible'}"
                  >本科学科领域意向</a>
                  <a
                      href="javascript:;"
                      class="subject"
                      @click="subjectForm2Show = {visibility: 'visible'}"
                  >高职学科领域意向</a>
                </div>
                <div class="attchs">
                  <div class="">备注：</div>
                  <div class="attch_item">
                    <span>1、高校/事业单位LOGO</span>
                    <a
                      v-if="!isDetail"
                      href="javascript:;"
                      data-type="logo"
                      class="uploadBtn"
                      @click="showWindow1"
                    >上传</a>
                    <a
                      v-if="isDetail"
                      href="javascript:;"
                      data-type="logo"
                      class="viewBtn"
                      @click="showWindow1"
                    >查看</a>
                  </div>
                </div>
              </div>
              <div :class="showCategory == 2? 'type2 ' : 'type2 hide'">
                <h2>虚拟仿真实验教学创新联盟企业/事业单位会员申请表</h2>
                <table
                  border="0"
                  cellspacing="0"
                  cellpadding="0"
                >
                  <tbody>
                    <tr>
                      <td class="td1">企&nbsp;业&nbsp;名&nbsp;称</td>
                      <td colspan="5">
                        <input
                          type="text"
                          v-model.trim="formInfo2.title"
                          :disabled="isDetail"
                          name="title"
                          id="title2"
                          autocomplete="off"
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="td1">企&nbsp;业&nbsp;法&nbsp;人</td>
                      <td colspan="2">
                        <input
                          type="text"
                          v-model="formInfo2.legalPerson"
                          :disabled="isDetail"
                          name="legalPerson"
                          id="legalPerson"
                          autocomplete="off"
                        >
                      </td>
                      <td class="td3">成&nbsp;&nbsp;立&nbsp;&nbsp;时&nbsp;&nbsp;间</td>
                      <td colspan="2">

                        <input
                          type="date"
                          v-model="formInfo2.foundDate"
                          :disabled="isDetail"
                          style="width: 100%;height: 100%;border: none"
                          name="foundDate"
                          id="foundDate"
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="td1">企&nbsp;业&nbsp;性&nbsp;质</td>
                      <td
                        colspan="5"
                        align="left"
                      >
                        <label><input
                            v-model="formInfo2.nature"
                            :disabled="isDetail"
                            type="radio"
                            name="nature"
                            value="1"
                          >国有</label>
                        <label><input
                            v-model="formInfo2.nature"
                            :disabled="isDetail"
                            type="radio"
                            name="nature"
                            value="2"
                          >私有</label>
                        <label><input
                            v-model="formInfo2.nature"
                            :disabled="isDetail"
                            type="radio"
                            name="nature"
                            value="3"
                          >外资</label>
                        <label><input
                            v-model="formInfo2.nature"
                            :disabled="isDetail"
                            type="radio"
                            name="nature"
                            value="4"
                          >独资</label>
                        <label><input
                            v-model="formInfo2.nature"
                            :disabled="isDetail"
                            type="radio"
                            name="nature"
                            value="5"
                          >合资</label>
                      </td>
                    </tr>
                    <tr>
                      <td class="td1">注&nbsp;册&nbsp;资&nbsp;金</td>
                      <td colspan="5">
                        <input
                          type="text"
                          v-model="formInfo2.registeredCapital"
                          :disabled="isDetail"
                          name="registeredCapital"
                          id="registeredCapital"
                          autocomplete="off"
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="td1">企&nbsp;业&nbsp;简&nbsp;介</td>
                      <td colspan="5">
                        <textarea
                          v-model="formInfo2.brief"
                          :disabled="isDetail"
                          name="brief"
                          id="brief"
                          rows="5"
                          placeholder="不超过300字"
                          maxlength="300"
                          autocomplete="off"
                        ></textarea>
                      </td>
                    </tr>
                    <tr>
                      <td class="td1">通&nbsp;讯&nbsp;地&nbsp;址</td>
                      <td colspan="3">
                        <input
                          type="text"
                          v-model="formInfo2.address"
                          :disabled="isDetail"
                          name="address"
                          id="address2"
                          autocomplete="off"
                        >
                      </td>
                      <td class="td2">邮&nbsp;&nbsp;编</td>
                      <td>
                        <input
                          type="text"
                          v-model="formInfo2.postcode"
                          :disabled="isDetail"
                          name="postcode"
                          id="postcode2"
                          autocomplete="off"
                        >
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="td1"
                        rowspan="2"
                      >
                        <p>公&nbsp;司&nbsp;主&nbsp;管</p>
                        <p>领&nbsp;导</p>
                      </td>
                      <td rowspan="2">
                        <input
                          type="text"
                          v-model="formInfo2.mainPrincipal"
                          :disabled="isDetail"
                          name="mainPrincipal"
                          id="mainPrincipal2"
                          autocomplete="off"
                        >
                      </td>
                      <td class="td2">职&nbsp;务&nbsp;职&nbsp;称</td>
                      <td>
                        <input
                          type="text"
                          v-model="formInfo2.mpjobTitle"
                          :disabled="isDetail"
                          name="mpjobTitle"
                          id="mpjobTitle2"
                          autocomplete="off"
                        >
                      </td>
                      <td class="td2">办&nbsp;公&nbsp;电&nbsp;话</td>
                      <td>
                        <input
                          type="text"
                          v-model="formInfo2.phone1"
                          :disabled="isDetail"
                          name="phone1"
                          id="phone12"
                          autocomplete="off"
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="td2">手&nbsp;&nbsp;机</td>
                      <td>
                        <input
                          type="text"
                          v-model="formInfo2.mobile1"
                          :disabled="isDetail"
                          name="mobile1"
                          id="mobile12"
                          autocomplete="off"
                        >
                      </td>
                      <td class="td2">邮&nbsp;&nbsp;箱</td>
                      <td>
                        <input
                          type="text"
                          v-model="formInfo2.email1"
                          :disabled="isDetail"
                          name="email1"
                          id="email12"
                          autocomplete="off"
                        >
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="td1"
                        rowspan="2"
                      >责&nbsp;任&nbsp;部&nbsp;门</td>
                      <td rowspan="2">
                        <input
                          type="text"
                          v-model="formInfo2.respDepart"
                          :disabled="isDetail"
                          name="respDepart"
                          id="respDepart2"
                          autocomplete="off"
                        >
                      </td>
                      <td class="td2">负&nbsp;责&nbsp;人</td>
                      <td>
                        <input
                          type="text"
                          v-model="formInfo2.principal"
                          :disabled="isDetail"
                          name="principal"
                          id="principal2"
                          autocomplete="off"
                        >
                      </td>
                      <td class="td2">职&nbsp;务&nbsp;职&nbsp;称</td>
                      <td>
                        <input
                          type="text"
                          v-model="formInfo2.pjobTitle"
                          :disabled="isDetail"
                          name="pjobTitle"
                          id="pjobTitle2"
                          autocomplete="off"
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="td2">手&nbsp;&nbsp;机</td>
                      <td>
                        <input
                          type="text"
                          v-model="formInfo2.mobile2"
                          :disabled="isDetail"
                          name="mobile2"
                          id="mobile22"
                          autocomplete="off"
                        >
                      </td>
                      <td class="td2">邮&nbsp;&nbsp;箱</td>
                      <td>
                        <input
                          type="text"
                          v-model="formInfo2.email3"
                          :disabled="isDetail"
                          name="email3"
                          id="email32"
                          autocomplete="off"
                        >
                      </td>
                    </tr>

                    <tr>
                      <td class="td1">主&nbsp;要&nbsp;联&nbsp;系&nbsp;人</td>
                      <td>
                        <input
                          type="text"
                          v-model="formInfo2.contacts"
                          :disabled="isDetail"
                          name="contacts"
                          id="contacts2"
                          autocomplete="off"
                        >
                      </td>
                      <td class="td2">手&nbsp;&nbsp;机</td>
                      <td>
                        <input
                          type="text"
                          v-model="formInfo2.phone2"
                          :disabled="isDetail"
                          name="phone2"
                          id="phone22"
                          autocomplete="off"
                        >
                      </td>
                      <td class="td2">邮&nbsp;&nbsp;箱</td>
                      <td>
                        <input
                          type="text"
                          v-model="formInfo2.email2"
                          :disabled="isDetail"
                          name="email2"
                          id="email22"
                          autocomplete="off"
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div id="subBtn3">
                  <a
                      href="javascript:;"
                      class="subject"
                      @click="subjectFormShow = {visibility: 'visible'}"
                  >本科学科领域意向</a>
                  <a
                      href="javascript:;"
                      class="subject"
                      @click="subjectForm2Show = {visibility: 'visible'}"
                  >高职学科领域意向</a>
                </div>
                <div class="attchs">
                  <div class="">备注：</div>
                  <div class="attch_item">
                    <span>1、单位营业执照复印件</span>
                    <a
                      v-if="!isDetail"
                      href="javascript:;"
                      data-type="cer"
                      class="uploadBtn"
                      @click="showWindow2"
                    >上传</a>
                    <a
                      v-if="isDetail"
                      href="javascript:;"
                      data-type="logo"
                      class="viewBtn"
                      @click="showWindow2"
                    >查看</a>
                  </div>
                  <div class="attch_item">
                    <span>2、单位简介及个人简历</span>
                    <a
                      v-if="!isDetail"
                      href="javascript:;"
                      data-type="pic"
                      class="uploadBtn"
                      @click="showWindow3"
                    >上传</a>
                    <a
                      v-if="isDetail"
                      href="javascript:;"
                      data-type="logo"
                      class="viewBtn"
                      @click="showWindow3"
                    >查看</a>
                  </div>
                  <div class="attch_item">
                    <span>3、企业LOGO</span>
                    <a
                      v-if="!isDetail"
                      href="javascript:;"
                      data-type="logo"
                      class="uploadBtn"
                      @click="showWindow4"
                    >上传</a>
                    <a
                      v-if="isDetail"
                      href="javascript:;"
                      data-type="logo"
                      class="viewBtn"
                      @click="showWindow4"
                    >查看</a>
                  </div>
                </div>
              </div>
              <div :class="showCategory == 3? 'type3 ' : 'type3 hide'">
                <h2>虚拟仿真实验教学创新联盟高校二级学院会员申请表</h2>
                <table
                  border="0"
                  cellspacing="0"
                  cellpadding="0"
                >
                  <tbody>
                    <tr>
                      <td class="td1">学&nbsp;校&nbsp;名&nbsp;称</td>
                      <td colspan="5">
                        <input
                          v-model.trim="formInfo3.schoolTitle"
                          :disabled="isDetail"
                          type="text"
                          name="title"
                          id="title3"
                          autocomplete="off"
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="td1">院&nbsp;系&nbsp;名&nbsp;称</td>
                      <td colspan="5">
                        <input
                          v-model.trim="formInfo3.title"
                          :disabled="isDetail"
                          type="text"
                          name="subTitle"
                          id="subTitle"
                          autocomplete="off"
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="td1">通&nbsp;讯&nbsp;地&nbsp;址</td>
                      <td colspan="3">
                        <input
                          type="text"
                          v-model="formInfo3.address"
                          :disabled="isDetail"
                          name="address"
                          autocomplete="off"
                        >
                      </td>
                      <td class="td2">邮&nbsp;&nbsp;编</td>
                      <td>
                        <input
                          type="text"
                          v-model="formInfo3.postcode"
                          :disabled="isDetail"
                          name="postcode"
                          autocomplete="off"
                        >
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="td1"
                        rowspan="2"
                      >
                        <p>学&nbsp;院&nbsp;主&nbsp;管</p>
                        <p>领&nbsp;导</p>
                      </td>
                      <td rowspan="2">
                        <input
                          v-model="formInfo3.mainPrincipal"
                          :disabled="isDetail"
                          type="text"
                          name="mainPrincipal"
                          autocomplete="off"
                        >
                      </td>
                      <td class="td2">职&nbsp;务&nbsp;职&nbsp;称</td>
                      <td>
                        <input
                          v-model="formInfo3.mpjobTitle"
                          :disabled="isDetail"
                          type="text"
                          name="mpjobTitle"
                          autocomplete="off"
                        >
                      </td>
                      <td class="td2">办&nbsp;公&nbsp;电&nbsp;话</td>
                      <td>
                        <input
                          v-model="formInfo3.phone1"
                          :disabled="isDetail"
                          type="text"
                          name="phone1"
                          autocomplete="off"
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="td2">手&nbsp;&nbsp;机</td>
                      <td>
                        <input
                          type="text"
                          v-model="formInfo3.mobile1"
                          :disabled="isDetail"
                          name="mobile1"
                          autocomplete="off"
                        >
                      </td>
                      <td class="td2">邮&nbsp;&nbsp;箱</td>
                      <td>
                        <input
                          type="text"
                          v-model="formInfo3.email1"
                          :disabled="isDetail"
                          name="email1"
                          autocomplete="off"
                        >
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="td1"
                        rowspan="2"
                      >责&nbsp;任&nbsp;部&nbsp;门</td>
                      <td rowspan="2">
                        <input
                          v-model="formInfo3.respDepart"
                          :disabled="isDetail"
                          type="text"
                          name="respDepart"
                          autocomplete="off"
                        >
                      </td>
                      <td class="td2">负&nbsp;责&nbsp;人</td>
                      <td>
                        <input
                          v-model="formInfo3.principal"
                          :disabled="isDetail"
                          type="text"
                          name="principal"
                          autocomplete="off"
                        >
                      </td>
                      <td class="td2">职&nbsp;务&nbsp;职&nbsp;称</td>
                      <td>
                        <input
                          v-model="formInfo3.pjobTitle"
                          :disabled="isDetail"
                          type="text"
                          name="pjobTitle"
                          autocomplete="off"
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="td2">手&nbsp;&nbsp;机</td>
                      <td>
                        <input
                          v-model="formInfo3.mobile2"
                          :disabled="isDetail"
                          type="text"
                          name="mobile2"
                          autocomplete="off"
                        >
                      </td>
                      <td class="td2">邮&nbsp;&nbsp;箱</td>
                      <td>
                        <input
                          v-model="formInfo3.email3"
                          :disabled="isDetail"
                          type="text"
                          name="email3"
                          autocomplete="off"
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="td1">主&nbsp;要&nbsp;联&nbsp;系&nbsp;人</td>
                      <td>
                        <input
                          v-model="formInfo3.contacts"
                          :disabled="isDetail"
                          type="text"
                          name="contacts"
                          autocomplete="off"
                        >
                      </td>
                      <td class="td2">手&nbsp;&nbsp;机</td>
                      <td>
                        <input
                          v-model="formInfo3.phone2"
                          :disabled="isDetail"
                          type="text"
                          name="phone2"
                          autocomplete="off"
                        >
                      </td>
                      <td class="td2">邮&nbsp;&nbsp;箱</td>
                      <td>
                        <input
                          v-model="formInfo3.email2"
                          :disabled="isDetail"
                          type="text"
                          name="email2"
                          autocomplete="off"
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div id="subBtn3">
                  <a
                    href="javascript:;"
                    class="subject"
                    @click="subjectFormShow = {visibility: 'visible'}"
                  >本科学科领域意向</a>
                  <a
                      href="javascript:;"
                      class="subject"
                      @click="subjectForm2Show = {visibility: 'visible'}"
                  >高职学科领域意向</a>
                </div>
                <div class="attchs attchs3 hide">
                  <div class="">备注：</div>

                  <div class="attch_item">
                    <span>1、高校/事业单位LOGO</span>
                    <a
                      href="javascript:;"
                      data-type="logo"
                      class="uploadBtn"
                    >上传</a>
                  </div>
                </div>
              </div>
              <div
                class="submit"
                v-if="pageMode == 1 && showCategory > 0"
              >
                <a
                  v-if="showCategory==2 && briefInfo.show_status==0  && briefInfo.show_status!=='' && changStatus==2"
                  href="javascript:;"
                  class="subBtn"
                  id="modifyBtn"
                  @click="showCompanyWidow(1)"
                  style="padding:10px 20px;height: 25px;line-height: 25px;font-size:14px;margin-right:20px"
                >编辑企业简介</a>
                <a
                  v-if="showCategory==2 && briefInfo.show_status!==0 && changStatus==3"
                  href="javascript:;"
                  class="subBtn"
                  id="modifyBtn"
                  @click="showCompanyWidow(2)"
                  style="padding:10px 20px;height: 25px;line-height: 25px;font-size:14px;margin-right:20px"
                >修改企业简介</a>
                <a
                  v-if="showCategory==2 && briefInfo.show_status==2 && changStatus==1"
                  href="javascript:;"
                  class="subBtn"
                  id="modifyBtn"
                  @click="showReject"
                  style="padding:10px 20px;height: 25px;line-height: 25px;font-size:14px;margin-right:20px;background:#a30014;border-color:#a30014"
                >查看企业简介驳回原因</a>
                <a
                  href="javascript:;"
                  class="subBtn"
                  @click="checkOwner"
                >确认提交</a>
              </div>
              <div
                class="submit"
                v-if="!isDetail && (pageMode == 2 || pageMode == 3 || pageMode == 4)"
              >
                <a
                  href="javascript:;"
                  class="subBtn"
                  @click="editInfo"
                >确认提交</a>
              </div>

              <div
                class="clearfix"
                v-if="pageMode >= 2 && isDetail"
              >
                <div
                  class="status"
                  id="status"
                >
                  <h3>处理进度</h3>
                  <div
                    class="progress"
                    :class="processBarClass"
                  >
                    <span class="progressBar"></span>
                    <span class="leftTip">提交申请表</span>
                    <span class="rightTip">审核</span>
                    <div class="leftInfo">
                      <p>管理员</p>
                      <p>2021-05-13 17:07</p>
                      <p
                        class="btns"
                        style="position: absolute"
                      >
                        <a
                          href="javascript:;"
                          class="btn "
                          id="downloadBtn"
                          :class="pageMode!=2 ? 'disabled' : ''"
                          style="width: 98px;height: 25px;line-height: 25px;"
                          @click="downloadPdf"
                        >下载完整申请表</a>
                        <a
                          ref="downLoadBtn"
                          :download="downLoadName"
                          :href="downLoadUrl"
                          style="display: none"
                        ></a>
                      </p>
                    </div>
                    <div class="rightInfo">
                      <p>
                        状态：
                        <span
                          v-if="pageMode == 2"
                          class="itemStatus3"
                        >审核中</span>
                        <span
                          v-if="pageMode == 3"
                          class="itemStatus1"
                        >已通过</span>
                        <span
                          v-if="pageMode == 4"
                          class="itemStatus0"
                        >驳回</span>
                      </p>
                      <p v-if="pageMode == 4 && showCategory == 1">原因：{{ formInfo1.rejectReason }}</p>
                      <p v-if="pageMode == 4 && showCategory == 2">原因：{{ formInfo2.rejectReason }}</p>
                      <p v-if="pageMode == 4 && showCategory == 3">原因：{{ formInfo3.rejectReason }}</p>
                    </div>
                  </div>
                </div>
                <div class="btns">

                </div>
              </div>

              <div
                class="submit"
                v-if="isDetail && (pageMode == 2 || pageMode == 3 || pageMode == 4)"
                style="margin-top: 0px;"
              >
                <a
                  v-if="showCategory==2 && briefInfo.show_status==0  && briefInfo.show_status!=='' && changStatus==2"
                  href="javascript:;"
                  class="subBtn"
                  id="modifyBtn"
                  @click="showCompanyWidow(1)"
                  style="padding:10px 20px;height: 25px;line-height: 25px;font-size:14px;margin-right:20px"
                >编辑企业简介</a>
                <a
                  v-if="showCategory==2 && briefInfo.show_status!==0 && changStatus==3"
                  href="javascript:;"
                  class="subBtn"
                  id="modifyBtn"
                  @click="showCompanyWidow(2)"
                  style="padding:10px 20px;height: 25px;line-height: 25px;font-size:14px;margin-right:20px"
                >修改企业简介</a>
                <a
                  v-if="showCategory==2 && briefInfo.show_status==2 && changStatus==1"
                  href="javascript:;"
                  class="subBtn"
                  id="modifyBtn"
                  @click="showReject"
                  style="padding:10px 20px;height: 25px;line-height: 25px;font-size:14px;margin-right:20px;background:#a30014;border-color:#a30014"
                >查看企业简介驳回原因</a>
                <a
                  href="javascript:;"
                  class="subBtn"
                  id="modifyBtn"
                  @click="changeToEdit"
                  style="padding:10px 20px;height: 25px;line-height: 25px;font-size:14px;"
                >修改申请</a>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="form_overlay"
      id="form_overlay"
      :style="fileFormShow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">申请资料上传</div>
        <div class="form_content">
          <form id="itemForm">
            <div
              class="item cer"
              :class="windowConfig.showIndex == 'cer' ? '' : 'hide'"
            >
              <span class="label">单位法人证书复印件:</span>
              <div class="item-box">
                <span
                  v-if="!isDetail"
                  class="button uploadImg"
                  data-type="1"
                  id="uploadImg1"
                  @click="showImageWindow"
                >上传</span>
                <input
                  style="visibility:hidden;"
                  name="imgPath1"
                  type="text"
                  id="imgurl1"
                  disabled="disabled"
                >
              </div>
            </div>
            <div
              class="item cer"
              :class="windowConfig.showIndex == 'cer' ? '' : 'hide'"
            >
              <div
                class="preview"
                id="preview1"
                v-show="windowConfig.showPreview.preview1"
              >
                <img :src="formatImgPath(formInfo2.credentialsUrl)">
              </div>
            </div>

            <div class="item lic hide">
              <span class="label">企业营业执照或事业注册登记证复印件:</span>
              <div class="item-box">
                <span
                  class="button uploadImg"
                  data-type="2"
                  id="uploadImg2"
                >上传</span>
                <input
                  style="visibility:hidden;"
                  name="imgPath2"
                  type="text"
                  id="imgurl2"
                  disabled="disabled"
                >
              </div>
            </div>
            <div class="item lic hide">
              <div
                class="preview"
                id="preview2"
              ></div>
            </div>

            <div class="item id hide">
              <span class="label">法人代表身份证复印件:</span>
              <div class="item-box">
                <span
                  class="button uploadImg"
                  data-type="3"
                  id="uploadImg3"
                >正面上传</span>
                <span
                  class="button uploadImg"
                  data-type="4"
                  id="uploadImg4"
                >反面上传</span>
                <input
                  style="visibility:hidden;"
                  name="imgPath3"
                  type="text"
                  id="imgurl3"
                  disabled="disabled"
                >
                <input
                  style="visibility:hidden;"
                  name="imgPath4"
                  type="text"
                  id="imgurl4"
                  disabled="disabled"
                >
              </div>
            </div>
            <div class="item id hide">
              <div
                class="preview"
                id="preview3"
              ></div>
              <div
                class="preview"
                id="preview4"
              ></div>
            </div>

            <div class="item id hide">
              <span class="label">主要负责人身份证复印件:</span>
              <div class="item-box">
                <span
                  class="button uploadImg"
                  data-type="5"
                  id="uploadImg5"
                >正面上传</span>
                <span
                  class="button uploadImg"
                  data-type="6"
                  id="uploadImg6"
                >反面上传</span>
                <input
                  style="visibility:hidden;"
                  name="imgPath5"
                  type="text"
                  id="imgurl5"
                  disabled="disabled"
                >
                <input
                  style="visibility:hidden;"
                  name="imgPath6"
                  type="text"
                  id="imgurl6"
                  disabled="disabled"
                >
              </div>
            </div>
            <div class="item id hide">
              <div
                class="preview"
                id="preview5"
              ></div>
              <div
                class="preview"
                id="preview6"
              ></div>
            </div>

            <div
              class="item pic"
              :class="windowConfig.showIndex == 'pic' ? '' : 'hide'"
            >
              <span class="label">单位简介:</span>
              <div class="item-box clearfix">
                <div
                  class="filebox-list"
                  id="attUrl1"
                >
                  <p
                    v-for="(item, index) in windowConfig.orgFile"
                    :key="index"
                  >
                    <a :href="formatImgPath(item.filePath)">{{ formatFileName(item.fileName) }}</a>
                    <span
                      @click="windowConfig.orgFile.splice(index, 1)"
                      title="删除"
                      class="del"
                    >×</span>
                  </p>
                </div>
                <span
                  v-if="!isDetail"
                  class="button uploadAtt"
                  data-type="1"
                  id="attPath1"
                  @click="showFileWindow1"
                >上传</span>
              </div>
            </div>

            <div
              class="item pic"
              :class="windowConfig.showIndex == 'pic' ? '' : 'hide'"
            >
              <span class="label">个人简历:</span>
              <div class="item-box clearfix">
                <div
                  class="filebox-list"
                  id="attUrl2"
                >
                  <p
                    v-for="(item, index) in windowConfig.leaderFile"
                    :key="index"
                  >
                    <a :href="formatImgPath(item.filePath)">{{ formatFileName(item.fileName) }}</a>
                    <span
                      @click="windowConfig.leaderFile.splice(index, 1)"
                      title="删除"
                      class="del"
                    >×</span>
                  </p>
                </div>
                <span
                  v-if="!isDetail"
                  class="button uploadAtt"
                  data-type="2"
                  id="attPath2"
                  @click="showFileWindow2"
                >上传</span>
              </div>
            </div>

            <div
              class="item logo"
              :class="windowConfig.showIndex == 'logo' ? '' : 'hide'"
            >
              <span class="label">建议图片大小:</span>
              <div class="item-box">
                <span style="position:relative;top:-5px;">300px * 300px</span>
              </div>
            </div>
            <div
              class="item logo"
              :class="windowConfig.showIndex == 'logo' ? '' : 'hide'"
            >
              <span class="label">单位/企业LOGO:</span>
              <div class="item-box">
                <span
                  v-if="!isDetail"
                  class="button uploadImg"
                  data-type="8"
                  id="uploadImg8"
                  @click="showImageWindow"
                >上传</span>
                <input
                  style="visibility:hidden;"
                  name="imgPath8"
                  type="text"
                  id="imgurl8"
                  disabled="disabled"
                >
              </div>
            </div>
            <div
              class="item logo"
              :class="windowConfig.showIndex == 'logo' ? '' : 'hide'"
            >
              <div
                class="preview"
                id="preview8"
                v-show="windowConfig.showPreview.preview8"
              >
                <img :src="formatImgPath(formInfo1.logo)">
              </div>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a
            v-if="!isDetail"
            href="javascript:;"
            class="button close"
            id="form_close"
            @click="hideWindow"
          >取消</a>
          <a
            href="javascript:;"
            class="button"
            id="form_submit"
            @click="hideWindow"
          >
            {{ isDetail ? '关闭' : '提交' }}
          </a>
        </div>
      </div>
    </div>

    <div
      class="form_overlay"
      id="subjectForm_overlay"
      :style="subjectFormShow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">
          <p>本科学科领域意向</p>
        </div>
        <div class="form_content">
          <div class="subjects">
            <div class="subjectItem">
              <div class="item_title clearfix">
                <div class="title">基础领域</div>
                <div class="contacts">
                  <input
                    v-model="subjectIntentionObj.connect[0].connect"
                    class="name"
                    name="name"
                    data-type="0"
                    placeholder="请输入联系人"
                    autocomplete="off"
                  >
                  <input
                    v-model="subjectIntentionObj.connect[0].phone"
                    class="phone"
                    name="phone"
                    data-type="0"
                    placeholder="请输入联系电话"
                    autocomplete="off"
                  >
                </div>
              </div>
              <hr>
              <div class="item_options clearfix">
                <div
                  class="option"
                  data-value="物理学类"
                  data-index="0"
                  :class="subjectIntentionObj.checked['0'] == 1 ? 'selected' : ''"
                  @click="changeSubject(0)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>物理学类</p>
                </div>
                <div
                  class="option"
                  data-value="化学类"
                  data-index="1"
                  :class="subjectIntentionObj.checked['1'] == 1 ? 'selected' : ''"
                  @click="changeSubject(1)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>化学类</p>
                </div>
                <div
                    class="option"
                    data-value="地理科学类"
                    data-index="3"
                    :class="subjectIntentionObj.checked['3'] == 1 ? 'selected' : ''"
                    @click="changeSubject(3)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>地理科学类</p>
                </div>
                <div
                    class="option"
                    data-value="大气科学类"
                    data-index="4"
                    :class="subjectIntentionObj.checked['4'] == 1 ? 'selected' : ''"
                    @click="changeSubject(4)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>大气科学类</p>
                </div>
                <div
                    class="option"
                    data-value="海洋科学类"
                    data-index="5"
                    :class="subjectIntentionObj.checked['5'] == 1 ? 'selected' : ''"
                    @click="changeSubject(5)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>海洋科学类</p>
                </div>
                <div
                    class="option"
                    data-value="地球物理学类"
                    data-index="6"
                    :class="subjectIntentionObj.checked['6'] == 1 ? 'selected' : ''"
                    @click="changeSubject(6)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>地球物理学类</p>
                </div>
                <div
                    class="option"
                    data-value="地质学类"
                    data-index="7"
                    :class="subjectIntentionObj.checked['7'] == 1 ? 'selected' : ''"
                    @click="changeSubject(7)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>地质学类</p>
                </div>
                <div
                    class="option"
                    data-value="心理学类"
                    data-index="9"
                    :class="subjectIntentionObj.checked['9'] == 1 ? 'selected' : ''"
                    @click="changeSubject(9)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>心理学类</p>
                </div>
<!--                <div-->
<!--                  class="option"-->
<!--                  data-value="天文"-->
<!--                  data-index="2"-->
<!--                  :class="subjectIntentionObj.checked['2'] == 1 ? 'selected' : ''"-->
<!--                  @click="changeSubject(2)"-->
<!--                >-->
<!--                  <p class="checkbox">-->
<!--                    <span></span>-->
<!--                  </p>-->
<!--                  <p>天文</p>-->
<!--                </div>-->
<!--                <div-->
<!--                  class="option"-->
<!--                  data-value="生物科学"-->
<!--                  data-index="8"-->
<!--                  :class="subjectIntentionObj.checked['8'] == 1 ? 'selected' : ''"-->
<!--                  @click="changeSubject(8)"-->
<!--                >-->
<!--                  <p class="checkbox">-->
<!--                    <span></span>-->
<!--                  </p>-->
<!--                  <p>生物科学</p>-->
<!--                </div>-->
              </div>
            </div>
            <div class="subjectItem">
              <div class="item_title clearfix">
                <div class="title">医学领域</div>
                <div class="contacts">
                  <input
                      v-model="subjectIntentionObj.connect[7].connect"
                      class="name"
                      name="name"
                      data-type="7"
                      placeholder="请输入联系人"
                      autocomplete="off"
                  >
                  <input
                      v-model="subjectIntentionObj.connect[7].phone"
                      class="phone"
                      name="phone"
                      data-type="7"
                      placeholder="请输入联系电话"
                      autocomplete="off"
                  >
                </div>
              </div>
              <hr>
              <div class="item_options clearfix">
                <div
                    class="option"
                    data-value="基础医学类"
                    data-index="51"
                    :class="subjectIntentionObj.checked['51'] == 1 ? 'selected' : ''"
                    @click="changeSubject(51)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>基础医学类</p>
                </div>
                <div
                    class="option"
                    data-value="临床医学类"
                    data-index="52"
                    :class="subjectIntentionObj.checked['52'] == 1 ? 'selected' : ''"
                    @click="changeSubject(52)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>临床医学类</p>
                </div>
                <div
                    class="option"
                    data-value="公共卫生与预防医学类"
                    data-index="53"
                    :class="subjectIntentionObj.checked['53'] == 1 ? 'selected' : ''"
                    @click="changeSubject(53)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>公共卫生与预防医学类</p>
                </div>
                <div
                    class="option"
                    data-value="中医学类"
                    data-index="54"
                    :class="subjectIntentionObj.checked['54'] == 1 ? 'selected' : ''"
                    @click="changeSubject(54)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>中医学类</p>
                </div>
                <div
                    class="option"
                    data-value="药学类"
                    data-index="55"
                    :class="subjectIntentionObj.checked['55'] == 1 ? 'selected' : ''"
                    @click="changeSubject(55)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>药学类</p>
                </div>
                <div
                    class="option"
                    data-value="法医学类"
                    data-index="56"
                    :class="subjectIntentionObj.checked['56'] == 1 ? 'selected' : ''"
                    @click="changeSubject(56)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>法医学类</p>
                </div>
                <div
                    class="option"
                    data-value="医学技术类"
                    data-index="57"
                    :class="subjectIntentionObj.checked['57'] == 1 ? 'selected' : ''"
                    @click="changeSubject(57)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>医学技术类</p>
                </div>
                <div
                    class="option"
                    data-value="护理学类"
                    data-index="58"
                    :class="subjectIntentionObj.checked['58'] == 1 ? 'selected' : ''"
                    @click="changeSubject(58)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>护理学类</p>
                </div>
                <div
                    class="option"
                    data-value="口腔医学类"
                    data-index="59"
                    :class="'59' in subjectIntentionObj.checked && subjectIntentionObj.checked['59'] === 1 ? 'selected' : ''"
                    @click="changeSubject(59)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>口腔医学类</p>
                </div>
              </div>
            </div>
            <div class="subjectItem">
              <div class="item_title clearfix">
                <div class="title">机械领域</div>
                <div class="contacts">
                  <input
                      v-model="subjectIntentionObj.connect[3].connect"
                      class="name"
                      name="name"
                      data-type="3"
                      placeholder="请输入联系人"
                      autocomplete="off"
                  >
                  <input
                      v-model="subjectIntentionObj.connect[3].phone"
                      class="phone"
                      name="phone"
                      data-type="3"
                      placeholder="请输入联系电话"
                      autocomplete="off"
                  >
                </div>
              </div>
              <hr>
              <div class="item_options clearfix">
                <div
                    class="option"
                    data-value="力学类"
                    data-index="22"
                    :class="subjectIntentionObj.checked['22'] == 1 ? 'selected' : ''"
                    @click="changeSubject(22)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>力学类</p>
                </div>
                <div
                    class="option"
                    data-value="机械类"
                    data-index="23"
                    :class="subjectIntentionObj.checked['23'] == 1 ? 'selected' : ''"
                    @click="changeSubject(23)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>机械类</p>
                </div>
                <div
                    class="option"
                    data-value="仪器类"
                    data-index="24"
                    :class="subjectIntentionObj.checked['24'] == 1 ? 'selected' : ''"
                    @click="changeSubject(24)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>仪器类</p>
                </div>
                <div
                    class="option"
                    data-value="材料类"
                    data-index="25"
                    :class="subjectIntentionObj.checked['25'] == 1 ? 'selected' : ''"
                    @click="changeSubject(25)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>材料类</p>
                </div>
                <div
                    class="option"
                    data-value="能源动力类"
                    data-index="26"
                    :class="subjectIntentionObj.checked['26'] == 1 ? 'selected' : ''"
                    @click="changeSubject(26)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>能源动力类</p>
                </div>
                <div
                    class="option"
                    data-value="交通运输类"
                    data-index="27"
                    :class="subjectIntentionObj.checked['27'] == 1 ? 'selected' : ''"
                    @click="changeSubject(27)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>交通运输类</p>
                </div>
                <div
                    class="option"
                    data-value="海洋工程类"
                    data-index="28"
                    :class="subjectIntentionObj.checked['28'] == 1 ? 'selected' : ''"
                    @click="changeSubject(28)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>海洋工程类</p>
                </div>
                <div
                    class="option"
                    data-value="航空航天类"
                    data-index="29"
                    :class="subjectIntentionObj.checked['29'] == 1 ? 'selected' : ''"
                    @click="changeSubject(29)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>航空航天类</p>
                </div>
                <div
                    class="option"
                    data-value="兵器类"
                    data-index="30"
                    :class="subjectIntentionObj.checked['30'] == 1 ? 'selected' : ''"
                    @click="changeSubject(30)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>兵器类</p>
                </div>
              </div>
            </div>
            <div class="subjectItem">
              <div class="item_title clearfix">
                <div class="title">信息领域</div>
                <div class="contacts">
                  <input
                      v-model="subjectIntentionObj.connect[2].connect"
                      class="name"
                      name="name"
                      data-type="2"
                      placeholder="请输入联系人"
                      autocomplete="off"
                  >
                  <input
                      v-model="subjectIntentionObj.connect[2].phone"
                      class="phone"
                      name="phone"
                      data-type="2"
                      placeholder="请输入联系电话"
                      autocomplete="off"
                  >
                </div>
              </div>
              <hr>
              <div class="item_options clearfix">
                <div
                    class="option"
                    data-value="电气类"
                    data-index="17"
                    :class="subjectIntentionObj.checked['17'] == 1 ? 'selected' : ''"
                    @click="changeSubject(17)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>电气类</p>
                </div>
                <div
                    class="option"
                    data-value="电子信息类"
                    data-index="16"
                    :class="subjectIntentionObj.checked['16'] == 1 ? 'selected' : ''"
                    @click="changeSubject(16)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>电子信息类</p>
                </div>

                <div
                    class="option"
                    data-value="自动化类"
                    data-index="18"
                    :class="subjectIntentionObj.checked['18'] == 1 ? 'selected' : ''"
                    @click="changeSubject(18)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>自动化类</p>
                </div>
                <div
                    class="option"
                    data-value="计算机类"
                    data-index="19"
                    :class="subjectIntentionObj.checked['19'] == 1 ? 'selected' : ''"
                    @click="changeSubject(19)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>计算机类</p>
                </div>
                <div
                    class="option"
                    data-value="安全科学与工程类"
                    data-index="20"
                    :class="subjectIntentionObj.checked['20'] == 1 ? 'selected' : ''"
                    @click="changeSubject(20)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>安全科学与工程类</p>
                </div>
                <div
                    class="option"
                    data-value="公安技术类"
                    data-index="21"
                    :class="subjectIntentionObj.checked['21'] == 1 ? 'selected' : ''"
                    @click="changeSubject(21)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>公安技术类</p>
                </div>
              </div>
            </div>
            <div class="subjectItem">
              <div class="item_title clearfix">
                <div class="title">土建领域</div>
                <div class="contacts">
                  <input
                      v-model="subjectIntentionObj.connect[1].connect"
                      class="name"
                      name="name"
                      data-type="1"
                      placeholder="请输入联系人"
                      autocomplete="off"
                  >
                  <input
                      v-model="subjectIntentionObj.connect[1].phone"
                      class="phone"
                      name="phone"
                      data-type="1"
                      placeholder="请输入联系电话"
                      autocomplete="off"
                  >
                </div>
              </div>
              <hr>
              <div class="item_options clearfix">
                <div
                    class="option"
                    data-value="土木类"
                    data-index="11"
                    :class="subjectIntentionObj.checked['11'] == 1 ? 'selected' : ''"
                    @click="changeSubject(11)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>土木类</p>
                </div>
                <div
                    class="option"
                    data-value="水利类"
                    data-index="12"
                    :class="subjectIntentionObj.checked['12'] == 1 ? 'selected' : ''"
                    @click="changeSubject(12)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>水利类</p>
                </div>
                <div
                    class="option"
                    data-value="测绘类"
                    data-index="13"
                    :class="subjectIntentionObj.checked['13'] == 1 ? 'selected' : ''"
                    @click="changeSubject(13)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>测绘类</p>
                </div>
                <div
                    class="option"
                    data-value="地质类"
                    data-index="14"
                    :class="subjectIntentionObj.checked['14'] == 1 ? 'selected' : ''"
                    @click="changeSubject(14)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>地质类</p>
                </div>
                <div
                    class="option"
                    data-value="矿业类"
                    data-index="15"
                    :class="subjectIntentionObj.checked['15'] == 1 ? 'selected' : ''"
                    @click="changeSubject(15)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>矿业类</p>
                </div>
                <div
                    class="option"
                    data-value="建筑类"
                    data-index="10"
                    :class="subjectIntentionObj.checked['10'] == 1 ? 'selected' : ''"
                    @click="changeSubject(10)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>建筑类</p>
                </div>
              </div>
            </div>
            <div class="subjectItem">
              <div class="item_title clearfix">
                <div class="title">化环领域</div>
                <div class="contacts">
                  <input
                      v-model="subjectIntentionObj.connect[4].connect"
                      class="name"
                      name="name"
                      data-type="4"
                      placeholder="请输入联系人"
                      autocomplete="off"
                  >
                  <input
                      v-model="subjectIntentionObj.connect[4].phone"
                      class="phone"
                      name="phone"
                      data-type="4"
                      placeholder="请输入联系电话"
                      autocomplete="off"
                  >
                </div>
              </div>
              <hr>
              <div class="item_options clearfix">
                <div
                    class="option"
                    data-value="化工与制药类"
                    data-index="31"
                    :class="subjectIntentionObj.checked['31'] == 1 ? 'selected' : ''"
                    @click="changeSubject(31)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>化工与制药类</p>
                </div>
                <div
                    class="option"
                    data-value="纺织类"
                    data-index="32"
                    :class="subjectIntentionObj.checked['32'] == 1 ? 'selected' : ''"
                    @click="changeSubject(32)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>纺织类</p>
                </div>
                <div
                    class="option"
                    data-value="轻工类"
                    data-index="33"
                    :class="subjectIntentionObj.checked['33'] == 1 ? 'selected' : ''"
                    @click="changeSubject(33)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>轻工类</p>
                </div>
                <div
                    class="option"
                    data-value="核工程类"
                    data-index="34"
                    :class="subjectIntentionObj.checked['34'] == 1 ? 'selected' : ''"
                    @click="changeSubject(34)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>核工程类</p>
                </div>
                <div
                    class="option"
                    data-value="农林工程类"
                    data-index="37"
                    :class="subjectIntentionObj.checked['37'] == 1 ? 'selected' : ''"
                    @click="changeSubject(37)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>农林工程类</p>
                </div>
                <div
                    class="option"
                    data-value="林业工程类"
                    data-index="38"
                    :class="subjectIntentionObj.checked['38'] == 1 ? 'selected' : ''"
                    @click="changeSubject(38)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>林业工程类</p>
                </div>
                <div
                    class="option"
                    data-value="环境科学与工程类"
                    data-index="36"
                    :class="subjectIntentionObj.checked['36'] == 1 ? 'selected' : ''"
                    @click="changeSubject(36)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>环境科学与工程类</p>
                </div>
                <div
                    class="option"
                    data-value="食品科学与工程类"
                    data-index="35"
                    :class="subjectIntentionObj.checked['35'] == 1 ? 'selected' : ''"
                    @click="changeSubject(35)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>食品科学与工程类</p>
                </div>
                <div
                    class="option"
                    data-value="自然保护与环境生态类"
                    data-index="39"
                    :class="subjectIntentionObj.checked['39'] == 1 ? 'selected' : ''"
                    @click="changeSubject(39)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>自然保护与环境生态类</p>
                </div>
              </div>
            </div>
            <div class="subjectItem">
              <div class="item_title clearfix">
                <div class="title">文科领域</div>
                <div class="contacts">
                  <input
                      v-model="subjectIntentionObj.connect[6].connect"
                      class="name"
                      name="name"
                      data-type="6"
                      placeholder="请输入联系人"
                      autocomplete="off"
                  >
                  <input
                      v-model="subjectIntentionObj.connect[6].phone"
                      class="phone"
                      name="phone"
                      data-type="6"
                      placeholder="请输入联系电话"
                      autocomplete="off"
                  >
                </div>
              </div>
              <hr>
              <div class="item_options clearfix">
                <div
                    class="option"
                    data-value="经管类"
                    data-index="44"
                    :class="subjectIntentionObj.checked['44'] == 1 ? 'selected' : ''"
                    @click="changeSubject(44)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>经管类</p>
                </div>
                <div
                    class="option"
                    data-value="金融类"
                    data-index="60"
                    :class="'60' in subjectIntentionObj.checked && subjectIntentionObj.checked['60'] === 1 ? 'selected' : ''"
                    @click="changeSubject(60)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>金融类</p>
                </div>
                <div
                    class="option"
                    data-value="法学类"
                    data-index="45"
                    :class="subjectIntentionObj.checked['45'] == 1 ? 'selected' : ''"
                    @click="changeSubject(45)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>法学类</p>
                </div>
                <div
                    class="option"
                    data-value="教育学类"
                    data-index="46"
                    :class="subjectIntentionObj.checked['46'] == 1 ? 'selected' : ''"
                    @click="changeSubject(46)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>教育学类</p>
                </div>
                <div
                    class="option"
                    data-value="体育学类"
                    data-index="47"
                    :class="subjectIntentionObj.checked['47'] == 1 ? 'selected' : ''"
                    @click="changeSubject(47)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>体育学类</p>
                </div>
                <div
                    class="option"
                    data-value="外国语言文学类"
                    data-index="61"
                    :class="'61' in subjectIntentionObj.checked && subjectIntentionObj.checked['61'] === 1 ? 'selected' : ''"
                    @click="changeSubject(61)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>外国语言文学类</p>
                </div>
                <div
                    class="option"
                    data-value="中国语言文学类"
                    data-index="62"
                    :class="'62' in subjectIntentionObj.checked && subjectIntentionObj.checked['62'] === 1 ? 'selected' : ''"
                    @click="changeSubject(62)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>中国语言文学类</p>
                </div>
                <div
                    class="option"
                    data-value="新闻传播学类"
                    data-index="48"
                    :class="subjectIntentionObj.checked['48'] == 1 ? 'selected' : ''"
                    @click="changeSubject(48)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>新闻传播学类</p>
                </div>
                <div
                    class="option"
                    data-value="历史学类"
                    data-index="49"
                    :class="subjectIntentionObj.checked['49'] == 1 ? 'selected' : ''"
                    @click="changeSubject(49)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>历史学类</p>
                </div>
                <div
                    class="option"
                    data-value="马克思主义理论类"
                    data-index="63"
                    :class="'63' in subjectIntentionObj.checked && subjectIntentionObj.checked['63'] === 1 ? 'selected' : ''"
                    @click="changeSubject(63)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>马克思主义理论类</p>
                </div>
                <div
                    class="option"
                    data-value="艺术学类"
                    data-index="50"
                    :class="subjectIntentionObj.checked['50'] == 1 ? 'selected' : ''"
                    @click="changeSubject(50)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>艺术学类</p>
                </div>
              </div>
            </div>
            <div class="subjectItem">
              <div class="item_title clearfix">
                <div class="title">生物领域</div>
                <div class="contacts">
                  <input
                    v-model="subjectIntentionObj.connect[5].connect"
                    class="name"
                    name="name"
                    data-type="5"
                    placeholder="请输入联系人"
                    autocomplete="off"
                  >
                  <input
                    v-model="subjectIntentionObj.connect[5].phone"
                    class="phone"
                    name="phone"
                    data-type="5"
                    placeholder="请输入联系电话"
                    autocomplete="off"
                  >
                </div>
              </div>
              <hr>
              <div class="item_options clearfix">
                <div
                  class="option"
                  data-value="生物医学工程类"
                  data-index="40"
                  :class="subjectIntentionObj.checked['40'] == 1 ? 'selected' : ''"
                  @click="changeSubject(40)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>生物医学工程类</p>
                </div>
                <div
                  class="option"
                  data-value="生物工程类"
                  data-index="41"
                  :class="subjectIntentionObj.checked['41'] == 1 ? 'selected' : ''"
                  @click="changeSubject(41)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>生物工程类</p>
                </div>
                <div
                  class="option"
                  data-value="植物类"
                  data-index="42"
                  :class="subjectIntentionObj.checked['42'] == 1 ? 'selected' : ''"
                  @click="changeSubject(42)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>植物类</p>
                </div>
                <div
                  class="option"
                  data-value="动物类"
                  data-index="43"
                  :class="subjectIntentionObj.checked['43'] == 1 ? 'selected' : ''"
                  @click="changeSubject(43)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>动物类</p>
                </div>
                <div
                    class="option"
                    data-value="生物科学类"
                    data-index="64"
                    :class="'64' in subjectIntentionObj.checked && subjectIntentionObj.checked['64'] === 1 ? 'selected' : ''"
                    @click="changeSubject(64)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>生物科学类</p>
                </div>
              </div>
            </div>
          </div>
          <div class="form_btns">
            <a href="javascript:;" class="subBtn" id="subForm_submit" @click="subjectFormShow = { visibility: 'hidden'}">保存</a>
<!--            <a-->
<!--              href="javascript:;"-->
<!--              class="closeBtn"-->
<!--              id="subForm_close"-->
<!--              @click="subjectFormShow = {visibility: 'hidden'}"-->
<!--            >关闭</a>-->
<!--            <a-->
<!--              href="javascript:;"-->
<!--              class="subBtn"-->
<!--              id="subForm_submit"-->
<!--              @click="this.subjectFormShow = { visibility: 'hidden' }"-->
<!--            >提交</a>-->
          </div>
        </div>
      </div>
    </div>

    <div
        class="form_overlay"
        id="subjectForm_overlay"
        :style="subjectForm2Show"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">
          <p>高职学科领域意向</p>
        </div>
        <div class="form_content">
          <div class="subjects">
            <div class="subjectItem">
              <div class="item_title clearfix">
                <div class="title">参与专业大类领域</div>
                <div class="contacts">
                  <input
                      v-model="subjectIntention2Obj.connect[0].connect"
                      class="name"
                      name="name"
                      data-type="0"
                      placeholder="请输入联系人"
                      autocomplete="off"
                  >
                  <input
                      v-model="subjectIntention2Obj.connect[0].phone"
                      class="phone"
                      name="phone"
                      data-type="0"
                      placeholder="请输入联系电话"
                      autocomplete="off"
                  >
                </div>
              </div>
              <hr>
              <div class="item_options clearfix">
                <div
                    class="option"
                    data-value="电子与信息大类"
                    data-index="0"
                    :class="subjectIntention2Obj.checked['0'] == 1 ? 'selected' : ''"
                    @click="changeSubject2(0)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>电子与信息大类</p>
                </div>
                <div
                    class="option"
                    data-value="财经商贸大类"
                    data-index="1"
                    :class="subjectIntention2Obj.checked['1'] == 1 ? 'selected' : ''"
                    @click="changeSubject2(1)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>财经商贸大类</p>
                </div>
                <div
                    class="option"
                    data-value="医药卫生大类"
                    data-index="2"
                    :class="subjectIntention2Obj.checked['2'] == 1 ? 'selected' : ''"
                    @click="changeSubject2(2)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>医药卫生大类</p>
                </div>
                <div
                    class="option"
                    data-value="公共管理与服务大类"
                    data-index="3"
                    :class="subjectIntention2Obj.checked['3'] == 1 ? 'selected' : ''"
                    @click="changeSubject2(3)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>公共管理与服务大类</p>
                </div>
                <div
                    class="option"
                    data-value="交通运输大类"
                    data-index="4"
                    :class="subjectIntention2Obj.checked['4'] == 1 ? 'selected' : ''"
                    @click="changeSubject2(4)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>交通运输大类</p>
                </div>
                <div
                    class="option"
                    data-value="生物与化工大类"
                    data-index="5"
                    :class="subjectIntention2Obj.checked['5'] == 1 ? 'selected' : ''"
                    @click="changeSubject2(5)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>生物与化工大类</p>
                </div>
                <div
                    class="option"
                    data-value="农林牧渔大类"
                    data-index="6"
                    :class="subjectIntention2Obj.checked['6'] == 1 ? 'selected' : ''"
                    @click="changeSubject2(6)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>农林牧渔大类</p>
                </div>
                <div
                    class="option"
                    data-value="能源动力与材料大类"
                    data-index="7"
                    :class="subjectIntention2Obj.checked['7'] == 1 ? 'selected' : ''"
                    @click="changeSubject2(7)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>能源动力与材料大类</p>
                </div>
                <div
                    class="option"
                    data-value="装备制造大类"
                    data-index="8"
                    :class="subjectIntention2Obj.checked['8'] == 1 ? 'selected' : ''"
                    @click="changeSubject2(8)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>装备制造大类</p>
                </div>
                <div
                    class="option"
                    data-value="资源环境与安全类"
                    data-index="9"
                    :class="subjectIntention2Obj.checked['9'] == 1 ? 'selected' : ''"
                    @click="changeSubject2(9)"
                >
                  <p class="checkbox">
                    <span></span>
                  </p>
                  <p>资源环境与安全类</p>
                </div>
              </div>
            </div>
          </div>
          <div class="form_btns">
            <a href="javascript:;" class="subBtn" id="subForm_submit" @click="subjectForm2Show = { visibility: 'hidden'}">保存</a>
<!--            <a-->
<!--                href="javascript:;"-->
<!--                class="closeBtn"-->
<!--                id="subForm_close"-->
<!--                @click="subjectForm2Show = {visibility: 'hidden'}"-->
<!--            >关闭</a>-->
<!--            <a-->
<!--                href="javascript:;"-->
<!--                class="subBtn"-->
<!--                id="subForm_submit"-->
<!--                @click="this.subjectForm2Show = { visibility: 'hidden' }"-->
<!--            >提交</a>-->
          </div>
        </div>
      </div>
    </div>

    <div
      id="dialog_overlay"
      class=""
      :style="classShowImageWindow"
    >
      <div id="dialog_bg"></div>
      <div
        id="dialog_panel"
        style="margin-left: -137px; margin-top: -84px;"
        class=""
      >
        <div id="dialog_panel_bg"></div>
        <div id="dialog_title">选择图片</div>
        <div id="dialog_content">
          <div
            class="imgType"
            style="margin-bottom:10px;"
            id="imgType"
          >允许上传的图片格式：jpg</div>
          <div
            id="thumbPreview"
            v-show="showImgPreview"
          ><img ref="headImg"></div>
          <div>
            <!--            <input id="imageFile" type="file" name="imageFile" accept="image/*" ref="imageFile" @change="imageFileChange">-->
            <input
              id="imageFile"
              type="file"
              name="imageFile"
              ref="imageFile"
              accept="image/*"
              @change="imageFileChange"
            >
          </div>
        </div>
        <div id="dialog_control">
          <a
            href="javascript:void(0)"
            @click="hideImageWindow"
            id="dialog_close"
            class="button"
            style="display: inline-block;"
          >取消</a>
          <a
            href="javascript:void(0)"
            @click="confirmImage"
            id="dialog_ok"
            class="button"
            style="display: inline-block;"
          >确认</a>
        </div>
      </div>
    </div>

    <div
      id="file-upload-overlay"
      :style="bigFileWindowShow"
    >
      <div class="file-upload-bg"></div>
      <div class="file-upload">
        <div class="file-upload-type">允许上传的文件扩展名：pdf,doc,docx,xsl,xlsx,ppt,pptx</div>
        <div
          class="file-upload-close"
          @click="hideBigFileWindow"
        >×</div>
        <span
          class="file_uploadBtn"
          id="file_uploadBtn"
          style="display: block;"
        >
          选择文件<input
            type="file"
            id="file_fileInput"
            ref="docFile"
            style="display: block;"
            @change="fileChange"
          >
        </span>
        <div
          class="fileBox"
          id="file_uploadBox"
          style="display: block;"
        >
          <span
            class="pauseBtn"
            id="file_pauseBtn"
            style="display: none;"
          >暂停</span>
          <span
            class="goBtn"
            id="file_goBtn"
            style="display:none;"
          >继续</span>
          <div
            class="filename"
            id="file_filename"
            style="display: none;"
          ></div>
          <div
            class="pbar"
            :style="windowConfig.showProgress ? 'display: block' : 'display: none'"
          >
            <div
              class="progress"
              id="file_progress"
              :style="'width: ' + Math.ceil(windowConfig.progressValue * 4.2) + 'px;'"
            ></div>
            <div id="file_percent">{{ windowConfig.progressValue }}%</div>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
    <!-- 驳回原因弹窗 -->
    <div
      class="form_overlay"
      id="applyReject_overlay"
      :style="classApplyRejectShow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">驳回原因
          <a
            href="javascript:;"
            class="close"
            @click="classApplyRejectShow={}"
          >×</a>
        </div>
        <div class="form_content">
          <form id="applyRejectForm">
            <div class="item">
              <p>{{briefInfo.show_rejectReason}}</p>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a
            href="javascript:;"
            class="button"
            id="applyReject_submit"
            @click="updateStatus"
            style="background:#a30014;border-color:#a30014"
          >重新编辑企业简介</a>
        </div>
      </div>
    </div>
    <!-- 编辑企业简介 -->
    <div
      class="form_overlay"
      id="form_overlay"
      :style="companyShowAddWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">编辑简介</div>
        <div class="form_content">
          <form id="itemForm">
            <div class="item">
              <span class="label">企业名称:</span>
              <input
                type="text"
                name="title"
                v-model="briefInfo.show_title"
              >
            </div>
            <div class="item">
              <span class="label">公司地址:</span>
              <input
                type="text"
                name="en_title"
                v-model="briefInfo.show_address"
              >
            </div>
            <div class="item">
              <span class="label">联系人:</span>
              <input
                type="text"
                name="contacts"
                v-model="briefInfo.show_mainPrincipal"
              >
            </div>
            <div class="item">
              <span class="label">手机号码:</span>
              <input
                type="text"
                name="phone"
                v-model="briefInfo.show_mobile1"
              >
            </div>
            <div class="item">
              <span class="label">邮箱:</span>
              <input
                type="text"
                name="code"
                v-model="briefInfo.show_email1"
              >
            </div>
            <div class="item brief">
              <span class="label">企业简介:</span>
              <textarea
                v-model="briefInfo.show_brief"
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="不超过300字"
                maxlength="300"
              ></textarea>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a
            href="javascript:;"
            class="button close"
            id="form_close"
            @click="hideAddWindow"
          >取消</a>
          <a
            href="javascript:;"
            class="button"
            id="form_submit"
            @click="saveBriefInfo"
          >提交</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Apply",
  components: {},
  data() {
    return {
      changStatus:'1',
      classApplyRejectShow: { visibility: 'hidden' },
      isDetail: false, // 申请页面 false 其他true
      pageMode: 0, // 1 未申请 2 未审核 3 已经审核 4 驳回
      showCategory: 3,
      editorConfig: {

        // 访问 UEditor 静态资源的根路径，可参考 https://hc199421.gitee.io/vue-ueditor-wrap/#/faq
        UEDITOR_HOME_URL: '/UE/',
        // 服务端接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
        // serverUrl: '//ueditor.szcloudplus.com/cos',
        serverUrl: this.$store.state.uEditorServerUrl,
        autoHeight: true,
        // imagePathFormat: '/files/{yyyy}{mm}{dd}-{time}{rand:6}',
        // imageUrlPrefix: 'http://localhost:8000'
        initialFrameWidth: '100%',

        initialFrameHeight: 200
      },
      companyShowAddWindow:{
        visibility:"hidden"
      },
      subjectFormShow: {
        visibility: 'hidden'
      },
      subjectForm2Show: {
        visibility: 'hidden'
      },
      fileFormShow: {
        visibility: 'hidden'
      },
      classShowImageWindow: {
        visibility: 'hidden'
      },
      bigFileWindowShow: {
        display: 'none'
      },
      uploaded_img: '',
      showImgPreview: false,
      windowConfig: {
        showIndex: 'logo', // 'cer', 'pic', 'logo'
        showPreview: {
          preview1: false,
          preview7: false,
          preview8: false,
        },
        fileIndex: '',
        showProgress: false,
        progressValue: 0,
        orgFile: [],
        leaderFile: []
      },
      formInfo1: {
        type: 1,
        title: '',
        address: '',
        postcode: '',
        mainPrincipal: '',
        mpjobTitle: '',
        phone1: '',
        mobile1: '',
        email1: '',
        respDepart: '',
        principal: '',
        pjobTitle: '',
        mobile2: '',
        email3: '',
        contacts: '',
        phone2: '',
        email2: '',
        logo: '',
        ownerId: this.$store.state.userInfo.user_id,
        username: this.$store.state.userInfo.user_name,
        name: this.$store.state.userInfo.name,
        rejectReason: ''
      },
      formInfo2: {
        type: 2,
        title: '',
        legalPerson: '',
        foundDate: '',
        nature: '',
        registeredCapital: '',
        address: '',
        postcode: '',
        mainPrincipal: '',
        mpjobTitle: '',
        phone1: '',
        mobile1: '',
        email1: '',
        respDepart: '',
        principal: '',
        pjobTitle: '',
        mobile2: '',
        email3: '',
        contacts: '',
        phone2: '',
        email2: '',
        logo: '',
        credentialsUrl: '',
        introduction: '',
        resume: '',
        brief: '',
        ownerId: this.$store.state.userInfo.user_id,
        username: this.$store.state.userInfo.user_name,
        name: this.$store.state.userInfo.name,
        rejectReason: ''
      },
      formInfo3: {
        type: 3,
        schoolId: 0,
        schoolTitle: '',
        title: '',
        address: '',
        postcode: '',
        mainPrincipal: '',
        mpjobTitle: '',
        phone1: '',
        mobile1: '',
        email1: '',
        respDepart: '',
        principal: '',
        pjobTitle: '',
        mobile2: '',
        email3: '',
        contacts: '',
        phone2: '',
        email2: '',
        logo: '',
        ownerId: this.$store.state.userInfo.user_id,
        username: this.$store.state.userInfo.user_name,
        name: this.$store.state.userInfo.name,
        rejectReason: ''
      },
      briefInfo:{
        show_title:'',
        show_address:'',
        show_mainPrincipal:'',
        show_email1:'',
        show_mobile1:'',
        show_brief:'',
        show_rejectReason:'',
        show_status:''//信息状态字典：0未申请 1待审核 2驳回 3待发布 4已发布
      },
      downLoadName: '',
      downLoadUrl: 'javascript:void(0);',
      subjectIntentionObj: {
        connect: [
          {'title': '基础类', 'connect': '', 'phone': ''},
          {'title': '土建类', 'connect': '', 'phone': ''},
          {'title': '信息类', 'connect': '', 'phone': ''},
          {'title': '机械类', 'connect': '', 'phone': ''},
          {'title': '化环类', 'connect': '', 'phone': ''},
          {'title': '生物类', 'connect': '', 'phone': ''},
          {'title': '文科类', 'connect': '', 'phone': ''},
          {'title': '医学类', 'connect': '', 'phone': ''},
        ],
        checked: {
          '0': 0,
          '1': 0,
          '2': 0,
          '3': 0,
          '4': 0,
          '5': 0,
          '6': 0,
          '7': 0,
          '8': 0,
          '9': 0,
          '10': 0,
          '11': 0,
          '12': 0,
          '13': 0,
          '14': 0,
          '15': 0,
          '16': 0,
          '17': 0,
          '18': 0,
          '19': 0,
          '20': 0,
          '21': 0,
          '22': 0,
          '23': 0,
          '24': 0,
          '25': 0,
          '26': 0,
          '27': 0,
          '28': 0,
          '29': 0,
          '30': 0,
          '31': 0,
          '32': 0,
          '33': 0,
          '34': 0,
          '35': 0,
          '36': 0,
          '37': 0,
          '38': 0,
          '39': 0,
          '40': 0,
          '41': 0,
          '42': 0,
          '43': 0,
          '44': 0,
          '45': 0,
          '46': 0,
          '47': 0,
          '48': 0,
          '49': 0,
          '50': 0,
          '51': 0,
          '52': 0,
          '53': 0,
          '54': 0,
          '55': 0,
          '56': 0,
          '57': 0,
          '58': 0,
          '59': 0,
          '60': 0,
          '61': 0,
          '62': 0,
          '63': 0,
          '64': 0
        }
      },
      subjectIntention2Obj: {
        connect: [
          {'title': '参与专业大类领域', 'connect': '', 'phone': ''},
        ],
        checked: {
          '0': 0,
          '1': 0,
          '2': 0,
          '3': 0,
          '4': 0,
          '5': 0,
          '6': 0,
          '7': 0,
          '8': 0,
          '9': 0
        }
      }
    }
  },

  computed: {

    loginUserInfo() {
      return this.$store.state.userInfo
    },
    processBarClass(){
      if(this.pageMode == 2){
        return 'ongoing'
      }else if(this.pageMode == 3){
        return 'end'
      }else if(this.pageMode == 4){
        return 'reject'
      }else{
        return ''
      }
    },
  },
  methods: {
    async updateStatus() {
      this.changStatus=2
      this.classApplyRejectShow={
        visibility: 'hidden'
      }
      this.briefInfo={
        show_title:'',
        show_address:'',
        show_mainPrincipal:'',
        show_email1:'',
        show_mobile1:'',
        show_brief:'',
        show_rejectReason:'',
        show_status:'0'
      }
    },
    showReject(){
      this.classApplyRejectShow = {
        visibility: 'visible'
      }
    },
    async saveBriefInfo(){
      this.briefInfo.show_status='1'
      let mobileReg = /^1[0-9]{10}$/;
      // let emailReg = /^\w+@\w+(\.\w+)+$/;
      // if (!emailReg.test(this.briefInfo.show_email1)) {
      //   this.$parent.showMsg('请正确的邮箱！')
      //   return false;
      // }
      if (!mobileReg.test(this.briefInfo.show_mobile1.trim())) {
        this.$parent.showMsg('请正确的手机号码！')
        return false;
      }
      let url = '/application/' + this.formInfo2.id + '/'
      await this.$http.put(url,this.briefInfo).then(() => {
         this.changStatus=3
      }).catch((error) => {
        console.log(error)
        this.$parent.showMsg('数据获取失败！');
      })

      this.hideAddWindow()
    },
    hideAddWindow(){
      this.companyShowAddWindow={
        visibility: 'hidden'
      }
    },
    showCompanyWidow(){
      this.companyShowAddWindow={
        visibility: 'visible'
      }
    },
    changeSubject(index){
      console.log(123123)
      debugger
      if ('' + index in this.subjectIntentionObj.checked) {
        this.$set(this.subjectIntentionObj.checked, '' + index, 1 - this.subjectIntentionObj.checked['' + index]);
      } else {
        this.$set(this.subjectIntentionObj.checked, '' + index, 1);
      }
    },
    changeSubject2(index){
      if ('' + index in this.subjectIntention2Obj.checked) {
        this.$set(this.subjectIntention2Obj.checked, '' + index, 1 - this.subjectIntention2Obj.checked['' + index]);
      } else {
        this.$set(this.subjectIntention2Obj.checked, '' + index, 1);
      }
    },
    changeCategory(value) {
      if (this.pageMode != 1) {
        return
      }
      this.showCategory = value
    },
    formatImgPath(src) {
      return this.$store.state.uploadFileRootUrl + src
    },
    formatFileName(name) {
      let dotIndex = name.lastIndexOf(".")
      let fileName = dotIndex > 10 ? name.substr(0, 10) + '...' + name.substr(dotIndex) : name
      return fileName
    },

    // 大窗体显示逻辑
    showWindowCommon(code) {
      this.fileFormShow = {visibility: 'visible'}
      this.windowConfig.showIndex = code
      // this.windowConfig.showPreview = {
      //   preview1: false,
      //   preview7: false,
      //   preview8: false,
      // }
    },
    showWindow1() {
      console.log(this.$store.state.userInfo.user_id)
      if (!this.$store.state.userInfo.user_id) {
        this.$parent.showMsg('请先登陆！')
        return;
      }
      this.showWindowCommon('logo')
    },
    showWindow2() {
      if (!this.$store.state.userInfo.user_id) {
        this.$parent.showMsg('请先登陆！')
        return;
      }
      this.showWindowCommon('cer')
    },
    showWindow3() {
      if (!this.$store.state.userInfo.user_id) {
        this.$parent.showMsg('请先登陆！')
        return;
      }
      this.showWindowCommon('pic')
    },
    showWindow4() {
      if (!this.$store.state.userInfo.user_id) {
        this.$parent.showMsg('请先登陆！')
        return;
      }
      this.showWindowCommon('logo')
    },
    hideWindow() {
      this.fileFormShow = {visibility: 'hidden'}
    },

    // 上传小窗
    showImageWindow() {
      this.classShowImageWindow = {
        visibility: 'visible'
      }
    },
    hideImageWindow() {
      this.classShowImageWindow = {}
      this.uploaded_img = ''
      this.showImgPreview = false
      this.$refs.headImg.src = ''
      this.$refs.imageFile.value = ''
    },
    async imageFileChange() {
      this.showImgPreview = false
      const uploadUrl = '/files/' + this.$refs.imageFile.files[0].name
      await this.$http.put(uploadUrl, this.$refs.imageFile.files[0], {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((data) => {
        this.uploaded_img = data
        this.showImgPreview = true
        this.$refs.headImg.src = this.$store.state.uploadFileRootUrl + data
      }).catch(() => {
        this.$parent.showMsg('上传失败！')
      })
    },
    confirmImage() {
      if (this.showImgPreview === false) {
        this.$layer.msg('图片上传中，请稍候！')
        return false
      }
      if (this.windowConfig.showIndex == 'logo') {
        this.formInfo1.logo = this.uploaded_img
        this.formInfo2.logo = this.uploaded_img
        this.formInfo3.logo = this.uploaded_img
        this.windowConfig.showPreview.preview8 = true
        this.hideImageWindow()
      } else if (this.windowConfig.showIndex == 'cer') {
        this.formInfo2.credentialsUrl = this.uploaded_img
        this.windowConfig.showPreview.preview1 = true
        this.hideImageWindow()
      }
    },

    // 文档上传
    showFileWindow1() {
      this.bigFileWindowShow = {
        display: 'block'
      }
      this.windowConfig.fileIndex = '1'
    },
    showFileWindow2() {
      this.bigFileWindowShow = {
        display: 'block'
      }
      this.windowConfig.fileIndex = '2'
    },
    hideBigFileWindow() {
      this.bigFileWindowShow = {
        display: 'none'
      }
      this.$refs.docFile.value = ''
    },
    async fileChange() {
      this.windowConfig.showProgress = true
      const fileRealName = this.$refs.docFile.files[0].name
      const uploadUrl = '/files/' + fileRealName
      await this.$http.put(uploadUrl, this.$refs.docFile.files[0], {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (e) => {
          this.windowConfig.progressValue = Math.ceil(e.loaded / e.total * 100)
        }
      }).then((data) => {

        this.windowConfig.showProgress = false
        this.windowConfig.progressValue = 0
        this.hideBigFileWindow()

        if (this.windowConfig.fileIndex == '1') {
          this.windowConfig.orgFile.push({
            fileName: fileRealName,
            filePath: data
          })
          this.formInfo2.introduction = JSON.stringify(this.windowConfig.orgFile)
        } else if (this.windowConfig.fileIndex == '2') {
          this.windowConfig.leaderFile.push({
            fileName: fileRealName,
            filePath: data
          })
          this.formInfo2.resume = JSON.stringify(this.windowConfig.leaderFile)
        }
      }).catch(() => {
        this.windowConfig.showProgress = false
        this.windowConfig.progressValue = 0
        this.hideBigFileWindow()
        this.$parent.showMsg('上传失败！')
      })
    },
    async checkOwner() {
      console.log(this.$store.state.userInfo.user_id)
      if (!this.$store.state.userInfo.user_id) {
        this.$parent.showMsg('请先登陆！')
        return;
      }

      await this.$http.get('/application/', {
        params: {
          page: 1,
          size: 1,
          ownerId: this.loginUserInfo.user_id,
          isdel: 0
        }
      }).then((data) => {
        if (data.results.length > 0) {
          this.$parent.showMsg('已提交过申请！');
        } else {
          this.saveInfo()
        }
      }).catch((error) => {
        console.log(error)
        this.$parent.showMsg('数据获取失败！');
      })
    },
    async saveInfo() {
      console.log(this.$store.state.userInfo.user_id)
      if (!this.$store.state.userInfo.user_id) {
        this.$parent.showMsg('请先登陆！')
        return;
      }

      let submitForm = {}

      if (this.showCategory == 1) {
        submitForm = this.formInfo1
      } else if (this.showCategory == 2) {
        submitForm = this.formInfo2
      } else if (this.showCategory == 3) {
        submitForm = this.formInfo3
      }

      for (let key in submitForm) {
        if (key == 'schoolId' || key == 'username' || key == 'name' || key == 'rejectReason') {
          continue
        }
        if (key == 'logo' && this.showCategory == 3){
          continue
        }

        // if (key.indexOf('email') >= 0){
        //   let emailReg = /^\w+@\w+(\.\w+)+$/
        //   if (!emailReg.test(submitForm[key])) {
        //     this.$parent.showMsg('请正确的邮箱！')
        //     return false;
        //   }
        // }

        if (key.indexOf('mobile') >= 0){
          let mobileReg = /^1[0-9]{10}$/;
          if (!mobileReg.test(submitForm[key].trim())) {
            this.$parent.showMsg('请正确的手机号码！')
            return false;
          }
        }

        if (!submitForm[key] || submitForm[key] == '') {
          console.log(key)
          this.$parent.showMsg('请将申请表填写完整！')
          return;
        }
      }

      let has_title = true

      await this.$http.get('/application/', {
        params: {
          eq_title: submitForm.title,
          isdel: 0
        }
      }).then(async (data) => {
        if (data.count > 0) {
          this.formInfo3.schoolId = data.results[0].id
        }else{
          has_title = false
        }
      })

      if(has_title && this.showCategory != 3){
        this.$parent.showMsg('【' + submitForm.title + '】已存在！')
        return false;
      }

      submitForm.subjectIntention = JSON.stringify(this.subjectIntentionObj)
      submitForm.subjectIntention2 = JSON.stringify(this.subjectIntention2Obj)

      if (this.showCategory == 1 || this.showCategory == 2) {

        const maxSortNum = await this.$http.get('/max_sortnum/')
        const postData = JSON.parse(JSON.stringify(submitForm))
        postData['super_category1_sort_num'] = maxSortNum['max_sn_1'] + 1
        postData['super_category2_sort_num'] = maxSortNum['max_sn_2'] + 1
        postData['super_category3_sort_num'] = maxSortNum['max_sn_3'] + 1

        await this.$http.post('/application/', postData).then((data) => {
          if (data.id) {
            this.$parent.showMsg('提交成功！', ()=>{
              window.location.reload()
            })
            // window.location.reload()
          } else {
            this.$parent.showMsg('提交失败！')
          }
        }).catch((error) => {
          console.log(error)
          this.$parent.showMsg('提交失败！')
        })
      }

      if (this.showCategory == 3) {

        this.formInfo3.schoolId = 0

        await this.$http.get('/application/', {
          params: {
            eq_title: this.formInfo3.schoolTitle,
            type: '1',
            isdel: 0
          }
        }).then(async (data) => {
          if (data.count > 0) {
            this.formInfo3.schoolId = data.results[0].id
          }
        })

        if (!this.formInfo3.schoolId && !this.formInfo3.logo){
          this.$parent.showMsg('请上传学校logo后再提交！', ()=>{
            this.showWindowCommon('logo')
          })
          return false;
        }

        if (!this.formInfo3.schoolId) {
          let schoolForm = JSON.parse(JSON.stringify(this.formInfo3))
          schoolForm.title = this.formInfo3.schoolTitle
          schoolForm.type = 1
          schoolForm.schoolTitle = ''
          delete schoolForm.schoolId

          const maxSortNum = await this.$http.get('/max_sortnum/')
          const postData = JSON.parse(JSON.stringify(schoolForm))
          postData['super_category1_sort_num'] = maxSortNum['max_sn_1'] + 1
          postData['super_category2_sort_num'] = maxSortNum['max_sn_2'] + 1
          postData['super_category3_sort_num'] = maxSortNum['max_sn_3'] + 1

          await this.$http.post('/application/', postData).then(async (data) => {
            if (data.id) {
              this.formInfo3.schoolId = data.id
            } else {
              this.$parent.showMsg('学校提交失败！')
            }
          }).catch((error) => {
            console.log(error)
            this.$parent.showMsg('学校提交失败！')
          })
        }

        const maxSortNum = await this.$http.get('/max_sortnum/')
        const postData = JSON.parse(JSON.stringify(this.formInfo3))
        postData['super_category1_sort_num'] = maxSortNum['max_sn_1'] + 1
        postData['super_category2_sort_num'] = maxSortNum['max_sn_2'] + 1
        postData['super_category3_sort_num'] = maxSortNum['max_sn_3'] + 1

        await this.$http.post('/application/', postData).then((data) => {
          if (data.id) {
            this.$parent.showMsg('学院提交成功！', ()=>{
              window.location.reload()
            })
            // window.location.reload()
          } else {
            this.$parent.showMsg('学院提交失败！')
          }
        }).catch((error) => {
          console.log(error)
          this.$parent.showMsg('学院提交失败！')
        })


      }

    },

    async getAppInfo() {
      if (!this.$store.state.userInfo.user_id) {
        this.pageMode = 1
        return;
      }

      await this.$http.get('/application/', {
        params: {
          page: 1,
          size: 10,
          ownerId: this.loginUserInfo.user_id
        }
      }).then((data) => {
        console.log('row.show_statu',data)
        if (data.results.length > 0) {
          this.isDetail = true
        }
        if (data.results.length > 0 && data.results[0].type == 1) {
          let row = data.results[0]
          this.showCategory = 1
          this.formInfo1 = {
            id: row.id,
            type: 1,
            title: row.title,
            address: row.address,
            postcode: row.postcode,
            mainPrincipal: row.mainPrincipal,
            mpjobTitle: row.mpjobTitle,
            phone1: row.phone1,
            mobile1: row.mobile1,
            email1: row.email1,
            respDepart: row.respDepart,
            principal: row.principal,
            pjobTitle: row.pjobTitle,
            mobile2: row.mobile2,
            email3: row.email3,
            contacts: row.contacts,
            phone2: row.phone2,
            email2: row.email2,
            logo: row.logo,
            ownerId: row.ownerId,
            username: row.username,
            name: row.name,
            created: row.created,
            rejectReason: row.rejectReason
          }
          if(data.results[0].status == 2){
            this.pageMode = 4
          }else{
            this.pageMode = data.results[0].status == 5 ? 3 : 2
          }
          if(data.results[0].subjectIntention){
            // this.subjectIntentionObj = JSON.parse(data.results[0].subjectIntention)
            const temp = JSON.parse(data.results[0].subjectIntention)
            this.subjectIntentionObj.connect = temp.connect
            for (const key in temp.checked) {
              this.subjectIntentionObj.checked[key] = temp.checked[key]
            }
          }
          if(data.results[0].subjectIntention2){
            this.subjectIntention2Obj = JSON.parse(data.results[0].subjectIntention2)
          }
          this.windowConfig.showPreview.preview8 = true
        } else if (data.results.length > 0 && data.results[0].type == 2) {
          let row = data.results[0]
          this.showCategory = 2
          this.formInfo2 = {
            id: row.id,
            type: 2,
            title: row.title,
            legalPerson: row.legalPerson,
            foundDate: row.foundDate,
            nature: row.nature,
            registeredCapital: row.registeredCapital,
            address: row.address,
            postcode: row.postcode,
            mainPrincipal: row.mainPrincipal,
            mpjobTitle: row.mpjobTitle,
            phone1: row.phone1,
            mobile1: row.mobile1,
            email1: row.email1,
            respDepart: row.respDepart,
            principal: row.principal,
            pjobTitle: row.pjobTitle,
            mobile2: row.mobile2,
            email3: row.email3,
            contacts: row.contacts,
            phone2: row.phone2,
            email2: row.email2,
            logo: row.logo,
            credentialsUrl: row.credentialsUrl,
            introduction: row.introduction,
            resume: row.resume,
            brief: row.brief,
            ownerId: row.ownerId,
            username: row.username,
            name: row.name,
            created: row.created,
            rejectReason: row.rejectReason
          }
          this.briefInfo={
            show_title:row.show_title,
            show_address:row.show_address,
            show_mainPrincipal:row.show_mainPrincipal,
            show_email1:row.show_email1,
            show_mobile1:row.show_mobile1,
            show_brief:row.show_brief,
            show_status:row.show_status,
            show_rejectReason:row.show_rejectReason
          }

          if(row.show_status==2){
            this.changStatus=1
          }else if(row.show_status!==2 && row.show_status!==0){
            this.changStatus=3
          }else if(row.show_status==0){
            this.changStatus=2
          }
          this.formInfo1.logo = row.logo
          if (this.formInfo2.introduction) {
            this.windowConfig.orgFile = JSON.parse(this.formInfo2.introduction)
          }
          if (this.formInfo2.resume) {
            this.windowConfig.leaderFile = JSON.parse(this.formInfo2.resume)
          }
          if(data.results[0].status == 2){
            this.pageMode = 4
          }else{
            this.pageMode = data.results[0].status == 5 ? 3 : 2
          }
          this.windowConfig.showPreview.preview1 = true
          this.windowConfig.showPreview.preview8 = true

          console.log(data.results[0].subjectIntention)
          console.log(data.results[0].subjectIntention2)
          if(data.results[0].subjectIntention){
            this.subjectIntentionObj = JSON.parse(data.results[0].subjectIntention)
            // const temp = JSON.parse(data.results[0].subjectIntention)
            // this.subjectIntentionObj.connect = temp.connect
            // for (const key in temp.checked) {
            //   this.subjectIntentionObj.checked[key] = temp.checked[key]
            // }
          }
          if(data.results[0].subjectIntention2){
            this.subjectIntention2Obj = JSON.parse(data.results[0].subjectIntention2)
          }
        } else if (data.results.length > 0 && data.results[0].type == 3) {
          let row = data.results[0]
          this.showCategory = 3
          this.formInfo3 = {
            id: row.id,
            type: 3,
            schoolId: row.schoolId,
            schoolTitle: row.schoolTitle,
            title: row.title,
            address: row.address,
            postcode: row.postcode,
            mainPrincipal: row.mainPrincipal,
            mpjobTitle: row.mpjobTitle,
            phone1: row.phone1,
            mobile1: row.mobile1,
            email1: row.email1,
            respDepart: row.respDepart,
            principal: row.principal,
            pjobTitle: row.pjobTitle,
            mobile2: row.mobile2,
            email3: row.email3,
            contacts: row.contacts,
            phone2: row.phone2,
            email2: row.email2,
            ownerId: row.ownerId,
            username: row.username,
            name: row.name,
            created: row.created,
            rejectReason: row.rejectReason
          }
          if(data.results[0].status == 2){
            this.pageMode = 4
          }else{
            this.pageMode = data.results[0].status == 5 ? 3 : 2
          }
          if(data.results[0].subjectIntention){
            // this.subjectIntentionObj = JSON.parse(data.results[0].subjectIntention)
            const temp = JSON.parse(data.results[0].subjectIntention)
            this.subjectIntentionObj.connect = temp.connect
            for (const key in temp.checked) {
              this.subjectIntentionObj.checked[key] = temp.checked[key]
            }
          }
          if(data.results[0].subjectIntention2){
            this.subjectIntention2Obj = JSON.parse(data.results[0].subjectIntention2)
          }
        } else {
          this.pageMode = 1

        }


      }).catch((error) => {
        console.log(error)
      })
    },
    changeToEdit () {
      this.isDetail = false
    },
    async editInfo(){
      console.log(this.$store.state.userInfo.user_id)
      if (!this.$store.state.userInfo.user_id) {
        this.$parent.showMsg('请先登陆！')
        return;
      }

      let submitForm = {}

      if (this.showCategory == 1) {
        submitForm = this.formInfo1
      } else if (this.showCategory == 2) {
        submitForm = this.formInfo2
      } else if (this.showCategory == 3) {
        submitForm = this.formInfo3
      }

      for (let key in submitForm) {
        if (key == 'schoolId' || key == 'username' || key == 'name' || key == 'rejectReason') {
          // 旧数据没有这些字段 跳过
          continue
        }
        if (key == 'logo' && this.showCategory == 3){
          continue
        }

        // if (key.indexOf('email') >= 0){
        //   let emailReg = /^\w+@\w+(\.\w+)+$/
        //   if (!emailReg.test(submitForm[key])) {
        //     this.$parent.showMsg('请正确的邮箱！')
        //     return false;
        //   }
        // }

        if (key.indexOf('mobile') >= 0){
          let mobileReg = /^1[0-9]{10}$/;
          if (!mobileReg.test(submitForm[key].trim())) {
            this.$parent.showMsg('请正确的手机号码！')
            return false;
          }
        }

        if (!submitForm[key] || submitForm[key] == '') {
          this.$parent.showMsg('请将申请表填写完整！')
          return;
        }
      }

      submitForm.subjectIntention = JSON.stringify(this.subjectIntentionObj)
      submitForm.subjectIntention2 = JSON.stringify(this.subjectIntention2Obj)

      if (this.showCategory == 1 || this.showCategory == 2) {
        let url = '/application/' + submitForm.id + '/'
        submitForm.status = 1
        await this.$http.put(url, submitForm).then((data) => {
          if (data.id) {
            this.$parent.showMsg('修改成功！', ()=>{
              window.location.reload()
            })
          } else {
            this.$parent.showMsg('修改失败！')
          }
        }).catch((error) => {
          console.log(error)
          this.$parent.showMsg('修改失败！')
        })
      } else if (this.showCategory == 3) {
        let schoolUrl = '/application/' + submitForm.schoolId + '/'

        let schoolForm = JSON.parse(JSON.stringify(this.formInfo3))
        schoolForm.title = this.formInfo3.schoolTitle
        schoolForm.type = 1
        schoolForm.schoolTitle = ''
        schoolForm.status = 1
        delete schoolForm.schoolId
        delete schoolForm.id

        await this.$http.put(schoolUrl, schoolForm).then(async (data) => {
          if (data.id > 0) {
            console.log('updated school title ')
          }
        })

        let url = '/application/' + this.formInfo3.id + '/'
        let subjectForm = JSON.parse(JSON.stringify(this.formInfo3))
        subjectForm.status = 1
        await this.$http.put(url, subjectForm).then((data) => {
          if (data.id) {
            this.$parent.showMsg('学院修改成功！', ()=>{
              window.location.reload()
            })
          } else {
            this.$parent.showMsg('学院修改失败！')
          }
        }).catch((error) => {
          console.log(error)
          this.$parent.showMsg('学院修改失败！')
        })

      }
    },

    async downloadPdf(){
      if(this.pageMode != 2){
        return false;
      }

      let app_id = 0
      if (this.showCategory == 1) {
        app_id = this.formInfo1.id
      } else if (this.showCategory == 2) {
        app_id = this.formInfo2.id
      } else if (this.showCategory == 3) {
        app_id = this.formInfo3.id
      }

      let url = '/app_print/' + app_id
      await this.$http.post(url, {
        app_id: app_id
      }).then((data) => {
        if (data.code == 200) {
          this.downLoadName = data.data
          this.downLoadUrl = '/static/print_file/' + data.data
          setTimeout(() => {
            this.$refs.downLoadBtn.click()
          }, 500)
        } else {
          this.$parent.showMsg('下载失败！')
        }
      }).catch((error) => {
        console.log(error)
        this.$parent.showMsg('下载失败！')
      })
    }
  },
  mounted() {
    this.getAppInfo()
  }
}
</script>

<style scoped>
@import "../../assets/css/form.css";
@import "../../assets/css/vip-apply.css";
@import "../../assets/css/chunkUpload.css";

.form_content .label {
  width: 120px;
  line-height: 20px;
}

#center {
  width: auto !important;
}

#subjectForm_overlay .form_panel {
  margin-top: auto;
}

/* .form_panel {
  top: 200px !important;
} */

#status {
  margin-left: 180px;
}

.step {
  text-align: left;
}

.step span.st1 {
  margin-left: 101px;
}
.step span.st2 {
  margin-left: 195px;
}
.step span.st3 {
  text-align: center;
  margin-left: 139px;
}
.step span.st4 {
  margin-left: 132px;
}
.brief textarea {
  padding: 10px;
  color: #333;
}
.subMenu {
  text-align: center;
  padding: 20px;
  font-size: 18px;
}
.subMenu a {
  color: #666;
  margin: 0 30px;
  position: relative;
  height: unset;
}
.subMenu a:not(:last-of-type):after {
  content: "-";
  position: absolute;
  right: -34px;
  color: #333;
}

.subMenu a.cur {
  color: #fff;
  background: #2045a9;
  border-radius: 35px;
  padding: 10px 30px;
  line-height: 30px;
}

.subMenu a.cur:hover {
  background: #233f8b;
}
</style>
