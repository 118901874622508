<template>
  <div>
    <form id="searchSection" onsubmit="return false;" style="margin-top: 30px;">
      <div class="choice" style="width:100%">
        <select
            name="status"
            class="s-select separate"
            v-model="queryInfo.is_show"
        >
          <option value="">首页列表显示</option>
          <option value="1">已显示</option>
          <option value="0">不显示</option>
        </select>
        <input
            type="text"
            name="title"
            class="s-input separate"
            placeholder="专委会名称"
            v-model="queryInfo.gao_name"
        >
        <input
            type="text"
            name="code"
            class="s-input separate"
            placeholder="姓名"
            v-model="queryInfo.username"
        >
        <input
            type="button"
            class="s-btn"
            value="搜索"
            @click="search"
        >

        <div class="add-btn" @click="showInfoWindow">新建</div>
        <div
            class="import-btn"
            id="batchUpload"
            @click="showUploadWindow"
        >导入</div>
      </div>
    </form>
    <div id="list">
      <table v-if="this.tableData.length > 0">
        <tbody>
        <tr class="l_header">
          <th class="itemStatus">序号</th>
          <th class="itemStatus">专委会名称</th>
          <th class="itemStatus">职位</th>
          <th class="itemStatus">单位名称</th>
          <th class="itemStatus">姓名</th>
          <th class="itemStatus">所在部门</th>
          <th class="itemStatus">职务</th>
          <th class="itemStatus">在首页显示</th>
          <th class="action" width="120">操作</th>
        </tr>
        <tr class="item" v-for="(item, index) in tableData" :key="item.id">
          <td>{{index + 1}}</td>
          <td class="itemStatus">{{item.gao_name}}</td>
          <td class="itemStatus">{{item.position}}</td>
          <td class="itemStatus">{{item.org_name}}</td>
          <td class="itemStatus">{{item.username}}</td>
          <td class="itemStatus">{{item.department}}</td>
          <td class="itemStatus">{{item.duty}}</td>
          <td class="itemStatus">
            <a
                v-if="item.is_show==1"
                class="nopublish"
                href="javascript:;"
                @click="askShow(item.id, 0)"
            >已显示</a>
            <a
                v-if="item.is_show==0"
                class="publish"
                href="javascript:;"
                @click="askShow(item.id, 1)"
            >显示</a>
          </td>
          <td class="action">
            <a class="edit" href="javascript:;" @click="querySingle(item.id)">编辑</a>
            <a class="delete" href="javascript:;" @click="askDel(item.id)">删除</a>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="noitems" v-if="tableData.length == 0">没有数据</div>

      <pagination :cur="pageInfo.page" :all="pageInfo.all" :totalData="pageInfo.totalData" :callback="pageCallback"></pagination>
    </div>
    <div class="form_overlay" id="form_overlay" :style="classShowInfoWindow">
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">内容简介</div>
        <div class="form_content">
          <form id="itemForm">
            <div class="item">
              <span class="label"><i class="require">*</i>委员会名称:</span>
              <input v-model="formInfo.gao_name" type="text" >
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>职位:</span>
              <input v-model="formInfo.position" type="text" >
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>工作单位:</span>
              <input v-model="formInfo.org_name" type="text" >
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>姓名:</span>
              <input v-model="formInfo.username" type="text" >
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>所在部门:</span>
              <input v-model="formInfo.department" type="text" >
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>职称/职务:</span>
              <input v-model="formInfo.duty" type="text" >
            </div>

          </form>
        </div>
        <div class="form_control">
          <a href="javascript:;" class="button close" id="form_close" @click="hideInfoWindow">取消</a>
          <a href="javascript:;" class="button" id="form_submit" @click="saveInfo">提交</a>
        </div>
      </div>
    </div>

    <div
        class="form_overlay"
        :style="classShowUploadWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">批量上传</div>
        <div class="form_content">
          <form id="uploadForm">
            <div class="item">
              <span
                  class="label"
                  style="width: auto"
              ><i class="require"></i>模版创建规则:</span>
              <div class="item-box divLabel gray">
                <div class="normal-width">
                  <p>1. 文件格式为Excel文件；</p>
                  <p>2. 表格头说明：
                    name	position	unit	username	department	duty	fixed
                    name（委员会名称）、position（职位）、unit（工作单位）、username（姓名）、department（所在部门）、duty（职称/职务）、fixed（是否在首页列表显示1为显示0为不显示）</p>
                </div>
              </div>
              <div class="clear"></div>
              <div class="attachment">
                <a
                    href="/static/upload_template/batch_upload_gaozhi_template.csv"
                    download="batch_upload_gaozhi_template.csv"
                    target="_blank"
                >模版下载</a>
              </div>
            </div>
            <div class="item">
              <span
                  class="label"
                  style="width: auto"
              ><i class="require"></i></span>
              <div
                  class="item-box"
                  style="margin-left: 130px;"
              >
                <input
                    name="file"
                    v-model="uploadedCsv"
                    disabled
                    type="text"
                    id="fileUrl"
                    style="width: 225px;"
                >
                <span
                    class="button"
                    style="position: relative;top: 0px; left: 0px;"
                >
                  选择文件
                  <input
                      @change="xlsChange"
                      type="file"
                      ref="xlsFile"
                      id="fileUpload"
                      class="upload-derict"
                  >
                </span>
              </div>
              <div class="clear"></div>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a
              href="javascript:;"
              class="button close"
              id="form_upload_close"
              @click="hideUploadWindow"
          >取消</a>
          <a
              href="javascript:;"
              class="button"
              id="form_upload_submit"
              @click="submitCsv"
          >提交</a>
        </div>
      </div>
    </div>

    <div
        id="dialog_overlay"
        :style="classShowProcessWindow"
    >
      <div id="dialog_bg"></div>
      <div
          id="dialog_panel"
          ref="refErrorList"
          style="margin-left: -136px; margin-top: -54px;max-width: 300px;"
      >
        <div id="dialog_panel_bg"></div>
        <div id="dialog_title">提示</div>
        <div id="dialog_content">
          ({{ curCsvIndex }} / {{ uploadedCscData.length }})
          <span style="color: #219d2b">
            {{ ' ' + csvFinishText }}
          </span>
        </div>
        <div id="dialog_content2">
          <ul>
            <li
                v-for="(item, index) in errorList"
                v-bind:key="index"
            >
              <span :class="item.code == 1 ? 'success' : 'fail'">{{ item.text }}</span>
            </li>
          </ul>

        </div>
        <div id="dialog_control">
          <a
              href="javascript:void(0)"
              id="dialog_ok"
              class="button col2"
              @click="classShowProcessWindow={}"
              :style="isFinishUpsert ? 'display: inline-block' : 'display:none'"
          >确认</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../../../components/Pagination";

export default {
  name: "AdminOrgGao",
  components: {
    Pagination
  },
  data() {
    return {
      queryInfo: {
        page: 1,
        size: 10,
        is_show: '',
        gao_name: '',
        username: '',
      },
      formInfo: {
        id: 0,
        gao_name:'',
        position:'',
        org_name:'',
        username:'',
        department:'',
        duty:''
      },
      tableData: [],
      tableDataCount: 0,
      showWindow: false,
      classShowUploadWindow: {},
      uploadedCsv: '',
      uploadedCscData: [],
      classShowProcessWindow: {},
      errorList: [],
      isFinishUpsert: false,
      curCsvIndex: 0,
      csvFinishText: ''
    }
  },
  computed: {
    classShowInfoWindow() {
      if (this.showWindow) {
        return {
          visibility: 'visible'
        }
      } else {
        return {}
      }
    },
    pageInfo() {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    }
  },
  methods: {
    async search() {
      await this.$http.get('/org_gao/', {
        params: this.queryInfo
      }).then((data) => {
        this.tableData = data.results
        this.tableDataCount = data.count

      }).catch(() => {
        this.$layer.msg('获取信息失败！')
      })
    },
    showInfoWindow() {
      this.showWindow = true
    },
    hideInfoWindow() {
      this.showWindow = false
      this.formInfo = {
        id: 0,
        gao_name:'',
        position:'',
        org_name:'',
        username:'',
        department:'',
        duty:''
      }
    },
    async saveInfo() {
      if(!this.formInfo.gao_name){
        this.$parent.$parent.$parent.showMsg("请输入委员会名称!")
        return false
      }
      if(!this.formInfo.position){
        this.$parent.$parent.$parent.showMsg("请输入职位!")
        return false
      }
      if(!this.formInfo.org_name){
        this.$parent.$parent.$parent.showMsg("请输入工作单位!")
        return false
      }
      if(!this.formInfo.username){
        this.$parent.$parent.$parent.showMsg("请输入姓名!")
        return false
      }
      if(!this.formInfo.department){
        this.$parent.$parent.$parent.showMsg("请输入所在部门!")
        return false
      }
      if(!this.formInfo.duty){
        this.$parent.$parent.$parent.showMsg("请输入职称/职务!")
        return false
      }
      if(this.formInfo.id){
        await this.$http.put('/org_gao/' + this.formInfo.id + '/', this.formInfo).then((data) => {
          if (data.id) {
            this.$layer.msg("修改成功！")
            this.hideInfoWindow()
            this.search()
          } else {
            this.$layer.msg('修改失败！')
          }
        }).catch(() => {
          this.$layer.msg('修改失败！')
        })
      }else{
        await this.$http.post('/org_gao/', this.formInfo).then((data) => {
          if (data.id) {
            this.$layer.msg("添加成功！")
            this.hideInfoWindow()
            this.search()
          } else {
            this.$layer.msg('添加失败！')
          }
        }).catch(() => {
          this.$layer.msg('添加失败！')
        })
      }

    },
    async delInfo(id) {
      const url = '/org_gao/' + id + '/'
      await this.$http.delete(url, ).then(() => {
        this.$layer.msg('删除成功！');
        this.search()
      }).catch(() => {
        this.$layer.msg('删除失败！');
      })
    },
    askDel(id) {
      this.$parent.$parent.$parent.showConfirm('确定要删除吗？', () => {
        this.delInfo(id)
      });
    },
    async pageCallback(data) {
      this.queryInfo.page = data
      await this.search()
    },
    async querySingle(id) {
      const url = '/org_gao/' + id + '/'
      await this.$http.get(url).then((data) => {
        this.formInfo = {
          id: id,
          gao_name: data.gao_name,
          position: data.position,
          org_name: data.org_name,
          username: data.username,
          department: data.department,
          duty: data.duty
        }
        this.showInfoWindow()
      }).catch(() => {
        this.$layer.msg('数据获取失败！');
      })
    },
    askShow(id, v) {
      let doName = v === 1 ? '设为显示' : '设为不显示'
      this.$parent.$parent.$parent.showConfirm('确定要' + doName + '吗？', async () => {
        await this.$http.put('/org_gao/' + id + '/',{
          is_show: v
        }).then((data) => {
          if (data.id) {
            this.$layer.msg(doName + "成功！")
            this.hideInfoWindow()
            this.search()
          } else {
            this.$layer.msg(doName + '失败！')
          }
        }).catch((error) => {
          console.log(error)
          this.$layer.msg(doName + '失败！')
        })
      });
    },
    showUploadWindow() {
      this.classShowUploadWindow = {
        visibility: 'visible'
      }
    },
    hideUploadWindow() {
      this.classShowUploadWindow = {}
    },
    async xlsChange() {
      console.log(this.uploadedCsv)
      const uploadUrl = '/files/' + this.$refs.xlsFile.files[0].name
      await this.$http.put(uploadUrl, this.$refs.xlsFile.files[0]).then((data) => {
        this.uploadedCsv = data
      }).catch(() => {
        this.$parent.$parent.showMsg('上传失败！')
      })
    },
    async submitCsv() {
      const uploadUrl = '/get_csv/'
      await this.$http.get(uploadUrl, {
        params: {
          'file_name': this.uploadedCsv
        }
      }).then((data) => {

        if (data.length <= 1) {
          this.$parent.$parent.showMsg('表格中无数据！')
          return;
        }

        data.splice(0, 1)

        this.uploadedCscData = data

        this.hideUploadWindow()
        this.uploadedCsv=''
        this.classShowProcessWindow = {
          visibility: 'visible'
        }
        this.isFinishUpsert = false
        this.curCsvIndex = 0
        this.csvFinishText = ''
        this.errorList = []
        this.insertOne()

      }).catch(() => {
        this.$parent.$parent.showMsg('读取失败！')
      })
    },
    async insertOne() {


      if (this.curCsvIndex >= this.uploadedCscData.length) {
        this.csvFinishText = '上传完成'
        this.isFinishUpsert = true
        this.search()
        return;
      }

      let row = this.uploadedCscData[this.curCsvIndex]

      // region 数据检查开始

      if (row[0].trim().length == 0) {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '请输委员会名称！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }


      if (row[1].trim().length == 0) {
        this.errorList.push({code: 0, text: '[' + row[1] + ']' + '请输入职位！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      if (row[2].trim().length == 0) {
        this.errorList.push({code: 0, text: '[' + row[2] + ']' + '请输入工作单位！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }
      if (row[3].trim().length == 0) {
        this.errorList.push({code: 0, text: '[' + row[3] + ']' + '请输入姓名！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }
      if (row[4].trim().length == 0) {
        this.errorList.push({code: 0, text: '[' + row[4] + ']' + '请输入所在部门！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }
      if (row[5].trim().length == 0) {
        this.errorList.push({code: 0, text: '[' + row[5] + ']' + '请输入职称/职务！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }
      if (row[6].trim().length == 0) {
        row[7]=0
      }


      // // endregion 数据检查结束
      // let findKey=(value,compare=(a,b)=>a===b)=>{
      //   return Object.keys(this.dict_org_major_type).find(k=>compare(this.dict_org_major_type[k],value))
      // }
      // if(!findKey(row[0])){
      //   this.errorList.push({code: 0, text: '[' + row[0] + ']' + '该专业类领域找不到！'})
      //   this.curCsvIndex++
      //   await this.insertOne()
      //   return;
      // }
      let addForm = {
        gao_name: row[0],
        position: row[1],
        org_name: row[2],
        username: row[3],
        department: row[4],
        duty: row[5],
        is_show: row[6]
      }


      console.log('=======正在插入Application========')
      console.log(addForm)

      await this.$http.post('/org_gao/', addForm).then(async (data) => {
        console.dir(data)
        this.errorList.push({code: 1, text: '[' + addForm.username+ ']' + "导入成功！"})
        this.curCsvIndex++
        this.insertOne()

      }).catch((error) => {
        console.dir(error)
        this.errorList.push({code: 0, text: '[' + addForm.username + ']' + error.response.data[0]})
        this.curCsvIndex++
        this.insertOne()
      })
    },
  },
  mounted() {
    this.search()
  }
}
</script>

<style scoped>
.form_content .label{
  width: 160px;
  text-align: right;
  padding-right: 10px;
}
.rowline{
  display: block;
  margin: 0 auto;
  max-width: 600px;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
}
</style>
