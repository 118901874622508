<template>
  <div id="content1" class="pull-right">
    <div class="center_content" style="min-height: 458px;">
      <div class="container">
        <form id="itemForm">
          <div class="item">
            <span class="title long">旧密码</span>
            <input type="password" value="" name="oldPassword" class="t-input" v-model="pwdForm.oldPassword">
          </div>
          <div class="item">
            <span class="title long">新密码</span>
            <input type="password" value="" name="password" class="t-input" v-model="pwdForm.password">
          </div>
          <div class="item">
            <span class="title">确认新密码</span>
            <input type="password" value="" name="password2" id="imgurl" class="t-input" v-model="pwdForm.password2">
          </div>
        </form>
        <span class="submitBtn" @click="changePassword">提交</span>
        <div class="tip">
          <p>1、密码长度8-64位</p>
          <p>2、须同时包含大写字母、小写字母、数字、特殊字符中的任意三种</p>
          <p>3、密码不能包含邮箱</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Password",
  data(){
    return {
      pwdForm: {
        oldPassword: "",
        password: "",
        password2: "",
      }
    }
  },
  methods: {
    async changePassword () {
      if(this.pwdForm.oldPassword.trim() == ''){
        this.$layer.msg("请输入原始密码")
        return false;
      }

      if(this.pwdForm.password.trim() == ''){
        this.$layer.msg("请输入新密码")
        return false;
      }

      if(this.pwdForm.password2.trim() == ''){
        this.$layer.msg("请输入确认密码")
        return false;
      }

      if(this.pwdForm.password.length < 8){
        this.$layer.msg("密码至少8位")
        return false;
      }

      if(this.pwdForm.password.length > 64){
        this.$layer.msg("密码最多64位")
        return false;
      }

      if(this.pwdForm.password != this.pwdForm.password2){
        this.$layer.msg("新密码和确认密码不一致")
        return false;
      }

      const url = '/change_password/'
      await this.$http.put(url, this.pwdForm).then((data) => {
        console.log(data)
        window.alert("密码修改成功，请重新登陆")
        // this.$cookies.remove('token')
        window.localStorage.clear()
        this.$router.push('/login')
      }).catch((error) => {
        console.log(error)
        alert('修改失败！')
      })
    }
  }
}
</script>

<style scoped>
@import "../../assets/css/dialog.css";
@import "../../assets/css/common.css";
@import "../../assets/css/header.css";
@import "../../assets/css/footer.css";
@import "../../assets/css/pagination.css";
@import "../../assets/css/common(1).css";
@import "../../assets/css/change-password.css";

.center_content{
  width: 900px !important;
  margin: 0px !important;
}

.tip{
  text-align: left;
}
</style>
