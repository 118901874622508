<template>
  <div class="subRight">
    <div class="subRightTit">
      <span>联盟章程</span>
      <a href="/">首页</a>&gt;&gt;
      <a href="/www/introduction/intro">联盟介绍</a>&gt;&gt;联盟章程
    </div>
    <div class="con">
      <p align="center">
        <strong>虚拟仿真实验教学创新联盟章程 </strong>
        <br>
        <strong>（<a name="_GoBack"></a>试行）</strong>
      </p>
      <ol>
        <li>
          <strong>第一章	总则 </strong>
        </li>
      </ol>
      <p>第一条 虚拟仿真实验教学创新联盟（以下简称“联盟”）是由高等院校、相关企事业单位和专家组织自愿组成的，在教育部指导下开展虚拟仿真实验教学研究、咨询、指导、评估和服务的非盈利组织。</p>
      <p>第二条 联盟的宗旨是推进现代信息技术与实验教学课程深度融合，提升实验教学质量和水平；推动形成专业布局合理、教学效果优良、开放共享有效的高等教育信息化实验教学课程新体系；促进实现学校教学、行业应用与技术创新的融合发展。</p>
      <p>第三条 联盟是开放型组织，实行机构会员制。</p>
      <ol>
        <li>
          <strong>第二章	工作任务</strong>
        </li>
      </ol>
      <p>第四条 联盟的主要工作任务：</p>
      <p>(一)	推动虚拟仿真实验教学课程的共享应用</p>
      <p>推动已认定的国家虚拟仿真实验教学课程面向高校和社会开放共享，探索建立实验教学课程在教学领域和行业应用领域共享应用的创新机制；建立虚拟仿真实验教学课程在内容建设、教学实践与效果、共享应用、服务质量等方面的评价体系。</p>
      <p>(二)	编制虚拟仿真实验教学课程建设指导框架</p>
      <p>研究高等教育专业结构和布局，组织和开展各学科专业实验教学的理论与实践研究，分专业制订虚拟仿真实验教学课程的建设规划、建设规范、质量标准、评价要素等系列化指南。</p>
      <p>(三)	实施实验教学队伍教师能力提升计划</p>
      <p>推进对实验教学教师队伍的能力培养，以高校教师为主体推动探索线上、线下教学相结合的新型实验教学模式，着力培养教师对现代信息技术与实验教学深度融合的意识、提高教师使用现代信息技术改造传统实验教学课程的能力，全面提升高校教师的整体实验教学水平。</p>
      <p>(四)	研究实验教学体系认证标准</p>
      <p>根据我国社会经济的发展需要，面向高校和行业产业研究制定实验教学和虚拟仿真实验室建设等方面的体系认证标准，推动形成行业企业深度参与的产学研共同体。</p>
      <p>(五)	推进虚拟仿真实验教学产教融合</p>
      <p>为高校和产业界搭建平台，依托产业发展，促进将前沿技术、最新理论和研究成果转化为实验教学应用，实现产教协同发展。</p>
      <ol>
        <li>
          <strong>第三章	组织机构</strong>
        </li>
      </ol>
      <p>第五条 联盟设立理事会、理事长会议和秘书处。理事长会议是理事会的常设决策机构，秘书处是理事会的常设办事机构。秘书处下设办公室、研究规划部、平台资源部、应用评价部、学术交流部和合作推广部。</p>
      <p>第六条 理事会成员采取席位制，由所在机构在职人员担任。首届理事会理事由联盟发起机构邀请，后续各届理事会理事由会员代表推荐选举产生。</p>
      <p>第七条 理事会的职责是：</p>
      <p>(一)	制定和修改章程，制定联盟的工作方针和任务；</p>
      <p>(二)	选举理事长、副理事长；</p>
      <p>(三)	任命秘书长、副秘书长；</p>
      <p>(四)	审议和批准联盟工作计划；</p>
      <p>(五)	决定其他重大事项。</p>
      <p>理事会每届任期四年，每年至少召开一次会议。</p>
      <p>第八条 联盟设理事长一名，副理事长若干名。首届理事长、副理事长由发起机构协商产生，后续理事长、副理事长由理事会选举产生，须经到会半数以上代表表决通过方能生效。</p>
      <p>第九条 在理事会闭会期间，由理事长会议代行职责。理事长会议由理事长召集，理事长、副理事长、秘书长、副秘书长参加。</p>
      <p>第十条 秘书处在理事会领导下行使下列职权：</p>
      <p>(一)	负责联盟运营，主持日常工作；</p>
      <p>(二)	组织实施年度工作计划；</p>
      <p>(三)	负责与有关部门的工作协调，与联盟成员单位的联系沟通；</p>
      <p>(四)	负责联盟日常活动及经费管理；</p>
      <p>(五)	完成联盟其他相关工作。</p>
      <p>第十一条 联盟根据工作需要，设立如下组织机构：</p>
      <p>(一) 设立学术委员会，负责审定虚拟仿真实验教学课程建设指导框架，指导建立实验教学体系认证标准；</p>
      <p>(二)	设立专业领域工作委员会，统筹研究虚拟仿真实验教学课程建设规划，探索实验教学课程建设与共享应用创新机制；专业领域工作委员会可下设专业类工作委员会，专业类工作委员会的设立须报秘书处审核备案；</p>
      <p>(三)	设立技术工作委员会，建立虚拟仿真实验教学课程建设与应用技术规范，提供技术发展趋势分析和预测；</p>
      <p>(四)	联盟亦可根据工作需要，增加设立专项工作委员会。</p>
      <li>
        <strong>第四章	会员</strong>
      </li>
      <p>第十二条 加入联盟条件：</p>
      <p>(一)	承认联盟章程，遵守联盟相关规章制度；</p>
      <p>(二) 有虚拟仿真实验教学课程建设、应用需求的高校或高校二级学院；在高等学校教学改革和教学建设方面有权威的专家组织；在虚拟仿真技术研究应用、产教融合方面有经验、有规模的机构和企事业单位均可以申请成为成员单位。</p>
      <p>第十三条 符合前述条件的单位应按要求向联盟秘书处办公室提交会员申请，经秘书处会议审核通过后可成为联盟会员单位。</p>
      <p>第十四条 会员享有下列权利：</p>
      <p>(一)	联盟的选举权、被选举权和表决权；</p>
      <p>(二)	优先参加联盟活动和加入各项工作委员会；</p>
      <p>(三)	优先获得联盟提供的服务；</p>
      <p>(四)	对联盟工作的知情权、批评权、建议权和监督权。</p>
      <p>第十五条 会员履行下列义务：</p>
      <p>(一)	遵守联盟的章程和各项规定；</p>
      <p>(二)	积极宣传并参与联盟的各项活动；</p>
      <p>(三)	企业会员应每年按时缴纳会员费；</p>
      <p>(四)	为联盟发展会员，为联盟的发展献策出力。</p>
      <p>第十六条 会员单位退会应提前30日向秘书处提交书面申请。会员单位如有以下情形时，自动丧失会员资格：</p>
      <p>(一)	违反联盟章程或其他规章制度，对联盟的利益和声誉造成重大损害者；</p>
      <p>(二)	严重违犯学术道德规范或违背职业道德者。</p>
      <ol>
        <li>
          <strong>第五章	附则</strong>
        </li>
      </ol>
      <p>第十七条 联盟因终止、解散或分立、合并等原因需要解体时，由理事长会议提出提案，经联盟理事大会2/3以上成员表决同意后生效。联盟终止后的剩余财产，在具有审计资质的财务审计机构监督下，用于发展与联盟宗旨相关的事业。</p>
      <p>第十八条 本章程的制修订草案由秘书处提出，经理事会审议通过后生效。</p>
      <p>第十九条 本章程由联盟理事会负责解释。</p>
      <p style="text-align:right;">2019年03月20日</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Rules"
}
</script>

<style scoped>

</style>
