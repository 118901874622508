<template>
  <div id="panel" class="panel" style="min-height: 696px;">
    <form id="searchSection" onsubmit="return false;">
      <input type="text" name="title" placeholder="标题" class="s-input separate" v-model="queryInfo.title">
      <select name="status" class="s-select separate" v-model="queryInfo.status">
        <option value="">选择发布状态</option>
        <option value="0">未发布</option>
        <option value="1">已发布</option>
      </select>
      <select name="type" class="s-select separate" v-model="queryInfo.type">
        <option value="">通知类型</option>
        <option value="0">平台通知</option>
        <option value="1">会员通知</option>
      </select>
      <input type="submit" class="s-btn" value="搜索" @click="search">
      <div class="add-btn" @click="showAddWindow">新建</div>
    </form>

    <div id="list">
      <table v-if="tableData.length > 0">
        <tbody>
        <tr class="l_header">
          <th class="itemStatus">标题</th>
          <th class="itemStatus">通知类型</th>
          <th class="itemStatus">开始时间</th>
          <th class="itemStatus">结束时间</th>
          <th class="itemStatus">状态</th>
          <th class="action" width="180">操作</th>
        </tr>
        <tr class="item" v-for="item in tableData" :key="item.id">
          <td class="itemStatus" title=" 123123 ">
            <a class="a-link" href="javascript:;" @click="showDetailWindow(item.id)">{{ item.title }}</a>
          </td>
          <td class="itemStatus">{{ item.type == 0 ? '平台通知' : '会员通知' }}</td>
          <td class="itemStatus">{{ item.startTime ? item.startTime.substr(0, 10) : '' }}</td>
          <td class="itemStatus">{{ item.endTime ? item.endTime.substr(0, 10) : '' }}</td>
          <td :class="'itemStatus itemStatus' + item.status">{{ item.status == 1 ? '已发布' : '未发布' }}</td>
          <td class="action">
            <a class="set" href="javascript:;" @click="showDetailWindow(item.id)">查看</a>
            <a v-if="item.status==1" class="nopublish" href="javascript:;" @click="unpublish(item.id)">取消发布</a>
            <a v-if="item.status==0" class="publish" href="javascript:;" @click="publish(item.id)">发布</a>
            <br>
            <a class="edit" href="javascript:;" @click="querySingle(item.id)">编辑</a>
            <a class="delete" href="javascript:;" @click="askDel(item.id)">删除</a>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="noitems" v-if="tableData.length == 0">没有数据</div>
    </div>

    <pagination :cur="pageInfo.page" :all="pageInfo.all" :totalData="pageInfo.totalData"
                :callback="pageCallback"></pagination>

    <div class="form_overlay" id="form_overlay" :style="classShowAddWindow">
      <div class="form_overlay_bg"></div>
      <div class="form_panel editor" >
        <div class="form_title">添加/编辑通知</div>
        <div class="form_content">
          <form id="itemForm">
            <div class="item">
              <span class="label"><i class="require">*</i>类型:</span>
              <select name="type" id="type" v-model="formInfo.type">
                <option value="">请选择</option>
                <option value="0">平台通知</option>
                <option value="1">会员通知</option>
              </select>
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>标题:</span>
              <input type="hidden" v-model="formInfo.id">
              <input type="text" name="title" v-model="formInfo.title">
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>开始时间:</span>
              <input type="date" name="startTime" id="startTime" class="date_box"
                     v-model="formInfo.startTime">
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>结束时间:</span>
              <input type="date" name="endTime" id="endTime" class="date_box"
                     v-model="formInfo.endTime">
            </div>
            <span class="label"><i class="require">*</i>通知内容:</span>
            <div class="clearfix"></div>
            <span>
              <vue-ueditor-wrap v-model="formInfo.content" :config="editorConfig"
                                editor-id="editor-demo-01"></vue-ueditor-wrap>
            </span>
            <div class="clearfix"></div>
          </form>
        </div>
        <div class="form_control">
          <a href="javascript:;" class="button close" id="form_close" @click="hideAddWindow">取消</a>
          <a href="javascript:;" class="button" id="form_submit" @click="saveInfo">提交</a>
        </div>
      </div>
    </div>

    <div class="dialog_bg" :style="classShowDetailWindow"></div>

    <div class="dialog_box" :style="classShowDetailWindow">
      <p class="dialog_top">平台通知<span class="close btn_close" @click="hideDetailWindow">×</span></p>
      <div class="dialog_content"><h2 class="dialog_title">{{ detailFormInfo.title }}</h2>
        <ul class="content_list">
          <li><strong class="content_title">开始时间：</strong>
            {{ detailFormInfo.startTime ? detailFormInfo.startTime.substr(0, 10) : '' }}
          </li>
          <li><strong class="content_title">结束时间：</strong>
            {{ detailFormInfo.endTime ? detailFormInfo.endTime.substr(0, 10) : '' }}
          </li>
          <li><strong class="content_title">通知内容：</strong>
            <p v-html="detailFormInfo.content"></p>
          </li>
        </ul>
        <button class="close dialog_btn btn_confirm" @click="hideDetailWindow">确认</button>
      </div>
    </div>

  </div>
</template>

<script>
import VueUeditorWrap from 'vue-ueditor-wrap'
import Pagination from "../../components/Pagination"

export default {
  name: "AdminNotice",
  components: {
    VueUeditorWrap,
    Pagination
  },
  data() {
    return {
      queryInfo: {
        page: 1,
        size: 10,
        title: '',
        status: '',
        type: '',
      },
      formInfo: {
        id: 0,
        type: '',
        title: '',
        startTime: '',
        endTime: '',
        content: ''
      },
      detailFormInfo: {
        title: '',
        startTime: '',
        endTime: '',
        content: ''
      },
      classShowAddWindow: {},
      classShowDetailWindow: {display: 'none'},
      editorConfig: {
        // 访问 UEditor 静态资源的根路径，可参考 https://hc199421.gitee.io/vue-ueditor-wrap/#/faq
        UEDITOR_HOME_URL: '/UE/',
        // 服务端接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
        // serverUrl: '//ueditor.szcloudplus.com/cos',
        serverUrl: this.$store.state.uEditorServerUrl,
        autoHeight: true,
        // imagePathFormat: '/files/{yyyy}{mm}{dd}-{time}{rand:6}',
        // imageUrlPrefix: 'http://localhost:8000'
        initialFrameWidth: '100%',

        initialFrameHeight: 300
      },
      tableData: [],
      tableDataCount: 0,
    }
  },
  methods: {
    showAddWindow() {
      this.classShowAddWindow = {
        visibility: 'visible'
      }
    },
    hideAddWindow() {
      this.classShowAddWindow = {}
      this.formInfo = {
        id: 0,
        type: '',
        title: '',
        startTime: '',
        endTime: '',
        content: ''
      }
    },
    async saveInfo() {
      console.log(this.formInfo)

      if(!this.formInfo.type){
        this.$parent.$parent.showMsg("请选择类型!")
        return false
      }

      if(!this.formInfo.title){
        this.$parent.$parent.showMsg("请输入标题!")
        return false
      }

      if(!this.formInfo.startTime){
        this.$parent.$parent.showMsg("请选择开始时间!")
        return false
      }

      if(!this.formInfo.endTime){
        this.$parent.$parent.showMsg("请选择结束时间!")
        return false
      }

      if(!this.formInfo.content){
        this.$parent.$parent.showMsg("请输入通知内容!")
        return false
      }

      if(this.formInfo.id){
        let url = '/notice/' + this.formInfo.id + '/'
        await this.$http.put(url , {
          type: this.formInfo.type,
          title: this.formInfo.title,
          startTime: this.formInfo.startTime + ' 00:00:00',
          endTime: this.formInfo.endTime + ' 23:59:59',
          content: this.formInfo.content,
        }).then((data) => {
          if (data.id) {
            this.$parent.$parent.showMsg('修改成功！')
            this.hideAddWindow()
            this.search()
          } else {
            this.$parent.$parent.showMsg('修改失败！')
          }
        }).catch((error) => {
          console.log(error)
          this.$parent.$parent.showMsg('修改失败！')
        })
      }else{
        await this.$http.post('/notice/', {
          type: this.formInfo.type,
          title: this.formInfo.title,
          startTime: this.formInfo.startTime + ' 00:00:00',
          endTime: this.formInfo.endTime + ' 23:59:59',
          content: this.formInfo.content,
        }).then((data) => {
          if (data.id) {
            this.$parent.$parent.showMsg('添加成功！')
            this.hideAddWindow()
            this.search()
          } else {
            this.$parent.$parent.showMsg('添加失败！')
          }
        }).catch((error) => {
          console.log(error)
          this.$parent.$parent.showMsg('添加失败！')
        })
      }
    },
    async search() {
      await this.$http.get('/notice/', {
        params: this.queryInfo
      }).then((data) => {
        console.log(data)
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$parent.$parent.showMsg('获取通知信息失败！')
      })
    },
    async changePublishState(id, status) {
      const msg = status == 0 ? "取消" : ""
      const url = '/notice/' + id + '/'
      await this.$http.put(url, {
        status: status
      }).then((data) => {
        console.log(data)
        this.$parent.$parent.showMsg(msg + '发布成功！');
        this.search()
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg(msg + '发布失败！');
      })
    },
    unpublish(id) {
      console.log(id)
      this.$parent.$parent.showConfirm('确定要取消发布吗？', () => {
        this.changePublishState(id, 0)
      });
    },
    publish(id) {
      console.log(id)
      this.$parent.$parent.showConfirm('确定要发布吗？', () => {
        this.changePublishState(id, 1)
      });
    },
    async delRow(id) {
      const url = '/notice/' + id + '/'
      await this.$http.put(url, {
        isdel: 1
      }).then((data) => {
        console.log(data)
        this.$parent.$parent.showMsg('删除成功！');
        this.search()
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg('删除失败！');
      })
    },
    askDel(id) {
      this.$parent.$parent.showConfirm('确定要删除吗？', () => {
        this.delRow(id)
      });
    },
    async pageCallback(data) {
      this.queryInfo.page = data
      await this.search()
    },
    async showDetailWindow(id) {
      const url = '/notice/' + id + '/'
      await this.$http.get(url).then((data) => {
        console.log(data)
        this.detailFormInfo.title = data.title
        this.detailFormInfo.startTime = data.startTime
        this.detailFormInfo.endTime = data.endTime
        this.detailFormInfo.content = data.content
        this.classShowDetailWindow = {display: 'block'}
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg('删除失败！');
      })
    },
    hideDetailWindow() {
      this.classShowDetailWindow = {display: 'none'}
      this.detailFormInfo = {
        title: '',
        startTime: '',
        endTime: '',
        content: ''
      }
    },
    async querySingle(id) {
      const url = '/notice/' + id + '/'
      await this.$http.get(url).then((data) => {
        console.log(data)
        this.formInfo = {
          id: id,
          type: data.type,
          title: data.title,
          startTime: data.startTime ? data.startTime.substr(0, 10) : '',
          endTime: data.endTime ? data.endTime.substr(0, 10) : '',
          content: data.content
        }
        this.showAddWindow()
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg('数据获取失败！');
      })
    }
  },
  mounted() {
    this.search()
  },
  computed: {
    pageInfo() {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    }
  },
}
</script>

<style scoped>
.date_box {
  border: 1px solid #dddddd;
  /*background-color: #f5f7fc;*/
  width: 292px;
  height: 32px;
  line-height: 32px;
  margin-right: 8px;
  padding-left: 8px;
}

.dialog_btn {
  width: 70px;
  height: 36px;
  font-size: 14px;
}
</style>
