<template>
  <div>
    <div class="breadcrumb-nav">
      <a href="/">首页</a>&gt;&gt;
      <a href="/www/organization/chairman">组织机构</a>&gt;&gt;企业工作委员会
    </div>
    <div class="subRight">
      <div class="subRightTit">
        <span>企业工作委员会</span>

      </div>
      <div class="con ">
        <!-- <article class="articleNews" v-html="data.length > 0 ? data[0].brief  : ''"></article> -->
        <table
          cellspacing="0"
          cellpadding="0"
          class="orgTbl m-hide"
        >
          <tbody>
            <tr>
              <th
                colspan="5"
                width="759"
              >企业工作委员会（按单位名称拼音顺序排列）</th>
            </tr>
            <tr>
              <th class="thead">序号</th>
              <th class="thead">职位</th>
              <th class="thead">姓名</th>
              <th class="thead">职务</th>
              <th class="thead">工作单位</th>
            </tr>
            <tr
              v-for="(item, index) in data"
              :key="item.id"
            >
              <td>{{ index + 1 }}</td>
              <td>{{ item.position }}</td>
              <td>{{ item.username }}</td>
              <td>{{ item.duty }}</td>
              <td>{{ item.org_name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Enterprise",
  data (){
    return {
      data: []
    }
  },
  methods: {
    async getData(){
      await this.$http.get('/allowany/company_brief/', {
        params: {
          page: 1,
          size: 100000
        }
      }).then((data) => {
        console.log(data)
        this.data = data.results
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped>
.thead {
  background: #fff;
  text-align: left;
}
</style>
