<template>
  <div style="height:100%">
    <div
      id="layout"
      style="visibility: visible;"
    >
      <div id="north">
        <div class="header2">
          <div class="header-bg"></div>
          <div class="header_container">
            <a href="javascript:void(0);">
              <div class="logo">
                <img src="../assets/images/logo.png">
              </div>
            </a>
            <div class="nav">
              <!-- <div id="menu2">
                <ul class="clearfix">
                  <li @click="updateMenu" class="nav_item" :class="this.menuSelected == '/www/index' ? 'cur' : ''">
                    <router-link to="/www/index">
                      <a href="javascript:void(0);">联盟首页</a>
                    </router-link>
                  </li>
                  <li @click="updateMenu" class="nav_item" :class="this.menuSelected.substr(0, 17) == '/www/introduction' ? 'cur' : ''">
                    <router-link to="/www/introduction">
                      <a href="javascript:void(0);">联盟介绍</a>
                    </router-link>
                  </li>
                  <li @click="updateMenu" class="nav_item" :class="this.menuSelected.substr(0, 17) == '/www/organization' ? 'cur' : ''">
                    <router-link to="/www/organization">
                      <a href="javascript:void(0);">组织机构</a>
                    </router-link>
                  </li>
                  <li @click="updateMenu" class="nav_item" :class="this.menuSelected == '/www/vip' ? 'cur' : ''">
                    <router-link to="/www/vip">
                      <a href="javascript:void(0);">会员单位</a>
                    </router-link>
                  </li>
                  <li @click="updateMenu" class="nav_item" :class="this.menuSelected == '/www/apply' ? 'cur' : ''">
                    <router-link to="/www/apply">
                      <a href="javascript:void(0);">会员申请</a>
                    </router-link>
                  </li>
                  <li @click="updateMenu" class="nav_item" :class="this.menuSelected.substr(0, 20) == '/www/activity_layout' ? 'cur' : ''">
                    <router-link to="/www/activity_layout">
                      <a href="javascript:void(0);">工作动态</a>
                    </router-link>
                  </li>
                  <li class="nav_item">
                    <a href="/www/construction_guide" target="_blank">建设指南</a>
                  </li>
                  <li class="nav_item">
                    <a href="/www/activity?id=77" target="_blank">建设指南</a>
                  </li>
                  <li @click="updateMenu" class="nav_item" :class="this.menuSelected == '/www/enterprise_zone' ? 'cur' : ''">
                    <router-link to="/www/enterprise_zone">
                      <a href="javascript:void(0);">企业专区</a>
                    </router-link>
                  </li>
                  <li @click="updateMenu" class="nav_item" :class="this.menuSelected.substr(0, 20) == '/www/competition' ? 'cur' : ''">
                    <router-link to="/www/competition">
                      <a href="javascript:void(0);">虚仿竞赛</a>
                    </router-link>
                  </li>
                </ul>
              </div> -->
              <div id="menu2">
                <ul class="clearfix">
                  <li
                    @click="updateMenu"
                    class="nav_item"
                    :class="this.menuSelected == '/www/index' ? 'cur' : ''"
                  >
                    <router-link to="/www/index">
                      <a href="javascript:void(0);">首页</a>
                    </router-link>

                  </li>
                  <li
                    @click="updateMenu"
                    class="nav_item"
                    :class="this.menuSelected.substr(0, 17) == '/www/introduction' ? 'cur' : ''"
                  >
                    <router-link to="/www/introduction">
                      <a href="javascript:void(0);">联盟介绍<div class="angle"></div></a>
                    </router-link>

                    <ul>
                      <li @click="updateMenu">
                        <router-link to="/www/organization">
                          <a href="javascript:void(0);">组织机构</a>
                        </router-link>
                      </li>
                      <li @click="updateMenu">
                        <router-link to="/www/vip">
                          <a href="javascript:void(0);">会员单位</a>
                        </router-link>
                      </li>
                      <li @click="updateMenu">
                        <router-link to="/www/apply">
                          <a href="javascript:void(0);">会员申请</a>
                        </router-link>
                      </li>
                    </ul>
                  </li>
<!--                  <li-->
<!--                    @click="updateMenu"-->
<!--                    class="nav_item"-->
<!--                    :class="this.menuSelected == '/www/vip' ? 'cur' : ''"-->
<!--                  >-->
<!--                    <router-link to="/www/vip">-->
<!--                      <a href="javascript:void(0);">会员单位</a>-->
<!--                    </router-link>-->
<!--                    <div class="angle"></div>-->
<!--                    <ul>-->
<!--                      <li @click="updateMenu">-->
<!--                        <router-link to="/www/apply">-->
<!--                          <a href="javascript:void(0);">会员申请</a>-->
<!--                        </router-link>-->
<!--                      </li>-->
<!--                    </ul>-->
<!--                  </li>-->
                  <li
                    @click="updateMenu"
                    class="nav_item"
                    :class="this.menuSelected.substr(0, 20) == '/www/activity_layout' ? 'cur' : ''"
                  >
                    <router-link to="/www/activity_layout">
                      <a href="javascript:void(0);">工作动态</a>
                    </router-link>
                  </li>
                  <li class="nav_item">
                    <a
                      href="http://xmsb.cloudvse.com"
                      target="_blank"
                    >项目申报</a>
                  </li>
<!--                  <li class="nav_item">-->
<!--                    <a-->
<!--                        href="http://des.cloudvse.com/"-->
<!--                        target="_blank"-->
<!--                    >课程设计</a>-->
<!--                  </li>-->
                  <li
                    @click="updateMenu"
                    class="nav_item"
                    :class="this.menuSelected == '/www/enterprise_zone' ? 'cur' : ''"
                  >
                    <router-link to="/www/enterprise_zone">
                      <a href="javascript:void(0);">企业专区</a>
                    </router-link>
                  </li>
                  <li
                      @click="updateMenu"
                      class="nav_item"
                      :class="this.menuSelected == '/www/research' ? 'cur' : ''"
                  >
                    <router-link to="/www/research">
                      <a href="javascript:void(0);">在线教研</a>
                    </router-link>
                  </li>
                  <li
                      class="nav_item"
                  >
                    <a href="javascript:void(0);">
                      <a href="https://vseclass.cn/subject" target="_blank">专委会频道</a>
                    </a>
                  </li>
<!--                  <li-->
<!--                    @click="updateMenu"-->
<!--                    class="nav_item"-->
<!--                    :class="this.menuSelected.substr(0, 16) == '/www/competition' ? 'cur' : ''"-->
<!--                  >-->
<!--                    <router-link to="/www/competition">-->
<!--                      <a href="javascript:void(0);">虚仿竞赛</a>-->
<!--                    </router-link>-->
<!--                  </li>-->

                </ul>
              </div>
              <div
                class="nav-right"
                id="user-info"
                style="position: absolute"
              >
                <div class="search">
                  <div class="search-ipt">
                    <i class="iconfont icon-sousuo"></i>
                    <input
                      type="text"
                      placeholder="输入关键词查询"
                      v-model="searchText"
                      @keydown.enter="getSearchRes"
                      :style="searchOpenStatus == 1 ? 'width: 160px' : 'width: 0px'"
                    >
                    <img src="../assets/images/dot.png"  alt=""
                         :style="searchOpenStatus == 1 ? 'transform: rotate(180deg)' : ''"
                         @click="searchOpenStatus = 1 - searchOpenStatus"
                    >
                  </div>
                </div>
                <div style="width: 160px" v-if="searchOpenStatus == 0 && localUser.user_id">
                  <div class="people">
                    <img
                        class="pic"
                        @click="toProfile"
                        v-if="localUser.avatar == ''"
                        src="../assets/images/user.png"
                    >
                    <img
                        class="pic"
                        @click="toProfile"
                        v-if="localUser.avatar != ''"
                        :src="this.$store.state.uploadFileRootUrl + localUser.avatar"
                    >
                    <a
                      href="javascript:;"
                      id="username"
                      @click="toProfile"
                      :title="localUser.name"
                    >{{ localUser.name.length > 5 ? localUser.name.substr(0, 4) + '..' : localUser.name }}</a>
                    <a
                        href="javascript:;"
                        id="signOff"
                        @click="doLogout"
                    >退出</a>
                  </div>
                </div>
                <div style="width: 160px" v-if="searchOpenStatus == 0 && !localUser.user_id">
                  <a
                    href="/login"
                    class="to-login"
                  >会员登录</a>
                  <span class="line">|</span>
                  <a
                    href="/www/register"
                    class="to-register"
                  >会员注册</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="menu"></div>
      <router-view></router-view>
      <div id="south">
        <div id="footer">
          <div class="bottom">
            主办单位：虚拟仿真实验教学创新联盟
            <span class="blank"></span>北京中教仪科技有限公司<span class="blank"></span>
            <a
              class="beian-link"
              target="_blank"
              href="http://beian.miit.gov.cn/"
            >京ICP备2020048768号-1</a>
          </div>
        </div>
      </div>
    </div>
    <div
      id="dialog_overlay"
      :style="showMsgWindow"
    >
      <div id="dialog_bg"></div>
      <div
        id="dialog_panel"
        style="margin-left: -136px; margin-top: -54px;max-width: 300px;"
      >
        <div id="dialog_panel_bg"></div>
        <div id="dialog_title">提示</div>
        <div id="dialog_content">{{ msgText }}</div>
        <div id="dialog_content2">
          <span
            v-for="item in errorList"
            v-bind:key="item"
          >{{ item }}</span>
        </div>
        <div id="dialog_control">
          <a
            href="javascript:void(0)"
            id="dialog_close"
            @click="hideMsg"
            class="button"
            :style="this.msgType == 1? 'display:inline-block': 'display:none'"
          >取消</a>
          <a
            href="javascript:void(0)"
            id="dialog_ok"
            @click="confirmMsg"
            :class="this.msgType == 1? 'button': 'button col2'"
            :style="this.msgType == 2? 'display:none': 'display: inline-block'"
          >确认</a>
        </div>
      </div>
    </div>
    <div
      id="page-loading"
      style="display: none;"
    ></div>
    <!--    <a href="javascript:;" id="toTop" style="display: none;"><span id="toTopHover"></span>-->
    <!--      <i class="move-to-up iconfont"></i>-->
    <!--    </a>-->
    <!--    <a href="javascript:;" id="toTop" style="display: block;"><span id="toTopHover" style="opacity: 0;"></span><i-->
    <!--        class="move-to-up iconfont"></i></a>-->
    <!--    <back-to-top text="^" visibleoffset="500"></back-to-top>-->

  </div>
</template>

<script>
// import BackToTop from 'vue-backtotop'

export default {
  name: 'Home',
  data() {
    return {
      msgType: 0,
      showMsgWindow: {},
      msgText: '',
      errorList: [],
      confirmCallback: '',
      menuSelected: '',
      searchText:'',
      searchOpenStatus: 0
    }
  },
  methods: {
    getSearchRes(){
      if (this.searchOpenStatus !== 1) {
        return false
      }
      if(this.$route.path=='/www/searchresult'){
        let num = Math.floor(Math.random()*1000 + 1);
        this.$router.push(`${this.$route.path}?${num}`)
        this.$route.params.searchText=this.searchText
      }else{
        this.$router.push({'name':'SearchResult',params: { searchText:this.searchText }})
      }

    },
    toProfile() {
      this.$router.push('/profile')
      this.updateMenu()
    },
    async getUserInfo() {
      if(this.$store.state.userInfo.user_id){
        return;
      }
      await this.$http.post('/login_user/', {}).then(async (data) => {
        this.$store.commit('setUserInfo', data)
      }).catch((error) => {
        console.log(error)
        this.$store.commit('setUserInfo', {
          'user_id': 0,
          'user_name': '',
          'profile_id': 0,
          'name': '',
          'avatar': '',
          'role': '',
          'gender': '',
          'isFirstLog': false,
          'province': '',
          'school': '',
          'mobile': '',
          'pwdChecked': 0
        })
      })
    },
    doLogout() {
      // 退出，清空cookie
      // this.$cookies.remove('token')
      // window.localStorage.clear()
      // this.$router.push('/login')

      this.$store.commit('setToken', '')

      this.$store.commit('setUserInfo', {
        'user_id': 0,
        'user_name': '',
        'profile_id': 0,
        'name': '',
        'avatar': '',
        'role': '',
        'gender': '',
        'isFirstLog': false,
        'province': '',
        'school': '',
        'mobile': '',
        'pwdChecked': 0
      })

      this.$router.push('/login')
      // window.location.reload()
    },
    showMsg(text, callback='') {
      this.msgType = 0
      this.msgText = text
      this.showMsgWindow = {
        visibility: 'visible'
      }
      this.confirmCallback = callback
    },
    showConfirm(text, callback) {
      this.msgType = 1
      this.msgText = text
      this.showMsgWindow = {
        visibility: 'visible'
      }
      this.confirmCallback = callback
    },
    showProcess(text) {
      this.msgType = 2
      this.msgText = text
      this.errorList = []
      this.showMsgWindow = {
        visibility: 'visible'
      }
      this.confirmCallback = ''
    },
    setText(text) {
      this.msgText = text
    },
    pushError(err) {
      this.errorList.push(err)
    },
    hideMsg() {
      this.msgText = ''
      this.showMsgWindow = {}
    },
    confirmMsg() {
      this.msgText = ''
      this.showMsgWindow = {}
      if (this.confirmCallback) {
        this.confirmCallback()
        this.confirmCallback = ''
      }
    },
    updateMenu(){
      const url = window.location.href
      const arrUrl = url.split('//')
      const start = arrUrl[1].indexOf('/')
      let relUrl = arrUrl[1].substring(start)

      if (relUrl.indexOf('?') !== -1) {
        relUrl = relUrl.split('?')[0]
      }
      relUrl = relUrl.replace('#', '')
      this.menuSelected = relUrl
    },
    showTip(msg) {
      this.$layer.msg(msg)
    }
  },
  computed: {
    localUser(){
      return this.$store.state.userInfo
    }
  },
  // components: { BackToTop },
  mounted() {
    this.updateMenu()
    // this.getUserInfo()
    // this.showMsg("测试消息")
  },
}
</script>

<style scoped>
@font-face {
  font-family: "iconfont"; /* Project id 2618897 */
  src: url("//at.alicdn.com/t/font_2618897_0j5eq4ybtj9j.woff2?t=1624006100957")
      format("woff2"),
    url("//at.alicdn.com/t/font_2618897_0j5eq4ybtj9j.woff?t=1624006100957")
      format("woff"),
    url("//at.alicdn.com/t/font_2618897_0j5eq4ybtj9j.ttf?t=1624006100957")
      format("truetype");
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-meh:before {
  content: "\ebee";
}

.icon-sousuo:before {
  content: "\e603";
}

.icon-index-copy:before {
  content: "\e622";
}
.header2 .header_container .nav .nav_item {
  position: relative;
}
.header2 .header_container .nav .nav_item:nth-child(2) {
  width: 74px;
}
.header2 .header_container .nav .nav_item ul {
  position: absolute;
  left: 0;
  top: 76px;
  width: 100%;
  text-align: center;
  font-weight: normal;
  background: #fff;
  display: none;
  z-index: 2;
  line-height: 1.5;
  padding-bottom: 10px;
  box-shadow: 2px 1px 5px rgba(200, 200, 200.15);
}
.header2 .header_container .nav .nav_item ul li {
  margin-top: 10px;
}

.header2 .header_container .nav .nav_item ul:before {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border: 1px solid #eee;
  border-left: 0;
  border-bottom: 0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  position: absolute;
  left: 50%;
  top: -9px;
  list-style: none;
  margin: 0;
  margin-left: -7.7px;
}
.angle {
  position: absolute;
  right: 10px;
  top: 50%;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-bottom-width: 0;
  border-top-color: #000;
  border-width: 5px;
  margin-top: -2.5px;
  transition: transform 0.3s ease-in;
}

.angle::after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  content: "";
  border-width: 5px;
  bottom: 1px;
  margin-left: -5px;
  border-bottom-width: 0;
  border-top-color: #f6f7f9;
}
.header2 .header_container .nav .nav_item:hover ul {
  display: block;
}
.header2 .header_container .nav .nav_item:hover .angle {
  border-top-width: 0;
  border-bottom-color: #000;
  transition: transform 0.3s ease-in;
}
.header2 .header_container .nav .nav_item:hover .angle::after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  content: "";
  border-width: 5px;
  top: 1px;
  margin-left: -5px;
  border-top-width: 0;
  border-bottom-color: #f6f7f9;
}

.vue-back-to-top {
  /*width: 60px;*/
  /*height: 60px;*/
  /*padding: 10px 16px;*/
  /*border-radius: 50%;*/
  /*font-size: 22px;*/
  /*line-height: 22px;*/
  position: fixed;
  bottom: 100px;
  right: 5px;
  z-index: 100;
}

div.vue-back-to-top .default {
  /*background-color: #f5c85c;*/
  /*border-radius: 3px;*/
  /*color: #ffffff;*/
  /*height: 30px;*/
  /*line-height: 30px;*/
  /*text-align: center;*/
  /*width: 160px;*/

  position: relative;
  display: block;
  color: #fff;
  /* padding: 15px 10px; */
  /* padding: 15px; */
  cursor: pointer;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #2095ff;
  font-size: 36px;
}

#dialog_content2 {
  max-height: 100px;
  overflow-y: auto;
}

#dialog_content2 span {
  display: block;
  padding: 0px 10px 0px 10px;
  color: #f88;
}

#dialog_control a {
  border-right: 0px !important;
}
.nav-right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.search {
  width: auto;
  line-height: 30px;
  position: relative;
  margin-right: 5px;
}
.search-ipt {
  /*width: 150px;*/
  line-height: 30px;
  background: #fff;
  height: auto;
  border-radius: 16px;
  padding: 0 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 2px #ccc;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.search-ipt input {
  width: 100px;
  height: 30px;
  border: none;
  outline: none;
  float: left;
}
.icon-sousuo {
  font-size: 20px;
  color: #999999;
  cursor: pointer;
}

.loginTxt {
  background: #f6f7f9;
  z-index: 3;
}
.header2 .header_container .people {
  display: flex;
  align-items: center;
}
.header2 .header_container .people * {
  margin-left: 10px;
}
.header2 .header_container .people img {
  /*margin-left: 5px;*/
}
</style>
