<template>
  <div id="center">
    <div class="subBanner3"></div>
    <div id="subCenter">
      <div id="content" class="content">
        <div class="subLeft">
          <dl>
            <dt>联盟介绍
              <p>Alliance introduction</p>
            </dt>
            <dd @click="updateMenuSelected" :class="this.menuSelected == '/www/introduction/intro' ? 'cur' : ''">
              <router-link to="/www/introduction/intro">联盟简介</router-link>
            </dd>
            <dd @click="updateMenuSelected" :class="this.menuSelected == '/www/introduction/rules' ? 'cur' : ''">
              <router-link to="/www/introduction/rules">联盟章程</router-link>
            </dd>
            <!-- <dd @click="updateMenuSelected" :class="this.menuSelected == '/www/introduction/notice' ? 'cur' : ''">
              <router-link to="/www/introduction/notice">联盟公告</router-link>
            </dd> -->
            <dd @click="updateMenuSelected" :class="this.menuSelected == '/www/introduction/contact' ? 'cur' : ''">
              <router-link to="/www/introduction/contact">联系我们</router-link>
            </dd>
          </dl>
        </div>
        <router-view></router-view>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "IntroductionLayout",
  data() {
    return {
      menuSelected: ''
    }
  },
  methods: {
    updateMenuSelected(){
      const url = window.location.href
      const arrUrl = url.split('//')
      const start = arrUrl[1].indexOf('/')
      let relUrl = arrUrl[1].substring(start)

      if (relUrl.indexOf('?') !== -1) {
        relUrl = relUrl.split('?')[0]
      }
      relUrl = relUrl.replace('#', '')
      this.menuSelected = relUrl
    },
  },
  mounted() {
    this.updateMenuSelected()
  }
}
</script>

<style scoped>
@import "../../../assets/css/dialog.css";
@import "../../../assets/css/common.css";

@import "../../../assets/css/union.css";
@import "../../../assets/css/pagination2.css";

@import "../../../assets/css/header.css";
@import "../../../assets/css/footer.css";

#center {
  width: auto !important;
}

</style>
