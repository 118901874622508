<template>
  <div id="panel" class="panel" style="min-height: 696px; padding-top: 0px;">
    <div class="orgTab">
      <span :class="menuSelected == '/admin_company_apply' ? 'active' : ''" @click="updateMenuSelected">
        <router-link to="/admin_company_apply">企业会员申请管理</router-link>
      </span>
      <span :class="menuSelected == '/admin_company_info' ? 'active' : ''" @click="updateMenuSelected">
        <router-link to="/admin_company_info">企业会员简介管理</router-link>
      </span>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "AdminCompanyLayout",
  data() {
    return {
      menuSelected: '/admin_org_leader',
    }
  },
  methods: {
    updateMenuSelected(){
      const url = window.location.href
      const arrUrl = url.split('//')
      const start = arrUrl[1].indexOf('/')
      let relUrl = arrUrl[1].substring(start)

      if (relUrl.indexOf('?') !== -1) {
        relUrl = relUrl.split('?')[0]
      }
      relUrl = relUrl.replace('#', '')
      this.menuSelected = relUrl
    },
  },
  mounted() {
    this.updateMenuSelected()
  }
}
</script>

<style scoped>
.orgTab {
  height: 52px;
  border-bottom: 1px solid #dddddd;
  box-sizing: border-box;
}

.orgTab span{
  display: inline-block;
  line-height: 50px;
  font-size: 14px;
  color: #333333;
  margin-right:15px;
}

.orgTab span>a{
  display: inline-block;
  line-height: 50px;
  box-sizing: border-box;
}

.orgTab span.active{
  border-bottom: 2px solid #5473e8;
  font-weight: bold;
}
</style>
