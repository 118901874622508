<template>
  <div id="panel" class="panel" style="min-height: 696px;">
    <form id="searchSection" onsubmit="return false;">
      <div class="choice" style="width:100%">
        <select name="status" class="s-select separate" v-model="queryInfo.status">
          <option value="">选择发布状态</option>
          <option value="0">未发布</option>
          <option value="1">已发布</option>
        </select>
        <select name="grade" class="s-select separate" v-model="queryInfo.grade">
          <option value="">选择级别</option>
          <option value="1">领域</option>
          <option value="2">专业类</option>
          <option value="3">适用专业</option>
        </select>
        <input type="text" name="code" class="s-input separate" placeholder="专业编码" v-model="queryInfo.code">
        <input type="text" name="title" class="s-input separate" placeholder="专业名称" v-model="queryInfo.title">
        <input type="button" class="s-btn" value="搜索" @click="search">
      </div>
      <hr>
      <div class="add-btn" @click="showAddWindow">新建</div>
      <div class="import-btn" id="batchUpload" @click="showUploadWindow">导入</div>
    </form>
    <div id="list">
      <table v-if="this.tableData.length > 0">
        <tbody>
        <tr class="l_header">
          <th class="itemStatus">专业编码</th>
          <th class="itemStatus">级别</th>
          <th class="itemStatus">名称</th>
          <th class="itemStatus">所属领域</th>
          <th class="itemStatus">所属专业类</th>
          <th class="itemStatus">状态</th>
          <th class="action" width="180">操作</th>
        </tr>
        <tr class="item" v-for="item in tableData" :key="item.id">
          <td class="itemStatus">{{ item.code }}</td>
          <td class="itemStatus">{{ item.grade }}</td>
          <td class="itemStatus">{{ item.title }}</td>
          <td class="itemStatus">{{ item.parentTitle }}</td>
          <td class="itemStatus">{{ item.parent2Title }}</td>
          <td :class="'itemStatus itemStatus' + item.status">{{ item.status == 1 ? '已发布' : '未发布' }}</td>
          <td class="action">
            <a v-if="item.status==1" class="nopublish" href="javascript:;" @click="unpublish(item.id)">取消发布</a>
            <a v-if="item.status==0" class="publish" href="javascript:;" @click="publish(item.id)">发布</a>
            <a class="edit" href="javascript:;" @click="querySingle(item.id)">编辑</a>
            <a class="delete" href="javascript:;" @click="askDel(item.id)">删除</a>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="noitems" v-if="tableData.length == 0">没有数据</div>
    </div>

    <pagination :cur="pageInfo.page" :all="pageInfo.all" :totalData="pageInfo.totalData"
                :callback="pageCallback"></pagination>

    <div class="form_overlay" id="form_overlay" :style="classShowAddWindow">
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">添加/编辑专业</div>
        <div class="form_content">
          <form id="itemForm">
            <div class="item">
              <span class="label"><i class="require">*</i>级别:</span>
              <input type="hidden" v-model="formInfo.id">
              <select name="grade" v-model="formInfo.grade">
                <option value="">选择级别</option>
                <option value="1">领域</option>
                <option value="2">专业类</option>
                <option value="3">适用专业</option>
              </select>
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>专业编码:</span>
              <input type="text" name="code" v-model="formInfo.code">
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>专业名称:</span>
              <input type="text" name="title" v-model="formInfo.title">
            </div>
            <div :class="['2', '3'].indexOf(formInfo.grade) > -1 ? 'item' : 'item hide'" id="parentBox">
              <span class="label"><i class="require">*</i>所属领域:</span>
              <select name="parent" v-model="formInfo.parent" @change="getCategory">
                <option v-for="item in dictDomain" :key="item.id" :value="item.code">{{ item.title }}</option>
              </select>
            </div>
            <div :class="['3'].indexOf(formInfo.grade) > -1 && formInfo.parent != ''? 'item' : 'item hide'"
                 id="parent2Box">
              <span class="label"><i class="require">*</i>所属专业类:</span>
              <select name="parent2" v-model="formInfo.parent2">
                <option v-for="item in dictCategory" :key="item.id" :value="item.code">{{ item.title }}</option>
              </select>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a href="javascript:;" class="button close" id="form_close" @click="hideAddWindow">取消</a>
          <a href="javascript:;" class="button" id="form_submit" @click="saveInfo">提交</a>
        </div>
      </div>
    </div>

    <div class="form_overlay" :style="classShowUploadWindow">
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">批量上传</div>
        <div class="form_content">
          <form id="uploadForm">
            <div class="item">
              <span class="label" style="width: auto"><i class="require"></i>模版创建规则:</span>
              <div class="item-box divLabel gray">
                <div class="normal-width">
                  <p>1. 文件格式为“逗号分隔”的CSV文件；</p>
                  <p>2. 文件编码必须为ANSI编码；</p>
                  <p>3. 上传文档转换完毕后，请使用记事本打开，查看是否含有空数据，如果含有空数据，则需删除；</p>
                  <p>4. 上传文档中内容不能含有西文逗号；</p>
                  <p>5. CSV文件生成，可使用excel另存为“逗号分隔的CSV文件”；</p>
                  <p>6. 文件编码，可使用记事本打开CSV，另存为ANSI编码。</p>
                </div>
              </div>
              <div class="clear"></div>
              <div class="attachment">
                <a href="/static/upload_template/batch_upload_categories_template.csv" download="batch_upload_categories_template.csv" target="_blank">模版下载</a>
              </div>
            </div>
            <div class="item">
              <span class="label" style="width: auto"><i class="require">*</i>CSV文件:</span>
              <div class="item-box" style="margin-left: 130px;">
                <input name="file" v-model="uploadedCsv" disabled type="text" id="fileUrl" style="width: 225px;">
                <span class="button" style="position: relative;top: 0px; left: 0px;">
                  选择文件
                  <input @change="xlsChange" type="file" ref="xlsFile" id="fileUpload" class="upload-derict">
                </span>
              </div>
              <div class="clear"></div>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a href="javascript:;" class="button close" id="form_upload_close" @click="hideUploadWindow">取消</a>
          <a href="javascript:;" class="button" id="form_upload_submit" @click="submitCsv">提交</a>
        </div>
      </div>
    </div>

    <div id="dialog_overlay" :style="classShowProcessWindow">
      <div id="dialog_bg"></div>
      <div id="dialog_panel" ref="refErrorList" style="margin-left: -136px; margin-top: -54px;max-width: 300px;">
        <div id="dialog_panel_bg"></div>
        <div id="dialog_title">提示</div>
        <div id="dialog_content">
          ({{ curCsvIndex }} / {{ uploadedCscData.length }})
          <span style="color: #219d2b">
            {{ ' ' + csvFinishText }}
          </span>
        </div>
        <div id="dialog_content2">
          <ul>
            <li v-for="(item, index) in errorList" v-bind:key="index">
              <span :class="item.code == 1 ? 'success' : 'fail'">{{ item.text }}</span>
            </li>
          </ul>

        </div>
        <div id="dialog_control">
          <a href="javascript:void(0)" id="dialog_ok" class="button col2" @click="classShowProcessWindow={}"
             :style="isFinishUpsert ? 'display: inline-block' : 'display:none'">确认</a>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import Pagination from "../../components/Pagination";

export default {
  name: "AdminCategories",
  components: {
    Pagination
  },
  data() {
    return {
      dictDomain: [],
      dictCategory: [],
      queryInfo: {
        page: 1,
        size: 10,
        status: '',
        grade: '',
        code: '',
        title: ''
      },
      formInfo: {
        id: 0,
        grade: '',
        code: '',
        title: '',
        parent: '',
        parentTitle: '',
        parent2: '',
        parent2Title: ''
      },
      classShowAddWindow: {},
      tableData: [],
      tableDataCount: 0,
      classShowUploadWindow: {},
      uploadedCsv: '',
      uploadedCscData: [],
      classShowProcessWindow: {},
      errorList: [],
      isFinishUpsert: false,
      curCsvIndex: 0,
      csvFinishText: ''
    }
  },
  computed: {
    pageInfo() {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    }
  },
  methods: {
    async getDomain() {
      await this.$http.get('/categories/', {
        params: {
          page: 1,
          size: 1000000,
          grade: '1',
          status: 1,
          isdel: 0
        }
      }).then(async (data) => {
        console.log(data)
        this.dictDomain = data.results
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg('获取领域信息失败！')
      })
    },
    async getCategory() {
      await this.$http.get('/categories/', {
        params: {
          page: 1,
          size: 1000000,
          parent: this.formInfo.parent,
          grade: '2',
          status: 1,
          isdel: 0
        }
      }).then(async (data) => {
        console.log(data)
        this.dictCategory = data.results
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg('获取专业类信息失败！')
      })
    },
    showAddWindow() {
      this.classShowAddWindow = {
        visibility: 'visible'
      }
    },
    hideAddWindow() {
      this.classShowAddWindow = {}
      this.formInfo = {
        id: 0,
        grade: '',
        code: '',
        title: '',
        parent: '',
        parentTitle: '',
        parent2: '',
        parent2Title: ''
      }
    },
    async saveInfo() {
      if(!this.formInfo.grade){
        this.$parent.$parent.showMsg("请选择级别!")
        return false
      }

      if(!this.formInfo.code){
        this.$parent.$parent.showMsg("请输入专业编码!")
        return false
      }
      if(!this.formInfo.title){
        this.$parent.$parent.showMsg("请输入专业名称!")
        return false
      }

      if (this.formInfo.grade == "2" || this.formInfo.grade == "3") {
        if (!this.formInfo.parent){
          this.$parent.$parent.showMsg("请选择所属领域!")
          return false
        }
      }

      if (this.formInfo.grade == "3") {
        if (!this.formInfo.parent2){
          this.$parent.$parent.showMsg("请选择所属专业类!")
          return false
        }
      }

      if (this.formInfo.grade == "1") {
        this.formInfo.parent = ""
        this.formInfo.parentTitle = ""
        this.formInfo.parent2 = ""
        this.formInfo.parent2Title = ""
      } else if (this.formInfo.grade == "2") {
        for (let i = 0; i < this.dictDomain.length; i++) {
          if (this.formInfo.parent == this.dictDomain[i].code.toString()) {
            this.formInfo.parentTitle = this.dictDomain[i].title
            break
          }
        }
        this.formInfo.parent2 = ""
        this.formInfo.parent2Title = ""
      } else if (this.formInfo.grade == "3") {
        for (let i = 0; i < this.dictDomain.length; i++) {
          if (this.formInfo.parent == this.dictDomain[i].code.toString()) {
            this.formInfo.parentTitle = this.dictDomain[i].title
            break
          }
        }
        for (let i = 0; i < this.dictCategory.length; i++) {
          if (this.formInfo.parent2 == this.dictCategory[i].code.toString()) {
            this.formInfo.parent2Title = this.dictCategory[i].title
            break
          }
        }
      } else {
        this.$parent.$parent.showMsg('请选择级别！')
        return
      }

      if (this.formInfo.id) {
        let url = '/categories/' + this.formInfo.id + '/'
        await this.$http.put(url, this.formInfo).then((data) => {
          if (data.id) {
            this.$parent.$parent.showMsg('修改成功！')
            this.hideAddWindow()
            this.search()
            this.getDomain()
          } else {
            this.$parent.$parent.showMsg('修改失败！')
          }
        }).catch((error) => {
          console.log(error)
          this.$parent.$parent.showMsg('修改失败！')
        })
      } else {
        await this.$http.post('/categories/', this.formInfo).then((data) => {
          if (data.id) {
            this.$parent.$parent.showMsg('添加成功！')
            this.hideAddWindow()
            this.search()
            this.getDomain()
          } else {
            this.$parent.$parent.showMsg('添加失败！')
          }
        }).catch((error) => {
          console.log(error)
          this.$parent.$parent.showMsg('添加失败！')
        })
      }


    },
    async search() {
      await this.$http.get('/categories/', {
        params: this.queryInfo
      }).then((data) => {
        console.log(data)
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$parent.$parent.showMsg('获取专业体系信息失败！')
      })
    },
    async pageCallback(data) {
      this.queryInfo.page = data
      await this.search()
    },
    async changePublishState(id, status) {
      const msg = status == 0 ? "取消" : ""
      const url = '/categories/' + id + '/'
      await this.$http.put(url, {
        status: status
      }).then((data) => {
        console.log(data)
        this.$parent.$parent.showMsg(msg + '发布成功！');
        this.getDomain()
        this.search()
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg(msg + '发布失败！');
      })
    },
    unpublish(id) {
      console.log(id)
      this.$parent.$parent.showConfirm('确定要取消发布吗？', () => {
        this.changePublishState(id, 0)
      });
    },
    publish(id) {
      console.log(id)
      this.$parent.$parent.showConfirm('确定要发布吗？', () => {
        this.changePublishState(id, 1)
      });
    },
    async delRow(id) {
      const url = '/categories/' + id + '/'
      await this.$http.put(url, {
        isdel: 1
      }).then((data) => {
        console.log(data)
        this.$parent.$parent.showMsg('删除成功！');
        this.search()
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg('删除失败！');
      })
    },
    askDel(id) {
      this.$parent.$parent.showConfirm('确定要删除吗？', () => {
        this.delRow(id)
      });
    },
    async querySingle(id) {
      const url = '/categories/' + id + '/'
      await this.$http.get(url).then(async (data) => {
        console.log(data)
        this.formInfo = {
          id: id,
          grade: data.grade,
          code: data.code,
          title: data.title,
        }

        if (data.parent) {
          await this.$http.get('/categories/', {
            params: {
              page: 1,
              size: 1000000,
              grade: '1',
              status: 1,
              isdel: 0
            }
          }).then(async (domain_data) => {
            this.dictDomain = domain_data.results
            this.formInfo.parent = domain_data.parent
            this.formInfo.parentTitle = domain_data.parentTitle

            this.formInfo.parent = data.parent;
            this.formInfo.parentTitle = data.parentTitle;
            if (data.parent2) {
              await this.$http.get('/categories/', {
                params: {
                  page: 1,
                  size: 1000000,
                  parent: this.formInfo.parent,
                  grade: '2',
                  status: 1,
                  isdel: 0
                }
              }).then(async (category_data) => {
                console.log(category_data)
                this.dictCategory = category_data.results

                this.formInfo.parent2 = data.parent2;
              }).catch((error) => {
                console.log(error)
                this.$parent.$parent.showMsg('获取专业类信息失败！')
              })
            }

          }).catch((error) => {
            console.log(error)
            this.$parent.$parent.showMsg('获取领域信息失败！')
          })
        }

        this.showAddWindow()
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg('数据获取失败！');
      })
    },
    showUploadWindow() {
      this.classShowUploadWindow = {
        visibility: 'visible'
      }
    },
    hideUploadWindow() {
      this.classShowUploadWindow = {}
    },
    async xlsChange(){
      const uploadUrl = '/files/' + this.$refs.xlsFile.files[0].name
      await this.$http.put(uploadUrl, this.$refs.xlsFile.files[0]).then((data) => {
        console.log(data)
        this.uploadedCsv = data
      }).catch(() => {
        this.$parent.$parent.showMsg('上传失败！')
      })
    },
    async submitCsv() {
      const uploadUrl = '/get_csv/'
      await this.$http.get(uploadUrl, {
        params: {
          'file_name': this.uploadedCsv
        }
      }).then((data) => {
        console.log(data)

        if(data.length <= 1){
          this.$parent.$parent.showMsg('表格中无数据！')
          return;
        }

        data.splice(0, 1)

        this.uploadedCscData = data

        this.hideUploadWindow()

        this.classShowProcessWindow = {
          visibility: 'visible'
        }
        this.isFinishUpsert = false
        this.curCsvIndex = 0
        this.csvFinishText = ''
        this.errorList = []
        this.insertOne()

      }).catch(() => {
        this.$parent.$parent.showMsg('读取失败！')
      })
    },
    async insertOne(){

      console.log(this.uploadedCscData)

      if(this.curCsvIndex >= this.uploadedCscData.length) {
        this.csvFinishText = '上传完成'
        this.isFinishUpsert = true
        this.search()
        return;
      }

      let row = this.uploadedCscData[this.curCsvIndex]

      // region 数据检查开始

      if (row[0].trim().length == 0) {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '请输入级别！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      if (row[1].trim().length == 0) {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '请输入编码！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      if (row[2].trim().length == 0) {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '请输入名称！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      // endregion 数据检查结束

      let addForm = {
        grade: row[0],
        code: row[1],
        title: row[2],
        parent: row[1],
        parentTitle: row[2],
        parent2: row[1],
        parent2Title: row[2],
        status: 1
      }

      console.log(addForm)

      await this.$http.post('/categories/',addForm).then(async (data) => {
        console.log(data)
        this.errorList.push({ code : 1, text: '[' + addForm.title + ']' +"导入成功！" })
        this.curCsvIndex++
        this.insertOne()

      }).catch((error) => {
        console.dir(error)
        this.errorList.push({ code : 0, text: '[' + addForm.title + ']' + error.response.data[0] })
        this.curCsvIndex++
        this.insertOne()
      })
    }
  },
  mounted() {
    this.getDomain()
    this.search()
  }
}
</script>

<style scoped>
/* .form_panel {
  top: 150px !important;
} */

#dialog_content2 {
  max-height: 100px;
  overflow-y: auto;
  border-top: 1px solid #ddd;
}

#dialog_content2 .success {
  color: #aaa;
}

#dialog_content2 .fail {
  color: #e33;
}
</style>
