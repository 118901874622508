<template>
  <div
    id="panel"
    class="panel"
    style="min-height: 696px;"
  >
    <form
      id="searchSection"
      onsubmit="return false;"
    >
      <input
        type="text"
        name="title"
        placeholder="单位名称"
        class="s-input separate"
        v-model="queryInfo.super_title"
      >
      <input
        type="text"
        name="title"
        placeholder="联系人"
        class="s-input separate"
        v-model="queryInfo.contacts"
      >
      <input
        type="date"
        name="date"
        placeholder="日期"
        class="date_box"
        v-model="queryInfo.super_view_submit_time"
      >
      <input
        type="button"
        class="s-btn"
        value="搜索"
        @click="queryInfo.page=1,search()"
      >
      <div class="search-line clearfix">
      </div>
    </form>
    <ul class="action-btn">
      <li
        :class="queryInfo.super_view_status=='1,2,3'?'active':''"
        @click="queryInfo.super_view_status='1,2,3',search()"
      >全部</li>
      <li
        :class="queryInfo.super_view_status==1?'active':''"
        @click="queryInfo.super_view_status='1',search()"
      >待审核</li>
      <li
        :class="queryInfo.super_view_status==2?'active':''"
        @click="queryInfo.super_view_status='2',search()"
      >已通过</li>
      <li
        :class="queryInfo.super_view_status==3?'active':''"
        @click="queryInfo.super_view_status='3',search()"
      >未通过</li>
    </ul>
    <div id="list">
      <table v-if="this.tableData.length > 0">
        <tbody>
          <tr class="l_header">
            <th class="itemStatus">单位名称</th>
            <th class="itemStatus">联系人</th>
            <th class="itemStatus">电话</th>
            <th class="itemStatus">邮箱</th>
            <th class="itemStatus">提交时间</th>
            <th class="itemStatus">状态</th>
            <th
              class="action"
              width="180"
            >操作</th>
          </tr>
          <tr
            class="item"
            v-for="item in tableData"
            :key="item.id"
          >
            <td class="itemStatus">{{ item.super_title }}</td>
            <td class="itemStatus">{{ item.super_connect }}</td>
            <td class="itemStatus">{{ item.super_phone }}</td>
            <td class="itemStatus">{{ item.super_email }}</td>
            <td class="itemStatus">{{ dateFormat('YYYY-mm-dd',item.super_view_submit_time) }}</td>
            <td class="itemStatus">{{formatType(item.super_view_status) }}</td>
            <td class="action">
              <a
                class="publish"
                href="javascript:;"
                @click="ispPublish(item.id,'2')"
                v-if="item.super_view_status==1"
              >通过</a>
              <a
                class="see"
                @click="goTemplate(item.super_view_status,item.super_view_category,item.ownerId)"
              >查看</a>
              <a
                v-if="
                item.super_view_status==1"
                class="delete"
                href="javascript:;"
                @click="ispPublish(item.id,'3')"
              >驳回</a>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="noitems"
        v-if="tableData.length == 0"
      >没有数据</div>
    </div>

    <pagination
      :cur="pageInfo.page"
      :all="pageInfo.all"
      :totalData="pageInfo.totalData"
      :callback="pageCallback"
    ></pagination>

  </div>
</template>

<script>
import Pagination from "../../components/Pagination";
export default {
  // 可用组件的哈希表
  components: {Pagination},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      queryInfo: {
        super_title: '',
        page: 1,
        size: 10,
        super_view_status:'1,2,3',
        super_view_submit_time:'',
        contacts:''
      },
      tableData:[],
      tableDataCount:0,
      classShowAddWindow: {},
      classShowImageWindow: {},
      showImgPreview: false,
      uploaded_img: '',
    }
  },
  // 事件处理器
  methods: {
    formatType(val){
      if(val==='1'){
        return '待审核'
      }else if(val==='2'){
        return '通过'
      }else if(val==='3'){
        return '未通过'
      }
    },
    // 分页
    async pageCallback(data) {
      this.queryInfo.page = data
      await this.search()
    },
    // 打开新建弹窗
    showAddWindow() {
      this.classShowAddWindow = {
        visibility: 'visible'
      }
    },
    // 关闭新建弹窗
    hideAddWindow(){
      this.classShowAddWindow = {}
      this.formInfo = {}
    },
    // 打开上传图片弹窗
    showImageWindow() {
      this.classShowImageWindow = {
        visibility: 'visible'
      }
    },
    // 关闭上传图片弹窗
    hideImageWindow() {
      this.classShowImageWindow = {}
    },
    confirmImage() {
      this.formInfo.picPath = this.uploaded_img
      this.hideImageWindow()
    },
    // 日期格式转换
    dateFormat(fmt, date) {
      let ret="";
      date=new Date(date);
      const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString() // 秒
      }
      for (let k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0')
          )
        }
      }
      return fmt
    },

    // 搜索
    async search() {
      await this.$http.get('/application/', {
        params: this.queryInfo
      }).then((data) => {
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$parent.$parent.showMsg('获取会议信息失败！')
      })
    },
    async ispPublish(id,val){
       const url='/application/'+id+'/'
      await this.$http.put(url, {
        super_view_status:val,
      }).then(() => {
        this.search()
      }).catch(() => {
      })
    },
   
 
    getFormat(time){
      var format = "";
      var nTime = new Date(time);
      format += nTime.getFullYear()+"-";
      format += (nTime.getMonth()+1)<10?"0"+(nTime.getMonth()+1):(nTime.getMonth()+1);
      format += "-";
      format += nTime.getDate()<10?"0"+(nTime.getDate()):(nTime.getDate());
      // format += "T";
      // format += nTime.getHours()<10?"0"+(nTime.getHours()):(nTime.getHours());
      // format += ":";
      // format += nTime.getMinutes()<10?"0"+(nTime.getMinutes()):(nTime.getMinutes());
      // format += ":00";
      return format;
    },
    goTemplate(super_view_status,super_view_category, ownerId) {
        var oUrl = ''
        oUrl = super_view_category == 1 ? '/www/template_one' : super_view_category == 2 ? '/www/template_two' : '/www/template_three'
        window.open(oUrl+'?ownerId=' + ownerId, '_blank')
    }
   
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () {
    this.search()
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {
    pageInfo() {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    }
  },
  // 自定义的侦听器
  watch: {}
}
</script>

<style scoped>
.date_box {
  border: 1px solid #e6e6e6;
  background-color: #f5f7fc;
  width: 150px;
  height: 32px;
  line-height: 32px;
  margin-right: 8px;
  padding-left: 8px;
}
.tips {
  position: relative;
  left: 110px;
  font-size: 12px;
  color: #aaaaaa;
}
.tips_Hover {
  top: 30px;
  height: 50px;
}
.previewImg {
  position: relative;
  left: -105px;
  top: 40px;
  width: 180px;
  height: 120px;
}
.action-btn {
  padding-top: 10px;
  border-top: 1px solid #eee;
}
.action-btn li {
  display: inline-block;
  padding: 5px 20px;
  background: #ccc;
  margin-right: 20px;
  color: #fff;
  cursor: pointer;
}
.action-btn li.active {
  background: #4976e8;
}
.see {
  cursor: pointer;
}
</style>
