<template>
  <div
    id="panel"
    class="panel"
    style="min-height: 696px;"
  >

    <ul class="list-type">
      <li>
        <i class="iconfont icon-qiyehuiyuanzongshu"></i>
        <div>
          <span>{{ statisticsData.school_count }}</span>
          <span>高校会员总数</span>
        </div>
      </li>
      <li>
        <i class="iconfont icon-qiyehuiyuanzongshu"></i>
        <div>
          <span>{{ statisticsData.department_count }}</span>
          <span>学院会员总数</span>
        </div>
      </li>
      <li>
        <i class="iconfont icon-daishenhe"></i>
        <div>
          <span>{{ statisticsData.apply_count }}</span>
          <span>待审核数</span>
        </div>
      </li>
      <li>
        <i class="iconfont icon-yitongguo"></i>
        <div>
          <span>{{ statisticsData.pass_count }}</span>
          <span>已通过数</span>
        </div>
      </li>
    </ul>

    <form
      id="searchSection"
      onsubmit="return false;"
    >
      <div class="search-line clearfix">
        <select
          v-model="queryInfo.type"
          name="type"
          class="s-select separate"
        >
          <option value="1, 3">选择申请类型</option>
          <option value="1">高校会员</option>
          <option value="3">学院会员</option>
        </select>

        <select
          v-model="queryInfo.status"
          name="status"
          class="s-select separate"
        >
          <option value="">选择状态</option>
          <!--          <option value="1">初审中</option>-->
          <option value="1">审核中</option>
          <option value="5">已通过</option>
          <option value="2">驳回</option>
        </select>
        <input
          v-model="queryInfo.like_title"
          type="text"
          name="title"
          class="s-input separate"
          placeholder="单位名称"
        >

        <input
          type="submit"
          class="s-btn"
          value="搜索"
          @click="search"
        >

        <div
          class="import-btn btncolor"
          id="batchUpload"
          @click="showExportWindow"
        >导出</div>
      </div>

      <div class="search-line clearfix">

      </div>
    </form>
    <div id="list">
      <table v-if="this.tableData.length > 0">
        <tbody>
          <tr class="l_header">
            <th class="itemTitle">单位名称</th>
            <th class="itemTitle">院系名称</th>
            <th class="itemTitle">主要联系人</th>
            <th class="itemTitle">电话</th>
            <th class="itemCategory">申请时间</th>
            <th class="itemTitle">申请类型</th>
            <th class="itemCategory">状态</th>
            <th
              class="action"
              width="155"
            >操作</th>
          </tr>
          <tr
            class="item"
            v-for="item in tableData"
            :key="item.id"
          >
            <td class="itemTitle">
              <a
                class="a-link"
                :href="'/www/appview?id=' + item.id"
                target="_blank"
              >
                {{ item.type == 3 ? item.schoolTitle : item.title }}
              </a>
            </td>
            <td class="itemTitle">{{ item.type == 3 ? item.title : '-' }}</td>
            <td class="itemTitle">{{ item.contacts }}</td>
            <td class="itemTitle">{{ item.phone2 }}</td>
            <td class="itemTitle">{{ item.created.substr(0, 10) }}</td>
            <td
              class="itemTitle"
              v-if="item.type == 1"
            >高校会员</td>
            <td
              class="itemTitle"
              v-else-if="item.type == 2"
            >企业会员</td>
            <td
              class="itemTitle"
              v-else-if="item.type == 3"
            >学院会员</td>
            <td
              class="itemTitle"
              v-else
            ></td>

            <td
              v-if="item.isdel == 1"
              class="itemTitle itemStatus2"
            >已删除</td>
            <td
              v-else-if="item.status == 1"
              class="itemTitle itemStatus3"
            >审核中</td>
            <td
              v-else-if="item.status == 3"
              class="itemTitle itemStatus3"
            >审核中</td>
            <td
              v-else-if="item.status == 5"
              class="itemTitle itemStatus1"
            >已通过</td>
            <td
              v-else-if="item.status == 2"
              class="itemTitle itemStatus0"
            >驳回</td>
            <td
              v-else
              class="itemTitle "
            ></td>

            <td
              class="action"
              style="font-size:12px"
            >
              <!--            <a v-if="item.status == 1" class="publish" href="javascript:;" @click="updateStatus(item.id, 3)">初审通过</a>-->
              <a
                v-if="item.status == 1 || item.status == 3"
                class="publish"
                href="javascript:;"
                @click="updateStatus(item.id, 5)"
              >通过</a>
              <a
                v-if="item.status == 1 || item.status == 3 || item.status == 5"
                class="publish"
                href="javascript:;"
                @click="sendMessage(item.phone2)"
              >发送短信</a>
              <!-- <br> -->
              <a
                v-if="item.status == 1 || item.status == 3"
                class="delete"
                href="javascript:;"
                @click="showReject(item.id)"
              >驳回</a>
              <a
                class="see"
                :href="'/www/appview?id=' + item.id"
                target="_blank"
              >查看</a>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="noitems"
        v-if="tableData.length == 0"
      >没有数据</div>
    </div>

    <pagination
      :cur="pageInfo.page"
      :all="pageInfo.all"
      :totalData="pageInfo.totalData"
      :callback="pageCallback"
    ></pagination>

    <div
      class="form_overlay"
      id="applyReject_overlay"
      :style="classApplyRejectShow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">申请驳回</div>
        <div class="form_content">
          <form id="applyRejectForm">
            <div class="item">
              <span class="label"><i class="require"></i>驳回理由</span>
              <textarea
                class=""
                type="text"
                name="rejectReason"
                v-model="rejectForm.rejectReason"
                style="width: 350px;height: 144px;resize: none;"
              ></textarea>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a
            href="javascript:;"
            class="button close"
            id="applyReject_close"
            @click="classApplyRejectShow = { visibility: 'hidden' }"
          >取消</a>
          <a
            href="javascript:;"
            class="button"
            id="applyReject_submit"
            @click="doReject"
          >提交</a>
        </div>
      </div>
    </div>

    <div
      class="form_overlay"
      id="form_overlay"
      :style="classShowemExportWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">信息导出</div>
        <div class="form_content">
          <form id="itemForm">
            <div class="item">
              <span class="label">申请开始时间:</span>
              <input
                type="date"
                name="foundDate"
                v-model="exportParam.created_start"
                style="width: 300px;height: 30px;border: 1px solid #ddd;"
              >
            </div>
            <div class="item">
              <span class="label">申请结束时间:</span>
              <input
                type="date"
                name="foundDate"
                v-model="exportParam.created_end"
                style="width: 300px;height: 30px;border: 1px solid #ddd;"
                placeholder="adas"
              >
            </div>
          </form>
        </div>
        <div class="form_control">
          <a
            href="javascript:;"
            class="button close"
            id="form_close"
            @click="hideExporWindow()"
          >取消</a>
          <a
            href="javascript:;"
            class="button"
            id="form_submit"
            @click="exportMember"
          >导出</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import outputTable from "../../assets/basic/outputTable.js"
import Pagination from "../../components/Pagination"

export default {
  name: 'AdminApply',
  components: {
    Pagination
  },
  data() {
    return {
      classApplyRejectShow: { visibility: 'hidden' },
      rejectForm: {
        id: 0,
        rejectReason: '',
      },
      queryInfo: {
        page: 1,
        size: 10,
        like_title: '',
        status: '',
        type: '1, 3',
      },
      classShowemExportWindow:{},
      tableData: [],
      tableDataCount: 0,
      exportParam:{
        page: 1,
        size: 10000,
        created_start:'',
        created_end:'',
        type: '1,3',
      },
      statisticsData: {
        school_count: 0,
        department_count: 0,
        apply_count: 0,
        pass_count: 0
      }
    }
  },
  computed: {
    pageInfo() {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    }
  },
  methods: {
    async search() {
      await this.getStatistics()

      await this.$http.get('/application/', {
        params: this.queryInfo
      }).then((data) => {
        console.log(data)
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$parent.$parent.showMsg('获取通知信息失败！')
      })
    },

    async updateStatus(id, statusValue, showMsgTag=true) {
      const url = '/application/' + id + '/'
      await this.$http.put(url, {
        status: statusValue
      }).then(async (data) => {
        console.log(data)
        console.log(data.type)
        if(data.type == 1){
          console.log(123456)

          // region 学校 修改下属院系状态
          await this.$http.get('/application/', {
            params: {
              schoolId: id
            }
          }).then(async (yx_data) => {
            console.log('下属院系')
            console.log(yx_data)

            // if(yx_data.results.length > 0){
            //   for(let i=0;i<yx_data.results.length;i++){
            //     await this.updateStatus(yx_data.results[i].id, statusValue, false)
            //   }
            // }
            this.$layer.msg('操作成功！');
            this.search()
          }).catch((error) => {
            console.log(error)
            this.$layer.msg('操作失败！');
          })
          // endregion
        }else{
          // 修改企业 院系 直接提示操作结果
          if(showMsgTag){
            this.$layer.msg('操作成功！');
          }
          this.search()
        }
      }).catch((error) => {
        console.log(error)
        this.$layer.msg('操作失败！');
      })
    },
    async pageCallback(data) {
      this.queryInfo.page = data
      await this.search()
    },
    async showReject(id){
      this.rejectForm = {
        id: id,
        rejectReason: '',
      }
      this.classApplyRejectShow = {
        visibility: 'visible'
      }
    },
    async doReject(){
      const url = '/application/' + this.rejectForm.id + '/'
      await this.$http.put(url, {
        status: 2,
        rejectReason: this.rejectForm.rejectReason
      }).then(async (data) => {
        console.log(data)

        if(data.type == 1){

          // region 学校 修改下属院系状态
          await this.$http.get('/application/', {
            params: {
              schoolId: this.rejectForm.id
            }
          }).then(async (yx_data) => {
            console.log('下属院系')
            console.log(yx_data)

            // if(yx_data.results.length > 0){
            //   for(let i=0;i<yx_data.results.length;i++){

            //     await this.$http.put('/application/' + yx_data.results[i].id + '/', {
            //       status: 2,
            //       rejectReason: this.rejectForm.rejectReason
            //     }).then(async (data) => {
            //       console.log(data)
            //     }).catch((error) => {
            //       console.log(error)
            //     })

            //   }
            // }
            this.classApplyRejectShow = { visibility: 'hidden' }
            this.$layer.msg('操作成功！');
            this.search()
          }).catch((error) => {
            console.log(error)
            this.$layer.msg('操作失败！');
          })
          // endregion
        }else{
          // 修改企业 院系 直接提示操作结果
          this.classApplyRejectShow = { visibility: 'hidden' }
          this.$layer.msg('操作成功！');
          this.search()
        }

        this.classApplyRejectShow = { visibility: 'hidden' }
        // await this.search()
      }).catch((error) => {
        console.log(error)
        this.$layer.msg('操作失败！');
      })
    },
    async sendMessage(phone) {
      if (!phone){
        this.$layer.msg("未绑定手机号码！")
        return false;
      }
      let mobileReg = /^1[0-9]{10}$/;
      if (!mobileReg.test(phone)) {
        this.$layer.msg('手机号码不正确！')
        return false;
      }
      await this.$http.get('/verify_code/', {
        params: {
          phone: phone,
          type: 4 // 1 注册 2 忘记密码 3 绑定手机 4 会员审核通过提醒
        }
      }).then((data) => {
        console.log(data)
        if(parseInt(data.res) >= 0){
          this.$layer.msg('发送成功！')
        } else {
          this.$layer.msg('发送失败！')
        }
      }).catch((error) => {
        console.log(error)
        this.$layer.msg('发送失败！')
      })
    },
    showExportWindow() {
      this.classShowemExportWindow = {visibility: 'visible'}
    },
    hideExporWindow() {
      this.classShowemExportWindow = {}
      this.exportParam.created_start = ''
      this.exportParam.created_end = ''
    },
    // 导出查询列表
    async exportMember() {
      if(!this.exportParam.created_start){
        this.$layer.msg('请选择申请开始时间')
        return
      }
      if(!this.exportParam.created_end){
        this.$layer.msg('请选择申请结束时间')
        return
      }
      await this.$http.get('/application/', {
        params: {
          page: 1,
          size: 10000,
          created_start:this.exportParam.created_start,
          created_end:this.exportParam.created_end+'T23:59:59',
          type: '1,3'
        }
      }).then((data) => {
        let exportData = data.results

        let exportArray = [
          {
            schoolTitle:'单位名称',
            title:'院系名称',

            address: '通讯地址',
            postcode: '邮编',

            mainPrincipal: '学校主管领导',
            mpjobTitle: '职务职称',
            phone1: '办公电话',
            mobile1: '手机',
            email1: '邮箱',

            respDepart: '责任部门',
            principal: '负责人',
            pjobTitle: '职务职称',
            mobile2: '手机',
            email3: '邮箱',

            contacts:'主要联系人',
            phone2:'手机',
            email2:'邮箱',

            type:'申请类型',
            created:'申请时间',
            isPub:'状态',
            category_1: '基础领域',
            category_1_connect: '联系人',
            category_1_phone: '联系电话',
            category_8: '医学领域',
            category_8_connect: '联系人',
            category_8_phone: '联系电话',
            category_4: '机械领域',
            category_4_connect: '联系人',
            category_4_phone: '联系电话',
            category_3: '信息领域',
            category_3_connect: '联系人',
            category_3_phone: '联系电话',
            category_2: '土建领域',
            category_2_connect: '联系人',
            category_2_phone: '联系电话',
            category_5: '化环领域',
            category_5_connect: '联系人',
            category_5_phone: '联系电话',
            category_7: '文科领域',
            category_7_connect: '联系人',
            category_7_phone: '联系电话',
            category_6: '生物领域',
            category_6_connect: '联系人',
            category_6_phone: '联系电话',
            category_100: '参与专业大类领域',
            category_100_connect: '联系人',
            category_100_phone: '联系电话'
          }
        ]
        if(exportData.length==0){
          this.$parent.$parent.showMsg('暂无信息可导出！')
          return false
        } else{
          exportData.forEach(item => {
            let exportRow = {
              schoolTitle:item.type == 3 ? item.schoolTitle : item.title,
              title:item.type == 3 ? item.title : '-',

              address: item.address,
              postcode: item.postcode,

              mainPrincipal: item.mainPrincipal,
              mpjobTitle: item.mpjobTitle,
              phone1: item.phone1,
              mobile1: item.mobile1,
              email1: item.email1,

              respDepart: item.respDepart,
              principal: item.principal,
              pjobTitle: item.pjobTitle,
              mobile2: item.mobile2,
              email3: item.email3,

              contacts:item.contacts,
              phone2:item.phone2,
              email2:item.email2,

              type:item.type == 1?'高校会员':item.type == 2?'企业会员':'学院会员',
              created:item.created.substr(0, 10),
              isPub:item.isPub == 1?'已发布':'未发布',
              category_1: '',
              category_1_connect: '',
              category_1_phone: '',
              category_8: '',
              category_8_connect: '',
              category_8_phone: '',
              category_4: '',
              category_4_connect: '',
              category_4_phone: '',
              category_3: '',
              category_3_connect: '',
              category_3_phone: '',
              category_2: '',
              category_2_connect: '',
              category_2_phone: '',
              category_5: '',
              category_5_connect: '',
              category_5_phone: '',
              category_7: '',
              category_7_connect: '',
              category_7_phone: '',
              category_6: '',
              category_6_connect: '',
              category_6_phone: '',
              category_100: '',
              category_100_connect: '',
              category_100_phone: ''
            }

            let subject = item.subjectIntention ? JSON.parse(item.subjectIntention) : null

            if (subject) {
              for (let i = 0; i < subject.connect.length; i++) {
                if (subject.connect[i].title === '基础类') {
                  exportRow.category_1_connect = subject.connect[i].connect
                  exportRow.category_1_phone = subject.connect[i].phone
                }
                if (subject.connect[i].title === '土建类') {
                  exportRow.category_2_connect = subject.connect[i].connect
                  exportRow.category_2_phone = subject.connect[i].phone
                }
                if (subject.connect[i].title === '信息类') {
                  exportRow.category_3_connect = subject.connect[i].connect
                  exportRow.category_3_phone = subject.connect[i].phone
                }
                if (subject.connect[i].title === '机械类') {
                  exportRow.category_4_connect = subject.connect[i].connect
                  exportRow.category_4_phone = subject.connect[i].phone
                }
                if (subject.connect[i].title === '化环类') {
                  exportRow.category_5_connect = subject.connect[i].connect
                  exportRow.category_5_phone = subject.connect[i].phone
                }
                if (subject.connect[i].title === '生物类') {
                  exportRow.category_6_connect = subject.connect[i].connect
                  exportRow.category_6_phone = subject.connect[i].phone
                }
                if (subject.connect[i].title === '文科类') {
                  exportRow.category_7_connect = subject.connect[i].connect
                  exportRow.category_7_phone = subject.connect[i].phone
                }
                if (subject.connect[i].title === '医学类') {
                  exportRow.category_8_connect = subject.connect[i].connect
                  exportRow.category_8_phone = subject.connect[i].phone
                }
              }

              if (subject.checked['0'] === 1) {
                exportRow.category_1 += exportRow.category_1.length === 0 ? "": "，"
                exportRow.category_1 += "物理学类"
              }
              if (subject.checked['1'] === 1) {
                exportRow.category_1 += exportRow.category_1.length === 0 ? "": "，"
                exportRow.category_1 += "化学类"
              }
              // if (subject.checked['2'] === 1) {
              //   exportRow.category_1 += exportRow.category_1.length === 0 ? "": "，"
              //   exportRow.category_1 += "天文"
              // }
              if (subject.checked['3'] === 1) {
                exportRow.category_1 += exportRow.category_1.length === 0 ? "": "，"
                exportRow.category_1 += "地理科学类"
              }
              if (subject.checked['4'] === 1) {
                exportRow.category_1 += exportRow.category_1.length === 0 ? "": "，"
                exportRow.category_1 += "大气科学类"
              }
              if (subject.checked['5'] === 1) {
                exportRow.category_1 += exportRow.category_1.length === 0 ? "": "，"
                exportRow.category_1 += "海洋科学类"
              }
              if (subject.checked['6'] === 1) {
                exportRow.category_1 += exportRow.category_1.length === 0 ? "": "，"
                exportRow.category_1 += "地球物理学类"
              }
              if (subject.checked['7'] === 1) {
                exportRow.category_1 += exportRow.category_1.length === 0 ? "": "，"
                exportRow.category_1 += "地质学类"
              }
              // if (subject.checked['8'] === 1) {
              //   exportRow.category_1 += exportRow.category_1.length === 0 ? "": "，"
              //   exportRow.category_1 += "生物科学"
              // }
              if (subject.checked['9'] === 1) {
                exportRow.category_1 += exportRow.category_1.length === 0 ? "": "，"
                exportRow.category_1 += "心理学类"
              }

              if (subject.checked['51'] === 1) {
                exportRow.category_8 += exportRow.category_8.length === 0 ? "": "，"
                exportRow.category_8 += "基础医学类"
              }
              if (subject.checked['52'] === 1) {
                exportRow.category_8 += exportRow.category_8.length === 0 ? "": "，"
                exportRow.category_8 += "临床医学类"
              }
              if (subject.checked['53'] === 1) {
                exportRow.category_8 += exportRow.category_8.length === 0 ? "": "，"
                exportRow.category_8 += "公共卫生与预防医学类"
              }
              if (subject.checked['54'] === 1) {
                exportRow.category_8 += exportRow.category_8.length === 0 ? "": "，"
                exportRow.category_8 += "中医学类"
              }
              if (subject.checked['55'] === 1) {
                exportRow.category_8 += exportRow.category_8.length === 0 ? "": "，"
                exportRow.category_8 += "药学类"
              }
              if (subject.checked['56'] === 1) {
                exportRow.category_8 += exportRow.category_8.length === 0 ? "": "，"
                exportRow.category_8 += "法医学类"
              }
              if (subject.checked['57'] === 1) {
                exportRow.category_8 += exportRow.category_8.length === 0 ? "": "，"
                exportRow.category_8 += "医学技术类"
              }
              if (subject.checked['58'] === 1) {
                exportRow.category_8 += exportRow.category_8.length === 0 ? "": "，"
                exportRow.category_8 += "护理学类"
              }
              if (subject.checked['59'] === 1) {
                exportRow.category_8 += exportRow.category_8.length === 0 ? "": "，"
                exportRow.category_8 += "口腔医学类"
              }

              if (subject.checked['22'] === 1) {
                exportRow.category_4 += exportRow.category_4.length === 0 ? "": "，"
                exportRow.category_4 += "力学类"
              }
              if (subject.checked['23'] === 1) {
                exportRow.category_4 += exportRow.category_4.length === 0 ? "": "，"
                exportRow.category_4 += "机械类"
              }
              if (subject.checked['24'] === 1) {
                exportRow.category_4 += exportRow.category_4.length === 0 ? "": "，"
                exportRow.category_4 += "仪器类"
              }
              if (subject.checked['25'] === 1) {
                exportRow.category_4 += exportRow.category_4.length === 0 ? "": "，"
                exportRow.category_4 += "材料类"
              }
              if (subject.checked['26'] === 1) {
                exportRow.category_4 += exportRow.category_4.length === 0 ? "": "，"
                exportRow.category_4 += "能源动力类"
              }
              if (subject.checked['27'] === 1) {
                exportRow.category_4 += exportRow.category_4.length === 0 ? "": "，"
                exportRow.category_4 += "交通运输类"
              }
              if (subject.checked['28'] === 1) {
                exportRow.category_4 += exportRow.category_4.length === 0 ? "": "，"
                exportRow.category_4 += "海洋工程类"
              }
              if (subject.checked['29'] === 1) {
                exportRow.category_4 += exportRow.category_4.length === 0 ? "": "，"
                exportRow.category_4 += "航空航天类"
              }
              if (subject.checked['30'] === 1) {
                exportRow.category_4 += exportRow.category_4.length === 0 ? "": "，"
                exportRow.category_4 += "兵器类"
              }

              if (subject.checked['17'] === 1) {
                exportRow.category_3 += exportRow.category_3.length === 0 ? "": "，"
                exportRow.category_3 += "电气类"
              }
              if (subject.checked['16'] === 1) {
                exportRow.category_3 += exportRow.category_3.length === 0 ? "": "，"
                exportRow.category_3 += "电子信息类"
              }
              if (subject.checked['18'] === 1) {
                exportRow.category_3 += exportRow.category_3.length === 0 ? "": "，"
                exportRow.category_3 += "自动化类"
              }
              if (subject.checked['19'] === 1) {
                exportRow.category_3 += exportRow.category_3.length === 0 ? "": "，"
                exportRow.category_3 += "计算机类"
              }
              if (subject.checked['20'] === 1) {
                exportRow.category_3 += exportRow.category_3.length === 0 ? "": "，"
                exportRow.category_3 += "安全科学与工程类"
              }
              if (subject.checked['21'] === 1) {
                exportRow.category_3 += exportRow.category_3.length === 0 ? "": "，"
                exportRow.category_3 += "公安技术类"
              }

              if (subject.checked['11'] === 1) {
                exportRow.category_2 += exportRow.category_2.length === 0 ? "": "，"
                exportRow.category_2 += "土木类"
              }
              if (subject.checked['12'] === 1) {
                exportRow.category_2 += exportRow.category_2.length === 0 ? "": "，"
                exportRow.category_2 += "水利类"
              }
              if (subject.checked['13'] === 1) {
                exportRow.category_2 += exportRow.category_2.length === 0 ? "": "，"
                exportRow.category_2 += "测绘类"
              }
              if (subject.checked['14'] === 1) {
                exportRow.category_2 += exportRow.category_2.length === 0 ? "": "，"
                exportRow.category_2 += "地质类"
              }
              if (subject.checked['15'] === 1) {
                exportRow.category_2 += exportRow.category_2.length === 0 ? "": "，"
                exportRow.category_2 += "矿业类"
              }
              if (subject.checked['10'] === 1) {
                exportRow.category_2 += exportRow.category_2.length === 0 ? "": "，"
                exportRow.category_2 += "建筑类"
              }

              if (subject.checked['31'] === 1) {
                exportRow.category_5 += exportRow.category_5.length === 0 ? "": "，"
                exportRow.category_5 += "化工与制药类"
              }
              if (subject.checked['32'] === 1) {
                exportRow.category_5 += exportRow.category_5.length === 0 ? "": "，"
                exportRow.category_5 += "纺织类"
              }
              if (subject.checked['33'] === 1) {
                exportRow.category_5 += exportRow.category_5.length === 0 ? "": "，"
                exportRow.category_5 += "轻工类"
              }
              if (subject.checked['34'] === 1) {
                exportRow.category_5 += exportRow.category_5.length === 0 ? "": "，"
                exportRow.category_5 += "核工程类"
              }
              if (subject.checked['37'] === 1) {
                exportRow.category_5 += exportRow.category_5.length === 0 ? "": "，"
                exportRow.category_5 += "农林工程类"
              }
              if (subject.checked['38'] === 1) {
                exportRow.category_5 += exportRow.category_5.length === 0 ? "": "，"
                exportRow.category_5 += "林业工程类"
              }
              if (subject.checked['36'] === 1) {
                exportRow.category_5 += exportRow.category_5.length === 0 ? "": "，"
                exportRow.category_5 += "环境科学与工程类"
              }
              if (subject.checked['35'] === 1) {
                exportRow.category_5 += exportRow.category_5.length === 0 ? "": "，"
                exportRow.category_5 += "食品科学与工程类"
              }
              if (subject.checked['39'] === 1) {
                exportRow.category_5 += exportRow.category_5.length === 0 ? "": "，"
                exportRow.category_5 += "自然保护与环境生态类"
              }

              if (subject.checked['44'] === 1) {
                exportRow.category_7 += exportRow.category_7.length === 0 ? "": "，"
                exportRow.category_7 += "经管类"
              }
              if (subject.checked['60'] === 1) {
                exportRow.category_7 += exportRow.category_7.length === 0 ? "": "，"
                exportRow.category_7 += "金融类"
              }
              if (subject.checked['45'] === 1) {
                exportRow.category_7 += exportRow.category_7.length === 0 ? "": "，"
                exportRow.category_7 += "法学类"
              }
              if (subject.checked['46'] === 1) {
                exportRow.category_7 += exportRow.category_7.length === 0 ? "": "，"
                exportRow.category_7 += "教育学类"
              }
              if (subject.checked['47'] === 1) {
                exportRow.category_7 += exportRow.category_7.length === 0 ? "": "，"
                exportRow.category_7 += "体育学类"
              }
              if (subject.checked['61'] === 1) {
                exportRow.category_7 += exportRow.category_7.length === 0 ? "": "，"
                exportRow.category_7 += "外国语言文学类"
              }
              if (subject.checked['62'] === 1) {
                exportRow.category_7 += exportRow.category_7.length === 0 ? "": "，"
                exportRow.category_7 += "中国语言文学类"
              }
              if (subject.checked['48'] === 1) {
                exportRow.category_7 += exportRow.category_7.length === 0 ? "": "，"
                exportRow.category_7 += "新闻传播学类"
              }
              if (subject.checked['49'] === 1) {
                exportRow.category_7 += exportRow.category_7.length === 0 ? "": "，"
                exportRow.category_7 += "历史学类"
              }
              if (subject.checked['63'] === 1) {
                exportRow.category_7 += exportRow.category_7.length === 0 ? "": "，"
                exportRow.category_7 += "马克思主义理论类"
              }
              if (subject.checked['50'] === 1) {
                exportRow.category_7 += exportRow.category_7.length === 0 ? "": "，"
                exportRow.category_7 += "艺术学类"
              }

              if (subject.checked['40'] === 1) {
                exportRow.category_6 += exportRow.category_6.length === 0 ? "": "，"
                exportRow.category_6 += "生物医学工程类"
              }
              if (subject.checked['41'] === 1) {
                exportRow.category_6 += exportRow.category_6.length === 0 ? "": "，"
                exportRow.category_6 += "生物工程类"
              }
              if (subject.checked['42'] === 1) {
                exportRow.category_6 += exportRow.category_6.length === 0 ? "": "，"
                exportRow.category_6 += "植物类"
              }
              if (subject.checked['43'] === 1) {
                exportRow.category_6 += exportRow.category_6.length === 0 ? "": "，"
                exportRow.category_6 += "动物类"
              }
              if (subject.checked['64'] === 1) {
                exportRow.category_6 += exportRow.category_6.length === 0 ? "": "，"
                exportRow.category_6 += "生物科学类"
              }

            }

            let subject2 = item.subjectIntention2 ? JSON.parse(item.subjectIntention2) : null

            if (subject2) {
              for (let i = 0; i < subject2.connect.length; i++) {
                if (subject2.connect[i].title === '参与专业大类领域') {
                  exportRow.category_100_connect = subject2.connect[i].connect
                  exportRow.category_100_phone = subject2.connect[i].phone
                }
              }

              if (subject2.checked['0'] === 1) {
                exportRow.category_100 += exportRow.category_100.length === 0 ? "": "，"
                exportRow.category_100 += "电子与信息大类"
              }
              if (subject2.checked['1'] === 1) {
                exportRow.category_100 += exportRow.category_100.length === 0 ? "": "，"
                exportRow.category_100 += "财经商贸大类"
              }
              if (subject2.checked['2'] === 1) {
                exportRow.category_100 += exportRow.category_100.length === 0 ? "": "，"
                exportRow.category_100 += "医药卫生大类"
              }
              if (subject2.checked['3'] === 1) {
                exportRow.category_100 += exportRow.category_100.length === 0 ? "": "，"
                exportRow.category_100 += "公共管理与服务大类"
              }
              if (subject2.checked['4'] === 1) {
                exportRow.category_100 += exportRow.category_100.length === 0 ? "": "，"
                exportRow.category_100 += "交通运输大类"
              }
              if (subject2.checked['5'] === 1) {
                exportRow.category_100 += exportRow.category_100.length === 0 ? "": "，"
                exportRow.category_100 += "生物与化工大类"
              }
              if (subject2.checked['6'] === 1) {
                exportRow.category_100 += exportRow.category_100.length === 0 ? "": "，"
                exportRow.category_100 += "农林牧渔大类"
              }
              if (subject2.checked['7'] === 1) {
                exportRow.category_100 += exportRow.category_100.length === 0 ? "": "，"
                exportRow.category_100 += "能源动力与材料大类"
              }
              if (subject2.checked['8'] === 1) {
                exportRow.category_100 += exportRow.category_100.length === 0 ? "": "，"
                exportRow.category_100 += "装备制造大类"
              }
              if (subject2.checked['9'] === 1) {
                exportRow.category_100 += exportRow.category_100.length === 0 ? "": "，"
                exportRow.category_100 += "资源环境与安全类"
              }
            }

            exportArray.push(exportRow)
          });
          this.$parent.$parent.showMsg('导出成功')
          this.hideExporWindow()
          outputTable(exportArray,'会员申请信息文件.csv')
        }
      }).catch(() => {
        this.$parent.$parent.showMsg('获取会员信息失败！')
      })
    },
    async getStatistics () {
      await this.$http.get('/application_statistics/', {
        params: {
          type: 1
        }
      }).then((data) => {
        console.log(data)
        if(data.code == 1 && data.data.length > 0){
          this.statisticsData.school_count = data.data[0]['school_count']
          this.statisticsData.department_count = data.data[0]['department_count']
          this.statisticsData.apply_count = data.data[0]['apply_count']
          this.statisticsData.pass_count = data.data[0]['pass_count']
        }
      }).catch(() => {
        this.$parent.$parent.showMsg('获取通知信息失败！')
      })
    }
  },
  mounted() {

    this.search()
  },
}
</script>

<style lang="less" scoped>
.action a {
  display: inline-block;
  padding: 0 !important;
  padding-right: 17px !important;
  word-wrap: break-word;
  text-align: left;
}
.btncolor {
  border: 1px solid #4976e8 !important;
  background-color: #4976e8 !important;
}

.iconfont {
  color: #5683f7;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  text-align: center;
  line-height: 64px;
  background: #f1f3fb;
}
#dialog_content2 {
  max-height: 100px;
  overflow-y: auto;
  border-top: 1px solid #ddd;
}

#dialog_content2 .success {
  color: #aaa;
}

#dialog_content2 .fail {
  color: #e33;
}
.btncolor {
  border: 1px solid #4976e8 !important;
  background-color: #4976e8 !important;
}
.list-type {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  li {
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 200px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 40px;
      margin-right: 25px;
    }
    div {
      span {
        display: block;
        text-align: center;
        font-size: 12px;
        &:first-child {
          font-size: 20px;
        }
      }
    }
  }
}
.change-tab {
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  li {
    display: inline-block;
    font-size: 18px;
    padding-bottom: 10px;
    cursor: pointer;
    &:first-child {
      margin-right: 20px;
    }
  }
  .changed {
    border-bottom: 2px solid #4976e8;
    font-weight: bold;
  }
}
</style>
