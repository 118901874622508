<template>
  <div>
    <div class="breadcrumb-nav">
      <a href="/">首页</a>&gt;&gt;
      <a href="/www/organization/chairman">组织机构</a>&gt;&gt;<span
        class="last-nav"
        @click="hideGroupInfo"
      >专业领域工作委员会</span>
    </div>
    <div class="subRight">
      <div class="subRightTit">
        <div>
          <span @click="hideGroupInfo">专业领域工作委员会</span>
          <span
            class="group"
            v-if="groupInfo"
          >{{groupName}}</span>
        </div>

        <span
          class="last-nav"
          v-if="groupInfo"
        >&gt;&gt;{{groupName}}</span>
      </div>
      <div class="con">
        <table
          cellspacing="0"
          cellpadding="0"
          class="orgTbl m-hide"
          v-if="!groupInfo"
        >
          <tbody>
            <tr>
              <th
                colspan="5"
                width="759"
              >专业领域工作委员会（按单位名称拼音顺序排列）</th>
            </tr>
            <tr>
              <th class="thead">序号</th>
              <th class="thead">专业领域工作委员会</th>
              <th class="thead">牵头单位</th>
              <th class="thead">负责人</th>
              <th class="thead">联系方式</th>
            </tr>
            <tr
              v-for="(item, index) in data"
              :key="item.id"
            >
              <td>{{ index + 1 }}</td>
              <!-- <td><span
                class="group-name"
                @click="showGroupInfo(item.committee_name,item.id)"
              >{{ item.committee_name }}</span></td> -->
              <td><span>{{ item.committee_name }}</span></td>
              <td>{{ item.org_name }}</td>
              <td>{{ item.manager_name }}</td>
              <td>{{ item.connect }}</td>
            </tr>
          </tbody>
        </table>
        <select
          v-if="false"
          v-model="selectedCategory"
          @change="filterData"
        >
          <option value="">全部</option>
          <option
            v-for="(item, index) in groupCategory"
            :key="index"
            :value="item"
          >{{ item }}</option>
        </select>
        <table
          cellspacing="0"
          cellpadding="0"
          class="orgTbl m-hide"
          v-if="groupInfo"
        >
          <tbody>
            <tr>
              <th
                colspan="7"
                width="759"
              >{{groupName}}（按单位名称拼音顺序排列）</th>
            </tr>
            <tr>
              <th class="thead">序号</th>
              <th class="thead">专委会名称</th>
              <th class="thead">专委会职务</th>
              <th class="thead">姓名</th>
              <th class="thead">职务/职称</th>
              <th class="thead">工作单位</th>
              <th class="thead">联系方式</th>
            </tr>
            <tr
              v-for="(item, index) in groupDataFilter"
              :key="item.id"
            >
              <td>{{ index + 1 }}</td>
              <td>{{ item.title }}</td>
              <td>{{ item.duty }}</td>
              <td>{{ item.connect_user }}</td>
              <td>{{ item.connect_duty }}</td>
              <td>{{ item.connect_org }}</td>
              <td>{{ item.connect_phone }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Subambit",
  data (){
    return {
      data: [],
      groupData:[],
      groupDataFilter:[],
      groupInfo:false,
      groupName:'',
      selectedCategory: ''
    }
  },
  computed: {
    groupCategory() {
      let res = []
      for (let i=0;i<this.groupData.length;i++){
        if (res.indexOf(this.groupData[i].title) < 0){
          res.push(this.groupData[i].title)
        }
      }
      return res
    }
  },
  methods: {
    showGroupInfo(name,id){
      this.groupData=[]
      this.groupInfo=true
      this.groupName=name
      this.getGroupData(id)
    },
    hideGroupInfo(){
      this.groupInfo=false
      this.selectedCategory = ''
      this.groupData = []
      this.groupDataFilter = []
    },
    async getData(){
      await this.$http.get('/allowany/org_committee/', {
        params: {
          page: 1,
          size: 10000
        }
      }).then((data) => {
        console.log(data)
        this.data = data.results
      })
    },
    async getGroupData(id){
      await this.$http.get('/allowany/org_workgroup/', {
        params: {
          page: 1,
          size: 10000,
          org_committee_id:id
        }
      }).then((data) => {
        console.log(data)
        this.groupData = data.results
        this.filterData()
      })
    },
    filterData() {
      if (this.selectedCategory == ''){
        this.groupDataFilter = this.groupData
      } else {
        this.groupDataFilter = this.groupData.filter(item => item.title == this.selectedCategory)
      }
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
td {
  padding: 10 20px;
}
.subRightTit {
  > div {
    position: absolute;
    left: 30px;
    height: 60px;
    line-height: 60px;
  }
  span {
    position: static;
    left: 0;
    display: inline-block;
    //line-height: 80px;
    cursor: pointer;
    &.group {
      margin-left: 50px;
      cursor: auto;
    }
    &:first-child {
      border: none;
    }
    &:last-child {
      border-bottom: #444 2px solid;
    }
  }
  .last-nav {
    color: #999;
    padding: 0 5px;
    font-size: 14px;
    border-bottom: none;
    cursor: pointer;
    &:hover {
      color: #0b3b9d;
    }
    &:last-child {
      cursor: auto;
      color: #999;
      border: none;
    }
  }
}
.group-name {
  cursor: pointer;
  &:hover {
    color: #1f45a9;
  }
}
.thead {
  background: #fff;
  text-align: left;
}

select {
  height: 34px;
  padding-top: 5px;
  padding-bottom: 7px;
  margin-left: 45px;
  background: #f5f7fc;
  border: 1px solid #e6e6e6;
  width: 150px;
}
</style>
