<template>
  <div id="center" class="clearfix" style="min-height: 522px;">
    <div id="left-menu" class="pull-left">
      <div class="menu-wrap" style="min-height: 498px;">
        <div class="t-user">
          <img v-if="this.$store.state.userInfo.avatar == ''" src="../../assets/images/user.png" width="36" height="36">
          <img v-if="this.$store.state.userInfo.avatar != ''"
               :src="this.$store.state.uploadFileRootUrl + this.$store.state.userInfo.avatar" width="36" height="36">
          <span style="margin-top:5px;display:inline-block;">{{ this.$store.state.userInfo.name }}</span>
        </div>
        <ul class="my-left">
          <li class="m-li1 admin1 admin2 admin3 vip" @click="updateMenuSelected" :class="this.menuSelected == '/profile' ? 'cur' : ''">
            <i class="iconfont icon-wode"></i>
            <router-link to="/profile" href="javascript:void(0);">我的信息</router-link>
          </li>
          <li class="m-li2 admin1 admin2 admin3 vip" @click="updateMenuSelected" :class="this.menuSelected == '/password' ? 'cur' : ''">
            <i class="iconfont icon-chugui2"></i>
            <router-link to="/password" href="javascript:void(0);">修改密码</router-link>
          </li>
          <li class="m-li7 guide" @click="updateMenuSelected" :class="this.menuSelected == '/guid_info' ? 'cur' : ''">
          <!-- v-if="showGuidInfo" -->
            <i class="iconfont icon-zixunguanli"></i>
            <router-link to="/guid_info" href="javascript:void(0);">我的指南</router-link>
          </li>

          <li class="m-li3 vip" v-if="showMemberInfo" @click="updateMenuSelected" :class="this.menuSelected == '/vip_info' ? 'cur' : ''">
            <i class="iconfont icon-huiyuanguanli"></i>
            <router-link to="/vip_info" href="javascript:void(0);">会员信息</router-link>
          </li>
          <li class="m-li4 notice" v-if="showMemberInfo" @click="updateMenuSelected"
              :class="this.menuSelected == '/user_notice' ? 'cur' : ''">
            <i class="iconfont icon-tongzhiguanli"></i>
            <router-link to="/user_notice" href="javascript:void(0);">通知消息</router-link>
          </li>
          <li class="m-li1 admin1 admin2 admin3 vip" v-if="showMeetInfo" @click="updateMenuSelected" :class="this.menuSelected == '/my_meeting' ? 'cur' : ''">
            <i class="iconfont icon-huiyiguanli"></i>
            <router-link to="/my_meeting" href="javascript:void(0);">我的会议</router-link>
          </li>

          <li v-if="isSuperMember" class="m-li5 admin1">
            <i class="iconfont icon-qiyehuiyuan"></i>
            <router-link :to="superVip.category==2?template.two:superVip.category==3?template.three:template.one">高级会员信息</router-link>
          </li>
          <li v-if="showAdmin == 1" class="m-li5 admin1">
            <i class="iconfont icon-set"></i>
            <router-link to="/admin_user">后台管理</router-link>
          </li>
          <li v-if="showAdmin == 6" class="m-li5 admin1">
            <i class="iconfont icon-set"></i>
            <router-link to="/admin_guid">后台管理</router-link>
          </li>
          <li v-if="showAdmin == 9" class="m-li5 admin1">
            <i class="iconfont icon-set"></i>
            <router-link to="/admin_flash">后台管理</router-link>
          </li>

          <li class="m-li3 vip hide"><i class="iconfont icon-set"></i><a
              href="javascript:void(0);">会员信息</a></li>
          <li class="m-li4 notice hide"><i class="iconfont icon-set"></i><a
              href="javascript:void(0);">通知消息</a></li>
          <li class="m-li6 admin2 hide"><i class="iconfont icon-set"></i><a
              href="javascript:void(0);">后台管理</a></li>
          <li class="m-li6 admin3 hide"><i class="iconfont icon-set"></i><a
              href="javascript:void(0);">后台管理</a></li>
          <li class="m-li7 guide hide"><i class="iconfont icon-set"></i><a
              href="javascript:void(0);">我的指南</a></li>
        </ul>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'UserLayout',
  data() {
    return {
      menuSelected: '/profile',
      isMember: false,
      superVip:{
        status:0,
        category:'',
      },
      template:{
        one:'/template_one',
        two:'/template_two',
        three:'/template_three',
      }

    }
  },
  computed: {
    showAdmin (){
      if(['0', '1', '6', '7'].indexOf(this.$store.state.userInfo.role) >= 0){
        return 1
      }
      if(['5'].indexOf(this.$store.state.userInfo.role) >= 0){
        return 6
      }
      if(['2'].indexOf(this.$store.state.userInfo.role) >= 0){
        return 9
      }
      return 0
    },
    loginUserInfo() {
      return this.$store.state.userInfo
    },
    showMemberInfo() {
      if(['3', '4', '8'].indexOf(this.$store.state.userInfo.role) >= 0){
        return 1
      }
      return 0
    },
    showGuidInfo() {
      if(['3', '5', '6'].indexOf(this.$store.state.userInfo.role) >= 0){
        return 1
      }
      return 0
    },
    showMeetInfo(){
      if(['0', '1', '2'].indexOf(this.$store.state.userInfo.role) >= 0){
        console.log(['0', '1', '2'].indexOf(this.$store.state.userInfo.role) >= 0)
        return 0
      }
      return 1
    },
    isSuperMember() {
      return this.$store.state.userInfo.super_status >= 3
    }
  },
  methods: {
    updateMenuSelected(){
      const url = window.location.href
      const arrUrl = url.split('//')
      const start = arrUrl[1].indexOf('/')
      let relUrl = arrUrl[1].substring(start)

      if (relUrl.indexOf('?') !== -1) {
        relUrl = relUrl.split('?')[0]
      }
      this.menuSelected = relUrl
    },
    async checkUserIsAdmin() {
      await this.$http.get('/application/' , {
        params: {
          page: 1,
          size: 1,
          ownerId: this.loginUserInfo.user_id
        }
      }).then((data) => {
        if(data.count > 0){
          this.isMember = true
        } else {
          this.isMember = false
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    showMsg(text, callback='') {
      this.$parent.showMsg(text, callback)
    },
    // 查询高级会员
    async FunIsVip() {
      await this.$http.get('/application/', {
        params: {
          type:2,
          ownerId:this.$store.state.userInfo.user_id
        }
      }).then((data) => {
        if (data.results && data.results.length > 0){
          this.superVip.status = data.results[0].super_status
          this.superVip.category = data.results[0].super_view_category
        }
      })
    },
  },
  mounted() {
    this.updateMenuSelected()
    this.FunIsVip()
    // this.checkUserIsAdmin()
  }
}
</script>

<style scoped>
.menu {
  float: left;
}

.content {
  float: left;
}

</style>
