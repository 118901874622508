<template>
  <div id="center" style="position:relative;width:100%;height:80vh;background:#fff">
    <div class="loadImg">
      <img src="../../assets/images/load.jpg">
      </div>
  </div>
</template>

<script>
export default {
  name: "Load",
  data() {
    return {
      
      
    }
  },
  computed: {
   
  },
  methods: {
    
  },
  mounted() {
    
  }
}
</script>

<style scoped>
.loadImg{
  width:30%;
  position:absolute;
  left:50%;
  top:40%;
  transform: translate(-50%,-50%);
}
.loadImg img{
  width:100%;
  height:100%;
}
</style>
