<template>
  <div id="center" v-title :data-title="$route.query.id=='77'?'建设指南':'工作动态'"
>
    <div class="subBanner1"></div>
    <div id="subCenter">
      <div id="content" class="content">
        <div id="elList" class="newsContent">
          <div class="newsDetailed clearfix">
            <h2 class="title">{{ infoModel ? infoModel.title : '' }}</h2>
            <h4 class="subTitle">{{ infoModel ? infoModel.subTitle : '' }}</h4>
            <div class="info">
              <span class="source">{{ infoModel ? (infoModel.source == 0 ? '原创' : '转载') : '' }}&nbsp;</span>
              <span class="" time="">{{ infoModel ? infoModel.date : '' }}</span>
            </div>
            <article class="articleNews" v-html="infoModel ? infoModel.contents : ''">
            </article>
          </div>
          <p class="attachment-download" v-if="infoModel && infoModel.attPath && infoModel.attPath.length > 0"><i class="iconfont icon-attachment"></i>附件下载：</p>
          <ul class="attachment-file" v-if="infoModel && infoModel.attPath">
            <li v-for="(item, index) in JSON.parse(infoModel.attPath)" :key="index" style="padding:0;">
              <a :href="formTableImageSrc(item.path)" :download="item.name">{{ item.name }}</a>
            </li>
          </ul>
          <ul class="attachment-file" v-else></ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Activity",
  data () {
    return {
      infoModel: null
    }
  },
  methods: {
    getUrlKey (name){
      let url = window.location.href;
      let dz_url = url.split('#')[0];
      if (dz_url.split('?').length <= 1) {
        return ''
      }
      let cs_arr = dz_url.split('?')[1].split('&');
      let cs = {};
      for (let i = 0; i < cs_arr.length; i++) {
        cs[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
      }
      return cs[name]
    },
    async getInfo(id){
      console.log(id)
      const url = '/allowany/info/' + id + '/'
      await this.$http.get(url).then((data) => {
        console.log(data)
        this.infoModel = data
      }).catch((error) => {
        console.log(error)
      })
    },
    formTableImageSrc(src) {
      if (src != '') {
        return this.$store.state.wwwFileRootUrl + src
      } else {
        return ''
      }
    },
  },
  mounted() {
    this.getInfo(this.getUrlKey('id'))
  }
}
</script>

<style scoped>
#center {
  width: auto !important;
}
.title{
  width: auto !important;
}
</style>
