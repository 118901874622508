<template>
  <div id="panel" class="panel" style="min-height: 696px;">
    <form id="searchSection" onsubmit="return false;">
      <h2 style="color: #999; text-align: center">【征集意见】建设中</h2>
    </form>
  </div>
</template>

<script>
export default {
  name: "AdminOption"
}
</script>

<style scoped>

</style>
