import Vue from 'vue'
import store from '../store'
// import { VueRouter, createWebHistory } from 'vue-router'
import VueRouter from 'vue-router'
import Login from "../views/Login";
import Home from '../views/Home.vue'
import UserLayout from "../views/user/UserLayout";
import AdminLayout from "../views/admin/AdminLayout";

import Profile from "../views/user/Profile";
import Password from "../views/user/Password";
import VipInfo from "../views/user/VipInfo";
import UserNotice from "../views/user/UserNotice";
import GuidInfo from "../views/user/GuidInfo";
import Mymeeting from "../views/user/Mymeeting";

import AdminUser from "../views/admin/AdminUser";
import AdminMember from "../views/admin/AdminMember";
import AdminApply from "../views/admin/AdminApply";
import AdminSuperApply from "../views/admin/AdminSuperApply";
import AdminMeeting from "../views/admin/AdminMeeting";
import AdminUploadMeet from "../views/admin/AdminUploadMeet";
import AdminAttendance from "../views/admin/AdminAttendance";
import AdminSchool from "../views/admin/AdminSchool";
import AdminCategories from "../views/admin/AdminCategories";
import AdminGuid from "../views/admin/AdminGuid";
import AdminGuidEdit from "../views/admin/AdminGuidEdit";
import AdminOption from "../views/admin/AdminOption";
import AdminStatistics from "../views/admin/AdminStatistics";
import AdminFlash from "../views/admin/AdminFlash";
import AdminInfo from "../views/admin/AdminInfo";
import AdminNotice from "../views/admin/AdminNotice";
import AdminCompanyProfile from "../views/admin/AdminCompanyProfile";
import AdminTemplate from "../views/admin/AdminTemplate";
import AdminEnterpriseZone from "../views/admin/AdminEnterpriseZone";

import IntroductionLayout from "../views/www/introduction/IntroductionLayout";
import Intro from "../views/www/introduction/Intro";
import Rules from "../views/www/introduction/Rules";
import Notice from "../views/www/introduction/Notice";
import Contact from "../views/www/introduction/Contact";
import ConstructionGuide from "../views/www/ConstructionGuide";
import EnterpriseZone from "../views/www/EnterpriseZone";
import TemplateOne from "../views/www/TemplateOne";
import TemplateTwo from "../views/www/TemplateTwo";
import TemplateThree from "../views/www/TemplateThree";

import OrganizationLayout from "../views/www/organization/OrganizationLayout";
import Academic from "../views/www/organization/Academic";
import Chairman from "../views/www/organization/Chairman";
import Council from "../views/www/organization/Council";
import Secretariat from "../views/www/organization/Secretariat";
import Subambit from "../views/www/organization/Subambit";
import Technical from "../views/www/organization/Technical";
import Enterprise from "../views/www/organization/Enterprise";
import OrgMajor from "../views/www/organization/OrgMajor";
import OrgGao from "../views/www/organization/OrgGao";

import CompetitionLayout from "../views/www/competition/CompetitionLayout";
import MatchNotice from "../views/www/competition/Notice";
import Research from "../views/www/Research"

// import Index from "../views/www/Index";
import Index2 from "../views/www/Index2";
import Load from "../views/www/Load";
import Loading from "../views/www/Loading";
import Activity from "../views/www/Activity";
import Apply from "../views/www/Apply";
import Vip from "../views/www/Vip";
import AppView from "../views/www/AppView";
import BindPhone from "../views/www/BindPhone";
import SearchResult from "../views/www/SearchResult";

import ActivityLayout from "../views/www/activity/ActivityLayout";
import ActivityNotice from "../views/www/activity/ActivityNotice";
import ActivityReport from "../views/www/activity/ActivityReport";
import ActivityMeeting from "../views/www/activity/ActivityMeeting";
import ActivityBoard from "../views/www/activity/ActivityBoard";

import ActivityPlane from "../views/www/activity/ActivityPlane";
import ActivitySubject from "../views/www/activity/ActivitySubject";
import ActivityGuid from "../views/www/activity/ActivityGuid";
import ActivityRule from "../views/www/activity/ActivityRule";

import Register from "../views/www/Register";
import ForgotPwd from "../views/www/ForgotPwd";

import AdminOrgLayout from "../views/admin/org/AdminOrgLayout";
import AdminOrgLeader from "../views/admin/org/AdminOrgLeader";
import AdminOrgCommittee from "../views/admin/org/AdminOrgCommittee";
import AdminOrgGroup from "../views/admin/org/AdminOrgGroup";
import AdminOrgBrief from "../views/admin/org/AdminOrgBrief";
import AdminOrgEnterprise from "../views/admin/org/AdminOrgEnterprise";
import AdminOrgGao from "../views/admin/org/AdminOrgGao";
import AdminOrgMajor from "../views/admin/org/AdminOrgMajor";
import AdminGuidDetail from "../views/admin/AdminGuidDetail";

import AdminCorporateMembers from "../views/admin/AdminCorporateMembers";

import AdminCompanyLayout from "../views/admin/company/AdminCompanyLayout";
import AdminCompanyApply from "../views/admin/company/AdminCompanyApply";
import AdminCompanyInfo from "../views/admin/company/AdminCompanyInfo";
import AdminResearch from "../views/admin/AdminResearch";

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/www/index',
    children: [
      {
        path: '/www/index',
        name: 'Index',
        component: Index2,
        meta: {
          title: '虚拟仿真实验教学创新联盟'
        }
      },
      {
        path: '/www/activity',
        name: 'Activity',
        component: Activity,
        meta: {
          title: '工作动态'
        }
      },
      {
        path: '/www/apply',
        name: 'Apply',
        component: Apply,
        meta: {
          title: '会员申请'
        }
      },
      {
        path: '/www/vip',
        name: 'Vip',
        component: Vip,
        meta: {
          title: '会员单位'
        }
      },
      {
        path: '/www/searchresult',
        name: 'SearchResult',
        component: SearchResult,
        meta: {
          title: '会员单位'
        }
      },
      {
        path: '/www/construction_guide',
        name: 'ConstructionGuide',
        component: ConstructionGuide,
        meta: {
          title: '建设指南'
        }
      },
      {
        path: '/www/enterprise_zone',
        name: 'EnterpriseZone',
        component: EnterpriseZone,
        meta: {
          title: '企业专区'
        }
      },
      {
        path: '/template_one',
        name: 'TemplateOne',
        component: TemplateOne,
        meta: {
          title: '企业专区'
        }
      },
      {
        path: '/template_two',
        name: 'TemplateTwo',
        component: TemplateTwo,
        meta: {
          title: '企业专区'
        }
      },
      {
        path: '/template_three',
        name: 'TemplateThree',
        component: TemplateThree,
        meta: {
          title: '企业专区'
        }
      },
      {
        path: '/www/template_one',
        name: 'TemplateOne',
        component: TemplateOne,
        meta: {
          title: '企业专区'
        }
      },
      {
        path: '/www/template_two',
        name: 'TemplateTwo',
        component: TemplateTwo,
        meta: {
          title: '企业专区'
        }
      },
      {
        path: '/www/template_three',
        name: 'TemplateThree',
        component: TemplateThree,
        meta: {
          title: '企业专区'
        }
      },
      {
        path: '/www/load',
        name: 'Load',
        component: Load,
        meta: {
          title: '即将上线，敬请期待'
        }
      },
      {
        path: '/www/loading',
        name: 'Loading',
        component: Loading,
        meta: {
          title: '即将上线，敬请期待'
        }
      },
      {
        path: '/www/register',
        name: 'Register',
        component: Register,
        meta: {
          title: '注册'
        }
      },
      {
        path: '/www/forgotpwd',
        name: 'ForgotPwd',
        component: ForgotPwd,
        meta: {
          title: '忘记密码'
        }
      },
      {
        path: '/www/appview',
        name: 'AppView',
        component: AppView,
        meta: {
          title: '会员申请信息'
        }
      },

      {
        path: '/www/bindphone',
        name: 'BindPhone',
        component: BindPhone,
        meta: {
          title: '绑定手机'
        }
      },
      {
        path: '/admin_guid_detail',
        name: 'AdminGuidDetail',
        component: AdminGuidDetail,
        meta: {
          title: '指南管理-详情模式'
        }
      },
      {
        path: '/www/research',
        name: 'Research',
        component: Research,
        meta: {
          title: '在线教研'
        }
      },
      {
        path: '/user_layout',
        name: 'UserLayout',
        component: UserLayout,
        redirect: '/profile',
        children: [
          {
            path: '/profile',
            name: 'Profile',
            component: Profile,
            meta: {
              title: '我的信息'
            }
          },
          {
            path: '/password',
            name: 'Password',
            component: Password,
            meta: {
              title: '修改密码'
            }
          },
          {
            path: '/vip_info',
            name: 'VipInfo',
            component: VipInfo,
            meta: {
              title: '会员信息'
            }
          },
          {
            path: '/user_notice',
            name: 'UserNotice',
            component: UserNotice,
            meta: {
              title: '通知消息'
            }
          },
          {
            path: '/guid_info',
            name: 'GuidInfo',
            component: GuidInfo,
            meta: {
              title: '我的指南'
            }
          },
          {
            path: '/admin_info_edit',
            name: 'AdminGuidEdit',
            component: AdminGuidEdit,
            meta: {
              title: '我的指南'
            }
          },
          {
            path: '/my_meeting',
            name: 'Mymeeting',
            component: Mymeeting,
            meta: {
              title: '我的会议'
            }
          }
        ]
      },
      {
        path: '/admin_layout',
        name: 'AdminLayout',
        component: AdminLayout,
        redirect: '/admin_user',
        children: [
          {
            path: '/admin_user',
            name: 'AdminUser',
            component: AdminUser,
            meta: {
              title: '用户管理'
            }
          },
          {
            path: '/admin_member',
            name: 'AdminMember',
            component: AdminMember,
            meta: {
              title: '会员管理'
            }
          },
          {
            path: '/admin_apply',
            name: 'AdminApply',
            component: AdminApply,
            meta: {
              title: '高校会员申请管理'
            }
          },
          {
            path: '/admin_super_apply',
            name: 'AdminSuperApply',
            component: AdminSuperApply,
            meta: {
              title: '高级会员申请管理'
            }
          },
          {
            path: '/admin_corporate_member',
            name: 'AdminCorporateMembers',
            component: AdminCorporateMembers,
            meta: {
              title: '企业会员申请管理'
            }
          },
          {
            path: '/admin_meeting',
            name: 'AdminMeeting',
            component: AdminMeeting,
            meta: {
              title: '会议管理'
            }
          },
          {
            path: '/admin_uploadmeet',
            name: 'AdminUploadMeet',
            component: AdminUploadMeet,
            meta: {
              title: '上传会议资料'
            }
          },
          {
            path: '/admin_attendance',
            name: 'AdminAttendance',
            component: AdminAttendance,
            meta: {
              title: '导入参会人员'
            }
          },
          {
            path: '/admin_school',
            name: 'AdminSchool',
            component: AdminSchool,
            meta: {
              title: '学校管理'
            }
          },
          {
            path: '/admin_categories',
            name: 'AdminCategories',
            component: AdminCategories,
            meta: {
              title: '专业体系管理'
            }
          },
          {
            path: '/admin_guid',
            name: 'AdminGuid',
            component: AdminGuid,
            meta: {
              title: '指南管理'
            }
          },
          {
            path: '/admin_guid_edit',
            name: 'AdminGuidEdit',
            component: AdminGuidEdit,
            meta: {
              title: '指南管理'
            }
          },

          {
            path: '/admin_option',
            name: 'AdminOption',
            component: AdminOption,
            meta: {
              title: '征集意见'
            }
          },
          {
            path: '/admin_statistics',
            name: 'AdminStatistics',
            component: AdminStatistics,
            meta: {
              title: '统计分析'
            }
          },
          {
            path: '/admin_flash',
            name: 'AdminFlash',
            component: AdminFlash,
            meta: {
              title: '轮播管理'
            }
          },
          {
            path: '/admin_info',
            name: 'AdminInfo',
            component: AdminInfo,
            meta: {
              title: '资讯管理'
            }
          },
          {
            path: '/admin_notice',
            name: 'AdminNotice',
            component: AdminNotice,
            meta: {
              title: '通知管理'
            }
          },
          {
            path: '/admin_research',
            name: 'AdminResearch',
            component: AdminResearch,
            meta: {
              title: '问卷管理'
            }
          },
          {
            path: '/admin_company_rofile',
            name: 'AdminCompanyProfile',
            component: AdminCompanyProfile,
            meta: {
              title: '企业会员简介'
            }
          },
          {
            path: '/admin_template',
            name: 'AdminTemplate',
            component: AdminTemplate,
            meta: {
              title: '企业模板审核'
            }
          },
          {
            path: '/admin_enterprise_zone',
            name: 'AdminEnterpriseZone',
            component: AdminEnterpriseZone,
            meta: {
              title: '企业专区管理'
            }
          },
          {
            path: '/admin_org_layout',
            name: 'AdminOrgLayout',
            component: AdminOrgLayout,
            redirect: '/admin_org_leader',
            children: [
              {
                path: '/admin_org_leader',
                name: 'AdminOrgLeader',
                component: AdminOrgLeader,
                meta: {
                  title: '联盟理事、秘书处及学术委员会'
                }
              },
              {
                path: '/admin_org_committee',
                name: 'AdminOrgCommittee',
                component: AdminOrgCommittee,
                meta: {
                  title: '联盟理事、秘书处及学术委员会'
                }
              },
              {
                path: '/admin_org_group',
                name: 'AdminOrgGroup',
                component: AdminOrgGroup,
                meta: {
                  title: '工作组'
                }
              },

              {
                path: '/admin_org_brief',
                name: 'AdminOrgBrief',
                component: AdminOrgBrief,
                meta: {
                  title: '技术工作委员会'
                }
              },
              {
                path: '/admin_org_eterprise',
                name: 'AdminOrgEnterprise',
                component: AdminOrgEnterprise,
                meta: {
                  title: '企业工作委员会'
                }
              },{
                path: '/admin_org_major',
                name: 'AdminOrgMajor',
                component: AdminOrgMajor,
                meta: {
                  title: '企业工作委员会'
                }
              },
              {
                path: '/admin_org_gao',
                name: 'AdminOrgGao',
                component: AdminOrgGao,
                meta: {
                  title: '高职领域工作委员会'
                }
              }
            ]
          },
          {
            path: '/admin_company_layout',
            name: 'AdminCompanyLayout',
            component: AdminCompanyLayout,
            redirect: '/admin_company_apply',
            children: [
              {
                path: '/admin_company_apply',
                name: 'AdminCompanyApply',
                component: AdminCompanyApply,
                meta: {
                  title: '企业会员申请管理'
                }
              },
              {
                path: '/admin_company_info',
                name: 'AdminCompanyInfo',
                component: AdminCompanyInfo,
                meta: {
                  title: '企业会员简介管理'
                }
              }
            ]
          }
        ]
      },
      {
        path: '/www/introduction',
        name: 'IntroductionLayout',
        component: IntroductionLayout,
        redirect: '/www/introduction/intro',
        children: [
          {
            path: '/www/introduction/intro',
            name: 'Intro',
            component: Intro,
            meta: {
              title: '联盟介绍'
            }
          },
          {
            path: '/www/introduction/rules',
            name: 'Rules',
            component: Rules,
            meta: {
              title: '联盟章程'
            }
          },
          {
            path: '/www/introduction/notice',
            name: 'Notice',
            component: Notice,
            meta: {
              title: '联盟公告'
            }
          },
          {
            path: '/www/introduction/contact',
            name: 'Contact',
            component: Contact,
            meta: {
              title: '联系我们'
            }
          }
        ]
      },
      {
        path: '/www/organization',
        name: 'OrganizationLayout',
        component: OrganizationLayout,
        redirect: '/www/organization/council',
        children: [
          {
            path: '/www/organization/chairman',
            name: 'Chairman',
            component: Chairman,
            meta: {
              title: '组织机构'
            }
          },
          {
            path: '/www/organization/council',
            name: 'Council',
            component: Council,
            meta: {
              title: '组织机构'
            }
          },
          {
            path: '/www/organization/secretariat',
            name: 'Secretariat',
            component: Secretariat,
            meta: {
              title: '组织机构'
            }
          },
          {
            path: '/www/organization/academic',
            name: 'Academic',
            component: Academic,
            meta: {
              title: '组织机构'
            }
          },
          {
            path: '/www/organization/subambit',
            name: 'Subambit',
            component: Subambit,
            meta: {
              title: '组织机构'
            }
          },
          {
            path: '/www/organization/technical',
            name: 'Technical',
            component: Technical,
            meta: {
              title: '组织机构'
            }
          },
          {
            path: '/www/organization/enterprise',
            name: 'Enterprise',
            component: Enterprise,
            meta: {
              title: '组织机构'
            }
          },
          {
            path: '/www/organization/major',
            name: 'OrgMajor',
            component: OrgMajor,
            meta: {
              title: '组织机构'
            }
          },
          {
            path: '/www/organization/gao',
            name: 'OrgGao',
            component: OrgGao,
            meta: {
              title: '高职领域委员会'
            }
          }
        ]
      },
      {
        path: '/www/competition',
        name: 'CompetitionLayout',
        component: CompetitionLayout,
        redirect: '/www/competition/notice',
        children: [
          {
            path: '/www/competition/notice',
            name: 'MatchNotice',
            component: MatchNotice,
            meta: {
              title: '赛事通知'
            }
          },
        ]
      },
      {
        path: '/www/activity_layout',
        name: 'ActivityLayout',
        component: ActivityLayout,
        redirect: '/www/activity_layout/notice',
        children: [
          {
            path: '/www/activity_layout/notice',
            name: 'ActivityNotice',
            component: ActivityNotice,
            meta: {
              title: '通知公告'
            }
          },
          {
            path: '/www/activity_layout/report',
            name: 'ActivityReport',
            component: ActivityReport,
            meta: {
              title: '活动报道'
            }
          },
          {
            path: '/www/activity_layout/meeting',
            name: 'ActivityMeeting',
            component: ActivityMeeting,
            meta: {
              title: '会议研讨'
            }
          },
          {
            path: '/www/activity_layout/board',
            name: 'ActivityBoard',
            component: ActivityBoard,
            meta: {
              title: '专业委员会'
            }
          },
          {
            path: '/www/activity_layout/plane',
            name: 'ActivityPlane',
            component: ActivityPlane,
            meta: {
              title: '双百计划'
            }
          },
          {
            path: '/www/activity_layout/subject',
            name: 'ActivitySubject',
            component: ActivitySubject,
            meta: {
              title: '课题研究'
            }
          },
          {
            path: '/www/activity_layout/guid',
            name: 'ActivityGuid',
            component: ActivityGuid,
            meta: {
              title: '建设指南'
            }
          },
          {
            path: '/www/activity_layout/rule',
            name: 'ActivityRule',
            component: ActivityRule,
            meta: {
              title: '建设规范'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: '登陆'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  // history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {

  if (store.state.last_use_time) {
    let cur_time = parseInt(Date.parse(new Date()) / 1000)
    if (store.state.last_use_time + 1800 < cur_time) {
      window.localStorage.clear()
      window.location.href = '/'
    } else {
      store.commit('setLastUseTime', parseInt(Date.parse(new Date()) / 1000))
    }
  }

  if (to.meta.title) {
    document.title = to.meta.title
  }

  if (to.path === '/login' || to.path.substr(0, 4) === '/www') return next()

  const tokenStr = store.state.token
  if (!tokenStr) return next('/login')
  next()
})

export default router
