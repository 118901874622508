<template>
  <div id="center" style="min-height: 504px;">
    <div class="center_content">
      <div class="word"><i></i>
        <span>绑定手机号</span>
      </div>
      <div class="container">
        <div class="main">
          <p class="tip">请绑定您的手机号</p>
          <form class="form-signin" id="itemForm">
            <div class="item">
              <input name="mobile" v-model="formInfo.mobile" type="num" class="form-control" placeholder="手机号">
            </div>
            <div class="item">
              <input name="code" v-model="formInfo.code" type="text" class="form-control short" placeholder="填写验证码">
              <span class="sendCode"
                    @click="sendMsg"
                    :style="isMsgSend ? 'height: 42px; padding: 0px; line-height: 42px;' : ''">
                {{ this.isMsgSend ? '' + this.seconds + '秒' : '获取验证码' }}
              </span>
            </div>
            <input type="button" class="btn" id="submitBtn" value="提交" @click="verifyCode">
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BindPhone",
  data() {
    return {
      isMsgSend: false,
      interval: null,
      seconds: 0,
      formInfo: {
        id: this.$store.state.userInfo.profile_id,
        mobile: '',
        code: ''
      },
    }
  },
  computed: {
    verifyForm() {
      return {
        phone: this.formInfo.mobile,
        code: this.formInfo.code,
        type: 3 // 1 注册 2 忘记密码 3 绑定手机
      }
    },
  },
  methods: {
    async sendMsg() {
      if (this.isMsgSend == true){
        return;
      }
      await this.$http.get('/verify_code/', {
        params: this.verifyForm
      }).then((data) => {
        console.log(data)
        this.$layer.msg("验证码已发送，有效期两分钟！")

        this.isMsgSend = true
        this.seconds = 120
        this.interval = setInterval(() => {
          if(this.seconds == 0){
            clearInterval(this.interval)
            this.isMsgSend = false
          }
          this.seconds -= 1
        }, 1000)

      }).catch(() => {
        this.$parent.showMsg('发送验证码失败！')
      })

    },
    async verifyCode() {
      let mobileReg = /^1[0-9]{10}$/;

      if (!mobileReg.test(this.formInfo.mobile)) {
        this.$parent.showMsg('手机号码输入错误！')
        return false;
      }

      await this.$http.post('/verify_code/', this.verifyForm).then((data) => {
        console.log(data)
        if (data.code == '200'){
          this.saveInfo()
        } else {
          this.$parent.showMsg(data.msg)
        }
      }).catch((data) => {
        console.log(data)
        this.$parent.showMsg("验证码错误")
      })
    },
    async saveInfo() {

      let mobileReg = /^1[0-9]{10}$/;

      console.log(this.formInfo.mobile)
      console.log(!mobileReg.test(this.formInfo.mobile))

      if (!mobileReg.test(this.formInfo.mobile)) {
        this.$parent.showMsg('手机号码输入错误！')
        return false;
      }

      let isBind = false

      await this.$http.get('/allowany/userprofile/', {
        params: {
          eq_mobile: this.formInfo.mobile
        }
      }).then((data) => {
        console.log(data)
        if(data.count > 0){
          isBind = true
        }
      })

      if(isBind){
        this.$parent.showMsg('手机号码已绑定过其他账户，不能重复绑定！')
        return false;
      }

      let url = '/userprofile/' + this.formInfo.id + '/'
      await this.$http.put(url, {
        mobile: this.formInfo.mobile
      }).then((data) => {
        if (data.id) {
          this.$parent.showMsg('绑定成功！', ()=>{
            let userInfo = JSON.parse(JSON.stringify(this.$store.state.userInfo))
            userInfo.mobile = this.formInfo.mobile
            this.$store.commit('setUserInfo', userInfo)
            // this.$store.state.userInfo.mobile = this.formInfo.mobile
            this.$router.push('/profile')
          })

        } else {
          this.$parent.showMsg('绑定失败！')
        }
      }).catch((error) => {
        console.log(error)
        this.$parent.showMsg(error)
      })
    },
  }
}
</script>

<style scoped>

</style>
