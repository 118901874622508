<template>
  <div id="center">
    <div class="item1">
      <div>
        <div class="item1-left">
          <swiper
            :options="swiperNoticeOption"
            v-if="flashData.length"
          >
            <swiper-slide
              class="swiper-slide"
              v-for="(item, index) in flashData"
              :key="index"
            >
              <div class="notice-swiper">
                <img
                  :src="formTableImageSrc(item.imgPath)"
                  alt=""
                >
                <div>
                  <a
                    :href="item.link"
                    target="_blank"
                  >
                    {{ item.title.length > 35 ? item.title.substr(0, 35) + '...' : item.title }}
                  </a>
                </div>
              </div>
            </swiper-slide>
            <div
              class="swiper-pagination"
              slot="pagination"
            ></div>
          </swiper>
        </div>
        <div class="item1-right">
          <ul>
            <li
              :class="noticeActive==2?'active':''"
              @click="toggleNoticeType(2)"
            >会议研讨</li>
            <li
              :class="noticeActive==1?'active':''"
              @click="toggleNoticeType(1)"
            >通知公告</li>

          </ul>
          <ul>
            <li
              v-for="(item,index) in bannerInfo"
              :key="index"
              @click="toActivity(item.id)"
            >
              <span>{{ item.title.length > 28 ? item.title.substr(0, 28) + '...' : item.title }}</span>
              <span>{{ item.date.substr(0, 10) }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="item2">
      <div>
        <ul>
          <li
            :class="jobActive==1?'active':''"
            @click="toggleJobType(1)"
          >专业领域动态</li>
          <li
            :class="jobActive==2?'active':''"
            @click="toggleJobType(2)"
          >重点工作</li>
        </ul>
        <ul>
          <li
            v-for="(item,index) in activityInfo"
            :key="index"
            @click="toActivity(item.id)"
          >
            <div>
              <span>{{ item.date.substr(8, 2) }}</span>
              <span>/{{ item.date.substr(5, 2) }}</span>
            </div>
            <p>{{ item.title.length > 28 ? item.title.substr(0, 28) + '...' : item.title }}</p>
          </li>

        </ul>
      </div>
    </div>
    <div class="item3">
      <div>
        <ul>
          <li>
            <div class="title">
              <span>建设指南</span>
            </div>
            <div @click="toGuide">
              <div>
                <img
                  src="../../assets/images/list_1.jpg"
                  alt=""
                >
              </div>
            </div>
          </li>
          <li>
            <div class="title">
              <span>建设规范</span>
            </div>
            <div @click="toRule">
              <div>
                <img
                  src="../../assets/images/list_2.jpg"
                  alt=""
                >
              </div>
            </div>
          </li>
          <li>
            <div class="title">
              <span>课题研究</span>
            </div>
            <div @click="toSubject">
              <div>
                <img
                  src="../../assets/images/list_3.jpg"
                  alt=""
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="item3 item5">
      <div>
        <ul>
          <li>
            <div class="title">
              <span>双百计划</span>
            </div>
            <div @click="toPlane">
              <div>
                <img
                  src="../../assets/images/list_4.jpg"
                  alt=""
                >
              </div>
            </div>
          </li>
          <li>
            <div class="title">
              <span>虚仿竞赛</span>
            </div>
            <div @click="toCompetition">
              <div>
                <img
                  src="../../assets/images/list_5.jpg"
                  alt=""
                >
              </div>
            </div>
          </li>
          <li>
            <div class="title">
              <span>企业专区</span>
            </div>
            <div @click="toEnterprise">
              <div>
                <img
                  src="../../assets/images/list_6.jpg"
                  alt=""
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="item4">
      <div>
        <div class="title">
          <span>高校会员</span>
        </div>
        <div style="width:1005px;height:220px;margin:0 auto">
          <swiper :options="schoolSwiperOption">
            <div
              class="swiper-slide"
              v-for="(item,index) in schoolList"
              :key="index"
            >
              <div class="notice-swiper">
                <div class="swiper-img">
                  <img
                    :src="formatImage(item.logo)"
                    alt=""
                  >
                </div>
                <div>{{ item.title }}</div>
              </div>
            </div>
            <div
              class="swiper-pagination"
              slot="pagination"
            ></div>
          </swiper>
          <div
            class="swiper-button-prev"
            @click="test"
            slot="button-prev"
          >
            <i class="iconfont icon-zuo"></i>
          </div>
          <div
            class="swiper-button-next"
            slot="button-next"
          >
            <i class="iconfont icon-you"></i>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {
      slide: [1, 2, 3, 4, 5],
      //设置属性
      swiperOption: {
        slidesPerView:"auto",
        //显示分页
        pagination: {
          el: '.swiper-pagination',
          clickable: true, //允许分页点击跳转
        },
        centeredSlides : true,
        //设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        //自动轮播
      //  autoplay: {
      //     delay: 3000,
      //     stopOnLastSlide: false,
      //     disableOnInteraction: true,
      //   },
      //   //开启循环模式
      //   loop: true,
        //开启鼠标滚轮控制Swiper切换
        mousewheel: false
      },
      swiperNoticeOption: {
        slidesPerView:"auto",
        //显示分页
        pagination: {
          el: '.swiper-pagination',
        },
        centeredSlides : true,
        //自动轮播
        autoplay: {
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: true,
        },
        //开启循环模式
        loop: true
      },
      swiperCompanyOption: {
        slidesPerView:"auto",
        //显示分页
        pagination: {
          el: '.swiper-pagination',
        },
        centeredSlides : true,
        //自动轮播
        autoplay: {
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: true,
        },
        //开启循环模式
        loop: true
      },
      schoolSwiperOption: {
        slidesPerView: 6,
        slidesPerGroup: 6,
        spaceBetween: 50,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      adData: [],
      activeData: [],

      timer: '',
      noticeLeft: 2000,
      noticeData: [],
      detailFormInfo: {
        title: '',
        startTime: '',
        endTime: '',
        content: ''
      },
      noticeActive:2,
      jobActive:1,

      flashData: [],
      bannerInfo: [],

      activityInfo: [],
      schoolList: [],
    }
  },
  computed: {
    adUrl () {
      if(this.adData.length > 0){
        return this.formTableImageSrc(this.adData[0].imgPath)
      }else{
        return "../../assets/images/ad.jpeg"
      }
    },
    myBotSwiper() {
        return this.$refs.myBotSwiper.swiper
    }
  },
  methods: {
    test(){
      console.log("11")
    },
    toggleNoticeType(value){
      this.noticeActive=value

      if(value == 1){
        this.getBannerInfo(1)
      }else{
        this.getBannerInfo(5)
      }
    },
    toggleJobType(value){
      this.jobActive=value

      if(value == 1){
        this.getActivityInfo(6)
      }else{
        this.getActivityInfo('7,8,9,10')
      }
    },
    formTableImageSrc(src) {
      if (src != '') {
        return this.$store.state.wwwFileRootUrl + src
      } else {
        return ''
      }
    },
    toActivity(id){
      window.open('/www/activity?id=' + id, '_blank')
    },
    toGuide(){
      window.open('/www/construction_guide', '_blank')
    },
    toRule(){
      window.open('/www/activity_layout/rule', '_blank')
    },
    toSubject(){
      window.open('/www/activity_layout/subject', '_blank')
    },
    toPlane(){
      window.open('/www/activity_layout/plane', '_blank')
    },
    toCompetition(){
      window.open('/www/competition/notice', '_blank')
    },
    toEnterprise(){
      window.open('/www/enterprise_zone', '_blank')
    },

    formatImage(fileName) {
      return this.$store.state.uploadFileRootUrl + fileName
    },
    async searchFlash() {
      await this.$http.get('/allowany/flash/', {
        params: {
          page: 1,
          size: 5,
          position: '1',
          status: '1',
          isdel: '0',
        }
      }).then((data) => {
        this.flashData = data.results
      })
    },
    async getBannerInfo(typeValue) {
      await this.$http.get('/allowany/info_index/', {
        params: {
          page: 1,
          size: 6,
          status: '1',
          type: typeValue,
        }
      }).then((data) => {
        this.bannerInfo = data.results
      }).catch(() => {
        this.bannerInfo = []
        this.$parent.$parent.showMsg('获取活动信息失败！')
      })
    },
    async getActivityInfo(typeValue) {
      await this.$http.get('/allowany/info_index/', {
        params: {
          page: 1,
          size: 6,
          status: '1',
          type: typeValue,
        }
      }).then((data) => {
        this.activityInfo = data.results
      }).catch(() => {
        this.activityInfo = []
        this.$parent.$parent.showMsg('获取活动信息失败！')
      })
    },
    async getSchoolList() {
      await this.$http.get('/allowany/application_index/', {
        params: {
          type: 1,
          status: '5',
          isdel: 0,
          isPub: 1,
          page: 1,
          size: 180,
          ordering: 'created'
        }
      }).then((data) => {
        this.schoolList = data.results
      })
    },
  },
  mounted() {
    this.searchFlash()
    this.getBannerInfo(5)
    this.getActivityInfo(6)
    this.getSchoolList()
  },

  beforeDestroy() {
    clearInterval(this.timer);
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/dialog.css";
@import "../../assets/css/common.css";

@import "../../assets/css/union.css";
@import "../../assets/css/pagination2.css";

@import "../../assets/css/header.css";
@import "../../assets/css/footer.css";

@import "../../assets/css/index2.css";

@import "../../assets/css/common(1).css";
@import "../../assets/css/notice-view.css";

#content {
  width: auto !important;
  background: #fff;
}

#center {
  width: auto !important;
  padding-bottom: 0px !important;
  min-height: 0px !important;
  overflow: hidden;
}
.title {
  width: unset;
}
.center_content {
  /*width: auto !important;*/
  margin: 0 auto;
  min-width: 1300px !important;
  padding: 10px !important;
  border: 0;
}
.banner-item {
  width: 100%;
  height: 100%;
  position: absolute;
  /* margin-left: -960px;
  left: 50%; */
}
#notice {
  height: 45px;
  line-height: 45px;
}

.notice-list {
  position: relative;
  left: 0;
}

.notice-list div {
  float: left;
  padding: 0 55px;
}

.notice-list img {
  width: 18px;
  height: 18px;
  position: relative;
  top: 0px;
  margin-right: 4px;
}

.dialog_btn {
  width: 70px;
  height: 36px;
  font-size: 14px;
}

.notice-list img {
  top: 4px;
}
.item1 {
  background: #fff;
  height: 494px;
  padding-top: 50px;
  box-sizing: border-box;
  > div {
    width: 1200px;
    height: 393px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .item1-left {
    width: 590px;
    cursor: pointer;
    > div {
      height: 405px;
    }
    .notice-swiper {
      width: 590px;
      height: 393px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      div {
        width: 590px;
        height: 58px;
        position: absolute;
        left: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.6);
        color: #fff;
        line-height: 58px;
        text-align: center;
        a {
          color: white;
        }
      }
    }
  }
  .item1-right {
    width: 558px;
    ul {
      &:first-child {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 20px;
        border-bottom: 1px solid #ededed;
        margin-top: -9px;
        li {
          height: 50px;
          cursor: pointer;
          &:first-child {
            margin-right: 20px;
          }
        }
        .active {
          font-weight: bold;
          color: #1f45a9;
          border-bottom: 2px solid #1f45a9;
        }
      }
      &:last-child {
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 16px;
          color: #333;
          margin: 25px 0;
          cursor: pointer;
          span {
            &:last-child {
              color: #999999;
            }
          }
          &:hover {
            color: #1f45a9;
            span {
              &:last-child {
                color: #1f45a9;
              }
            }
          }
        }
      }
    }
  }
}
.item2 {
  background: #f5f5f5;
  box-sizing: border-box;
  padding: 50px 0 15px;
  > div {
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    overflow: hidden;
    ul {
      &:first-child {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 20px;
        margin-top: -10px;
        margin-bottom: 20px;
        border-bottom: 2px solid #ededed;
        li {
          margin-right: 20px;
          height: 40px;
          line-height: 40px;
          cursor: pointer;
          position: relative;
          top: 2px;
        }
        .active {
          font-weight: bold;
          color: #1f45a9;
          border-bottom: 2px solid #1f45a9;

          z-index: 10;
        }
      }
      &:last-child {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        height: 143px;
        li {
          width: 336px;
          margin-right: 90px;
          font-size: 14px;
          color: #333;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          // margin-bottom: 40px;
          cursor: pointer;
          &:hover {
            p {
              color: #1f45a9;
            }
          }
          > div {
            width: 59px;
            height: 41px;
            background: #1f45a9;
            color: #fff;
            margin-right: 10px;
            padding-top: 5px;
            box-sizing: border-box;
            text-align: center;
            line-height: 36px;
            span {
              display: inline-block;
              font-size: 20px;
              text-align: center;
              line-height: normal;
              font-weight: bold;
              &:last-child {
                font-size: 12px;
                font-weight: normal;
              }
            }
          }
          p {
            width: 248px;
            height: 41px;
            line-height: 1.6;
          }
          &:nth-child(3n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
.item3 {
  background: #fff;
  height: 390px;
  padding-top: 50px;
  box-sizing: border-box;
  > div {
    width: 1200px;
    margin: 0 auto;
    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      li {
        transition: all 0.3s;
        cursor: pointer;
        .title {
          font-size: 20px;
          width: 367px;
          padding-bottom: 10px;
          border-bottom: 2px solid #ededed;
          line-height: normal;
          margin-bottom: 32px;
          box-sizing: border-box;
          span {
            font-weight: bold;
            color: #1f45a9;
            padding-bottom: 10px;
            border-bottom: 2px solid #1f45a9;
            line-height: normal;
            position: relative;
            top: 0px;
          }
        }
        &:hover > div:last-child {
          transform: scale(1.05);
          transition: 0.3s;
        }

        > div {
          &:last-child {
            transform: scale(1);
            transition: 0.3s;
            width: 367px;
            height: 216px;
            text-align: center;
            box-shadow: 0px 0 5px rgba(200, 200, 200, 0.4);
            > div {
              width: inherit;
              height: 216px;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}
.item5 {
  background: #f5f5f5;
}
.item4 {
  background: #fff;
  padding-top: 40px;
  padding-bottom: 130px;
  > div {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    .title {
      width: 1200px;
      line-height: 50px;
      text-align: center;
      border-bottom: 2px solid #ededed;
      font-size: 20px;
      color: #1f45a9;
      font-weight: bold;
      margin-bottom: 30px;
      box-sizing: border-box;
      span {
        display: inline-block;
        border-bottom: 2px solid #1f45a9;
        line-height: 50px;
        box-sizing: border-box;
        position: relative;
        top: 2px;
      }
    }
    .notice-swiper {
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      & > div {
        &:last-child {
          margin-top: 10px;
        }
      }
      .swiper-img {
        width: 118px;
        height: 118px;
      }

      img {
        width: 100%;
      }
    }
  }
}
.swiper-button-next,
.swiper-button-prev {
  top: 50%;
  transform: translateY(-50%);
  background: #fff;
}
.icon-zuo,
.icon-you {
  font-size: 60px;
  color: #6e6565;
}
</style>
<style lang="less">
.item1-left span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #fff;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 0 !important;
  .icon-zuo {
    color: #1f45a9;
  }
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 30px !important;
  .icon-you {
    color: #1f45a9;
  }
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  i {
    color: #6e6565;
  }
}
#center {
  margin-top: 0;
}
.swiper-bg {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
