<template>
  <div>
    <form id="searchSection" onsubmit="return false;" style="margin-top: 30px;">
      <div class="choice" style="width:100%">
        <div class="add-btn" @click="showInfoWindow">新建</div>
      </div>
    </form>
    <div id="list">
      <table v-if="this.tableData.length > 0">
        <tbody>
        <tr class="l_header">
          <th class="itemStatus">序号</th>
          <th class="itemStatus">职位</th>
          <th class="itemStatus">姓名</th>
          <th class="itemStatus">职务</th>
          <th class="itemStatus">工作单位</th>
          <th class="action" width="120">操作</th>
        </tr>
        <tr class="item" v-for="item in tableData" :key="item.id">
          <td>{{item.index}}</td>  
          <td class="itemStatus">{{item.position}}</td>
          <td class="itemStatus">{{item.username}}</td>
          <td class="itemStatus">{{item.duty}}</td>
          <td class="itemStatus">{{item.org_name}}</td>
          <td class="action">
            <a class="edit" href="javascript:;" @click="querySingle(item.id)">编辑</a>
            <a class="delete" href="javascript:;" @click="askDel(item.id)">删除</a>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="noitems" v-if="tableData.length == 0">没有数据</div>

      <pagination :cur="pageInfo.page" :all="pageInfo.all" :totalData="pageInfo.totalData"
                  :callback="pageCallback"></pagination>
    </div>
    <div class="form_overlay" id="form_overlay" :style="classShowInfoWindow">
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">内容简介</div>
        <div class="form_content">
          <form id="itemForm">
            <div class="item">
              <span class="label"><i class="require">*</i>职位:</span>
              <input v-model="formInfo.position" type="text" >
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>姓名:</span>
              <input v-model="formInfo.username" type="text" >
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>职务:</span>
              <input v-model="formInfo.duty" type="text" >
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>工作单位:</span>
              <input v-model="formInfo.org_name" type="text" >
            </div>
          </form>
        </div>
        <div class="form_control">
          <a href="javascript:;" class="button close" id="form_close" @click="hideInfoWindow">取消</a>
          <a href="javascript:;" class="button" id="form_submit" @click="saveInfo">提交</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../../../components/Pagination";

export default {
  name: "AdminOrgCommittee",
  components: {
    Pagination
  },
  data() {
    return {
      queryInfo: {
        page: 1,
        size: 10,
      },
      formInfo: {
        id: 0,
        position:'',
        username:'',
        duty:'',
        org_name:'',
      },
      tableData: [],
      tableDataCount: 0,
      showWindow: false
    }
  },
  computed: {
    classShowInfoWindow() {
      if (this.showWindow) {
        return {
          visibility: 'visible'
        }
      } else {
        return {}
      }
    },
    pageInfo() {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    }
  },
  methods: {
    async search() {
      await this.$http.get('/company_brief/', {
        params: this.queryInfo
      }).then((data) => {
        data.results.forEach((item, index) => {
          item.index = (this.queryInfo.page - 1) * this.queryInfo.size + index + 1
        })
        this.tableData = data.results
        this.tableDataCount = data.count
        
      }).catch(() => {
        this.$layer.msg('获取信息失败！')
      })
    },
    showInfoWindow() {
      this.showWindow = true
    },
    hideInfoWindow() {
      this.showWindow = false
      this.formInfo = {
        id: 0,
        position:'',
        username:'',
        duty:'',
        org_name:'',
      }
    },
    async saveInfo() {
      if(!this.formInfo.position){
        this.$parent.$parent.$parent.showMsg("请输入职位!")
        return false
      }
      if(!this.formInfo.username){
        this.$parent.$parent.$parent.showMsg("请输入姓名!")
        return false
      }
      if(!this.formInfo.duty){
        this.$parent.$parent.$parent.showMsg("请输入职务!")
        return false
      }
      if(!this.formInfo.org_name){
        this.$parent.$parent.$parent.showMsg("请输入工作单位!")
        return false
      }
      if(this.formInfo.id){
        await this.$http.put('/company_brief/' + this.formInfo.id + '/', this.formInfo).then((data) => {
          if (data.id) {
            this.$layer.msg("修改成功！")
            this.hideInfoWindow()
            this.search()
          } else {
            this.$layer.msg('修改失败！')
          }
        }).catch(() => {
          this.$layer.msg('修改失败！')
        })
      }else{
        await this.$http.post('/company_brief/', this.formInfo).then((data) => {
          if (data.id) {
            this.$layer.msg("添加成功！")
            this.hideInfoWindow()
            this.search()
          } else {
            this.$layer.msg('添加失败！')
          }
        }).catch(() => {
          this.$layer.msg('添加失败！')
        })
      }

    },
    async delInfo(id) {
      const url = '/company_brief/' + id + '/'
      await this.$http.delete(url, ).then(() => {
        this.$layer.msg('删除成功！');
        this.search()
      }).catch(() => {
        this.$layer.msg('删除失败！');
      })
    },
    askDel(id) {
      this.$parent.$parent.$parent.showConfirm('确定要删除吗？', () => {
        this.delInfo(id)
      });
    },
    async pageCallback(data) {
      this.queryInfo.page = data
      await this.search()
    },
    async querySingle(id) {
      const url = '/company_brief/' + id + '/'
      await this.$http.get(url).then((data) => {
        this.formInfo = {
          id: id,
          position: data.position,
          username: data.username,
          duty: data.duty,
          org_name: data.org_name,
        }
        this.showInfoWindow()
      }).catch(() => {
        this.$layer.msg('数据获取失败！');
      })
    },
  },
  mounted() {
    this.search()
  }
}
</script>

<style scoped>
.form_content .label{
  width: 160px;
  text-align: right;
  padding-right: 10px;
}
.rowline{
  display: block;
  margin: 0 auto;
  max-width: 600px;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
}
</style>
