<template>
  <div
    id="center"
    class="center_box"
  >
    <div
      id="main"
      class="main clearfix"
    >
      <div
        id="leftmenu"
        class="leftmenu"
      >
        <div class="overview">
          <div class="m-title"><i class="iconfont icon-lover"></i>后台管理</div>
          <ul class="a-menu">
            <li
              v-if="curPower[0] == 1"
              class="list"
              @click="updateMenuSelected"
              :class="this.menuSelected == '/admin_user' ? 'current' : ''"
            >
              <router-link
                class="m-btn"
                to="/admin_user"
              >
                <i class="iconfont icon-yonghuguanli"></i>用户管理<i class="iconfont pull-right"></i>
              </router-link>
            </li>
            <li
              v-if="curPower[1] == 1"
              class="list"
              @click="updateMenuSelected"
              :class="this.menuSelected == '/admin_member' ? 'current' : ''"
            >
              <router-link
                class="m-btn"
                to="/admin_member"
              >
                <i class="iconfont icon-huiyuanguanli"></i>会员管理<i class="iconfont pull-right"></i>
              </router-link>
            </li>
            <li
              v-if="curPower[2] == 1"
              class="list"
              @click="updateMenuSelected"
              :class="this.menuSelected == '/admin_apply' ? 'current' : ''"
            >
              <router-link
                class="m-btn"
                to="/admin_apply"
              >
                <i class="iconfont icon-qiyehuiyuan"></i>高校会员申请管理<i class="iconfont pull-right"></i>
              </router-link>
            </li>
            <!--            <li class="list" @click="updateMenuSelected" :class="this.menuSelected == '/admin_corporate_member' ? 'current' : ''">-->
            <!--              <router-link class="m-btn" to="/admin_corporate_member">-->
            <!--                <i class="iconfont icon-mianshiguanxiaozu"></i>企业会员管理<i class="iconfont pull-right"></i>-->
            <!--              </router-link>-->
            <!--            </li>-->
            <li
              v-if="curPower[12] == 1"
              class="list"
              @click="updateMenuSelected"
              :class="this.menuSelected.substr(0, 14) == '/admin_company' ? 'current' : ''"
            >
              <router-link
                class="m-btn"
                to="/admin_company_layout"
              >
                <i class="iconfont icon-qiyehuiyuan"></i>企业会员申请管理<i class="iconfont pull-right"></i>
              </router-link>
            </li>
            <li
              v-if="curPower[14] == 1"
              class="list"
              @click="updateMenuSelected"
              :class="this.menuSelected == '/admin_super_apply' ? 'current' : ''"
            >
              <router-link
                class="m-btn"
                to="/admin_super_apply"
              >
                <i class="iconfont icon-qiyehuiyuan"></i>高级会员管理<i class="iconfont pull-right"></i>
              </router-link>
            </li>
            <li
              v-if="curPower[14] == 1"
              class="list"
              @click="updateMenuSelected"
              :class="this.menuSelected == '/admin_enterprise_zone' ? 'current' : ''"
            >
              <router-link
                class="m-btn"
                to="/admin_enterprise_zone"
              >
                <i class="iconfont icon-qiyehuiyuan"></i>企业专区管理<i class="iconfont pull-right"></i>
              </router-link>
            </li>
            <!--            <li v-if="curPower[12] == 1" class="list" @click="updateMenuSelected" :class="this.menuSelected == '/admin_company_rofile' ? 'current' : ''">-->
            <!--              <router-link class="m-btn" to="/admin_company_rofile">-->
            <!--                <i class="iconfont icon-mianshiguanxiaozu"></i>企业会员简介<i class="iconfont pull-right"></i>-->
            <!--              </router-link>-->
            <!--            </li>-->
            <li
              v-if="curPower[13] == 1"
              class="list"
              @click="updateMenuSelected"
              :class="this.menuSelected.substr(0, 10) == '/admin_org' ? 'current' : ''"
            >
              <router-link
                class="m-btn"
                to="/admin_org_layout"
              >
                <i class="iconfont icon-tixiguanli"></i>组织机构管理<i class="iconfont pull-right"></i>
              </router-link>
            </li>
            <li
              v-if="curPower[3] == 1"
              class="list"
              @click="updateMenuSelected"
              :class="this.menuSelected == '/admin_school' ? 'current' : ''"
            >
              <router-link
                class="m-btn"
                to="/admin_school"
              >
                <i class="iconfont icon-xuexiaoguanli"></i>学校管理<i class="iconfont pull-right"></i>
              </router-link>
            </li>
            <li
              v-if="curPower[4] == 1"
              class="list"
              @click="updateMenuSelected"
              :class="this.menuSelected == '/admin_categories' ? 'current' : ''"
            >
              <router-link
                class="m-btn"
                to="/admin_categories"
              >
                <i class="iconfont icon-tixiguanli"></i>专业体系管理<i class="iconfont pull-right"></i>
              </router-link>
            </li>
            <li
              v-if="curPower[11] == 1"
              class="list"
              @click="updateMenuSelected"
              :class="this.menuSelected == '/admin_meeting' ? 'current' : ''"
            >
              <router-link
                class="m-btn"
                to="/admin_meeting"
              >
                <i class="iconfont icon-huiyiguanli"></i>会议管理<i class="iconfont pull-right"></i>
              </router-link>
            </li>
            <li
              v-if="curPower[11] == 1"
              class="list"
              @click="updateMenuSelected"
              :class="this.menuSelected == '/admin_template' ? 'current' : ''"
            >
              <router-link
                class="m-btn"
                to="/admin_template"
              >
                <i class="iconfont icon-huiyiguanli"></i>企业模板审核<i class="iconfont pull-right"></i>
              </router-link>
            </li>
            <li
              v-if="curPower[5] == 1"
              class="list"
              @click="updateMenuSelected"
              :class="this.menuSelected == '/admin_guid' ? 'current' : ''"
            >
              <router-link
                class="m-btn"
                to="/admin_guid"
              >
                <i class="iconfont icon-data"></i>指南管理<i class="iconfont pull-right"></i>
              </router-link>
            </li>
            <!--            <li v-if="curPower[6] == 1" class="list" @click="updateMenuSelected" :class="this.menuSelected == '/admin_option' ? 'current' : ''">-->
            <!--              <router-link class="m-btn" to="/admin_option">-->
            <!--                <i class="iconfont icon-data"></i>征集意见<i class="iconfont pull-right"></i>-->
            <!--              </router-link>-->
            <!--            </li>-->
            <li
              v-if="curPower[7] == 1"
              class="list"
              @click="updateMenuSelected"
              :class="this.menuSelected == '/admin_statistics' ? 'current' : ''"
            >
              <router-link
                class="m-btn"
                to="/admin_statistics"
              >
                <i class="iconfont icon-tongjifenxi"></i>统计分析<i class="iconfont pull-right"></i>
              </router-link>
            </li>
            <li
              v-if="curPower[8] == 1"
              class="list"
              @click="updateMenuSelected"
              :class="this.menuSelected == '/admin_flash' ? 'current' : ''"
            >
              <router-link
                class="m-btn"
                to="/admin_flash"
              >
                <i class="iconfont icon-lunboguanli"></i>轮播管理<i class="iconfont pull-right"></i>
              </router-link>
            </li>
            <li
              v-if="curPower[9] == 1"
              class="list"
              @click="updateMenuSelected"
              :class="this.menuSelected == '/admin_info' ? 'current' : ''"
            >
              <router-link
                class="m-btn"
                to="/admin_info"
              >
                <i class="iconfont icon-zixunguanli"></i>资讯管理<i class="iconfont pull-right"></i>
              </router-link>
            </li>
            <li
              v-if="curPower[10] == 1"
              class="list"
              @click="updateMenuSelected"
              :class="this.menuSelected == '/admin_notice' ? 'current' : ''"
            >
              <router-link
                class="m-btn"
                to="/admin_notice"
              >
                <i class="iconfont icon-tongzhiguanli"></i>通知管理<i class="iconfont pull-right"></i>
              </router-link>
            </li>
            <li
                v-if="curPower[15] == 1"
                class="list"
                @click="updateMenuSelected"
                :class="this.menuSelected == '/admin_research' ? 'current' : ''"
            >
              <router-link
                  class="m-btn"
                  to="/admin_research"
              >
                <i class="iconfont icon-zixunguanli"></i>问卷管理<i class="iconfont pull-right"></i>
              </router-link>
            </li>

          </ul>
        </div>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminLayout",
  data() {
    return {
      menuSelected: '/profile',
      rolePower: {
        // 1用户 2会员 3高校会员申请 4学校 5专业 6指南 7征集 8统计 9轮播 10资讯 11通知 12会议 13企业申请管理 14组织机构管理 15高级会员申 16问卷管理
        '0': [1, 1, 1, 1, 1, 1, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1],
        '1': [1, 1, 1, 1, 0, 1, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1],
        '2': [0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0],
        '3': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        '4': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        '5': [0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0 ,0, 0, 0, 0, 0],
        '6': [1, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        '7': [1, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        '8': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }
    }
  },
  computed: {
    curPower (){
      return this.rolePower[this.$store.state.userInfo.role]
    }
  },
  methods: {
    updateMenuSelected(){
      const url = window.location.href
      const arrUrl = url.split('//')
      const start = arrUrl[1].indexOf('/')
      let relUrl = arrUrl[1].substring(start)

      if (relUrl.indexOf('?') !== -1) {
        relUrl = relUrl.split('?')[0]
      }
      relUrl = relUrl.replace('#', '')
      this.menuSelected = relUrl
    },
    toAdmin() {
      this.$router.push('/admin_layout')
    }
  },
  mounted() {
    this.updateMenuSelected()
  }
}
</script>

<style scoped>
/*@import "../../assets/css/dialog.css";*/
@import "../../assets/css/common.css";
@import "../../assets/css/header.css";
@import "../../assets/css/footer.css";
@import "../../assets/css/pagination.css";
/*!*@import "../../assets/css/common(1).css";*!*/
@import "../../assets/css/form.css";
@import "../../assets/css/panel.css";
@import "../../assets/css/union.css";
/*!*@import "../../assets/css/panel.css";*!*/
@import "../../assets/css/leftmenu.css";
/*!*@import "../../assets/css/laydate.css";*!*/
/*!*@import "../../assets/css/laydate(1).css";*!*/
/*!*@import "../../assets/css/user.css";*!*/

.main {
  width: auto;
  display: flex;
}
</style>
