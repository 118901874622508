<template>
  <div>
    <form
      id="searchSection"
      onsubmit="return false;"
      style="margin-top: 30px;"
    >
      <div
        class="choice"
        style="width:100%"
      >
        <select
          name="status"
          class="s-select separate"
          v-model="queryInfo.type"
        >
          <option value="">选择类型</option>
          <option value="1">理事长单位</option>
          <option value="2">副理事长单位</option>
          <option value="3">联盟理事会</option>
          <option value="4">学术委员会</option>
          <option value="5">联盟秘书处</option>
        </select>
        <input
          type="text"
          name="title"
          class="s-input separate"
          placeholder="职位"
          v-model="queryInfo.position"
        >
        <input
          type="text"
          name="code"
          class="s-input separate"
          placeholder="姓名"
          v-model="queryInfo.name"
        >
        <input
          type="text"
          name="code"
          class="s-input separate"
          placeholder="工作单位"
          v-model="queryInfo.org_name"
        >
        <input
          type="button"
          class="s-btn"
          value="搜索"
          @click="search"
        >

        <div
          class="add-btn"
          @click="showInfoWindow"
        >新建</div>

      </div>
    </form>
    <div id="list">
      <table v-if="this.tableData.length > 0">
        <tbody>
          <tr class="l_header">
            <th class="itemStatus">类型</th>
            <th class="itemStatus">职位</th>
            <th class="itemStatus">姓名</th>
            <th class="itemStatus">职称</th>
            <th class="itemStatus">职务</th>
            <th class="itemStatus">工作单位</th>
            <th
              class="action"
              width="180"
            >操作</th>
          </tr>
          <tr
            class="item"
            v-for="item in tableData"
            :key="item.id"
          >
            <td class="itemStatus">{{ formatType(item.type) }}</td>
            <td class="itemStatus">{{ item.position }}</td>
            <td class="itemStatus">{{ item.name }}</td>
            <td class="itemStatus">{{ item.level }}</td>
            <td class="itemStatus">{{ item.duty }}</td>
            <td class="itemStatus">{{ item.org_name }}</td>
            <td class="action">
              <a
                class="edit"
                href="javascript:;"
                @click="querySingle(item.id)"
              >编辑</a>
              <a
                class="delete"
                href="javascript:;"
                @click="askDel(item.id)"
              >删除</a>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="noitems"
        v-if="tableData.length == 0"
      >没有数据</div>

      <pagination
        :cur="pageInfo.page"
        :all="pageInfo.all"
        :totalData="pageInfo.totalData"
        :callback="pageCallback"
      ></pagination>
    </div>
    <div
      class="form_overlay"
      id="form_overlay"
      :style="classShowInfoWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">联盟理事、秘书处及学术委员会添加</div>
        <div class="form_content">
          <form id="itemForm">
            <div class="item">
              <span class="label">理事长单位类型:</span>
              <select
                name="attr"
                v-model="formInfo.type"
              >
                <option value="1">理事长单位</option>
                <option value="2">副理事长单位</option>
                <option value="3">联盟理事会</option>
                <option value="4">学术委员会</option>
                <option value="5">联盟秘书处</option>
              </select>
            </div>
            <div class="item">
              <span class="label">职位:</span>
              <input
                type="text"
                v-model="formInfo.position"
              >
            </div>
            <div class="item">
              <span class="label">姓名:</span>
              <input
                type="text"
                v-model="formInfo.name"
              >
            </div>
            <div class="item">
              <span class="label">职称:</span>
              <input
                type="text"
                v-model="formInfo.level"
              >
            </div>
            <div class="item">
              <span class="label">职务:</span>
              <input
                type="text"
                v-model="formInfo.duty"
              >
            </div>
            <div class="item">
              <span class="label">工作单位:</span>
              <input
                type="text"
                v-model="formInfo.org_name"
              >
            </div>
          </form>
        </div>
        <div class="form_control">
          <a
            href="javascript:;"
            class="button close"
            id="form_close"
            @click="hideInfoWindow"
          >取消</a>
          <a
            href="javascript:;"
            class="button"
            id="form_submit"
            @click="saveInfo"
          >提交</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../../../components/Pagination";

export default {
  name: "AdminOrgLeader",
  components: {
    Pagination
  },
  data() {
    return {
      queryInfo: {
        page: 1,
        size: 10,
        type: '',
        position: '',
        name: '',
        org_name: '',
      },
      formInfo: {
        id: 0,
        type: 1,
        position: '',
        name: '',
        level: '',
        duty: '',
        org_name: '',
      },
      tableData: [],
      tableDataCount: 0,
      showWindow: false
    }
  },
  computed: {
    classShowInfoWindow() {
      if (this.showWindow) {
        return {
          visibility: 'visible'
        }
      } else {
        return {}
      }
    },
    pageInfo() {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    }
  },
  methods: {
    async search() {
      await this.$http.get('/org_leader/', {
        params: this.queryInfo
      }).then((data) => {
        console.log(data)
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$layer.msg('获取信息失败！')
      })
    },
    showInfoWindow() {
      this.showWindow = true
    },
    hideInfoWindow() {
      this.showWindow = false
      this.formInfo = {
        id: 0,
        type: 1,
        position: '',
        name: '',
        level: '',
        duty: '',
        org_name: '',
      }
    },
    async saveInfo() {
      // if(!this.formInfo.position){
      //   this.$parent.$parent.$parent.showMsg("请输入职位!")
      //   return false
      // }
      // if(!this.formInfo.name){
      //   this.$parent.$parent.$parent.showMsg("请输入姓名!")
      //   return false
      // }
      // if(!this.formInfo.level){
      //   this.$parent.$parent.$parent.showMsg("请输入职称!")
      //   return false
      // }
      // if(!this.formInfo.duty){
      //   this.$parent.$parent.$parent.showMsg("请输入职务!")
      //   return false
      // }
      // if(!this.formInfo.org_name){
      //   this.$parent.$parent.$parent.showMsg("请输入工作单位!")
      //   return false
      // }

      if(this.formInfo.id){
        await this.$http.put('/org_leader/' + this.formInfo.id + '/', this.formInfo).then((data) => {
          if (data.id) {
            this.$layer.msg("修改成功！")
            this.hideInfoWindow()
            this.search()
          } else {
            this.$layer.msg('修改失败！')
          }
        }).catch((error) => {
          console.log(error)
          this.$layer.msg('修改失败！')
        })
      }else{
        await this.$http.post('/org_leader/', this.formInfo).then((data) => {
          if (data.id) {
            this.$layer.msg("添加成功！")
            this.hideInfoWindow()
            this.search()
          } else {
            this.$layer.msg('添加失败！')
          }
        }).catch((error) => {
          console.log(error)
          this.$layer.msg('添加失败！')
        })
      }


    },
    async delInfo(id) {
      const url = '/org_leader/' + id + '/'
      await this.$http.delete(url, ).then((data) => {
        console.log(data)
        this.$layer.msg('删除成功！');
        this.search()
      }).catch((error) => {
        console.log(error)
        this.$layer.msg('删除失败！');
      })
    },
    askDel(id) {
      this.$parent.$parent.$parent.showConfirm('确定要删除吗？', () => {
        this.delInfo(id)
      });
    },
    async pageCallback(data) {
      this.queryInfo.page = data
      await this.search()
    },
    formatType(type){
      let dictType = {
        "1": "理事长单位",
        "2": "副理事长单位",
        "3": "联盟理事会",
        "4": "学术委员会",
        "5": "联盟秘书处",
      }
      if(type.toString() in dictType){
        return dictType[type.toString()]
      }else{
        return ""
      }
    },
    async querySingle(id) {
      const url = '/org_leader/' + id + '/'
      await this.$http.get(url).then((data) => {
        console.log(data)
        this.formInfo = {
          id: id,
          type: data.type,
          position: data.position,
          name: data.name,
          level: data.level,
          duty: data.duty,
          org_name: data.org_name,
        }
        this.showInfoWindow()
      }).catch((error) => {
        console.log(error)
        this.$layer.msg('数据获取失败！');
      })
    },
  },
  mounted() {
    this.search()
  }
}
</script>

<style scoped>
.form_content .label {
  width: 120px;
  text-align: right;
  padding-right: 10px;
}
</style>
