<template>
  <div id="panel" class="panel" style="min-height: 696px;">
    <form id="searchSection" onsubmit="return false;">
      <select name="status" class="s-select separate" v-model="queryInfo.category">
        <option value="">选择类型</option>
        <option value="1">智谱教研</option>
      </select>
      <input type="text" class="s-input separate" placeholder="申请人姓名" v-model="queryInfo.name">
      <input type="text" class="s-input separate" placeholder="单位" v-model="queryInfo.org">
      <input type="text" class="s-input separate" placeholder="联系电话" v-model="queryInfo.phone">
      <input type="submit" class="s-btn" value="搜索" @click="search">
    </form>
    <div id="list">
      <table v-if="tableData.length > 0">
        <tbody>
        <tr class="l_header">
          <th class="itemStatus">姓名</th>
          <th class="itemStatus">单位</th>
          <th class="itemStatus">联系电话</th>
          <th class="itemStatus">详情内容</th>
          <th class="itemStatus" width="100">时间</th>
          <th class="itemStatus" width="80">类型</th>
          <th class="action" width="60">操作</th>
        </tr>
        <tr class="item" v-for="item in tableData" :key="item.id">
          <td class="itemStatus">{{ item.name }}</td>
          <td class="itemStatus">{{ item.org }}</td>
          <td class="itemStatus">{{ item.phone }}</td>
          <td class="itemStatus">{{ item.content.toString().length > 10 ? item.content.substr(0, 9) + '...' : item.content }}</td>
          <td class="itemStatus">{{ item.created.substr(0, 10) }} <br> {{ item.created.substr(11, 8) }} </td>
          <td class="itemStatus">智谱教研</td>
          <td class="action">
            <a class="set" href="javascript:;" @click="openWinApply(item)">查看</a>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="noitems" v-if="tableData.length == 0">没有数据</div>
    </div>
    <pagination :cur="pageInfo.page" :all="pageInfo.all" :totalData="pageInfo.totalData"
                :callback="pageCallback"></pagination>

    <div class="win-apply" :style="winApply.show == true ? 'display: block' : 'display: none'">
      <div class="win-apply-bg"></div>
      <div class="win-apply-panel">
        <div class="win-apply-header">
          <div>详情</div>
          <img src="../../assets/images/work_news/btn_close.png" @click="closeWinApply">
        </div>
        <div class="win-apply-body">
          <div>时间：{{ winApply.formInfo.created.substr(0, 19).replace('T', ' ') }}</div>
          <div class="item-margin">类型：智谱教研</div>
          <div>姓名：{{ winApply.formInfo.name }}</div>
          <div>单位：{{ winApply.formInfo.org }}</div>
          <div class="item-margin">联系电话：{{ winApply.formInfo.phone }}</div>
          <div class="item-margin item-content">
            <div>详情内容：</div>
            <textarea v-model="winApply.formInfo.content"></textarea>
          </div>
        </div>
        <div class="win-apply-footer">
          <div class="win-apply-btn win-apply-btn-close" @click="closeWinApply">关闭</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../../components/Pagination"

export default {
  name: "AdminResearch",
  components: {
    Pagination
  },
  data () {
    return {
      queryInfo: {
        page: 1,
        size: 10,
        category: '',
        name: '',
        org: '',
        phone: '',
      },
      tableData: [],
      tableDataCount: 0,
      winApply: {
        show: false,
        formInfo: {
          category: '',
          name: '',
          org: '',
          phone: '',
          content: '',
          created: ''
        }
      }
    }
  },
  methods: {
    async search() {
      await this.$http.get('/research/', {
        params: this.queryInfo
      }).then((data) => {
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$parent.$parent.showMsg('获取通知信息失败！')
      })
    },
    async pageCallback(data) {
      this.queryInfo.page = data
      await this.search()
    },
    openWinApply (item) {
      this.winApply = {
        show: true,
        formInfo: {
          category: item.category,
          name: item.name,
          org: item.org,
          phone: item.phone,
          content: item.content,
          created: item.created
        }
      }
    },
    closeWinApply () {
      this.winApply.show = false
    }
  },
  mounted() {
    this.search()
  },
  computed: {
    pageInfo() {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    }
  }
}
</script>

<style lang="less" scoped>
.win-apply {
  .win-apply-bg {
    position: fixed;
    left: 0;
    top: 0;
    //height: 100%;
    //width: 100%;
    bottom: 0;
    right: 0;
    background-color: black;
    opacity: .2;
  }
  .win-apply-panel {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -60%);
    width: 300px;
    min-height: 380px;
    background-color: white;
    border-radius: 5px;
    padding-bottom: 10px;
    .win-apply-header {
      display: flex;
      height: 60px;
      line-height: 60px;
      padding: 0px 22px;
      font-size: 18px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: #333333;
      img {
        width: 16px;
        height: 16px;
      }
    }
    .win-apply-body {
      padding: 0px 22px;
      color: #888888;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .item-margin {
        margin-bottom: 25px;
      }
      .item-content {
        min-height: 70px;
        textarea {
          width: 100%;
          height: 100px;
          border: none;
          resize: none;
          color: #888;
        }
      }
    }
    .win-apply-footer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 0px 30px;
      .win-apply-btn {
        width: 60px;
        height: 32px;
        line-height: 32px;
        border-radius: 3px;
        text-align: center;
        margin-left: 15px;
      }
      .win-apply-btn-close {
        border: 1px solid #CCCCCC;
        color: #606267;
      }
      .win-apply-btn-confirm {
        background-color: #00a0e9;
        color: white;
      }
    }
  }
}
</style>