<template>
  <ul id="pagination" class="cN-pagination">
    <li v-if="cur > 1" class="prev"><a href="javascript:;" rel="prev" @click="prevPage">« 上一页</a></li>

    <li v-for="index in indexs" v-bind:key="index" v-bind:class="{ selected: cur == index}">
      <a v-if="cur != index" v-on:click="btnClick(index)">{{ index }}</a>
      {{ index == cur ? cur : '' }}
    </li>

    <li v-if="cur < all" class="next"><a href="javascript:;" rel="prev" @click="nextPage">下一页 »</a>
    </li><span class="page_total">共<b>{{ totalData }}</b>条</span>
  </ul>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    cur: {
      type: [String, Number],
      required: true
    },
    all: {
      type: [String, Number],
      required: true
    },
    totalData: {
      type: [String, Number],
      required: true
    },
    callback: {
      default() {
        return function callback() {
          // todo
        }
      }
    }
  },
  computed: {
    indexs() {
      var left = 1
      var right = this.all
      var ar = []
      if (this.all >= 11) {
        if (this.cur > 5 && this.cur < this.all - 4) {
          left = this.cur - 5
          right = this.cur + 4
        } else {
          if (this.cur <= 5) {
            left = 1
            right = 10
          } else {
            right = this.all
            left = this.all - 9
          }
        }
      }
      while (left <= right) {
        ar.push(left)
        left++
      }
      return ar
    }
  },
  methods: {
    btnClick(page) {
      if (page != this.cur) {
        this.callback(page)
      }
    },
    nextPage(){
      this.callback(this.cur + 1)
    },
    prevPage(){
      this.callback(this.cur - 1)
    }
  }
}
</script>

<style scoped>
.cN-pagination {
  margin-top: 40px;
}
</style>
