<template>
  <div id="center">
    <div class="subBanner1"></div>
    <div id="subCenter" style="min-height: 430px;">
      <div id="content" class="content">
        <div class="subLeft">
          <dl>
            <dt>工作动态
              <p>news</p>
            </dt>
            <dd @click="updateMenuSelected" :class="this.menuSelected == '/www/activity_layout/notice' ? 'cur' : ''">
              <router-link to="/www/activity_layout/notice">通知公告</router-link>
            </dd>
            <dd @click="updateMenuSelected" :class="this.menuSelected == '/www/activity_layout/meeting' ? 'cur' : ''">
              <router-link to="/www/activity_layout/meeting">会议研讨</router-link>
            </dd>
            <dd @click="updateMenuSelected" :class="this.menuSelected == '/www/activity_layout/board' ? 'cur' : ''">
              <router-link to="/www/activity_layout/board">专业委员会</router-link>
            </dd>
            <dd @click="updateMenuSelected" :class="this.menuSelected == '/www/activity_layout/plane' ? 'cur' : ''">
              <router-link to="/www/activity_layout/plane">双百计划</router-link>
            </dd>
            <dd @click="updateMenuSelected" :class="this.menuSelected == '/www/activity_layout/subject' ? 'cur' : ''">
              <router-link to="/www/activity_layout/subject">课题研究</router-link>
            </dd>
            <dd @click="updateMenuSelected" :class="this.menuSelected == '/www/activity_layout/guid' ? 'cur' : ''">
              <router-link to="/www/activity_layout/guid">建设指南</router-link>
            </dd>
            <dd @click="updateMenuSelected" :class="this.menuSelected == '/www/activity_layout/rule' ? 'cur' : ''">
              <router-link to="/www/activity_layout/rule">建设规范</router-link>
            </dd>
          </dl>
        </div>

        <router-view></router-view>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "ActivityLayout",
  data() {
    return {
      menuSelected: ''
    }
  },
  methods: {
    updateMenuSelected(){
      const url = window.location.href
      const arrUrl = url.split('//')
      const start = arrUrl[1].indexOf('/')
      let relUrl = arrUrl[1].substring(start)

      if (relUrl.indexOf('?') !== -1) {
        relUrl = relUrl.split('?')[0]
      }
      relUrl = relUrl.replace('#', '')
      this.menuSelected = relUrl
    },
  },
  mounted() {
    this.updateMenuSelected()
  }
}
</script>

<style scoped>
#center {
  width: auto !important;
}
</style>
