<template>
  <div>
    <div class="breadcrumb-nav">
      <a href="/">首页</a>&gt;&gt;
      <a href="/www/organization/chairman">组织机构</a>&gt;&gt;联盟理事会
    </div>
    <div class="subRight">
      <div class="subRightTit">
        <span
          :class="category == 1 ? 'active' : ''"
          @click="category=1"
        >理事长单位</span>
        <span
          :class="category == 2 ? 'active' : ''"
          @click="category=2"
          style="left: 140px"
        >理事单位</span>

      </div>

      <div
        class="con"
        v-show="category == 1"
      >
        <table
          cellspacing="0"
          cellpadding="0"
          class="orgTbl m-hide"
        >
          <tbody>
            <tr>
              <th
                colspan="5"
                width="759"
              >理事长单位</th>
            </tr>
            <tr>
              <td class="thead">职位</td>
              <td class="thead">姓名</td>
              <td class="thead">职称</td>
              <td class="thead">职务</td>
              <td class="thead">工作单位</td>
            </tr>
            <tr
              v-for="item in data1"
              :key="item.id"
            >
              <td>{{ item.position }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.level }}</td>
              <td>{{ item.duty }}</td>
              <td>{{ item.org_name }}</td>
            </tr>
            <tr>
              <th
                colspan="5"
                width="759"
              >副理事长单位（按单位名称拼音顺序排列）</th>
            </tr>
            <tr>
              <td class="thead">职位</td>
              <td class="thead">姓名</td>
              <td class="thead">职称</td>
              <td class="thead">职务</td>
              <td class="thead">工作单位</td>
            </tr>
            <tr
              v-for="item in data2"
              :key="item.id"
            >
              <td>{{ item.position }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.level }}</td>
              <td>{{ item.duty }}</td>
              <td>{{ item.org_name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="con"
        v-show="category == 2"
      >
        <table
          cellspacing="0"
          cellpadding="0"
          class="orgTbl m-hide"
        >
          <tbody>
            <tr>
              <th
                colspan="5"
                width="759"
              >理事单位（按单位名称拼音顺序排列）</th>
            </tr>
            <tr>
              <td class="thead">职位</td>
              <td class="thead">姓名</td>
              <td class="thead">职称</td>
              <td class="thead">职务</td>
              <td class="thead">工作单位</td>
            </tr>

            <tr
              v-for="item in data"
              :key="item.id"
            >
              <td>{{ item.position }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.level }}</td>
              <td>{{ item.duty }}</td>
              <td>{{ item.org_name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Council",
  data (){
    return {
      category: 1,
      data: [],
      data1: [],
      data2: []
    }
  },
  methods: {
    async getData(){
      await this.$http.get('/allowany/org_leader/', {
        params: {
          page: 1,
          size: 10000,
          type: 3,
        }
      }).then((data) => {
        console.log(data)
        this.data = data.results
      })
    },

    async getData2(){
      await this.$http.get('/allowany/org_leader/', {
        params: {
          page: 1,
          size: 10000,
          type: 1,
        }
      }).then((data) => {
        this.data1 = data.results
      })

      await this.$http.get('/allowany/org_leader/', {
        params: {
          page: 1,
          size: 10000,
          type: 2,
        }
      }).then((data) => {
        this.data2 = data.results
      })
    }
  },
  mounted() {
    this.getData()
    this.getData2()
  }
}
</script>

<style scoped>
td {
  width: 60px;
}
td:nth-child(4) {
  width: 200px;
}
td:last-child {
  width: 200px;
}

.subRightTit span {
  color: #777777;
  border-bottom: none;
  cursor: pointer;
}

.subRightTit span.active {
  color: #333;
  border-bottom: #444 2px solid;
}
</style>
