<template>
  <div id="panel" class="panel" style="min-height: 696px;">
    <form id="searchSection" onsubmit="return false;">
      <div class="search-line clearfix">
        <select name="role" class="s-select separate" id="role" v-model="queryInfo.role">
          <option value="">选择角色</option>
          <option value="1">联盟管理员</option>
          <option value="2">联盟运营管理员</option>
          <option value="3">教师</option>
          <option value="4">社会人士</option>
          <option value="5">指南负责人</option>
          <option value="6">领域负责人</option>
          <option value="7">专业类负责人</option>
          <option value="8">专家</option>
        </select>
        <select name="province" class="s-select separate" id="province1" v-model="queryInfo.province" @change="getSchoolForSearch">
          <option value="">选择省份</option>
          <option value="0">北京市</option>
          <option value="1">天津市</option>
          <option value="2">河北省</option>
          <option value="3">山西省</option>
          <option value="4">内蒙古自治区</option>
          <option value="5">辽宁省</option>
          <option value="6">吉林省</option>
          <option value="7">黑龙江省</option>
          <option value="8">上海市</option>
          <option value="9">江苏省</option>
          <option value="10">浙江省</option>
          <option value="11">安徽省</option>
          <option value="12">福建省</option>
          <option value="13">江西省</option>
          <option value="14">山东省</option>
          <option value="15">河南省</option>
          <option value="16">湖北省</option>
          <option value="17">湖南省</option>
          <option value="18">广东省</option>
          <option value="19">广西壮族自治区</option>
          <option value="20">海南省</option>
          <option value="21">重庆市</option>
          <option value="22">四川省</option>
          <option value="23">贵州省</option>
          <option value="24">云南省</option>
          <option value="25">西藏自治区</option>
          <option value="26">陕西省</option>
          <option value="27">甘肃省</option>
          <option value="28">青海省</option>
          <option value="29">宁夏回族自治区</option>
          <option value="30">新疆维吾尔自治区</option>
          <option value="31">新疆生产建设兵团</option>
          <option value="32">香港特别行政区</option>
          <option value="33">澳门特别行政区</option>
          <option value="34">台湾省</option>
        </select>
        <select id="school1" name="school" class="s-select separate" v-model="queryInfo.school">
          <option value="">选择学校</option>
          <option v-for="item in dictSchool_for_search" :key="item.id" :value="item.id">{{ item.title }}</option>
        </select>
        <select name="gender" class="s-select separate" v-model="queryInfo.gender">
          <option value="">选择性别</option>
          <option value="M">男</option>
          <option value="F">女</option>

        </select>
        <select name="disable" class="s-select separate" v-model="queryInfo.disable">
          <option value="">选择状态</option>
          <option value="0">启用</option>
          <option value="1">禁用</option>
        </select>
      </div>
      <div class="search-line clearfix">
        <input type="text" name="mobile" class="s-input separate" placeholder="手机号" v-model="queryInfo.mobile">
        <input type="text" name="username" class="s-input separate" placeholder="用户名" v-model="queryInfo.username">
        <input type="text" name="name" class="s-input separate" placeholder="姓名" v-model="queryInfo.name">
        <input type="submit" class="s-btn" value="搜索" @click="search">
      </div>
      <hr>
      <div class="search-line clearfix">
        <div class="add-btn" style="margin-bottom:0;" @click="showAddWindow">添加用户</div>
        <div class="import-btn" id="batchUpload" @click="showUploadWindow">导入</div>
      </div>
    </form>

    <div id="list">
      <table v-if="this.tableData.length > 0">
        <tbody>
        <tr class="l_header">
          <th width="35px"><a href="javascript:;" class="a-link" @click="ckAll">全选</a></th>
          <th class="itemTitle">用户名</th>
          <th class="itemTitle">姓名</th>
          <th class="itemTitle">手机</th>
          <th class="itemTitle">省份</th>
          <th class="itemCategory" width="40">性别</th>
          <th class="itemCategory">学校</th>
          <th class="itemCategory">行业</th>
          <th class="itemCategory">角色</th>
          <th class="itemCategory">注册时间</th>
          <th class="itemCategory">状态</th>
          <th class="action" width="130">操作</th>
        </tr>
        <tr class="item" v-for="item in tableData" :key="item.id">
          <td><input type="checkbox" name="chose" :value="item.id" v-model="ckUser" @change="ckChange"></td>
          <td class="itemId">{{ item.username }}</td>
          <td class="itemTitle">{{ item.name }}</td>
          <td class="itemTitle">{{ item.mobile }}</td>
          <td class="itemTitle">{{ provinceFormater(item.province) }}</td>
          <td class="itemTitle">{{ genderFormater(item.gender) }}</td>
          <td class="itemTitle">{{ schoolFormater(item.school) }}</td>
          <td class="itemTitle">{{ industryFormater(item.industry) }}</td>
          <td class="itemTitle">{{ roleFormater(item.role) }}</td>
          <td class="itemTitle">{{ item.created ? item.created.substr(0, 10) : '' }}</td>
          <td :class="'itemStatus itemStatus' + (item.disable + 1)">{{ item.disable == 1 ? '已禁用' : '已启用' }}</td>
          <td class="action" style="font-size:12px">
            <a class="edit" href="javascript:;" @click="querySingle(item.id)">编辑</a>
            <a v-if="item.disable == 0" class="disable" href="javascript:;"
               @click="askDisable(item.user_id, item.id, 0, 1)">禁用</a>
            <a v-if="item.disable == 1" class="undisable" href="javascript:;"
               @click="askDisable(item.user_id, item.id, 1, 0)">启用</a>
            <br>
            <a class="other1" href="javascript:;" @click="askResetPwd(item.user_id, item.id)">重置密码</a>
            <br>
            <a class="modify" href="javascript:;" @click="getPhoneBind(item.id)">是否绑定手机</a>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="noitems" v-if="this.tableData.length == 0">没有用户</div>
    </div>

    <ul class="batchBtns" id="batchBtns">
      <li><input type="button" class="disable-btn" id="batchDisableBtn" value="批量禁用" @click="batchDisable"></li>
      <li><input type="button" class="enable-btn" id="batchEnableBtn" value="批量启用" @click="batchUnDisable"></li>
    </ul>

    <pagination :cur="pageInfo.page" :all="pageInfo.all" :totalData="pageInfo.totalData"
                :callback="pageCallback"></pagination>

    <div class="form_overlay" :style="classShowAddWindow">
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">用户管理</div>
        <div class="form_content">
          <form id="itemForm">
            <div class="item">
              <span class="label"><i class="require">*</i>身份:</span>
              <input type="hidden" v-model="formInfo.id">
              <select name="role" v-model="formInfo.role">
                <option value="">选择角色</option>
                <option :value="item['value']" v-for="item in addUserRole" :key="item['value']">{{ item['name'] }}</option>
              </select>
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>用户名:</span>
              <input type="text" name="username" v-model="formInfo.username">
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>姓名:</span>
              <input type="text" name="name" v-model="formInfo.name">
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>密码:</span>
              <input type="password" name="password1" autocomplete="off" v-model="formInfo.password1">
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>确认密码:</span>
              <input type="password" name="password2" autocomplete="off" v-model="formInfo.password2">
            </div>
            <div class="item " id="cat01Box" :class="['6', '7'].indexOf(formInfo.role) >= 0 ? '' : 'hide'">
              <span class="label"><i class="require">*</i>所属领域:</span>
              <select name="categorie01" v-model="formInfo.categorie01" @change="getCategory('add')">
                <option value="">请选择领域</option>
                <option v-for="item in dictDomain" :key="item.id" :value="item.code">{{ item.title }}</option>
              </select>
            </div>
            <div class="item " id="cat02Box" :class="['7'].indexOf(formInfo.role) >= 0 ? '' : 'hide'">
              <span class="label"><i class="require">*</i>所属专业类:</span>
              <select name="categorie02" v-model="formInfo.categorie02">
                <option value="">请选择专业类</option>
                <option v-for="item in dictCategory" :key="item.id" :value="item.code">{{ item.title }}</option>
              </select>
            </div>
            <div class="item">
              <span class="label">性别:</span>
              <select name="gender" v-model="formInfo.gender">
                <option value="">请选择</option>
                <option value="M">男</option>
                <option value="F">女</option>
              </select>
            </div>
            <div class="item">
              <span class="label">出生年月:</span>
              <input type="date" name="birth" class="date_box" autocomplete="off" v-model="formInfo.birth">
            </div>
            <div class="item">
              <span class="label">邮箱:</span>
              <input type="text" name="email" v-model="formInfo.email">
            </div>
            <div class="item">
              <span class="label">手机:</span>
              <input type="text" name="mobile" v-model="formInfo.mobile">
            </div>
            <div class="item ts-unit-1">
              <span class="label">省份:</span>
              <select name="province" id="province2" v-model="formInfo.province" @change="getSchoolForAdd">
                <option value="">选择省份</option>
                <option value="0">北京市</option>
                <option value="1">天津市</option>
                <option value="2">河北省</option>
                <option value="3">山西省</option>
                <option value="4">内蒙古自治区</option>
                <option value="5">辽宁省</option>
                <option value="6">吉林省</option>
                <option value="7">黑龙江省</option>
                <option value="8">上海市</option>
                <option value="9">江苏省</option>
                <option value="10">浙江省</option>
                <option value="11">安徽省</option>
                <option value="12">福建省</option>
                <option value="13">江西省</option>
                <option value="14">山东省</option>
                <option value="15">河南省</option>
                <option value="16">湖北省</option>
                <option value="17">湖南省</option>
                <option value="18">广东省</option>
                <option value="19">广西壮族自治区</option>
                <option value="20">海南省</option>
                <option value="21">重庆市</option>
                <option value="22">四川省</option>
                <option value="23">贵州省</option>
                <option value="24">云南省</option>
                <option value="25">西藏自治区</option>
                <option value="26">陕西省</option>
                <option value="27">甘肃省</option>
                <option value="28">青海省</option>
                <option value="29">宁夏回族自治区</option>
                <option value="30">新疆维吾尔自治区</option>
                <option value="31">新疆生产建设兵团</option>
                <option value="32">香港特别行政区</option>
                <option value="33">澳门特别行政区</option>
                <option value="34">台湾省</option>
              </select>
            </div>
            <div class="item ts-unit-2" :class="formInfo.role == '4' ? 'hide' : ''">
              <span class="label">选择学校:</span>
              <select name="school" id="school2" v-model="formInfo.school">
                <option value="">请选择学校</option>
                <option v-for="item in dictSchool_for_add" :key="item.id" :value="item.id">{{ item.title }}</option>
              </select>
            </div>
            <div class="item" :class="formInfo.role == '3' ? 'hide' : ''">
              <span class="label">行业:</span>
              <select name="industry" id="industry" v-model="formInfo.industry">
                <option value="">选择行业</option>
                <option value="1">农、林、木、渔业</option>
                <option value="2">采矿业</option>
                <option value="3">制造业</option>
                <option value="4">电力、热力、燃气及水生产和供应</option>
                <option value="5">建筑业</option>
                <option value="6">批发和零售业</option>
                <option value="7">交通运输、仓储和邮政业</option>
                <option value="8">住宿和餐饮业</option>
                <option value="9">信息传输、软件和信息技术服务业</option>
                <option value="10">金融业</option>
                <option value="11">房地产业</option>
                <option value="12">租赁和商务服务业</option>
                <option value="13">科学研究和技术服务业</option>
                <option value="14">水利、环境和公共设施管理业</option>
                <option value="15">居民服务、修理和其他服务业</option>
                <option value="16">教育</option>
                <option value="17">卫生和社会工作</option>
                <option value="18">文化、体育和娱乐业</option>
                <option value="19">公共管理、社会保障和社会组织</option>
                <option value="20">国际组织</option>
              </select>
            </div>


          </form>
        </div>
        <div class="form_control">
          <a href="javascript:;" class="button close" id="form_close" @click="hideAddWindow">取消</a>
          <a href="javascript:;" class="button" id="form_submit" @click="saveInfo">提交</a>
        </div>
      </div>
    </div>

    <div class="form_overlay" :style="classShowEditWindow">
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">用户管理</div>
        <div class="form_content">
          <form>
            <div class="item">
              <span class="label"><i class="require">*</i>身份:</span>
              <input type="hidden" v-model="editFormInfo.id">
              <select name="role" v-model="editFormInfo.role">
                <option value="">选择角色</option>
                <option value="1">联盟管理员</option>
                <option value="2">联盟运营管理员</option>
                <option value="3">教师</option>
                <option value="4">社会人士</option>
                <option value="5">指南负责人</option>
                <option value="6">领域负责人</option>
                <option value="7">专业类负责人</option>
                <option value="8">专家</option>
              </select>
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>用户名:</span>
              <input type="text" name="username" v-model="editFormInfo.username">
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>姓名:</span>
              <input type="text" name="name" v-model="editFormInfo.name">
            </div>
            <div class="item " :class="['6', '7'].indexOf(editFormInfo.role) >= 0 ? '' : 'hide'">
              <span class="label"><i class="require">*</i>所属领域:</span>
              <select name="categorie01" v-model="editFormInfo.categorie01" @change="getCategory('edit')">
                <option value="">请选择领域</option>
                <option v-for="item in dictDomain" :key="item.id" :value="item.code">{{ item.title }}</option>
              </select>
            </div>
            <div class="item " :class="['7'].indexOf(editFormInfo.role) >= 0 ? '' : 'hide'">
              <span class="label"><i class="require">*</i>所属专业类:</span>
              <select name="categorie02" v-model="editFormInfo.categorie02">
                <option value="">请选择专业类</option>
                <option v-for="item in dictCategory" :key="item.id" :value="item.code">{{ item.title }}</option>
              </select>
            </div>
            <div class="item">
              <span class="label">性别:</span>
              <select name="gender" v-model="editFormInfo.gender">
                <option value="">请选择</option>
                <option value="M">男</option>
                <option value="F">女</option>
              </select>
            </div>
            <div class="item">
              <span class="label">出生年月:</span>
              <input type="date" name="birth" class="date_box" autocomplete="off" v-model="editFormInfo.birth">
            </div>
            <div class="item">
              <span class="label">手机:</span>
              <input type="text" name="mobile" v-model="editFormInfo.mobile">
            </div>
            <div class="item ts-unit-1">
              <span class="label">省份:</span>
              <select name="province" v-model="editFormInfo.province"  @change="getSchoolForEdit">
                <option value="">选择省份</option>
                <option value="0">北京市</option>
                <option value="1">天津市</option>
                <option value="2">河北省</option>
                <option value="3">山西省</option>
                <option value="4">内蒙古自治区</option>
                <option value="5">辽宁省</option>
                <option value="6">吉林省</option>
                <option value="7">黑龙江省</option>
                <option value="8">上海市</option>
                <option value="9">江苏省</option>
                <option value="10">浙江省</option>
                <option value="11">安徽省</option>
                <option value="12">福建省</option>
                <option value="13">江西省</option>
                <option value="14">山东省</option>
                <option value="15">河南省</option>
                <option value="16">湖北省</option>
                <option value="17">湖南省</option>
                <option value="18">广东省</option>
                <option value="19">广西壮族自治区</option>
                <option value="20">海南省</option>
                <option value="21">重庆市</option>
                <option value="22">四川省</option>
                <option value="23">贵州省</option>
                <option value="24">云南省</option>
                <option value="25">西藏自治区</option>
                <option value="26">陕西省</option>
                <option value="27">甘肃省</option>
                <option value="28">青海省</option>
                <option value="29">宁夏回族自治区</option>
                <option value="30">新疆维吾尔自治区</option>
                <option value="31">新疆生产建设兵团</option>
                <option value="32">香港特别行政区</option>
                <option value="33">澳门特别行政区</option>
                <option value="34">台湾省</option>
              </select>
            </div>
            <div class="item ts-unit-2" :class="editFormInfo.role == '4' ? 'hide' : ''">
              <span class="label">选择学校:</span>
              <select name="school" v-model="editFormInfo.school">
                <option value="">请选择学校</option>
                <option v-for="item in dictSchool_for_edit" :key="item.id" :value="item.id">{{ item.title }}</option>
              </select>
            </div>
            <div class="item" :class="editFormInfo.role == '3' ? 'hide' : ''">
              <span class="label">行业:</span>
              <select name="industry" v-model="editFormInfo.industry">
                <option value="">选择行业</option>
                <option value="1">农、林、木、渔业</option>
                <option value="2">采矿业</option>
                <option value="3">制造业</option>
                <option value="4">电力、热力、燃气及水生产和供应</option>
                <option value="5">建筑业</option>
                <option value="6">批发和零售业</option>
                <option value="7">交通运输、仓储和邮政业</option>
                <option value="8">住宿和餐饮业</option>
                <option value="9">信息传输、软件和信息技术服务业</option>
                <option value="10">金融业</option>
                <option value="11">房地产业</option>
                <option value="12">租赁和商务服务业</option>
                <option value="13">科学研究和技术服务业</option>
                <option value="14">水利、环境和公共设施管理业</option>
                <option value="15">居民服务、修理和其他服务业</option>
                <option value="16">教育</option>
                <option value="17">卫生和社会工作</option>
                <option value="18">文化、体育和娱乐业</option>
                <option value="19">公共管理、社会保障和社会组织</option>
                <option value="20">国际组织</option>
              </select>
            </div>


          </form>
        </div>
        <div class="form_control">
          <a href="javascript:;" class="button close" @click="hideEditWindow">取消</a>
          <a href="javascript:;" class="button" @click="saveEditInfo">提交</a>
        </div>
      </div>
    </div>

    <div class="form_overlay" :style="classBindPhoneWindow">
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">设置是否绑定手机</div>
        <div class="form_content">
          <form id="bindPhoneForm">
            <div class="item">
              <span class="label"><i class="require"></i>是否绑定手机</span>
              <select name="isFirstLog" id="isFirstLog" v-model="phoneBindForm.isFirstLog">
                <!--                <option value="">请选择</option>-->
                <option value="1">是</option>
                <option value="2">否</option>
              </select>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a href="javascript:;" class="button close" id="bindPhone_close" @click="classBindPhoneWindow = {}">取消</a>
          <a href="javascript:;" class="button" id="bindPhone_submit" @click="changeBindPhone">提交</a>
        </div>
      </div>
    </div>

    <div class="form_overlay" :style="classShowUploadWindow">
      <div class="form_overlay_bg"></div>
      <div class="form_panel" >
        <div class="form_title">批量上传</div>
        <div class="form_content">
          <form id="uploadForm">
            <div class="item">
              <span class="label" style="width: auto"><i class="require"></i>模版创建规则:</span>
              <div class="item-box divLabel gray">
                <div class="normal-width">
                  <p>1. 文件格式为“逗号分隔”的CSV文件；</p>
                  <p>2. 文件编码必须为ANSI编码；</p>
                  <p>3. 上传文档转换完毕后，请使用记事本打开，查看是否含有空数据，如果含有空数据，则需删除；</p>
                  <p>4. 上传文档中内容不能含有西文逗号；</p>
                  <p>5. CSV文件生成，可使用excel另存为“逗号分隔的CSV文件”；</p>
                  <p>6. 文件编码，可使用记事本打开CSV，另存为ANSI编码。</p>
                </div>
              </div>
              <div class="clear"></div>
              <div class="attachment">
                <a href="/static/upload_template/batch_upload_user_template.csv" download="batch_upload_user_template.csv" target="_blank">模版下载</a>
              </div>
            </div>
            <div class="item">
              <span class="label" style="width: auto"><i class="require">*</i>CSV文件:</span>
              <div class="item-box" style="margin-left: 130px;">
                <input name="file" v-model="uploadedCsv" disabled type="text" id="fileUrl" style="width: 225px;">
                <span class="button" style="position: relative;top: 0px; left: 0px;">
                  选择文件
                  <input @change="xlsChange" type="file" ref="xlsFile" id="fileUpload" class="upload-derict">
                </span>
              </div>
              <div class="clear"></div>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a href="javascript:;" style="width: 30px" class="button close" id="form_upload_close"
             @click="hideUploadWindow">取消</a>
          <a href="javascript:;" style="width: 30px" class="button" id="form_upload_submit" @click="submitCsv">提交</a>
        </div>
      </div>
    </div>

    <div id="dialog_overlay" :style="classShowProcessWindow">
      <div id="dialog_bg"></div>
      <div id="dialog_panel" ref="refErrorList" style="margin-left: -136px; margin-top: -54px;max-width: 300px;">
        <div id="dialog_panel_bg"></div>
        <div id="dialog_title">提示</div>
        <div id="dialog_content">
          ({{ curCsvIndex }} / {{ uploadedCscData.length }})
          <span style="color: #219d2b">
            {{ ' ' + csvFinishText }}
          </span>
        </div>
        <div id="dialog_content2">
          <ul>
            <li v-for="(item, index) in errorList" v-bind:key="index">
              <span :class="item.code == 1 ? 'success' : 'fail'">{{ item.text }}</span>
            </li>
          </ul>

        </div>
        <div id="dialog_control">
          <a href="javascript:void(0)" id="dialog_ok" class="button col2" @click="classShowProcessWindow={}"
             :style="isFinishUpsert ? 'display: inline-block' : 'display:none'">确认</a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Pagination from "../../components/Pagination"

export default {
  name: "AdminUser",
  components: {
    Pagination
  },
  data() {
    return {
      dictDomain: [],
      dictCategory: [],
      dictSchool_for_search: [],
      dictSchool_for_add: [],
      dictSchool_for_edit: [],
      dictAllSchool: [],
      queryInfo: {
        page: 1,
        size: 10,
        role: '',
        province: '',
        school: '',
        gender: '',
        disable: '',
        mobile: '',
        username: '',
        name: '',
      },
      formInfo: {
        id: 0,
        user_id: 0,
        role: '',
        username: '',
        name: '',
        password1: '',
        password2: '',
        categorie01: '',
        categorie02: '',
        gender: '',
        birth: '',
        email: '',
        mobile: '',
        province: '',
        school: '',
        industry: '',
      },
      editFormInfo: {
        id: 0,
        user_id: 0,
        role: '',
        username: '',
        name: '',
        categorie01: '',
        categorie02: '',
        gender: '',
        birth: '',
        mobile: '',
        province: '',
        school: '',
        industry: '',
      },
      classShowAddWindow: {},
      classShowEditWindow: {},
      classBindPhoneWindow: {},
      tableData: [],
      tableDataCount: 0,
      phoneBindForm: {
        id: 0,
        isFirstLog: false
      },
      ckUser: [],
      classShowUploadWindow: {},
      uploadedCsv: '',
      uploadedCscData: [],
      classShowProcessWindow: {},
      errorList: [],
      isFinishUpsert: false,
      curCsvUserId: 0,
      curCsvIndex: 0,
      csvFinishText: ''
    }
  },
  computed: {
    authUserForm() {
      return {
        username: this.formInfo.username,
        password1: this.formInfo.password1,
        password2: this.formInfo.password2
      }
    },
    authEditUserForm() {
      return {
        username: this.editFormInfo.username
      }
    },
    pageInfo() {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    },
    addUserRole() {
      if(this.$store.state.userInfo.role == 1 || this.$store.state.userInfo.role == 0){
        return [
            {"name": "联盟管理员", "value": "1"},
            {"name": "联盟运营管理员", "value": "2"},
            {"name": "教师", "value": "3"},
            {"name": "社会人士", "value": "4"},
            {"name": "指南负责人", "value": "5"},
            {"name": "领域负责人", "value": "6"},
            {"name": "专业类负责人", "value": "7"},
            {"name": "专家", "value": "8"}
        ]
      }else if(this.$store.state.userInfo.role == 6){
        return [
          {"name": "教师", "value": "3"},
          {"name": "专业类负责人", "value": "7"}
        ]
      }else if(this.$store.state.userInfo.role == 7){
        return [
          {"name": "教师", "value": "3"}
        ]
      }
      return []
    }
  },
  methods: {
    ckChange() {
      console.log(this.ckUser)
    },
    ckAll() {
      if (this.ckUser.length == this.tableData.length) {
        this.ckUser = []
      } else {
        for (let i = 0; i < this.tableData.length; i++) {
          this.ckUser.push(this.tableData[i].id)
        }
      }
    },
    async getDomain() {
      await this.$http.get('/categories/', {
        params: {
          page: 1,
          size: 1000000,
          grade: '1',
          status: 1,
          isdel: 0
        }
      }).then(async (data) => {
        console.log(data)
        this.dictDomain = data.results
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg('获取领域信息失败！')
      })
    },
    async getCategory(type) {
      await this.$http.get('/categories/', {
        params: {
          page: 1,
          size: 1000000,
          parent: type == 'add' ? this.formInfo.categorie01 : this.editFormInfo.categorie01,
          grade: '2',
          status: 1,
          isdel: 0
        }
      }).then(async (data) => {
        console.log(data)
        this.dictCategory = data.results
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg('获取专业类信息失败！')
      })
    },
    async getSchoolForSearch() {
      await this.$http.get('/school/', {
        params: {
          page: 1,
          size: 1000000,
          status: 1,
          isdel: 0,
          province: this.queryInfo.province
        }
      }).then(async (data) => {
        console.log(data)
        this.queryInfo.school = ""
        this.dictSchool_for_search = data.results
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg('获取专业类信息失败！')
      })
    },
    async getSchoolForAdd() {
      await this.$http.get('/school/', {
        params: {
          page: 1,
          size: 1000000,
          status: 1,
          isdel: 0,
          province: this.formInfo.province
        }
      }).then(async (data) => {
        console.log(data)
        this.formInfo.school = ""
        this.dictSchool_for_add = data.results
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg('获取专业类信息失败！')
      })
    },
    async getSchoolForEdit() {
      await this.$http.get('/school/', {
        params: {
          page: 1,
          size: 1000000,
          status: 1,
          isdel: 0,
          province: this.editFormInfo.province
        }
      }).then(async (data) => {
        console.log(data)
        this.editFormInfo.school = ""
        this.dictSchool_for_edit = data.results
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg('获取专业类信息失败！')
      })
    },
    async getAllSchool() {
      await this.$http.get('/school/', {
        params: {
          page: 1,
          size: 1000000
        }
      }).then(async (data) => {
        console.log(data)
        let dict_result = {}
        for (let i = 0; i < data.results.length; i++) {
          dict_result[data.results[i].id.toString()] = data.results[i].title
        }
        this.dictAllSchool = dict_result
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg('获取专业类信息失败！')
      })
    },
    showAddWindow() {
      this.classShowAddWindow = {
        visibility: 'visible'
      }
    },
    hideAddWindow() {
      this.classShowAddWindow = {}
      this.formInfo = {
        id: 0,
        user_id: 0,
        role: '',
        username: '',
        name: '',
        password1: '',
        password2: '',
        categorie01: '',
        categorie02: '',
        gender: '',
        birth: '',
        email: '',
        mobile: '',
        province: '',
        school: '',
        industry: '',
      }
    },
    showEditWindow() {
      this.classShowEditWindow = {
        visibility: 'visible'
      }
    },
    hideEditWindow() {
      this.classShowEditWindow = {}
      this.editFormInfo = {
        id: 0,
        user_id: 0,
        role: '',
        username: '',
        name: '',
        categorie01: '',
        categorie02: '',
        gender: '',
        birth: '',
        email: '',
        mobile: '',
        province: '',
        school: '',
        industry: '',
      }
    },
    async saveInfo() {
      // 保存前 数据检查
      if (!this.formInfo.role) {
        this.$parent.$parent.showMsg("请选择身份!")
        return false
      }
      if (!this.formInfo.username) {
        this.$parent.$parent.showMsg("请输入用户名!")
        return false
      }
      if (this.formInfo.username.length <= 3) {
        this.$parent.$parent.showMsg("用户名长度必须大于4位!")
        return false
      }
      if (this.formInfo.username.length >= 50) {
        this.$parent.$parent.showMsg("用户名长度必须小于50位!")
        return false
      }
      if (!this.formInfo.name) {
        this.$parent.$parent.showMsg("请输入姓名!")
        return false
      }
      if (this.formInfo.name.trim().length < 2) {
        this.$parent.$parent.showMsg("姓名必须大于2位!")
        return false
      }
      if (this.formInfo.name.trim().length > 20) {
        this.$parent.$parent.showMsg("姓名过长!")
        return false
      }
      if (!this.formInfo.password1) {
        this.$parent.$parent.showMsg("请输入密码!")
        return false
      }
      if (!this.formInfo.password2) {
        this.$parent.$parent.showMsg("请输入确认密码!")
        return false
      }

      let mobileReg = /^1[0-9]{10}$/;
      if (this.formInfo.mobile.length > 0 && !mobileReg.test(this.formInfo.mobile)) {
        this.$parent.$parent.showMsg('手机号码输入错误！')
        return false;
      }

      let emailReg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      if (this.formInfo.email.length > 0 && !emailReg.test(this.formInfo.email)) {
        this.$parent.$parent.showMsg('邮箱输入错误！')
        return false;
      }

      if (['6', '7'].indexOf(this.formInfo.role) >= 0 && !this.formInfo.categorie01) {
        this.$parent.$parent.showMsg("请选择领域!")
        return false
      }
      if (['7'].indexOf(this.formInfo.role) >= 0 && !this.formInfo.categorie02) {
        this.$parent.$parent.showMsg("请选择专业!")
        return false
      }

      // 教师
      if (this.formInfo.role == '3') {
        this.formInfo.industry = ''
      }
      // 社会人士
      if (this.formInfo.role == '4') {
        this.formInfo.school = ''
      }
      // 非领域负责人 专业负责人
      if (['6', '7'].indexOf(this.formInfo.role) < 0) {
        this.formInfo.categorie01 = ''
      }
      // 非专业负责人
      if (['7'].indexOf(this.formInfo.role) < 0) {
        this.formInfo.categorie02 = ''
      }

      await this.$http.post('/user/', this.authUserForm).then((data) => {
        if (data.id) {
          this.formInfo.user_id = data.id
          this.saveProfile()
        } else {
          this.$parent.$parent.showMsg("创建用户失败")
        }
      }).catch((error) => {
        console.dir(error)
        this.$parent.$parent.showMsg(error.response.data)
      })
    },
    async saveEditInfo() {
      // 保存前 数据检查
      if (!this.editFormInfo.role) {
        this.$parent.$parent.showMsg("请选择身份!")
        return false
      }
      if (!this.editFormInfo.username) {
        this.$parent.$parent.showMsg("请输入用户名!")
        return false
      }
      if (this.editFormInfo.username.length <= 3) {
        this.$parent.$parent.showMsg("用户名长度必须大于4位!")
        return false
      }
      if (this.editFormInfo.username.length >= 50) {
        this.$parent.$parent.showMsg("用户名长度必须小于50位!")
        return false
      }
      if (!this.editFormInfo.name) {
        this.$parent.$parent.showMsg("请输入姓名!")
        return false
      }
      if (this.editFormInfo.name.trim().length < 2) {
        this.$parent.$parent.showMsg("姓名必须大于2位!")
        return false
      }
      if (this.editFormInfo.name.trim().length > 20) {
        this.$parent.$parent.showMsg("姓名过长!")
        return false
      }

      let mobileReg = /^1[0-9]{10}$/;
      if (this.editFormInfo.mobile.length > 0 && !mobileReg.test(this.editFormInfo.mobile)) {
        this.$parent.$parent.showMsg('手机号码输入错误！')
        return false;
      }
      let emailReg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      if (this.editFormInfo.email.length > 0 && !emailReg.test(this.editFormInfo.email)) {
        this.$parent.$parent.showMsg('邮箱输入错误！')
        return false;
      }

      if (['6', '7'].indexOf(this.editFormInfo.role) >= 0 && !this.editFormInfo.categorie01) {
        this.$parent.$parent.showMsg("请选择领域!")
        return false
      }
      if (['7'].indexOf(this.editFormInfo.role) >= 0 && !this.editFormInfo.categorie02) {
        this.$parent.$parent.showMsg("请选择专业!")
        return false
      }

      // 教师
      if (this.editFormInfo.role == '3') {
        this.editFormInfo.industry = ''
      }
      // 社会人士
      if (this.editFormInfo.role == '4') {
        this.editFormInfo.school = ''
      }
      // 非领域负责人 专业负责人
      if (['6', '7'].indexOf(this.editFormInfo.role) < 0) {
        this.editFormInfo.categorie01 = ''
      }
      // 非专业负责人
      if (['7'].indexOf(this.editFormInfo.role) < 0) {
        this.editFormInfo.categorie02 = ''
      }

      let url = '/user/' + this.editFormInfo.user_id + '/'
      await this.$http.put(url, this.authEditUserForm).then((data) => {
        if (data.id) {
          this.saveEditProfile()
        } else {
          this.$parent.$parent.showMsg("修改用户失败")
        }
      }).catch((error) => {
        console.dir(error)
        this.$parent.$parent.showMsg(error.response.data)
      })
    },
    async delInfo(id) {
      await this.$http.delete('/user/' + id + '/').then((data) => {
        console.log(data)
        console.log('已删除')
      }).catch((error) => {
        console.log(error)
      })
    },
    async saveProfile() {
      if (!this.formInfo.birth) {
        this.formInfo.birth = null
      }
      await this.$http.post('/userprofile/', this.formInfo).then((data) => {
        if (data.id) {
          this.$parent.$parent.showMsg('添加成功！')
          this.hideAddWindow()
          this.search()
        } else {
          this.$parent.$parent.showMsg('添加失败！')
          this.delInfo(this.formInfo.user_id)
        }
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg(error)
        this.delInfo(this.formInfo.user_id)
      })
    },
    async saveEditProfile() {
      if (!this.editFormInfo.birth) {
        this.editFormInfo.birth = null
      }
      let url = '/userprofile/' + this.editFormInfo.id + '/'
      await this.$http.put(url, this.editFormInfo).then((data) => {
        if (data.id) {
          this.$parent.$parent.showMsg('修改成功！')
          this.hideEditWindow()
          this.search()
        } else {
          this.$parent.$parent.showMsg('修改失败！')
        }
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg(error)
      })
    },
    async search() {
      await this.$http.get('/userprofile/', {
        params: this.queryInfo
      }).then((data) => {
        console.log(data)
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$parent.$parent.showMsg('获取用户信息失败！')
      })
    },
    provinceFormater(province) {
      return this.$store.state.dict_province[province]
    },
    genderFormater(gender) {
      return this.$store.state.dict_gender[gender]
    },
    schoolFormater(school) {
      return this.dictAllSchool[school]
    },
    industryFormater(industry) {
      return this.$store.state.dict_industry[industry]
    },
    roleFormater(role) {
      if (role == '0') {
        return '系统管理员'
      }
      return this.$store.state.dict_role[role]
    },
    async pageCallback(data) {
      this.queryInfo.page = data
      await this.search()
    },
    async querySingle(id) {
      const url = '/userprofile/' + id + '/'
      await this.$http.get(url).then(async (data) => {
        console.log(data)
        this.editFormInfo = {
          id: id,
          user_id: data.user_id,
          role: data.role,
          username: data.username,
          name: data.name,
          // categorie01: data.categorie01,
          // categorie02: data.categorie02,
          gender: data.gender,
          birth: data.birth,
          email: data.email,
          mobile: data.mobile,
          province: data.province,
          school: data.school,
          industry: data.industry
        }

        await this.$http.get('/school/', {
          params: {
            page: 1,
            size: 1000000,
            status: 1,
            isdel: 0,
            province: this.editFormInfo.province
          }
        }).then(async (data) => {
          console.log(data)
          this.dictSchool_for_edit = data.results
        }).catch((error) => {
          console.log(error)
          this.$parent.$parent.showMsg('获取专业类信息失败！')
        })

        if (data.categorie01) {
          await this.$http.get('/categories/', {
            params: {
              page: 1,
              size: 1000000,
              grade: '1',
              status: 1,
              isdel: 0
            }
          }).then(async (domain_data) => {
            this.dictDomain = domain_data.results
            this.editFormInfo.parent = domain_data.parent
            this.editFormInfo.parentTitle = domain_data.parentTitle

            this.editFormInfo.categorie01 = data.categorie01;
            if (data.categorie02) {
              await this.$http.get('/categories/', {
                params: {
                  page: 1,
                  size: 1000000,
                  parent: this.editFormInfo.categorie01,
                  grade: '2',
                  status: 1,
                  isdel: 0
                }
              }).then(async (category_data) => {
                console.log(category_data)
                this.dictCategory = category_data.results

                this.editFormInfo.categorie02 = data.categorie02;
              }).catch((error) => {
                console.log(error)
                this.$parent.$parent.showMsg('获取专业类信息失败！')
              })
            }

          }).catch((error) => {
            console.log(error)
            this.$parent.$parent.showMsg('获取领域信息失败！')
          })
        }

        this.showEditWindow()
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg('数据获取失败！');
      })
    },
    async doDisable(user_id, profile_id, user_value, profile_value, show_msg) {
      let url = '/user/' + user_id + '/'
      await this.$http.put(url, {
        is_active: user_value
      }).then((data) => {
        if (data.id) {
          this.doDisableProfile(user_id, profile_id, user_value, profile_value, show_msg)
        } else {
          let msg = profile_value == 1 ? '禁用用户失败' : '启用户失败'
          this.$layer.msg(msg)
        }
      }).catch((error) => {
        console.log(123)
        console.dir(error)
        console.log(456)
        this.$parent.$parent.showMsg(error.response.data)
      })
    },
    async doDisableProfile(user_id, profile_id, user_value, profile_value, show_msg) {
      let url = '/userprofile/' + profile_id + '/'
      await this.$http.put(url, {
        disable: profile_value
      }).then((data) => {
        let msg = profile_value == 1 ? '禁用' : '启用'
        if (data.id) {
          if (show_msg) {
            this.$layer.msg(msg + "用户成功")
          }
          this.search()
        } else {
          if (show_msg) {
            this.$layer.msg(msg + "用户失败")
          }
        }
      }).catch(async (error) => {
        console.log(error)
        let url = '/user/' + user_id + '/'
        await this.$http.put(url, {
          is_active: user_value
        }).then((data) => {
          console.log(data)
        }).catch((error) => {
          console.dir(error)
        })
        let msg = profile_value == 1 ? '禁用' : '启用'
        if (show_msg) {
          this.$layer.msg(msg + "用户失败")
        }
      })
    },
    askDisable(user_id, profile_id, user_value, profile_value) {
      let msg = profile_value == 1 ? '确定要禁用吗？' : '确定要启用吗？'
      this.$parent.$parent.showConfirm(msg, () => {
        this.doDisable(user_id, profile_id, user_value, profile_value, true)
      });
    },
    async resetPwd(user_id, profile_id) {
      let url = '/user/' + user_id + '/'
      await this.$http.put(url, {
        password1: '123456',
        password2: '123456'
      }).then(async (data) => {
        if (data.id) {

          // 修改旧系统 密码验证状态
          let url = '/userprofile/' + profile_id + '/'
          await this.$http.put(url, {
            pwdChecked: 1
          }).then((data) => {
            console.log(data)
          })

          this.$layer.msg('重置密码成功！')
        } else {
          this.$layer.msg('重置密码失败！')
        }
      }).catch((error) => {
        console.dir(error)
        this.$layer.msg('重置密码失败！')
      })
    },
    askResetPwd(user_id, profile_id) {
      this.$parent.$parent.showConfirm('密码将重置为123456，确定要重置密码？', () => {
        this.resetPwd(user_id, profile_id)
      });
    },
    async getPhoneBind(profile_id) {
      console.log(profile_id)
      const url = '/userprofile/' + profile_id + '/'
      await this.$http.get(url).then((data) => {
        console.log(data)
        this.phoneBindForm = {
          id: data.id,
          isFirstLog: data.isFirstLog
        }
        console.log(this.phoneBindForm)
        this.classBindPhoneWindow = {
          visibility: 'visible'
        }
        console.log(this.classBindPhoneWindow)
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg('数据获取失败！');
      })
    },
    async changeBindPhone() {
      let url = '/userprofile/' + this.phoneBindForm.id + '/'
      await this.$http.put(url, this.phoneBindForm).then((data) => {
        if (data.id) {
          this.$layer.msg('设置成功！')
          this.classBindPhoneWindow = {}
          this.search()
        } else {
          this.$layer.msg('设置失败！')
        }
      }).catch((error) => {
        console.log(error)
        this.$layer.msg(error)
      })
    },
    batchDisable() {
      this.$parent.$parent.showConfirm("确认将所选用户批量禁用？", () => {
        for (let i = 0; i < this.tableData.length; i++) {
          console.log(this.tableData[i])
          if (this.ckUser.indexOf(this.tableData[i].id) >= 0) {
            console.log('do == ' + this.tableData[i].id)
            this.doDisable(this.tableData[i].user_id, this.tableData[i].id, 0, 1, false)
          }
        }
        this.ckUser = []
        this.$layer.msg("操作成功！")
      });
    },
    batchUnDisable() {
      this.$parent.$parent.showConfirm("确认将所选用户批量启用？", () => {
        for (let i = 0; i < this.tableData.length; i++) {
          console.log(this.tableData[i])
          if (this.ckUser.indexOf(this.tableData[i].id) >= 0) {
            console.log('do == ' + this.tableData[i].id)
            this.doDisable(this.tableData[i].user_id, this.tableData[i].id, 1, 0, false)
          }
        }
        this.ckUser = []
        this.$layer.msg("操作成功！")
      });
    },
    showUploadWindow() {
      this.classShowUploadWindow = {
        visibility: 'visible'
      }
    },
    hideUploadWindow() {
      this.classShowUploadWindow = {}
    },
    async xlsChange() {
      const uploadUrl = '/files/' + this.$refs.xlsFile.files[0].name
      await this.$http.put(uploadUrl, this.$refs.xlsFile.files[0]).then((data) => {
        console.log(data)
        this.uploadedCsv = data
      }).catch(() => {
        this.$parent.$parent.showMsg('上传失败！')
      })
    },
    async submitCsv() {
      const uploadUrl = '/get_csv/'
      await this.$http.get(uploadUrl, {
        params: {
          'file_name': this.uploadedCsv
        }
      }).then((data) => {
        console.log(data)

        if (data.length <= 1) {
          this.$parent.$parent.showMsg('表格中无数据！')
          return;
        }

        data.splice(0, 1)

        this.uploadedCscData = data

        this.hideUploadWindow()

        this.classShowProcessWindow = {
          visibility: 'visible'
        }
        this.isFinishUpsert = false
        this.curCsvIndex = 0
        this.csvFinishText = ''
        this.errorList = []
        this.insertOne()

      }).catch(() => {
        this.$parent.$parent.showMsg('读取失败！')
      })
    },
    async insertOne() {

      console.log(this.uploadedCscData)

      if (this.curCsvIndex >= this.uploadedCscData.length) {
        this.csvFinishText = '上传完成'
        this.isFinishUpsert = true
        this.search()
        return;
      }

      let row = this.uploadedCscData[this.curCsvIndex]
      let role = ''
      let province = ''

      // region 数据检查开始

      if (row[0].trim().length == 0) {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '请输入用户名！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      if (row[1].trim().length == 0) {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '请输入姓名！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      if (row[2].trim().length == 0) {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '请输入角色！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      if (row[3].trim().length == 0) {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '请输入省份！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      const url = '/user/'
      let usernameValid = false
      await this.$http.get(url, {
        params: {
          username: row[0]
        }
      }).then(async (data) => {
        console.log(data)
        if (data.count == 0) {
          usernameValid = true
        }
      })

      if (!usernameValid) {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '用户名已存在！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      if (row[2] != '教师' && row[2] != '社会人士') {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '角色输入错误！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      if (row[2] == '教师') {
        role = '3'
      } else if (row[2] == '社会人士') {
        role = '4'
      }

      let dict_province = this.$store.state.dict_province

      let provinceValid = false
      for (let key in dict_province) {
        if (dict_province[key] == row[3].trim()) {
          provinceValid = true
          province = key
          break
        }
      }
      if (!provinceValid) {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '省份输入错误！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      // endregion 数据检查结束

      let authForm = {
        username: row[0],
        password1: '123456',
        password2: '123456'
      }

      console.log('=======正在插入auth user========')
      console.log(authForm)

      await this.$http.post('/user/', authForm).then(async (data) => {

        this.curCsvUserId = data.id
        let profileForm = {
          user_id: data.id,
          username: row[0],
          name: row[1],
          role: role,
          province: province,
        }
        console.log(profileForm)
        await this.$http.post('/userprofile/', profileForm).then((profileData) => {
          console.log(profileData)

          this.errorList.push({code: 1, text: '[' + authForm.username + ']' + "导入成功！"})
          this.curCsvIndex++
          this.insertOne()

        }).catch((error) => {
          console.log(error)
          this.delInfo(this.curCsvUserId)
          this.errorList.push({code: 0, text: '[' + authForm.username + ']' + error.response.data[0]})
          this.curCsvIndex++
          this.insertOne()
        })

      }).catch((error) => {
        console.dir(error)
        this.errorList.push({code: 0, text: '[' + authForm.username + ']' + error.response.data[0]})
        this.curCsvIndex++
        this.insertOne()
      })
    }
  },
  mounted() {
    this.getDomain()
    // this.getSchool()
    this.getAllSchool()
    this.search()
  }
}
</script>

<style scoped>
@import "../../assets/css/user.css";

.date_box {
  border: 1px solid #dddddd;
  /*background-color: #f5f7fc;*/
  width: 292px;
  height: 32px;
  line-height: 32px;
  margin-right: 8px;
  padding-left: 8px;
}

#dialog_content2 {
  max-height: 100px;
  overflow-y: auto;
  border-top: 1px solid #ddd;
}

#dialog_content2 .success {
  color: #aaa;
}

#dialog_content2 .fail {
  color: #e33;
}

</style>
