<template>
  <div id="panel" class="panel" style="min-height: 696px;">
    <form id="searchSection" onsubmit="return false;">
      <div class="search-line clearfix">
        <select v-model="queryInfo.show_status"  name="show_status" class="s-select separate">
          <option value="">选择状态</option>
          <option value="1">待审核</option>
          <option value="3">待发布</option>
          <option value="4">已发布</option>
          <option value="2">驳回</option>
        </select>
        <input v-model="queryInfo.show_title" type="text" name="title" class="s-input separate" placeholder="企业名称">
        <input v-model="queryInfo.show_mainPrincipal" type="text" name="title" class="s-input separate" placeholder="姓名">
        <input v-model="queryInfo.show_mobile1" type="text" name="title" class="s-input separate" placeholder="手机号">

        <input type="submit" class="s-btn" value="搜索" @click="search">
      </div>

      <div class="search-line clearfix">

      </div>
    </form>
    <div id="list">
      <table v-if="this.tableData.length > 0 ">
        <tbody>
        <tr class="l_header">
          <th class="itemTitle">企业名称</th>
          <th class="itemTitle">联系人姓名</th>
          <th class="itemTitle">手机号</th>
          <th class="itemTitle">邮箱</th>
          <th class="itemCategory">提交时间</th>
          <th class="itemCategory">状态</th>
          <th class="action" width="155">操作</th>
        </tr>
        <tr class="item" v-for="item in tableData" :key="item.id">
          <td class="itemTitle">{{ item.show_title }}</td>
          <td class="itemTitle">{{ item.show_mainPrincipal }}</td>
          <td class="itemTitle">{{ item.show_mobile1 }}</td>
          <td class="itemTitle">{{ item.show_email1 }}</td>
          <td class="itemTitle">{{ item.created.substr(0, 10) }}</td>

          <td v-if="item.show_status == 1" class="itemTitle itemStatus3 color1">待审核</td>
          <td v-else-if="item.show_status == 3" class="itemTitle itemStatus3 color4">待发布</td>
          <td v-else-if="item.show_status == 4" class="itemTitle itemStatus1 color2">已发布</td>
          <td v-else-if="item.show_status == 2" class="itemTitle itemStatus0 color3">驳回</td>
          <td v-else class="itemTitle "></td>

          <td class="action" style="font-size:12px">
            <a v-if="item.show_status == 1 || item.show_status == 2 || item.show_status == 3 || item.show_status == 4" class="color1" href="javascript:;" @click="viewBrief(item)">查看</a>
            <a v-if="item.show_status == 1" class="color2" href="javascript:;" @click="updateStatus(item.id, 3)">通过</a>
            <a v-if="item.show_status == 1" class="color3" href="javascript:;" @click="showReject(item.id)">驳回</a>
            <a v-if="item.show_status == 2" class="color3" href="javascript:;" @click="del(item.id)">删除</a>
            <a v-if="item.show_status == 3" class="color2" href="javascript:;" @click="updateStatus(item.id, 4)">发布</a>
            <a v-if="item.show_status == 4" class="color3" href="javascript:;" @click="updateStatus(item.id, 3)">取消发布</a>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="noitems" v-if="tableData.length == 0">没有数据</div>
    </div>

    <pagination :cur="pageInfo.page" :all="pageInfo.all" :totalData="pageInfo.totalData"
                :callback="pageCallback"></pagination>

    <div class="form_overlay" id="applyReject_overlay" :style="classApplyRejectShow">
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">申请驳回</div>
        <div class="form_content">
          <form id="applyRejectForm">
            <div class="item">
              <span class="label"><i class="require"></i>驳回理由</span>
              <textarea class="" type="text" name="show_rejectReason" v-model="rejectForm.show_rejectReason"
                        style="width: 350px;height: 144px;resize: none;"></textarea>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a href="javascript:;" class="button close" id="applyReject_close"
             @click="classApplyRejectShow = { visibility: 'hidden' }">取消</a>
          <a href="javascript:;" class="button" id="applyReject_submit" @click="doReject">提交</a>
        </div>
      </div>
    </div>

    <div class="form_overlay" id="form_overlay" :style="companyInfoWindow" >
      <div class="form_overlay_bg"></div>
      <div class="form_panel" style="min-height: 444px;padding: 20px;min-width: 700px;">
        <div class="form_title">企业介绍<a href="javascript:;" class="close" @click="companyInfoWindow={}">×</a>
        </div>
        <div class="form_content clearfix" id="vipInfo">
          <div class="leftInfo" style="margin-left: 20px;">
            <img :src="formatImage(brief_Info.logo)"
                style="margin-top: 40px;height: 150px;width: 150px;">
            <p class="vipTitle">{{ brief_Info.title }}</p>
            <p class="vipInfo"><span class="iconfont"></span>{{brief_Info.show_address}}</p>
            <p class="vipInfo"><span class="iconfont"></span>{{brief_Info.show_mainPrincipal}}</p>
            <p class="vipInfo"><span class="iconfont"></span>{{brief_Info.show_mobile1}}</p>
            <p class="vipInfo"><span class="iconfont"></span>{{brief_Info.show_email1}}</p></div>
          <div class="rightInfo" style="top: auto;left: 370px;width: 370px;min-width: 370px;">
            <p class="infoTitle">企业简介</p>
            <p class="brief" style="width: 370px;" v-html="brief_Info.show_brief">
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../../components/Pagination"

export default {
  name: 'AdminApply',
  components: {
    Pagination
  },
  data() {
    return {
      classApplyRejectShow: { visibility: 'hidden' },
      companyInfoWindow: { visibility: 'hidden' },
      rejectForm: {
        id: 0,
        show_rejectReason: '',
      },
      queryInfo: {
        page: 1,
        size: 10,
        show_status: '',
        show_title:'',
        show_mainPrincipal:'',
        show_mobile1:''
      },
      brief_Info:{
        logo:'',
        title:'',
        show_title:'',
        show_address:'',
        show_mainPrincipal:'',
        show_email1:'',
        show_mobile1:'',
        show_brief:'',
        show_rejectReason:'',
        show_status:''
      },
      tableData: [],
      tableDataCount: 0,
    }
  },
  computed: {
    pageInfo() {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    }
  },
  methods: {
    formatImage(fileName) {
      console.log(fileName)
      return this.$store.state.uploadFileRootUrl + fileName
    },
    viewBrief(item){
      this.companyInfoWindow={ visibility: 'visible' }
      console.log(item)
      this.brief_Info={
        logo:item.logo,
        title:item.title,
        show_title:item.show_title,
        show_address:item.show_address,
        show_mainPrincipal:item.show_mainPrincipal,
        show_email1:item.show_email1,
        show_mobile1:item.show_mobile1,
        show_brief:item.show_brief,
        show_rejectReason:item.show_rejectReason,
        show_status:item.show_status
      }
    },
    async del(id){
      console.log(id)
      console.log("111")
      let briefInfo={
        show_title:'',
        show_address:'',
        show_mainPrincipal:'',
        show_email1:'',
        show_mobile1:'',
        show_brief:'',
        show_rejectReason:'',
        show_status:0
      }
      const url = '/application/' + id + '/'
      await this.$http.put(url,briefInfo).then(() => {
        this.search()
      }).catch((error) => {
        console.log(error)
        this.$layer.msg('操作失败！');
      })
    },
    async search() {
      let listArray=[]
      await this.$http.get('/application/', {
        params: this.queryInfo
      }).then((data) => {
        data.results.forEach(element => {
          if(element.show_status>0 && element.type==2){
            listArray.push(element)
          }
        });
        this.tableData=listArray
        this.tableDataCount = this.tableData.length
      }).catch(() => {
        this.$parent.$parent.showMsg('获取通知信息失败！')
      })
    },
    async updateStatus(id, statusValue) {
      const url = '/application/' + id + '/'
      await this.$http.put(url, {
        show_status: statusValue
      }).then(() => {
        this.search()
      }).catch((error) => {
        console.log(error)
        this.$layer.msg('操作失败！');
      })
    },
    async pageCallback(data) {
      this.queryInfo.page = data
      await this.search()
    },
    async showReject(id){
      this.rejectForm = {
        id: id,
        show_rejectReason: '',
      }
      this.classApplyRejectShow = {
        visibility: 'visible'
      }
    },
    async doReject(){
      const url = '/application/' + this.rejectForm.id + '/'
      await this.$http.put(url, {
        show_status: 2,
        show_rejectReason: this.rejectForm.show_rejectReason
      }).then(async () => {
        this.classApplyRejectShow = {
          visibility: 'hidden'
        }
        await this.search()
      }).catch((error) => {
        console.log(error)
        this.$layer.msg('操作失败！');
      })
    },
    
  },
  mounted() {
    this.search()
  },
}
</script>

<style scoped>

.action a{
  display: inline-block;
  padding:0 !important;
  padding-right:17px !important;
  word-wrap:break-word;
  text-align: left;
}
.color1{
  color:#02A7F0
}
.color2{
  color:#70B603
}
.color3{
  color:#D9001B
}
.color4{
  color:#F59A23
}
#pagination{
  margin-top:20px;
}
#list{
  min-height: 140px;
}
.form_content p.brief{
  font-size:15px;
  line-height:2.2;
  text-align: justify;
}
</style>
