<template>
  <div
    id="panel"
    class="panel"
    style="min-height: 696px;"
  >

    <form
      id="searchSection"
      onsubmit="return false;"
    >
      <div class="search-line clearfix">
        <input
          v-model="queryInfo.super_title"
          type="text"
          name="title"
          class="s-input separate"
          placeholder="单位名称"
        >
        <input
          type="submit"
          class="s-btn"
          value="搜索"
          @click="search"
        >
        <div
          class="add-btn"
          @click="showMember"
        >添加高级会员
        </div>
      </div>

      <div class="search-line clearfix">

      </div>

    </form>
    <div id="list">
      <table v-if="this.tableData.length > 0">
        <tbody>
          <tr class="l_header">
            <th class="itemTitle">单位名称</th>
            <th class="itemTitle">联系人</th>
            <th class="itemTitle">电话</th>
            <th class="itemTitle">邮箱</th>
            <th class="itemCategory">创新合作伙伴</th>
            <th class="itemCategory">理事会员</th>
            <th
              class="action"
              width="155"
            >操作
            </th>
          </tr>
          <tr
            class="item"
            v-for="item in tableData"
            :key="item.id"
          >
            <td class="itemTitle">
              <a
                class="a-link"
                :href="'/www/appview?id=' + item.id"
                target="_blank"
              >
                {{ item.super_title }}
              </a>
            </td>
            <td class="itemTitle">{{ item.super_connect }}</td>
            <td class="itemTitle">{{ item.super_phone }}</td>
            <td class="itemTitle">{{ item.super_email }}</td>
            <td class="itemTitle">
              <span
                :class="item.super_category1 == 1 ? 'ck_select' : 'ck_unselect'"
                @click="changeSuperCategory1(item)"
              ></span>
            </td>
            <td class="itemTitle itemStatus3">
              <span
                :class="item.super_category2 == 1 ? 'ck_select' : 'ck_unselect'"
                @click="changeSuperCategory2(item)"
              ></span>
            </td>
            <td
              class="action"
              style="font-size:12px"
            >
              <a
                v-if="item.super_status == 2 || item.super_status == 3 "
                class="see"
                :href="'/www/appview?id=' + item.id"
                target="_blank"
              >查看</a>
              <a
                class="delete"
                href="javascript:;"
                @click="askDel(item.id)"
              >移出</a>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="noitems"
        v-if="tableData.length == 0"
      >没有数据
      </div>
    </div>

    <pagination
      :cur="pageInfo.page"
      :all="pageInfo.all"
      :totalData="pageInfo.totalData"
      :callback="pageCallback"
    ></pagination>

    <div
      class="form_overlay"
      id="expert_overlay"
      :style="classShowMemberWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel editor"><span
          class="overlay-close"
          @click="classShowMemberWindow={}"
        ><i class="iconfont">x</i></span>
        <div class="form_content">
          <form
            id="expert-search"
            class="search"
            onsubmit="return false;"
          >
            <input
              type="text"
              name="name"
              class="s-input"
              v-model="queryMemberInfo.like_title"
              placeholder="单位名称"
            ><input
              type="text"
              name="referral"
              class="s-input"
              v-model="queryMemberInfo.phone2"
              placeholder="手机号"
            ><input
              type="button"
              class="s-btn1"
              value="查询"
              @click="pageMemberCallback(1)"
            >
          </form>
          <div class="clearfix">
            <div
              id="expert-list"
              class="list"
            >
              <table>
                <tbody>
                  <tr class="l_header">
                    <th class="itemTitle">单位名称</th>
                    <th class="itemTitle">主要联系人</th>
                    <th class="itemTitle">电话</th>
                    <th class="itemTitle">邮箱</th>
                    <th width="80">操作</th>
                  </tr>
                  <tr
                    v-for="item in tableMemberData"
                    :key="item.id"
                  >
                    <td class="itemTitle">
                      <a
                        class="a-link"
                        :href="'/www/appview?id=' + item.id"
                        target="_blank"
                      >
                        {{ item.type == 3 ? item.schoolTitle : item.title }}
                      </a>
                    </td>
                    <td class="itemTitle">{{ item.contacts }}</td>
                    <td class="itemTitle">{{ item.phone2 }}</td>
                    <td>{{ item.email1 }}</td>
                    <td class="a-button"><a
                        class="a-button"
                        href="javascript:void(0)"
                        v-if="item.isSelect"
                      >已添加</a>
                      <a
                        style="color:#4976e8"
                        href="javascript:void(0)"
                        v-else
                        @click="choiceMember(item)"
                      >添加</a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                class="noitems"
                v-if="!tableMemberData.length"
              >没有用户
              </div>
            </div>
            <!-- 分页 -->
            <pagination
              :cur='pageMemberInfo.page'
              :all='pageMemberInfo.all'
              :totalData="pageMemberInfo.totalData"
              :callback="pageMemberCallback"
            ></pagination>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Pagination from "../../components/Pagination"

export default {
  name: "AdminSuperApply",
  components: {
    Pagination
  },
  data() {
    return {
      classShowMemberWindow: {visibility: 'hidden'},
      rejectForm: {
        id: 0,
        super_reject_reason: '',
      },
      queryInfo: {
        page: 1,
        size: 10,
        super_title: '',
        super_status: '1,2,3',
        isdel: 0
      },
      tableData: [],
      tableDataCount: 0,
      queryMemberInfo: {
        page: 1,
        size: 10,
        like_title: '',
        phone2: '',
        status: 5,
        type: '2',
      },
      tableMemberData: [],
      tableMemberDataCount: 0
    }
  },
  computed: {
    pageInfo() {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    },
    pageMemberInfo() {
      return {
        page: this.queryMemberInfo.page,
        all: Math.ceil(this.tableMemberDataCount / this.queryMemberInfo.size),
        totalData: this.tableMemberDataCount
      }
    },
  },
  methods: {
    async choiceMember(item) {
      const params = {
        super_title: item.title,
        super_connect: item.contacts,
        super_phone: item.phone2,
        super_email: item.email1,
        super_logo: item.logo,
        super_status: '3',//1 待审核 2 驳回 3 已通过
      }
      const url = '/application/' + item.id + '/'
      await this.$http.put(url, params).then(async (data) => {
        console.log(data)
        this.addMember()
        this.search()
        this.classShowMemberWindow = {}
      }).catch(() => {
        this.$parent.$parent.showMsg('获取数据失败！')
      })
    },
    async addMember() {
      await this.$http.get('/application/', {
        params: this.queryMemberInfo
      }).then((data) => {
        for (let i = 0; i < data.results.length; i++) {
          data.results[i].isSelect = false
          for (let j = 0; j < this.tableData.length; j++) {
            if (this.tableData[j].id === data.results[i].id) {
              data.results[i].isSelect = true
            }
          }
        }
        this.tableMemberData = data.results
        this.tableMemberDataCount = data.count
      }).catch(() => {
        this.$parent.$parent.showMsg('获取数据失败！')
      })
    },
    async search() {
      await this.$http.get('/application/', {
        params: this.queryInfo
      }).then((data) => {
        console.log(data)
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$parent.$parent.showMsg('获取通知信息失败！')
      })
    },

    async updateStatus(id, statusValue) {
      const url = '/application/' + id + '/'
      await this.$http.put(url, {
        super_status: statusValue
      }).then((data) => {
        console.log(data)
        this.search()
      }).catch((error) => {
        console.log(error)
        this.$layer.msg('操作失败！');
      })
    },
    async pageCallback(data) {
      this.queryInfo.page = data
      await this.search()
    },
    async pageMemberCallback(data) {
      this.queryMemberInfo.page = data
      await this.addMember()
    },
    async showMember() {
      this.classShowMemberWindow = {
        visibility: 'visible'
      }
      this.addMember()
    },
    async doReject() {
      const url = '/application/' + this.rejectForm.id + '/'
      await this.$http.put(url, {
        super_status: 2,
        super_reject_reason: this.rejectForm.super_reject_reason
      }).then((data) => {
        console.log(data)
        this.search()
        this.classApplyRejectShow = {visibility: 'hidden'}
      }).catch((error) => {
        console.log(error)
        this.$layer.msg('操作失败！');
      })
    },
    askDel(id) {
      this.$parent.$parent.showConfirm('确定要删除吗？', () => {
        this.delSuper(id)
      });
    },
    async delSuper(id) {
      const url = '/application/' + id + '/'
      await this.$http.put(url, {
        super_status: 0,
        super_title: '',
        super_connect: '',
        super_phone: '',
        super_email: '',
        super_logo: '',
        super_created: null,
        super_reject_reason: '',
        super_view_category: null,
        super_view_data: '',
      }).then((data) => {
        console.log(data)
        this.$layer.msg('删除成功！');
        this.search()
        this.classApplyRejectShow = {visibility: 'hidden'}
      }).catch((error) => {
        console.log(error)
        this.$layer.msg('删除失败！');
      })
    },
    async changeSuperCategory1(item) {
      let msg = item.super_category1 == 1 ? '确认取消该身份？' : '确认要将该单位设置成创新合作伙伴？'
      this.$parent.$parent.showConfirm(msg, async () => {
        let v = item.super_category1 == 1 ? 0 : 1
        const url = '/application/' + item.id + '/'
        await this.$http.put(url, {
          super_category1: v
        }).then((data) => {
          console.log(data)
          this.$layer.msg('操作成功！');
          this.search()
        }).catch((error) => {
          console.log(error)
          this.$layer.msg('操作失败！');
        })
      });
    },
    async changeSuperCategory2(item) {
      let msg = item.super_category2 == 1 ? '确认取消该身份？' : '确认要将该单位设置成理事会员？'
      this.$parent.$parent.showConfirm(msg, async () => {
        let v = item.super_category2 == 1 ? 0 : 1
        const url = '/application/' + item.id + '/'
        await this.$http.put(url, {
          super_category2: v
        }).then((data) => {
          console.log(data)
          this.$layer.msg('操作成功！');
          this.search()
        }).catch((error) => {
          console.log(error)
          this.$layer.msg('操作失败！');
        })
      });
    }
  },
  mounted() {
    this.search()
  },
}
</script>

<style scoped>
.ck_select {
  width: 16px;
  height: 16px;
  display: inline-block;
  background-image: url(../../assets/images/ck_select.png);
}

.ck_unselect {
  width: 16px;
  height: 16px;
  display: inline-block;
  background-image: url(../../assets/images/ck_unselect.png);
}

.action a {
  display: inline-block;
  padding: 0 !important;
  padding-right: 17px !important;
  word-wrap: break-word;
  text-align: left;
}

.btncolor {
  border: 1px solid #4976e8 !important;
  background-color: #4976e8 !important;
}

.form_panel {
  margin: 0;
  transform: translate(-50%, -50%);
}

.form_content table {
  border-collapse: collapse;
  text-align: center;
  background: #fff;
  width: 100%;
  margin: 0 auto;
  border-left: 1px solid #f0f0f3;
  border-top: 1px solid #f0f0f3;
}

.form_content tr.l_header {
  border: 1px solid #f0f0f3;
  background: #f5f7fc;
  border: none;
  color: #666;
}

.form_content table th {
  height: 44px;
  background: #f5f7fc;
  line-height: 44px;
  border-right: 1px solid #f0f0f3;
  border-bottom: 1px solid #f0f0f3;
  font-weight: lighter;
}

.form_content td {
  padding: 2px 5px;
  border-right: 1px solid #f0f0f3;
  border-bottom: 1px solid #f0f0f3;
  color: #959595;
}

#expert-search {
  margin-bottom: 10px;
}

#expert-search .s-input,
#expert-search .s-select,
#expert-search .s-checkbox {
  border: 1px solid #e6e6e6;
  background: #f5f7fc;
  height: 32px;
  line-height: 34px;
  padding-left: 10px;
  text-indent: 0;
  width: auto;
  border-radius: 2px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

#expert-search .s-btn1 {
  border: 1px solid #4976e8;
  background: #4976e8;
  height: 34px;
  line-height: 34px;
  text-align: center;
  color: #fff;
  padding: 0px 12px;
  cursor: pointer;
  border-radius: 2px;
  vertical-align: middle;
  display: inline-block;
}
</style>
