<template>
  <div>
    <ul class="btn-group">
      <li v-if="isPreview">
        <i class="iconfont icon-shiyongfangfa"></i>
        <span
          class="info"
          @click="showUsewindow"
        >使用方法</span>
      </li>
      <li v-if="isPreview">
        <i class="iconfont icon-shiyongmoban"></i>
        <span
          class="info"
          @click="showTemplateWindow"
        >选择模板</span>
      </li>
      <li>
        <i class="iconfont icon-yulan"></i>
        <span
          class="info"
          @click="preview"
        >{{isPreview?'预览':'取消预览'}}</span>
      </li>
      <li v-if="isPreview">
        <i class="iconfont icon-baocun"></i>
        <span
          class="info"
          @click="save"
        >保存并提交</span>
      </li>
      <li v-if="isPreview">
        <i class="iconfont icon-quxiao"></i>
        <span
          class="info"
          @click="$router.push('/profile')"
        >返回</span>
      </li>
    </ul>
    <div
      class="form_overlay"
      :style="classUseWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">使用方法<a
            href="javascript:;"
            class="close"
            @click="classUseWindow={}"
          >×</a></div>
        <div class="form_content">
          <p>1.点击文字部分进行文案编辑</p>
          <p>2.点击“上传图片”对现有图片进行替换，上传图片尺寸尽量与建议尺寸相同</p>
        </div>
        <div class="form_control">
          <a
            href="javascript:;"
            style="width: 30px"
            class="button"
            id="form_upload_close"
            @click="classUseWindow={}"
          >确定</a>
        </div>
      </div>
    </div>
    <div
      class="form_overlay"
      :style="classTemplateWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel form_template">
        <div class="form_title">选择模板<a
            href="javascript:;"
            class="close"
            @click="classTemplateWindow={}"
          >×</a></div>
        <div class="form_content">
          <ul class="form_ui">
            <li @click="radioClick=1">
              <img
                class="image"
                src="@/assets/images/template1.jpg"
                alt=""
              >
              <span class="text">
                <div
                  class="redio"
                  :class="{'radioActive':radioClick==1}"
                ></div>模板1
              </span>
            </li>
            <li @click="radioClick=2">
              <img
                class="image"
                src="@/assets/images/template2.jpg"
                alt=""
              >
              <span class="text">
                <div
                  class="redio"
                  :class="{'radioActive':radioClick==2}"
                ></div>模板2
              </span>
            </li>
            <li @click="radioClick=3">
              <img
                class="image"
                src="@/assets/images/template3.jpg"
                alt=""
              >
              <span class="text">
                <div
                  class="redio"
                  :class="{'radioActive':radioClick==3}"
                ></div>模板3
              </span>
            </li>
          </ul>
        </div>
        <div class="form_control">
          <a
            href="javascript:;"
            style="width: 30px"
            class="close"
            id="form_upload_close"
            @click="classTemplateWindow={}"
          >取消</a>
          <a
            href="javascript:;"
            style="width: 30px"
            class="button"
            id="form_upload_close"
            @click="submitOk"
          >确定</a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    isPreview: {
      type: Boolean,
    },
    category: {},
  },
  // 数据对象
  data () {
    return {
      radioClick:1,
      classUseWindow:{},
      classTemplateWindow:{},
    }
  },
  // 事件处理器
  methods: {
    // 预览/取消
    preview(){
      this.$emit('Setpreview')
    },
    save(){
      this.$emit('Setsave')
      this.$layer.msg('保存成功')
    },
    showUsewindow(){
      this.classUseWindow={
        visibility: 'visible'
      }
    },
    showTemplateWindow(){
      this.classTemplateWindow={
        visibility: 'visible'
      }
    },
    submitOk(){
      this.classTemplateWindow={}
      if(this.radioClick==1){
        this.$router.push('/template_one')
      }else if(this.radioClick==2){
        this.$router.push('/template_two')
      }else if(this.radioClick==3){
        this.$router.push('/template_three')
      }
    }
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () {
    this.radioClick = this.category
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.btn-group {
  position: fixed;
  left: 27px;
  top: 130px;
  width: 130px;
  z-index: 1000;
  overflow: hidden;
  li {
    width: 50px;
    margin-bottom: 17px;
    cursor: pointer;
    position: relative;
    i {
      display: inline-block;
      width: 50px;
      height: 50px;
      line-height: 50px;
      color: #335dcc;
      font-size: 30px;
      background: #fff;
      border-radius: 50%;
      text-align: center;
      border: 2px solid #335dcc;
    }
    &:hover .info {
      transition: left linear 0.1s;
      left: 0;
      opacity: 1;
    }
    .info {
      position: absolute;
      left: -122px;
      top: 0;
      display: inline-block;
      width: 122px;
      height: 54px;
      color: #fff;
      background: #335dcc;
      border-radius: 24px;
      text-align: center;
      line-height: 52px;
      font-size: 14px;
      opacity: 1;
    }
  }
}
.form_template {
  width: 1000px;
  max-width: 1000px;
  .form_ui {
    margin-bottom: 30px;
    padding: 0 25px;
    display: flex;
    justify-content: space-between;
    li {
      text-align: center;
      width: 280px;
      .image {
        width: 100%;
        height: 165px;
      }
      .text {
        font-size: 18px;
        color: #000000;
      }
      .redio {
        margin-right: 3px;
        width: 12px;
        height: 12px;
        display: inline-block;
        border: 2px solid #797979;
        border-radius: 50%;
        position: relative;
        top: 1px;
      }
      .radioActive:after {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #797979;
        position: relative;
        top: 2px;
        left: 2px;
      }
    }
  }
}
</style>
