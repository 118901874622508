<template>
  <div class="subRight">
    <div class="subRightTit">
      <span>联系我们</span>
      <a href="/">首页</a>&gt;&gt;
      <a href="/www/introduction/intro">联盟介绍</a>&gt;&gt;联系我们
    </div>
    <div class="con">
      <div id="contact_content" class="contact_content">
        <div class="clearfix">
          <h3>联系方式：</h3>

          <p>联盟秘书处办公室联系人：王成涛</p>
          <p>电话：13681363100</p>
          <p>邮箱：wangchengtao@china-didac.com</p>
          <p>地址：北京市西城区大木仓胡同北一巷1号中国教学仪器设备有限公司202室</p>

          <div class="map"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact"
}
</script>

<style scoped>

</style>
