<template>
  <div>
    <div class="breadcrumb-nav">
      <a href="/">首页</a>&gt;&gt;
      <a href="/www/organization/chairman">组织机构</a>&gt;&gt;技术工作委员会
    </div>
    <div class="subRight">
      <div class="subRightTit">
        <span>技术工作委员会</span>

      </div>
      <div class="con details">
        <article
          class="articleNews"
          v-html="data.length > 0 ? data[0].brief  : ''"
        ></article>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Technical",
  data (){
    return {
      data: []
    }
  },
  methods: {
    async getData(){
      await this.$http.get('/allowany/committee_brief/', {
        params: {
          page: 1,
          size: 1
        }
      }).then((data) => {
        console.log(data)
        this.data = data.results
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped>
</style>
