<template>
  <div id="content1" class="pull-right">
    <div class="center_content" style="min-height: 458px;">
      <form id="searchSection" class="search-form" onsubmit="return false;">
          <input type="text" name="title" class="s-input separate" placeholder="实验项目方向" v-model="queryInfo.direction">
          <input type="text" name="title" class="s-input separate" placeholder="课程名称" v-model="queryInfo.courseTitle">
          <select class="s-select separate" name="categorie01" v-model="queryInfo.categorie01" @change="getChildCategory(2)">
            <option value=''>请选择领域</option>
            <option v-for="(text, value) in category_dict" :key="value" :value="value">{{ text }}</option>
          </select>
          <select class="s-select separate" name="categorie02" v-model="queryInfo.categorie02" @change="getChildCategory(3)" >
            <option value=''>请选择专业类</option>
            <option v-for="text in category02_dict" :key="text.code" :value="text.code">{{text.title}}</option>
          </select>
          <select name="status" class="s-select separate" v-model="queryInfo.categorie03">
            <option value=''>请选择适用专业</option>
            <option v-for="text in category03_dict" :key="text.code" :value="text.code">{{text.title}}</option>
          </select>
          <input type="button" class="s-btn" value="搜索" @click="queryInfo.page=1,search()">
        </form>
        <hr>
        <ul class="toggle-button">
          <li  v-for="item in stateArry" :key="item.status" :class="actived==item.code?'active':''" @click="changeStatus(item.code)">
            <div>
              <span>{{item.state}}</span>
              <span>({{getNum(item.code)}})</span>
            </div>
          </li>
        </ul>
        <div class="buttons">
          <button class="export" v-if="actived==3 && (localUser.role==5 || localUser.role==6)" @click="exportMember">导出</button>
          <button class="export" v-if="actived==0" @click="showUploadWindow">导入</button>
          <button class="publish" v-if="actived==0" @click="SubmitAll">全部提交</button>
          <router-link to="/admin_info_edit?state=0">
           <button class="add" v-if="actived==0" >新建</button>
          </router-link>
          <button @click="toDetail">切换详情模式</button>
        </div>
        <div id="list">
          <table v-if="this.tableData.length > 0">
            <tbody>
            <tr class="l_header">
              <!-- <th v-if="actived==0" width="45px"><a href="javascript:;" class="a-link" @click="ckAll">全选</a></th> -->
              <th class="itemStatus">编号</th>
              <th class="itemStatus">课程名称</th>
              <th class="itemStatus">实验项目方向</th>
              <th class="itemStatus">领域</th>
              <th class="itemStatus">专业类</th>
              <th class="itemStatus">适用专业</th>
              <th class="itemStatus">创建人</th>
              <th class="itemStatus">创建时间</th>
              <th class="itemStatus">状态</th>
              <th class="action">操作</th>
            </tr>
            <tr class="item"  v-for="item in tableData" :key="item.id">
              <!-- <td v-if="actived==0">
                <input type="checkbox" name="chose" :value="item.id" v-model="ckUser" @change="ckChange">
              </td> -->
              <!-- <td class="itemId"></td> -->
              <td class="itemStatus">{{num_format(item.categorie02,item.id)}}</td>
              <td class="itemStatus">{{item.courseTitle}}</td>
              <td class="itemStatus">{{item.direction}}</td>
              <td class="itemStatus">{{ item.categorie01 in category_dictAll ? category_dictAll[item.categorie01] : '-' }}</td>
              <td class="itemStatus">{{ item.categorie02 in category_dictAll ? category_dictAll[item.categorie02] : '-'}}</td>
              <td class="itemStatus">{{ item.categorie03 in category_dictAll ? category_dictAll[item.categorie03] : '-'}}</td>

              <td class="itemStatus">{{item.owner}}</td>
              <td class="itemStatus">{{item.created? item.created.substr(0, 10) : ''}}</td>
              <td class="itemStatus" :class="`color${item.status}`">{{getStatusTxt(item.status)}}</td>
              <td class="action">
                <router-link class="see" :to="'/admin_info_edit?id=' + item.id +'&state=1&num='+ num_format(item.categorie02,item.id)">查看</router-link>
                <router-link  v-if="actived == 0" class="edit" :to="'/admin_info_edit?id=' + item.id +'&state=2'">编辑</router-link>
                <a v-if="actived==0" class="delete" href="javascript:;" @click="askDel(item.id)">删除</a>
                <a v-if="actived==4" class="color6" href="javascript:;" @click="Review(item.feedback)">评审意见</a>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="noitems" v-if="tableData.length == 0">没有数据</div>
        </div>
        <pagination :cur="pageInfo.page" :all="pageInfo.all" :totalData="pageInfo.totalData"
                    :callback="pageCallback"></pagination>
        <!-- 弹窗 -->
        <div class="form_overlay" :style="classShowUploadWindow">
          <div class="form_overlay_bg"></div>
          <div class="form_panel" >
            <div class="form_title">批量上传</div>
            <div class="form_content">
              <form id="uploadForm">
                <div class="item">
                  <span class="label" style="width: auto"><i class="require"></i>模版创建规则:</span>
                  <div class="item-box divLabel gray">
                    <div class="normal-width">
                      <p>1. 文件格式为“逗号分隔”的CSV文件；</p>
                      <p>2. 文件编码必须为ANSI编码；</p>
                      <p>3. 上传文档转换完毕后，请使用记事本打开，查看是否含有空数据，如果含有空数据，则需删除；</p>
                      <p>4. 上传文档中内容不能含有西文逗号；</p>
                      <p>5. CSV文件生成，可使用excel另存为“逗号分隔的CSV文件”；</p>
                      <p>6. 文件编码，可使用记事本打开CSV，另存为ANSI编码。</p>
                    </div>
                  </div>
                  <div class="clear"></div>
                  <div class="attachment">
                    <a href="/static/upload_template/batch_upload_guide_template.csv" download="batch_upload_guide_template.csv" target="_blank">模版下载</a>
                  </div>
                </div>
                <div class="item">
                  <span class="label" style="width: auto"><i class="require">*</i>CSV文件:</span>
                  <div class="item-box" style="margin-left: 130px;">
                    <input name="file" v-model="uploadedCsv" disabled type="text" id="fileUrl" style="width: 225px;">
                    <span class="button" style="position: relative;top: 0px; left: 0px;">
                      选择文件
                      <input @change="xlsChange" type="file" ref="xlsFile" id="fileUpload" class="upload-derict">
                    </span>
                  </div>
                  <div class="clear"></div>
                </div>
              </form>
            </div>
            <div class="form_control">
              <a href="javascript:;" style="width: 30px" class="button close" id="form_upload_close"
                @click="hideUploadWindow">取消</a>
              <a href="javascript:;" style="width: 30px" class="button" id="form_upload_submit"  @click="submitCsv">提交</a>
            </div>
          </div>
        </div>
        <div id="dialog_overlay" :style="classShowProcessWindow">
          <div id="dialog_bg"></div>
          <div id="dialog_panel" ref="refErrorList" style="margin-left: -136px; margin-top: -54px;max-width: 300px;">
            <div id="dialog_panel_bg"></div>
            <div id="dialog_title">提示</div>
            <div id="dialog_content">
              ({{ curCsvIndex }} / {{ uploadedCscData.length }})
              <span style="color: #219d2b">
                {{ ' ' + csvFinishText }}
              </span>
            </div>
            <div id="dialog_content2">
              <ul>
                <li v-for="(item, index) in errorList" v-bind:key="index">
                  <span :class="item.code == 1 ? 'success' : 'fail'">{{ item.text }}</span>
                </li>
              </ul>

            </div>
            <div id="dialog_control">
              <a href="javascript:void(0)" id="dialog_ok" class="button col2" @click="classShowProcessWindow={}"
                :style="isFinishUpsert ? 'display: inline-block' : 'display:none'">确认</a>
            </div>
          </div>
        </div>
        <!-- 评审意见弹窗 -->
        <div class="form_overlay" :style="classReviewWindow">
          <div class="form_overlay_bg"></div>
          <div class="form_panel" >
            <div class="form_title">评审意见</div>
            <div class="form_content">
              <form id="uploadForm">
                <div class="item">
                  <p>{{this.review}}</p>
                </div>
              </form>
            </div>
            <div class="form_control">
              <a href="javascript:;" style="width: 30px" class="button close" id="form_upload_close"
                @click="classReviewWindow={}">关闭</a>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import outputTable from "../../assets/basic/outputTable.js"
import Pagination from "../../components/Pagination";
export default {
 components: {
    Pagination
  },
  data() {
    return {
      ckUser:[],
      classShowUploadWindow:{},
      classReviewWindow:{},
      review:'',
      dictDomain: [],
      dictCategory: [],
      dictMajor: [],
      actived:'0',
      stateArry:[
        { state:'建设中',code:0 },
        { state:'待审核',code:1 },
        { state:'待评审',code:3 },
        { state:'已发布',code:5 },
        { state:'已关闭',code:2 },
        { state:'评审未通过',code:4 },
      ],
      toggleBtn:[],
      queryInfo: {
        page: 1,
        size: 10,
        categorie01: '',
        categorie02: '',
        categorie03: '',
        direction: '',
        courseTitle: '',
        ownerId:'',
        status:0
      },
      formInfo: {
        id: 0,
        position: '',
        title: '',
        link: '',
        imgPath: ''
      },
      classShowAddWindow: {},
      classShowImageWindow: {},
      showImgPreview: false,
      uploaded_img: '',
      tableData: [],
      tableDataCount: 0,

      category_dictAll:[],  //列表渲染用
      category_dict:{},
      category02_dict:{},
      category03_dict:{},

      // 导入
      uploadedCsv: '',
      uploadedCscData: [],
      classShowProcessWindow: {},
      isFinishUpsert: false,
      curCsvIndex: 0,
      csvFinishText: '',
      errorList: [],

      exportArray:[
        {
          courseTitle:'单位名称',
          direction:'院系名称',
          contacts:'实验项目方向',
          categorie01:'领域',
          categorie02:'专业类',
          categorie03:'适用专业',
          owner:'创建人',
          created:'创建时间',
          status:'状态'
        }
      ],
    }
  },
  computed: {
    pageInfo() {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    },
    localUser(){
      return this.$store.state.userInfo
    }
  },
  methods: {
    // 导出查询列表
    async exportMember() {
      await this.$http.get('/guide/', {
        params: {
          page:1,
          size:100000,
          status:3,
          ownerId:this.localUser.user_id,
          direction:this.queryInfo.direction,
          courseTitle:this.queryInfo.courseTitle,
          categorie01:this.queryInfo.categorie01,
          categorie02:this.queryInfo.categorie02,
          categorie03:this.queryInfo.categorie03,
        }
      }).then((data) => {
        if(data.results.length==0){
          this.$parent.$parent.showMsg('暂无信息可导出！')
          return false
        } else{
          data.results.forEach(item => {
            this.exportArray.push({
              courseTitle:item.courseTitle,
              direction:item.direction,
              contacts:item.courseTitle,
              categorie01:item.categorie01 in this.category_dictAll ? this.category_dictAll[item.categorie01]:'-',
              categorie02:item.categorie02 in this.category_dictAll ? this.category_dictAll[item.categorie02]:'-',
              categorie03:item.categorie03 in this.category_dictAll ? this.category_dictAll[item.categorie03]:'-',
              owner:item.owner,
              created:item.created? item.created.substr(0, 10) : '-',
              status:this.getStatusTxt(item.status),

            })
          });
          this.$parent.$parent.showMsg('导出成功')
          this.exportCsv()
        }
      }).catch(() => {
        this.$parent.$parent.showMsg('获取会员信息失败！')
      })
    },
    // 导出
    exportCsv () {
      var fileName='指南管理待评审列表.csv'
      outputTable(this.exportArray,fileName)
      this.exportArray = [
        {
          courseTitle:'单位名称',
          direction:'院系名称',
          contacts:'实验项目方向',
          categorie01:'领域',
          categorie02:'专业类',
          categorie03:'适用专业',
          owner:'创建人',
          created:'创建时间',
          status:'状态'
        }
      ]
    },
    getStatusTxt(code){
      if(code==0){
        return '建设中'
      }else if(code==1){
        return '待审核'
      }else if(code==3){
        return '待评审'
      }else if(code==5){
        return '已发布'
      }else if(code==2){
        return '已关闭'
      }else if(code==4){
        return '评审未通过'
      }
    },
    changeStatus(id){
      this.actived=id
      this.queryInfo.status=id
      this.search()
    },
    num_format(category, id) {
      let res = ''
      res += category
      for(let i=0;i<5-id.toString().length;i++){
        res += '0'
      }
      res += id.toString()
      return res
    },
    async getGuideStatus() {
      await this.$http.get('/guide_status/',{
        params:{
          ownerId:this.localUser.user_id
        }
      }).then((data) => {
        this.toggleBtn = data.data
      }).catch(() => {
        this.$parent.$parent.showMsg('获取指南信息失败！')
      })
    },
    // 删除
    askDel(id){
      this.$parent.$parent.showConfirm('确定要删除吗？', () => {
        this.delGuide(id)
      });
    },
    async delGuide(id){
      let url = '/guide/' + id + '/'
      await this.$http.put(url, {
        isdel: 1
      }).then((data) => {
        if (data.id) {
          this.$parent.$parent.showMsg('删除成功！')
          this.ckUser = []
          this.search()
        } else {
          this.$parent.$parent.showMsg('删除失败！')
        }
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg('删除失败！')
      })
    },
    // 评审意见弹窗
    Review(item){
      this.review = item
      this.classReviewWindow = {
        visibility: 'visible'
      }
      
    },
    // 获取对应状态下的指南数量
    getNum(code){
      var num = 0
      this.toggleBtn.forEach(item => {
        if(code == item.status){
          num = item.status_sum
        }
      })
      return num
    },
    async search() {
      this.queryInfo.ownerId = this.localUser.user_id
      await this.$http.get('/guide/', {
        params: this.queryInfo
      }).then((data) => {
        this.tableData = data.results
        this.tableDataCount = data.count
        this.tableData.forEach(element => {
          this.ckUser.push(element.id)
        });
        
      }).catch(() => {
        this.$parent.$parent.showMsg('获取指南信息失败！')
      })
      this.getGuideStatus()
    },
    // 提交指南列表
    async SubmitAll(){
      if(this.ckUser.length==0){
        this.$parent.$parent.showMsg('提交失败！')
        return
      }
      this.$parent.$parent.showConfirm('确定要提交吗？', () => {
        this.ckUser.forEach(async element => {
          let url = '/guide/' + element + '/'
          await this.$http.put(url, {
            status:1
          }).then((data) => {
            if (data.id) {
              this.$parent.$parent.showMsg('提交成功！')
              this.ckUser = []
              this.search()
            } else {
              this.$parent.$parent.showMsg('提交失败！')
            }
          }).catch((error) => {
            console.log(error)
            this.$parent.$parent.showMsg('提交失败！')
          })
        });
      });
    },
    async pageCallback(data) {
      this.queryInfo.page = data
      await this.search()
    },
    // 查找分类
    async get_category(type){
      await this.$http.get('/categories/',{
        params: {
          grade: type==1?1:'',
          status: 1,
          isdel: 0,
          size:1000
        }
      }).then(async (data) => {
        let temp_dict = {}
        for(let i=0;i<data.results.length;i++){
          temp_dict[data.results[i]['code']] = data.results[i]['title']
        }
        if(type==1){
          this.category_dict = temp_dict
        }else if(type==2){
          this.category_dictAll = temp_dict
        }
        
      })
    },
    // 查找子级分类
    async getChildCategory(level){

      let params = {}
      if(level == 1){
        params = {
          grade: 1,
          status: 1,
          isdel: 0
        }
      }else if(level == 2){
        params = {
          grade: 2,
          status: 1,
          isdel: 0,
          parent: this.queryInfo.categorie01
        }
      }else if(level == 3){
        params = {
          grade: 3,
          status: 1,
          isdel: 0,
          parent2: this.queryInfo.categorie02
        }
      }

      await this.$http.get('/categories/', {
        params: params
      }).then(async (data) => {
        if(level == 2){
          this.category03_dict = {}
          this.queryInfo.categorie02 = ''
          this.queryInfo.categorie03 = ''
          this.category02_dict = data.results
        }else if(level == 3){
          this.category03_dict = data.results
          this.queryInfo.categorie03 = ''
        }
      })
    },
    // 全选
    ckAll() {
      if (this.ckUser.length == this.tableData.length) {
        this.ckUser = []
      } else {
        for (let i = 0; i < this.tableData.length; i++) {
          this.ckUser.push(this.tableData[i].id)
        }
      }
    },
    ckChange() {
      console.log()
    },

    // 模式切换
    toDetail() {
      window.location.href = '/admin_guid_detail?status=' + this.actived + '&type=1'
    },

    // 导入
    showUploadWindow() {
      this.classShowUploadWindow = {
        visibility: 'visible'
      }
    },
    hideUploadWindow() {
      this.classShowUploadWindow = {}
    },

    async xlsChange() {
      const uploadUrl = '/files/' + this.$refs.xlsFile.files[0].name
      await this.$http.put(uploadUrl, this.$refs.xlsFile.files[0]).then((data) => {
        this.uploadedCsv = data
      }).catch(() => {
        this.$parent.$parent.showMsg('上传失败！')
      })
    },
    async submitCsv() {
      const uploadUrl = '/get_csv/'
      await this.$http.get(uploadUrl, {
        params: {
          'file_name': this.uploadedCsv
        }
      }).then((data) => {

        if(data.length <= 1){
          this.$parent.$parent.showMsg('表格中无数据！')
          return;
        }

        data.splice(0, 1)

        this.uploadedCscData = data

        this.hideUploadWindow()

        this.classShowProcessWindow = {
          visibility: 'visible'
        }
        this.isFinishUpsert = false
        this.curCsvIndex = 0
        this.csvFinishText = ''
        this.errorList = []
        this.insertOne()

      }).catch(() => {
        this.$parent.$parent.showMsg('读取失败！')
      })
    },
    async insertOne(){

      if(this.curCsvIndex >= this.uploadedCscData.length) {
        this.csvFinishText = '上传完成'
        this.isFinishUpsert = true
        this.search()
        return;
      }

      let row = this.uploadedCscData[this.curCsvIndex]
      let courseType = ''
      let experimentType = ''
      


      // region 数据检查开始

      if (row[0].trim().length == 0) {
        this.errorList.push({code: 0, text: '请输入课程名称！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      if (row[1].trim().length == 0) {
        this.errorList.push({code: 0, text: '请输入领域编码！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      if (row[2].trim().length == 0) {
        this.errorList.push({code: 0, text: '请输入专业类编码！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      if (row[3].trim().length == 0) {
        this.errorList.push({code: 0, text: '请输入适用专业编码！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      if (row[4].trim().length == 0) {
        this.errorList.push({code: 0, text: '请输入课程类型！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      if (row[5].trim().length == 0) {
        this.errorList.push({code: 0, text: '请输入实验类型！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      if (row[6].trim().length == 0) {
        this.errorList.push({code: 0, text: '请输入建议学时！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      if (row[7].trim().length == 0) {
        this.errorList.push({code: 0, text: '请输入实验项目方向！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      if (row[8].trim().length == 0) {
        this.errorList.push({code: 0, text: '请输入必要性！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      if (row[9].trim().length == 0) {
        this.errorList.push({code: 0, text: '请输入内容简介！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      // 检查输入的内容是否正确
      if (row[4] != '专业基础' && row[4] != '公共基础' && row[4] != '专业') {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '课程类型输入错误！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }
      if (row[5] != '综合' && row[5] != '验证') {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '实验类型输入错误！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }
      if (row[6] != 2 && row[6] != 3 && row[6] != 4 && row[6] != 5 && row[6] != 6 && row[6] != 7 && row[6] != 8 ) {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '建议课时输入错误！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      if (row[4] == '专业基础') {
        courseType = '1'
      } else if (row[4] == '公共基础') {
        courseType = '2'
      } else if (row[4] == '专业') {
        courseType = '3'
      }

      if (row[5] == '综合') {
        experimentType = '1'
      } else if (row[5] == '验证') {
        experimentType = '2'
      } 


      // endregion 数据检查结束

      let addForm = {
        courseTitle:row[0],
        categorie01:row[1],
        categorie02:row[2],
        categorie03:row[3],
        courseType: courseType,
        experimentType: experimentType,
        hours:row[6],
        direction:row[7],
        necessity:row[8],
        brief:row[9],
        suggest:row[10],
        remarks:row[11],
        owner:this.localUser.name,
        ownerId:this.localUser.user_id,
      }

      await this.$http.post('/guide/',addForm).then(async (data) => {
        console.log(data)
        this.errorList.push({ code : 1, text: '[' + addForm.courseTitle + ']' +"导入成功！" })
        this.curCsvIndex++
        this.insertOne()

      }).catch((error) => {
        this.errorList.push({ code : 0, text: '[' + addForm.courseTitle + ']' + error.response.data[0] })
        this.curCsvIndex++
        this.insertOne()
      })
    },

  },
  mounted() {
    this.get_category(1)
    this.get_category(2)
    this.getGuideStatus()
    this.search()
  }
}
</script>

<style lang="less" scoped>
#pagination{
  float: none;
}

.center_content{
  width: 900px !important;
  margin: 0px !important;
}

.tip{
  text-align: left;
}
.toggle-button{
  width:100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  li{
    background:#d8d8d8;
    margin-right:10px;
    box-sizing: border-box;
    padding: 6px 10px;
    cursor: pointer;
    &>div{
      span{
        line-height: 1.2;
        display:block;
        text-align:center;
        color:#fff;
      }
    }
  }
  .active{
    background:#4976e8;
  }
}
.buttons{
  width:100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button{
    padding:10px 20px;
    border: 1px solid #4976e8;
    background: #4976e8;
    color:#fff;
    margin-left:10px;
    cursor: pointer;
  }
  .export{
    background: #8f8f8f;
    border: 1px solid #8f8f8f;
  }
  .publish{
    border: 1px solid #ffa445;
    background: #ffa445;
  }
  .add{
    border: 1px solid #018484;
    background: #009900;
  }
}
#list{
  td{
    padding: 15px 5px;
    border-right: 1px solid #f0f0f3;
    border-bottom: 1px solid #f0f0f3;
    color: #959595;

  }
  .color0{
    color:#eb6736
  }

  .color1{
    color:#eb6736
  }
  .color2{
    color:#959595
  }
  .color3{
    color:#eb6736
  }
  .color4{
    color:#eb6736
  }
  .color5{
    color:#219d2b
  }
  .color6{
    color:#FF9000
  }
}
</style>
