<template>
  <div id="center" style="min-height: 514px;">
    <div class="center_content">
      <div class="word">
        <i></i>
        <span>忘记密码</span>
      </div>
      <div class="container">
        <div class="main">
          <p class="tip">请输入绑定的手机号</p>
          <form class="form-signin" id="itemForm">

            <div class="step1" v-show="step == 1">
              <div class="item phone-reg">
                <input v-model="formInfo.mobile" name="phone" type="num" class="form-control" placeholder="手机号">
              </div>

              <div class="item">
                <input v-model="formInfo.code" name="code" type="text" class="form-control short" placeholder="填写验证码">
                <span class="sendCode sendCode1" @click="sendMsg"
                      :style="isMsgSend ? 'height: 42px; padding: 0px; line-height: 42px;' : ''">
                  {{ this.isMsgSend ? '' + this.seconds + '秒' : '获取验证码' }}
                </span>
              </div>
              <input type="button" class="btn" id="submitBtn" value="下一步" @click="verifyPhone">
            </div>
            <div class="step2" v-show="step == 2">
              <div class="item">
                <input v-model="formInfo.password1" name="password" type="password" class="form-control" placeholder="填写新密码">
              </div>
              <div class="item">
                <input v-model="formInfo.password2" name="password2" type="password" class="form-control" placeholder="再次填写新密码">
              </div>
              <input type="button" class="btn" id="submitBtn2" value="确认修改" @click="saveInfo">
              <div class="tip2">
                <p>1、密码长度8-64位</p>
                <p>2、须同时包含大写字母、小写字母、数字、特殊字符中的任意三种</p>
                <p>3、密码不能包含邮箱</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ForgotPwd",
  data() {
    return {
      step: 1,
      isMsgSend: false,
      interval: null,
      seconds: 0,
      formInfo: {
        user_id: 0,
        mobile: '',
        code: '',
        password1: '',
        password2: ''
      },
    }
  },
  computed: {
    verifyForm() {
      return {
        phone: this.formInfo.mobile,
        code: this.formInfo.code,
        type: 2 // 1 注册 2 忘记密码 3 绑定手机
      }
    },
  },
  methods: {
    async sendMsg() {
      if (this.isMsgSend == true){
        return;
      }
      await this.$http.get('/verify_code/', {
        params: this.verifyForm
      }).then((data) => {
        console.log(data)
        this.$layer.msg("验证码已发送，有效期两分钟！")

        this.isMsgSend = true
        this.seconds = 120
        this.interval = setInterval(() => {
          if(this.seconds == 0){
            clearInterval(this.interval)
            this.isMsgSend = false
          }
          this.seconds -= 1
        }, 1000)

      }).catch(() => {
        this.$parent.showMsg('发送验证码失败！')
      })

    },
    async verifyPhone() {
      await this.$http.get('/allowany/userprofile/', {
        params: {
          page: 1,
          size: 1,
          mobile: this.formInfo.mobile,
        }
      }).then((data) => {
        console.log(data)
        if(data.results.length > 0){
          this.formInfo.user_id = data.results[0].user_id
          this.verifyCode()
        }else{
          this.$parent.showMsg('手机号码不存在！')
        }
      }).catch((data) => {
        console.log(data)
        this.$parent.showMsg('手机号码不存在！')
      })
    },
    async verifyCode() {
      await this.$http.post('/verify_code/', this.verifyForm).then((data) => {
        console.log(data)
        if (data.code == '200'){
          this.step += 1
        } else {
          this.$parent.showMsg(data.msg)
        }
      }).catch((data) => {
        console.log(data)
        this.$parent.showMsg("验证码错误")
      })
    },
    async saveInfo() {
      // 保存前 数据检查
      if(!this.formInfo.password1){
        this.$parent.showMsg("请输入密码!")
        return false
      }
      if(!this.formInfo.password2){
        this.$parent.showMsg("请输入确认密码!")
        return false
      }
      if(this.formInfo.password1 != this.formInfo.password2){
        this.$parent.showMsg("两次输入密码不一致!")
        return false
      }

      // let url = '/allowany/user/' + this.formInfo.user_id + '/'
      await this.$http.post('/forgot_password/', {
        password1: this.formInfo.password1,
        password2: this.formInfo.password2,
        code: this.formInfo.code,
        mobile: this.formInfo.mobile
      }).then((data) => {
        if (data.code === 200) {
          this.$parent.showMsg('修改密码成功！', () => {
            this.$router.push('/login')
          })
        } else {
          this.$parent.showMsg('修改密码失败！')
        }
      }).catch((error) => {
        console.dir(error)
        this.$parent.showMsg('修改密码失败！')
      })
    },

  }
}
</script>

<style scoped>
@import "../../assets/css/find-password.css";

.step1, .step2 {
  display: block;
}
</style>
