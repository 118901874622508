<template>
  <div id="center">
    <div class="tip-info">
      <h2>{{ infoModel ? infoModel.title : '' }}</h2>
      <span class="solicitationBtn" @click="showAddWindow">指南征集</span>
      <h4 class="subTitle">{{ infoModel ? infoModel.subTitle : '' }}</h4>
      <div class="info">
        <span class="source">{{ infoModel ? (infoModel.source == 0 ? '原创' : '转载') : '' }}&nbsp;</span>
        <span class="" time="">{{ infoModel ? infoModel.date : '' }}</span>
      </div>
      <article class="articleNews" v-html="infoModel ? infoModel.contents : ''">
      </article>
    </div>
    <div class="list-table ">
      <div class="m-t-md">
          <ul class="tabList">
            <li v-for="(item,index) in publishGuieArr" :key="index"
            :class="onClick == index?'onActive':''" @click="onClick = index,guideListInfo.code=item.code,getStatisticData(item.code)">{{item.title}}</li>
          </ul>
        </div>
      <div v-if="viewList==1">
        <div class="m-t-md">
            <div class="table-wrap" style="width:100%">
              <table>
                <tbody>
                  <tr class="l_header">
                    <th class="itemStatus">专业类名称</th>
                    <th class="itemStatus">指南数</th>
                    <th class="itemStatus">覆盖专业数</th>
                    <th class="itemStatus">覆盖课程数</th>
                    <th class="action">操作</th>
                  </tr>
                  <tr class="item" v-for="(item,index) in majorGuieArr" :key="index">
                    <td>{{item.title}}</td>
                    <td>{{item.count_guide}}</td>
                    <td>{{item.count_categorie03}}</td>
                    <td>{{item.count_course}}</td>
                    <td @click="toggleList(item)">查看指南列表</td>
                  </tr>
                </tbody>
              </table>
              <div class="noInfo"  v-if="majorGuieArr.length == 0">没有数据</div>
              <pagination :cur="listPageInfo.page" :all="listPageInfo.all" :totalData="listPageInfo.totalData" :callback="listPageCallback"></pagination>
            </div>
        </div>

      </div>
      <!-- 指南列表 -->
      <div v-else-if="viewList==2">
        <hr>
        <div class="guide-info">
          <span>专业类：{{guideInfo.title}}</span>
          <span>指南数：{{guideInfo.count_guide}}</span>
          <span>覆盖专业数：{{guideInfo.count_categorie03}}</span>
          <span>覆盖课程数：{{guideInfo.count_course}}</span>
        </div>
        <div class="search">
          <div>
          <input type="text" name="title" class="s-input separate" placeholder="适用专业" v-model="queryInfo.categorie03_dis">
          <input type="text" name="title" class="s-input separate" placeholder="课程名称" v-model="queryInfo.courseTitle">
          <input type="text" name="title" class="s-input separate" placeholder="实验项目方向" v-model="queryInfo.direction">
          <button class="s-btn" @click="search">搜索</button>
          </div>
          <button @click="backPrev">返回</button>
        </div>
        <div class="m-t-md" >
            <div class="table-wrap" style="width:100%" >
              <table>
                <tbody>
                  <tr class="l_header">
                    <th class="itemStatus">编号</th>
                    <th class="itemStatus">适用专业</th>
                    <th class="itemStatus">课程类型</th>
                    <th class="itemStatus">课程名称</th>
                    <th class="itemStatus">实验项目方向</th>
                    <th class="itemStatus">实验类型</th>
                    <th class="itemStatus">建议学时</th>
                    <th class="action">操作</th>
                  </tr>
                  <tr class="item" v-for="item in tableData" :key="item.id">
                    <td class="itemStatus">{{num_format(item.categorie02,item.id)}}</td>
                    <td class="itemStatus">{{item.categorie03 in category_dict ? category_dict[item.categorie03]:''}}</td>
                    <td class="itemStatus">{{ getCourseType(item.courseType)}}</td>
                    <td class="itemStatus">{{item.courseTitle}}</td>
                    <td class="itemStatus">{{item.direction}}</td>
                    <td class="itemStatus">{{ getExperimentType(item.experimentType)}}</td>
                    <td class="itemStatus">{{item.hours}}</td>
                    <td class="action" @click="showDetailWindow(item.id)">
                      详情
                    </td>
                  </tr>

                </tbody>
              </table>
              <div class="noInfo" v-if="tableData.length==0">没有数据</div>
              <pagination :cur="pageInfo.page" :all="pageInfo.all" :totalData="pageInfo.totalData" :callback="pageCallback"></pagination>
            </div>

        </div>
      </div>
    </div>
    <div class="form_overlay" id="form_overlay" :style="classShowDetailWindow">
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">指南详情</div>
        <div class="form_close" @click="hideDetailWindow()">关闭</div>
        <div class="form_content">
          <div class="form-row">
            <div class="form-col">
              <div class="form-group">
                <span>编号：</span>
                <p>{{num_format(formInfo.categorie02,formInfo.id)}}</p>
              </div>
            </div>
            <div class="form-col">
              <div class="form-group">
                <span>创建时间：</span>
                <p>{{formInfo.created}}</p>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-col">
              <div class="form-group">
                <span class="redlabel">课程名称：</span>
                <p>{{formInfo.courseTitle}}</p>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-col">
              <div class="form-group">
                <span class="redlabel">领域：</span>
                <p>{{formInfo.categorie01 in category_dict ? category_dict[formInfo.categorie01]:''}}</p>
              </div>
            </div>
            <div class="form-col">
              <div class="form-group">
                <span class="redlabel">专业类：</span>
                <p>{{formInfo.categorie02 in category_dict ? category_dict[formInfo.categorie02]:''}}</p>
              </div>
            </div>
            <div class="form-col">
              <div class="form-group">
                <span class="redlabel">适用专业：</span>
                <p>{{formInfo.categorie03 in category_dict ? category_dict[formInfo.categorie03]:''}}</p>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-col">
              <div class="form-group">
                <span class="redlabel">课程类型：</span>
                <p>{{getCourseType(formInfo.courseType)}}</p>
              </div>
            </div>
            <div class="form-col">
              <div class="form-group">
                <span class="redlabel">实验类型：</span>
                <p>{{ getExperimentType(formInfo.experimentType)}}</p>
              </div>
            </div>
            <div class="form-col">
              <div class="form-group">
                <span class="redlabel">建议学时：</span>
                <p>{{formInfo.hours}}</p>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-col">
              <div class="form-group">
                <span class="redlabel">实验项目方向：</span>
                <p>{{formInfo.direction}}</p>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-col">
              <div class="form-group">
                <span class="redlabel">必要性：</span>
                <p>{{formInfo.necessity}}</p>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-col">
              <div class="form-group">
                <span class="redlabel">必内容简介：</span>
                <p>{{formInfo.brief}}</p>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-col">
              <div class="form-group">
                <span>建议实验项目：</span>
                <p>{{formInfo.suggest}}</p>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-col">
              <div class="form-group">
                <span>备注：</span>
                <p>{{formInfo.remarks}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form_overlay" id="form_overlay" :style="classShowAddWindow">
      <div class="form_overlay_bg"></div>
      <div class="form_panel form_acitve" style="width:700px">
        <div class="form_title">新建指南</div>
        <div class="content-form">
        <form id="editForm" class="edit-form">
          <div class="form-row" id="numBox">
            <div class="form-col">
              <div class="form-group">
                <label class="required" for="courseTitle">课程名称</label>
                <input type="text" v-model="formInfoGuide.courseTitle" :disabled="state" class="form-input" id="courseTitle" placeholder="请输入课程名称"/>
              </div>
            </div>
          </div>
          <div class="form-row" id="numBox">
            <div class="form-col">
              <div class="form-group">
                <label class="required" for="courseTitle">领域</label>
                <select name="status" class="form-input" :disabled="state" placeholder="请选择领域" v-model="formInfoGuide.categorie01" @change="get_category(2)">
                  <option value=0>请选择领域</option>
                  <option :value="item.code" v-for="item in category01_dict" :key="item.code">{{ item.title }}</option>
                </select>
              </div>
            </div>
            <div class="form-col">
              <div class="form-group">
                <label class="required" for="courseTitle">专业类</label>
                <select name="status" class="form-input" :disabled="state" placeholder="请选择专业类" @change="get_category(3)" v-model="formInfoGuide.categorie02">
                  <option value=0>请选择专业类</option>
                  <option :value="item.code" v-for="item in category02_dict" :key="item.code">{{ item.title }}</option>
                </select>
              </div>
            </div>
            <div class="form-col">
              <div class="form-group">
                <label class="required" for="courseTitle">适用专业</label>
                <select name="status" class="form-input" :disabled="state" placeholder="请选择适用专业" v-model="formInfoGuide.categorie03">
                  <option value=0>请选择适用专业</option>
                  <option :value="item.code" v-for="item in category03_dict" :key="item.code">{{ item.title }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-row" id="numBox">
            <div class="form-col">
              <div class="form-group">
                <label class="required" for="courseTitle">课程类型</label>
                <select name="status" class="form-input" :disabled="state" placeholder="请选择课程类型" v-model="formInfoGuide.courseType">
                  <option value="">请选择课程类型</option>
                  <option value="1">专业基础</option>
                  <option value="2">公共基础</option>
                  <option value="3">专业</option>
                </select>
              </div>
            </div>
            <div class="form-col">
              <div class="form-group">
                <label class="required" for="courseTitle">实验类型</label>
                <select name="status" class="form-input" :disabled="state" placeholder="请选择实验类型" v-model="formInfoGuide.experimentType">
                  <option value="">请选择实验类型</option>
                  <option value="1">综合</option>
                  <option value="2">验证</option>
                </select>
              </div>
            </div>
            <div class="form-col">
              <div class="form-group">
                <label class="required" for="courseTitle">建议学时</label>
                <select name="status" class="form-input" :disabled="state" placeholder="请选择建议学时" v-model="formInfoGuide.hours">
                  <option value="">请选择建议学时</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-row" id="numBox">
            <div class="form-col">
              <div class="form-group">
                <label class="required" for="courseTitle">实验项目方向</label>
                <textarea type="text" v-model="formInfoGuide.direction" rows="3" class="form-textarea" id="direction" :disabled="state" placeholder="请输入实验项目方向"></textarea>
              </div>
            </div>
          </div>
          <div class="form-row" id="numBox">
            <div class="form-col">
              <div class="form-group">
                <label class="required" for="courseTitle">必要性</label>
                <textarea type="text" v-model="formInfoGuide.necessity" rows="3" class="form-textarea" id="direction" :disabled="state" placeholder="请输入必要性"></textarea>
              </div>
            </div>
          </div>
          <div class="form-row" id="numBox">
            <div class="form-col">
              <div class="form-group">
                <label class="required" for="courseTitle">内容简介</label>
                <textarea type="text" v-model="formInfoGuide.brief" rows="3" class="form-textarea" id="direction" :disabled="state" placeholder="请输入内容简介"></textarea>
              </div>
            </div>
          </div>
          <div class="form-row" id="numBox">
            <div class="form-col">
              <div class="form-group">
                <label for="courseTitle">建议实验项目</label>
                <textarea type="text" v-model="formInfoGuide.suggest" rows="3" class="form-textarea" id="direction" :disabled="state" placeholder="请输入建议实验项目"></textarea>
              </div>
            </div>
          </div>
          <div class="form-row" id="numBox">
            <div class="form-col">
              <div class="form-group">
                <label for="courseTitle">所属单位</label>
                <input type="text" v-model="formInfoGuide.org_name" :disabled="state" class="form-input" id="courseTitle" placeholder="请输入所属单位"/>
              </div>
            </div>
          </div>
          <div class="form-row" id="numBox">
            <div class="form-col">
              <div class="form-group">
                <label for="courseTitle">备注</label>
                <textarea type="text" v-model="formInfoGuide.remarks" rows="3" class="form-textarea" id="direction" :disabled="state" placeholder="请输入备注"></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
        <div class="form_control">
          <a href="javascript:;" style="margin: 0 10px;" class="button" id="form_submit" @click="saveInfo(1)">保存并提交</a>
          <a href="javascript:;" style="margin: 0 10px;" class="button" id="form_submit" @click="saveInfo(2)">保存</a>
          <a href="javascript:;" style="margin: 0 10px;" class="button close" id="form_close" @click="hideAddWindow">取消</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../../components/Pagination";
export default {
  name: "ConstructionGuide",
  components: {
    Pagination
  },
  data() {
    return {
      state:false,
      infoModel: null,
      category_dict:{},
      viewList:1,
      onClick:0,
      publishGuieArr:[],
      majorGuieArr:[],
      tableData: [],
      tableDataCount: 0,
      listTableDataCount: 0,
      queryInfo: {
        page: 1,
        size: 10,
        categorie01: '',
        categorie02: '',
        categorie03: '',
        categorie03_dis:'',
        direction: '',
        courseTitle: '',
        status:'5'
      },
      guideListInfo:{
        page: 1,
        size: 10,
        code:''
      },
      guideInfo:{},
      classShowDetailWindow:{},
      classShowAddWindow: {},
      formInfo:{
        id:0,
        created:'',
        courseTitle:'',
        categorie01:0,
        categorie02:0,
        categorie03:0,
        courseType:'',
        experimentType:'',
        hours:'',
        direction:'',
        necessity:'',
        brief:'',
        suggest:'',
        remarks:'',
        owner:'',
        ownerId:''
      },
      formInfoGuide:{
        id:0,
        created:'',
        courseTitle:'',
        categorie01:0,
        categorie02:0,
        categorie03:0,
        courseType:'',
        experimentType:'',
        hours:'',
        direction:'',
        necessity:'',
        brief:'',
        suggest:'',
        remarks:'',
        org_name:'',
        owner:'',
        ownerId:''
      },
      category01_dict: [],
      category02_dict: [],
      category03_dict: [],
    }
  },
  computed: {
   pageInfo() {

      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }

    },
    listPageInfo() {
      return {
        page: this.guideListInfo.page,
        all: Math.ceil(this.listTableDataCount / this.guideListInfo.size),
        totalData: this.listTableDataCount
      }

    },

    localUser(){
      return this.$store.state.userInfo
    }
  },
  methods: {
    async getInfo(id){
      const url = '/allowany/info/' + id + '/'
      await this.$http.get(url).then((data) => {
        this.infoModel = data
      }).catch((error) => {
        console.log(error)
      })
    },
    getCourseType(code){
      if(code==1){
        return '专业基础'
      }else if(code==2){
        return '公共基础'
      }else if(code==3){
        return '专业'
      }
    },
    getExperimentType(code){
      if(code==1){
        return '综合'
      }else if(code==2){
        return '验证'
      }
    },
    toggleList(item){
      this.viewList=2
      this.guideInfo=item
      this.queryInfo.categorie02=item.code
      this.search()
    },
    backPrev(){
      this.queryInfo.categorie03_dis=''
      this.queryInfo.direction=''
      this.queryInfo.courseTitle=''
      this.viewList=1
    },
    num_format(category, id) {
      let res = ''
      res += category
      for(let i=0;i<5-id.toString().length;i++){
        res += '0'
      }
      res += id.toString()
      return res
    },
    async get_categorySigle(){
      await this.$http.get('/allowany/categories/',{
        params:{
          page:1,
          size:1000,
        }
      }).then(async (data) => {
        let temp_dict = {}
        for(let i=0;i<data.results.length;i++){
          temp_dict[data.results[i]['code']] = data.results[i]['title']
        }
        this.category_dict = temp_dict

      })
    },
    // 获取列表数据
    async search() {
      await this.$http.get('/allowany/guide/', {
        params: this.queryInfo
      }).then((data) => {
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        // this.$parent.$parent.showMsg('获取指南信息失败！')
      })
    },
    // 获取列表数据
    async getGuideData() {
      await this.$http.get('/statistics/', {
        params: {
          status:5
        }
      }).then((data) => {
          this.publishGuieArr=data.data
          this.guideListInfo.code=this.publishGuieArr[0].code
          this.getStatisticData(this.publishGuieArr[0].code)
      }).catch(() => {
        // this.$parent.$parent.showMsg('获取指南信息失败！')
      })
    },
    async getStatisticData(code){
      this.viewList=1
      this.queryInfo.categorie03_dis=''
      this.queryInfo.direction=''
      this.queryInfo.courseTitle=''
      await this.$http.get('/statistics_subpage/', {
        params: {
          status:5,
          code:code?code:this.guideListInfo.code,
          page:this.guideListInfo.page,
          size:this.guideListInfo.size
        }
      }).then((data) => {
        this.majorGuieArr = data.results
        this.listTableDataCount=data.count
      }).catch(() => {
        // this.$parent.$parent.showMsg('获取指南信息失败！')
      })
    },
    async pageCallback(data) {
      this.queryInfo.page = data
      await this.search()
    },
    async listPageCallback(data) {
      this.guideListInfo.page = data
      await this.getStatisticData()
    },
    // 查子级类
    async get_category(level,type){
      let params = {}
      if(level == 1){
        params = {
          grade: 1,
          status: 1,
          isdel: 0
        }
      }else if(level == 2){
        params = {
          grade: 2,
          status: 1,
          isdel: 0,
          parent: this.formInfoGuide.categorie01
        }
      }else if(level == 3){
        params = {
          grade: 3,
          status: 1,
          isdel: 0,
          parent2: this.formInfoGuide.categorie02
        }
      }

      await this.$http.get('/allowany/categories/', {
        params: params
      }).then(async (data) => {
        if(level == 1){
          this.category01_dict = data.results
        }else if(level == 2){
          this.category02_dict = data.results
          if(type){
            console.log()
          } else{
            this.category03_dict=[]
            this.formInfo.categorie02 = 0
            this.formInfo.categorie03 = 0
          }
        }else if(level == 3){
          this.category03_dict = data.results
          if(type){
            console.log()
          } else{
            this.formInfo.categorie03 = 0
          }
        }
      })
    },
    async showDetailWindow(id){
      const url = '/allowany/guide/' + id + '/'
      await this.$http.get(url).then((data) => {
        this.formInfo = {
          id: id,
          created: data.created.substr(0, 10),
          courseTitle: data.courseTitle,
          categorie01: data.categorie01,
          categorie02: data.categorie02,
          categorie03: data.categorie03,
          courseType: data.courseType,
          experimentType: data.experimentType,
          hours: data.hours,
          direction: data.direction,
          necessity: data.necessity,
          brief: data.brief,
          suggest: data.suggest,
          remarks: data.remarks,
        }
      }).catch(() => {
        this.$parent.$parent.showMsg('数据获取失败！');
      })
      this.classShowDetailWindow = {visibility: 'visible'}
    },
    // 保存
    async saveInfo(type) {
      if(!this.formInfoGuide.courseTitle){
        this.$parent.$parent.showMsg("请输入课程名称!")
        return false
      } else if(this.formInfoGuide.categorie01==0){
        this.$parent.$parent.showMsg("请选择领域!")
        return false
      } else if(this.formInfoGuide.categorie02==0){
        this.$parent.$parent.showMsg("请选择专业类!")
        return false
      } else if(this.formInfoGuide.categorie03==0){
        this.$parent.$parent.showMsg("请选择适用专业!")
        return false
      } else if(!this.formInfoGuide.courseType){
        this.$parent.$parent.showMsg("请选择课程类型!")
        return false
      } else if(!this.formInfoGuide.experimentType){
        this.$parent.$parent.showMsg("请选择实验类型!")
        return false
      } else if(!this.formInfoGuide.hours){
        this.$parent.$parent.showMsg("请选择建议学时!")
        return false
      } else if(!this.formInfoGuide.direction){
        this.$parent.$parent.showMsg("请输入实验项目方向!")
        return false
      } else if(!this.formInfoGuide.necessity){
        this.$parent.$parent.showMsg("请输入必要性!")
        return false
      } else if(!this.formInfoGuide.brief){
        this.$parent.$parent.showMsg("请输入内容简介!")
        return false
      }
      if(type == 1){
        this.formInfoGuide.status = 1
      } else if(type == 2){
        this.formInfoGuide.status = 0
      }
      this.formInfoGuide.ownerId = this.localUser.user_id
      this.formInfoGuide.owner = this.localUser.name
      await this.$http.post('/guide/', this.formInfoGuide).then((data) => {
        if (data.id) {
          this.$parent.showMsg('添加成功！')
          this.hideAddWindow()
        } else {
          this.$parent.showMsg('添加失败！')
        }
      }).catch((error) => {
        console.log(error)
        this.$parent.showMsg('添加失败！')
      })
    },
    hideDetailWindow() {
      this.classShowDetailWindow = {}
    },
    showAddWindow() {
      if(!this.localUser.user_id){
        this.$parent.showMsg("请登录!")
        return false
      }
      this.classShowAddWindow = {
        visibility: 'visible'
      }
    },
    hideAddWindow() {
      this.classShowAddWindow = {}
      this.formInfoGuide={
        id:0,
        created:'',
        courseTitle:'',
        categorie01:0,
        categorie02:0,
        categorie03:0,
        courseType:'',
        experimentType:'',
        hours:'',
        direction:'',
        necessity:'',
        brief:'',
        suggest:'',
        remarks:'',
        org_name:'',
        owner:'',
        ownerId:''
      }
    },
  },
  mounted() {
    this.get_categorySigle()
    this.get_category(1,true)
    this.getGuideData()
    this.getInfo(77)
  }

}
</script>

<style lang="less" scoped>
.tip-info{
  min-height:498px;
  margin-top:20px;
  padding:20px;
  background: #fff;
  position: relative;
  .solicitationBtn{
    cursor: pointer;
    position: absolute;
    text-align: center;
    right: 20px;
    top: 20px;
    width: 120px;
    height: 35px;
    color: #ffffff;
    font-size: 20px;
    background: #1F45A8;
  }
  h2,h4{
    text-align: center;
  }
  .info{
    display: block;
    text-align: center;
    font-size:14px;
    color:#999;
    margin:20px 0;

  }
  &>div{
    p{
      text-indent: 2em;
      text-align: justify;
      line-height:1.5;
      font-size:18px;
      color:#333;
      margin-bottom:20px;
      img{
        display: block;
        margin:0 auto;
        width:500px;
        text-align: center;
      }
    }
  }
}
.list-table{
  min-height:298px;
  background:#fff;
  margin-top:20px;
  padding: 20px;
  text-align: center;
  .titleName {
    font-size: 18px;
    text-align: center;
  }
  .status-num-list{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    li{
      padding: 10px 5px;
      width: 101px;
      background: #33CC99;
      color: #ffffff;
    }
  }
  .m-t-md{
    margin-top:10px;
    overflow: hidden;
    .tabList{
      overflow: hidden;
      li{
        cursor: pointer;
        float: left;
        height: 36px;
        line-height: 36px;
        padding: 0 15px;
        background: #797979;
        color: #fff;
        margin-right: 5px;
      }
      .onActive{
        background: #4976e8;
      }
    }
    .table-wrap{
      float: left;
      width: 460px;
      table {
        border-collapse: collapse;
        text-align: center;
        background: #fff;
        width: 100%;
        border-left: 1px solid #D7D7D7;
        border-top: 1px solid #D7D7D7;
        th {
          padding: 10px 5px;
          background: #D0E9F5;
          border-right: 1px solid #D7D7D7;
          border-bottom: 1px solid #D7D7D7;
          color: #333;
          font-weight: normal;
          line-height: 20px;
        }
        td {
          line-height: 20px;
          padding: 10px 5px;
          border-right: 1px solid #D7D7D7;
          border-bottom: 1px solid #D7D7D7;
          color: #959595;
          &:last-child{
            color:#4976e8;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
    .chart-wrap{
      float: right;
      width: 370px;
      height: 260px;
    }
  }
}
.status-num-wrap:last-child{
  margin-bottom: 0;
  padding-bottom: 50px;
}
hr{
  margin:10px 0;
}
.guide-info{
  float: left;
  display: inline;
  text-align: left;
  font-size:14px;
  background:rgba(51,204,204,0.24);
  border-radius: 20px;
  padding:5px 10px;
  margin-bottom:10px;
  box-sizing: border-box;
  overflow: hidden;
  span{
    margin-right:30px;
  }
}
.search{
  clear: both;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  input{
    width:150px;
    height:30px;
    margin-right:10px;
    padding-left:5px;
    outline: none;
    border:1px solid #ccc;
    box-sizing: border-box;
  }
  button{
    background:#169bd5;
    border:1px solid #169bd5;
    color:#fff;
    height:30px;
    font-size:14px;
    padding:0 20px;
    box-sizing: border-box;
  }
}
.noInfo{
  margin-top:30px;
  font-size:14px;
  color:#999
}
.form_panel{
  max-width: 1100px;
  width: 1100px;
  .form_close{
    cursor: pointer;
    width: 58px;
    height: 33px;
    line-height: 33px;
    text-align: center;
    position: absolute;
    right: 40px;
    top: 15px;
    background-color: #A1A1A1;
    color: #ffffff;
  }
  .form_content{
    padding: 20px  30px;
  }
  .form-row{
    display: flex;
    margin-bottom: 20px;
    .form-col{
      .form-group{
        display: flex;
        min-width: 360px;
        .redlabel::before{
          content: '*';
          color: red;
          margin-right: 5px;
        }
      }
      span{
        min-width: 120px;
      }
    }
  }
}
.form_acitve .form-row .form-col .form-group{
  display: block;
  min-width: 190px !important;
}
.content-form {
  width: 100%;
  padding-top: 20px;
  box-sizing: border-box;
  .form-row {
    width: 100%;
    display: table;
    .form-col {
      display: table-cell;
      padding: 0 20px;
      .form-group {
        margin-bottom: 15px;
        min-width: 200px;
        label {
          display: inline-block;
          margin-bottom: 12px;
          color: #989898;
        }
        .form-input.plaintext, .form-select.plaintext, .form-textarea.plaintext {
          display: block;
          width: 100%;
          background-color: transparent;
          border: solid transparent;
          border-width: 1px 0;
          outline: none;
          padding: 0;
          color: #757575;
          pointer-events: none;
        }
        .form-input {
          display: block;
          width: 100%;
          height: 32px;
          padding: 0 10px;
          font-size: 14px;
          font-weight: 400;
          line-height: 32px;
          background-color: #f5f7fc;
          border: 1px solid #e6e6e6;
          border-radius: 2px;
          box-sizing: border-box;
        }
        .form-textarea {
          height: auto;
          display: block;
          width: 100%;
          padding: 0 10px;
          font-size: 14px;
          font-weight: 400;
          line-height: 32px;
          background-color: #f5f7fc;
          border: 1px solid #e6e6e6;
          border-radius: 2px;
          box-sizing: border-box;
          font-family: inherit;
        }
        .required:before {
          content: '*';
          color: red;
          display: inline;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
