<template>
  <div id="panel" class="panel" style="min-height: 696px;">
    <form id="searchSection" class="search-form" onsubmit="return false;">
      <input type="text" name="title" class="s-input separate" placeholder="实验项目方向" v-model="queryInfo.direction">
      <input type="text" name="title" class="s-input separate" placeholder="课程名称" v-model="queryInfo.courseTitle">
      <select class="s-select separate" name="categorie01" :disabled="$store.state.userInfo.role==6 || $store.state.userInfo.role==7" v-model="queryInfo.categorie01" @change="getCategory">
        <option value="">请选择领域</option>
        <option v-for="item in dictDomain" :key="item.id" :value="item.code">{{ item.title }}</option>
      </select>
      <select class="s-select separate" name="categorie02" :disabled="$store.state.userInfo.role==7" v-model="queryInfo.categorie02" @change="getMajor">
        <option value="">请选择专业类</option>
        <option v-for="item in dictCategory" :key="item.id" :value="item.code">{{ item.title }}</option>
      </select>
      <select name="categorie03" class="s-select separate" v-model="queryInfo.categorie03">
        <option value="">请选择适用专业</option>
        <option v-for="item in dictMajor" :key="item.id" :value="item.code">{{ item.title }}</option>
      </select>
      <input type="button" class="s-btn" value="搜索" @click="search">
    </form>
    <hr>
    <ul class="toggle-button">
      <li  v-for="item in stateArry" :key="item.status" :class="actived==item.code?'active':''" @click="changeStatus(item.code)">
        <div>
          <span>{{item.state}}</span>
          <span>({{getNum(item.code)}})</span>
        </div>
      </li>
    </ul>
    <div class="buttons">
      <button class="export"  @click="exportMember">导出</button>
      <button class="publish" v-if="actived==0" @click="allSubmit(1)">全部提交</button>
      <button class="publish" v-if="actived==1" @click="allSubmit(3)">全部推送</button>
      <button class="publish" v-if="actived==3" @click="allSubmit(5)">全部发布</button>
      <button @click="toDetail">切换详情模式</button>
    </div>
    <div id="list">
      <table v-if="this.tableData.length > 0">
        <tbody>
        <tr class="l_header">
          <!-- <th width="45px"><a href="javascript:;" class="a-link" @click="ckAll">全选</a></th> -->
          <th class="itemStatus">编号</th>
          <th class="itemStatus">课程名称</th>
          <th class="itemStatus">实验项目方向</th>
          <th class="itemStatus">领域</th>
          <th class="itemStatus">专业类</th>
          <th class="itemStatus">适用专业</th>
          <th class="itemStatus">创建人</th>
          <th class="itemStatus">所属单位</th>
          <th class="itemStatus">创建时间</th>
          <th class="itemStatus" v-if="actived==4 || actived==5">审核人</th>
          <th class="itemStatus">状态</th>
          <th class="action">操作</th>
        </tr>
        <tr class="item"  v-for="item in tableData" :key="item.id">
          <!-- <td><input type="checkbox" name="chose" :value="item.id" v-model="ckUser" @change="ckChange"></td> -->
          <td class="itemStatus">{{num_format(item.categorie02,item.id)}}</td>
          <td class="itemStatus">{{item.courseTitle}}</td>
          <td class="itemStatus">{{item.direction}}</td>
          <td class="itemStatus">{{item.categorie01 in category_dict ? category_dict[item.categorie01]:''}}</td>
          <td class="itemStatus">{{item.categorie02 in category_dict ? category_dict[item.categorie02]:''}}</td>
          <td class="itemStatus">{{item.categorie03 in category_dict ? category_dict[item.categorie03]:''}}</td>
          <td class="itemStatus">{{item.owner}}</td>
          <td class="itemStatus">{{item.org_name}}</td>
          <td class="itemStatus">{{item.created? item.created.substr(0, 10) : ''}}</td>
          <td class="itemStatus" v-if="actived==4 || actived==5">{{item.auditUserName}}</td>
          <td class="itemStatus" :class="`color${item.status}`">{{getStatusTxt(item.status)}}</td>
          <td class="action">
            <router-link class="see" :to="'/admin_guid_edit?id=' + item.id +'&state=1'">查看</router-link>
            <router-link  v-if="actived != 2 && actived != 4" class="edit" :to="'/admin_guid_edit?id=' + item.id +'&state=2'">编辑</router-link>
            <a v-if="actived==0 ||actived==1 || actived==5" class="delete" href="javascript:;" @click="closeGuid(item.id)">关闭</a>
            <a v-if="actived==2" class="modify" href="javascript:;" @click="openGuid(item.id)">开启</a>
            <a v-if="actived==3" class="delete" href="javascript:;" @click="noPass(item.id)">设为不通过</a>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="noitems" v-if="tableData.length == 0">没有数据</div>
    </div>
    

    <pagination :cur="pageInfo.page" :all="pageInfo.all" :totalData="pageInfo.totalData"
                :callback="pageCallback"></pagination>
    <div id="dialog_overlay" :style="classShowProcessWindow">
      <div id="dialog_bg"></div>
      <div id="dialog_panel" ref="refErrorList" style="margin-left: -136px; margin-top: -54px;max-width: 300px;">
        <div id="dialog_panel_bg"></div>
        <div id="dialog_title">提示</div>
        <div id="dialog_content">
          ({{ curCsvIndex }} / {{ uploadedCscData.length }})
          <span style="color: #219d2b">
            {{ ' ' + csvFinishText }}
          </span>
        </div>
        <div id="dialog_content2">
          <ul>
            <li v-for="(item, index) in errorList" v-bind:key="index">
              <span :class="item.code == 1 ? 'success' : 'fail'">{{ item.text }}</span>
            </li>
          </ul>

        </div>
        <div id="dialog_control">
          <a href="javascript:void(0)" id="dialog_ok" class="button col2" @click="classShowProcessWindow={}"
            :style="isFinishUpsert ? 'display: inline-block' : 'display:none'">确认</a>
        </div>
      </div>
    </div>
    <div class="form_overlay" id="applyReject_overlay" :style="classApplyRejectShow">
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">审核</div>
        <div class="form_content">
          <form id="applyRejectForm">
            <div class="item">
              <span class="label"><i class="require"></i>审核意见</span>
              <textarea class="" type="text" name="feedback" v-model="feedbackForm.feedback"
                        style="width: 350px;height: 144px;resize: none;"></textarea>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a href="javascript:;" class="button close" id="applyReject_close"
             @click="classApplyRejectShow = { visibility: 'hidden' }">取消</a>
          <a href="javascript:;" class="button" id="applyReject_submit" @click="refusePass">提交</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import outputTable from "../../assets/basic/outputTable.js"
import Pagination from "../../components/Pagination";

export default {
  name: "AdminGuid",
  components: {
    Pagination
  },
  data() {
    return {
      ckUser:[],
      classShowProcessWindow: {},
      classApplyRejectShow: { visibility: 'hidden' },
      category_dict:{},
      dictDomain: [],
      dictCategory: [],
      dictMajor: [],
      actived:'0',
      stateArry:[
        { state:'建设中',code:0 },
        { state:'待审核',code:1 },
        { state:'待评审',code:3 },
        { state:'已发布',code:5 },
        { state:'已关闭',code:2 },
        { state:'评审未通过',code:4 },
      ],
      feedbackForm:{
        id:'',
        feedback:''
      },
      toggleBtn:[],
      queryInfo: {
        page: 1,
        size: 10,
        categorie01: '',
        categorie02: '',
        categorie03: '',
        direction: '',
        courseTitle: '',
        status:0
      },
      formInfo: {
        id: 0,
        position: '',
        title: '',
        link: '',
        imgPath: ''
      },
      tableData: [],
      tableDataCount: 0,
      uploadedCscData: [],
      isFinishUpsert: false,
      curCsvIndex: 0,
      csvFinishText: '',
      errorList: [],
      exportArray:[
        {
          courseTitle:'单位名称',
          direction:'院系名称',
          contacts:'实验项目方向',
          categorie01:'领域',
          categorie02:'专业类',
          categorie03:'适用专业',
          owner:'创建人',
          created:'创建时间',
          status:'状态'
        }
      ],
    }
  },
  computed: {
    pageInfo() {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    }
  },
  methods: {
    // 导出查询列表
    async exportMember() {
      await this.$http.get('/guide/', {
        params: {
          page:1,
          size:100000,
          // status:3,
          direction:this.queryInfo.direction,
          courseTitle:this.queryInfo.courseTitle,
          categorie01:this.queryInfo.categorie01,
          categorie02:this.queryInfo.categorie02,
          categorie03:this.queryInfo.categorie03,
        }
      }).then((data) => {
        if(data.results.length==0){
          this.$parent.$parent.showMsg('暂无信息可导出！')
          return false
        } else{
          data.results.forEach(item => {
            console.log(item)
            this.exportArray.push({
              courseTitle:item.courseTitle,
              direction:item.direction,
              contacts:item.courseTitle,
              categorie01:item.categorie01 in this.category_dict ? this.category_dict[item.categorie01]:'-',
              categorie02:item.categorie02 in this.category_dict ? this.category_dict[item.categorie02]:'-',
              categorie03:item.categorie03 in this.category_dict ? this.category_dict[item.categorie03]:'-',
              owner:item.owner,
              created:item.created? item.created.substr(0, 10) : '-',
              status:this.getStatusTxt(item.status),

            })
          });
          this.$parent.$parent.showMsg('导出成功')
          this.exportCsv()
        }
      }).catch(() => {
        this.$parent.$parent.showMsg('获取会员信息失败！')
      })
    },
    // 导出
    exportCsv () {
      var fileName='指南管理列表.csv'
      outputTable(this.exportArray,fileName)
      this.exportArray = [
        {
          courseTitle:'单位名称',
          direction:'院系名称',
          contacts:'实验项目方向',
          categorie01:'领域',
          categorie02:'专业类',
          categorie03:'适用专业',
          owner:'创建人',
          created:'创建时间',
          status:'状态'
        }
      ]
    },
    // 全选
    ckAll() {
      if (this.ckUser.length == this.tableData.length) {
        this.ckUser = []
      } else {
        for (let i = 0; i < this.tableData.length; i++) {
          this.ckUser.push(this.tableData[i].id)
        }
      }
    },
    ckChange() {
      console.log(this.ckUser)
    },
    // 显示审核意见窗口
    noPass(id){
      this.feedbackForm = {
        id: id,
        feedback: '',
      }
      this.classApplyRejectShow = {
        visibility: 'visible'
      }
      
    },
    // 设为不通过
    async refusePass(){
      let url='/guide/'+this.feedbackForm.id+'/'
      await this.$http.put(url,{
        status:4,
        feedback:this.feedbackForm.feedback
      }).then(() => {
        this.classApplyRejectShow = {
          visibility: 'hidden'
        }
        this.getGuideStatus()
        this.search()
      }).catch(() => {
        this.$parent.$parent.showMsg('获取指南信息失败！')
      })
      
    },
    // 全部提交
    allSubmit(value){
      if(this.ckUser.length == 0){
        if(value==1){
          this.$parent.$parent.showMsg('提交失败！')
          return
        }else if(value==3){
          this.$parent.$parent.showMsg('推送失败！')
          return
        }else if(value==5){
          this.$parent.$parent.showMsg('发布失败！')
          return
        }
      }
      this.$parent.$parent.showConfirm('确定要提交吗？', () => {
        this.ckUser.forEach(async element => {
          console.log(element)
          let url = '/guide/' + element + '/'
          await this.$http.put(url, {
            status:value,
            auditUserId:this.$store.state.userInfo.user_id,
            auditUserName:this.$store.state.userInfo.name
          }).then((data) => {
            if (data.id) {
              this.$parent.$parent.showMsg('提交成功！')
              this.ckUser = []
              this.getGuideStatus()
              this.search()
            } else {
              this.$parent.$parent.showMsg('提交失败！')
            }
          }).catch((error) => {
            console.log(error)
            this.$parent.$parent.showMsg('提交失败！')
          })
        });
      });
    },
    
    // 关闭
    async closeGuid(id){
      let url='/guide/'+id+'/'
      await this.$http.put(url,{
        status:2
      }).then(() => {
        this.getGuideStatus()
        this.search()
      }).catch(() => {
        this.$parent.$parent.showMsg('获取指南信息失败！')
      })
      
    },
    // 开启
    async openGuid(id){
      let url='/guide/'+id+'/'
      await this.$http.put(url,{
        status:1
      }).then(() => {
        this.getGuideStatus()
        this.search()
      }).catch(() => {
        this.$parent.$parent.showMsg('获取指南信息失败！')
        
      })
    },
    toDetail() {
      window.location.href = '/admin_guid_detail?status=' + this.actived + '&type=2'
    },
    getStatusTxt(code){
      if(code==0){
        return '建设中'
      }else if(code==1){
        return '待审核'
      }else if(code==3){
        return '待评审'
      }else if(code==5){
        return '已发布'
      }else if(code==2){
        return '已关闭'
      }else if(code==4){
        return '评审未通过'
      }
    },
    getNum(code){
      var num = 0
      this.toggleBtn.forEach(item => {
        if(code == item.status){
          num = item.status_sum
        }
      })
      return num
    },
    changeStatus(status){
      this.actived=status
      this.queryInfo.status=status
      this.search()
    },
    num_format(category, id) {
      let res = ''
      res += category
      for(let i=0;i<5-id.toString().length;i++){
        res += '0'
      }
      res += id.toString()
      return res
    },
    async getGuideStatus() {
      await this.$http.get('/guide_status/',{
        params:{
          categorie01:this.queryInfo.categorie01,
          categorie02:this.queryInfo.categorie02
        }
      }).then((data) => {
        this.toggleBtn = data.data
      }).catch(() => {
        this.$parent.$parent.showMsg('获取指南信息失败！')
      })
    },
    async get_category(){
      await this.$http.get('/categories/',{
        params:{
          page:1,
          size:1000,
        }
      }).then(async (data) => {
        let temp_dict = {}
        for(let i=0;i<data.results.length;i++){
          temp_dict[data.results[i]['code']] = data.results[i]['title']
        }
        this.category_dict = temp_dict

      })
    },
    async getSearch(){
      let role = parseInt(this.$store.state.userInfo.role)
      if (role == 6 || role == 7) {
        this.queryInfo.categorie01 = null
        this.queryInfo.categorie02 = null

        const url = '/userprofile/' + this.$store.state.userInfo.profile_id + '/'
        await this.$http.get(url).then(async (data) => {
          console.log(data)
          this.getCategory()

          if (role == 6){
            if (data.categorie01) {
              this.queryInfo.categorie01 = data.categorie01
              this.queryInfo.categorie02 = ''
            } else {
              // 领域管理员 未设置领域时 不查询数据
              this.queryInfo.categorie01 = -1
            }
            
          }

          if (role == 7){
            if (data.categorie02) {
              this.queryInfo.categorie01 = data.categorie01
              this.queryInfo.categorie02 = data.categorie02
            } else {
              // 专业类管理员 未设置专业类时 不查询数据
              this.queryInfo.categorie01 = -1
              this.queryInfo.categorie02 = -1
            }
            this.getMajor()
          }

        })
      }
      this.$nextTick(()=>{
        setTimeout(()=>{
          this.search()
          this.getGuideStatus()
        },110)
      })
    },
    // 获取列表数据
    async search() {
      
      await this.$http.get('/guide/', {
        params: this.queryInfo
      }).then((data) => {
        this.tableData = data.results
        this.tableDataCount = data.count
        this.ckUser = []
        this.tableData.forEach(element => {
          this.ckUser.push(element.id)
        });
      }).catch(() => {
        this.$parent.$parent.showMsg('获取指南信息失败！')
      })
    },
    async pageCallback(data) {
      this.queryInfo.page = data
      await this.search()
    },
    // 获取所有领域
    async getDomain() {
      await this.$http.get('/categories/', {
        params: {
          page: 1,
          size: 1000000,
          grade: '1',
          status: 1,
          isdel: 0
        }
      }).then(async (data) => {
        console.log(data)
        this.dictDomain = data.results
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg('获取领域信息失败！')
      })
    },
    // 获取所有专业类信息
    async getCategory() {
      await this.$http.get('/categories/', {
        params: {
          page: 1,
          size: 1000000,
          parent: this.queryInfo.categorie01,
          grade: '2',
          status: 1,
          isdel: 0
        }
      }).then(async (data) => {
        console.log(data)
        this.dictCategory = data.results
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg('获取专业类信息失败！')
      })
    },
    async getMajor() {
      await this.$http.get('/categories/', {
        params: {
          page: 1,
          size: 1000000,
          parent2: this.queryInfo.categorie02,
          grade: '3',
          status: 1,
          isdel: 0
        }
      }).then(async (data) => {
        console.log(data)
        this.dictMajor = data.results
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg('获取专业信息失败！')
      })
    },
  },
  mounted() {
    this.getSearch()
    this.get_category()
    this.getDomain()
    
    
  }
}
</script>

<style lang="less" scoped>
.toggle-button{
  width:100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  li{
    margin-right:10px;
    padding:2px 10px;
    background:#d8d8d8;
    box-sizing: border-box;
    cursor: pointer;
    &>div{
      
      span{
        display:block;
        text-align:center;
        color:#fff;
      }
    }
  }
  .active{
    background:#4976e8;
  }
}
.buttons{
  width:100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button{
    padding:10px 20px;
    border: 1px solid #4976e8;
    background: #4976e8;
    color:#fff;
    margin-left:10px;
    cursor: pointer;
  }
  .export{
    background: #8f8f8f;
    border: 1px solid #8f8f8f;
  }
  .publish{
    border: 1px solid #ffa445;
    background: #ffa445;
  }
}
#list{
  overflow-x:auto;
  table{
    width:1200px;
  }
  td{
    padding: 15px 5px;
    border-right: 1px solid #f0f0f3;
    border-bottom: 1px solid #f0f0f3;
    color: #959595;
    &:last-child{
      width:200px;
    }
  }
  .color0{
    color:#eb6736
  }

  .color1{
    color:#eb6736
  }
  .color2{
    color:#1884d0
  }
  .color3{
    color:#eb6736
  }
  .color4{
    color:#eb6736
  }
  .color5{
    color:#219d2b
  }
}

</style>
