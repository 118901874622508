<template>
  <div
    id="center"
    style="min-height: 552px;"
  >
    <div
      id="subCenter"
      style="min-height: 82vh;background:#fff"
    >
      <div
        id="content"
        class="content "
      >
        <div class="subMenu">
          <a
            href="javascript:;"
            :class="queryInfo.type == 1 ? 'cur' : ''"
            @click="changeType(1)"
          >高校会员</a>
          <a
            href="javascript:;"
            :class="queryInfo.type == 2 ? 'cur' : ''"
            @click="changeType(2)"
          >企业会员</a>
        </div>
        <div class="change-letter">
          <div
            class="change-tab"
            @click="switchList"
          >{{switchIndex?'切换到LOGO显示':'切换到列表显示'}}</div>
          <ul class="letter-list" v-if="switchIndex">
              <li
                v-for="(item,index) in letterList"
                :key="index"
              >
                <a :href="`#${item}`">{{item}}</a>
              </li>
          </ul>
        </div>
        <div
          class="switch-list"
          v-if="switchIndex"
        >
          <ul class="list">
            <li
              v-for="(item,value) in letterObj"
              :key="value"
            >
              <div
                class="letter-title"
                :id="value"
              >{{value}}</div>
              <ul
                v-for="(term,index) in item"
                :key="index"
              >
                <li>
                  <span @click="toggleShow(value,index)">{{term.title}}<i
                      class="iconfont "
                      v-if="term.children && term.children.length>0"
                      :class="term.hide?'icon-jiantouarrow492':'icon-jiantouarrow486'"
                    ></i></span>
                  <ul :style="term.hide?'display:block':'display:none'">
                    <li
                      v-for="(task,index) in term.children"
                      :key="index"
                    >
                      <span>{{task.title}}</span>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>

        </div>
        <div
          class="vipList clearfix"
          v-else
        >
          <ul
            id="list"
            class="clearfix"
          >
            <li
              :class="pageType == 2 ? 'type2' : ''"
              :ref="'vipLi' + item.id"
              v-for="item in tableData"
              :key="item.id"
              @mouseenter="showOrg(item)"
              @mouseleave="hideOrg(item)"
            >
              <img
                v-if="item.logo"
                :src="formatImage(item.logo)"
              >
              <img
                v-if="!item.logo"
                src="../../assets/images/vip_default_logo.png"
              >
              <span :data-id="item.id">{{ item.title }}</span>
              <div
                class="subList"
                :style="college.length>0?formatStyle(item):''"
                style="opacity: 1;"
              >
                <div class="title">本校二级学院会员</div>
                <div class="list_main">
                  <div class="list_img">
                    <div class="school_img">
                      <img
                        v-if="item.logo"
                        :src="formatImage(item.logo)"
                      >
                      <img
                        v-if="!item.logo"
                        src="../../assets/images/vip_default_logo.png"
                      >
                    </div>
                    <span>{{ item.title }}</span>
                  </div>
                  <ul class="list_info">
                    <li
                      v-for="sub in college"
                      :key="sub.id"
                    >
                      {{ sub.title }}
                    </li>
                  </ul>
                </div>
              </div>
              
            </li>
          </ul>
          <a
            href="javascript:"
            class="getMore"
            id="getMore"
            style="display: block !important;"
            v-if="showMore"
            @click="searchMore"
          >查看全部</a>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Vip",
  data() {
    return {
      pageType: 0,
      subListTop:'',
      queryInfo: {
        page: 1,
        size: 18,
        type: 1,
        status: '5',
        isdel: 0,
        isPub: 1,
        ordering: 'created'
      },
      tableData: [],
      tableDataCount: 0,
      subData: [],
      curCompany: {
        title: '',
        logo: '',
        show_mainPrincipal: '',
        show_mobile1: '',
        show_email1: '',
        show_brief: '',
        show_address: ''
      },
      index:0,
      curCompanyShow: {
        visibility: 'hidden'
      },
      curSchoolShow: {
        visibility: 'hidden'
      },
      switchIndex:false,
      letterList:[ 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z' ],
      letterObj:{},
      showCollege:false,
      college:[]
    }
  },
  computed: {
    showMore() {
      return this.tableData.length < this.tableDataCount
    }
  },
  methods: {
    toggleShow(value,index){
      this.letterObj[value][index].hide=!this.letterObj[value][index].hide
      this.$forceUpdate();
    },
    switchList(){
      this.switchIndex=!this.switchIndex
      if(this.switchIndex){
        this.getVipList(this.queryInfo.type)
      }
    },
    async changeType(typeValue) {
      this.pageType = typeValue
      this.queryInfo.page = 1
      this.queryInfo.type = typeValue
      this.getVipList(typeValue)
      if (typeValue == 1) {
        await this.$http.get('/allowany/application/', {
          params: {
            type: 3,
            status: '5',
            isdel: 0,
            isPub: 1,
            page: 1,
            size: 1000000
          }
        }).then((data) => {

          this.subData = data.results
        })
      } else {
        this.subData = []
      }

      await this.$http.get('/allowany/application/', {
        params: this.queryInfo
      }).then((data) => {
        for (let i = 0; i < data.results.length; i++) {
          data.results[i].showTip = false
          data.results[i].curCompanyShow = false
          data.results[i].left = 0
          data.results[i].top = 0
        }
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$layer.msg('获取通知信息失败！')
      })
    },
    async searchMore() {
      // this.queryInfo.page += 1
      await this.$http.get('/allowany/application/', {
        params: {
          page: 1,
          size: 1000,
          type: this.queryInfo.type,
          status: '5',
          isdel: 0,
          isPub: 1,
          ordering: 'created'
        }
      }).then((data) => {
        // for (let i = 0; i < data.results.length; i++) {
        //   this.tableData.push(data.results[i])
        // }
        for (let i = 0; i < data.results.length; i++) {
          data.results[i].showTip = false
          data.results[i].curCompanyShow = false
          data.results[i].left = 0
          data.results[i].top = 0
        }
        this.tableData = data.results
        this.tableDataCount = data.count
      })
    },
    async getVipList(value) {
      this.letterObj={}
      await this.$http.get('/application_pinyin/', {
        params: {
          type:value
        }
      }).then((data) => {
        var newkey = Object.keys(data.data).sort();
        for (var i = 0; i < newkey.length; i++) {//遍历newkey数组
              data.data[newkey[i]].forEach(item=>{
                item.hide=false
              })
              this.letterObj[newkey[i]] = data.data[newkey[i]];//向新创建的对象中按照排好的顺序依次增加键值对
        }
        this.$forceUpdate()
      })
      
    },
 
    formatImage(fileName) {
      return this.$store.state.uploadFileRootUrl + fileName
    },
    formatStyle(item) {
      return item.showTip ?  'display:block;' : ''
    },
    formatCompanyStyle(item) {
      return item.curCompanyShow ?  'visibility:visible' : ''
    },
    showOrg(item) {
      this.college=[]
      if (this.pageType == 1) {
        item.showTip = true
        for (let i = 0; i < this.subData.length; i++) {
          if (this.subData[i].schoolId == item.id) {
            this.college.push(this.subData[i])
          }
        }
      }else{
        item.curCompanyShow = true
      }
    },
    hideOrg(item) {
      if (this.pageType == 1) {
        item.showTip = false
      }else{
        item.curCompanyShow = false
      }
    },
    showCompany(item) {
      if (this.pageType == 1) {
        return
      }
      this.curCompany = {
        title: item.title,
        logo: item.logo,
        mainPrincipal: item.mainPrincipal,
        mobile1: item.mobile1,
        email1: item.email1,
        brief: item.brief,
        address: item.address,
      }
      this.curCompanyShow = {
        visibility: 'visible'
      }
    }
  },
  mounted() {
    this.changeType(1)
    this.getVipList(1)
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/vip.css";
@import "../../assets/css/dialog.css";
@import "../../assets/css/font.css";
@font-face {
  font-family: "iconfont"; /* Project id 2618897 */
  src: url("//at.alicdn.com/t/font_2618897_8eglmohrtlu.woff2?t=1625651551017")
      format("woff2"),
    url("//at.alicdn.com/t/font_2618897_8eglmohrtlu.woff?t=1625651551017")
      format("woff"),
    url("//at.alicdn.com/t/font_2618897_8eglmohrtlu.ttf?t=1625651551017")
      format("truetype");
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-jiantouarrow486:before {
  content: "\e6aa";
}

.icon-jiantouarrow492:before {
  content: "\e6b0";
}
#center {
  width: auto !important;
  padding-bottom:0 !important;
}
.form_content p.vipInfo {
  text-align: left;
}

.vipList ul li img {
  width: 120px;
  height: 120px;
  object-fit: scale-down;
}
.mask-bg {
  width: 950px;
  height: 500px;
  background: rgba(255, 255, 255, 0);
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.form_panel {
  padding: 20px;
  min-width: 700px;
  -webkit-box-shadow: #bbb 0px 0px 15px;
  -moz-box-shadow: #bbb 0px 0px 15px;
  box-shadow: #bbb 0px 0px 15px;
  z-index: 10;
}

.form_title {
  font-weight: normal;
  border-left: 0;
  border-bottom: 1px solid #eee;
  padding: 0 0 10px 0;
  text-align: left;
}
.form_content p.infoTitle {
  text-align: left;
}
.form_content p.brief {
  font-size: 15px;
  line-height: 2.2;
  text-align: justify;
}
.change-tab {
  color: #1f45a9;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
  
}
.switch-list {
  width:1410px;
  height: 450px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: #fbfbfb;
  padding: 20px;
  position: relative;
  box-sizing: border-box;
    overflow:auto;
  .list {
    width: 100%;
    min-height: 770px;
    li {
      margin-bottom: 10px;
      box-sizing: border-box;
      ul {
        li {
          font-size: 16px;
          span {
            display: block;
            padding: 12px 0 12px 20px;
            border-bottom: 1px solid #eee;
          }
          ul {
            span {
              padding: 12px 0 12px 50px;
            }
          }
        }
      }
    }
    .letter-title {
      font-size: 22px;
      background: #eee;
      padding-left: 10px;
      font-weight: bold;
      box-sizing: border-box;
    }
  }
  
}
.letter-list {
    
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left:30px;
    li {
      text-align: center;
      a {
        display: inline-block;
        width: 35px;
        height: 35px;
        line-height: 35px;
        font-size: 18px;
        color: #474747;
        &:hover {
          background: #728fdb;
          color: #fff;
          border-radius: 50%;
        }
      }
    }
  }
  .change-letter{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
  }
</style>
