<template>
  <div id="center">
    <div class="subBanner2"></div>
    <div id="subCenter">
      <div id="content" class="content organization-wrap">
        <div class="subLeft">
          <dl>
            <dt>组织机构
              <p>Organization</p>
            </dt>
<!--            <dd @click="updateMenuSelected" :class="this.menuSelected == '/www/organization/chairman' ? 'cur' : ''">-->
<!--              <router-link to="/www/organization/chairman">联盟理事长</router-link>-->
<!--            </dd>-->
            <dd @click="updateMenuSelected" :class="this.menuSelected == '/www/organization/council' ? 'cur' : ''">
              <router-link to="/www/organization/council">联盟理事会</router-link>
            </dd>
            <dd @click="updateMenuSelected" :class="this.menuSelected == '/www/organization/academic' ? 'cur' : ''">
              <router-link to="/www/organization/academic">学术委员会</router-link>
            </dd>
            <dd @click="updateMenuSelected" :class="this.menuSelected == '/www/organization/secretariat' ? 'cur' : ''">
              <router-link to="/www/organization/secretariat">联盟秘书处</router-link>
            </dd>

            <dd @click="updateMenuSelected" :class="this.menuSelected == '/www/organization/subambit' ? 'cur' : ''">
              <router-link to="/www/organization/subambit">专业领域工作委员会</router-link>
            </dd>
            <dd @click="updateMenuSelected" :class="this.menuSelected == '/www/organization/major' ? 'cur' : ''">
              <router-link to="/www/organization/major">专业工作委员会</router-link>
            </dd>
            <dd @click="updateMenuSelected" :class="this.menuSelected == '/www/organization/technical' ? 'cur' : ''">
              <router-link to="/www/organization/technical">技术工作委员会</router-link>
            </dd>
            <dd @click="updateMenuSelected" class="hide" :class="this.menuSelected == '/www/organization/enterprise' ? 'cur' : ''">
              <router-link to="/www/organization/enterprise">企业工作委员会</router-link>
            </dd>
            <dd @click="updateMenuSelected" :class="this.menuSelected == '/www/organization/gao' ? 'cur' : ''">
              <router-link to="/www/organization/gao">高职领域委员会</router-link>
            </dd>
          </dl>
        </div>
        <router-view></router-view>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "OrganizationLayout",
  data() {
    return {
      menuSelected: ''
    }
  },
  methods: {
    updateMenuSelected(){
      const url = window.location.href
      const arrUrl = url.split('//')
      const start = arrUrl[1].indexOf('/')
      let relUrl = arrUrl[1].substring(start)

      if (relUrl.indexOf('?') !== -1) {
        relUrl = relUrl.split('?')[0]
      }
      relUrl = relUrl.replace('#', '')
      this.menuSelected = relUrl
    },
  },
  mounted() {
    this.updateMenuSelected()
  }
}
</script>

<style scoped>
#center {
  width: auto !important;
}
</style>
