<template>
  <div id="panel" class="panel" style="min-height: 696px;">
    <!-- <img src="../../assets/images/load.jpg" alt=""> -->
    <form id="searchSection" onsubmit="return false;">
      <div class="search-line clearfix">
        <select v-model="queryInfo.type" name="type" class="s-select separate">
          <option value="">选择申请类型</option>
          <option value="1">高校会员</option>
          <option value="3">学院会员</option>
          <option value="2">企业/事业单位会员</option>
        </select>
        <select v-model="queryInfo.isPub" name="status" class="s-select separate">
          <option value="">选择状态</option>
          <option value="0">未发布</option>
          <option value="1">已发布</option>
        </select>
        <input v-model="queryInfo.like_title" type="text" name="title" class="s-input separate" placeholder="单位名称">

        <input type="submit" class="s-btn" value="搜索" @click="search">

        <div class="import-btn btncolor" id="batchUpload" @click="exportMember">导出</div>
        <div class="import-btn" id="batchUpload" @click="showUploadWindow">导入</div>
      </div>

      <div class="search-line clearfix">

      </div>
    </form>
    <div id="list">
      <table v-if="this.tableData.length > 0">
        <tbody>
        <tr class="l_header">
          <th class="itemTitle">单位名称</th>
          <th class="itemTitle">院系名称</th>
          <th class="itemTitle">主要联系人</th>
          <th class="itemTitle">电话</th>
          <th class="itemTitle">申请类型</th>
          <th class="itemCategory">状态</th>
          <th class="action" width="140">操作</th>
        </tr>
        <tr class="item" v-for="item in tableData" :key="item.id">
          <td class="itemTitle">
            <a class="a-link" :href="'/www/appview?id=' + item.id" target="_blank">
              {{ item.type == 3 ? item.schoolTitle : item.title }}
            </a>
          </td>
          <td class="itemTitle">{{ item.type == 3 ? item.title : '-' }}</td>
          <td class="itemTitle">{{ item.contacts }}</td>
          <td class="itemTitle">{{ item.phone2 }}</td>
          <td class="itemTitle" v-if="item.type == 1">高校会员</td>
          <td class="itemTitle" v-else-if="item.type == 2">企业会员</td>
          <td class="itemTitle" v-else-if="item.type == 3">学院会员</td>
          <td class="itemTitle" v-else></td>

          <td v-if="item.isPub == 1" class="itemTitle itemStatus1">已发布</td>
          <td v-if="item.isPub == 0" class="itemTitle itemStatus0">未发布</td>

          <td class="action" style="font-size:12px">
            <a v-if="item.isPub==1" class="nopublish" href="javascript:;" @click="unpublish(item.id)">取消发布</a>
            <a v-if="item.isPub==0" class="publish" href="javascript:;" @click="publish(item.id)">发布</a>

            <a class="edit" href="javascript:;" @click="querySingle(item.id)">编辑</a>
            <a class="delete" href="javascript:;" @click="askDel(item.id)">删除</a>

            <br>

            <a class="other1" href="javascript:;" @click="backOne(item.id)">退回修改</a>

          </td>
        </tr>
        </tbody>
      </table>
      <div class="noitems" v-if="tableData.length == 0">没有数据</div>
    </div>

    <pagination :cur="pageInfo.page" :all="pageInfo.all" :totalData="pageInfo.totalData"
                :callback="pageCallback"></pagination>

    <div class="form_overlay" id="applyReject_overlay">
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">申请驳回</div>
        <div class="form_content">
          <form id="applyRejectForm">
            <div class="item">
              <span class="label"><i class="require"></i>驳回理由</span>
              <input class="" type="text" name="rejectReason">
            </div>
          </form>
        </div>
        <div class="form_control">
          <a href="javascript:;" class="button close" id="applyReject_close">取消</a>
          <a href="javascript:;" class="button" id="applyReject_submit">提交</a>
        </div>
      </div>
    </div>

    <div class="form_overlay" id="form_overlay" :style="classShowEditWindow">
      <div class="form_overlay_bg"></div>
      <div class="form_panel" >
        <div class="form_title">会员管理</div>
        <div class="form_content">
          <form id="itemForm">
            <div class="item">
              <span class="label">会员类型:</span>
              <select name="type" v-model="formInfo.type" @change="formInfoTypeChange">
                <option value="">选择类型</option>
                <option value="1">高校会员</option>
                <option value="3">学院会员</option>
                <option value="2">企业会员</option>
              </select>
            </div>

            <div class="item" v-show="formInfo.type == '3'">
              <span class="label">学校名称:</span>
              <input type="text" name="title" v-model="formInfo.schoolTitle">
            </div>
            <div class="item">
              <span class="label" v-if="formInfo.type != '3'">单位/企业名称:</span>
              <span class="label" v-if="formInfo.type == '3'">学院名称:</span>
              <input type="text" name="title" v-model="formInfo.title">
            </div>
            <div class="item type2" :class="formInfo.type == '2' ? '' : 'hide'">
              <span class="label">企业法人:</span>
              <input type="text" name="legalPerson" v-model="formInfo.legalPerson">
            </div>
            <div class="item type2" :class="formInfo.type == '2' ? '' : 'hide'">
              <span class="label">成立时间:</span>
              <input type="date" name="foundDate" v-model="formInfo.foundDate"
                     style="width: 300px;height: 30px;border: 1px solid #ddd;">
            </div>
            <div class="item type2" :class="formInfo.type == '2' ? '' : 'hide'">
              <span class="label">企业性质:</span>
              <select name="nature" v-model="formInfo.nature">
                <option value="">选择性质</option>
                <option value="1">国有</option>
                <option value="2">私有</option>
                <option value="3">外资</option>
                <option value="4">独资</option>
                <option value="5">合资</option>
              </select>
            </div>
            <div class="item type2" :class="formInfo.type == '2' ? '' : 'hide'">
              <span class="label">注册资金:</span>
              <input type="text" name="registeredCapital" v-model="formInfo.registeredCapital">
            </div>
            <div class="item">
              <span class="label">通讯地址:</span>
              <input type="text" name="address" v-model="formInfo.address">
            </div>
            <div class="item">
              <span class="label">邮编:</span>
              <input type="text" name="postcode" v-model="formInfo.postcode">
            </div>
            <div class="item">
              <span class="label">主管领导:</span>
              <input type="text" name="mainPrincipal" v-model="formInfo.mainPrincipal">
            </div>
            <div class="item">
              <span class="label">职务职称:</span>
              <input type="text" name="mpjobTitle" v-model="formInfo.mpjobTitle">
            </div>
            <div class="item">
              <span class="label">办公电话:</span>
              <input type="text" name="phone1" v-model="formInfo.phone1">
            </div>
            <div class="item">
              <span class="label">手机:</span>
              <input type="text" name="mobile1" v-model="formInfo.mobile1">
            </div>
            <div class="item">
              <span class="label">邮箱:</span>
              <input type="text" name="email1" v-model="formInfo.email1">
            </div>
            <div class="item">
              <span class="label">责任部门:</span>
              <input type="text" name="respDepart" v-model="formInfo.respDepart">
            </div>
            <div class="item">
              <span class="label">负责人:</span>
              <input type="text" name="principal" v-model="formInfo.principal">
            </div>
            <div class="item">
              <span class="label">职务职称:</span>
              <input type="text" name="pjobTitle" v-model="formInfo.pjobTitle">
            </div>
            <div class="item">
              <span class="label">手机:</span>
              <input type="text" name="mobile2" v-model="formInfo.mobile2">
            </div>
            <div class="item">
              <span class="label">邮箱:</span>
              <input type="text" name="email3" v-model="formInfo.email3">
            </div>
            <div class="item">
              <span class="label">主要联系人:</span>
              <input type="text" name="contacts" v-model="formInfo.contacts">
            </div>
            <div class="item">
              <span class="label">电话:</span>
              <input type="text" name="phone2" v-model="formInfo.phone2">
            </div>
            <div class="item">
              <span class="label">邮箱:</span>
              <input type="text" name="email2" v-model="formInfo.email2">
            </div>
            <div class="item">
              <span class="label">建议图片大小:</span>
              <div>300px * 300px</div>
            </div>
            <div class="item">
              <span class="label">LOGO图片:</span>
              <div class="item-box">
                <input name="logoPath" type="text" id="imgurl" disabled="disabled" v-model="formInfo.logo" style="width: 225px;">
                <span class="button" id="uploadImg" @click="showImageWindow" style="left: 0px;top: 0px;">添加图片</span>
              </div>
            </div>
            <div class="item">
              <div id="preview"><img :src="formatImagePath(formInfo.logo)" height="50"></div>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a href="javascript:;" class="button close" id="form_close" @click="hideEditWindow()">取消</a>
          <a href="javascript:;" class="button" id="form_submit" @click="saveInfo">提交</a>
        </div>
      </div>
    </div>

    <div id="dialog_overlay" class="" :style="classShowImageWindow">
      <div id="dialog_bg"></div>
      <div id="dialog_panel" style="margin-left: -137px; margin-top: -84px;" class="">
        <div id="dialog_panel_bg"></div>
        <div id="dialog_title">选择图片</div>
        <div id="dialog_content">
          <div class="imgType" style="margin-bottom:10px;" id="imgType">允许上传的图片格式：jpg</div>
          <div id="thumbPreview" v-show="showImgPreview"><img ref="headImg"></div>
          <div>
            <input id="imageFile" type="file" name="imageFile" accept="image/*"
                   ref="imageFile" @change="imageFileChange">
          </div>
        </div>
        <div id="dialog_control">
          <a href="javascript:void(0)" @click="hideImageWindow" id="dialog_close" class="button"
             style="display: inline-block;">取消</a>
          <a href="javascript:void(0)" @click="confirmImage" id="dialog_ok" class="button"
             style="display: inline-block;">确认</a>
        </div>
      </div>
    </div>

    <div class="form_overlay" :style="classShowUploadWindow">
      <div class="form_overlay_bg"></div>
      <div class="form_panel" >
        <div class="form_title">批量上传</div>
        <div class="form_content">
          <form id="uploadForm">
            <div class="item">
              <span class="label" style="width: auto"><i class="require"></i>模版创建规则:</span>
              <div class="item-box divLabel gray">
                <div class="normal-width">
                  <p>1. 文件格式为“逗号分隔”的CSV文件；</p>
                  <p>2. 文件编码必须为ANSI编码；</p>
                  <p>3. 上传文档转换完毕后，请使用记事本打开，查看是否含有空数据，如果含有空数据，则需删除；</p>
                  <p>4. 上传文档中内容不能含有西文逗号；</p>
                  <p>5. CSV文件生成，可使用excel另存为“逗号分隔的CSV文件”；</p>
                  <p>6. 文件编码，可使用记事本打开CSV，另存为ANSI编码。</p>
                </div>
              </div>
              <div class="clear"></div>
              <div class="attachment">
                <a href="/static/upload_template/batch_upload_member_template.csv" download="batch_upload_member_template.csv" target="_blank">模版下载</a>
              </div>
            </div>
            <div class="item">
              <span class="label" style="width: auto"><i class="require">*</i>CSV文件:</span>
              <div class="item-box" style="margin-left: 130px;">
                <input name="file" v-model="uploadedCsv" disabled type="text" id="fileUrl" style="width: 225px;">
                <span class="button" style="position: relative;top: 0px; left: 0px;">
                  选择文件
                  <input @change="xlsChange" type="file" ref="xlsFile" id="fileUpload" class="upload-derict">
                </span>
              </div>
              <div class="clear"></div>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a href="javascript:;" class="button close" id="form_upload_close" @click="hideUploadWindow">取消</a>
          <a href="javascript:;" class="button" id="form_upload_submit" @click="submitCsv">提交</a>
        </div>
      </div>
    </div>

    <div id="dialog_overlay" :style="classShowProcessWindow">
      <div id="dialog_bg"></div>
      <div id="dialog_panel" ref="refErrorList" style="margin-left: -136px; margin-top: -54px;max-width: 300px;">
        <div id="dialog_panel_bg"></div>
        <div id="dialog_title">提示</div>
        <div id="dialog_content">
          ({{ curCsvIndex }} / {{ uploadedCscData.length }})
          <span style="color: #219d2b">
            {{ ' ' + csvFinishText }}
          </span>
        </div>
        <div id="dialog_content2">
          <ul>
            <li v-for="(item, index) in errorList" v-bind:key="index">
              <span :class="item.code == 1 ? 'success' : 'fail'">{{ item.text }}</span>
            </li>
          </ul>

        </div>
        <div id="dialog_control">
          <a href="javascript:void(0)" id="dialog_ok" class="button col2" @click="classShowProcessWindow={}"
             :style="isFinishUpsert ? 'display: inline-block' : 'display:none'">确认</a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import outputTable from "../../assets/basic/outputTable.js"
import Pagination from "../../components/Pagination"

export default {
  name: 'AdminMember',
  components: {
    Pagination
  },
  data() {
    return {
      queryInfo: {
        page: 1,
        size: 10,
        like_title: '',
        status: '5',
        isPub: '',
        type: '',
        isdel: 0,
      },
      exportParam:{
        page: 1,
        size: 10000,
        status: '5',
        isdel: 0,
      },
      tableData: [],
      tableDataCount: 0,
      exportData:[],
      exportArray:[
        {
          schoolTitle:'单位名称',
          title:'院系名称',
          contacts:'主要联系人',
          phone2:'电话',
          type:'申请类型',
          isPub:'状态'
        }
      ],
      classShowEditWindow: {},
      formInfo: {
        id: 0,
        type: 1,
        title: '',
        legalPerson: '',
        foundDate: '',
        nature: '',
        registeredCapital: '',
        address: '',
        postcode: '',
        mainPrincipal: '',
        mpjobTitle: '',
        phone1: '',
        mobile1: '',
        email1: '',
        respDepart: '',
        principal: '',
        pjobTitle: '',
        mobile2: '',
        email3: '',
        contacts: '',
        phone2: '',
        email2: '',
        logo: '',
        schoolId: '',
        schoolTitle: ''
      },
      classShowImageWindow: {},
      uploaded_img: '',
      showImgPreview: false,
      classShowUploadWindow: {},
      uploadedCsv: '',
      uploadedCscData: [],
      classShowProcessWindow: {},
      errorList: [],
      isFinishUpsert: false,
      curCsvIndex: 0,
      csvFinishText: ''
    }
  },
  computed: {
    pageInfo() {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    }
  },
  methods: {
    formInfoTypeChange (){
      if(this.formInfo.type == 3){
        // 切换为学院会员
        this.formInfo.schoolTitle = this.formInfo.title
        this.formInfo.title = ''

      }else if(this.formInfo.type == 1 || this.formInfo.type == 2){
        // 切换为其他
        if(this.formInfo.schoolTitle != ''){
          this.formInfo.title = this.formInfo.schoolTitle
          this.formInfo.schoolTitle = ''
        }
      }
    },
    async search() {
      await this.$http.get('/application/', {
        params: this.queryInfo
      }).then((data) => {
        console.log(data)
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$parent.$parent.showMsg('获取通知信息失败！')
      })
    },
    // 导出查询列表
    async exportMember() {
      await this.$http.get('/application/', {
        params: this.exportParam
      }).then((data) => {
        this.exportData = data.results
        if(this.exportData.length==0){
          this.$parent.$parent.showMsg('暂无信息可导出！')
          return false
        } else{
          this.exportData.forEach(item => {
            this.exportArray.push({
              schoolTitle:item.type == 3 ? item.schoolTitle : item.title,
              title:item.type == 3 ? item.title : '-',
              contacts:item.contacts,
              phone2:item.phone2,
              type:item.type == 1?'高校会员':item.type == 2?'企业会员':'学院会员',
              isPub:item.isPub == 1?'已发布':'未发布',
            })
          });
          this.$parent.$parent.showMsg('导出成功')
          this.exportCsv()
        }
      }).catch(() => {
        this.$parent.$parent.showMsg('获取会员信息失败！')
      })
    },
    // 导出
    exportCsv () {
      var fileName='会员信息文件.csv'
      outputTable(this.exportArray,fileName)
      this.exportArray = [
        {
          schoolTitle:'单位名称',
          title:'院系名称',
          contacts:'主要联系人',
          phone2:'电话',
          type:'申请类型',
          isPub:'状态'
        }
      ]
    },
    async pageCallback(data) {
      this.queryInfo.page = data
      await this.search()
    },
    async changePublishState(id, isPub) {
      const msg = isPub == 0 ? "取消" : ""
      const url = '/application/' + id + '/'
      await this.$http.put(url, {
        isPub: isPub
      }).then((data) => {
        console.log(data)
        this.$layer.msg(msg + '发布成功！');
        this.search()
      }).catch((error) => {
        console.log(error)
        this.$layer.msg(msg + '发布失败！');
      })
    },
    unpublish(id) {
      this.$parent.$parent.showConfirm('确定要取消发布吗？', () => {
        this.changePublishState(id, 0)
      });
    },
    publish(id) {
      this.$parent.$parent.showConfirm('确定要发布吗？', () => {
        this.changePublishState(id, 1)
      });
    },
    async delRow(id) {
      const url = '/application/' + id + '/'
      await this.$http.put(url, {
        isdel: 1
      }).then((data) => {
        console.log(data)
        this.$layer.msg('删除成功！');
        this.search()
      }).catch((error) => {
        console.log(error)
        this.$layer.msg('删除失败！');
      })
    },
    askDel(id) {
      this.$parent.$parent.showConfirm('确定要删除吗？', () => {
        this.delRow(id)
      });
    },
    showEditWindow() {
      this.classShowEditWindow = {visibility: 'visible'}
    },
    hideEditWindow() {
      this.classShowEditWindow = {}
      this.formInfo = {
        id: 0,
        type: 1,
        title: '',
        legalPerson: '',
        foundDate: '',
        nature: '',
        registeredCapital: '',
        address: '',
        postcode: '',
        mainPrincipal: '',
        mpjobTitle: '',
        phone1: '',
        mobile1: '',
        email1: '',
        respDepart: '',
        principal: '',
        pjobTitle: '',
        mobile2: '',
        email3: '',
        contacts: '',
        phone2: '',
        email2: '',
        logo: ''
      }
    },
    async querySingle(id) {
      const url = '/application/' + id + '/'
      await this.$http.get(url).then((data) => {
        console.log(data)
        this.formInfo = {
          id: data.id,
          type: data.type,
          title: data.title,
          legalPerson: data.legalPerson,
          foundDate: data.foundDate,
          nature: data.nature,
          registeredCapital: data.registeredCapital,
          address: data.address,
          postcode: data.postcode,
          mainPrincipal: data.mainPrincipal,
          mpjobTitle: data.mpjobTitle,
          phone1: data.phone1,
          mobile1: data.mobile1,
          email1: data.email1,
          respDepart: data.respDepart,
          principal: data.principal,
          pjobTitle: data.pjobTitle,
          mobile2: data.mobile2,
          email3: data.email3,
          contacts: data.contacts,
          phone2: data.phone2,
          email2: data.email2,
          logo: data.logo,
          schoolId: data.schoolId,
          schoolTitle: data.schoolTitle
        }
        this.showEditWindow()
      }).catch((error) => {
        console.log(error)
        this.$parent.$parent.showMsg('数据获取失败！');
      })
    },
    formatImagePath(name) {
      return this.$store.state.uploadFileRootUrl + name
    },
    async saveInfo() {

      let has_title = false

      await this.$http.get('/application/', {
        params: {
          eq_title: this.formInfo.title,
          not_id: this.formInfo.id,
          isdel: 0
        }
      }).then(async (data) => {
        if (data.count > 0) {
          has_title = true
        }
      })

      if(has_title){
        this.$parent.$parent.showMsg('【' + this.formInfo.title + '】已存在！')
        return false;
      }

      if(this.formInfo.phone1 && (this.formInfo.phone1.length < 7 || this.formInfo.phone1.length > 12 )){
        this.$parent.$parent.showMsg("办公电话错误")
        return false;
      }

      let mobileReg = /^1[0-9]{10}$/;
      if (this.formInfo.mobile1 && this.formInfo.mobile1.length > 0 && !mobileReg.test(this.formInfo.mobile1)) {
        this.$parent.$parent.showMsg('主管领导手机号码输入错误！')
        return false;
      }
      let emailReg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      if (this.formInfo.email1 && this.formInfo.email1.length > 0 && !emailReg.test(this.formInfo.email1)) {
        this.$parent.$parent.showMsg('主管领导邮箱输入错误！')
        return false;
      }

      if (this.formInfo.mobile2 && this.formInfo.mobile2.length > 0 && !mobileReg.test(this.formInfo.mobile2)) {
        this.$parent.$parent.showMsg('责任部门手机号码输入错误！')
        return false;
      }
      if (this.formInfo.email3 && this.formInfo.email3.length > 0 && !emailReg.test(this.formInfo.email3)) {
        this.$parent.$parent.showMsg('责任部门邮箱输入错误！')
        return false;
      }

      if (this.formInfo.phone2 && this.formInfo.phone2.length > 0 && !mobileReg.test(this.formInfo.phone2)) {
        this.$parent.$parent.showMsg('主要联系人手机号码输入错误！')
        return false;
      }
      if (this.formInfo.email2 && this.formInfo.email2.length > 0 && !emailReg.test(this.formInfo.email2)) {
        this.$parent.$parent.showMsg('主要联系人邮箱输入错误！')
        return false;
      }

      if (this.formInfo.type == 1) {
        this.formInfo.legalPerson = null
        this.formInfo.foundDate = null
        this.formInfo.nature = null
        this.formInfo.registeredCapital = null
        let submitForm = JSON.parse(JSON.stringify(this.formInfo))
        submitForm.schoolTitle = ''
        delete submitForm.schoolId
        let url = '/application/' + this.formInfo.id + '/'
        await this.$http.put(url, submitForm).then(async (data) => {
          if (data.id) {

            // region 修改学院信息
            await this.$http.get('/application/', {
              params: {
                schoolId: data.id
              }
            }).then(async (sub_data) => {
              if (sub_data.count > 0) {
                for(let i=0;i<sub_data.results.length;i++){
                  let url = '/application/' + sub_data.results[i].id + '/'
                  await this.$http.put(url, {
                    schoolTitle: data.title
                  }).then((data) => {
                    console.log(data)
                  })
                }
              }
            })
            // endregion

            this.$parent.$parent.showMsg('修改成功！')
            this.hideEditWindow()
            this.search()
          } else {
            this.$parent.$parent.showMsg('修改失败！')
          }
        }).catch((error) => {
          console.log(error)
          this.$parent.$parent.showMsg('修改失败！')
        })
      } else if (this.formInfo.type == 2) {
        let url = '/application/' + this.formInfo.id + '/'
        let submitForm = JSON.parse(JSON.stringify(this.formInfo))
        submitForm.schoolTitle = ''
        delete submitForm.schoolId
        await this.$http.put(url, submitForm).then((data) => {
          if (data.id) {
            this.$parent.$parent.showMsg('修改成功！')
            this.hideEditWindow()
            this.search()
          } else {
            this.$parent.$parent.showMsg('修改失败！')
          }
        }).catch((error) => {
          console.log(error)
          this.$parent.$parent.showMsg('修改失败！')
        })
      } else if (this.formInfo.type == 3) {

        // region 查询是否存在下属院系

        let subAppCount = 0;

        await this.$http.get('/application/', {
          params: {
            schoolId: this.formInfo.id,
          }
        }).then(async (data) => {
          if (data.count > 0) {
            subAppCount = data.count
          }
        })

        if(subAppCount > 0){
          this.$parent.$parent.showMsg('该高校下存在学院单位，不得修改！')
          return;
        }

        // endregion

        this.formInfo.legalPerson = null
        this.formInfo.foundDate = null
        this.formInfo.nature = null
        this.formInfo.registeredCapital = null

        // region 学校信息保存逻辑

        if (this.formInfo.schoolId) {
          // 已绑定高校
          let schoolUrl = '/application/' + this.formInfo.schoolId + '/'

          let schoolForm = JSON.parse(JSON.stringify(this.formInfo))
          schoolForm.title = this.formInfo.schoolTitle
          schoolForm.type = 1
          schoolForm.schoolTitle = ''
          delete schoolForm.schoolId
          delete schoolForm.id

          await this.$http.put(schoolUrl, schoolForm).then(async (data) => {
            console.log(data)
            if (data.id > 0) {
              console.log('updated school title ')
            }
          })

        } else {
          // 未绑定高校 先查询是否存在高校 不存在则创建 存在则修改

          // 查询是否存在学校
          await this.$http.get('/application/', {
            params: {
              eq_title: this.formInfo.schoolTitle,
              // type: '1',
              // isdel: 0
            }
          }).then(async (data) => {
            console.log('=========')
            console.log(data)
            console.log(this.formInfo.id)
            console.log('=========')
            if (data.count > 0) {

              for(let i=0;i<data.results.length;i++){

                console.log(data.results[i].id != this.formInfo.id)
                console.log(parseInt(data.results[i].id) != parseInt(this.formInfo.id))

                if(data.results[i].id != this.formInfo.id){
                  this.formInfo.schoolId = data.results[i].id
                }
              }
            }
          })

          // 存在同名称高校 修改信息
          if (this.formInfo.schoolId) {
            let schoolUrl = '/application/' + this.formInfo.schoolId + '/'

            let schoolForm = JSON.parse(JSON.stringify(this.formInfo))
            schoolForm.title = this.formInfo.schoolTitle
            schoolForm.type = 1
            schoolForm.schoolTitle = ''
            delete schoolForm.schoolId
            delete schoolForm.id

            await this.$http.put(schoolUrl, schoolForm).then(async (data) => {
              console.log(data)
              if (data.id > 0) {
                console.log('updated school title ')
              }
            })
          } else {
            // 不存在学校 创建

            let schoolForm = JSON.parse(JSON.stringify(this.formInfo))
            schoolForm.title = this.formInfo.schoolTitle
            schoolForm.type = 1
            schoolForm.status = 5
            schoolForm.isPub = 1
            schoolForm.schoolTitle = ''
            delete schoolForm.schoolId

            const maxSortNum = await this.$http.get('/max_sortnum/')
            const postData = JSON.parse(JSON.stringify(schoolForm))
            postData['super_category1_sort_num'] = maxSortNum['max_sn_1'] + 1
            postData['super_category2_sort_num'] = maxSortNum['max_sn_2'] + 1
            postData['super_category3_sort_num'] = maxSortNum['max_sn_3'] + 1

            await this.$http.post('/application/', postData).then(async (data) => {
              if (data.id) {
                this.formInfo.schoolId = data.id
              } else {
                this.$parent.showMsg('学校创建失败！')
              }
            }).catch((error) => {
              console.log(error)
              this.$parent.showMsg('学校创建失败！')
            })
          }
        }

        // endregion

        // region 学院保存逻辑

        let url = '/application/' + this.formInfo.id + '/'
        await this.$http.put(url, this.formInfo).then((data) => {
          if (data.id) {
            this.$parent.$parent.showMsg('修改成功！')
            this.hideEditWindow()
            this.search()
          } else {
            this.$parent.$parent.showMsg('修改失败！')
          }
        }).catch((error) => {
          console.log(error)
          this.$parent.$parent.showMsg('修改失败！')
        })

        // endregion

        console.log(this.formInfo)
      }
    },
    showImageWindow() {
      this.classShowImageWindow = {
        visibility: 'visible'
      }
    },
    hideImageWindow() {
      this.classShowImageWindow = {}
    },
    async imageFileChange() {
      const uploadUrl = '/files/' + this.$refs.imageFile.files[0].name
      await this.$http.put(uploadUrl, this.$refs.imageFile.files[0]).then((data) => {

        this.uploaded_img = data
        this.showImgPreview = true
        this.$refs.headImg.src = this.$store.state.uploadFileRootUrl + data
      }).catch(() => {
        this.$parent.$parent.showMsg('上传失败！')
      })
    },
    confirmImage() {
      this.formInfo.logo = this.uploaded_img
      this.hideImageWindow()
    },
    showUploadWindow() {
      this.classShowUploadWindow = {
        visibility: 'visible'
      }
    },
    hideUploadWindow() {
      this.classShowUploadWindow = {}
    },
    async xlsChange() {
      const uploadUrl = '/files/' + this.$refs.xlsFile.files[0].name
      await this.$http.put(uploadUrl, this.$refs.xlsFile.files[0]).then((data) => {
        console.log(data)
        this.uploadedCsv = data
      }).catch(() => {
        this.$parent.$parent.showMsg('上传失败！')
      })
    },
    async submitCsv() {
      const uploadUrl = '/get_csv/'
      await this.$http.get(uploadUrl, {
        params: {
          'file_name': this.uploadedCsv
        }
      }).then((data) => {
        console.log(data)

        if (data.length <= 1) {
          this.$parent.$parent.showMsg('表格中无数据！')
          return;
        }

        data.splice(0, 1)

        this.uploadedCscData = data

        this.hideUploadWindow()

        this.classShowProcessWindow = {
          visibility: 'visible'
        }
        this.isFinishUpsert = false
        this.curCsvIndex = 0
        this.csvFinishText = ''
        this.errorList = []
        this.insertOne()

      }).catch(() => {
        this.$parent.$parent.showMsg('读取失败！')
      })
    },
    async insertOne() {

      console.log(this.uploadedCscData)

      if (this.curCsvIndex >= this.uploadedCscData.length) {
        this.csvFinishText = '上传完成'
        this.isFinishUpsert = true
        this.search()
        return;
      }

      let row = this.uploadedCscData[this.curCsvIndex]
      let type = ''

      // region 数据检查开始

      if (row[0].trim().length == 0) {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '请输入单位/企业名称！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      if (row[1].trim().length == 0) {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '请输入会员类型！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      if (row[2].trim().length == 0) {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '请输入用户名！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      if (row[1] != '高校会员' && row[1] != '企业会员' && row[1] != '学院会员') {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '会员类型输入错误！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      if (row[1] == '高校会员') {
        type = '1'
      } else if (row[1] == '企业会员') {
        type = '2'
      } else if (row[1] == '学院会员') {
        type = '3'
      }

      let titleValid = false
      await this.$http.get('/application/', {
        params: {
          eq_title: row[0],
        }
      }).then(async (data) => {
        console.log(data)
        if (data.count == 0) {
          titleValid = true
        }
      })
      if (!titleValid) {
        this.errorList.push({code: 0, text: '[' + row[0] + ']' + '单位/企业名称已存在！'})
        this.curCsvIndex++
        await this.insertOne()
        return;
      }

      // endregion 数据检查结束

      let addForm = {
        title: row[0],
        type: type,
        mainPrincipal: row[2],
        status: 5,
        isPub: 1
      }

      console.log('=======正在插入Application========')
      console.log(addForm)

      const maxSortNum = await this.$http.get('/max_sortnum/')
      const postData = JSON.parse(JSON.stringify(addForm))
      postData['super_category1_sort_num'] = maxSortNum['max_sn_1'] + 1
      postData['super_category2_sort_num'] = maxSortNum['max_sn_2'] + 1
      postData['super_category3_sort_num'] = maxSortNum['max_sn_3'] + 1

      await this.$http.post('/application/', postData).then(async (data) => {
        console.log(data)
        this.errorList.push({code: 1, text: '[' + addForm.title + ']' + "导入成功！"})
        this.curCsvIndex++
        this.insertOne()

      }).catch((error) => {
        console.dir(error)
        this.errorList.push({code: 0, text: '[' + addForm.title + ']' + error.response.data[0]})
        this.curCsvIndex++
        this.insertOne()
      })
    },
    async backOne(id) {
      const url = '/application/' + id + '/'
      await this.$http.put(url, {
        isPub: 0,
        status: 1
      }).then((data) => {
        console.log(data)
        this.$layer.msg('退回成功！');
        this.search()
      }).catch((error) => {
        console.log(error)
        this.$layer.msg('退回失败！');
      })
    },
  },
  mounted() {
    this.search()
  },
}
</script>

<style scoped>
#dialog_content2 {
  max-height: 100px;
  overflow-y: auto;
  border-top: 1px solid #ddd;
}

#dialog_content2 .success {
  color: #aaa;
}

#dialog_content2 .fail {
  color: #e33;
}
.btncolor{
  border: 1px solid #4976e8 !important;
  background-color: #4976e8 !important;
}
</style>
