<template>
  <div>
    <div class="breadcrumb-nav">
      <a href="/">首页</a>&gt;&gt;
      <a href="/www/organization/chairman">组织机构</a>&gt;&gt;联盟秘书处
    </div>
    <div class="subRight">
      <div class="subRightTit">
        <span>联盟秘书处</span>

      </div>
      <div class="con">
        <table
          cellspacing="0"
          cellpadding="0"
          class="orgTbl m-hide"
        >
          <tbody>
            <tr>
              <th
                colspan="5"
                width="759"
              >秘书处单位（按单位名称拼音顺序排列）</th>
            </tr>
            <tr>
              <td class="thead">职位</td>
              <td class="thead">姓名</td>
              <td class="thead">职称</td>
              <td class="thead">职务</td>
              <td class="thead">工作单位</td>
            </tr>
            <tr
              v-for="item in data"
              :key="item.id"
            >
              <td>{{ item.position }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.level }}</td>
              <td>{{ item.duty }}</td>
              <td>{{ item.org_name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Secretariat",
  data (){
    return {
      data: []
    }
  },
  methods: {
    async getData(){
      await this.$http.get('/allowany/org_leader/', {
        params: {
          page: 1,
          size: 10000,
          type: 5,
        }
      }).then((data) => {
        console.log(data)
        this.data = data.results
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped>
td {
  padding: 10 20px;
}
</style>
