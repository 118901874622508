<template>
  <div style="width:100%">
    <TemOne
      :isPreview="isPreview"
      :isMenu="isMenu"
    ></TemOne>
  </div>
</template>

<script>
import TemOne from "../../components/TemOne"
export default {
  name: "TemplateOne",
  components: {TemOne},
  data() {
    return {
    isMenu:true,
    isPreview:true,
    }
  },
  computed: {
   
  },
  watch:{

  },
  methods: {
   
  },
  
  // 生命周期-实例创建完成后调用
  created () { 
   if(this.$route.path.substr(0, 4) == '/www'){
      if(this.$route.query.ownerId){
        this.isPreview = false
        this.isMenu = false
      } else{
        this.$router.push('/')
      }
    }
  },
  mounted() {
  }
}
</script>

