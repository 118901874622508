<template>
  <div>
    <form id="searchSection" onsubmit="return false;" style="margin-top: 30px;">
      <div class="choice" style="width:100%">
        <select name="attr" class="s-input separate"  v-model="queryInfo.org_committee_id">
          <option value="">请选择所属专业领域工作委员会</option>
          <option v-for="item in domainData" :key="item.id" :value="item.id">{{item.committee_name}}</option>
        </select>
        <input type="text" name="code" class="s-input separate" placeholder="专委会名称" v-model="queryInfo.title">
        <input type="text" name="code" class="s-input separate" placeholder="姓名" v-model="queryInfo.connect_user">
        <input type="button" class="s-btn" value="搜索" @click="search(),queryInfo.page=1">

        <div class="add-btn" @click="showInfoWindow">新建</div>
      </div>
    </form>
    <div id="list">
      <table v-if="this.tableData.length > 0">
        <tbody>
        <tr class="l_header">
          <th class="itemStatus">序号</th>
          <th class="itemStatus">所属专业领域工作委员会</th>
          <th class="itemStatus">专委会名称</th>
          <th class="itemStatus">专委会职务</th>
          <th class="itemStatus">姓名</th>
          <th class="itemStatus">职务/职称</th>
          <th class="itemStatus">工作单位</th>
          <th class="itemStatus">联系方式</th>
          <th class="action" width="120">操作</th>
        </tr>
        <tr class="item" v-for="item in tableData" :key="item.id">
          <td>{{item.index}}</td>  
          <td class="itemStatus">{{item.org_committee.committee_name}}</td>
          <td class="itemStatus">{{item.title}}</td>
          <td class="itemStatus">{{item.duty}}</td>
          <td class="itemStatus">{{item.connect_user}}</td>
          <td class="itemStatus">{{item.connect_duty}}</td>
          <td class="itemStatus">{{item.connect_org}}</td>
          <td class="itemStatus">{{item.connect_phone}}</td>
          <td class="action">
            <a class="edit" href="javascript:;" @click="querySingle(item.id)">编辑</a>
            <a class="delete" href="javascript:;" @click="askDel(item.id)">删除</a>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="noitems" v-if="tableData.length == 0">没有数据</div>

      <pagination :cur="pageInfo.page" :all="pageInfo.all" :totalData="pageInfo.totalData"
                  :callback="pageCallback"></pagination>
    </div>
    <div class="form_overlay" id="form_overlay" :style="classShowInfoWindow">
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">工作组添加</div>
        <div class="form_content">
          <form id="itemForm">
            <div class="item">
              <span class="label"><i class="require">*</i>所属专业领域工作委员会:</span>
              <select name="attr" v-model="formInfo.org_committee_id">
                <option value="">请选择所属专业领域工作委员会</option>
                <option v-for="item in domainData" :key="item.id" :value="item.id">{{item.committee_name}}</option>
              </select>
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>专委会名称:</span>
              <input v-model="formInfo.title" type="text" >
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>专委会职务:</span>
              <input v-model="formInfo.duty" type="text" >
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>姓名:</span>
              <input v-model="formInfo.connect_user" type="text" >
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>职务/职称:</span>
              <input v-model="formInfo.connect_duty" type="text">
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>工作单位:</span>
              <input v-model="formInfo.connect_org" type="text">
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>联系方式:</span>
              <input v-model="formInfo.connect_phone" type="text">
            </div>
          </form>
        </div>
        <div class="form_control">
          <a href="javascript:;" class="button close" id="form_close" @click="hideInfoWindow">取消</a>
          <a href="javascript:;" class="button" id="form_submit" @click="saveInfo">提交</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../../../components/Pagination";

export default {
  name: "AdminOrgCommittee",
  components: {
    Pagination
  },
  data() {
    return {
      queryInfo: {
        page: 1,
        size: 10,
        org_committee_id: '',
        title: '',
        connect_user: ''
      },
      formInfo: {
        id: 0,
        org_committee_id:'',
        title: '',
        duty:'',
        connect_user: '',
        connect_duty: '',
        connect_org: '',
        connect_phone: '',
      },
      domainData:[],
      tableData: [],
      tableDataCount: 0,
      showWindow: false
    }
  },
  computed: {
    classShowInfoWindow() {
      if (this.showWindow) {
        return {
          visibility: 'visible'
        }
      } else {
        return {}
      }
    },
    pageInfo() {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    }
  },
  methods: {
    async search() {
      await this.$http.get('/org_workgroup/', {
        params: this.queryInfo
      }).then((data) => {
        data.results.forEach((item, index) => {
          item.index = (this.queryInfo.page - 1) * this.queryInfo.size + index + 1
        })
        this.tableData = data.results
        this.tableDataCount = data.count
        
      }).catch(() => {
        this.$layer.msg('获取信息失败！')
      })
    },
    async searchField() {
      await this.$http.get('/org_committee/', {
        params: {
          page: 1,
          size: 1000,
        }
      }).then((data) => {
        console.log(data)
        this.domainData = data.results
      }).catch(() => {
        this.$layer.msg('获取信息失败！')
      })
    },
    showInfoWindow() {
      this.showWindow = true
    },
    hideInfoWindow() {
      this.showWindow = false
      this.formInfo = {
        id: 0,
        org_committee_id:'',
        title: '',
        duty:'',
        connect_user: '',
        connect_duty: '',
        connect_org: '',
        connect_phone: '',
      }
    },
    async saveInfo() {
      if(!this.formInfo.org_committee_id){
        this.$parent.$parent.$parent.showMsg("请选择所属专业领域工作委员会!")
        return false
      }
      if(!this.formInfo.title){
        this.$parent.$parent.$parent.showMsg("请输入专委会名称!")
        return false
      }
      if(!this.formInfo.duty){
        this.$parent.$parent.$parent.showMsg("请输入专委会职务!")
        return false
      }
      if(!this.formInfo.connect_user){
        this.$parent.$parent.$parent.showMsg("请输入姓名!")
        return false
      }
      if(!this.formInfo.connect_duty){
        this.$parent.$parent.$parent.showMsg("请输入职务/职称!")
        return false
      }
      if(!this.formInfo.connect_org){
        this.$parent.$parent.$parent.showMsg("请输入工作单位!")
        return false
      }
      if(!this.formInfo.connect_phone){
        this.$parent.$parent.$parent.showMsg("请输入联系方式!")
        return false
      }
      if(this.formInfo.id){
        await this.$http.put('/org_workgroup/' + this.formInfo.id + '/', this.formInfo).then((data) => {
          if (data.id) {
            this.$layer.msg("修改成功！")
            this.hideInfoWindow()
            this.search()
          } else {
            this.$layer.msg('修改失败！')
          }
        }).catch(() => {
          this.$layer.msg('修改失败！')
        })
      }else{
        await this.$http.post('/org_workgroup/', this.formInfo).then((data) => {
          if (data.id) {
            this.$layer.msg("添加成功！")
            this.hideInfoWindow()
            this.search()
          } else {
            this.$layer.msg('添加失败！')
          }
        }).catch(() => {
          this.$layer.msg('添加失败！')
        })
      }

    },
    async delInfo(id) {
      const url = '/org_workgroup/' + id + '/'
      await this.$http.delete(url, ).then(() => {
        this.$layer.msg('删除成功！');
        this.search()
      }).catch(() => {
        this.$layer.msg('删除失败！');
      })
    },
    askDel(id) {
      this.$parent.$parent.$parent.showConfirm('确定要删除吗？', () => {
        this.delInfo(id)
      });
    },
    async pageCallback(data) {
      this.queryInfo.page = data
      await this.search()
    },
    async querySingle(id) {
      const url = '/org_workgroup/' + id + '/'
      await this.$http.get(url).then((data) => {
        this.formInfo = {
          id: id,
          org_committee_id: data.org_committee_id,
          title: data.title,
          duty: data.duty,
          connect_user: data.connect_user,
          connect_duty: data.connect_duty,
          connect_org: data.connect_org,
          connect_phone: data.connect_phone,
        }
        this.showInfoWindow()
      }).catch(() => {
        this.$layer.msg('数据获取失败！');
      })
    },
  },
  mounted() {
    this.search()
    this.searchField()
  }
}
</script>

<style scoped>
.form_content .label{
  width: 160px;
  text-align: right;
  padding-right: 10px;
}
</style>
